export const AutoMagicIcon = ({
  width = 32,
  height = 32,
  startColor = '#5FC1D2',
  stopColor = '#4F79EC',
}: {
  width?: number;
  height?: number;
  startColor?: string;
  stopColor?: string;
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" fill="white" fillOpacity="0.01" />
    <path
      d="M10.0001 7.46669L13.3334 9.33335L11.4667 6.00002L13.3334 2.66669L10.0001 4.53335L6.66675 2.66669L8.53341 6.00002L6.66675 9.33335L10.0001 7.46669ZM26.0001 20.5334L22.6667 18.6667L24.5334 22L22.6667 25.3334L26.0001 23.4667L29.3334 25.3334L27.4667 22L29.3334 18.6667L26.0001 20.5334ZM29.3334 2.66669L26.0001 4.53335L22.6667 2.66669L24.5334 6.00002L22.6667 9.33335L26.0001 7.46669L29.3334 9.33335L27.4667 6.00002L29.3334 2.66669ZM19.1601 9.72002C18.6401 9.20002 17.8001 9.20002 17.2801 9.72002L1.72008 25.28C1.20008 25.8 1.20008 26.64 1.72008 27.16L4.84008 30.28C5.36008 30.8 6.20008 30.8 6.72008 30.28L22.2667 14.7334C22.7867 14.2134 22.7867 13.3734 22.2667 12.8534L19.1601 9.72002ZM17.7867 17.04L14.9601 14.2134L18.2134 10.96L21.0401 13.7867L17.7867 17.04Z"
      fill="url(#paint0_linear_374_12601)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_374_12601"
        x1="1.33008"
        y1="16.7769"
        x2="29.3334"
        y2="16.7769"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={startColor} />
        <stop offset="1" stopColor={stopColor} />
      </linearGradient>
    </defs>
  </svg>
);
