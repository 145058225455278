import { useEffect, useMemo, useRef, useState } from "react";
import { getSelectedDropdownItems, getResolvedList } from "./@utils";
import { IDropDownItem } from "./@types";

type Params = {
  value: unknown | unknown[];
  list: IDropDownItem[];
  width?: string | number;
  disabled?: boolean;
  readOnly?: boolean;
  isMultiSelect?: boolean;
  isAnchorWidthMatched?: boolean;
}
export const useDropdown = (params: Params) => {
  const { value, list, width, disabled, readOnly, isMultiSelect, isAnchorWidthMatched } = params;

  const [lastList, setLastList] = useState<IDropDownItem[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const popupAnchorEl = useRef<Element>();
  const popupWidth = isAnchorWidthMatched ? popupAnchorEl.current?.clientWidth : width;
  const handlePopupOpen = (event: React.SyntheticEvent) => {
    if (event && !disabled && !readOnly) {
      popupAnchorEl.current = event.currentTarget;
      setIsOpen(true);
    }
  };
  const closePopup = () => { setIsOpen(false); }

  const handleChange = (temp: unknown[]) => {
    const arrValue = Array.isArray(value) ? value : [value];
    const values = [...arrValue, ...temp.filter((v) => !arrValue.includes(v))];

    const loop = (value: unknown, items: IDropDownItem[]): IDropDownItem | undefined => {
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (item.children) {
          return loop(value, item.children);
        } else if (value === item.value) {
          return item;
        }
      }
    };

    setLastList(
      (ps) =>
        values
          .map((v) => ps.find((item) => item.value === v) || loop(v, list))
          .filter((v) => !!v) as IDropDownItem[]
    );
  };


  const selectedItems = useMemo(() => getSelectedDropdownItems({
    values: Array.isArray(value) ? value : ([value] as unknown[]),
    initialList: list,
    lastList,
    isMultiSelect: isMultiSelect,
  }), [isMultiSelect, lastList, list, value]);



  useEffect(() => {
    // isMultiSelect와 local multi selection은 동시에 사용할 수 없다.
    if (process.env.NODE_ENV !== 'production') {
      const resolvedList = getResolvedList(list);
      if (isMultiSelect && resolvedList.some(item => item.isChildrenMultiSelectable)) {
        console.warn('[MDSDropDown]: isMultiSelect와 isChildrenMultiSelectable은 함께 사용할 수 없습니다.');
      }
    }
  }, [list, isMultiSelect]);




  return {
    selectedItems,
    popupWidth,
    popupAnchorEl,
    isOpen,
    handlePopupOpen,
    closePopup,
    handleChange
  }

}