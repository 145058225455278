import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { IcoCloseDeleteRemoveFill } from '@marqvision/mds/assets';
import Select, { ISelectProps, ISelectValue } from '@marqvision/mds/core/Select';
import Chip from '@marqvision/mds/core/Chip';

const chipWithSelectUseStyles = makeStyles(() => ({
  select: {
    '& button': {
      textAlign: 'left',
      height: 'auto !important',
      padding: '5px 14px 5px 5px !important',
    },
  },
  chipItemWrap: {
    cursor: 'pointer',
    display: 'inherit',
    flexWrap: 'wrap',
    gap: '4px',
  },
}));

interface IOwnProps {
  value?: ISelectValue[];
  removeChip?: (item: ISelectValue) => void;
  placeholder: string;
  size?: 'medium' | 'large';
}

type IProps = IOwnProps & ISelectProps;

export const SelectWithChip = (props: IProps): JSX.Element => {
  const classes = chipWithSelectUseStyles();
  const { size, value, removeChip, placeholder, ...restProps } = props;

  const handleChipRemove = (event: React.MouseEvent<HTMLSpanElement>, item: ISelectValue) => {
    removeChip && removeChip(item);
    event.stopPropagation();
  };

  return (
    <Box position="relative">
      <Select
        size={size || 'large'}
        placeholder={(value || []).length > 0 ? '' : placeholder}
        value={value}
        className={value?.length ? classes.select : undefined}
        renderValue={(values: ISelectValue[]) => {
          return (
            <div className={classes.chipItemWrap}>
              {values.map((item: ISelectValue) => {
                return (
                  <Chip
                    key={item?.value}
                    variant="tint"
                    size="small"
                    color={item?.color || 'bluegray'}
                    label={item?.label}
                    endIcon={restProps.readOnly ? undefined : <IcoCloseDeleteRemoveFill />}
                    endIconClick={(event: React.MouseEvent<HTMLSpanElement>) => handleChipRemove(event, item)}
                    multiple
                  />
                );
              })}
            </div>
          );
        }}
        {...restProps}
      />
    </Box>
  );
};
