import { DragEvent } from 'react';
import { imageHeaderTypeList } from './@constants';

export const getProgressPercentage = (current?: number, total?: number): number => {
  return Math.floor(((current || 0) / (total || 1)) * 100);
};

export const isAcceptedDropData = (e: DragEvent<HTMLDivElement>, acceptDropData?: string) => {
  return Boolean(
    e.dataTransfer.types.includes('Files') || !acceptDropData || e.dataTransfer.types.includes(acceptDropData)
  );
};

const loadedMimeType = (e: ProgressEvent<FileReader>) => {
  if (!e.target?.result || typeof e.target.result === 'string') return;
  const arr = new Uint8Array(e.target.result).subarray(0, 4);
  const header = Array.from(arr, (number) => number.toString(16)).join('');
  return imageHeaderTypeList[header];
};

export const getMimeType = async (file: File) => {
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(file);
  return await new Promise<string | undefined>(
    (resolve) =>
      (fileReader.onloadend = (e) => {
        resolve(loadedMimeType(e));
      })
  );
};

export const isAcceptedExtension = (file: File, accepts: string[]): boolean => {
  if (accepts.length === 0) return true;
  const extension = `.${file.name.split('.')[file.name.split('.').length - 1].split('?')[0]}`;

  return (
    accepts.includes(extension) ||
    accepts.some((v) => {
      const reg = new RegExp(v);
      return reg.test(file.type);
    })
  );
};

export const isAcceptedFileFormat = async (file: File, accepts: string[]): Promise<boolean> => {
  if (accepts.length === 0) return true;

  if (file.type.includes('image')) {
    // file.type 이 이미지일경우 magic number 확인
    const imageType = await getMimeType(file);
    return Boolean(
      imageType &&
        accepts.some((v) => {
          const reg = new RegExp(v);
          return reg.test(file.type);
        })
    );
  }

  return isAcceptedExtension(file, accepts);
};
