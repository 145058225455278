import { useEffect } from 'react';
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import amplitude from 'amplitude-js';
import ReactDOM from 'react-dom/client';
import { DevTools as JotaiDevTools } from 'jotai-devtools';
import { QueryClientProvider } from '@tanstack/react-query';
import '@marqvision/mds/css/fonts.css';
import { queryClient } from '~/apis';
import pkg from '../package.json';
import * as serviceWorker from './serviceWorker';
import { marqCommerceClientRouter } from './router';
import 'core-js/modules/esnext.array.to-sorted';
import 'core-js/modules/esnext.array.to-spliced.js';
import 'core-js/modules/esnext.array.with.js';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  release: pkg.version,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: ['production'].includes(process.env.REACT_APP_SENTRY_ENV ?? '') ? 0.1 : 0,
  sampleRate: ['production', 'pre-release', 'staging'].includes(process.env.REACT_APP_SENTRY_ENV ?? '') ? 1.0 : 0,
});

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY as string);

async function deferRender() {
  if (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_MSW !== 'true') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);
deferRender().then(() => {
  root.render(
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={marqCommerceClientRouter} />
      <JotaiDevTools />
    </QueryClientProvider>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
