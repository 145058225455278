import { useInfiniteQuery } from '@tanstack/react-query';
import { monitoringFilterApi } from '~/apis';
import { QueryKeyDetected } from '~/apis/detection';
import { parseFilterDropDown } from '~/utils';
import { MonitoringFilterProtocolPagingRequest } from '~/swagger/data-contracts';

export const useGetAllOIQuery = (request: MonitoringFilterProtocolPagingRequest) => {
  return useInfiniteQuery(
    [QueryKeyDetected, 'get-all-ois', request],
    async ({ pageParam }) => {
      const { data } = await monitoringFilterApi.filterGetAllOisCreate({
        ...request,
        search_after: pageParam || request.search_after,
      });
      return data;
    },
    {
      select: (data) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page) => parseFilterDropDown(page)),
        };
      },
      getNextPageParam: (lastPage) => {
        return lastPage.search_after;
      },
      enabled: !!request.domain_type,
    }
  );
};
