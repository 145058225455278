/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  EmployeeSignupProtocolsGetEmployeeDataWithTokenRequest,
  EmployeeSignupProtocolsGetEmployeeDataWithTokenResponse,
  EmployeeSignupProtocolsUpdateRegisterRequest,
  EmployeeSignupProtocolsUpdateRegisterResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class EmployeeSignup<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags EmployeeSignup
   * @name GetEmployeeDataWithTokenCreate
   * @request POST:/signup/get-employee-data-with-token
   */
  getEmployeeDataWithTokenCreate = (
    data: EmployeeSignupProtocolsGetEmployeeDataWithTokenRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<EmployeeSignupProtocolsGetEmployeeDataWithTokenResponse, any>({
      path: `/signup/get-employee-data-with-token`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags EmployeeSignup
   * @name UpdateRegisterCreate
   * @request POST:/signup/update-register
   */
  updateRegisterCreate = (data: EmployeeSignupProtocolsUpdateRegisterRequest, params: RequestParams = {}) =>
    this.http.request<EmployeeSignupProtocolsUpdateRegisterResponse, any>({
      path: `/signup/update-register`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
