/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolFileResponse,
  MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeCsvRequest,
  MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeRequest,
  MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeResponse,
  MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateRequest,
  MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateResponse,
  MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopCsvRequest,
  MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopDataRequest,
  MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopDataResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PerformanceDetailsCompRate<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags PerformanceDetailsCompRate
   * @name DetailsCompRateCreate
   * @request POST:/performance/details/comp-rate
   */
  detailsCompRateCreate = (
    data: MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateResponse, any>({
      path: `/performance/details/comp-rate`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsCompRate
   * @name DetailsCompRateOverTimeCreate
   * @request POST:/performance/details/comp-rate/over-time
   */
  detailsCompRateOverTimeCreate = (
    data: MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeResponse, any>({
      path: `/performance/details/comp-rate/over-time`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsCompRate
   * @name DetailsCompRateTopMarketplaceCreate
   * @request POST:/performance/details/comp-rate/top-marketplace
   */
  detailsCompRateTopMarketplaceCreate = (
    data: MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopDataRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopDataResponse, any>({
      path: `/performance/details/comp-rate/top-marketplace`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsCompRate
   * @name DetailsCompRateTopMarketplaceCsvCreate
   * @request POST:/performance/details/comp-rate/top-marketplace/csv
   */
  detailsCompRateTopMarketplaceCsvCreate = (
    data: MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopCsvRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/performance/details/comp-rate/top-marketplace/csv`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsCompRate
   * @name DetailsCompRateOverTimeCsvCreate
   * @request POST:/performance/details/comp-rate/over-time/csv
   */
  detailsCompRateOverTimeCsvCreate = (
    data: MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeCsvRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/performance/details/comp-rate/over-time/csv`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
