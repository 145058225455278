import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { MDSTable, TableRowProps } from '@marqvision/mds-v2';

type TrProps = TableRowProps & {
  as: 'tr';
};

type DivProps = React.PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    as?: 'div';
  }
>;

export type Props = {
  className?: string;
  initHeight?: number;
  isDisabled?: boolean;
} & (TrProps | DivProps);

export const VirtualizationWrapper = (props: Props) => {
  const { as, children, initHeight = 0, ...restProps } = props;

  const [isOnScreen, setIsOnScreen] = useState(false);
  const [height, setHeight] = useState<string | number>('auto');

  const listWrapperRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting);
      const height = entry.isIntersecting ? 'auto' : entry.target.clientHeight;
      setHeight(height);
    });
    if (listWrapperRef.current) {
      observer.observe(listWrapperRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return as === 'tr' ? (
    <MDSTable.Row
      ref={listWrapperRef}
      style={{ height: typeof height === 'string' ? `${initHeight}px` : `${height}px` }}
      {...restProps}
    >
      {isOnScreen ? children : <></>}
    </MDSTable.Row>
  ) : (
    <div
      ref={listWrapperRef}
      style={{
        minHeight: typeof height === 'string' ? initHeight : Math.min(initHeight, height),
        height: typeof height === 'string' ? height : `${height}px`,
      }}
      {...restProps}
    >
      {isOnScreen ? children : <></>}
    </div>
  );
};
