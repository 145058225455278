import countries from 'i18n-iso-countries';
import countryLanguage from 'i18n-iso-countries/langs/en.json';
import dayjs from 'dayjs';
import numeral from 'numeral';

export const getValidUrl = (url: string, scheme = 'https'): string =>
  /^(http|https):\/\//i.test(url) ? encodeURI(url) : `${scheme}://${encodeURI(url)}`;

export const downloadFile = async (fileOrUrl: File | string): Promise<void> => {
  const a = document.createElement('a');

  let url;

  if (fileOrUrl instanceof File) {
    url = window.URL.createObjectURL(fileOrUrl);
    a.download = fileOrUrl.name;
  } else {
    url = fileOrUrl;
  }

  a.href = url;

  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
};

export const downloadFiles = async (fileOrUrls: File[] | string[]): Promise<void> => {
  if (fileOrUrls.length === 0) {
    return;
  }

  const Timeout = 1000; // TODO: It may doesn't work depending on the sort of browser. Please set the value larger if it happens.

  fileOrUrls.forEach((url, index) => {
    window.setTimeout(() => downloadFile(url), index * Timeout);
  });
};

export const downloadBlobFile = (data: unknown, filename: string): void => {
  const url = window.URL.createObjectURL(new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadResponseTypeBlobFile = (data: any, filename: string, contentType?: string): void => {
  const url = window.URL.createObjectURL(new Blob([data], { type: contentType }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFileNameAndExtension = (fullFileName: string): [string, string] => {
  const [_, name = '', ext = ''] = Array.from(/(.*)(\.[a-zA-Z0-9]+$)/.exec(fullFileName) || []);

  return [name, ext];
};

export const stringSort = (a: string, b: string, orderBy: 'asc' | 'desc') => {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();
  if (nameA < nameB) return orderBy === 'asc' ? -1 : 1;
  if (nameA > nameB) return orderBy === 'asc' ? 1 : -1;

  return 0;
};

export const listSliceData = <T>(list: T[], target: T): T[] => {
  const index = list.findIndex((item) => item === target);
  if (index !== -1) {
    list.splice(index, 1);
  }
  return list;
};

export const getCountryName = (countryCode: string): string => {
  countries.registerLocale(countryLanguage);

  if (!countryCode) {
    return '';
  }

  if (countryCode === '00') {
    return 'GLOBAL';
  } else {
    return countries.getName(countryCode, 'en', { select: 'alias' }) || '';
  }
};

export const getChartLabels = (data: any[], frequency?: string): string[] => {
  return data.map((item) => {
    if (frequency === 'daily' || frequency === 'weekly') {
      return dayjs(item.label).format('MMM DD, YYYY');
    }
    return dayjs(item.label).format('MMM YYYY');
  });
};

export function parseValueToValueArray<T>(value: T | T[]): T[] {
  if (value === undefined || value === null || value === '') {
    return [];
  }
  if (typeof value === 'object' && Array.isArray(value)) {
    return value;
  }
  return [value];
}

export const formatNumber = (num: number): string => numeral(num).format('0,0');
export const formatDate = (date?: string | null) => {
  return dayjs(date).format('MMM DD, YYYY');
};

export * from './array/array';
export * from './parser';
export * from './parser/filter';
export * from './parser/archivedLabel';
export * from './toast';
