import { useMatch } from 'react-router-dom';
import {
  MarqBrandEnumsEnforcementListingMonitoringType,
  MarqBrandEnumsEnforcementTPMonitoringType,
} from '~/pages/Enforcement/EcommerceSocialMedia/@types';
import { MarqBrandEnumsListingMonitoringStatusEnum } from '~/swagger/data-contracts';
import {
  toEnforcementListingMonitoringStatus,
  toEnforcementStage,
  toEnforcementTab,
} from '~/pages/Enforcement/EcommerceSocialMedia/@hooks/parser';

export const useEnforcementPathParams = (): MarqBrandEnumsEnforcementListingMonitoringType => {
  const matches = useMatch('/enforcement/:tab/:stage/:status/');
  /**
   * default path 처리는 packages/brand-client/src/Router/ProtectedRouter.tsx 에서 처리하고 있음.
   * - default path 처리가 변경되면 위 부분에서 변경되어야 함.
   * 아래 로직은 Router 에서 처리가 누락된 경우를 방어하기 위한 로직임.
   */
  if (!matches || !matches.params.tab || !matches.params.stage || !matches.params.status) {
    return {
      tab: 'ecommerce_and_social_media',
      stage: 'under_review',
      status: MarqBrandEnumsListingMonitoringStatusEnum.Processing,
    };
  }

  const {
    params: { tab, stage, status },
  } = matches;

  return {
    tab: toEnforcementTab(tab),
    stage: toEnforcementStage(stage),
    status: toEnforcementListingMonitoringStatus(status),
  };
};
export const useEnforcementPathParamsForTestPurchase = (): MarqBrandEnumsEnforcementTPMonitoringType => {
  const matches = useMatch('/enforcement/:tab/:stage/:status/');
  /**
   * default path 처리는 packages/brand-client/src/Router/ProtectedRouter.tsx 에서 처리하고 있음.
   * - default path 처리가 변경되면 위 부분에서 변경되어야 함.
   * 아래 로직은 Router 에서 처리가 누락된 경우를 방어하기 위한 로직임.
   */
  if (!matches || !matches.params.tab || !matches.params.stage || !matches.params.status) {
    return {
      tab: 'ecommerce_and_social_media',
      stage: 'test_purchase',
      status: MarqBrandEnumsListingMonitoringStatusEnum.Reviewing,
    };
  }

  const {
    params: { tab, status },
  } = matches;

  return {
    tab: toEnforcementTab(tab),
    stage: 'test_purchase',
    status: toEnforcementListingMonitoringStatus(status),
  };
};

export * from './parser';
