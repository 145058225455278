import { useCallback } from 'react';
import { useAtomValue } from 'jotai';
import amplitude from 'amplitude-js';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { FeatureFlags } from '@marqvision/shared/src/utils';
import { useAuth } from '~/apis/auth';
import { useIpBrandListQuery } from '~/apis/portfolio/ipDocuments';
import { amplitudeProperties } from '~/atoms';

const getNoUnderscoreProps = (value: unknown): unknown => {
  if (typeof value === 'string') {
    return value.replaceAll('_', ' ');
  }
  if (Array.isArray(value)) {
    return value.map((v) => getNoUnderscoreProps(v)) as string[];
  }

  return value;
};

export const useLogger = () => {
  const { data: clientData } = useAuth();
  const { data } = useIpBrandListQuery({ enabled: !!clientData });

  const brandId = clientData?.selectedBrandId;
  const defaultProperties = useAtomValue(amplitudeProperties);

  const amplitudeDisabled = useFeatureIsOn(FeatureFlags['brand-client-8124-amplitude']);

  const amplitudeLogging = useCallback(
    (event: string, eventProps: Record<string, unknown> = {}) => {
      const brandList = data?.brand_list || [];
      const userEmail = defaultProperties.User || clientData?.user_email || null;

      if (amplitudeDisabled && [defaultProperties.By, eventProps.By].includes('admin')) return;

      const eventProperties: Record<string, unknown> = {
        ...defaultProperties,
        'Client Id': clientData?.client_id,
        'Client Name': clientData?.client_name,
        User: userEmail,
      };

      if (brandId) {
        eventProperties['Brand Name'] = brandList.find((brand) => brand.brand_id === brandId)?.name;
      } else {
        eventProperties['Brand Name'] = brandList.map((brand) => brand.name);
      }

      // Replace underscores of properties with spaces
      for (const [key, value] of Object.entries(eventProps)) {
        eventProperties[key] = getNoUnderscoreProps(value);
      }

      amplitude.getInstance().setUserId(userEmail);
      amplitude.getInstance().logEvent(event, eventProperties);

      if (process.env.NODE_ENV !== 'production') {
        console.log('[Amplitude Log]', event, JSON.stringify(eventProperties, null, 2));
      }
    },
    [brandId, data, defaultProperties, clientData, amplitudeDisabled]
  );

  return {
    amplitudeLogging,
  };
};
