import { Fragment, ReactElement } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { MDSChip, MDSTheme } from './index';

const useStyles = makeStyles(() => ({
  segmentedButtonWrapper: {
    display: 'flex',
    borderRadius: '100px',
    gap: '1px',

    '&.hug': {
      width: 'fit-content',

      '& svg': {
        width: '16px',
        height: '16px',
      },
    },

    '&.fit > div': {
      flex: '1 1 0px',
      justifyContent: 'center',

      '& span': {
        width: 'auto',
      },

      '& svg': {
        width: '16px',
        height: '16px',
      },
    },

    '&.fixed > div': {
      flex: '1 1 0px',
      justifyContent: 'center',

      '& span': {
        width: 'auto',
      },

      '& svg': {
        width: '16px',
        height: '16px',
      },
    },

    '&.tint': {
      backgroundColor: MDSTheme.palette.white,
      border: `1px solid ${MDSTheme.palette.bluegray[200]}`,
    },

    '&.fill': {
      backgroundColor: MDSTheme.palette.bluegray[150],
      border: 'none',
    },
  },
  fillChoiceButton: {
    cursor: 'pointer',
    userSelect: 'none',

    '&:hover': {
      transition: 'background-color 150ms ease-in-out',
    },
  },
  tintChoiceButton: {
    cursor: 'pointer',
    userSelect: 'none',

    '&:hover': {
      transition: 'background-color 150ms ease-in-out',
      backgroundColor: `${MDSTheme.palette.bluegray[300]} !important`,
    },

    '&.selectedChip': {
      '&:hover': {
        backgroundColor: `${MDSTheme.palette.blue[200]} !important`,
      },
    },
  },
}));

type TSegmentedButtonType = 'fit' | 'fixed' | 'hug';
type TSegmentedButtonVariant = 'tint' | 'fill';

export type ButtonGroupItem<T> = {
  label: string;
  value: T;
  Icon?: ReactElement;
  SelectedIcon?: ReactElement;
};

interface CommonProps<T> {
  buttonGroupList: ButtonGroupItem<T>[];
  selectedValue: T;
  fixedWidth?: string;
  type: TSegmentedButtonType;
  variant: TSegmentedButtonVariant;
  onClick: (value: T) => void;
}

interface WithoutWidth<T> extends CommonProps<T> {
  type: 'fit' | 'hug';
  fixedWidth?: never;
}

interface WithWidth<T> extends CommonProps<T> {
  type: 'fixed';
  fixedWidth: string;
}

type Props<T> = WithoutWidth<T> | WithWidth<T>;

export const MDSSegmentedButton = <T extends string | number>(props: Props<T>) => {
  const classes = useStyles();
  const { buttonGroupList, selectedValue, variant, type, fixedWidth, onClick } = props;

  return (
    <div className={clsx(classes.segmentedButtonWrapper, variant, type)} style={{ width: fixedWidth }}>
      {buttonGroupList.map(({ label, value, Icon, SelectedIcon }) => {
        const isSelected = selectedValue === value;

        return (
          <Fragment key={`Segmented_Button_Selection_${label}`}>
            {variant === 'fill' ? (
              <MDSChip
                label={label}
                color={isSelected ? 'blue' : 'bluegray'}
                variant={isSelected ? 'fill' : 'tint'}
                icon={isSelected && SelectedIcon ? SelectedIcon : !isSelected && Icon ? Icon : undefined}
                onClick={() => onClick(value)}
                className={classes.fillChoiceButton}
              />
            ) : (
              <MDSChip
                label={label}
                color={isSelected ? 'blue' : 'white'}
                variant={isSelected ? 'outline' : 'fill'}
                style={{
                  backgroundColor: isSelected ? MDSTheme.palette.blue[50] : 'transparent',
                  outline: isSelected ? `1px solid ${MDSTheme.palette.blue[700]}` : 'none',
                  border: 'none',
                }}
                className={clsx(classes.tintChoiceButton, {
                  selectedChip: isSelected,
                })}
                icon={isSelected && SelectedIcon ? SelectedIcon : !isSelected && Icon ? Icon : undefined}
                onClick={() => onClick(value)}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
