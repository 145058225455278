import React, { Ref, TdHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { MDSTheme } from '../index';

const useStyles = makeStyles({
  td: {
    padding: '12px',
    ...MDSTheme.typography.regular,
    ...MDSTheme.typography.T14,
    color: MDSTheme.palette.bluegray[900],
    boxSizing: 'content-box',
    borderBottom: `1px solid ${MDSTheme.palette.bluegray[100]}`,
    '&:first-child': {
      paddingLeft: '16px',
    },
    '&:last-child': {
      paddingRight: '16px',
    },
  },
});

const MDSTableCell = forwardRef(
  (props: TdHTMLAttributes<HTMLTableCellElement>, ref: Ref<HTMLTableCellElement>): React.ReactNode => {
    const { className, children, ...restProps } = props;
    const classes = useStyles();

    return (
      <td ref={ref} className={clsx(classes.td, className)} {...restProps}>
        {children}
      </td>
    );
  }
);
MDSTableCell.displayName = 'MDSTableCell';

export default MDSTableCell;
