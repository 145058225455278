/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolFileResponse,
  MonitoringWebsiteSWEnforcedUDRPProtocolsExcelResultRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsExcelResultResponse,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetAllWebsiteIdListRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetAllWebsiteIdListResponse,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountAndRecentlyStatusRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountAndRecentlyStatusResponse,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountResponse,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetExportListingsByListingIdRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetExportListingsRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetExportListingsResponse,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetGridWebsiteListRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetGridWebsiteListResponse,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetStatusFilterByTabRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetStatusFilterByTabResponse,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetTableWebsiteListRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetTableWebsiteListResponse,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetWebsiteDetailRequest,
  MonitoringWebsiteSWEnforcedUDRPProtocolsGetWebsiteDetailResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringWebsiteEnforcedUdrp<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpStatusCountCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/status-count
   */
  enforcementWebSiteUdrpStatusCountCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountAndRecentlyStatusRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountAndRecentlyStatusResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/status-count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpCountCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/count
   */
  enforcementWebSiteUdrpCountCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpGridGetListCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/grid/get-list
   */
  enforcementWebSiteUdrpGridGetListCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsGetGridWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUDRPProtocolsGetGridWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/grid/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpTableGetListCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/table/get-list
   */
  enforcementWebSiteUdrpTableGetListCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsGetTableWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUDRPProtocolsGetTableWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/table/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpDetailsCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/details
   */
  enforcementWebSiteUdrpDetailsCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsGetWebsiteDetailRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUDRPProtocolsGetWebsiteDetailResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpGetIdListCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/get-id-list
   */
  enforcementWebSiteUdrpGetIdListCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsGetAllWebsiteIdListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUDRPProtocolsGetAllWebsiteIdListResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/get-id-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpExcelCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/excel
   */
  enforcementWebSiteUdrpExcelCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsGetExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUDRPProtocolsGetExportListingsResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/excel`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpExcelResultCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/excel/result
   */
  enforcementWebSiteUdrpExcelResultCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUDRPProtocolsExcelResultResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/excel/result`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpStatusListCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/status-list
   */
  enforcementWebSiteUdrpStatusListCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsGetStatusFilterByTabRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUDRPProtocolsGetStatusFilterByTabResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/status-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUDRP
   * @name EnforcementWebSiteUdrpExcelListingIdCreate
   * @request POST:/monitoring/enforcement/web-site/udrp/excel/listing-id
   */
  enforcementWebSiteUdrpExcelListingIdCreate = (
    data: MonitoringWebsiteSWEnforcedUDRPProtocolsGetExportListingsByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/web-site/udrp/excel/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
