/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MonitoringFilterProtocolResponse,
  SellerSellerAnalyticDetailArchivedListingProtocolsExportListingStatusListRequest,
  SellerSellerAnalyticDetailArchivedListingProtocolsExportListingStatusListResponse,
  SellerSellerAnalyticDetailArchivedListingProtocolsExportProgressListingStatusListRequest,
  SellerSellerAnalyticDetailArchivedListingProtocolsExportProgressListingStatusListResponse,
  SellerSellerAnalyticDetailArchivedListingProtocolsGetArchivedListingListRequest,
  SellerSellerAnalyticDetailArchivedListingProtocolsGetArchivedListingListResponse,
  SellerSellerAnalyticDetailArchivedListingProtocolsGetFilterInfoDomainRequest,
  SellerSellerAnalyticDetailArchivedListingProtocolsGetFilterInfoReasonRequest,
  SellerSellerAnalyticDetailArchivedListingProtocolsGetFilterInfoSellerRequest,
  SellerSellerAnalyticDetailArchivedListingProtocolsGetListingStatusCountRequest,
  SellerSellerAnalyticDetailArchivedListingProtocolsGetListingStatusCountResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SellerAnalyticsDetailArchived<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SellerAnalyticsDetailArchived
   * @name DetailListingStatusArchivedFilterDomainCreate
   * @request POST:/seller-analytic/detail/listing-status/archived/filter/domain
   */
  detailListingStatusArchivedFilterDomainCreate = (
    data: SellerSellerAnalyticDetailArchivedListingProtocolsGetFilterInfoDomainRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/detail/listing-status/archived/filter/domain`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailArchived
   * @name DetailListingStatusArchivedFilterSellerCreate
   * @request POST:/seller-analytic/detail/listing-status/archived/filter/seller
   */
  detailListingStatusArchivedFilterSellerCreate = (
    data: SellerSellerAnalyticDetailArchivedListingProtocolsGetFilterInfoSellerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/detail/listing-status/archived/filter/seller`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailArchived
   * @name DetailListingStatusArchivedFilterReasonCreate
   * @request POST:/seller-analytic/detail/listing-status/archived/filter/reason
   */
  detailListingStatusArchivedFilterReasonCreate = (
    data: SellerSellerAnalyticDetailArchivedListingProtocolsGetFilterInfoReasonRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/detail/listing-status/archived/filter/reason`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailArchived
   * @name DetailListingStatusArchivedListCreate
   * @request POST:/seller-analytic/detail/listing-status/archived/list
   */
  detailListingStatusArchivedListCreate = (
    data: SellerSellerAnalyticDetailArchivedListingProtocolsGetArchivedListingListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailArchivedListingProtocolsGetArchivedListingListResponse, any>({
      path: `/seller-analytic/detail/listing-status/archived/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailArchived
   * @name DetailListingStatusArchivedCountCreate
   * @request POST:/seller-analytic/detail/listing-status/archived/count
   */
  detailListingStatusArchivedCountCreate = (
    data: SellerSellerAnalyticDetailArchivedListingProtocolsGetListingStatusCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailArchivedListingProtocolsGetListingStatusCountResponse, any>({
      path: `/seller-analytic/detail/listing-status/archived/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailArchived
   * @name DetailListingStatusArchivedExportProgressCreate
   * @request POST:/seller-analytic/detail/listing-status/archived/export/progress
   */
  detailListingStatusArchivedExportProgressCreate = (
    data: SellerSellerAnalyticDetailArchivedListingProtocolsExportProgressListingStatusListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailArchivedListingProtocolsExportProgressListingStatusListResponse, any>({
      path: `/seller-analytic/detail/listing-status/archived/export/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailArchived
   * @name DetailListingStatusArchivedExportCreate
   * @request POST:/seller-analytic/detail/listing-status/archived/export
   */
  detailListingStatusArchivedExportCreate = (
    data: SellerSellerAnalyticDetailArchivedListingProtocolsExportListingStatusListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailArchivedListingProtocolsExportListingStatusListResponse, any>({
      path: `/seller-analytic/detail/listing-status/archived/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
