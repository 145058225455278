import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import MuiTab, { TabProps } from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { useTabContext } from '@material-ui/lab';
import MDSTypography from './Typography';
import MDSTag from './Tag';


const useStyles = makeStyles(() => ({
  tab: {
    minHeight: 'auto',
    minWidth: 'auto',
    padding: 8,
    marginRight: 0,
    '& .MuiTab-wrapper': {
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      textTransform: 'initial',
    },
  },
  labelWrapper: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    '& div': {
      cursor: 'pointer',
    },
  },
}));

type PreparedTag = { count?: number; isNew?: boolean };

type OwnProps = {
  badge?: ReactNode;
  tag?: PreparedTag;
  size?: 'small' | 'medium' | 'large';
};
type Props = PropsWithChildren<OwnProps> & Omit<TabProps, 'children'>;

export const MDSTab = (props: Props) => {
  const classes = useStyles();
  const { label, badge, tag, size = 'medium', className, ...restProps } = props;
  const variant = (function () {
    switch (size) {
      case 'small':
        return 'T14';
      case 'medium':
        return 'T16';
      case 'large':
        return 'T20';
      default:
        return 'T16';
    }
  })();

  const context = useTabContext();
  const isSelected = context?.value === restProps.value;

  return (
    <MuiTab
      className={clsx(classes.tab, className)}
      label={
        <div className={classes.labelWrapper}>
          <MDSTypography weight="medium" variant={variant}>
            {label}
          </MDSTypography>
          {tag?.count !== undefined && (
            <MDSTag variant="fill" color={isSelected ? 'blue' : 'bluegray'} label={Intl.NumberFormat("en-US").format(tag.count)} size="small" />
          )}
          {tag?.isNew && <MDSTag variant="fill" color="red" label="N" size="small" />}
          {badge}
        </div>
      }
      {...restProps}
    />
  );
};

export default MDSTab;
