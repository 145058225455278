import React, { useEffect, useState } from 'react';
import { DateRange, DateRangeProps, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import dayjs from 'dayjs';
import ko from 'date-fns/locale/ko';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import { IcoReset } from '@marqvision/mds/assets';
import { MDSButton } from '@marqvision/mds/core';

const useStyles = makeStyles({
  popover: {
    marginTop: '4px',
  },
  footer: {
    padding: '0 20px 20px',
    backgroundColor: 'white',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    gap: '8px',
  },
});

export interface IDateRange {
  startDate: string | undefined;
  endDate: string | undefined;
}

type LanguageType = 'en' | 'ko';

const LocalizedLabel = {
  en: {
    Apply: 'Apply',
    Cancel: 'Cancel',
    Reset: 'Reset',
  },
  ko: {
    Apply: '적용',
    Cancel: '취소',
    Reset: '초기화',
  },
} as const;

type DataPopupProps = {
  anchorEl: Element;
  onClose: () => void;
  onApply: (date: IDateRange) => void;
  data?: IDateRange | null;
  locale?: LanguageType;
};

const DatePopup = (props: DataPopupProps & Omit<DateRangeProps, 'locale'>) => {
  const { anchorEl, locale = 'en', onClose, onApply, data, ...restProps } = props;
  const classes = useStyles();

  const [dateRange, setDateRange] = useState<Range[]>([
    {
      key: 'selection',
      startDate: data?.startDate ? dayjs(data.startDate).toDate() : undefined,
      endDate: data?.endDate ? dayjs(data.endDate).toDate() : undefined,
    },
  ]);

  const handleChangeDateRange = (value: Range[]) => {
    setDateRange(value);
  };

  const handleApply = () => {
    onApply({
      startDate: dateRange[0].startDate ? dayjs(dateRange[0].startDate).format('YYYY-MM-DDT00:00:00.000Z') : undefined,
      endDate: dateRange[0].endDate ? dayjs(dateRange[0].endDate).format('YYYY-MM-DDT23:59:59.000Z') : undefined,
    });
    onClose();
  };

  const handleReset = () => {
    setDateRange([
      {
        key: 'selection',
        startDate: undefined,
        endDate: undefined,
      },
    ]);
  };

  useEffect(() => {
    if (anchorEl) {
      setDateRange([
        {
          key: 'selection',
          startDate: data?.startDate ? dayjs(data.startDate).toDate() : undefined,
          endDate: data?.endDate ? dayjs(data.endDate).toDate() : undefined,
        },
      ]);
    }
  }, [anchorEl, data]);

  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      className={classes.popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <DateRange
        dateDisplayFormat={locale === 'ko' ? 'yyyy년 M월 d일' : undefined}
        locale={locale === 'ko' ? ko : undefined}
        moveRangeOnFirstSelection={false}
        onChange={(item) => handleChangeDateRange([item.selection])}
        months={2}
        ranges={
          dateRange[0].startDate && dateRange[0].endDate
            ? dateRange
            : [
                {
                  ...dateRange[0],
                  startDate: undefined,
                  endDate: new Date(''),
                },
              ]
        }
        direction="horizontal"
        {...restProps}
      />
      <div className={classes.footer}>
        <div className={classes.buttons}>
          {(dateRange[0].startDate || dateRange[0].endDate) && (
            <MDSButton variant="text" color="blue" size="medium" startIcon={<IcoReset />} onClick={handleReset}>
              {LocalizedLabel[locale].Reset}
            </MDSButton>
          )}
        </div>
        <div className={classes.buttons}>
          <MDSButton variant="outline" color="bluegray" size="small" onClick={onClose}>
            {LocalizedLabel[locale].Cancel}
          </MDSButton>
          <MDSButton variant="outline" color="blue" size="small" onClick={handleApply}>
            {LocalizedLabel[locale].Apply}
          </MDSButton>
        </div>
      </div>
    </Popover>
  );
};

interface IProps extends Omit<DateRangeProps, 'locale' | 'onChange'> {
  renderAnchorComponent: (value?: Range) => React.ReactNode;
  onChange: (date: IDateRange) => void;
  value?: IDateRange | null;
  locale?: LanguageType;
}

export const DateRangePicker = (props: IProps) => {
  const { renderAnchorComponent, onChange, value, locale, ...restProps } = props;

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleApply = (value: IDateRange) => {
    onChange({
      startDate: value.startDate ? dayjs(value.startDate).format('YYYY-MM-DDT00:00:00.000Z') : undefined,
      endDate: value.endDate ? dayjs(value.endDate).format('YYYY-MM-DDT23:59:59.000Z') : undefined,
    });
  };

  const handleOpen = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div onClick={handleOpen}>
        {renderAnchorComponent({
          key: 'selection',
          startDate: value?.startDate ? dayjs(value.startDate).toDate() : undefined,
          endDate: value?.endDate ? dayjs(value.endDate).toDate() : undefined,
        })}
      </div>
      {!!anchorEl && (
        <DatePopup
          anchorEl={anchorEl}
          data={value}
          locale={locale}
          onApply={handleApply}
          onClose={handleClose}
          {...restProps}
        />
      )}
    </div>
  );
};

DateRangePicker.Popup = DatePopup;
