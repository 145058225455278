import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core';
import { MDSTheme, MDSTypography } from '@marqvision/mds/core';

const useStyles = makeStyles(() => ({
  circle: {
    margin: '20px',
  },
  progress: {
    position: 'absolute',
    top: '32px',
    width: '82px',
    textAlign: 'center',
  },
}));

interface IProps {
  open: boolean;
  progress?: number;
}

const LoadingCircularProgress = ({ open, progress }: IProps) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <CircularProgress className={classes.circle} />
      {progress !== undefined && (
        <div className={classes.progress}>
          <MDSTypography variant="T12" color={MDSTheme.palette.blue[600]}>{`${progress}%`}</MDSTypography>
        </div>
      )}
    </Dialog>
  );
};

export default LoadingCircularProgress;
