import { Icon } from './@types';

export const Checked: Icon = {
  small: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2H4ZM8.82415 13.8702C8.46867 14.2257 7.89442 14.2257 7.53893 13.8702L4.26662 10.5979C3.91113 10.2424 3.91113 9.66813 4.26662 9.31264C4.6221 8.95715 5.19635 8.95715 5.55184 9.31264L8.17698 11.9378L14.4482 5.66661C14.8036 5.31112 15.3779 5.31112 15.7334 5.66661C16.0889 6.0221 16.0889 6.59635 15.7334 6.95184L8.82415 13.8702Z"
      fill="currentColor"
    />
  ),

  medium: (
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM10.71 16.29C10.32 16.68 9.69 16.68 9.3 16.29L5.71 12.7C5.32 12.31 5.32 11.68 5.71 11.29C6.1 10.9 6.73 10.9 7.12 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.71 16.29Z"
      fill="currentColor"
    />
  ),
};

export const UnChecked: Icon = {
  small: (
    <>
      <path
        d="M2 4C2 2.89543 2.89543 2 4 2H16C17.1046 2 18 2.89543 18 4V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V4Z"
        fill="fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 4H4V16H16V4ZM4 2C2.89543 2 2 2.89543 2 4V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2H4Z"
        fill="currentColor"
      />
    </>
  ),
  medium: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3Z"
        fill="fill"
      />
      <path
        d="M19 19L5 19V5H19V19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
        fill="currentColor"
      />
    </>
  ),
};

export const Indeterminate: Icon = {
  small: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.55556 2H16C16.9778 2 17.7778 2.8 17.7778 3.77778V16.2222C17.7778 17.2 16.9778 18 16 18H3.55556C2.57778 18 1.77778 17.2 1.77778 16.2222V3.77778C1.77778 2.8 2.57778 2 3.55556 2ZM13.7778 11H5.77778C5.22778 11 4.77778 10.55 4.77778 10C4.77778 9.45 5.22778 9 5.77778 9H13.7778C14.3278 9 14.7778 9.45 14.7778 10C14.7778 10.55 14.3278 11 13.7778 11Z"
      fill="currentColor"
    />
  ),
  medium: (
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM16 13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
      fill="currentColor"
    />
  ),
};
