/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqBrandCoreModelResponseSellerAnalyticResponse,
  SellerSellerAnalyticDetailProtocolsEditProfileRequest,
  SellerSellerAnalyticDetailProtocolsGetClusterProfileRequest,
  SellerSellerAnalyticDetailProtocolsGetClusterProfileResponse,
  SellerSellerAnalyticDetailProtocolsGetClusterSellerListRequest,
  SellerSellerAnalyticDetailProtocolsGetClusterSellerListResponse,
  SellerSellerAnalyticDetailProtocolsGetDataDrivenInsightRequest,
  SellerSellerAnalyticDetailProtocolsGetDataDrivenInsightResponse,
  SellerSellerAnalyticDetailProtocolsGetDetailRequest,
  SellerSellerAnalyticDetailProtocolsGetFirstEnforcementHistoryRequest,
  SellerSellerAnalyticDetailProtocolsGetFirstEnforcementHistoryResponse,
  SellerSellerAnalyticDetailProtocolsGetListingStatusGraphRequest,
  SellerSellerAnalyticDetailProtocolsGetListingStatusGraphResponse,
  SellerSellerAnalyticDetailProtocolsGetSummaryRequest,
  SellerSellerAnalyticDetailProtocolsGetSummaryResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SellerAnalyticsDetail<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SellerAnalyticsDetail
   * @name DetailCreate
   * @request POST:/seller-analytic/detail
   */
  detailCreate = (data: SellerSellerAnalyticDetailProtocolsGetDetailRequest, params: RequestParams = {}) =>
    this.http.request<MarqBrandCoreModelResponseSellerAnalyticResponse, any>({
      path: `/seller-analytic/detail`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetail
   * @name DetailProfileCreate
   * @request POST:/seller-analytic/detail/profile
   */
  detailProfileCreate = (
    data: SellerSellerAnalyticDetailProtocolsGetClusterProfileRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailProtocolsGetClusterProfileResponse, any>({
      path: `/seller-analytic/detail/profile`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetail
   * @name DetailProfileEditCreate
   * @request POST:/seller-analytic/detail/profile/edit
   */
  detailProfileEditCreate = (data: SellerSellerAnalyticDetailProtocolsEditProfileRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/seller-analytic/detail/profile/edit`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetail
   * @name DetailSummaryCreate
   * @request POST:/seller-analytic/detail/summary
   */
  detailSummaryCreate = (data: SellerSellerAnalyticDetailProtocolsGetSummaryRequest, params: RequestParams = {}) =>
    this.http.request<SellerSellerAnalyticDetailProtocolsGetSummaryResponse, any>({
      path: `/seller-analytic/detail/summary`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetail
   * @name DetailListingStatusCreate
   * @request POST:/seller-analytic/detail/listing-status
   */
  detailListingStatusCreate = (
    data: SellerSellerAnalyticDetailProtocolsGetListingStatusGraphRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailProtocolsGetListingStatusGraphResponse, any>({
      path: `/seller-analytic/detail/listing-status`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetail
   * @name DetailClusterSellersCreate
   * @request POST:/seller-analytic/detail/cluster/sellers
   */
  detailClusterSellersCreate = (
    data: SellerSellerAnalyticDetailProtocolsGetClusterSellerListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailProtocolsGetClusterSellerListResponse, any>({
      path: `/seller-analytic/detail/cluster/sellers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetail
   * @name DetailDataInsightCreate
   * @request POST:/seller-analytic/detail/data-insight
   */
  detailDataInsightCreate = (
    data: SellerSellerAnalyticDetailProtocolsGetDataDrivenInsightRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailProtocolsGetDataDrivenInsightResponse, any>({
      path: `/seller-analytic/detail/data-insight`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetail
   * @name DetailFirstEnforcementHistoryCreate
   * @request POST:/seller-analytic/detail/first-enforcement-history
   */
  detailFirstEnforcementHistoryCreate = (
    data: SellerSellerAnalyticDetailProtocolsGetFirstEnforcementHistoryRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailProtocolsGetFirstEnforcementHistoryResponse, any>({
      path: `/seller-analytic/detail/first-enforcement-history`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
