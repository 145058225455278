import { useQuery, useInfiniteQuery, useMutation, InfiniteData, QueryClient } from '@tanstack/react-query';
import { Domain } from '~/pages/Enforcement/@types';
import { parseFilterDropDown } from '~/utils';
import {
  MarqCommonProtocolEmptyRequest,
  MonitoringEnforcementTPProtocolsGetAllListingIdRequest,
  MonitoringEnforcementTPProtocolsGetEnforcementTPListingsRequest,
  MonitoringEnforcementTPProtocolsGetEnforcementTPListingsResponse,
  MonitoringEnforcementTPProtocolsGetNoteDownloadUrlRequest,
  MonitoringFilterProtocolPagingRequest,
  MonitoringFilterProtocolRequest,
} from '~/swagger/data-contracts';
import { monitoringEnforcementTpApi, monitoringFilterApi } from '~/apis';
import { EnforcementStage } from '~/pages/Enforcement/EcommerceSocialMedia/@types';
import { QueryKeyEnforcement } from './reporting';

type AddtionalQueryOptions = {
  staleTime?: number;
  enabled?: boolean;
};

export const useEnforcementTpCountQuery = (request: MarqCommonProtocolEmptyRequest, options?: AddtionalQueryOptions) =>
  useQuery(
    [QueryKeyEnforcement, 'tp-count', request],
    async () => {
      const { data } = await monitoringEnforcementTpApi.enforcementTpCountCreate(request);
      return data;
    },
    options
  );

//#endregion
type FilterParamsForTestPurchase<T> = T & {
  tab: Domain | undefined;
  stage: EnforcementStage | undefined;
};

export const useGetAllTpDomains = (
  params: FilterParamsForTestPurchase<MonitoringFilterProtocolRequest>,
  options?: AddtionalQueryOptions
) => {
  const { tab, stage, ...request } = params;
  return useQuery(
    [QueryKeyEnforcement, 'get-all-domains', request],
    async () => {
      const { data } = await monitoringFilterApi.filterGetAllDomainsCreate(request);
      return data;
    },
    { ...options, select: parseFilterDropDown, enabled: !!request.domain_type }
  );
};

export const useGetAllTpAssets = (
  params: FilterParamsForTestPurchase<MonitoringFilterProtocolRequest>,
  options?: AddtionalQueryOptions
) => {
  const { tab, stage, ...request } = params;
  return useQuery(
    [QueryKeyEnforcement, 'get-all-assets', request],
    async () => {
      const { data } = await monitoringFilterApi.filterGetAllAssetsCreate(request);
      return data;
    },
    { ...options, select: parseFilterDropDown, enabled: !!request.domain_type }
  );
};
//#endregion

export const useGetAllTpSellers = (
  params: FilterParamsForTestPurchase<MonitoringFilterProtocolPagingRequest>,
  options?: AddtionalQueryOptions
) => {
  const { tab, stage, ...request } = params;
  const size = request.size || 0;
  return useInfiniteQuery(
    [QueryKeyEnforcement, 'get-all-sellers', request],
    async ({ pageParam }) => {
      const { data } = await monitoringFilterApi.filterGetAllSellersCreate({
        ...request,
        search_after: pageParam || [],
      });
      return data;
    },
    {
      ...options,
      select: (data) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page) => parseFilterDropDown(page)),
        };
      },
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.list.length < size) {
          return;
        }

        return size * allPage.length;
      },
      enabled: !!request.domain_type,
    }
  );
};

//#endregion

export const useEnforcementTpGetReviewListQuery = (
  request: MonitoringEnforcementTPProtocolsGetEnforcementTPListingsRequest,
  options?: AddtionalQueryOptions & {
    handleSuccess: (data: InfiniteData<MonitoringEnforcementTPProtocolsGetEnforcementTPListingsResponse>) => void;
  }
) => {
  const { handleSuccess, ...restOptions } = options || {};
  return useInfiniteQuery(
    [QueryKeyEnforcement, 'tp-list', request],
    async ({ pageParam }) => {
      const { data } = await monitoringEnforcementTpApi.enforcementTpGetReviewListCreate({
        ...request,
        offset: pageParam || request.offset,
      });
      return data;
    },
    {
      ...restOptions,
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.listings.length < request.limit) {
          return;
        }

        return request.limit * allPage.length;
      },
      onSuccess: (data) => {
        handleSuccess?.(data);
      },
    }
  );
};

//#endregion

export const useEnforcementTpGetAllListingIdQuery = (
  request: MonitoringEnforcementTPProtocolsGetAllListingIdRequest,
  options?: AddtionalQueryOptions
) =>
  useQuery(
    [QueryKeyEnforcement, 'tp-get-all-listing-id', request],
    async () => {
      const { data } = await monitoringEnforcementTpApi.enforcementTpGetAllListingIdCreate(request);
      return data;
    },
    options
  );

export const useTestPurchaseExcelExportMutation = () =>
  useMutation(monitoringEnforcementTpApi.enforcementTpExcelExportCreate);

export const useTestPurchaseExcelExportProgressMutation = () =>
  useMutation(monitoringEnforcementTpApi.enforcementTpExcelProgressCreate);

export const useTestPurchaseListingIdExcelExportMutation = () =>
  useMutation(monitoringEnforcementTpApi.enforcementTpExcelExportListingIdCreate);

export const fetchEnforcementTpNoteDownloadUrlQuery = (
  request: MonitoringEnforcementTPProtocolsGetNoteDownloadUrlRequest
) => {
  const queryClient = new QueryClient();
  return queryClient.fetchQuery({
    queryKey: [QueryKeyEnforcement, 'get-enforcement-tp-note-download-url', request],
    queryFn: async () => {
      const { data } = await monitoringEnforcementTpApi.enforcementTpNoteDownloadUrlCreate(request);
      return data;
    },
  });
};
