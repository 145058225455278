/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MonitoringFilterProtocolResponse,
  SellerSellerAnalyticDetailDetectedListingProtocolsExportListingStatusListRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsExportListingStatusListResponse,
  SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListResponse,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListResponse,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetFilterInfoDomainRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetFilterInfoSellerRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SellerAnalyticsDetailDetected<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SellerAnalyticsDetailDetected
   * @name DetailListingStatusDetectedFilterDomainCreate
   * @request POST:/seller-analytic/detail/listing-status/detected/filter/domain
   */
  detailListingStatusDetectedFilterDomainCreate = (
    data: SellerSellerAnalyticDetailDetectedListingProtocolsGetFilterInfoDomainRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/detail/listing-status/detected/filter/domain`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailDetected
   * @name DetailListingStatusDetectedFilterSellerCreate
   * @request POST:/seller-analytic/detail/listing-status/detected/filter/seller
   */
  detailListingStatusDetectedFilterSellerCreate = (
    data: SellerSellerAnalyticDetailDetectedListingProtocolsGetFilterInfoSellerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/detail/listing-status/detected/filter/seller`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailDetected
   * @name DetailListingStatusDetectedListCreate
   * @request POST:/seller-analytic/detail/listing-status/detected/list
   */
  detailListingStatusDetectedListCreate = (
    data: SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListResponse, any>({
      path: `/seller-analytic/detail/listing-status/detected/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailDetected
   * @name DetailListingStatusDetectedCountCreate
   * @request POST:/seller-analytic/detail/listing-status/detected/count
   */
  detailListingStatusDetectedCountCreate = (
    data: SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountResponse, any>({
      path: `/seller-analytic/detail/listing-status/detected/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailDetected
   * @name DetailListingStatusDetectedExportProgressCreate
   * @request POST:/seller-analytic/detail/listing-status/detected/export/progress
   */
  detailListingStatusDetectedExportProgressCreate = (
    data: SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListResponse, any>({
      path: `/seller-analytic/detail/listing-status/detected/export/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailDetected
   * @name DetailListingStatusDetectedExportCreate
   * @request POST:/seller-analytic/detail/listing-status/detected/export
   */
  detailListingStatusDetectedExportCreate = (
    data: SellerSellerAnalyticDetailDetectedListingProtocolsExportListingStatusListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailDetectedListingProtocolsExportListingStatusListResponse, any>({
      path: `/seller-analytic/detail/listing-status/detected/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
