import React, { JSX } from 'react';
import clsx from 'clsx';
import { Typography as MUITypography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TypographyProps } from '@material-ui/core/Typography/Typography';
import MDSTheme from './Theme';

const useStyles = makeStyles(() => ({
  typography: {
    WebkitLineClamp: 'unset',
    margin: 0,
    '&.H1': {
      ...MDSTheme.typography.H1,
    },
    '&.T40': {
      ...MDSTheme.typography.T40,
    },
    '&.T32': {
      ...MDSTheme.typography.T32,
    },
    '&.T24': {
      ...MDSTheme.typography.T24,
    },
    '&.T20': {
      ...MDSTheme.typography.T20,
    },
    '&.T18': {
      ...MDSTheme.typography.T18,
    },
    '&.T16': {
      ...MDSTheme.typography.T16,
    },
    '&.T14': {
      ...MDSTheme.typography.T14,
    },
    '&.T13': {
      ...MDSTheme.typography.T13,
    },
    '&.T12': {
      ...MDSTheme.typography.T12,
    },
    '&.H1.bold': {
      ...MDSTheme.typography.bold,
    },
    '&.bold': {
      ...MDSTheme.typography.bold,
    },
    '&.medium': {
      ...MDSTheme.typography.medium,
    },
    '&.regular': {
      ...MDSTheme.typography.regular,
    },
    '&.light': {
      ...MDSTheme.typography.light,
    },
  },
}));

export type TypographyVariant = 'H1' | 'T40' | 'T32' | 'T24' | 'T20' | 'T18' | 'T16' | 'T14' | 'T13' | 'T12';
export type TypographyWeight = 'bold' | 'medium' | 'regular' | 'light';

interface ITypographyProps {
  variant?: TypographyVariant;
  weight?: TypographyWeight;
  lineClamp?: number;
  component?: React.ElementType;
  color?: string;
}

type IProps = ITypographyProps & Omit<TypographyProps, 'variant' | 'color'>;

export const MDSTypography = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { className, variant = 'T16', weight = 'regular', lineClamp, children, style, color, ...restProps } = props;

  return (
    <MUITypography
      className={clsx(classes.typography, variant, weight, className, {
        ellipsis: lineClamp,
      })}
      style={{ ...style, WebkitLineClamp: lineClamp || 'unset', color: color || style?.color }}
      {...restProps}
    >
      {children}
    </MUITypography>
  );
};

export default MDSTypography;
