import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { detectionWebsiteDetectedApi } from '~/apis';
import {
  MonitoringFilterProtocolResponse,
  MonitoringWebsiteSWDetectionProtocolsGetCountRequest,
  MonitoringWebsiteSWDetectionProtocolsGetCountResponse,
  MonitoringWebsiteSWDetectionProtocolsGetDomainFilterRequest,
  MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListRequest,
  MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListResponse,
  MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListRequest,
  MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListResponse,
  MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailRequest,
  MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailResponse,
} from '~/swagger/data-contracts';
import { QueryKeyDetected } from '~/apis/detection';
import { QueryKeySettings } from '~/apis/settings';
import { WebsiteImpersonationQueryKey } from '~/apis/enforcement/websiteImpersonation';

export const QueryKeyDetectedWebsite = 'detection/websiteImpersonation';

export const useGetDetectionWICount = (
  request: MonitoringWebsiteSWDetectionProtocolsGetCountRequest,
  onInfiniteScrollSuccess: (data: MonitoringWebsiteSWDetectionProtocolsGetCountResponse) => void
): UseQueryResult<MonitoringWebsiteSWDetectionProtocolsGetCountResponse, unknown> => {
  return useQuery(
    [QueryKeyDetectedWebsite, 'website-impersonation-count', request],
    async () => {
      const { data } = await detectionWebsiteDetectedApi.detectedWebSiteCountCreate(request);

      return data;
    },

    {
      onSuccess: onInfiniteScrollSuccess,
    }
  );
};

export const useGetDetectionWIGridViewList = (
  request: MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListRequest,
  onInfiniteScrollSuccess: (data: InfiniteData<MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListResponse>) => void
): UseInfiniteQueryResult<MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListResponse, unknown> => {
  return useInfiniteQuery(
    [QueryKeyDetectedWebsite, 'website-impersonation-grid-view-list', request],
    async ({ pageParam }) => {
      const { data } = await detectionWebsiteDetectedApi.detectedWebSiteGridGetListCreate({
        ...request,
        offset: pageParam || request.offset,
      });

      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.website_list.length < 50) {
          return;
        }

        return request.limit ? request.limit * allPage.length : 0;
      },
      onSuccess: onInfiniteScrollSuccess,
    }
  );
};

export const useGetDetectionWITableViewList = (
  request: MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListRequest,
  onAddTableViewDataIdList: (data: MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListResponse) => void
): UseQueryResult<MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListResponse, unknown> => {
  return useQuery(
    [QueryKeyDetectedWebsite, 'website-impersonation-table-view-list', request],
    async () => {
      const { data } = await detectionWebsiteDetectedApi.detectedWebSiteTableGetListCreate(request);

      return data;
    },
    {
      onSuccess: onAddTableViewDataIdList,
    }
  );
};

export const useGetDetectionWIWebsiteDetail = (
  request: MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailRequest
): UseQueryResult<MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailResponse, unknown> => {
  return useQuery([QueryKeyDetectedWebsite, 'website-impersonation-detail-panel', request], async () => {
    const { data } = await detectionWebsiteDetectedApi.detectedWebSiteDetailsCreate(request);

    return data;
  });
};

export const useMutateDetectionWIEnforce = () => {
  const queryClient = useQueryClient();

  return useMutation(detectionWebsiteDetectedApi.detectedWebSiteEnforceCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-count']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-grid-view-list']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-table-view-list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'domain-type-count']);
      queryClient.invalidateQueries([QueryKeySettings, 'credits']);
    },
  });
};

export const useGetDetectionWIReportingCap = () => {
  return useMutation(detectionWebsiteDetectedApi.detectedWebSiteReportingCapCreate);
};

export const useMutateDetectionWIIdList = () => {
  return useMutation(detectionWebsiteDetectedApi.detectedWebSiteGetIdListCreate);
};

export const useDetectedWebSiteAuthorizedMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(detectionWebsiteDetectedApi.detectedWebSiteAuthorizedCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-count']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-grid-view-list']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-table-view-list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'domain-type-count']);
      queryClient.invalidateQueries([QueryKeySettings, 'credits']);
    },
  });
};
export const useMutateDetectionWIBulkExcelMutate = () => {
  return useMutation(detectionWebsiteDetectedApi.detectedWebSiteExcelCreate);
};

export const useMutateDetectionWIBulkExcelResultMutate = () => {
  return useMutation(detectionWebsiteDetectedApi.detectedWebSiteExcelResultCreate);
};

export const useMutateDetectionWIListingIdExcelMutate = () => {
  return useMutation(detectionWebsiteDetectedApi.detectedWebSiteExcelListingIdCreate);
};

export const useMutateUndoDetectionWIEnforce = () => {
  const queryClient = useQueryClient();

  return useMutation(detectionWebsiteDetectedApi.detectedWebSiteEnforceUndoCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-count']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-grid-view-list']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-table-view-list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'domain-type-count']);
      queryClient.invalidateQueries([QueryKeySettings, 'credits']);
    },
  });
};

export const useWebsiteDetectionFilterDomainQuery = <TData = MonitoringFilterProtocolResponse>(
  request: MonitoringWebsiteSWDetectionProtocolsGetDomainFilterRequest,
  options?: UseInfiniteQueryOptions<MonitoringFilterProtocolResponse, unknown, TData>
) => {
  const LIMIT = request.limit || 30;
  return useInfiniteQuery<MonitoringFilterProtocolResponse, unknown, TData>(
    [WebsiteImpersonationQueryKey, 'get-all-domains', request],
    async ({ pageParam }) => {
      const { data } = await detectionWebsiteDetectedApi.detectedWebSiteFilterDomainCreate({
        ...request,
        limit: LIMIT,
        offset: pageParam || request.offset,
      });

      return data;
    },
    {
      ...options,
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.list.length < LIMIT) {
          return;
        }

        return LIMIT * allPage.length;
      },
    }
  );
};

export const useDetectionProtocolsRequestIgnoreMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(detectionWebsiteDetectedApi.detectedWebSiteIgnoreCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-count']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-grid-view-list']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-table-view-list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'domain-type-count']);
      queryClient.invalidateQueries([QueryKeySettings, 'credits']);
    },
  });
};
