import { Domain } from '~/pages/Enforcement/@types';
import { EnforcementStage } from '~/pages/Enforcement/EcommerceSocialMedia/@types';
import { MarqBrandEnumsListingMonitoringStatusEnum } from '~/swagger/data-contracts';

export const toEnforcementTab = (tab: string): Domain => {
  if (['ecommerce', 'social_media', 'website', 'ecommerce_and_social_media'].includes(tab)) return tab as Domain;
  else return 'ecommerce' as Domain;
};
export const toEnforcementStage = (stage: string): EnforcementStage => {
  if (
    ['under_review', 'enforcement_in_progress', 'removed', 'denied', 'non_enforceable', 'test_purchase'].includes(stage)
  )
    return stage as EnforcementStage;
  else return 'under_review' as EnforcementStage;
};
export const toEnforcementListingMonitoringStatus = (status: string): MarqBrandEnumsListingMonitoringStatusEnum => {
  switch (status) {
    // case 'pre_public':
    //   return MarqBrandEnumsListingMonitoringStatusEnum.PrePublic;
    case 'detection':
      return MarqBrandEnumsListingMonitoringStatusEnum.Detection;
    case 'processing':
      return MarqBrandEnumsListingMonitoringStatusEnum.Processing;
    case 'reporting':
      return MarqBrandEnumsListingMonitoringStatusEnum.Reporting;
    case 'soft_notice':
      return MarqBrandEnumsListingMonitoringStatusEnum.SoftNotice;
    case 'removed':
      return MarqBrandEnumsListingMonitoringStatusEnum.Removed;
    case 'denied':
      return MarqBrandEnumsListingMonitoringStatusEnum.Denied;
    case 'non_enforceable':
      return MarqBrandEnumsListingMonitoringStatusEnum.NonEnforceable;
    case 'reviewing':
      return MarqBrandEnumsListingMonitoringStatusEnum.Reviewing;
    case 'purchase':
      return MarqBrandEnumsListingMonitoringStatusEnum.Purchase;
    case 'completed':
      return MarqBrandEnumsListingMonitoringStatusEnum.Completed;
    case 'case_closed':
      return MarqBrandEnumsListingMonitoringStatusEnum.CaseClosed;
    case 'dismissed':
      return MarqBrandEnumsListingMonitoringStatusEnum.Dismissed;
    case 'lager':
      return MarqBrandEnumsListingMonitoringStatusEnum.Later;
    case 'gray':
      return MarqBrandEnumsListingMonitoringStatusEnum.Gray;
    default:
      return MarqBrandEnumsListingMonitoringStatusEnum.Processing;
  }
};
export const toEnforcementTPMonitoringStatus = (
  status: MarqBrandEnumsListingMonitoringStatusEnum
): MarqBrandEnumsListingMonitoringStatusEnum => {
  switch (status) {
    case MarqBrandEnumsListingMonitoringStatusEnum.Reviewing:
      return MarqBrandEnumsListingMonitoringStatusEnum.Reviewing;
    case MarqBrandEnumsListingMonitoringStatusEnum.Purchase:
      return MarqBrandEnumsListingMonitoringStatusEnum.Purchase;
    case MarqBrandEnumsListingMonitoringStatusEnum.Completed:
      return MarqBrandEnumsListingMonitoringStatusEnum.Completed;
    case MarqBrandEnumsListingMonitoringStatusEnum.CaseClosed:
      return MarqBrandEnumsListingMonitoringStatusEnum.CaseClosed;
    default:
      return MarqBrandEnumsListingMonitoringStatusEnum.TpAll;
  }
};
