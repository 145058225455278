/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyResponse,
  MarqCommonProtocolFileResponse,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsExcelResultRequest,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsExcelResultResponse,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetAllWebsiteIdListRequest,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetAllWebsiteIdListResponse,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountAndRecentlyStatusRequest,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountAndRecentlyStatusResponse,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountRequest,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountResponse,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetExportListingsByListingIdRequest,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetExportListingsRequest,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetExportListingsResponse,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetGridWebsiteListRequest,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetGridWebsiteListResponse,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetTableWebsiteListRequest,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetTableWebsiteListResponse,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetWebsiteDetailRequest,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetWebsiteDetailResponse,
  MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsRequestUDRPRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringWebsiteEnforcedGoogleDelisting<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingStatusCountCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/status-count
   */
  enforcementWebSiteGoogleDelistingStatusCountCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountAndRecentlyStatusRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountAndRecentlyStatusResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/status-count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingCountCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/count
   */
  enforcementWebSiteGoogleDelistingCountCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingGridGetListCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/grid/get-list
   */
  enforcementWebSiteGoogleDelistingGridGetListCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetGridWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetGridWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/grid/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingTableGetListCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/table/get-list
   */
  enforcementWebSiteGoogleDelistingTableGetListCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetTableWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetTableWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/table/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingDetailsCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/details
   */
  enforcementWebSiteGoogleDelistingDetailsCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetWebsiteDetailRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetWebsiteDetailResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingGetIdListCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/get-id-list
   */
  enforcementWebSiteGoogleDelistingGetIdListCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetAllWebsiteIdListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetAllWebsiteIdListResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/get-id-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingRequestCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/request
   */
  enforcementWebSiteGoogleDelistingRequestCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsRequestUDRPRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/request`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingExcelCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/excel
   */
  enforcementWebSiteGoogleDelistingExcelCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetExportListingsResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/excel`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingExcelResultCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/excel/result
   */
  enforcementWebSiteGoogleDelistingExcelResultCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsExcelResultResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/excel/result`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedGoogleDelisting
   * @name EnforcementWebSiteGoogleDelistingExcelListingIdCreate
   * @request POST:/monitoring/enforcement/web-site/google-delisting/excel/listing-id
   */
  enforcementWebSiteGoogleDelistingExcelListingIdCreate = (
    data: MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetExportListingsByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/web-site/google-delisting/excel/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
