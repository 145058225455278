/**
 * 205 상태코드를 받았을 때 페이지를 리셋하는 모달을 띄우는 컴포넌트
 * - Seller 정보가 매주 업데이트가되는데, 만약 사용자가 업데이트될 당시에 옛날 데이타를 보고있을경우 서버에서 205코드를 준다. 그때 사용자 화면을 강제 refresh하기 위해서 존재.
 * 참고: https://marqvision.slack.com/archives/C031Z3W5FEZ/p1712641683562149?thread_ts=1712641548.036169&cid=C031Z3W5FEZ
 *
 * todo: 대상 url에서만 intercept하도록 수정해야함. -- 현재는 seller 관련 페이지 한번 들렀다가 간 뒤 interceptor가 해제되지않고 그대로 남아있음.
 */
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { http } from '~/apis/base';
import ClusterUpdateDialog from '../../pages/SellerAnalytics/components/ClusterUpdateDialog';

// 참고: https://marqvision.slack.com/archives/C04VA2NGQTG/p1717985000009479?thread_ts=1717983341.292429&cid=C04VA2NGQTG
const ENDPOINTS_CHECK_205 = ['/seller-analytic/detail', '/seller-analytic/cluster'];

const AxiosInterceptorWrapper = () => {
  const [shouldDisplayResetPageModal, setShouldDisplayResetPageModal] = useState(false);

  useEffect(() => {
    const sellerInterceptor = http.instance.interceptors.response.use((response) => {
      const apiEndpoint = response.request.responseURL;
      const shouldCheck = ENDPOINTS_CHECK_205.some((endpoint) => apiEndpoint.includes(endpoint));
      if (shouldCheck) {
        if (response?.status === 205) {
          setShouldDisplayResetPageModal(true);
        } else {
          setShouldDisplayResetPageModal(false);
        }
      }

      return response;
    });

    return () => {
      http.instance.interceptors.request.eject(sellerInterceptor);
    };
  }, []);

  return (
    <>
      {shouldDisplayResetPageModal && <ClusterUpdateDialog />}
      {!shouldDisplayResetPageModal && <Outlet />}
    </>
  );
};

export default AxiosInterceptorWrapper;
