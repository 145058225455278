import React from 'react';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { withRouterMock } from '@marqvision/shared';
import { MDSTheme } from '@marqvision/mds/core';
const styles = (theme) => ({
  popoverRoot: {
    top: '8px !important',
  },
  popoverPaper: {
    borderRadius: 6,
    boxShadow: `0 8px 20px 0 ${MDSTheme.palette.black_alpha_10} !important`,
    border: `solid 1px ${MDSTheme.palette.bluegray[200]}`,
  },
  popoverWrap: {
    minWidth: 140,
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.snow4,
    },
  },
  popoverButton: {
    ...theme.typography.regular,
    color: theme.palette.snow9,
    lineHight: 1.71,
    height: 38,
    padding: '7px 24px',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.snow3,
      opacity: 1,
    },
    '& .MuiButton-label': {
      justifyContent: 'left',
    },
  },
  smButton: {
    height: 30,
    padding: '5px 16px',
  },
  alert: {
    color: MDSTheme.palette.red[700],
  },
});

class Dropdown extends React.Component {
  render() {
    const { classes, anchorEl, onClose, items, size, anchorOrigin, transformOrigin } = this.props;

    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        classes={{
          root: classes.popoverRoot,
          paper: classes.popoverPaper,
        }}
        onClose={onClose}
        anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={transformOrigin || { vertical: 'top', horizontal: 'right' }}
      >
        <div className={classes.popoverWrap}>
          {items &&
            items.map((item, index) => (
              <React.Fragment key={`${item.name}-${index}`}>
                <Button
                  key={index}
                  className={clsx(classes.popoverButton, {
                    [classes.alert]: item.color === 'alert',
                    [classes.smButton]: size === 'sm',
                  })}
                  onClick={item.onClick}
                  disabled={item.disabled}
                >
                  {item.name}
                </Button>
              </React.Fragment>
            ))}
        </div>
      </Popover>
    );
  }
}

export default withStyles(styles)(withRouterMock(Dropdown));
