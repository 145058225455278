import { ChangeEvent } from 'react';
import clsx from 'clsx';
import MuiTabs, { TabsProps } from '@material-ui/core/Tabs';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton, TabScrollButtonProps } from '@material-ui/core';
import { TabContext as MuiTabContext } from '@material-ui/lab';
import { IcoArrowLeftBorder, IcoArrowRightBorder } from '../assets';
import theme from './Theme';

/*
  #172 이슈 수정사항
  (https://bitbucket.org/marqvision/marqvision-web/pull-requests/172/fix-mds-prod-3768-modify-mds-tabs-design)
  - 탭 선택됐을 경우 텍스트 색상 변경
  - 탭 width 가변
  - tabs에 적용되지 않은 스타일이 있어 적용 (tab-label class 없음)
  - variant: 'fill' 에 대해서도 수정 (수정 당시 사용하는 곳 없음)
  - '& button' 로 적용된 스타일을 MDSTab 으로 옮김
 */

export type Features = {
  color: 'light' | 'dark';
  size: 'small' | 'medium' | 'large' | 'custom';
  withTitle?: boolean;
  // note: MUI TabsProps 에서 이 함수를 ((event: ChangeEvent<{}>, value: any) => void) & FormEventHandler<HTMLButtonElement>'.
  // 타입으로 잡아버려서 type narrowing을 위해 다시 선언.
  onChange?: (event?: ChangeEvent<Record<string, unknown>>, value?: any) => void;
};

type StyleType = {
  withTitle: boolean;
  color: Features['color'];
};

const useStyles = makeStyles<Theme>(() => ({
  tabs: {
    // padding 때문에 width 100% 이상 먹어서 추가
    boxSizing: 'border-box',
    // 풀영역을 차지해야돼서 추가했는데 이게 맞을까요? 탭이 풀영역을 안가질수도 있을까요?
    width: '100%',
    margin: 0,
    padding: '0px 24px',
    minHeight: 'auto',
    position: 'relative',
    '& .MuiTabs-indicator': {
      display: 'inline-block',
      bottom: '0',
      height: '3px',
      borderRadius: '3px 3px 0px 0px',
    },
    '&.dark': {
      backgroundColor: theme.palette.bluegray[900],
      '& .MuiTab-root': {
        '& .MuiTypography-root': {
          color: theme.palette.white,
          opacity: 0.5,
        },
        '&.Mui-selected .MuiTypography-root': {
          color: theme.palette.white,
          opacity: 1,
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.white,
      },
    },
    '&.light': {
      backgroundColor: theme.palette.white,
      '& .MuiTab-root': {
        '& .MuiTypography-root': {
          color: theme.palette.bluegray[900],
          opacity: 1,
        },
        '&.Mui-selected .MuiTypography-root': {
          color: theme.palette.blue[700],
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.blue[700],
      },
    },
    // figma의 m과 동일 -> MDS v2 figma와 맞추기
    '&.small': {
      '& button': {
        height: ({ withTitle, color }: StyleType) => (withTitle || color === 'dark' ? '38px' : '48px'),
        '&.Mui-selected ': {
          height: ({ withTitle, color }: StyleType) => (withTitle || color === 'dark' ? '35px' : '45px'),
        },
      },
    },

    // figma의 l과 동일 -> MDS v2 figma와 맞추기
    '&.medium': {
      '& div[role="tablist"]': {
        gap: '4px',
      },
      '& button': {
        height: ({ withTitle, color }: StyleType) => (withTitle || color === 'dark' ? '44px' : '54px'),
        '&.Mui-selected ': {
          height: ({ withTitle, color }: StyleType) => (withTitle || color === 'dark' ? '41px' : '51px'),
        },
      },
    },

    // figma의 xl과 동일 -> MDS v2 figma와 맞추기
    '&.large': {
      '& div[role="tablist"]': {
        gap: '8px',
      },
      '& button': {
        height: ({ withTitle, color }: StyleType) => (withTitle || color === 'dark' ? '50px' : '60px'),
        '&.Mui-selected ': {
          height: ({ withTitle, color }: StyleType) => (withTitle || color === 'dark' ? '47px' : '57px'),
        },
      },
    },
  },
  scrollButtonWrapper: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    zIndex: 1,
  },
  scrollButton: {
    width: '38px',
    padding: '0 !important',
    height: '100%',
    borderRadius: 'unset',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.dark': {
      backgroundColor: theme.palette.bluegray[900],
      '& .MuiIconButton-label > svg > path': {
        fill: theme.palette.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.bluegray[800],
      },
    },
  },
  scrollButtonGradation: {
    width: '16px',
    height: '100%',
  },
  gradationLeft: {
    background: 'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    '&.dark': {
      background: 'linear-gradient(90deg, #212B36 0%, rgba(33, 43, 54, 0) 100%)',
    },
  },
  gradationRight: {
    background: 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);',
    '&.dark': {
      background: 'linear-gradient(270deg, #212B36 0%, rgba(33, 43, 54, 0) 100%)',
    },
  },
}));

export type MDSTabsProps = React.PropsWithChildren<Features> & Omit<TabsProps, keyof Features>;

export const MDSTabs = (props: MDSTabsProps): JSX.Element => {
  const { color, size, withTitle = true, className, children, onChange, ...restProps } = props;
  const classes = useStyles({ withTitle, color });

  const renderScrollButton = ({ disabled, direction, onClick }: TabScrollButtonProps) => {
    if (disabled) {
      return null;
    }

    return (
      <div className={classes.scrollButtonWrapper} style={{ [direction]: 0 }} onClick={onClick}>
        {direction === 'right' && (
          <div className={clsx(classes.scrollButtonGradation, classes.gradationRight, color)} />
        )}
        <IconButton className={clsx(classes.scrollButton, color)}>
          {direction === 'left' ? <IcoArrowLeftBorder /> : <IcoArrowRightBorder />}
        </IconButton>
        {direction === 'left' && <div className={clsx(classes.scrollButtonGradation, classes.gradationLeft, color)} />}
      </div>
    );
  };

  return (
    <MuiTabContext value={restProps.value}>
      <MuiTabs
        classes={{
          root: clsx(classes.tabs, className, size, color),
        }}
        ScrollButtonComponent={renderScrollButton}
        onChange={onChange}
        {...restProps}
      >
        {children}
      </MuiTabs>
    </MuiTabContext>
  );
};

export default MDSTabs;
