import React, { useState } from 'react';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { MDSThemeValue, MDSTypography } from '@marqvision/mds-v2';
import { useWrapperSize } from '../../../hooks';
import { parseFixedPercent } from '../../../utils';
import { Circle } from './Circle';
import { ChartTooltip } from './ChartTooltip';
import { CircleChartDataItem, ExtendedCircleChartDataItem, Tooltip } from './@types';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  circle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    aspectRatio: '1',
    borderRadius: '50%',
  },
  emptyData: {
    backgroundColor: MDSThemeValue.color.bg.surface.neutral.tertiary.normal,
  },
  label: {
    fill: MDSThemeValue.color.content.inverse.default.normal,
  },
});

type Props = {
  isLoading: boolean;
  data: CircleChartDataItem[];
};

export const CircleChart = (props: Props) => {
  const { isLoading, data } = props;
  const classes = useStyles();

  const { wrapperRef, width, height } = useWrapperSize();

  const sortedData = data.toSorted((a, b) => b.value - a.value);
  const denominator = sortedData.at(0)?.value || 1;
  const chartData: ExtendedCircleChartDataItem[] = sortedData.map((item) => ({
    ...item,
    percent: (item.value / denominator) * 100,
    radius: Math.sqrt((50 * 50 * Math.PI * (item.value / denominator)) / Math.PI),
  }));
  const labelTarget = chartData.at(-1);

  const [tooltip, setTooltip] = useState<Tooltip | null>(null);

  const handleMouseOver = (data: ExtendedCircleChartDataItem) => (event: React.MouseEvent<SVGCircleElement>) => {
    setTooltip({ left: event.clientX, top: event.clientY, data });
  };

  const handleMouseOut = () => {
    setTooltip(null);
  };

  const tooltipEvents = (data: ExtendedCircleChartDataItem) => ({
    onMouseOver: handleMouseOver(data),
    onMouseMove: handleMouseOver(data),
    onMouseOut: handleMouseOut,
  });

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      {isLoading ? (
        <Skeleton variant="circle" className={classes.circle} />
      ) : !chartData.length || denominator === 1 ? (
        <div className={clsx(classes.circle, classes.emptyData)} />
      ) : (
        <svg viewBox={`0 0 ${width} ${height}`} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          {chartData.map((item, index) => (
            <Circle
              disableAnimation={index === 0}
              key={item.label}
              radius={item.radius}
              color={item.color}
              tooltipEvents={tooltipEvents(item)}
              width={width}
              height={height}
            />
          ))}

          {labelTarget && (
            <text
              x={width / 2}
              y={labelTarget.radius ? ((100 - labelTarget.radius) * height) / 100 : height / 2}
              textAnchor="middle"
              dominantBaseline="central"
              className={classes.label}
            >
              <MDSTypography as="tspan" weight="medium">
                {parseFixedPercent(labelTarget.percent)}
              </MDSTypography>
              <MDSTypography as="tspan">%</MDSTypography>
            </text>
          )}
        </svg>
      )}

      {tooltip && <ChartTooltip {...tooltip} />}
    </div>
  );
};
