import {
  MarqBrandEnumsListingMonitoringStatusEnum,
  MarqCommonEnumsCountryCodeEnum,
  MarqCommonEnumsDomainTypeEnum,
  MarqCommonEnumsReportableReasonEnum,
} from '~/swagger/data-contracts';

export type EIPOption =
  | MarqBrandEnumsListingMonitoringStatusEnum.Reporting
  | MarqBrandEnumsListingMonitoringStatusEnum.SoftNotice;
export const EIPStatusFilterVocab: Record<'reporting' | 'soft_notice', string> = {
  reporting: 'Marketplace Reporting',
  soft_notice: 'Soft Notice',
};
export type TPStatusOption = 'reviewing' | 'purchase' | 'completed' | 'case_closed'; // MarqBrandEnumsEnforcementTPMonitoringStatusEnum
export const TPStatusFilterVocab: Record<TPStatusOption, string> = {
  reviewing: 'Reviewing',
  purchase: 'Purchase & Delivery',
  completed: 'Completed',
  case_closed: 'Case closed',
};

export type FilterQueryParam<KeywordFilterType = string> = {
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  keyword: KeywordFilterType;
  search: KeywordFilterType;
  status: MarqBrandEnumsListingMonitoringStatusEnum;
  assets: number | number[];
  marketplaces: number | number[];

  seller_search_keyword?: string;

  smart_rule_ids: number | number[];
  flagged: number[];

  report_reason: MarqCommonEnumsReportableReasonEnum | MarqCommonEnumsReportableReasonEnum[];
  ois: number | number[];

  min_price?: number | null;
  max_price?: number | null;
  min_sales_volume?: number;
  max_sales_volume?: number;

  likely_counterfeit?: boolean;
  high_risk?: boolean;
  priority?: boolean;
  use_of_rights?: boolean;
  low_price?: boolean;
  tm_logo?: boolean;
  tm_text?: boolean;
  infringing_images_filter?: boolean;
  repeat_offender?: boolean;
  top_results?: boolean;
  requested?: boolean;

  start_date: string;
  end_date: string;
  country?: MarqCommonEnumsCountryCodeEnum[];
  models: number[];
  authorized_seller: boolean;
  listing_id?: number;
  listing_option_id?: number;
  group_by?: string;
  period?: string;
  tab?: string;
  sorted_column?: string;
};

export type UpdatedDateRange = { startDate: string | null; endDate: string | null };
export type FilterChipOption<T> = {
  label: string;
  value: T;
  count?: number | string;
  selected: boolean;
};

export interface FilterDropDown {
  count: number;
  list: IDropDownItem[];
  filter_display?: boolean;
  search_after?: unknown | null;
}

export interface IDropDownItem {
  value: unknown;
  label: string;
  group?: string;
  image?: string;
  isFolded?: boolean;
  count?: number | null;
  children?: IDropDownItem[];
  [propName: string]: any;
}

export enum SortValueEnum {
  Desc = 'desc',
  Asc = 'asc',
}
