import { useQuery, useInfiniteQuery, useMutation, InfiniteData } from '@tanstack/react-query';
import { monitoringEnforcementReportApi, monitoringFilterApi } from '~/apis';
import {
  MonitoringEnforcementReportProtocolsExcelResultRequest,
  MonitoringEnforcementReportProtocolsExcelResultResponse,
  MonitoringEnforcementReportProtocolsGetAllListingIdRequest,
  MonitoringEnforcementReportProtocolsGetEnforcementReportListingsRequest,
  MonitoringEnforcementReportProtocolsGetEnforcementReportListingsResponse,
  MonitoringFilterProtocolPagingRequest,
  MonitoringFilterProtocolRequest,
} from '~/swagger/data-contracts';
import { parseFilterDropDown } from '~/utils';

export const QueryKeyEnforcement = 'enforcement';

type AdditionalQueryOptions = {
  staleTime?: number;
  enabled?: boolean;
};

export const useGetAllDomains = <T = null>(
  request: MonitoringFilterProtocolRequest & T,
  options?: AdditionalQueryOptions
) => {
  return useQuery(
    [QueryKeyEnforcement, 'get-all-domains', request],
    async () => {
      const { data } = await monitoringFilterApi.filterGetAllDomainsCreate(request);
      return data;
    },
    { ...options, select: parseFilterDropDown, enabled: !!request.domain_type }
  );
};

export const useGetAllAssets = (request: MonitoringFilterProtocolRequest, options?: AdditionalQueryOptions) => {
  return useQuery(
    [QueryKeyEnforcement, 'get-all-assets', request],
    async () => {
      const { data } = await monitoringFilterApi.filterGetAllAssetsCreate(request);
      return data;
    },
    { ...options, select: parseFilterDropDown, enabled: !!request.domain_type }
  );
};

export const useGetAllSellers = (request: MonitoringFilterProtocolPagingRequest, options?: AdditionalQueryOptions) => {
  const size = request.size || 0;
  return useInfiniteQuery(
    [QueryKeyEnforcement, 'get-all-sellers', request],
    async ({ pageParam }) => {
      const { data } = await monitoringFilterApi.filterGetAllSellersCreate({
        ...request,
        search_after: pageParam || [],
      });
      return data;
    },
    {
      ...options,
      select: (data) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page) => parseFilterDropDown(page)),
        };
      },
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.search_after && lastPage.search_after.length > 0) {
          return lastPage.search_after;
        }
        return false;
      },
      enabled: !!request.domain_type, // MonitoringFilterProtocolRequest, MonitoringFilterProtocolPagingRequest 둘다 domain_type 이 있기에 최소한의 방어로직 추가.
    }
  );
};

export const useGetAllModels = <T = null>(
  request: MonitoringFilterProtocolRequest & T,
  options?: AdditionalQueryOptions
) => {
  return useQuery(
    [QueryKeyEnforcement, 'get-all-models', request],
    async () => {
      const { data } = await monitoringFilterApi.filterGetAllModelCreate(request);
      return data;
    },
    {
      ...options,
      select: parseFilterDropDown,
    }
  );
};

export const useGetAllReportable = (request: MonitoringFilterProtocolRequest, options?: AdditionalQueryOptions) => {
  return useQuery(
    [QueryKeyEnforcement, 'get-all-reportable', request],
    async () => {
      const { data } = await monitoringFilterApi.filterGetAllReportableCreate(request);
      return data;
    },
    { ...options, select: parseFilterDropDown, enabled: !!request.domain_type }
  );
};

export const useGetAllRules = (request: MonitoringFilterProtocolRequest, options?: AdditionalQueryOptions) =>
  useQuery(
    [QueryKeyEnforcement, 'get-all-rules', request],
    async () => {
      const { data } = await monitoringFilterApi.filterEnforcementGetAllRulesCreate(request);
      return data;
    },
    { ...options, select: parseFilterDropDown, enabled: !!request.domain_type }
  );

export const useEnforcementReportedGetReviewListQuery = (
  request: MonitoringEnforcementReportProtocolsGetEnforcementReportListingsRequest,
  options?: AdditionalQueryOptions & {
    handleSuccess: (
      data: InfiniteData<MonitoringEnforcementReportProtocolsGetEnforcementReportListingsResponse>
    ) => void;
  }
) => {
  const { handleSuccess, ...restOptions } = options || {};
  return useInfiniteQuery(
    [QueryKeyEnforcement, 'reported-review-list', request],
    async ({ pageParam }) => {
      const { data } = await monitoringEnforcementReportApi.enforcementReportedGetReviewListCreate({
        ...request,
        offset: pageParam || request.offset,
      });
      return data;
    },
    {
      ...restOptions,
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.listings.length < request.limit) {
          return;
        }

        return request.limit * allPage.length;
      },
      onSuccess: (data) => {
        handleSuccess?.(data);
      },
    }
  );
};

export const useMarketplaceReportingExcelExportProgressQuery = (
  request: MonitoringEnforcementReportProtocolsExcelResultRequest,

  // TODO: query option type을 일반화시키기. - generic으로 data할당을 하더라도 optional로 작동하게.
  options?: AdditionalQueryOptions & {
    refetchInterval?:
      | number
      | false
      | ((data?: MonitoringEnforcementReportProtocolsExcelResultResponse) => number | false);
  }
) =>
  useQuery(
    [QueryKeyEnforcement, 'excel-export-progress', request],
    async () => {
      const { data } = await monitoringEnforcementReportApi.enforcementReportedExcelProgressCreate(request);
      return data;
    },
    options
  );

export const useMarketplaceReportingExcelExportMutation = () =>
  useMutation(monitoringEnforcementReportApi.enforcementReportedExcelExportCreate);

export const useMarketplaceReportingListingIdExcelExportMutation = () =>
  useMutation(monitoringEnforcementReportApi.enforcementReportedExcelExportListingIdCreate);

export const useEnforcementReportedGetAllListingIdQuery = (
  request: MonitoringEnforcementReportProtocolsGetAllListingIdRequest,
  options?: AdditionalQueryOptions
) =>
  useQuery(
    [QueryKeyEnforcement, 'reported-get-all-listing-id', request],
    async () => {
      const { data } = await monitoringEnforcementReportApi.enforcementReportedGetAllListingIdCreate(request);
      return data;
    },
    options
  );

export const useEnforcementReportedGetSnapshotsMutation = () =>
  useMutation(monitoringEnforcementReportApi.enforcementReportedGetSnapshotsCreate);
