import { ReactNode } from 'react';
import { MDSIcon } from '@marqvision/mds-v2';
import { PermissionMap } from '~/router/permissionMap';

type LNBItemGroup = {
  type: 'LNB_ITEM_GROUP';
  path?: string;
  display?: {
    text: string;
    icon?: ReactNode;
    open?: boolean;
    dividerTop?: boolean;
  };
  items: LNBItem[];
};
type LNBItem = {
  type: 'LNB_ITEM';
  path: string;
  display?: {
    text: string;
    icon?: ReactNode;
    open?: boolean;
    dividerTop?: boolean;
  };
};
export type LNBConfig = LNBItemGroup | LNBItem;

export const MARQ_COMMERCE_LNB: Array<LNBItemGroup | LNBItem> = [
  {
    type: 'LNB_ITEM',
    path: '/home',
    display: {
      text: 'Home',
      icon: <MDSIcon.Home />,
    },
  },
  {
    type: 'LNB_ITEM_GROUP',
    path: '/performance',
    items: [
      {
        type: 'LNB_ITEM',
        path: '/performance',
        display: { text: 'Overview' },
      },
      {
        type: 'LNB_ITEM',
        path: '/performance-reports',
        display: { text: 'Reports' },
      },
    ],
    display: {
      text: 'Performance',
      icon: <MDSIcon.ChartLeaderBoard />,
    },
  },
  {
    type: 'LNB_ITEM_GROUP',
    items: [
      {
        type: 'LNB_ITEM',
        path: '/assets',
        display: { text: 'Assets' },
      },
      {
        type: 'LNB_ITEM',
        path: '/ip-portfolio',
        display: { text: 'IP Documents' },
      },
      {
        type: 'LNB_ITEM',
        path: '/official-image',
        display: { text: 'Official Images' },
      },
      {
        type: 'LNB_ITEM',
        path: '/library',
        display: { text: 'Infringing Images' },
      },
      {
        type: 'LNB_ITEM',
        path: '/authorized-sellers',
        display: { text: 'Authorized List' },
      },
    ],
    display: {
      text: 'Portfolio',
      open: false,
      icon: <MDSIcon.Folder variant="outline" />,
    },
  },
  {
    type: 'LNB_ITEM',
    path: '/detection',
    display: {
      text: 'Detection',
      icon: <MDSIcon.SearchEarth />,
      dividerTop: true,
    },
  },
  {
    type: 'LNB_ITEM',
    path: '/enforcement',
    display: {
      text: 'Enforcement',
      icon: <MDSIcon.ProtectionCheck />,
    },
  },
  {
    type: 'LNB_ITEM',
    path: '/archived',
    display: {
      text: 'Archived',
      icon: <MDSIcon.Archive variant="outline" />,
    },
  },
  {
    type: 'LNB_ITEM',
    path: '/seller',
    display: {
      text: 'Seller Analytics',
      icon: <MDSIcon.Sellers />,
      dividerTop: true,
    },
  },
  {
    type: 'LNB_ITEM',
    path: '/seller/lab',
    display: {
      text: 'Seller Intel',
      icon: <MDSIcon.ClusterHubSellerIntel variant="fill" />,
    },
  },
  {
    type: 'LNB_ITEM',
    path: '/price-monitoring',
    display: {
      text: 'Price Monitoring',
      icon: <MDSIcon.SearchTrend />,
      dividerTop: true,
    },
  },
];

export const getAllowedRoutes = async (routes: LNBConfig[]): Promise<LNBConfig[]> => {
  return (
    await Promise.all(
      routes.map(async (route) => {
        if (route.type === 'LNB_ITEM_GROUP') {
          const filteredItems = await getAllowedRoutes(route.items);
          return filteredItems.length > 0 ? { ...route, items: filteredItems } : undefined;
        } else {
          switch (route.path) {
            case '/home':
              return (await PermissionMap.Home()) ? route : undefined;
            case '/performance':
              return (await PermissionMap.Performance()) ? route : undefined;
            case '/performance-reports':
              return (await PermissionMap.PerformanceReport()) ? route : undefined;
            case '/assets':
              return (await PermissionMap.PortfolioAssets()) ? route : undefined;
            case '/library':
              return (await PermissionMap.PortfolioInfringingImages()) ? route : undefined;
            case '/authorized-sellers':
              return (await PermissionMap.PortfolioAuthorizedSellers()) ? route : undefined;
            case '/archived':
              return (await PermissionMap.Archived()) ? route : undefined;
            case '/seller':
            case '/seller/lab':
              return (await PermissionMap.Seller()) ? route : undefined;
            case '/price-monitoring':
              return (await PermissionMap.PriceMonitoring()) ? route : undefined;
            default:
              return route;
          }
        }
      })
    )
  ).filter((route) => route !== undefined) as LNBConfig[];
};
