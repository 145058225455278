import { atom } from 'jotai';
import { SettingsProtocolsGetProfileResponse } from '~/swagger/data-contracts';

interface IClientChipFilterAtom {
  sellerNameList: string[];
  includeExpired: boolean;
  domainType: string;
}

export type AmplitudeProperties = {
  Product: string;
  By: 'client' | 'admin';
  'Client Id'?: number;
  'Client Name'?: string;
  'Brand Name'?: string | string[];
  User?: string;
};

export const clientFilterChipAtom = atom<IClientChipFilterAtom>({
  sellerNameList: [],
  includeExpired: false,
  domainType: '',
});

export const userProfileAtom = atom<SettingsProtocolsGetProfileResponse | undefined>(undefined);

export const amplitudeProperties = atom<AmplitudeProperties>({
  Product: 'counterfeit',
  By: 'client',
});

export const scrollContainerRefAtom = atom<null | HTMLDivElement>(null);

export const sellerIntelTransliterationAtom = atom(false);
