import { CSSProperties } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import MDSTheme from './Theme';

const useStyles = makeStyles(() => ({
  base: {
    display: 'block',
  },
  intensityDefault: {
    backgroundColor: MDSTheme.palette.bluegray[150],
  },
  intensityStrong: {
    backgroundColor: MDSTheme.palette.bluegray[200],
  },
  intensityWeak: {
    backgroundColor: MDSTheme.palette.bluegray[100],
  },
  orientationHorizontal: {
    width: ({ length }: MDSDividerProps) => length,
    height: ({ thickness }: MDSDividerProps) => thickness,
  },
  orientationVertical: {
    width: ({ thickness }: MDSDividerProps) => thickness,
    height: ({ length }: MDSDividerProps) => length,
  },

  dot: ({ thickness, orientation }: MDSDividerProps) => ({
    borderRadius: '50%',
    width: thickness,
    height: thickness,
    ...(orientation === 'horizontal'
      ? { marginLeft: '50%', transform: 'translateX(-50%)' }
      : { display: 'inline-block', verticalAlign: 'middle' }),
  }),
  intensityDot: {
    backgroundColor: MDSTheme.palette.bluegray[400],
  },
}));

export type MDSDividerProps = {
  /**
   * Divider의 모양을 나타냅니다.
   * @default 'line'
   */
  variant?: 'line' | 'dot'; // default: line

  /**
   * variant=line인 경우 Divider의 강도를 나타냅니다. 이 값에 따라 색상이 변합니다.
   * variant=dot인 경우에는 bluegray[400]으로 고정됩니다.
   * @default 'default'
   */
  intensity?: 'default' | 'strong' | 'weak';
  /**
   * variant=line인 경우 선의 방향을 나타냅니다.
   * @default 'horizontal'
   */
  orientation?: 'horizontal' | 'vertical';
  /**
   * variant=line인 경우 선의 길이를 나타냅니다. px단위와 %단위를 사용할 수 있습니다.
   * @default '100%''
   */
  length?: number | string;

  /**
   * variant=line인 경우 선의 두께를 나타냅니다.
   * shpe=dot인 경우 점의 크기를 나타냅니다.
   * @default 1
   */
  thickness?: number; // default: 1
  shape?: 'line' | 'dot'; // default: line
  style?: CSSProperties;
};
export const MDSDivider = ({
  intensity = 'default',
  orientation = 'horizontal',
  length = '100%',
  thickness = 1,
  variant = 'line',
  style,
}: MDSDividerProps) => {
  const classes = useStyles({ length, thickness, orientation });

  const variantClass =
    intensity === 'default'
      ? classes.intensityDefault
      : intensity === 'strong'
      ? classes.intensityStrong
      : classes.intensityWeak;
  const orientationClass = orientation === 'horizontal' ? classes.orientationHorizontal : classes.orientationVertical;

  const variantLineClasses = clsx([classes.base, variantClass, orientationClass]);
  const variantDotClasses = clsx([classes.base, classes.dot, classes.intensityDot]);

  return <div className={variant === 'line' ? variantLineClasses : variantDotClasses} role="separator" style={style} />;
};
