import { useEffect, useState } from 'react';
import { IDropDownItem } from '@marqvision/mds/core/DropDown';
import { useGetAllOIQuery } from '~/apis/common';
import { MarqCommonEnumsSortedOrderEnum } from '~/swagger/data-contracts';
import { useGetFilterParams, usePathParams } from '~/components/Filters/@hooks';
import { mergePages, isEqualArrays } from '~/utils';
import {
  parseFeaturesFromFilterDropDown,
  parseFeaturesFromFilterQueryParam,
  parseFilterQueryParamFromFeatures,
  getObjectKeyDiff,
  getLogArchivedStatusTitle,
} from '~/components/Filters/@utils';
import { useLogger } from '~/hooks';

const useFeaturesOptions = () => {
  const [oiSearchQuery, setOiSearchQuery] = useState<string>('');
  const [list, setList] = useState<IDropDownItem[]>([]);
  const [selectedList, setSelectedList] = useState<(string | number)[]>([]);
  const { params, requestFilterParams, setParams, removeParams } = useGetFilterParams();
  const { page, status } = usePathParams();
  const { amplitudeLogging } = useLogger();

  const oiQuery = useGetAllOIQuery({
    ...requestFilterParams,
    size: 30,
    search_after: [],
    in_search_keyword_filter: oiSearchQuery,
    sorted_order: MarqCommonEnumsSortedOrderEnum.Asc,
  });

  const handleLogging = (type: string) => {
    const eventProperties = {
      Type: type,
      On: `${page}`,
      ...(page === 'archived' && { Tab: `${getLogArchivedStatusTitle(status)}` }),
    };
    amplitudeLogging('applied filter', eventProperties);
  };

  useEffect(() => {
    setList(parseFeaturesFromFilterDropDown(mergePages(oiQuery?.data?.pages)));
  }, [oiQuery.data]);

  useEffect(() => {
    const { tm_logo, tm_text, infringing_images_filter, ois } = params;
    setSelectedList(parseFeaturesFromFilterQueryParam({ tm_logo, tm_text, infringing_images_filter, ois }, list));
  }, [list, params.tm_logo, params.tm_text, params.infringing_images_filter, params.ois]);

  const handleSelect = (value: (string | number)[]) => {
    const { tm_logo, tm_text, infringing_images_filter, ois } = params;
    if (!isEqualArrays(selectedList, value)) {
      const filterQuery = parseFilterQueryParamFromFeatures(value, list);
      const diff = getObjectKeyDiff({ tm_logo, tm_text, infringing_images_filter, ois: ois || [] }, filterQuery);
      diff.map((key) => handleLogging(key));
      removeParams(['tm_logo', 'tm_text', 'infringing_images_filter', 'ois']);
      if (value.length > 0) {
        setParams(filterQuery, { replace: true });
      }
    }
  };
  const handleSearch = (value: string) => {
    if (value.trim() === '') {
      setOiSearchQuery('');
    } else {
      setOiSearchQuery(value);
    }
  };
  const handleFetchNextPage = () => {
    if (!oiQuery.isFetching && !oiQuery.isFetchingNextPage) {
      oiQuery.fetchNextPage();
    }
  };

  return {
    isFetching: false,
    list,
    selected: selectedList,
    disabled: false,
    select: handleSelect,
    search: handleSearch,
    fetchNextPage: handleFetchNextPage,
  };
};

export default useFeaturesOptions;
