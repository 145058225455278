import React, { CSSProperties, SyntheticEvent, useState } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MDSIcon } from '@marqvision/mds-v2';
import { IcoErrorWarningFill } from '../assets';
import MDSTheme from './Theme';
import MDSTypography from './Typography';
import MDSButton from './Button';

const useStyles = makeStyles(() => ({
  selectBox: {
    width: '100%',
    overflow: 'hidden',
    '& .MuiButton-label': {
      justifyContent: 'space-between',
    },
  },
  helperTextIcon: {
    marginLeft: '4.5px',
    marginRight: '5px',
    width: '12px',
    height: '12px',
    '& > path': {
      fill: MDSTheme.palette.red[600],
    },
  },
  disabled: {
    backgroundColor: `${MDSTheme.palette.bluegray['100']} !important`,
    borderColor: MDSTheme.palette.bluegray[200],
  },
  focused: {
    borderColor: `${MDSTheme.palette.blue[700]} !important`,
  },
  error: {
    borderColor: `${MDSTheme.palette.red[600]} !important`,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
}));

export interface ISelectValue {
  value: string | number;
  label: string;
  color?: keyof typeof MDSTheme.palette;
}

export interface ISelectProps {
  size?: 'small' | 'medium' | 'large' | 'extra_large';
  error?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  helperText?: string;
  active?: boolean;
  value?: ISelectValue | ISelectValue[];
  renderValue?: ((value: ISelectValue) => React.ReactNode) | ((value: ISelectValue[]) => React.ReactNode);
  placeholder?: string;
  className?: string;
  placeholderColor?: string;
  style?: CSSProperties;
  typographyStyle?: CSSProperties;
  onClick?: (event: SyntheticEvent) => void;
  onFocus?: (event: SyntheticEvent) => void;
  onBlur?: (event: SyntheticEvent) => void;
  onKeydown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onClear?: () => void;
}

export const MDSSelect = (props: ISelectProps): JSX.Element => {
  const classes = useStyles();
  const {
    size = 'small',
    placeholder = '',
    active,
    error,
    helperText,
    readOnly,
    disabled,
    value,
    className,
    renderValue,
    placeholderColor,
    style,
    typographyStyle,
    onClick,
    onFocus,
    onBlur,
    onKeydown,
    onClear,
  } = props;

  const [focused, setFocused] = useState<boolean>(false);

  const handleInputFocus = (event: React.SyntheticEvent): void => {
    setFocused(true);
    onFocus?.(event);
  };

  const handleInputBlur = (event: React.SyntheticEvent): void => {
    setFocused(false);
    onBlur?.(event);
  };

  const handleClear = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    onClear?.();
  };

  const getLabelWithColor = (): [string, string] => {
    const defaultColor = disabled ? MDSTheme.palette.bluegray[300] : MDSTheme.palette.bluegray[900];

    if (Array.isArray(value) && value.length > 0) {
      return [value.map(({ label }) => label).join(', '), defaultColor];
    }

    if (!Array.isArray(value) && value) {
      return [value.label, defaultColor];
    }

    return [placeholder, placeholderColor || MDSTheme.palette.bluegray[300]];
  };

  const [label, color] = getLabelWithColor();
  const hasValue = (Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value);

  return (
    <div
      className={className}
      style={style}
      onClick={!disabled && !readOnly ? onClick : undefined}
      onKeyDown={onKeydown}
    >
      <MDSButton
        className={clsx(classes.selectBox, {
          [classes.disabled]: disabled || readOnly,
          [classes.focused]: focused && !readOnly,
          [classes.error]: error,
        })}
        variant="outline"
        color={active ? 'blue' : 'bluegray'}
        size={size}
        endIcon={
          <div className={classes.iconWrapper}>
            {!!onClear && !!value && <MDSIcon.CloseDelete variant="border" onClick={handleClear} />}
            <MDSIcon.ArrowDown variant="outline" />
          </div>
        }
        disabled={disabled || readOnly}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      >
        {renderValue && hasValue ? (
          renderValue(value as any)
        ) : (
          <MDSTypography
            variant={size === 'small' ? 'T13' : size === 'medium' ? 'T14' : size === 'large' ? 'T16' : 'T20'}
            weight="regular"
            style={{
              overflow: 'hidden',
              color,
              ...typographyStyle,
            }}
          >
            {label}
          </MDSTypography>
        )}
      </MDSButton>
      {error && helperText && (
        <Box width="100%" display="flex" alignItems="center" marginTop="4px">
          <IcoErrorWarningFill className={classes.helperTextIcon} />
          <MDSTypography style={{ color: MDSTheme.palette.red[700] }} variant="T12" weight="medium">
            {helperText}
          </MDSTypography>
        </Box>
      )}
    </div>
  );
};

export default MDSSelect;
