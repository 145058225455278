import { useLocation } from 'react-router-dom';
import { MDSToastSnackbar } from '@marqvision/mds/core';
import { useLogger } from '~/hooks';
import { TabEnum } from '~/types/websiteImpersonation';
import { useAddAuthorizedSellerMutation } from '~/apis/portfolio/authorizedSeller';
import { useAddAuthorizedWebsiteMutation } from '~/apis/portfolio/authorizedWebsite';
import { getAddSuccessMessage, logAddSuccessEventName } from '~/pages/Portfolio/AuthorizedSeller/@constants';
import { useDetectedWebSiteAuthorizedMutation } from '~/apis/detection/websiteImpersonation';

export const useAddAuthorizedUrl = (tab: TabEnum) => {
  const { amplitudeLogging } = useLogger();
  const { pathname } = useLocation();
  const addAuthorizedSellerMutation = useAddAuthorizedSellerMutation();
  const addAuthorizedWebsiteMutation = useAddAuthorizedWebsiteMutation();
  const detectedWebSiteAuthorizedMutation = useDetectedWebSiteAuthorizedMutation();

  const isAuthorizedSellerPage = pathname.includes('/authorized-sellers');
  const handleAddSuccess = (list: string[] | number[]) => {
    amplitudeLogging(logAddSuccessEventName[tab], {
      from: isAuthorizedSellerPage ? 'new' : 'detection',
      path: pathname,
    });

    MDSToastSnackbar({
      type: 'succeed',
      title: getAddSuccessMessage(tab, list),
    });
  };
  const handleAdd = async (list: string[]) => {
    if (tab === TabEnum.EcommerceAndSocialMedia) {
      await addAuthorizedSellerMutation.mutateAsync(
        { add_url_list: list },
        {
          onSuccess: () => {
            handleAddSuccess(list);
          },
        }
      );
    } else {
      await addAuthorizedWebsiteMutation.mutateAsync(
        { add_url_list: list },
        {
          onSuccess: () => {
            handleAddSuccess(list);
          },
        }
      );
    }
  };
  const handleAddById = async (list: number[]) => {
    await detectedWebSiteAuthorizedMutation.mutateAsync(
      { website_id_list: list },
      {
        onSuccess: () => {
          handleAddSuccess(list);
        },
      }
    );
  };
  return {
    isLoading: addAuthorizedSellerMutation.isLoading || addAuthorizedWebsiteMutation.isLoading,
    onAdd: handleAdd,
    onAddById: handleAddById,
  };
};

export type UseAddAuthorizedUrl = ReturnType<typeof useAddAuthorizedUrl>;
