/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  InfringingImageProtocolsCreateInfringingImageLibraryForAssetByPresignedRequest,
  InfringingImageProtocolsGetAllAssetsResponse,
  InfringingImageProtocolsGetAllInfringingImagesRequest,
  InfringingImageProtocolsGetAllInfringingImagesResponse,
  InfringingImageProtocolsGetInfringingImagePresignedUrlRequest,
  InfringingImageProtocolsGetInfringingImagePresignedUrlResponse,
  InfringingImageProtocolsGetInfringingImagesForAssetRequest,
  InfringingImageProtocolsGetInfringingImagesForAssetResponse,
  InfringingImageProtocolsGetIpDocumentsRequest,
  InfringingImageProtocolsGetIpDocumentsResponse,
  InfringingImageProtocolsGetIpDocumentsUniqueNameRequest,
  InfringingImageProtocolsGetIpDocumentsUniqueNameResponse,
  InfringingImageProtocolsUpdateInfringingImageLibraryForAssetByPresignedRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class InfringingImage<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags InfringingImage
   * @name GetAllAssetIdsAndNamesList
   * @request GET:/infringing-image/get_all_asset_ids_and_names
   */
  getAllAssetIdsAndNamesList = (params: RequestParams = {}) =>
    this.http.request<InfringingImageProtocolsGetAllAssetsResponse, any>({
      path: `/infringing-image/get_all_asset_ids_and_names`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags InfringingImage
   * @name PresignedCreate
   * @request POST:/infringing-image/presigned
   */
  presignedCreate = (data: InfringingImageProtocolsGetInfringingImagePresignedUrlRequest, params: RequestParams = {}) =>
    this.http.request<InfringingImageProtocolsGetInfringingImagePresignedUrlResponse, any>({
      path: `/infringing-image/presigned`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags InfringingImage
   * @name AllInfringingImagesCreate
   * @request POST:/infringing-image/all_infringing_images
   */
  allInfringingImagesCreate = (
    data: InfringingImageProtocolsGetAllInfringingImagesRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<InfringingImageProtocolsGetAllInfringingImagesResponse, any>({
      path: `/infringing-image/all_infringing_images`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags InfringingImage
   * @name InfringingImagesForAssetNewCreate
   * @request POST:/infringing-image/infringing_images_for_asset_new
   */
  infringingImagesForAssetNewCreate = (
    data: InfringingImageProtocolsGetInfringingImagesForAssetRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<InfringingImageProtocolsGetInfringingImagesForAssetResponse, any>({
      path: `/infringing-image/infringing_images_for_asset_new`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags InfringingImage
   * @name GetIpDocumentsUniqueCreate
   * @request POST:/infringing-image/get_ip_documents_unique
   */
  getIpDocumentsUniqueCreate = (
    data: InfringingImageProtocolsGetIpDocumentsUniqueNameRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<InfringingImageProtocolsGetIpDocumentsUniqueNameResponse, any>({
      path: `/infringing-image/get_ip_documents_unique`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags InfringingImage
   * @name GetIpDocumentsCreate
   * @request POST:/infringing-image/get-ip-documents
   */
  getIpDocumentsCreate = (data: InfringingImageProtocolsGetIpDocumentsRequest, params: RequestParams = {}) =>
    this.http.request<InfringingImageProtocolsGetIpDocumentsResponse, any>({
      path: `/infringing-image/get-ip-documents`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags InfringingImage
   * @name CreateInfringingImageLibraryForAssetPresignedCreate
   * @request POST:/infringing-image/create_infringing_image_library_for_asset_presigned
   */
  createInfringingImageLibraryForAssetPresignedCreate = (
    data: InfringingImageProtocolsCreateInfringingImageLibraryForAssetByPresignedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/infringing-image/create_infringing_image_library_for_asset_presigned`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags InfringingImage
   * @name UpdateInfringingImageLibraryForAssetPresignedCreate
   * @request POST:/infringing-image/update_infringing_image_library_for_asset_presigned
   */
  updateInfringingImageLibraryForAssetPresignedCreate = (
    data: InfringingImageProtocolsUpdateInfringingImageLibraryForAssetByPresignedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/infringing-image/update_infringing_image_library_for_asset_presigned`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
