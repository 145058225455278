/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MapMapModelCountryProtocolsExcludeListingOptionRequest,
  MapMapModelCountryProtocolsGetFilterInfoDomainRequest,
  MapMapModelCountryProtocolsGetInfoRequest,
  MapMapModelCountryProtocolsGetInfoResponse,
  MapMapModelCountryProtocolsGetPriceHistoryRequest,
  MapMapModelCountryProtocolsGetPriceHistoryResponse,
  MapMapModelCountryProtocolsGetViolationAnalysisRequest,
  MapMapModelCountryProtocolsGetViolationAnalysisResponse,
  MapMapModelCountryProtocolsGetViolationListingsRequest,
  MapMapModelCountryProtocolsGetViolationListingsResponse,
  MapMapModelProtocolsGetModelInfoRequest,
  MapMapModelProtocolsGetModelInfoResponse,
  MapMapProtocolsGetFilterInfoCountryRequest,
  MapMapProtocolsGetFilterInfoModelRequest,
  MapMapProtocolsGetMapCountRequest,
  MapMapProtocolsGetMapCountResponse,
  MapMapProtocolsGetMapListRequest,
  MapMapProtocolsGetMapListResponse,
  MonitoringFilterProtocolResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Map<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Map
   * @name GetAllModelsCreate
   * @request POST:/map/get-all-models
   */
  getAllModelsCreate = (data: MapMapProtocolsGetFilterInfoModelRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/map/get-all-models`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name GetAllCountriesCreate
   * @request POST:/map/get-all-countries
   */
  getAllCountriesCreate = (data: MapMapProtocolsGetFilterInfoCountryRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/map/get-all-countries`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name ListCreate
   * @request POST:/map/list
   */
  listCreate = (data: MapMapProtocolsGetMapListRequest, params: RequestParams = {}) =>
    this.http.request<MapMapProtocolsGetMapListResponse, any>({
      path: `/map/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name CountCreate
   * @request POST:/map/count
   */
  countCreate = (data: MapMapProtocolsGetMapCountRequest, params: RequestParams = {}) =>
    this.http.request<MapMapProtocolsGetMapCountResponse, any>({
      path: `/map/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name ModelCreate
   * @request POST:/map/model
   */
  modelCreate = (data: MapMapModelProtocolsGetModelInfoRequest, params: RequestParams = {}) =>
    this.http.request<MapMapModelProtocolsGetModelInfoResponse, any>({
      path: `/map/model`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name ModelCountryGetAllDomainsCreate
   * @request POST:/map/model/country/get-all-domains
   */
  modelCountryGetAllDomainsCreate = (
    data: MapMapModelCountryProtocolsGetFilterInfoDomainRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/map/model/country/get-all-domains`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name ModelCountryCreate
   * @request POST:/map/model/country
   */
  modelCountryCreate = (data: MapMapModelCountryProtocolsGetInfoRequest, params: RequestParams = {}) =>
    this.http.request<MapMapModelCountryProtocolsGetInfoResponse, any>({
      path: `/map/model/country`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name ModelCountryViolationListingsCreate
   * @request POST:/map/model/country/violation-listings
   */
  modelCountryViolationListingsCreate = (
    data: MapMapModelCountryProtocolsGetViolationListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MapMapModelCountryProtocolsGetViolationListingsResponse, any>({
      path: `/map/model/country/violation-listings`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name ModelCountryPriceHistoryCreate
   * @request POST:/map/model/country/price-history
   */
  modelCountryPriceHistoryCreate = (
    data: MapMapModelCountryProtocolsGetPriceHistoryRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MapMapModelCountryProtocolsGetPriceHistoryResponse, any>({
      path: `/map/model/country/price-history`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name ModelCountryViolationAnalysisCreate
   * @request POST:/map/model/country/violation-analysis
   */
  modelCountryViolationAnalysisCreate = (
    data: MapMapModelCountryProtocolsGetViolationAnalysisRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MapMapModelCountryProtocolsGetViolationAnalysisResponse, any>({
      path: `/map/model/country/violation-analysis`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Map
   * @name ModelCountryViolationListingsExcludeCreate
   * @request POST:/map/model/country/violation-listings/exclude
   */
  modelCountryViolationListingsExcludeCreate = (
    data: MapMapModelCountryProtocolsExcludeListingOptionRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/map/model/country/violation-listings/exclude`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
