import clsx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { MDSChip } from '@marqvision/mds/core';
import { IcoDownload } from '@marqvision/mds/assets';

const useStyles = makeStyles(() => ({
  dim: {
    display: 'none',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    gap: '8px',
  },
  dimChipButton: {
    cursor: 'pointer',
  },
}));

interface IOwnProps {
  onPreview: () => void;
  onDownload: () => void;
  language?: 'ko' | 'en';
}

const LocalizedString = {
  en: {
    Preview: 'Preview',
    Download: 'Download',
  },
  ko: {
    Preview: '미리보기',
    Download: '다운로드',
  },
} as const;

const RequestImageDim = ({ onPreview, onDownload, language = 'en' }: IOwnProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.dim, 'dimButtons')}>
      <MDSChip
        onClick={onPreview}
        label={LocalizedString[language].Preview}
        variant="outline"
        color="bluegray"
        size="medium"
      />
      <MDSChip
        onClick={onDownload}
        className={classes.dimChipButton}
        label={LocalizedString[language].Download}
        variant="outline"
        color="bluegray"
        size="medium"
        icon={<IcoDownload />}
      />
    </div>
  );
};

export default RequestImageDim;
