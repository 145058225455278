import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  boundingBoxWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
  },
  boundingBox: {
    boxSizing: 'content-box',
    display: 'block',
    position: 'absolute',
    borderStyle: 'solid',
    borderImageSlice: 1,
    borderImageSource: 'linear-gradient(to right bottom, #3EFFC5, #306AFF)',
    animation: '$rotate 2s linear infinite',
    pointerEvents: 'none',
  },
  '@keyframes rotate': {
    '0%': {
      borderImageSource: 'linear-gradient(to right bottom, #3EFFC5, #306AFF)',
    },
    '25%': {
      borderImageSource: 'linear-gradient(to right top, #3EFFC5, #306AFF)',
    },
    '50%': {
      borderImageSource: 'linear-gradient(to left top, #3EFFC5, #306AFF)',
    },
    '75%': {
      borderImageSource: 'linear-gradient(to left bottom, #3EFFC5, #306AFF)',
    },
    '100%': {
      borderImageSource: 'linear-gradient(to right bottom, #3EFFC5, #306AFF)',
    },
  },
}));

type LogoDetectionData = {
  bb_x1?: number | null;
  bb_x2?: number | null;
  bb_y1?: number | null;
  bb_y2?: number | null;
  image_height?: number | null;
  image_width?: number | null;
};

const createBoundingWrapperData = (logoDetectionData: LogoDetectionData): React.CSSProperties => {
  const imageWidth = logoDetectionData.image_width ?? 0;
  const imageHeight = logoDetectionData.image_height ?? 0;

  return {
    aspectRatio: `${imageWidth} / ${imageHeight}`,
    width: imageWidth >= imageHeight ? '100%' : 'unset',
    height: imageHeight > imageWidth ? '100%' : 'unset',
  };
};

const createBoundingBoxData = (logoDetectionData: LogoDetectionData, borderWidth?: number): React.CSSProperties => {
  const x1 = logoDetectionData.bb_x1 ?? 0;
  const x2 = logoDetectionData.bb_x2 ?? 0;
  const y1 = logoDetectionData.bb_y1 ?? 0;
  const y2 = logoDetectionData.bb_y2 ?? 0;
  const imageWidth = logoDetectionData.image_width ?? 0;
  const imageHeight = logoDetectionData.image_height ?? 0;

  const aspectRatio = `${x2 - x1} / ${y2 - y1}`;
  const top = `${(y1 / imageHeight) * 100}%`;
  const left = `${(x1 / imageWidth) * 100}%`;
  const height = `${((y2 - y1) / imageHeight) * 100}%`;
  const width = `${((x2 - x1) / imageWidth) * 100}%`;

  return {
    aspectRatio,
    borderWidth: `${borderWidth}px`,
    top: `calc(${top} - ${borderWidth}px)`,
    left: `calc(${left} - ${borderWidth}px)`,
    height,
    width,
  };
};

type Props = {
  detectionData: LogoDetectionData;
  className?: string;
  borderWidth?: number;
};

export const BoundingBox = (props: Props): React.ReactNode => {
  const { detectionData, className, borderWidth = 4 } = props;
  const classes = useStyles();

  return (
    <div className={classes.boundingBoxWrapper} style={createBoundingWrapperData(detectionData)}>
      <div className={clsx(classes.boundingBox, className)} style={createBoundingBoxData(detectionData, borderWidth)} />
    </div>
  );
};
