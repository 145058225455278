/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MonitoringFilterProtocolResponse,
  SellerSellerAnalyticDetailEnforcedListingProtocolsExportListingStatusListRequest,
  SellerSellerAnalyticDetailEnforcedListingProtocolsExportListingStatusListResponse,
  SellerSellerAnalyticDetailEnforcedListingProtocolsExportProgressListingStatusListRequest,
  SellerSellerAnalyticDetailEnforcedListingProtocolsExportProgressListingStatusListResponse,
  SellerSellerAnalyticDetailEnforcedListingProtocolsGetEnforcedListingListRequest,
  SellerSellerAnalyticDetailEnforcedListingProtocolsGetEnforcedListingListResponse,
  SellerSellerAnalyticDetailEnforcedListingProtocolsGetFilterInfoDomainRequest,
  SellerSellerAnalyticDetailEnforcedListingProtocolsGetFilterInfoSellerRequest,
  SellerSellerAnalyticDetailEnforcedListingProtocolsGetFilterInfoStatusRequest,
  SellerSellerAnalyticDetailEnforcedListingProtocolsGetListingStatusCountRequest,
  SellerSellerAnalyticDetailEnforcedListingProtocolsGetListingStatusCountResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SellerAnalyticsDetailEnforced<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SellerAnalyticsDetailEnforced
   * @name DetailListingStatusEnforcedFilterDomainCreate
   * @request POST:/seller-analytic/detail/listing-status/enforced/filter/domain
   */
  detailListingStatusEnforcedFilterDomainCreate = (
    data: SellerSellerAnalyticDetailEnforcedListingProtocolsGetFilterInfoDomainRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/detail/listing-status/enforced/filter/domain`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailEnforced
   * @name DetailListingStatusEnforcedFilterSellerCreate
   * @request POST:/seller-analytic/detail/listing-status/enforced/filter/seller
   */
  detailListingStatusEnforcedFilterSellerCreate = (
    data: SellerSellerAnalyticDetailEnforcedListingProtocolsGetFilterInfoSellerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/detail/listing-status/enforced/filter/seller`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailEnforced
   * @name DetailListingStatusEnforcedFilterStatusCreate
   * @request POST:/seller-analytic/detail/listing-status/enforced/filter/status
   */
  detailListingStatusEnforcedFilterStatusCreate = (
    data: SellerSellerAnalyticDetailEnforcedListingProtocolsGetFilterInfoStatusRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/detail/listing-status/enforced/filter/status`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailEnforced
   * @name DetailListingStatusEnforcedListCreate
   * @request POST:/seller-analytic/detail/listing-status/enforced/list
   */
  detailListingStatusEnforcedListCreate = (
    data: SellerSellerAnalyticDetailEnforcedListingProtocolsGetEnforcedListingListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailEnforcedListingProtocolsGetEnforcedListingListResponse, any>({
      path: `/seller-analytic/detail/listing-status/enforced/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailEnforced
   * @name DetailListingStatusEnforcedCountCreate
   * @request POST:/seller-analytic/detail/listing-status/enforced/count
   */
  detailListingStatusEnforcedCountCreate = (
    data: SellerSellerAnalyticDetailEnforcedListingProtocolsGetListingStatusCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailEnforcedListingProtocolsGetListingStatusCountResponse, any>({
      path: `/seller-analytic/detail/listing-status/enforced/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailEnforced
   * @name DetailListingStatusEnforcedExportProgressCreate
   * @request POST:/seller-analytic/detail/listing-status/enforced/export/progress
   */
  detailListingStatusEnforcedExportProgressCreate = (
    data: SellerSellerAnalyticDetailEnforcedListingProtocolsExportProgressListingStatusListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailEnforcedListingProtocolsExportProgressListingStatusListResponse, any>({
      path: `/seller-analytic/detail/listing-status/enforced/export/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDetailEnforced
   * @name DetailListingStatusEnforcedExportCreate
   * @request POST:/seller-analytic/detail/listing-status/enforced/export
   */
  detailListingStatusEnforcedExportCreate = (
    data: SellerSellerAnalyticDetailEnforcedListingProtocolsExportListingStatusListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticDetailEnforcedListingProtocolsExportListingStatusListResponse, any>({
      path: `/seller-analytic/detail/listing-status/enforced/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
