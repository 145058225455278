import React, { JSX, ReactNode } from 'react';
import clsx from 'clsx';
import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IcoArrowRightOutline } from '../assets';
import MDSTheme from './Theme';
import MDSTypography from './Typography';

type StyleProps = Pick<OwnProps, 'size' | 'color'>;
const useStyles = makeStyles(() => ({
  label: {
    display: 'inline-flex',
  },
  tooltip: ({ size, color }) => ({
    ...MDSTheme.typography.regular,
    maxWidth: 'max-content',
    fontSize: '14px',
    whiteSpace: 'pre-line',
    gap: '10px',
    borderRadius: '4px',
    padding: size === 'small' ? '4px 8px' : '12px 16px',
    backgroundColor: color === 'white' ? MDSTheme.palette.white : MDSTheme.palette.bluegray[900],
    boxShadow: color === 'white' ? '0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 8px rgba(0, 0, 0, 0.12)' : undefined,
  }),
  arrow: ({ size, color }) => ({
    fontSize: '10px',
    color:
      color === 'white'
        ? MDSTheme.palette.white // 기존 white prop 대응
        : MDSTheme.palette.bluegray[900], // default color
    display: size === 'small' ? 'none' : undefined,
  }),
  tooltipPlacementTop: {
    marginBottom: ({ size }) => (size === 'small' ? 0 : '8px'),
  },
  tooltipPlacementBottom: {
    marginTop: ({ size }) => (size === 'small' ? 0 : '8px'),
  },
  tooltipPlacementLeft: {
    marginRight: ({ size }) => (size === 'small' ? 0 : '8px'),
  },
  tooltipPlacementRight: {
    marginLeft: ({ size }) => (size === 'small' ? 0 : '8px'),
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '8px',
  },
  title: {
    color: (props: StyleProps) => (props.color === 'white' ? MDSTheme.palette.bluegray[800] : MDSTheme.palette.white),
    whiteSpace: 'pre-line',
  },
  icon: {
    marginRight: '-8px',
    '& path': {
      fill: MDSTheme.palette.white,
    },
  },
}));

type OwnProps = {
  width?: string;
  size?: 'small' | 'medium';
  clickIcon?: ReactNode;
  onClick?: () => void;
  color?: 'white';
};

type Props = React.PropsWithChildren<OwnProps> & TooltipProps;

export const MDSTooltip = (props: Props): JSX.Element => {
  const classes = useStyles({ size: props.size, color: props.color });
  const { size = 'medium', placement = 'top', title, width = '240px', clickIcon, onClick, ...restProps } = props;

  const renderTitle = (): NonNullable<React.ReactNode> => {
    return (
      <div className={classes.titleWrapper} onClick={onClick} style={{ maxWidth: width }}>
        <MDSTypography variant={size === 'small' ? 'T12' : 'T14'} className={classes.title}>
          {title}
        </MDSTypography>
        {onClick && <IconButton className={classes.icon}>{clickIcon || <IcoArrowRightOutline />}</IconButton>}
      </div>
    );
  };

  return (
    <MuiTooltip
      {...restProps}
      arrow={true}
      placement={placement}
      title={renderTitle()}
      classes={{
        arrow: classes.arrow,
        tooltip: classes.tooltip,
        tooltipPlacementTop: classes.tooltipPlacementTop,
        tooltipPlacementBottom: classes.tooltipPlacementBottom,
        tooltipPlacementLeft: classes.tooltipPlacementLeft,
        tooltipPlacementRight: classes.tooltipPlacementRight,
      }}
    >
      <div className={clsx(classes.label, restProps.className)}>{props.children}</div>
    </MuiTooltip>
  );
};

export default MDSTooltip;
