import { Features } from '../@types';

export const Bold = () => (
  <path
    d="M6 20V4H12.6197C13.8779 4 14.9765 4.40376 15.9155 5.21127C16.8545 6.01878 17.3239 7.04225 17.3239 8.28169C17.3239 8.9953 17.1315 9.65258 16.7465 10.2535C16.3615 10.8545 15.8404 11.3146 15.1831 11.6338V11.7746C15.9906 12.0188 16.6385 12.4836 17.1268 13.169C17.615 13.8545 17.8592 14.6291 17.8592 15.493C17.8592 16.7887 17.3568 17.8638 16.3521 18.7183C15.3474 19.5728 14.169 20 12.8169 20H6ZM8.56338 10.7324H12.4225C13.0798 10.7324 13.6432 10.5164 14.1127 10.0845C14.5822 9.65258 14.8169 9.11737 14.8169 8.47887C14.8169 7.84038 14.5822 7.30047 14.1127 6.85915C13.6432 6.41784 13.0798 6.19718 12.4225 6.19718H8.56338V10.7324ZM8.56338 17.7465H12.6479C13.3615 17.7465 13.9765 17.5117 14.493 17.0423C15.0094 16.5728 15.2676 15.9906 15.2676 15.2958C15.2676 14.6009 15.0094 14.0235 14.493 13.5634C13.9765 13.1033 13.3615 12.8732 12.6479 12.8732H8.56338V17.7465Z"
    fill="currentColor"
  />
);
export const Italic = () => (
  <path
    d="M5 20V17.3994H8.41796L12.7771 6.60062H8.91331V4H18.969V6.60062H15.5759L11.2167 17.3994H15.0557V20H5Z"
    fill="currentColor"
  />
);
export const TextFormat = () => (
  <path
    d="M5 20V18.1577H19.5615V20H5ZM6.71609 15.836L11.3596 4H13.1767L17.8202 15.836H16.1546L14.9685 12.7319H9.56782L8.40694 15.836H6.71609ZM10.0726 11.3438H14.489L12.3186 5.61514H12.2177L10.0726 11.3438Z"
    fill="currentColor"
  />
);
export const Underline = () => (
  <path
    d="M6 20V18.5055H18.4835V20H6ZM12.2418 16.4835C10.7326 16.4835 9.46154 15.9707 8.42857 14.9451C7.3956 13.9194 6.87912 12.6593 6.87912 11.1648V4H8.81319V11.2088C8.81319 12.1465 9.14286 12.9377 9.8022 13.5824C10.4615 14.2271 11.2747 14.5494 12.2418 14.5494C13.2088 14.5494 14.022 14.2271 14.6813 13.5824C15.3407 12.9377 15.6703 12.1465 15.6703 11.2088V4H17.6044V11.1648C17.6044 12.6447 17.0879 13.9011 16.0549 14.9341C15.022 15.967 13.7509 16.4835 12.2418 16.4835Z"
    fill="currentColor"
  />
);
export const Sign = () => (
  <path
    d="M9.75277 20.1479L13.1402 16.7604H19.2952C19.7675 16.7604 20.1697 16.9228 20.5018 17.2475C20.8339 17.5722 21 17.9707 21 18.4431C21 18.9301 20.8339 19.3361 20.5018 19.6608C20.1697 19.9855 19.7675 20.1479 19.2952 20.1479H9.75277ZM4.61624 18.5316H5.78967L15.5314 8.76779L14.3579 7.59435L4.61624 17.3582V18.5316ZM18.7417 7.81576L15.31 4.36188L16.2177 3.45413C16.5277 3.14417 16.9114 2.99288 17.369 3.00026C17.8266 3.00764 18.2103 3.16631 18.5203 3.47627L19.6716 4.6497C19.9816 4.95967 20.1328 5.33605 20.1255 5.77886C20.1181 6.22166 19.9594 6.59804 19.6494 6.90801L18.7417 7.81576ZM3.79705 20.1479C3.56089 20.1479 3.369 20.0704 3.2214 19.9154C3.0738 19.7604 3 19.5722 3 19.3508V17.0261C3 16.9228 3.01845 16.8231 3.05535 16.7272C3.09225 16.6313 3.1476 16.5464 3.2214 16.4726L14.4022 5.29177L17.8339 8.72351L6.67528 19.9043C6.58672 19.9929 6.49816 20.0556 6.40959 20.0925C6.32103 20.1294 6.22509 20.1479 6.12177 20.1479H3.79705ZM14.9557 8.19214L14.3579 7.59435L15.5314 8.76779L14.9557 8.19214Z"
    fill="currentColor"
  />
);

export const Bookmark = () => (
  <path
    d="M6.05 20.675C5.8 20.7917 5.5625 20.775 5.3375 20.625C5.1125 20.475 5 20.2667 5 20V4.5C5 4.1 5.15 3.75 5.45 3.45C5.75 3.15 6.1 3 6.5 3H17.5C17.9 3 18.25 3.15 18.55 3.45C18.85 3.75 19 4.1 19 4.5V20C19 20.2667 18.8875 20.475 18.6625 20.625C18.4375 20.775 18.2 20.7917 17.95 20.675L12 18.125L6.05 20.675Z"
    fill="currentColor"
  />
);
export const Connected = () => (
  <path
    d="M8.92167 20.389C7.77807 20.389 6.81854 19.9974 6.04308 19.2141C5.26762 18.4308 4.8799 17.483 4.8799 16.3708V8.4517C4.33159 8.21671 3.8812 7.86815 3.52872 7.40601C3.17624 6.94387 3 6.41514 3 5.81984C3 5.03655 3.27807 4.37076 3.8342 3.82245C4.39034 3.27415 5.05222 3 5.81984 3C6.60313 3 7.26893 3.27415 7.81723 3.82245C8.36553 4.37076 8.63969 5.03655 8.63969 5.81984C8.63969 6.41514 8.46345 6.94778 8.11097 7.41776C7.75849 7.88773 7.30809 8.23238 6.75979 8.4517V16.3708C6.75979 16.9661 6.96345 17.4713 7.37076 17.8864C7.77807 18.3016 8.29504 18.5091 8.92167 18.5091C9.5483 18.5091 10.0614 18.3016 10.4608 17.8864C10.8603 17.4713 11.0601 16.9661 11.0601 16.3708V7.01828C11.0601 5.89034 11.4478 4.93864 12.2232 4.16319C12.9987 3.38773 13.9504 3 15.0783 3C16.2219 3 17.1815 3.38773 17.9569 4.16319C18.7324 4.93864 19.1201 5.89034 19.1201 7.01828V14.9373C19.6684 15.1567 20.1188 15.5013 20.4713 15.9713C20.8238 16.4413 21 16.9739 21 17.5692C21 18.3368 20.7258 18.9987 20.1775 19.5548C19.6292 20.111 18.9634 20.389 18.1802 20.389C17.4125 20.389 16.7507 20.111 16.1945 19.5548C15.6384 18.9987 15.3603 18.3368 15.3603 17.5692C15.3603 16.9739 15.5366 16.4413 15.889 15.9713C16.2415 15.5013 16.6919 15.1567 17.2402 14.9373V7.01828C17.2402 6.40731 17.0366 5.89817 16.6292 5.49086C16.2219 5.08355 15.705 4.8799 15.0783 4.8799C14.4674 4.8799 13.9582 5.08355 13.5509 5.49086C13.1436 5.89817 12.9399 6.40731 12.9399 7.01828V16.3708C12.9399 17.483 12.5522 18.4308 11.7768 19.2141C11.0013 19.9974 10.0496 20.389 8.92167 20.389Z"
    fill="currentColor"
  />
);
export const ScheduleSend = () => (
  <path
    d="M3 18.5V5.49997C3 5.1333 3.14583 4.85414 3.4375 4.66247C3.72917 4.4708 4.04167 4.44164 4.375 4.57497L17.3 9.99997H17C16.4167 9.99997 15.8667 10.0666 15.35 10.2C14.8333 10.3333 14.3333 10.5166 13.85 10.75L5 6.99997V10.5L11 12L5 13.5V17L10.4 14.7C10.2667 15.0833 10.1667 15.4625 10.1 15.8375C10.0333 16.2125 10 16.6 10 17V17.05L4.4 19.425C4.06667 19.5583 3.75 19.5291 3.45 19.3375C3.15 19.1458 3 18.8666 3 18.5ZM17 22C15.6167 22 14.4375 21.5125 13.4625 20.5375C12.4875 19.5625 12 18.3833 12 17C12 15.6166 12.4875 14.4375 13.4625 13.4625C14.4375 12.4875 15.6167 12 17 12C18.3833 12 19.5625 12.4875 20.5375 13.4625C21.5125 14.4375 22 15.6166 22 17C22 18.3833 21.5125 19.5625 20.5375 20.5375C19.5625 21.5125 18.3833 22 17 22ZM19 19C19.1 18.9 19.15 18.7833 19.15 18.65C19.15 18.5166 19.1 18.4 19 18.3L17.5 16.8V14.5C17.5 14.3666 17.45 14.25 17.35 14.15C17.25 14.05 17.1333 14 17 14C16.8667 14 16.75 14.05 16.65 14.15C16.55 14.25 16.5 14.3666 16.5 14.5V16.9C16.5 17 16.5167 17.0916 16.55 17.175C16.5833 17.2583 16.6417 17.3416 16.725 17.425L18.3 19C18.4 19.1 18.5167 19.15 18.65 19.15C18.7833 19.15 18.9 19.1 19 19ZM5 14.7V6.99997V17V14.7Z"
    fill="currentColor"
  />
);
export const ImageNotSupported = () => (
  <path
    d="M20.475 23.3L18.175 21H5.00001C4.45001 21 3.97918 20.8041 3.58751 20.4125C3.19585 20.0208 3.00001 19.55 3.00001 19V5.82498L0.700012 3.49998L2.10001 2.09998L21.9 21.9L20.475 23.3ZM5.00001 19H16.175L14.175 17H6.00001L9.00001 13L11 15.725L11.85 14.675L5.00001 7.82498V19ZM21 18.175L19 16.175V4.99998H7.82501L5.82501 2.99998H19C19.55 2.99998 20.0208 3.19581 20.4125 3.58748C20.8042 3.97914 21 4.44998 21 4.99998V18.175Z"
    fill="currentColor"
  />
);
export const Crop = () => (
  <path
    d="M17 23V19H7C6.45 19 5.97917 18.8042 5.5875 18.4125C5.19583 18.0208 5 17.55 5 17V7H1V5H5V1H7V17H23V19H19V23H17ZM17 15V7H9V5H17C17.55 5 18.0208 5.19583 18.4125 5.5875C18.8042 5.97917 19 6.45 19 7V15H17Z"
    fill="currentColor"
  />
);
export const CropFree = () => (
  <path
    d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V15H5V19H9V21H5ZM15 21V19H19V15H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H15ZM3 9V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9V5H5V9H3ZM19 9V5H15V3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V9H19Z"
    fill="currentColor"
  />
);
export const CropSquare = () => (
  <path
    d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
    fill="currentColor"
  />
);
export const FlipX = () => (
  <path
    d="M15 21H17V19H15V21ZM19 9H21V7H19V9ZM3 5V19C3 20.1 3.9 21 5 21H9V19H5V5H9V3H5C3.9 3 3 3.9 3 5ZM19 3V5H21C21 3.9 20.1 3 19 3ZM11 23H13V1H11V23ZM19 17H21V15H19V17ZM15 5H17V3H15V5ZM19 13H21V11H19V13ZM19 21C20.1 21 21 20.1 21 19H19V21Z"
    fill="currentColor"
  />
);
export const FlipY = () => (
  <path
    d="M3 15L3 17L5 17L5 15L3 15ZM15 19L15 21L17 21L17 19L15 19ZM19 3L5 3C3.9 3 3 3.9 3 5L3 9L5 9L5 5L19 5L19 9L21 9L21 5C21 3.9 20.1 3 19 3ZM21 19L19 19L19 21C20.1 21 21 20.1 21 19ZM1 11L1 13L23 13L23 11L1 11ZM7 19L7 21L9 21L9 19L7 19ZM19 15L19 17L21 17L21 15L19 15ZM11 19L11 21L13 21L13 19L11 19ZM3 19C3 20.1 3.9 21 5 21L5 19L3 19Z"
    fill="currentColor"
  />
);
export const Rotate90CW = () => (
  <path
    d="M2 13C2 17.97 6.03 22 11 22C12.76 22 14.4 21.49 15.79 20.62L14.33 19.16C13.34 19.69 12.2 20 11 20C7.14 20 4 16.86 4 13C4 9.14 7.14 6 11 6H11.17L9.59 7.59L11 9L15 5L11 1L9.58 2.41L11.17 4H11C6.03 4 2 8.03 2 13ZM11 13L17 19L23 13L17 7L11 13ZM17 16.17L13.83 13L17 9.83L20.17 13L17 16.17Z"
    fill="currentColor"
  />
);
export const Rotate90CCW = () => (
  <path
    d="M7.34 6.41001L0.860001 12.9L7.35 19.38L13.84 12.9L7.34 6.41001ZM3.69 12.9L7.35 9.24001L11 12.9L7.34 16.56L3.69 12.9ZM19.36 6.64001C17.61 4.88001 15.3 4.00001 13 4.00001V0.76001L8.76 5.00001L13 9.24001V6.00001C14.79 6.00001 16.58 6.68001 17.95 8.05001C20.68 10.78 20.68 15.22 17.95 17.95C16.58 19.32 14.79 20 13 20C12.03 20 11.06 19.79 10.16 19.39L8.67 20.88C10.02 21.62 11.51 22 13 22C15.3 22 17.61 21.12 19.36 19.36C22.88 15.85 22.88 10.15 19.36 6.64001Z"
    fill="currentColor"
  />
);
export const Undo = () => (
  <path
    d="M7 19V17H14.1C15.15 17 16.0625 16.6667 16.8375 16C17.6125 15.3333 18 14.5 18 13.5C18 12.5 17.6125 11.6667 16.8375 11C16.0625 10.3333 15.15 10 14.1 10H7.8L10.4 12.6L9 14L4 9L9 4L10.4 5.4L7.8 8H14.1C15.7167 8 17.1042 8.525 18.2625 9.575C19.4208 10.625 20 11.9333 20 13.5C20 15.0667 19.4208 16.375 18.2625 17.425C17.1042 18.475 15.7167 19 14.1 19H7Z"
    fill="currentColor"
  />
);
export const Redo = () => (
  <path
    d="M9.9 19C8.28333 19 6.89583 18.475 5.7375 17.425C4.57917 16.375 4 15.0667 4 13.5C4 11.9333 4.57917 10.625 5.7375 9.575C6.89583 8.525 8.28333 8 9.9 8H16.2L13.6 5.4L15 4L20 9L15 14L13.6 12.6L16.2 10H9.9C8.85 10 7.9375 10.3333 7.1625 11C6.3875 11.6667 6 12.5 6 13.5C6 14.5 6.3875 15.3333 7.1625 16C7.9375 16.6667 8.85 17 9.9 17H17V19H9.9Z"
    fill="currentColor"
  />
);
// size 속성을 지정하지 않도록 해야..
export const Circle = ({ variant }: Features<'Circle'>) =>
  variant === 'outline' ? (
    <path
      d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
      fill="currentColor"
    />
  ) : variant === 'outline_small' ? (
    <path
      d="M12 20C10.8933 20 9.85333 19.79 8.88 19.37C7.90667 18.95 7.06 18.38 6.34 17.66C5.62 16.94 5.05 16.0933 4.63 15.12C4.21 14.1467 4 13.1067 4 12C4 10.8933 4.21 9.85333 4.63 8.88C5.05 7.90667 5.62 7.06 6.34 6.34C7.06 5.62 7.90667 5.05 8.88 4.63C9.85333 4.21 10.8933 4 12 4C13.1067 4 14.1467 4.21 15.12 4.63C16.0933 5.05 16.94 5.62 17.66 6.34C18.38 7.06 18.95 7.90667 19.37 8.88C19.79 9.85333 20 10.8933 20 12C20 13.1067 19.79 14.1467 19.37 15.12C18.95 16.0933 18.38 16.94 17.66 17.66C16.94 18.38 16.0933 18.95 15.12 19.37C14.1467 19.79 13.1067 20 12 20ZM12 18.4C13.7867 18.4 15.3 17.78 16.54 16.54C17.78 15.3 18.4 13.7867 18.4 12C18.4 10.2133 17.78 8.7 16.54 7.46C15.3 6.22 13.7867 5.6 12 5.6C10.2133 5.6 8.7 6.22 7.46 7.46C6.22 8.7 5.6 10.2133 5.6 12C5.6 13.7867 6.22 15.3 7.46 16.54C8.7 17.78 10.2133 18.4 12 18.4Z"
      fill="currentColor"
    />
  ) : variant === 'fill' ? (
    <circle cx="12" cy="12" r="10" fill="currentColor" />
  ) : (
    <circle cx="12" cy="12" r="8" fill="currentColor" />
  );

export const Shapes = () => (
  <path
    d="M8 17.95C8.16667 17.9667 8.32917 17.9792 8.4875 17.9875C8.64583 17.9958 8.81667 18 9 18C9.18333 18 9.35417 17.9958 9.5125 17.9875C9.67083 17.9792 9.83333 17.9667 10 17.95V20H20V10H17.95C17.9667 9.83333 17.9792 9.67083 17.9875 9.5125C17.9958 9.35417 18 9.18333 18 9C18 8.81667 17.9958 8.64583 17.9875 8.4875C17.9792 8.32917 17.9667 8.16667 17.95 8H20C20.55 8 21.0208 8.19583 21.4125 8.5875C21.8042 8.97917 22 9.45 22 10V20C22 20.55 21.8042 21.0208 21.4125 21.4125C21.0208 21.8042 20.55 22 20 22H10C9.45 22 8.97917 21.8042 8.5875 21.4125C8.19583 21.0208 8 20.55 8 20V17.95ZM9 16C7.05 16 5.39583 15.3208 4.0375 13.9625C2.67917 12.6042 2 10.95 2 9C2 7.05 2.67917 5.39583 4.0375 4.0375C5.39583 2.67917 7.05 2 9 2C10.95 2 12.6042 2.67917 13.9625 4.0375C15.3208 5.39583 16 7.05 16 9C16 10.95 15.3208 12.6042 13.9625 13.9625C12.6042 15.3208 10.95 16 9 16ZM9 14C10.3833 14 11.5625 13.5125 12.5375 12.5375C13.5125 11.5625 14 10.3833 14 9C14 7.61667 13.5125 6.4375 12.5375 5.4625C11.5625 4.4875 10.3833 4 9 4C7.61667 4 6.4375 4.4875 5.4625 5.4625C4.4875 6.4375 4 7.61667 4 9C4 10.3833 4.4875 11.5625 5.4625 12.5375C6.4375 13.5125 7.61667 14 9 14Z"
    fill="currentColor"
  />
);
export const Rectangle = () => <path d="M2 20V4H22V20H2ZM4 18H20V6H4V18Z" fill="currentColor" />;
export const Triangle = () => <path d="M2 20L12 4L22 20H2ZM5.6 18H18.4L12 7.75L5.6 18Z" fill="currentColor" />;
export const Line = () => (
  <path
    d="M5.07499 18.925C4.80832 18.6584 4.67499 18.35 4.67499 18C4.67499 17.65 4.80832 17.3417 5.07499 17.075L17.075 5.07505C17.325 4.80838 17.6292 4.67505 17.9875 4.67505C18.3458 4.67505 18.6583 4.80838 18.925 5.07505C19.1917 5.34172 19.325 5.65005 19.325 6.00005C19.325 6.35005 19.1917 6.65838 18.925 6.92505L6.92499 18.925C6.65832 19.1917 6.34999 19.325 5.99999 19.325C5.64999 19.325 5.34165 19.1917 5.07499 18.925Z"
    fill="currentColor"
  />
);

export const ShapeArrow = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M-2.07644e-07 4.75033L10.471 4.75033L10.471 -4.60239e-07L22 9.59421L10.471 19.1884L10.471 14.4381L-6.31109e-07 14.4381L-2.07644e-07 4.75033ZM12.5501 14.8076L12.5501 12.359L2.07911 12.359L2.07911 6.82945L12.5501 6.82945L12.5501 4.38084L18.712 9.59421L12.5501 14.8076Z"
    fill="currentColor"
  />
);
export const FormulaComponent = ({ variant }: Features<'FormulaComponent'>) =>
  variant === 'Ci' ? (
    <>
      <path
        d="M13.1235 3.82682C12.8287 3.71964 12.5072 3.66606 12.1589 3.66606C11.1675 3.66606 10.3369 4.04118 9.667 4.79142C8.99714 5.52826 8.50814 6.46606 8.20001 7.60482C7.89187 8.74358 7.7512 9.89573 7.778 11.0613C7.83159 12.7493 8.07273 14.0154 8.50144 14.8594C8.94355 15.69 9.6335 16.1053 10.5713 16.1053C11.4957 16.1053 12.2125 15.8642 12.7215 15.3819C12.9761 15.0603 13.2373 14.3972 13.5053 13.3924H15.4345L15.0125 16.2058C14.4766 16.6211 13.8335 16.9694 13.0833 17.2508C12.333 17.5321 11.3215 17.6728 10.0488 17.6728C7.97226 17.6728 6.44498 17.0766 5.46699 15.8843C4.50239 14.6919 4.0134 13.1512 4 11.2622C4 9.42683 4.35502 7.84597 5.06507 6.51965C5.78852 5.17993 6.80001 4.16175 8.09953 3.4651C9.41245 2.75505 10.9397 2.40002 12.6814 2.40002C13.4584 2.40002 14.0613 2.43352 14.49 2.5005C14.9321 2.55409 15.3742 2.64117 15.8163 2.76175L16.2182 2.86223L16.5397 2.94261L15.9369 6.88137H13.5053L13.1235 3.82682Z"
        fill="currentColor"
      />
      <path
        d="M18.7822 20.2048C18.7742 20.245 18.7701 20.2973 18.7701 20.3616C18.7701 20.5545 18.8425 20.651 18.9872 20.651C19.0515 20.651 19.1198 20.6309 19.1922 20.5907C19.2725 20.5505 19.3851 20.4822 19.5298 20.3857L19.6865 20.7957C19.5499 20.9805 19.3368 21.1694 19.0475 21.3624C18.7661 21.5553 18.4084 21.6517 17.9744 21.6517C17.6046 21.6517 17.3152 21.5834 17.1062 21.4468C16.9053 21.3021 16.8048 21.1172 16.8048 20.8921C16.8048 20.8439 16.8088 20.8037 16.8168 20.7715L17.1303 18.3962L17.4679 15.8762L16.7927 15.5145L16.8892 14.996L19.2163 14.6825L19.5298 14.8272L18.7822 20.2048ZM18.7701 13.6456C18.529 13.6456 18.312 13.5491 18.119 13.3562C17.9342 13.1633 17.8417 12.9463 17.8417 12.7051C17.8417 12.3755 17.9543 12.0982 18.1793 11.8732C18.4044 11.64 18.7018 11.5235 19.0716 11.5235C19.3529 11.5235 19.578 11.6159 19.7468 11.8008C19.9156 11.9857 20 12.1987 20 12.4399C20 12.7935 19.8955 13.0829 19.6865 13.308C19.4775 13.5331 19.1721 13.6456 18.7701 13.6456Z"
        fill="currentColor"
      />
    </>
  ) : variant === 'Li' ? (
    <>
      <path
        d="M12.1811 2.80005L12.0634 3.85947L10.3173 4.07528L8.72815 16.1605L12.2203 15.9643L14.2214 11.6678L15.9283 11.8836L14.9277 17.3769H4L4.11771 16.3763L5.43218 16.1017L7.00169 4.07528L5.60875 3.85947L5.74608 2.80005H12.1811Z"
        fill="currentColor"
      />
      <path
        d="M18.8111 20.0058C18.8032 20.0451 18.7993 20.0961 18.7993 20.1588C18.7993 20.3472 18.87 20.4414 19.0112 20.4414C19.074 20.4414 19.1407 20.4217 19.2113 20.3825C19.2898 20.3433 19.3997 20.2766 19.5409 20.1824L19.6939 20.5826C19.5605 20.7631 19.3526 20.9475 19.0701 21.1359C18.7954 21.3242 18.4462 21.4184 18.0224 21.4184C17.6614 21.4184 17.3789 21.3517 17.1749 21.2183C16.9787 21.077 16.8806 20.8965 16.8806 20.6768C16.8806 20.6297 16.8845 20.5905 16.8924 20.5591L17.1984 18.2401L17.528 15.7799L16.8688 15.4268L16.963 14.9206L19.2349 14.6145L19.5409 14.7558L18.8111 20.0058ZM18.7993 13.6022C18.5639 13.6022 18.352 13.508 18.1637 13.3197C17.9832 13.1314 17.8929 12.9195 17.8929 12.684C17.8929 12.3623 18.0028 12.0916 18.2225 11.8718C18.4423 11.6442 18.7326 11.5305 19.0936 11.5305C19.3683 11.5305 19.588 11.6207 19.7528 11.8012C19.9176 11.9817 20 12.1896 20 12.4251C20 12.7704 19.898 13.0529 19.6939 13.2726C19.4899 13.4923 19.1917 13.6022 18.7993 13.6022Z"
        fill="currentColor"
      />
    </>
  ) : variant === 'Pi' ? (
    <>
      <path
        d="M8.82 2.56002C9.23333 2.56002 9.81333 2.53336 10.56 2.48002C11.2267 2.42669 11.7133 2.40002 12.02 2.40002C13.98 2.40002 15.3733 2.75336 16.2 3.46003C17.04 4.15336 17.46 5.20669 17.46 6.62002C17.46 7.83336 17.1733 8.87336 16.6 9.74003C16.04 10.6067 15.2333 11.2667 14.18 11.72C13.14 12.1734 11.9133 12.4 10.5 12.4C9.99333 12.4 9.60667 12.38 9.34 12.34L8.9 16.12L11.56 16.4L11.5 17.42H4L4.1 16.4L5.44 16.12L7.08 3.86002L5.64 3.64003L5.78 2.56002H8.82ZM9.5 11.04C9.72667 11.0934 9.95333 11.12 10.18 11.12C10.42 11.12 10.56 11.12 10.6 11.12C11.64 11.1067 12.46 10.6734 13.06 9.82002C13.6733 8.96669 13.9867 7.84002 14 6.44002C14 5.48002 13.7733 4.77336 13.32 4.32002C12.88 3.86669 12.26 3.64003 11.46 3.64003C11.1267 3.64003 10.8067 3.66669 10.5 3.72002L9.5 11.04Z"
        fill="currentColor"
      />
      <path
        d="M18.7824 20.1C18.7744 20.14 18.7704 20.192 18.7704 20.256C18.7704 20.448 18.8424 20.544 18.9864 20.544C19.0504 20.544 19.1184 20.524 19.1904 20.484C19.2704 20.444 19.3824 20.376 19.5264 20.28L19.6824 20.688C19.5464 20.872 19.3344 21.06 19.0464 21.252C18.7664 21.444 18.4104 21.54 17.9784 21.54C17.6104 21.54 17.3224 21.472 17.1144 21.336C16.9144 21.192 16.8144 21.008 16.8144 20.784C16.8144 20.736 16.8184 20.696 16.8264 20.664L17.1384 18.3L17.4744 15.792L16.8024 15.432L16.8984 14.916L19.2144 14.604L19.5264 14.748L18.7824 20.1ZM18.7704 13.572C18.5304 13.572 18.3144 13.476 18.1224 13.284C17.9384 13.092 17.8464 12.876 17.8464 12.636C17.8464 12.308 17.9584 12.032 18.1824 11.808C18.4064 11.576 18.7024 11.46 19.0704 11.46C19.3504 11.46 19.5744 11.552 19.7424 11.736C19.9104 11.92 19.9944 12.132 19.9944 12.372C19.9944 12.724 19.8904 13.012 19.6824 13.236C19.4744 13.46 19.1704 13.572 18.7704 13.572Z"
        fill="currentColor"
      />
    </>
  ) : variant === 'Si' ? (
    <>
      <path
        d="M12.74 4.10002C12.5267 3.92669 12.3067 3.80669 12.08 3.74002C11.8533 3.66002 11.5733 3.62002 11.24 3.62002C10.5467 3.62002 10 3.78002 9.6 4.10002C9.2 4.42002 9 4.84669 9 5.38002C9 6.04669 9.20667 6.62002 9.62 7.10002C10.0333 7.58002 10.7533 8.14002 11.78 8.78002C12.7133 9.38002 13.4533 10.0267 14 10.72C14.5467 11.4134 14.82 12.2134 14.82 13.12C14.8333 13.9467 14.6267 14.7067 14.2 15.4C13.7733 16.08 13.1267 16.6267 12.26 17.04C11.4067 17.4534 10.36 17.66 9.12 17.66C8.12 17.66 7.16 17.5534 6.24 17.34C5.33333 17.1267 4.75333 16.9134 4.5 16.7L5.06 13.62H7.06L7.16 15.8C7.64 16.2134 8.26667 16.42 9.04 16.42C9.96 16.42 10.6267 16.24 11.04 15.88C11.4667 15.5067 11.68 15.0134 11.68 14.4C11.68 13.8 11.4867 13.2734 11.1 12.82C10.7267 12.3534 10.0733 11.8267 9.14 11.24C8.15333 10.6134 7.38 9.94002 6.82 9.22003C6.26 8.50002 5.98 7.66002 5.98 6.70003C5.98 5.34002 6.48667 4.28669 7.5 3.54002C8.52667 2.78002 9.85333 2.40002 11.48 2.40002C13.2533 2.40002 14.5533 2.64002 15.38 3.12002L14.86 6.06002H13.02L12.74 4.10002Z"
        fill="currentColor"
      />
      <path
        d="M18.2451 20.12C18.2371 20.16 18.2331 20.212 18.2331 20.276C18.2331 20.468 18.3051 20.564 18.4491 20.564C18.5131 20.564 18.5811 20.544 18.6531 20.504C18.7331 20.464 18.8451 20.396 18.9891 20.3L19.1451 20.708C19.0091 20.892 18.7971 21.08 18.5091 21.272C18.2291 21.464 17.8731 21.56 17.4411 21.56C17.0731 21.56 16.7851 21.492 16.5771 21.356C16.3771 21.212 16.2771 21.028 16.2771 20.804C16.2771 20.756 16.2811 20.716 16.2891 20.684L16.6011 18.32L16.9371 15.812L16.2651 15.452L16.3611 14.936L18.6771 14.624L18.9891 14.768L18.2451 20.12ZM18.2331 13.592C17.9931 13.592 17.7771 13.496 17.5851 13.304C17.4011 13.112 17.3091 12.896 17.3091 12.656C17.3091 12.328 17.4211 12.052 17.6451 11.828C17.8691 11.596 18.1651 11.48 18.5331 11.48C18.8131 11.48 19.0371 11.572 19.2051 11.756C19.3731 11.94 19.4571 12.152 19.4571 12.392C19.4571 12.744 19.3531 13.032 19.1451 13.256C18.9371 13.48 18.6331 13.592 18.2331 13.592Z"
        fill="currentColor"
      />
    </>
  ) : (
    <>
      <path
        d="M16.18 18.14C16.172 18.18 16.168 18.232 16.168 18.296C16.168 18.488 16.24 18.584 16.384 18.584C16.448 18.584 16.516 18.564 16.588 18.524C16.668 18.484 16.78 18.416 16.924 18.32L17.08 18.728C16.944 18.912 16.732 19.1 16.444 19.292C16.164 19.484 15.808 19.58 15.376 19.58C15.008 19.58 14.72 19.512 14.512 19.376C14.312 19.232 14.212 19.048 14.212 18.824C14.212 18.776 14.216 18.736 14.224 18.704L14.536 16.34L14.872 13.832L14.2 13.472L14.296 12.956L16.612 12.644L16.924 12.788L16.18 18.14ZM16.168 11.612C15.928 11.612 15.712 11.516 15.52 11.324C15.336 11.132 15.244 10.916 15.244 10.676C15.244 10.348 15.356 10.072 15.58 9.848C15.804 9.616 16.1 9.5 16.468 9.5C16.748 9.5 16.972 9.592 17.14 9.776C17.308 9.96 17.392 10.172 17.392 10.412C17.392 10.764 17.288 11.052 17.08 11.276C16.872 11.5 16.568 11.612 16.168 11.612Z"
        fill="currentColor"
      />
      <path
        d="M0.600006 15.4V13.9235L7.47501 7.90002L0.600006 1.87659V0.400024H12.6V2.74377H4.50001L10.375 7.90002L4.50001 13.0563H12.6V15.4H0.600006Z"
        fill="currentColor"
      />
    </>
  );
export const Eth = () => (
  <>
    <path d="M11.998 1.005L11.8506 1.50599V16.0433L11.998 16.1904L18.746 12.2017L11.998 1.005Z" fill="#343434" />
    <path d="M11.998 1.005L5.25 12.2017L11.998 16.1905V9.13451V1.005Z" fill="#8C8C8C" />
    <path d="M11.9979 17.4681L11.9149 17.5694V22.7478L11.9979 22.9905L18.75 13.4813L11.9979 17.4681Z" fill="#3C3C3B" />
    <path d="M11.998 22.9905V17.4681L5.25 13.4813L11.998 22.9905Z" fill="#8C8C8C" />
    <path d="M11.9979 16.1904L18.7459 12.2017L11.9979 9.13452V16.1904Z" fill="#141414" />
    <path d="M5.25 12.2017L11.998 16.1904V9.13452L5.25 12.2017Z" fill="#393939" />
  </>
);
