/**
 * 이 email 형식은 유효함
    - john.doe@example.com
    - mary_smith123@example.co.uk
    - jane-doe+blog@example.net
    - info@github.io
    - user.name+tag+sorting@example.com
 */
export const REGEX_EMAIL = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const REGEX_URL = /^(http|https):\/\/[^ "]+$/;
