import { ReactNode, useState } from 'react';
import { MDSModal, ModalWrapperProps } from '@marqvision/mds-v2';

type PromiseType = {
  resolve: (value: boolean | PromiseLike<boolean>) => void;
  reject: (reason?: any) => void;
} | null;
export const useConfirm = <T,>() => {
  const [promise, setPromise] = useState<PromiseType>(null);
  const [arg, setArg] = useState<T>();

  const confirm = (arg?: T) => {
    setArg(arg);
    return new Promise((resolve, reject) => {
      setPromise({ resolve, reject });
    });
  };

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };
  // You could replace the Dialog with your library's version
  const ConfirmationModalWrapper = ({
    children,
    ...props
  }: Omit<ModalWrapperProps, 'isOpen'> & { children: ReactNode }) => (
    <MDSModal.Wrapper {...props} isOpen={promise !== null}>
      {children}
    </MDSModal.Wrapper>
  );
  return { ConfirmationModalWrapper, confirm, handleConfirm, handleCancel, arg };
};

/**
 * 예제 코드
 
 function Example() {
  const { ConfirmationModalWrapper, confirm, handleConfirm, handleCancel } = useConfirm();
  const handleDelete = async () => {
    const ans = await confirm();
    if (ans) {
      alert('answer is ok');
    } else {
      alert('answer is cancel');
    }
  };
  return (
    <>
      <button onClick={handleDelete}>Delete</button>
      <ConfirmationModalWrapper>
        <MDSModal.Header onClose={handleCancel}>Title</MDSModal.Header>
        <MDSModal.Content>내용내용내용</MDSModal.Content>
        <MDSModal.Action>
          <MDSButton variant="outline" size="medium" color="bluegray" onClick={handleCancel}>
            Cancel
          </MDSButton>
          <MDSButton variant="outline" size="medium" color="bluegray" onClick={handleConfirm}>
            OK
          </MDSButton>
        </MDSModal.Action>
      </ConfirmationModalWrapper>
    </>
  );
}
 */
