import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MDSTheme, MDSTypography } from '../index';
import { LocalizedLabel } from './@constants';
import { Progress, ProgressSizes, LanguageEnum } from './@types';
import { getProgressPercentage } from './@util';

const useStyles = makeStyles(() => ({
  loadingWrapper: {
    width: '200px',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: '4px',
  },
  progressWrapper: {
    gridColumn: '1 / 3',
  },
  progressBar: {
    height: '3px',
    borderRadius: '4px',
    backgroundColor: MDSTheme.palette.bluegray[200],
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: MDSTheme.palette.blue[700],
    },
  },
}));

interface IProps {
  locale: LanguageEnum;
  uploadProgress: Progress;
  progressProp?: ProgressSizes;
}

export const LoadingProgress = (props: IProps): React.ReactNode => {
  const { locale, uploadProgress, progressProp } = props;
  const classes = useStyles();

  const allProgressFiles = Object.values(uploadProgress);
  const loadingFiles = allProgressFiles.filter(({ isLoading }) => isLoading);

  const getProgress = () => {
    if (progressProp?.totalSize) {
      // props 로 전달받은 progress 값
      const progress = getProgressPercentage(
        progressProp.loadedTotalSize + progressProp.loadedCurrentSize,
        progressProp.totalSize
      );
      return { text: `${progress}%`, value: progress };
    } else if (loadingFiles.length === 1) {
      // for a single upload
      const progress = getProgressPercentage(loadingFiles[0].uploadedSize, loadingFiles[0].totalSize);
      return { text: `${progress}%`, value: progress };
    } else {
      // for multiple uploads
      const progress = getProgressPercentage(
        allProgressFiles.reduce((prev, target) => prev + (target.uploadedSize || 0) / (target.totalSize || 0), 0),
        allProgressFiles.length
      );
      return { text: `${Math.floor(progress * allProgressFiles.length / 100)} / ${allProgressFiles.length}`, value: progress };
    }
  };
  
  return (
    <div className={classes.loadingWrapper}>
      <MDSTypography variant="T12" weight="medium" color={MDSTheme.palette.bluegray[400]}>
        {LocalizedLabel[locale].UploadingFiles}
      </MDSTypography>
      <MDSTypography variant="T12" weight="medium" color={MDSTheme.palette.bluegray[800]}>
        {getProgress().text}
      </MDSTypography>
      <div className={classes.progressWrapper}>
        <LinearProgress variant="determinate" value={getProgress().value} className={classes.progressBar} />
      </div>
    </div>
  );
};