/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ArchivedProtocolsExcelExportRequest {
  asset_id_filter: number[];
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter: number[];
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  include_expired: boolean;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  min_price?: number | null;
  model_id_filter?: number[] | null;
  search?: string | null;
  seller_name_filter: string[];
}

export interface ArchivedProtocolsExcelExportResponse {
  /** @format int32 */
  progress_id: number;
}

export interface ArchivedProtocolsExcelExportByIdListRequest {
  listing_id_list: number[];
}

export interface ArchivedProtocolsExcelResultRequest {
  /** @format int32 */
  progress_id: number;
}

export interface ArchivedProtocolsExcelResultResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface ArchivedProtocolsGetListingsRequest {
  asset_id_filter: number[];
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter: number[];
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  include_expired: boolean;
  /** @format int32 */
  limit: number;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  min_price?: number | null;
  model_id_filter?: number[] | null;
  /** @format int32 */
  offset: number;
  search?: string | null;
  seller_name_filter: string[];
}

export interface ArchivedProtocolsGetListingsResponse {
  /** @format int32 */
  count: number;
  listings: ArchivedProtocolsListing[];
}

export interface ArchivedProtocolsListing {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  archived_date: string;
  asset_name: string;
  currency: MarqCommonEnumsCurrencyEnum;
  domain_name: string;
  image_url: string;
  /** @format int32 */
  listing_id: number;
  name: string;
  /** @format double */
  price: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  public_date: string;
  seller_name: string;
  status: MarqCommonEnumsListingStatusEnum;
  /** @format int32 */
  stock_count: number;
  url: string;
}

export interface ArchivedProtocolsRequestTestPurchaseRequest {
  listing_id_list: number[];
  tp_type: MarqCommonEnumsTpTPTypeEnum;
}

export interface ArchivedProtocolsUpdateStatusRequest {
  archived_reason: MarqCommonEnumsArchivedReasonEnum;
  listing_ids: number[];
}

export interface AuthProtocolsCheckAccessTokenResponse {
  access_valid: string;
}

export interface AuthProtocolsCheckPasswordRequest {
  email: string;
  password: string;
}

export interface AuthProtocolsCheckPasswordResponse {
  success: string;
}

export interface AuthProtocolsCheckRefreshTokenResponse {
  refresh_valid: string;
}

export interface AuthProtocolsGetUserInfoResponse {
  /** @format int32 */
  client_id: number;
  client_name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00"
   */
  created_date: string;
  custom_filter: boolean;
  detected_tip: boolean;
  language: string;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  reporting_cap_client: boolean;
  role: MarqCommonEnumsClientClientRoleEnum;
  status: MarqCommonEnumsClientStatusEnum;
  transliteration: boolean;
  use_ecommerce: boolean;
  use_gen_ai: boolean;
  use_map_monitoring: boolean;
  use_social_media: boolean;
  use_stand_alone: boolean;
  user_email: string;
  /** @format int32 */
  user_id: number;
}

export interface AuthProtocolsLoginRequest {
  email: string;
  password: string;
  remember_me: boolean;
}

export interface AuthProtocolsLoginResponse {
  access_token: string;
  /** @format int32 */
  client_id: number;
  client_name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00"
   */
  created_date: string;
  custom_filter: boolean;
  detected_tip: boolean;
  language: string;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  refresh_token: string;
  reporting_cap_client: boolean;
  role: MarqCommonEnumsClientClientRoleEnum;
  status: MarqCommonEnumsClientStatusEnum;
  transliteration: boolean;
  use_ecommerce: boolean;
  use_gen_ai: boolean;
  use_map_monitoring: boolean;
  use_social_media: boolean;
  use_stand_alone: boolean;
  user_email: string;
  /** @format int32 */
  user_id: number;
}

export interface AuthProtocolsLoginWithTokenRequest {
  access_token: string;
  refresh_token: string;
}

export interface AuthProtocolsLoginWithTokenResponse {
  access_token: string;
  /** @format int32 */
  client_id: number;
  client_name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00"
   */
  created_date: string;
  custom_filter: boolean;
  detected_tip: boolean;
  language: string;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  refresh_token: string;
  reporting_cap_client: boolean;
  role: MarqCommonEnumsClientClientRoleEnum;
  status: MarqCommonEnumsClientStatusEnum;
  transliteration: boolean;
  use_ecommerce: boolean;
  use_gen_ai: boolean;
  use_map_monitoring: boolean;
  use_social_media: boolean;
  use_stand_alone: boolean;
  user_email: string;
  /** @format int32 */
  user_id: number;
}

export interface AuthProtocolsLogoutRequest {
  access_token?: string | null;
}

export interface AuthProtocolsLogoutResponse {
  success: string;
}

export interface AuthProtocolsOktaLoginRequest {
  code: string;
  code_verifier: string;
  url: string;
}

export interface AuthProtocolsOktaLoginResponse {
  access_token: string;
  /** @format int32 */
  client_id: number;
  client_name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00"
   */
  created_date: string;
  custom_filter: boolean;
  detected_tip: boolean;
  language: string;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  refresh_token: string;
  reporting_cap_client: boolean;
  role: MarqCommonEnumsClientClientRoleEnum;
  status: MarqCommonEnumsClientStatusEnum;
  transliteration: boolean;
  use_ecommerce: boolean;
  use_gen_ai: boolean;
  use_map_monitoring: boolean;
  use_social_media: boolean;
  use_stand_alone: boolean;
  user_email: string;
  /** @format int32 */
  user_id: number;
}

export interface AuthProtocolsOktaLoginInfoRequest {
  app_domain: string;
}

export interface AuthProtocolsOktaLoginInfoResponse {
  okta_client_id: string;
  redirect_uri: string;
}

export interface AuthProtocolsOktaRiotLoginRequest {
  access_token: string;
}

export interface AuthProtocolsOktaRiotLoginResponse {
  access_token: string;
  /** @format int32 */
  client_id: number;
  client_name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00"
   */
  created_date: string;
  custom_filter: boolean;
  detected_tip: boolean;
  language: string;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  refresh_token: string;
  reporting_cap_client: boolean;
  role: MarqCommonEnumsClientClientRoleEnum;
  status: MarqCommonEnumsClientStatusEnum;
  transliteration: boolean;
  use_ecommerce: boolean;
  use_gen_ai: boolean;
  use_map_monitoring: boolean;
  use_social_media: boolean;
  use_stand_alone: boolean;
  user_email: string;
  /** @format int32 */
  user_id: number;
}

export interface AuthProtocolsRefreshTokenRequest {
  email: string;
  refresh_token: string;
}

export interface AuthProtocolsRefreshTokenResponse {
  access_token: string;
}

export interface AuthorizedSellerProtocolsAddAuthorizedSellerRequest {
  add_url_list: string[];
}

export interface AuthorizedSellerProtocolsExportAuthorizedSellerRequest {
  domain_id_list?: number[] | null;
  search?: string | null;
  seller_id_list?: number[] | null;
  sorted_column: MarqCommonEnumsAuthorizedSellerSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface AuthorizedSellerProtocolsGetAllAuthorizedSellerIdListRequest {
  domain_id_list?: number[] | null;
  search?: string | null;
}

export interface AuthorizedSellerProtocolsGetAllAuthorizedSellerIdListResponse {
  id_list: number[];
}

export interface AuthorizedSellerProtocolsGetAuthorizedSellerCountRequest {
  domain_id_list?: number[] | null;
  search?: string | null;
}

export interface AuthorizedSellerProtocolsGetAuthorizedSellerCountResponse {
  /** @format int32 */
  total_count: number;
}

export interface AuthorizedSellerProtocolsGetAuthorizedSellerDetailAuthorizedSellerDetailResponse {
  country: MarqCommonEnumsCountryCodeEnum;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date: string;
  domain_name: string;
  listing_url?: string | null;
  /** @format int32 */
  seller_id: number;
  seller_name: string;
  seller_url?: string | null;
  shop_name: string;
}

export interface AuthorizedSellerProtocolsGetAuthorizedSellerDetailRequest {
  /** @format int32 */
  seller_id: number;
}

export interface AuthorizedSellerProtocolsGetAuthorizedSellerDetailResponse {
  data: AuthorizedSellerProtocolsGetAuthorizedSellerDetailAuthorizedSellerDetailResponse;
}

export interface AuthorizedSellerProtocolsGetAuthorizedSellerListAuthorizedSellerResponse {
  country: MarqCommonEnumsCountryCodeEnum;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date: string;
  domain_name: string;
  listing_url?: string | null;
  new: boolean;
  /** @format int32 */
  seller_id: number;
  seller_name: string;
  seller_url?: string | null;
  shop_name: string;
}

export interface AuthorizedSellerProtocolsGetAuthorizedSellerListRequest {
  domain_id_list?: number[] | null;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  search?: string | null;
  sorted_column: MarqCommonEnumsAuthorizedSellerSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface AuthorizedSellerProtocolsGetAuthorizedSellerListResponse {
  seller_list: AuthorizedSellerProtocolsGetAuthorizedSellerListAuthorizedSellerResponse[];
}

export interface AuthorizedSellerProtocolsGetDomainFilterRequest {
  domain_id_list?: number[] | null;
  search?: string | null;
}

export interface AuthorizedSellerProtocolsGetDomainFilterResponse {
  domain_list: MarqBrandCoreModelProtocolCountryDomains[];
  /** @format int32 */
  total_count: number;
}

export interface AuthorizedWebsiteProtocolsAddAuthWebsiteRequest {
  add_url_list: string[];
}

export interface AuthorizedWebsiteProtocolsExportAuthWebsiteRequest {
  id_list?: number[] | null;
  search: string;
  sorted_column: MarqCommonEnumsAuthorizedSellerSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface AuthorizedWebsiteProtocolsGetAllAuthWebsiteIdListRequest {
  search?: string | null;
}

export interface AuthorizedWebsiteProtocolsGetAllAuthWebsiteIdListResponse {
  id_list: number[];
}

export interface AuthorizedWebsiteProtocolsGetAuthWebsiteCountRequest {
  search?: string | null;
}

export interface AuthorizedWebsiteProtocolsGetAuthWebsiteCountResponse {
  /** @format int32 */
  total_count: number;
}

export interface AuthorizedWebsiteProtocolsGetAuthWebsiteListRequest {
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  search: string;
  sorted_column: MarqCommonEnumsAuthorizedSellerSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface AuthorizedWebsiteProtocolsGetAuthWebsiteListResponse {
  list: AuthorizedWebsiteProtocolsGetAuthWebsiteListResponseAuthWebsiteResponse[];
}

export interface AuthorizedWebsiteProtocolsGetAuthWebsiteListResponseAuthWebsiteResponse {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date: string;
  domain_name: string;
  domain_url: string;
  /** @format int32 */
  id: number;
}

export interface CommonProtocolsGetCreditsResponse {
  /** @format int32 */
  remaining_credits: number;
  /** @format int32 */
  remaining_returned_credits: number;
  /** @format int32 */
  total_credits: number;
}

export interface EmailProtocolsRequestUpdateEmailRequest {
  email: string;
  password: string;
}

export interface EmailProtocolsUpdateEmailAddressRequest {
  token: string;
}

export interface EmployeeSignupProtocolsGetEmployeeDataWithTokenRequest {
  token: string;
}

export interface EmployeeSignupProtocolsGetEmployeeDataWithTokenResponse {
  client_currency: MarqCommonEnumsCurrencyEnum;
  company?: string | null;
  country?: MarqCommonEnumsCountryCodeEnum;
  email?: string | null;
  /** @format int32 */
  id: number;
  language?: string | null;
  role: MarqCommonEnumsClientClientRoleEnum;
}

export interface EmployeeSignupProtocolsUpdateRegisterRequest {
  email: string;
  first_name?: string | null;
  last_name?: string | null;
  password: string;
  token: string;
}

export interface EmployeeSignupProtocolsUpdateRegisterResponse {
  access_token: string;
  /** @format int32 */
  client_id: number;
  client_name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00"
   */
  created_date: string;
  custom_filter: boolean;
  detected_tip: boolean;
  language: string;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  refresh_token: string;
  reporting_cap_client: boolean;
  role: MarqCommonEnumsClientClientRoleEnum;
  status: MarqCommonEnumsClientStatusEnum;
  transliteration: boolean;
  use_ecommerce: boolean;
  use_gen_ai: boolean;
  use_map_monitoring: boolean;
  use_social_media: boolean;
  use_stand_alone: boolean;
  user_email: string;
  /** @format int32 */
  user_id: number;
}

export interface HomeProtocolsBrand {
  brand_name: string;
  /** @format int32 */
  id: number;
}

export interface HomeProtocolsClosedItem {
  brand_name: string;
  channel: MarqCommonEnumsCrClientRequestChannelTypeEnum;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  closed_date?: string | null;
  detail: string;
  enforcement_type?: MarqCommonEnumsCrClientRequestEnforcementTypeEnum;
  /** @format int32 */
  id: number;
  is_new: boolean;
  title: string;
  type: MarqCommonEnumsCrClientRequestTypeEnum;
}

export interface HomeProtocolsFileIdNameAndUrl {
  filename: string;
  /** @format int32 */
  id?: number | null;
  url: string;
}

export interface HomeProtocolsFilenameAndUrl {
  filename: string;
  url: string;
}

export interface HomeProtocolsGetBrandAssetsResponse {
  list: HomeProtocolsGetBrandAssetsResponseBrandAssets[];
}

export interface HomeProtocolsGetBrandAssetsResponseBrandAssets {
  assets: HomeProtocolsGetBrandAssetsResponseBrandAssetsAsset[];
  /** @format int32 */
  brand_id: number;
  brand_name: string;
}

export interface HomeProtocolsGetBrandAssetsResponseBrandAssetsAsset {
  /** @format int32 */
  brand_id: number;
  brand_name: string;
  /** @format int32 */
  id: number;
  name: string;
}

export interface HomeProtocolsGetClosedListRequest {
  /** @format int32 */
  brand_id?: number | null;
  /** @format int32 */
  limit?: number | null;
  /** @format int32 */
  offset?: number | null;
  /** @format int32 */
  page?: number | null;
}

export interface HomeProtocolsGetClosedListResponse {
  closed_list: HomeProtocolsClosedItem[];
  /** @format int32 */
  total_closed: number;
}

export interface HomeProtocolsGetHomeResponse {
  closed_list: HomeProtocolsClosedItem[];
  name?: string | null;
  /** @format int32 */
  new_closed: number;
  opened_list: HomeProtocolsOpenedItem[];
  payment_failed_reason?: string | null;
  performance_report?: HomeProtocolsGetHomeResponsePerfReportSection;
  register_card_flag: boolean;
  request_types: MarqCommonEnumsCrClientRequestTypeEnum[];
  to_do_list: HomeProtocolsGetHomeResponseToDoItem[];
  /** @format int32 */
  total_closed: number;
  /** @format int32 */
  total_opened: number;
}

export interface HomeProtocolsGetHomeResponsePerfReportSection {
  filename: string;
  is_new: boolean;
  s3url: string;
}

export interface HomeProtocolsGetHomeResponseToDoItem {
  detail: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  due_date?: string | null;
  file_type?: MarqCommonEnumsCrClientTaskFileTypeEnum;
  /** @format int32 */
  task_id: number;
  title: string;
  type: HomeProtocolsToDoType;
  wrong_file_list: HomeProtocolsFileIdNameAndUrl[];
}

export interface HomeProtocolsGetOpenedListRequest {
  /** @format int32 */
  brand_id?: number | null;
  /** @format int32 */
  limit?: number | null;
  /** @format int32 */
  offset?: number | null;
  /** @format int32 */
  page?: number | null;
}

export interface HomeProtocolsGetOpenedListResponse {
  opened_list: HomeProtocolsOpenedItem[];
  /** @format int32 */
  total_opened: number;
}

export interface HomeProtocolsGetOtherDetailDocument {
  /** @format int32 */
  doc_id: number;
  filename: string;
}

export interface HomeProtocolsGetOtherDetailRequest {
  /** @format int32 */
  id: number;
}

export interface HomeProtocolsGetOtherDetailResponse {
  brand_name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  closed_date?: string | null;
  /** @format int32 */
  id: number;
  reply?: HomeProtocolsGetOtherDetailResponseReply;
  request: HomeProtocolsGetOtherDetailResponseReq;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  requested_date: string;
}

export interface HomeProtocolsGetOtherDetailResponseReply {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  date: string;
  detail: string;
  document_list: HomeProtocolsGetOtherDetailDocument[];
  title: string;
}

export interface HomeProtocolsGetOtherDetailResponseReq {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  date: string;
  detail: string;
  document_list: HomeProtocolsGetOtherDetailDocument[];
  title: string;
}

export interface HomeProtocolsGetOtherPresignedDownloadUrlRequest {
  /** @format int32 */
  doc_id: number;
}

export interface HomeProtocolsGetOtherPresignedDownloadUrlResponse {
  url: string;
}

export interface HomeProtocolsGetOtherPresignedUploadUrlRequest {
  filename: string;
}

export interface HomeProtocolsGetOtherPresignedUploadUrlResponse {
  url: string;
}

export interface HomeProtocolsGetTaskDetailRequest {
  /** @format int32 */
  id: number;
}

export interface HomeProtocolsGetTaskDetailResponse {
  /** @format int32 */
  id: number;
  list: HomeProtocolsGetTaskDetailResponseTypeAndDetail[];
  task_status: MarqCommonEnumsCrClientTaskStatusEnum;
}

export type HomeProtocolsGetTaskDetailResponseDetail =
  | HomeProtocolsGetTaskDetailResponseOpenDetail
  | HomeProtocolsGetTaskDetailResponseReOpenDetail
  | HomeProtocolsGetTaskDetailResponseReplyDetail;

export interface HomeProtocolsGetTaskDetailResponseOpenDetail {
  attached_template_list: HomeProtocolsFileIdNameAndUrl[];
  brand?: HomeProtocolsBrand;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  due_date: string;
  file_type: MarqCommonEnumsCrClientTaskFileTypeEnum;
  required_task: boolean;
  requirement: string;
  task_subtitle: string;
  task_title: string;
}

export interface HomeProtocolsGetTaskDetailResponseReOpenDetail {
  attached_template_list: HomeProtocolsFileIdNameAndUrl[];
  brand?: HomeProtocolsBrand;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  due_date: string;
  file_type: MarqCommonEnumsCrClientTaskFileTypeEnum;
  required_task: boolean;
  requirement: string;
  task_subtitle: string;
  task_title: string;
  wrong_file_list: HomeProtocolsFileIdNameAndUrl[];
}

export interface HomeProtocolsGetTaskDetailResponseReplyDetail {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date: string;
  file_list: HomeProtocolsFileIdNameAndUrl[];
}

export interface HomeProtocolsGetTaskDetailResponseTypeAndDetail {
  detail: HomeProtocolsGetTaskDetailResponseDetail;
  type: MarqCommonEnumsCrClientTaskMessageTypeEnum;
}

export interface HomeProtocolsOpenedItem {
  brand_name: string;
  channel: MarqCommonEnumsCrClientRequestChannelTypeEnum;
  detail: string;
  enforcement_type?: MarqCommonEnumsCrClientRequestEnforcementTypeEnum;
  /** @format int32 */
  id: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  requested_date: string;
  title: string;
  type: MarqCommonEnumsCrClientRequestTypeEnum;
}

export interface HomeProtocolsPostOtherAddRequest {
  /** @format int32 */
  brand_id?: number | null;
  detail: string;
  document_list?: HomeProtocolsPostOtherAddRequestDocument[] | null;
  title: string;
}

export interface HomeProtocolsPostOtherAddRequestDocument {
  filename: string;
  url: string;
}

export interface HomeProtocolsPostOtherAddResponse {
  /** @format int32 */
  cr_id: number;
  /** @format int32 */
  id: number;
}

export interface HomeProtocolsPostUrlAddRequest {
  /** @format int32 */
  brand_id: number;
  channel_type: MarqCommonEnumsCrClientRequestChannelTypeEnum;
  enforcement_type: MarqCommonEnumsCrClientRequestEnforcementTypeEnum;
  url_list: string[];
}

export interface HomeProtocolsPostUrlAddResponse {
  /** @format int32 */
  cr_id: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  remaining: number;
  /** @format int32 */
  total: number;
}

export interface HomeProtocolsPostUrlGetEcommerceDetailRequest {
  /** @format int32 */
  id: number;
}

export interface HomeProtocolsPostUrlGetEcommerceDetailResponse {
  brand_name: string;
  channel_type: MarqCommonEnumsCrClientRequestChannelTypeEnum;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  closed_date?: string | null;
  /** @format int32 */
  completed: number;
  enforcement_type: MarqCommonEnumsCrClientRequestEnforcementTypeEnum;
  /** @format int32 */
  failed: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  in_progress: number;
  list: MarqBrandModelResponseCrUrlResponseInterfaceCommerceItem[];
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  requested_date: string;
  /** @format int32 */
  total: number;
}

export interface HomeProtocolsPostUrlGetWebsiteDetailRequest {
  /** @format int32 */
  id: number;
}

export interface HomeProtocolsPostUrlGetWebsiteDetailResponse {
  brand_name: string;
  channel_type: MarqCommonEnumsCrClientRequestChannelTypeEnum;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  closed_date?: string | null;
  /** @format int32 */
  completed: number;
  enforcement_type: MarqCommonEnumsCrClientRequestEnforcementTypeEnum;
  /** @format int32 */
  failed: number;
  /** @format int32 */
  id: number;
  /** @format int32 */
  in_progress: number;
  list: MarqBrandModelResponseCrUrlResponseInterfaceWebsiteItem[];
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  requested_date: string;
  /** @format int32 */
  total: number;
}

export interface HomeProtocolsTaskDismissRequest {
  /** @format int32 */
  id: number;
}

export interface HomeProtocolsTaskGetDownloadUrlRequest {
  /** @format int32 */
  file_id: number;
  /** @format int32 */
  task_id: number;
}

export interface HomeProtocolsTaskGetDownloadUrlResponse {
  url: string;
}

export interface HomeProtocolsTaskGetUploadUrlRequest {
  filename: string;
}

export interface HomeProtocolsTaskGetUploadUrlResponse {
  url: string;
}

export interface HomeProtocolsTaskSubmitRequest {
  /** @format int32 */
  id: number;
  list: HomeProtocolsFilenameAndUrl[];
}

export enum HomeProtocolsToDoType {
  Confirm = 'confirm',
  Upload = 'upload',
  ReUpload = 're_upload',
}

export interface InfringingImageProtocolsCreateInfringingImageLibraryForAssetByPresignedRequest {
  /** @format int32 */
  asset_id: number;
  /** @format int32 */
  brand_id: number;
  image_file_url_list?: string[] | null;
  related_ip_doc_ids?: number[] | null;
}

export interface InfringingImageProtocolsGetAllAssetsResponse {
  data_list: InfringingImageProtocolsGetAllAssetsResponseItem[];
}

export interface InfringingImageProtocolsGetAllAssetsResponseItem {
  /** @format int32 */
  asset_id: number;
  /** @format int32 */
  brand_id?: number | null;
  has_infringing_image: boolean;
  name?: string | null;
  non_manufactured: boolean;
}

export interface InfringingImageProtocolsGetAllInfringingImagesRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface InfringingImageProtocolsGetAllInfringingImagesResponse {
  data: InfringingImageProtocolsGetAllInfringingImagesResponseAssetImageData[];
}

export interface InfringingImageProtocolsGetAllInfringingImagesResponseAssetImageData {
  /** @format int32 */
  asset_id: number;
  asset_name?: string | null;
  images: (string | null)[];
}

export interface InfringingImageProtocolsGetInfringingImagePresignedUrlRequest {
  /** @format int32 */
  asset_id: number;
  file_name: string;
}

export interface InfringingImageProtocolsGetInfringingImagePresignedUrlResponse {
  url: string;
}

export interface InfringingImageProtocolsGetInfringingImagesForAssetRequest {
  /** @format int32 */
  asset_id: number;
}

export interface InfringingImageProtocolsGetInfringingImagesForAssetResponse {
  infringing_image_list: MarqBrandCoreModelResponseIdWithUrl[];
  related_ip_doc_ids?: MarqBrandDataModelAssetDataFullIpDoc[] | null;
}

export interface InfringingImageProtocolsGetIpDocumentsRequest {
  /** @format int32 */
  brand_id: number;
}

export interface InfringingImageProtocolsGetIpDocumentsResponse {
  ip_documents: MarqBrandDataModelIpDocByGroup[];
}

export interface InfringingImageProtocolsGetIpDocumentsUniqueNameRequest {
  /** @format int32 */
  brand_id: number;
}

export interface InfringingImageProtocolsGetIpDocumentsUniqueNameResponse {
  full_ip_doc_list: MarqBrandDataModelAssetDataFullIpDoc[];
}

export interface InfringingImageProtocolsUpdateInfringingImageLibraryForAssetByPresignedRequest {
  /** @format int32 */
  asset_id: number;
  delete_file_id_list?: number[] | null;
  image_file_url_list: string[];
  related_ip_doc_ids?: number[] | null;
}

export interface LSettingsProtocolsDeleteEmployeeRequest {
  /** @format int32 */
  employee_id: number;
}

export interface LSettingsProtocolsRequestInvitationEmailRequest {
  email: string;
}

export interface LSettingsProtocolsSendInvitationRequest {
  invitation_data: LSettingsProtocolsSendInvitationRequestInvitation[];
}

export interface LSettingsProtocolsSendInvitationRequestInvitation {
  email: string;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  role: MarqCommonEnumsClientClientRoleEnum;
}

export interface LSettingsProtocolsUpdateCompanyProfileRequest {
  company_address?: string | null;
  company_name?: string | null;
  country?: MarqCommonEnumsCountryCodeEnum;
  website?: string | null;
}

export interface LSettingsProtocolsUpdateEmployeeRoleRequest {
  /** @format int32 */
  id: number;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  role: MarqCommonEnumsClientClientRoleEnum;
}

export interface MenuProtocolsGetBadgesResponse {
  tp: boolean;
}

export interface OfficialImageProtocolsCreateCollectionRequest {
  /** @format int32 */
  brand_id: number;
  collection_name: string;
}

export interface OfficialImageProtocolsCreateCollectionResponse {
  /** @format int32 */
  collection_id: number;
}

export interface OfficialImageProtocolsCreateOfficialImagesPresignedRequest {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  collection_id?: number | null;
  image_file_url_list: string[];
  note?: string | null;
  product_url?: string | null;
}

export interface OfficialImageProtocolsDeleteCollectionRequest {
  /** @format int32 */
  collection_id: number;
}

export interface OfficialImageProtocolsDeleteOfficialImageRequest {
  /** @format int32 */
  official_image_id: number;
}

export interface OfficialImageProtocolsGetAllCollectionsRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface OfficialImageProtocolsGetAllCollectionsResponse {
  brand_collection: OfficialImageProtocolsGetAllCollectionsResponseIpPortfolioCollection[];
}

export interface OfficialImageProtocolsGetAllCollectionsResponseCollectionData {
  /** @format int32 */
  id: number;
  name: string;
}

export interface OfficialImageProtocolsGetAllCollectionsResponseIpPortfolioCollection {
  brand: OfficialImageProtocolsGetAllCollectionsResponseIpPortfolioData;
  collection_tree: OfficialImageProtocolsGetAllCollectionsResponseCollectionData[];
}

export interface OfficialImageProtocolsGetAllCollectionsResponseIpPortfolioData {
  /** @format int32 */
  id: number;
  name: string;
}

export interface OfficialImageProtocolsGetAllOfficialImagesRequest {
  /** @format int32 */
  brand_id?: number | null;
  collection_filter?: number[] | null;
  search?: string | null;
  sorted_by?: MarqCommonEnumsOiCollectionSortedByEnum;
  sorted_order?: MarqCommonEnumsSortedOrderEnum;
}

export interface OfficialImageProtocolsGetAllOfficialImagesResponse {
  brand_data: OfficialImageProtocolsGetAllOfficialImagesResponseIpPortfolioData[];
}

export interface OfficialImageProtocolsGetAllOfficialImagesResponseCollectionData {
  /** @format int32 */
  count: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date: string;
  /** @format int32 */
  id: number;
  name: string;
  official_image_data: OfficialImageProtocolsGetAllOfficialImagesResponseOfficialImageData[];
}

export interface OfficialImageProtocolsGetAllOfficialImagesResponseIpPortfolioData {
  /** @format int32 */
  collection_count: number;
  collection_data: OfficialImageProtocolsGetAllOfficialImagesResponseCollectionData[];
  /** @format int32 */
  count: number;
  /** @format int32 */
  id: number;
  name: string;
}

export interface OfficialImageProtocolsGetAllOfficialImagesResponseOfficialImageData {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  added_date: string;
  /** @format int32 */
  id: number;
  marq_official_url?: string | null;
  name: string;
  note?: string | null;
  product_url: string;
  s3url?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date: string;
}

export interface OfficialImageProtocolsGetCollectionDetailsRequest {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  collection_id: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  search?: string | null;
  sorted_by?: MarqCommonEnumsOfficialImageSortedByEnum;
  sorted_order?: MarqCommonEnumsSortedOrderEnum;
}

export interface OfficialImageProtocolsGetCollectionDetailsResponse {
  collection_data: OfficialImageProtocolsGetCollectionDetailsResponseCollectionData;
}

export interface OfficialImageProtocolsGetCollectionDetailsResponseCollectionData {
  /** @format int32 */
  count: number;
  /** @format int32 */
  id: number;
  name: string;
  official_image_data: OfficialImageProtocolsGetCollectionDetailsResponseOfficialImageData[];
}

export interface OfficialImageProtocolsGetCollectionDetailsResponseOfficialImageData {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  added_date: string;
  /** @format int32 */
  id: number;
  marq_official_url?: string | null;
  name: string;
  note?: string | null;
  product_url: string;
  s3url?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date: string;
}

export interface OfficialImageProtocolsUpdateCollectionRequest {
  /** @format int32 */
  collection_id: number;
  collection_name: string;
}

export interface OfficialImageProtocolsUpdateOfficialImageRequest {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  collection_id?: number | null;
  /** @format int32 */
  id: number;
  name: string;
  note?: string | null;
  product_url?: string | null;
}

export interface OfficialImageWebProtocolsGetClientHomeResponse {
  brands: OfficialImageWebProtocolsGetClientHomeResponseBrand[];
  client_name: string;
}

export interface OfficialImageWebProtocolsGetClientHomeResponseBrand {
  copyright_footer?: string | null;
  logo_url?: string | null;
  official_images: OfficialImageWebProtocolsGetClientHomeResponseOfficialImage[];
}

export interface OfficialImageWebProtocolsGetClientHomeResponseOfficialImage {
  detail_url: string;
  image_url: string;
}

export interface OfficialImageWebProtocolsGetOfficialImageResponse {
  client_name: string;
  copyright_footer?: string | null;
  logo_url?: string | null;
  note?: string | null;
  oi_url?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00"
   */
  published_date: string;
}

export interface PasswordProtocolsChangePasswordRequest {
  password: string;
}

export interface PasswordProtocolsFindPasswordRequest {
  email: string;
}

export interface PasswordProtocolsResetPasswordRequest {
  password: string;
  token: string;
}

export interface SellerIntelligenceProtocolsGetClusterDetailsCountriesRequest {
  /** @format int32 */
  cluster_id: number;
  /** @format int32 */
  master_id: number;
  sort_by: MarqCommonEnumsSellerintelSellerIntelDetailsCountriesSortBy;
}

export interface SellerIntelligenceProtocolsGetClusterDetailsCountriesResponse {
  country_list: MarqBrandDataModelSellerCountryWithCountDataListCountryWithCountData[];
}

export interface SellerIntelligenceProtocolsGetClusterDetailsMarketplacesRequest {
  /** @format int32 */
  cluster_id: number;
  /** @format int32 */
  master_id: number;
  sort_by: MarqCommonEnumsSellerintelSellerIntelDetailsDomainsSortBy;
}

export interface SellerIntelligenceProtocolsGetClusterDetailsMarketplacesResponse {
  marketplace_list: MarqBrandDataModelSellerMarketplaceWithCountDataListMarketplaceWithCountData[];
}

export interface SellerIntelligenceProtocolsGetClusterDetailsSellersRequest {
  /** @format int32 */
  cluster_id: number;
  /** @format int32 */
  master_id: number;
  sort_by: MarqCommonEnumsSellerintelSellerIntelDetailsSellersSortBy;
}

export interface SellerIntelligenceProtocolsGetClusterDetailsSellersResponse {
  seller_list: MarqBrandDataModelSellerSellerWithCountDataListSellerWithCountData[];
}

export interface SellerIntelligenceProtocolsGetClusterDetailsSellersDataRequest {
  /** @format int32 */
  cluster_id: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  master_id: number;
  /** @format int32 */
  offset: number;
  sort_by?: MarqCommonEnumsSellerintelSellerClusterDataSortByEnum;
}

export interface SellerIntelligenceProtocolsGetClusterDetailsSellersDataResponse {
  seller_data_list: MarqBrandDataModelSellerClusterAnalysisSellerDataSellerData[];
}

export interface SellerIntelligenceProtocolsGetClusterDetailsSummaryRequest {
  /** @format int32 */
  cluster_id: number;
  /** @format int32 */
  master_id: number;
  status?: MarqCommonEnumsSellerintelSellerClusterStatusEnum;
}

export interface SellerIntelligenceProtocolsGetClusterDetailsSummaryResponse {
  action: MarqBrandDataModelSellerClusterDetailsSummaryAction;
  clustering: MarqBrandDataModelSellerClusterDetailsSummaryClustering;
  most_used_info?: MarqBrandDataModelSellerClusterDetailsSummaryMostUsedInfo;
  processing: MarqBrandDataModelSellerClusterDetailsSummaryProcessing;
}

export interface SellerIntelligenceProtocolsGetClusterMasterStatusResponse {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  last_updated: string;
  /** @format int32 */
  master_id: number;
  status: MarqCommonEnumsSellerintelSellerClusterStatusEnum;
}

export interface SellerIntelligenceProtocolsGetClustersSideViewTypeARequest {
  mapping_level: MarqCommonEnumsSellerintelSellerIntelMappingLevel;
  /** @format int32 */
  offset?: number | null;
  sort_by: MarqCommonEnumsSellerintelSellerIntelClusterSortBy;
}

export interface SellerIntelligenceProtocolsGetClustersSideViewTypeAResponse {
  cluster_list: MarqBrandDataModelSellerClusterSideInfo[];
}

export interface SellerIntelligenceProtocolsGetClustersSideViewTypeBRequest {
  /** @format int32 */
  offset?: number | null;
  product_desc: boolean;
  product_title: boolean;
  sort_by: MarqCommonEnumsSellerintelSellerIntelClusterSortBy;
}

export interface SellerIntelligenceProtocolsGetClustersSideViewTypeBResponse {
  cluster_list: MarqBrandDataModelSellerClusterSideInfo[];
}

export interface SellerIntelligenceProtocolsGetExportDataRequest {
  /** @format int32 */
  cluster_id: number;
  /** @format int32 */
  master_id: number;
}

export interface SellerIntelligenceProtocolsGetTypeAClusterByIdRequest {
  /** @format int32 */
  cluster_id: number;
  mapping_level: MarqCommonEnumsSellerintelSellerIntelMappingLevel;
}

export interface SellerIntelligenceProtocolsGetTypeAClusterByIdResponse {
  cluster: MarqBrandDataModelSellerCluster;
}

export interface SellerIntelligenceProtocolsGetTypeBClusterByIdRequest {
  /** @format int32 */
  cluster_id: number;
  product_desc: boolean;
  product_title: boolean;
}

export interface SellerIntelligenceProtocolsGetTypeBClusterByIdResponse {
  cluster: MarqBrandDataModelSellerCluster;
}

export interface SettingsProtocolsGetClientActiveCountriesResponse {
  countries: SettingsProtocolsLimitedAccessPolicyCountry[];
}

export interface SettingsProtocolsGetEmployeeListResponse {
  employee_list: SettingsProtocolsGetEmployeeListResponseEmployee[];
}

export interface SettingsProtocolsGetEmployeeListResponseEmployee {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date?: string | null;
  email?: string | null;
  first_name?: string | null;
  has_signed_up: boolean;
  /** @format int32 */
  id: number;
  last_name?: string | null;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  /** @format int32 */
  order: number;
  role: MarqCommonEnumsClientClientRoleEnum;
}

export interface SettingsProtocolsGetProfileResponse {
  client_currency: MarqCommonEnumsCurrencyEnum;
  company_address?: string | null;
  company_name?: string | null;
  country?: MarqCommonEnumsCountryCodeEnum;
  email: string;
  first_name?: string | null;
  /** @format int32 */
  id: number;
  last_name?: string | null;
  limited_access_policy?: SettingsProtocolsLimitedAccessPolicy;
  use_official_image_hosting: boolean;
  website?: string | null;
}

export interface SettingsProtocolsLimitedAccessPolicy {
  brands?: SettingsProtocolsLimitedAccessPolicyBrand[] | null;
  countries?: SettingsProtocolsLimitedAccessPolicyCountry[] | null;
  has_all_brand_access?: boolean | null;
  has_all_country_access?: boolean | null;
}

export interface SettingsProtocolsLimitedAccessPolicyBrand {
  /** @format int32 */
  id: number;
  name?: string | null;
}

export interface SettingsProtocolsLimitedAccessPolicyCountry {
  code: string;
  name?: string | null;
}

export interface IoKtorHttpContentType {
  content_subtype: string;
  content_type: string;
  parameters: IoKtorHttpHeaderValueParam[];
}

export interface IoKtorHttpHeaderValueParam {
  name: string;
  value: string;
}

export type KotlinAny = unknown;

export interface ListingsListingsProtocolsAddAuthorizedRequest {
  /** @format int32 */
  seller_id: number;
}

export interface ListingsListingsProtocolsClientNote {
  doc_list?: ListingsListingsProtocolsClientNoteDocument[] | null;
  note: string;
}

export interface ListingsListingsProtocolsClientNoteDocument {
  filename: string;
  url: string;
}

export interface ListingsListingsProtocolsDoEnforcementRequest {
  listing_id_list: number[];
  note?: ListingsListingsProtocolsClientNote;
}

export interface ListingsListingsProtocolsDoEnforcementResponse {
  /** @format int32 */
  affected: number;
  listing_image_list?: string[] | null;
  listing_title?: string | null;
  /** @format int32 */
  remaining: number;
  /** @format int32 */
  request_id?: number | null;
  success: boolean;
}

export interface ListingsListingsProtocolsDoEnforcementBySellerRequest {
  cluster_id_list?: number[] | null;
  note?: ListingsListingsProtocolsClientNote;
  string_seller_id_list?: string[] | null;
}

export interface ListingsListingsProtocolsDoEnforcementBySellerResponse {
  /** @format int32 */
  affected: number;
  listing_image_list?: string[] | null;
  listing_title?: string | null;
  /** @format int32 */
  remaining: number;
  /** @format int32 */
  request_id?: number | null;
  /** @format int32 */
  requested_count: number;
  success: boolean;
}

export interface ListingsListingsProtocolsGetCounterfeitReasonsRequest {
  /** @format int32 */
  listing_id: number;
}

export interface ListingsListingsProtocolsGetCounterfeitReasonsResponse {
  reasons?: MarqBrandModelResponseCounterfeitReasonResponse;
}

export interface ListingsListingsProtocolsGetDetailsRequest {
  /** @format int32 */
  brand_id?: number | null;
  /** @format int32 */
  listing_id: number;
}

export interface ListingsListingsProtocolsGetDetailsResponse {
  action_section_info: ListingsListingsProtocolsGetDetailsResponseActionSectionInfo;
  client_note?: MarqBrandCoreModelListingClientNoteData;
  investigation_detail?: MarqBrandCoreModelResponseInvestigationDetailResponse;
  listing_detail: MarqBrandCoreModelResponseListingDetailResponse;
  seller_detail?: MarqBrandCoreModelResponseSellerDetailResponse;
  tp_detail?: MarqBrandCoreModelResponseTPDetailResponse;
}

export interface ListingsListingsProtocolsGetDetailsResponseActionSectionInfo {
  client_status?: MarqBrandEnumsListingMonitoringStatusEnum;
  detail?: string | null;
  possible_add_authorized: boolean;
  possible_enforcement: boolean;
  title?: string | null;
}

export interface ListingsListingsProtocolsGetNoteUploadUrlRequest {
  filename: string;
}

export interface ListingsListingsProtocolsGetNoteUploadUrlResponse {
  url: string;
}

export interface ListingsListingsProtocolsRequestTestPurchaseRequest {
  listing_id_list: number[];
  tp_type: MarqCommonEnumsTpTPTypeEnum;
}

export interface ListingsListingsProtocolsUndoEnforcementRequest {
  /** @format int32 */
  request_id: number;
}

export interface ListingsListingsProtocolsUndoEnforcementResponse {
  success: boolean;
}

export interface ListingsListingsProtocolsUpdateCounterfeitReasonThumbRequest {
  down?: boolean | null;
  /** @format int32 */
  id: number;
  /** @format int32 */
  listing_id: number;
  up?: boolean | null;
}

export interface ListingsListingsProtocolsUpdateToArchivedRequest {
  listing_ids: number[];
  reason: MarqCommonEnumsArchivedReasonEnum;
}

export interface ListingsListingsProtocolsUpdateToArchivedResponse {
  listing_image_list?: string[] | null;
  listing_title?: string | null;
}

export interface MapMapModelCountryProtocolsExcludeListingOptionRequest {
  /** @format int32 */
  listing_option_id: number;
}

export interface MapMapModelCountryProtocolsGetFilterInfoDomainRequest {
  country: MarqCommonEnumsCountryCodeEnum;
  /** @format int32 */
  model_id: number;
}

export interface MapMapModelCountryProtocolsGetInfoRequest {
  country: MarqCommonEnumsCountryCodeEnum;
  /** @format int32 */
  model_id: number;
  sort: MarqBrandEnumsMapSortEnum;
}

export interface MapMapModelCountryProtocolsGetInfoResponse {
  asset_name: string;
  brand_name: string;
  collection_name?: string | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  currency: MarqCommonEnumsCurrencyEnum;
  domain_price_list: MapMapModelCountryProtocolsGetInfoResponseDomainPrice[];
  /** @format double */
  map: number;
  model_image: string;
  model_name: string;
}

export interface MapMapModelCountryProtocolsGetInfoResponseDomainPrice {
  /** @format int32 */
  domain_id: number;
  domain_name: string;
  /** @format double */
  value: number;
}

export interface MapMapModelCountryProtocolsGetPriceHistoryRequest {
  /** @format int32 */
  listing_option_id: number;
}

export interface MapMapModelCountryProtocolsGetPriceHistoryResponse {
  map_currency: MarqCommonEnumsCurrencyEnum;
  /** @format double */
  map_price: number;
  period: MarqCommonModelLocalDateRange;
  price_chart_list: MapMapModelCountryProtocolsGetPriceHistoryResponseDatePrice[];
  recent_price: MapMapModelCountryProtocolsGetPriceHistoryResponseDatePrice[];
}

export interface MapMapModelCountryProtocolsGetPriceHistoryResponseDatePrice {
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  /** @format double */
  price?: number | null;
}

export interface MapMapModelCountryProtocolsGetViolationAnalysisRequest {
  country: MarqCommonEnumsCountryCodeEnum;
  date_range: MarqCommonModelLocalDateRange;
  domain_list?: number[] | null;
  /** @format int32 */
  model_id: number;
  period: MapMapModelCountryProtocolsMapAnalysisPeriodEnum;
}

export interface MapMapModelCountryProtocolsGetViolationAnalysisResponse {
  lowest_price: MapMapModelCountryProtocolsGetViolationAnalysisResponseLowestPrice;
  violation_listings: MapMapModelCountryProtocolsGetViolationAnalysisResponseViolationListings;
}

export interface MapMapModelCountryProtocolsGetViolationAnalysisResponseLowestPrice {
  group_list: MapMapModelCountryProtocolsGetViolationAnalysisResponseLowestPriceMapPriceChartValue[];
  group_name_list: string[];
  /** @format double */
  map: number;
}

export interface MapMapModelCountryProtocolsGetViolationAnalysisResponseLowestPriceMapPriceChartValue {
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  from_date?: string | null;
  group_list: MapMapModelCountryProtocolsGetViolationAnalysisResponseLowestPricePriceGroupData[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  to_date?: string | null;
}

export interface MapMapModelCountryProtocolsGetViolationAnalysisResponseLowestPricePriceGroupData {
  group_name: string;
  is_lowest: boolean;
  /** @format double */
  price?: number | null;
}

export interface MapMapModelCountryProtocolsGetViolationAnalysisResponseViolationListings {
  group_list: MapMapModelCountryProtocolsGetViolationAnalysisResponseViolationListingsMapListingChartValue[];
  group_name_list: string[];
}

export interface MapMapModelCountryProtocolsGetViolationAnalysisResponseViolationListingsListingGroupData {
  /** @format int32 */
  count?: number | null;
  group_name: string;
  market_group: boolean;
}

export interface MapMapModelCountryProtocolsGetViolationAnalysisResponseViolationListingsMapListingChartValue {
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  from_date?: string | null;
  group_list: MapMapModelCountryProtocolsGetViolationAnalysisResponseViolationListingsListingGroupData[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  to_date?: string | null;
}

export interface MapMapModelCountryProtocolsGetViolationListingsRequest {
  authorized_seller?: boolean | null;
  country: MarqCommonEnumsCountryCodeEnum;
  domain_list?: number[] | null;
  /** @format int32 */
  model_id: number;
}

export interface MapMapModelCountryProtocolsGetViolationListingsResponse {
  listing_list: MapMapModelCountryProtocolsGetViolationListingsResponseMapListing[];
  /** @format int32 */
  total_count: number;
}

export interface MapMapModelCountryProtocolsGetViolationListingsResponseMapListing {
  authorized_seller: boolean;
  currency: MarqCommonEnumsCurrencyEnum;
  domain_name: string;
  /** @format int32 */
  listing_id: number;
  /** @format int32 */
  listing_option_id: number;
  listing_url: string;
  /** @format double */
  price: number;
  seller_name?: string | null;
  title: string;
}

export enum MapMapModelCountryProtocolsMapAnalysisPeriodEnum {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export interface MapMapModelProtocolsGetModelInfoRequest {
  /** @format int32 */
  model_id: number;
  sort: MarqBrandEnumsMapSortEnum;
}

export interface MapMapModelProtocolsGetModelInfoResponse {
  asset_name: string;
  brand_name: string;
  collection_name?: string | null;
  /** @format int32 */
  country_count: number;
  country_item_list: MapMapModelProtocolsGetModelInfoResponseCountryItem[];
  /** @format int32 */
  domain_count: number;
  model_image: string;
  model_name: string;
}

export interface MapMapModelProtocolsGetModelInfoResponseCountryItem {
  country: MarqCommonEnumsCountryCodeEnum;
  currency: MarqCommonEnumsCurrencyEnum;
  domain_item_list: MapMapModelProtocolsGetModelInfoResponseDomainMapItem[];
  /** @format double */
  map: number;
}

export interface MapMapModelProtocolsGetModelInfoResponseDomainMapItem {
  currency: MarqCommonEnumsCurrencyEnum;
  /** @format int32 */
  domain_id: number;
  domain_name: string;
  /** @format double */
  lowest_price: number;
  /** @format int32 */
  violation_listings: number;
  /** @format int32 */
  violation_sellers: number;
}

export interface MapMapProtocolsGetFilterInfoCountryRequest {
  /** @format int32 */
  brand_id?: number | null;
  model_list?: number[] | null;
  search?: string | null;
}

export interface MapMapProtocolsGetFilterInfoModelRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  search?: string | null;
}

export interface MapMapProtocolsGetMapCountRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  model_list?: number[] | null;
  search?: string | null;
}

export interface MapMapProtocolsGetMapCountResponse {
  /** @format int32 */
  count: number;
}

export interface MapMapProtocolsGetMapListRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  /** @format int32 */
  limit: number;
  model_list?: number[] | null;
  /** @format int32 */
  offset: number;
  search?: string | null;
  sort: MarqBrandEnumsMapSortEnum;
}

export interface MapMapProtocolsGetMapListResponse {
  list: MapMapProtocolsGetMapListResponseMapModel[];
}

export interface MapMapProtocolsGetMapListResponseCountryMapItem {
  country: MarqCommonEnumsCountryCodeEnum;
  currency: MarqCommonEnumsCurrencyEnum;
  /** @format double */
  lowest_price: number;
  /** @format double */
  map: number;
  /** @format int32 */
  violation_listings: number;
  /** @format int32 */
  violation_sellers: number;
}

export interface MapMapProtocolsGetMapListResponseMapModel {
  asset_name: string;
  item_list: MapMapProtocolsGetMapListResponseCountryMapItem[];
  /** @format int32 */
  model_id: number;
  model_image: string;
  model_name: string;
}

export enum MarqBrandCoreModelClientListingSortedColumnEnum {
  DetectedDate = 'detected_date',
  Price = 'price',
  ImageRelevancy = 'image_relevancy',
  Marketplace = 'marketplace',
  Asset = 'asset',
  Featured = 'featured',
  SalesVolume = 'sales_volume',
  RequestedDate = 'requested_date',
  UpdatedDate = 'updated_date',
}

export interface MarqBrandCoreModelListingClientNoteData {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date?: string | null;
  doc_list: MarqBrandCoreModelListingClientNoteDataDocumentData[];
  email?: string | null;
  /** @format int32 */
  listing_id?: number | null;
  note: string;
}

export interface MarqBrandCoreModelListingClientNoteDataDocumentData {
  /** @format int32 */
  doc_id?: number | null;
  filename: string;
  url: string;
}

export interface MarqBrandCoreModelProtocolAtomResponse {
  /** @format int32 */
  asset_id: number;
  asset_name: string;
  /** @format int32 */
  atom_id: number;
  atom_model_name: string;
}

export interface MarqBrandCoreModelProtocolCountryDomains {
  /** @format int32 */
  count?: number | null;
  country_code?: MarqCommonEnumsCountryCodeEnum;
  country_name: string;
  domain_list: MarqBrandCoreModelProtocolDomain[];
}

export interface MarqBrandCoreModelProtocolDomain {
  /** @format int32 */
  count?: number | null;
  /** @format int32 */
  domain_id: number;
  domain_name: string;
}

export interface MarqBrandCoreModelProtocolInvestigationTags {
  authentic?: boolean | null;
  copyright: boolean;
  design: boolean;
  free_riding: boolean;
  in_progress?: boolean | null;
  irrelevant?: boolean | null;
  logo_name_conceal: boolean;
  material_diff: boolean;
  model_not_exist: boolean;
  no_authorization: boolean;
  no_counterfeit?: boolean | null;
  no_ip_rights?: boolean | null;
  official_image: boolean;
  official_video: boolean;
  origin_diff: boolean;
  other: boolean;
  reported?: boolean | null;
  shape_diff: boolean;
  size_diff: boolean;
  test_purchased?: boolean | null;
  trademark: boolean;
  unavailable?: boolean | null;
  word_obvious: boolean;
}

export interface MarqBrandCoreModelProtocolTags {
  client_requested: boolean;
  flagged: boolean;
  infringing_image?: boolean | null;
  low_price?: boolean | null;
  official_image: boolean;
  repeat_offender?: boolean | null;
  text_detection: boolean;
  top_result: boolean;
  trademark: boolean;
}

export interface MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem {
  /** @format int32 */
  max_risk_score?: number | null;
  /** @format int32 */
  min_risk_score?: number | null;
}

export interface MarqBrandCoreModelResponseAssetResponse {
  /** @format int32 */
  asset_id: number;
  asset_name: string;
  /** @format int32 */
  brand_id: number;
  brand_name: string;
}

export interface MarqBrandCoreModelResponseClusterProfileResponse {
  address?: string | null;
  business_reg_num?: string | null;
  company_name?: string | null;
  /** @format int32 */
  company_name_seller_count?: number | null;
  country_code?: MarqCommonEnumsCountryCodeEnum;
  country_name?: string | null;
  /** @format int32 */
  country_seller_count?: number | null;
  email?: string | null;
  /** @format int32 */
  email_seller_count?: number | null;
  legal_representative?: string | null;
  marketplace_name?: string | null;
  /** @format int32 */
  marketplace_seller_count?: number | null;
  online_marketing_number?: string | null;
  phone?: string | null;
  seller_name?: string | null;
  /** @format int32 */
  seller_name_seller_count?: number | null;
  seller_type: MarqCommonEnumsSellerAnalyticsSellerTypeEnum;
  seller_url?: string | null;
  shop_name?: string | null;
  /** @format int32 */
  shop_name_seller_count?: number | null;
}

export interface MarqBrandCoreModelResponseCodeLabelComboBoxResponse {
  label: string;
  value: string;
}

export interface MarqBrandCoreModelResponseComboBoxIntValueLabelResponse {
  label: string;
  /** @format int32 */
  value: number;
}

export interface MarqBrandCoreModelResponseDataDrivenInsightResponse {
  action_list: MarqBrandCoreModelResponsePossibleComboBox[];
  infringing_type_list: MarqBrandCoreModelResponsePossibleComboBox[];
  risk_reason_list: MarqBrandCoreModelResponsePossibleComboBox[];
  /** @format int32 */
  risk_score: number;
}

export interface MarqBrandCoreModelResponseIdAndStringValueResponse {
  /** @format int32 */
  id: number;
  value: string;
}

export interface MarqBrandCoreModelResponseIdWithUrl {
  /** @format int32 */
  id: number;
  url?: string | null;
}

export interface MarqBrandCoreModelResponseInvestigationDetailResponse {
  counterfeit_proof_list: MarqBrandCoreModelResponseInvestigationDetailResponseImageWithUrlResponse[];
  /** @format int32 */
  enforceability_info_id: number;
  genuine_proof_list: MarqBrandCoreModelResponseInvestigationDetailResponseImageWithUrlResponse[];
  ip_document_response_list: MarqBrandCoreModelResponseInvestigationDetailResponseImageWithUrlResponse[];
  ipr_used_proof_list: MarqBrandCoreModelResponseInvestigationDetailResponseImageWithUrlResponse[];
  /** @format int32 */
  listing_id: number;
  listing_status: MarqCommonEnumsListingStatusEnum;
  listing_url: string;
  non_enforceable_list: MarqCommonEnumsNonEnforcementReasonEnum[];
  possible_report: boolean;
  possible_tp: boolean;
  report_response?: MarqBrandCoreModelResponseInvestigationDetailResponseReportedStatusSimpleResponse;
  /** @format int32 */
  seller_id?: number | null;
  tp_response?: MarqBrandCoreModelResponseInvestigationDetailResponseTPStatusSimpleResponse;
  use_counterfeit?: boolean | null;
  use_counterfeit_type?: MarqCommonEnumsIpCounterfeitType;
  use_ip_rights?: boolean | null;
  use_ip_rights_type?: MarqCommonEnumsIpRightType;
}

export interface MarqBrandCoreModelResponseInvestigationDetailResponseImageWithUrlResponse {
  /** @format int32 */
  id: number;
  img_url: string;
  name?: string | null;
}

export interface MarqBrandCoreModelResponseInvestigationDetailResponseReportedStatusSimpleResponse {
  /** @format int32 */
  id: number;
  r_id: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  request_date: string;
}

export interface MarqBrandCoreModelResponseInvestigationDetailResponseTPStatusSimpleResponse {
  /** @format int32 */
  id: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  request_date: string;
  status: MarqCommonEnumsTpTPStatusEnum;
  tp_id: string;
}

export interface MarqBrandCoreModelResponseListingDetailResponse {
  ai_result?: MarqCommonEnumsAiClientAIResultEnum;
  asset_name: string;
  brand_name: string;
  converted_currency?: MarqCommonEnumsCurrencyEnum;
  /** @format double */
  converted_price?: number | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00"
   */
  detected_date: string;
  domain_name: string;
  domain_type: MarqCommonEnumsDomainTypeEnum;
  images: MarqBrandDataModelListingImageWithFlagsClient[];
  keywords: string[];
  /** @format int32 */
  like_count?: number | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00"
   */
  listed_date?: string | null;
  listing_currency?: MarqCommonEnumsCurrencyEnum;
  /** @format double */
  listing_price?: number | null;
  listing_status: MarqCommonEnumsListingStatusEnum;
  name: string;
  official_image_name?: string | null;
  /** @format int32 */
  product_id: number;
  /** @format int32 */
  sales_volume?: number | null;
  /** @format int32 */
  seller_id?: number | null;
  seller_name: string;
  snapshot_pdf_url?: string | null;
  snapshot_pdf_url_list?: string[] | null;
  /** @format int32 */
  stock_count?: number | null;
  tag_list: MarqCommonEnumsAiClientTagEnum[];
  tags: MarqBrandCoreModelProtocolTags;
  /** @format int32 */
  text_end_index?: number | null;
  /** @format int32 */
  text_start_index?: number | null;
  url: string;
}

export interface MarqBrandCoreModelResponseMarketplaceResponse {
  /** @format int32 */
  marketplace_id: number;
  marketplace_name: string;
}

export interface MarqBrandCoreModelResponsePossibleComboBox {
  code: string;
  possible: boolean;
}

export interface MarqBrandCoreModelResponsePriceResponse {
  currency: MarqCommonEnumsCurrencyEnum;
  /** @format double */
  gte?: number | null;
  /** @format double */
  lte?: number | null;
}

export interface MarqBrandCoreModelResponseSellerAnalyticResponse {
  cluster?: MarqBrandCoreModelResponseSellerAnalyticResponseClusterSimpleResponse;
  seller?: MarqBrandCoreModelResponseSellerAnalyticResponseSellerSimpleResponse;
}

export interface MarqBrandCoreModelResponseSellerAnalyticResponseClusterSimpleResponse {
  /** @format int32 */
  active_listings: number;
  /** @format int32 */
  cluster_id: number;
  cluster_name: string;
  /** @format int32 */
  seller_count: number;
}

export interface MarqBrandCoreModelResponseSellerAnalyticResponseSellerSimpleResponse {
  /** @format int32 */
  active_listings: number;
  seller_id: string;
  seller_name: string;
}

export interface MarqBrandCoreModelResponseSellerDetailResponse {
  basic_info: MarqBrandCoreModelResponseSellerDetailResponseBasicInfo;
  count_by_listing_status: MarqBrandCoreModelResponseSellerDetailResponseCountByListingStatus;
  first_enforcement_history: MarqBrandCoreModelResponseSellerDetailResponseFirstEnforcementHistory[];
  recent_enforcement_activity: MarqBrandCoreModelResponseSellerDetailResponseRecentEnforcementActivity[];
}

export interface MarqBrandCoreModelResponseSellerDetailResponseBasicInfo {
  /** @format int32 */
  domain_id: number;
  domain_name: string;
  is_brand_specified: boolean;
  is_clustered: boolean;
  is_repeat_offender: boolean;
  seller_id: string;
  seller_name: string;
  seller_url?: string | null;
}

export interface MarqBrandCoreModelResponseSellerDetailResponseCountByListingStatus {
  /** @format int32 */
  active_count: number;
  /** @format int32 */
  detected_count: number;
  /** @format int32 */
  removed_count: number;
}

export interface MarqBrandCoreModelResponseSellerDetailResponseFirstEnforcementHistory {
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  event: string;
}

export interface MarqBrandCoreModelResponseSellerDetailResponseRecentEnforcementActivity {
  listing_title: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  removed_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  reported_date: string;
  status: string;
}

export interface MarqBrandCoreModelResponseSellerDetailSummaryResponse {
  /** @format int32 */
  asset_count: number;
  /** @format int32 */
  available_stock_count: number;
  /**
   * @format date
   * @example "2023-03-01"
   */
  first_detected_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  last_detected_date?: string | null;
  /** @format int64 */
  sales_volume_count: number;
}

export interface MarqBrandCoreModelResponseTPDetailResponse {
  tp_case_info?: MarqBrandCoreModelResponseTPDetailResponseTPCaseInfoResponse;
  tp_report?: MarqBrandCoreModelResponseTPDetailResponseTPReportResponse;
}

export interface MarqBrandCoreModelResponseTPDetailResponseTPCaseInfoResponse {
  case_id: string;
  /** @format int32 */
  domain_id: number;
  domain_name: string;
  expected_progress_list: MarqBrandCoreModelResponseTPDetailResponseTPExpectedHistoryResponse[];
  listing_url?: string | null;
  progress_list: MarqBrandCoreModelResponseTPDetailResponseTPSubHistoryResponse[];
  service_type: MarqCommonEnumsTpTPTypeEnum;
  /** @format int32 */
  tp_id: number;
}

export interface MarqBrandCoreModelResponseTPDetailResponseTPExpectedHistoryResponse {
  active: boolean;
  code: string;
  progress_title: string;
}

export interface MarqBrandCoreModelResponseTPDetailResponseTPFailedReasonResponse {
  reason_detail?: string | null;
  reason_type?: string | null;
}

export interface MarqBrandCoreModelResponseTPDetailResponseTPReportResponse {
  case_id: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  item_received_date?: string | null;
  order_confirmation_name?: string | null;
  order_confirmation_url?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  purchase_date?: string | null;
  return_address?: string | null;
  return_contact?: string | null;
  return_name?: string | null;
  seller_address?: string | null;
  seller_contact?: string | null;
  seller_name?: string | null;
  /** @format int32 */
  tp_id: number;
  tp_image_list: MarqBrandCoreModelResponseTPDetailResponseTPReportResponseImageWithUrlResponse[];
  /** @format int32 */
  tp_result_id: number;
}

export interface MarqBrandCoreModelResponseTPDetailResponseTPReportResponseImageWithUrlResponse {
  /** @format int32 */
  id?: number | null;
  image_name?: string | null;
  image_url: string;
  tp_image_type: MarqCommonEnumsTpTPImageTypeEnum;
}

export interface MarqBrandCoreModelResponseTPDetailResponseTPShippingResponse {
  order_confirmation_name?: string | null;
  order_confirmation_url?: string | null;
  shipping_company?: string | null;
  tracking_number?: string | null;
}

export interface MarqBrandCoreModelResponseTPDetailResponseTPSubHistoryResponse {
  active: boolean;
  additional_info_type: MarqCommonEnumsTpTPAdditionalInfoTypeEnum;
  case_closed: boolean;
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  failed_reason?: MarqBrandCoreModelResponseTPDetailResponseTPFailedReasonResponse;
  progress_title: string;
  shipping?: MarqBrandCoreModelResponseTPDetailResponseTPShippingResponse;
}

export interface MarqBrandDataModelAssetDataFullIpDoc {
  /** @format int32 */
  ip_doc_id: number;
  unique_name: string;
}

export interface MarqBrandDataModelIpDocByGroup {
  class_id?: string | null;
  class_label: string;
  ip_docs: MarqBrandDataModelIpDocByGroupIpDoc[];
}

export interface MarqBrandDataModelIpDocByGroupIpDoc {
  /** @format int32 */
  ip_doc_id: number;
  unique_name: string;
}

export interface MarqBrandDataModelListingImageWithFlagsClient {
  /** @format int32 */
  id: number;
  img_url: string;
  infringing_image_flag: boolean;
  logo_detection_data?: MarqBrandDataTableLogodetectionLogoDetectionData;
  official_image_flag: boolean;
}

export interface MarqBrandDataModelSellerCluster {
  c_sum: MarqBrandDataModelSellerClusterCSum;
  /** @format int32 */
  id: number;
  name?: string | null;
  name_trans?: string | null;
  s_detected_list?: MarqBrandDataModelSellerClusterSSimpleInfo[] | null;
  s_remove_list?: MarqBrandDataModelSellerClusterSSimpleInfo[] | null;
  s_sales_volume_list?: MarqBrandDataModelSellerClusterSSimpleInfo[] | null;
  s_stock_count_list?: MarqBrandDataModelSellerClusterSSimpleInfo[] | null;
}

export interface MarqBrandDataModelSellerClusterCSum {
  /** @format int32 */
  active: number;
  /** @format int32 */
  detected: number;
  /** @format int32 */
  m_count: number;
  /** @format int32 */
  removed: number;
  /** @format int32 */
  s_count: number;
  /** @format int32 */
  s_vol: number;
  /** @format int32 */
  st_count: number;
}

export interface MarqBrandDataModelSellerClusterSSimpleInfo {
  s_id: string;
  s_name: string;
  s_name_trans: string;
  /** @format int32 */
  s_value: number;
}

export interface MarqBrandDataModelSellerClusterAnalysisSellerDataSellerData {
  /** @format int32 */
  active: number;
  address?: string | null;
  address_trans?: string | null;
  bus_reg_num?: string | null;
  comp_name?: string | null;
  comp_name_trans?: string | null;
  /** @format int32 */
  detected: number;
  email?: string | null;
  leg_rep?: string | null;
  leg_rep_trans?: string | null;
  listing_desc?: string | null;
  listing_images?: string[] | null;
  listing_title?: string | null;
  market: string;
  market_country_code: MarqCommonEnumsCountryCodeEnum;
  online_mark_num?: string | null;
  online_mark_num_trans?: string | null;
  phone_num?: string | null;
  /** @format int32 */
  removed: number;
  seller_name?: string | null;
  seller_name_trans?: string | null;
  shop_name?: string | null;
  shop_name_trans?: string | null;
}

export interface MarqBrandDataModelSellerClusterDetailsSummaryAction {
  /** @format int32 */
  seller_notice_count: number;
  /** @format int32 */
  tp_count: number;
}

export interface MarqBrandDataModelSellerClusterDetailsSummaryClustering {
  /** @format int32 */
  country_count: number;
  /** @format int32 */
  marketplace_count: number;
  /** @format int32 */
  seller_count: number;
}

export interface MarqBrandDataModelSellerClusterDetailsSummaryMostUsedInfo {
  address?: string | null;
  /** @format int32 */
  address_matched?: number | null;
  address_trans?: string | null;
  bus_reg_num?: string | null;
  /** @format int32 */
  bus_reg_num_matched?: number | null;
  comp_name?: string | null;
  /** @format int32 */
  comp_name_matched?: number | null;
  comp_name_trans?: string | null;
  email?: string | null;
  /** @format int32 */
  email_matched?: number | null;
  leg_rep?: string | null;
  /** @format int32 */
  leg_rep_matched?: number | null;
  leg_rep_trans?: string | null;
  listing_desc?: string | null;
  /** @format int32 */
  listing_desc_matched?: number | null;
  listing_images?: string[] | null;
  /** @format int32 */
  listing_images_matched?: number | null;
  listing_title?: string | null;
  /** @format int32 */
  listing_title_matched?: number | null;
  online_mark_num?: string | null;
  /** @format int32 */
  online_mark_num_matched?: number | null;
  online_mark_num_trans?: string | null;
  phone_num?: string | null;
  /** @format int32 */
  phone_num_matched?: number | null;
  seller_name?: string | null;
  /** @format int32 */
  seller_name_matched?: number | null;
  seller_name_trans?: string | null;
  shop_name?: string | null;
  /** @format int32 */
  shop_name_matched?: number | null;
  shop_name_trans?: string | null;
}

export interface MarqBrandDataModelSellerClusterDetailsSummaryProcessing {
  /** @format int32 */
  active: number;
  /** @format int32 */
  detected: number;
  /** @format int32 */
  enforcement_req: number;
  /** @format int32 */
  removed: number;
}

export interface MarqBrandDataModelSellerClusterSideInfo {
  c_sum: MarqBrandDataModelSellerClusterSideInfoCSum;
  /** @format int32 */
  id: number;
  name?: string | null;
  name_trans?: string | null;
}

export interface MarqBrandDataModelSellerClusterSideInfoCSum {
  /** @format int32 */
  active: number;
  /** @format int32 */
  detected: number;
  /** @format int32 */
  m_count: number;
  /** @format int32 */
  removed: number;
  /** @format int32 */
  s_count: number;
  /** @format int32 */
  s_vol: number;
  /** @format int32 */
  st_count: number;
}

export interface MarqBrandDataModelSellerCountryWithCountDataListCountryWithCountData {
  /** @format int32 */
  count_data: number;
  country_code: MarqCommonEnumsCountryCodeEnum;
}

export interface MarqBrandDataModelSellerMarketplaceWithCountDataListMarketplaceWithCountData {
  /** @format int32 */
  count_data: number;
  marketplace_name: string;
}

export interface MarqBrandDataModelSellerSellerWithCountDataListSellerWithCountData {
  /** @format int32 */
  count_data: number;
  seller_id: string;
  seller_name: string;
}

export interface MarqBrandDataTableLogodetectionLogoDetectionData {
  /** @format double */
  bb_x1?: number | null;
  /** @format double */
  bb_x2?: number | null;
  /** @format double */
  bb_y1?: number | null;
  /** @format double */
  bb_y2?: number | null;
  /** @format int32 */
  image_height?: number | null;
  /** @format int32 */
  image_width?: number | null;
}

export enum MarqBrandEnumsExpirationFilterOptionEnum {
  Expired = 'expired',
  ExpiringSoon = 'expiring_soon',
}

export enum MarqBrandEnumsListingMonitoringStatusEnum {
  Detection = 'detection',
  Processing = 'processing',
  Reporting = 'reporting',
  SoftNotice = 'soft_notice',
  Removed = 'removed',
  Denied = 'denied',
  NonEnforceable = 'non_enforceable',
  TpAll = 'tp_all',
  Reviewing = 'reviewing',
  Purchase = 'purchase',
  Completed = 'completed',
  CaseClosed = 'case_closed',
  Dismissed = 'dismissed',
  Later = 'later',
  Gray = 'gray',
}

export enum MarqBrandEnumsMapSortEnum {
  ViolationListings = 'violation_listings',
  ViolationSellers = 'violation_sellers',
  LowestPrice = 'lowest_price',
}

export enum MarqBrandEnumsSwBrandEnforceTabEnum {
  UnderReview = 'under_review',
  GoogleDelisting = 'google_delisting',
  SoftNotice = 'soft_notice',
  ReportAbuse = 'report_abuse',
  Udrp = 'udrp',
  NonEc = 'non_ec',
}

export interface MarqBrandModelResponseCounterfeitReasonResponse {
  list: MarqBrandModelResponseCounterfeitReasonResponseReason[];
}

export interface MarqBrandModelResponseCounterfeitReasonResponseReason {
  body: string;
  down: boolean;
  /** @format int32 */
  id: number;
  title: string;
  up: boolean;
}

export interface MarqBrandModelResponseCrUrlResponseInterfaceCommerceItem {
  description?: string | null;
  enforceability?: MarqCommonEnumsEnforceabilityEnum;
  /** @format int32 */
  listing_id?: number | null;
  listing_status?: MarqCommonEnumsListingStatusEnum;
  progress_status: MarqCommonEnumsCrCrUrlProgressStatusEnum;
  status: MarqCommonEnumsCrCRUrlStatusEnum;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date?: string | null;
  url: string;
}

export interface MarqBrandModelResponseCrUrlResponseInterfaceWebsiteItem {
  description?: string | null;
  progress_status: MarqCommonEnumsCrCrUrlProgressStatusEnum;
  status: MarqCommonEnumsCrCRUrlStatusEnum;
  /** @format int32 */
  sw_id?: number | null;
  sw_status?: MarqCommonEnumsSwSWStatusEnum;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date?: string | null;
  url: string;
}

export interface MarqBrandModelResponseDetectedListingResponse {
  ai_result?: MarqCommonEnumsAiClientAIResultEnum;
  /** @format double */
  confidence_score?: number | null;
  converted_currency?: MarqCommonEnumsCurrencyEnum;
  /** @format double */
  converted_price?: number | null;
  description?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  detected_date?: string | null;
  domain_name?: string | null;
  /** @format int32 */
  id: number;
  images: MarqBrandModelResponseDetectedListingResponseImageResponse[];
  list_currency?: MarqCommonEnumsCurrencyEnum;
  listing_url: string;
  name?: string | null;
  /** @format int32 */
  official_image_id?: number | null;
  /** @format double */
  price?: number | null;
  /** @format int32 */
  seller_id?: number | null;
  seller_name: string;
  tag_list: MarqCommonEnumsAiClientTagEnum[];
  tags: MarqBrandCoreModelProtocolTags;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date?: string | null;
}

export interface MarqBrandModelResponseDetectedListingResponseImageResponse {
  default: string;
}

export interface MarqBrandModelResponseDomainCountResponse {
  /** @format int32 */
  all_count: number;
  locked: boolean;
  /** @format int32 */
  new_count: number;
}

export interface MarqBrandModelResponseEnforcementReportListingResponse {
  /** @format double */
  confidence_score?: number | null;
  converted_currency?: MarqCommonEnumsCurrencyEnum;
  /** @format double */
  converted_price?: number | null;
  description?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  detected_date?: string | null;
  domain_name?: string | null;
  /** @format int32 */
  id: number;
  images: MarqBrandModelResponseEnforcementReportListingResponseImageResponse[];
  list_currency?: MarqCommonEnumsCurrencyEnum;
  listing_url: string;
  name?: string | null;
  /** @format int32 */
  official_image_id?: number | null;
  /** @format double */
  price?: number | null;
  /** @format int32 */
  seller_id?: number | null;
  seller_name: string;
  snapshot_pdf_url?: string | null;
  snapshot_pdf_url_list?: string[] | null;
  tag: MarqBrandCoreModelProtocolInvestigationTags;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date?: string | null;
}

export interface MarqBrandModelResponseEnforcementReportListingResponseImageResponse {
  default: string;
  oi_highest_score?: string | null;
}

export interface MarqBrandModelResponseEnforcementTPListingResponse {
  /** @format double */
  confidence_score?: number | null;
  converted_currency?: MarqCommonEnumsCurrencyEnum;
  /** @format double */
  converted_price?: number | null;
  description?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  detected_date?: string | null;
  domain_name?: string | null;
  exist_tp_report: boolean;
  /** @format int32 */
  id: number;
  images: MarqBrandModelResponseEnforcementTPListingResponseImageResponse[];
  list_currency?: MarqCommonEnumsCurrencyEnum;
  listing_url: string;
  name?: string | null;
  /** @format int32 */
  official_image_id?: number | null;
  /** @format double */
  price?: number | null;
  /** @format int32 */
  seller_id?: number | null;
  seller_name: string;
  tp_sub_status: MarqCommonEnumsTpTPSubStatusEnum;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date?: string | null;
}

export interface MarqBrandModelResponseEnforcementTPListingResponseImageResponse {
  default: string;
  oi_highest_score?: string | null;
}

export interface MarqBrandModelResponseListingsChartValue {
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  grouped_count_list?: MarqBrandModelResponseListingsChartValueGroupedCount[] | null;
  /** @format int64 */
  listing_count: number;
  /** @format int64 */
  top_search_count?: number | null;
}

export interface MarqBrandModelResponseListingsChartValueGroupedCount {
  /** @format int64 */
  count: number;
  /** @format int32 */
  id?: number | null;
  name: string;
}

export interface MarqBrandModelResponseSellerCountResponse {
  /** @format int64 */
  count: number;
  /** @format int32 */
  domain_id?: number | null;
  domain_name?: string | null;
  /** @format int64 */
  id: number;
  name: string;
  repeat_offender: boolean;
  /** @format int32 */
  seller_score?: number | null;
}

export interface MarqBrandModelResponseTopData {
  grouped_list?: MarqBrandModelResponseTopDataGroupedTopData[] | null;
  /** @format int64 */
  listing_count: number;
  /** @format double */
  listing_percent: number;
  name: string;
}

export interface MarqBrandModelResponseTopDataGroupedTopData {
  /** @format int64 */
  count: number;
  /** @format int32 */
  group_id?: number | null;
  group_label: string;
}

export interface MarqBrandModelResponseSellerBrandSellerClusterResponse {
  channel_list: MarqCommonEnumsDomainTypeEnum[];
  /** @format int32 */
  cluster_id: number;
  demand_letter?: MarqBrandModelResponseSellerDemandLetterResponse;
  domain_list: string[];
  /** @format int32 */
  enforcement_possible_count: number;
  /**
   * @format date
   * @example "2023-03-01"
   */
  last_detected_date?: string | null;
  name: string;
  /** @format int32 */
  other_seller_count?: number | null;
  /** @format int32 */
  risk_score: number;
  /** @format int32 */
  sn_listing_count: number;
  /** @format int32 */
  total_active: number;
  /** @format int32 */
  total_detected: number;
  /** @format int32 */
  total_removed: number;
}

export interface MarqBrandModelResponseSellerDemandLetterResponse {
  /** @format int32 */
  demand_letter_count: number;
  /** @format int32 */
  removed_listing_count: number;
  /** @format int32 */
  requested_count: number;
  /** @format int32 */
  sent_count: number;
  status_list: MarqCommonEnumsDemandLetterStatusEnum[];
}

export interface MarqBrandModelResponseSellerSellerAnalyticsResponse {
  channel_list: MarqCommonEnumsDomainTypeEnum[];
  /** @format int32 */
  cluster_id?: number | null;
  clustered: boolean;
  demand_letter?: MarqBrandModelResponseSellerDemandLetterResponse;
  domain_list: string[];
  /** @format int32 */
  enforcement_possible_count: number;
  /**
   * @format date
   * @example "2023-03-01"
   */
  last_detected_date?: string | null;
  name: string;
  /** @format int32 */
  risk_score: number;
  seller_id: string;
  seller_url?: string | null;
  /** @format int32 */
  sn_listing_count: number;
  /** @format int32 */
  total_active: number;
  /** @format int32 */
  total_detected: number;
  /** @format int32 */
  total_removed: number;
}

export interface MarqBrandModelResponseSellerSellerAnalyticsSellerInClusterResponse {
  channel_list: MarqCommonEnumsDomainTypeEnum[];
  /** @format int32 */
  cluster_id?: number | null;
  demand_letter?: MarqBrandModelResponseSellerDemandLetterResponse;
  domain_list: string[];
  /** @format int32 */
  enforcement_possible_count: number;
  is_clustered: boolean;
  /**
   * @format date
   * @example "2023-03-01"
   */
  last_detected_date?: string | null;
  name: string;
  /** @format int32 */
  other_seller_count?: number | null;
  /** @format int32 */
  risk_score: number;
  seller_id?: string | null;
  seller_url?: string | null;
  /** @format int32 */
  sn_listing_count: number;
  /** @format int32 */
  total_active: number;
  /** @format int32 */
  total_detected: number;
  /** @format int32 */
  total_removed: number;
}

export interface MarqBrandModelResponseWebsiteIpResponse {
  asn?: string | null;
  country?: string | null;
  /** @format int32 */
  id: number;
  ip_address: string;
  isp?: string | null;
}

export interface MarqBrandModelResponseWebsiteRegistrantResponse {
  address?: string | null;
  email_list: MarqBrandCoreModelResponseIdAndStringValueResponse[];
  fax?: string | null;
  name?: string | null;
  organization?: string | null;
  phone?: string | null;
}

export interface MarqBrandModelResponseWebsiteWebsiteActionProgressResponse {
  active: boolean;
  /**
   * @format date
   * @example "2023-03-01"
   */
  progress_date?: string | null;
  title: string;
}

export interface MarqBrandModelResponseWebsiteWebsiteActionTableListing {
  action_status: string;
  description?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  detected_date: string;
  domain_url: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  expiration_date?: string | null;
  favicon_url?: string | null;
  logo_detection: boolean;
  /**
   * @format date
   * @example "2023-03-01"
   */
  progress_date?: string | null;
  registrar?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  requested_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  target_date: string;
  title: string;
  type: MarqBrandModelResponseWebsiteWebsiteTypeTag;
  /**
   * @format date
   * @example "2023-03-01"
   */
  web_created_date?: string | null;
  /** @format int32 */
  website_id: number;
  website_url: string;
}

export interface MarqBrandModelResponseWebsiteWebsiteDetailInfoResponse {
  /** @format int32 */
  brand_id: number;
  brand_name: string;
  description?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  detected_date?: string | null;
  /** @format int32 */
  detected_website_count: number;
  /** @format int32 */
  domain_id: number;
  domain_url: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  expiration_date?: string | null;
  favicon_url?: string | null;
  investigation_result?: MarqBrandModelResponseWebsiteWebsiteInvestigationResponse;
  ip?: MarqBrandModelResponseWebsiteIpResponse;
  logo_detection: boolean;
  logo_detection_data?: MonitoringWebsiteLogoDetectionResponse;
  nested_url_list: MarqBrandCoreModelResponseIdAndStringValueResponse[];
  proof_url?: string | null;
  registrant?: MarqBrandModelResponseWebsiteRegistrantResponse;
  registrar?: string | null;
  registrar_email_list: MarqBrandCoreModelResponseIdAndStringValueResponse[];
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  requested_date?: string | null;
  soa_email_list: MarqBrandCoreModelResponseIdAndStringValueResponse[];
  thumbnail_url?: string | null;
  title: string;
  type?: MarqBrandModelResponseWebsiteWebsiteTypeResponse;
  /**
   * @format date
   * @example "2023-03-01"
   */
  website_created_date?: string | null;
  /** @format int32 */
  website_id: number;
  website_name: string;
  website_url: string;
}

export interface MarqBrandModelResponseWebsiteWebsiteDetailResponse {
  action_google_delisting?: MarqBrandModelResponseWebsiteWebsiteGoogleDelistingProgressResponse;
  action_report_abuse?: MarqBrandModelResponseWebsiteWebsiteReportAbuseProgressResponse;
  action_soft_notice?: MarqBrandModelResponseWebsiteWebsiteSoftNoticeProgressResponse;
  action_udrp?: MarqBrandModelResponseWebsiteWebsiteUDRPProgressResponse;
  website_info: MarqBrandModelResponseWebsiteWebsiteDetailInfoResponse;
}

export interface MarqBrandModelResponseWebsiteWebsiteGoogleDelistingProgressResponse {
  /** @format int32 */
  case_id: number;
  expected_progress_list: MarqBrandModelResponseWebsiteWebsiteActionProgressResponse[];
  in_progress_list: MarqBrandModelResponseWebsiteWebsiteActionProgressResponse[];
  website_name: string;
  website_url: string;
}

export interface MarqBrandModelResponseWebsiteWebsiteGridListing {
  description?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  detected_date: string;
  domain_url: string;
  favicon_url?: string | null;
  logo_detection: boolean;
  logo_detection_data?: MonitoringWebsiteLogoDetectionResponse;
  /**
   * @format date
   * @example "2023-03-01"
   */
  progress_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  requested_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  target_date: string;
  thumbnail_url?: string | null;
  title: string;
  type: MarqBrandModelResponseWebsiteWebsiteTypeTag;
  /** @format int32 */
  website_id: number;
  website_url: string;
}

export interface MarqBrandModelResponseWebsiteWebsiteInvestigationResponse {
  google_delisting: MarqBrandModelResponseWebsiteWebsiteInvestigationResponseWebsiteActionResponse;
  note?: string | null;
  report_abuse: MarqBrandModelResponseWebsiteWebsiteInvestigationResponseWebsiteActionResponse;
  soft_notice: MarqBrandModelResponseWebsiteWebsiteInvestigationResponseWebsiteActionResponse;
  udrp: MarqBrandModelResponseWebsiteWebsiteInvestigationResponseWebsiteActionResponse;
}

export interface MarqBrandModelResponseWebsiteWebsiteInvestigationResponseWebsiteActionResponse {
  action_possible: MarqCommonEnumsSwActionPossibleEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  action_taken_date?: string | null;
}

export interface MarqBrandModelResponseWebsiteWebsiteReportAbuseProgressResponse {
  /** @format int32 */
  case_id: number;
  expected_progress_list: MarqBrandModelResponseWebsiteWebsiteActionProgressResponse[];
  in_progress_list: MarqBrandModelResponseWebsiteWebsiteActionProgressResponse[];
  website_name: string;
  website_url: string;
}

export interface MarqBrandModelResponseWebsiteWebsiteSoftNoticeProgressResponse {
  /** @format int32 */
  case_id: number;
  expected_progress_list: MarqBrandModelResponseWebsiteWebsiteActionProgressResponse[];
  in_progress_list: MarqBrandModelResponseWebsiteWebsiteActionProgressResponse[];
  website_name: string;
  website_url: string;
}

export interface MarqBrandModelResponseWebsiteWebsiteTableListing {
  description?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  detected_date: string;
  domain_url: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  expiration_date?: string | null;
  favicon_url?: string | null;
  logo_detection: boolean;
  /**
   * @format date
   * @example "2023-03-01"
   */
  progress_date?: string | null;
  registrar?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  requested_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  target_date: string;
  title: string;
  type: MarqBrandModelResponseWebsiteWebsiteTypeTag;
  /**
   * @format date
   * @example "2023-03-01"
   */
  web_created_date?: string | null;
  /** @format int32 */
  website_id: number;
  website_url: string;
}

export interface MarqBrandModelResponseWebsiteWebsiteTypeResponse {
  impersonation: boolean;
}

export interface MarqBrandModelResponseWebsiteWebsiteTypeTag {
  impersonation: boolean;
}

export interface MarqBrandModelResponseWebsiteWebsiteUDRPProgressResponse {
  /** @format int32 */
  case_id: number;
  expected_progress_list: MarqBrandModelResponseWebsiteWebsiteActionProgressResponse[];
  in_progress_list: MarqBrandModelResponseWebsiteWebsiteActionProgressResponse[];
  website_name: string;
  website_url: string;
}

export enum MarqCommonEnumsArchivedReasonEnum {
  Dismissed = 'dismissed',
  Later = 'later',
  Gray = 'gray',
}

export enum MarqCommonEnumsAuthorizedSellerSortedColumnEnum {
  SellerName = 'seller_name',
  AddedDate = 'added_date',
}

export enum MarqCommonEnumsClientStatusEnum {
  Demo = 'demo',
  Deactivated = 'deactivated',
  Test = 'test',
  ActiveClient = 'active_client',
}

export enum MarqCommonEnumsCountryCodeEnum {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CV = 'CV',
  KY = 'KY',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GB = 'GB',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MW = 'MW',
  MV = 'MV',
  MY = 'MY',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SX = 'SX',
  SG = 'SG',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SZ = 'SZ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TL = 'TL',
  TH = 'TH',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  US = 'US',
  UM = 'UM',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
  EU = 'EU',
  Value00 = '00',
  Value10 = '10',
}

export enum MarqCommonEnumsCurrencyEnum {
  LTL = 'LTL',
  THB = 'THB',
  CDF = 'CDF',
  HKD = 'HKD',
  IDR = 'IDR',
  CLF = 'CLF',
  TRY = 'TRY',
  ETB = 'ETB',
  ILS = 'ILS',
  MOP = 'MOP',
  SYP = 'SYP',
  AOA = 'AOA',
  SCR = 'SCR',
  HRK = 'HRK',
  GTQ = 'GTQ',
  SLL = 'SLL',
  HUF = 'HUF',
  MXN = 'MXN',
  CVE = 'CVE',
  RUB = 'RUB',
  JMD = 'JMD',
  AWG = 'AWG',
  ZAR = 'ZAR',
  SAR = 'SAR',
  BWP = 'BWP',
  TOP = 'TOP',
  MNT = 'MNT',
  IRR = 'IRR',
  UYU = 'UYU',
  TTD = 'TTD',
  MYR = 'MYR',
  BND = 'BND',
  ANG = 'ANG',
  IMP = 'IMP',
  BTN = 'BTN',
  QAR = 'QAR',
  SHP = 'SHP',
  CUP = 'CUP',
  BMD = 'BMD',
  SDG = 'SDG',
  LAK = 'LAK',
  AED = 'AED',
  CLP = 'CLP',
  ZMK = 'ZMK',
  RSD = 'RSD',
  KGS = 'KGS',
  KES = 'KES',
  XPF = 'XPF',
  AZN = 'AZN',
  XCD = 'XCD',
  AFN = 'AFN',
  KPW = 'KPW',
  ERN = 'ERN',
  AUD = 'AUD',
  NGN = 'NGN',
  BGN = 'BGN',
  TWD = 'TWD',
  LYD = 'LYD',
  NPR = 'NPR',
  PLN = 'PLN',
  COP = 'COP',
  CRC = 'CRC',
  BHD = 'BHD',
  PKR = 'PKR',
  XOF = 'XOF',
  GBP = 'GBP',
  TND = 'TND',
  OMR = 'OMR',
  MZN = 'MZN',
  GYD = 'GYD',
  CZK = 'CZK',
  MGA = 'MGA',
  DKK = 'DKK',
  KYD = 'KYD',
  GGP = 'GGP',
  GIP = 'GIP',
  MVR = 'MVR',
  RON = 'RON',
  RWF = 'RWF',
  NAD = 'NAD',
  JOD = 'JOD',
  XAU = 'XAU',
  VUV = 'VUV',
  BBD = 'BBD',
  UAH = 'UAH',
  CHF = 'CHF',
  BTC = 'BTC',
  SOS = 'SOS',
  CAD = 'CAD',
  GEL = 'GEL',
  LKR = 'LKR',
  BOB = 'BOB',
  MDL = 'MDL',
  NZD = 'NZD',
  MMK = 'MMK',
  LRD = 'LRD',
  VND = 'VND',
  MRO = 'MRO',
  MRU = 'MRU',
  BYR = 'BYR',
  PGK = 'PGK',
  PYG = 'PYG',
  BZD = 'BZD',
  SRD = 'SRD',
  SEK = 'SEK',
  DZD = 'DZD',
  SVC = 'SVC',
  ARS = 'ARS',
  KHR = 'KHR',
  FJD = 'FJD',
  PEN = 'PEN',
  UZS = 'UZS',
  DJF = 'DJF',
  BRL = 'BRL',
  HNL = 'HNL',
  LBP = 'LBP',
  XAG = 'XAG',
  EGP = 'EGP',
  USD = 'USD',
  TJS = 'TJS',
  JEP = 'JEP',
  FKP = 'FKP',
  TMT = 'TMT',
  PHP = 'PHP',
  LSL = 'LSL',
  ISK = 'ISK',
  YER = 'YER',
  VEF = 'VEF',
  SBD = 'SBD',
  MUR = 'MUR',
  CNY = 'CNY',
  PAB = 'PAB',
  BAM = 'BAM',
  NOK = 'NOK',
  KMF = 'KMF',
  BDT = 'BDT',
  INR = 'INR',
  HTG = 'HTG',
  ALL = 'ALL',
  MWK = 'MWK',
  MAD = 'MAD',
  GMD = 'GMD',
  AMD = 'AMD',
  UGX = 'UGX',
  KRW = 'KRW',
  DOP = 'DOP',
  MKD = 'MKD',
  KWD = 'KWD',
  EUR = 'EUR',
  ZWL = 'ZWL',
  TZS = 'TZS',
  BYN = 'BYN',
  ZMW = 'ZMW',
  XAF = 'XAF',
  STD = 'STD',
  STN = 'STN',
  NIO = 'NIO',
  KZT = 'KZT',
  SGD = 'SGD',
  JPY = 'JPY',
  IQD = 'IQD',
  WST = 'WST',
  BIF = 'BIF',
  GHS = 'GHS',
  XDR = 'XDR',
  LVL = 'LVL',
  CUC = 'CUC',
  SZL = 'SZL',
  BSD = 'BSD',
  GNF = 'GNF',
  VES = 'VES',
  SSP = 'SSP',
}

export enum MarqCommonEnumsDemandLetterStatusEnum {
  Requested = 'requested',
  Reviewing = 'reviewing',
  Delivering = 'delivering',
  SellerResponse = 'seller_response',
  ContactFailed = 'contact_failed',
  CaseClosed = 'case_closed',
}

export enum MarqCommonEnumsDomainTypeEnum {
  Ecommerce = 'ecommerce',
  SocialMedia = 'social_media',
  StandAlone = 'stand_alone',
}

export enum MarqCommonEnumsEnforceabilityEnum {
  ToBeChecked = 'to_be_checked',
  Enforceable = 'enforceable',
  NonEnforceable = 'non_enforceable',
  NotSure = 'not_sure',
}

export enum MarqCommonEnumsEnforcementStatusEnum {
  Processing = 'processing',
  Reporting = 'reporting',
  SoftNotice = 'soft_notice',
  Removed = 'removed',
  Denied = 'denied',
  NonEnforceable = 'non_enforceable',
  Tp = 'tp',
}

export enum MarqCommonEnumsIpRightType {
  Trademark = 'trademark',
  Copyright = 'copyright',
  Design = 'design',
  NotFound = 'not_found',
}

export enum MarqCommonEnumsListingStatusEnum {
  Tbtba = 'tbtba',
  ToBeApproved = 'to_be_approved',
  TbaIr = 'tba_ir',
  Irrelevant = 'irrelevant',
  Unprotected = 'unprotected',
  Authorized = 'authorized',
  Authentic = 'authentic',
  Overpriced = 'overpriced',
  Expired = 'expired',
  PreApproved = 'pre_approved',
  PrePublic = 'pre_public',
  IrrelevantPublic = 'irrelevant_public',
  Unavailable = 'unavailable',
  AuthenticPublic = 'authentic_public',
  Checking = 'checking',
  DocPending = 'doc_pending',
  PreSn = 'pre_sn',
  DocDraft = 'doc_draft',
  DocFirst = 'doc_first',
  DocFinal = 'doc_final',
  PostSn = 'post_sn',
  Approved = 'approved',
  Public = 'public',
  DocApproved = 'doc_approved',
  Investigated = 'investigated',
  OldInvestigated = 'old_investigated',
  Pending = 'pending',
  Dismissed = 'dismissed',
  Later = 'later',
  Gray = 'gray',
  InvDismissed = 'inv_dismissed',
  InvLater = 'inv_later',
  InvGray = 'inv_gray',
  Tbr = 'tbr',
  Unreportable = 'unreportable',
  InReview = 'in_review',
  OnHold = 'on_hold',
  Tp = 'tp',
  Enforced = 'enforced',
  Denied = 'denied',
  NonEc = 'non_ec',
  SelfRemoved = 'self_removed',
  Trash = 'trash',
}

export enum MarqCommonEnumsNonEnforcementReasonEnum {
  NoCounterfeit = 'no_counterfeit',
  NoIpRights = 'no_ip_rights',
  Authentic = 'authentic',
  Irrelevant = 'irrelevant',
  Unavailable = 'unavailable',
}

export enum MarqCommonEnumsOfficialImageSortedByEnum {
  ImageTitle = 'image_title',
  CreatedDate = 'created_date',
}

export enum MarqCommonEnumsOiCollectionSortedByEnum {
  NumberOfImages = 'number_of_images',
  CollectionTitle = 'collection_title',
  CreatedDate = 'created_date',
}

export enum MarqCommonEnumsPerfGroupByEnum {
  None = 'none',
  Marketplace = 'marketplace',
  Country = 'country',
  Asset = 'asset',
  Collection = 'collection',
}

export enum MarqCommonEnumsPerfTimeFrequencyEnum {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export enum MarqCommonEnumsReportActionEnum {
  DigitalDemandLeter = 'digital_demand_leter',
  DemandLetter = 'demand_letter',
  SoftNotice = 'soft_notice',
  CdLetter = 'cd_letter',
  GoogleDelisting = 'google_delisting',
  ReportAbuse = 'report_abuse',
  Udrp = 'udrp',
  MarketplaceReport = 'marketplace_report',
  Tp = 'tp',
  UnreportableSoftNotice = 'unreportable_soft_notice',
  PreInvestigationSoftNotice = 'pre_investigation_soft_notice',
}

export enum MarqCommonEnumsReportableReasonEnum {
  TrademarkWordObvious = 'trademark_word_obvious',
  TrademarkOriginDiff = 'trademark_origin_diff',
  TrademarkSizeDiff = 'trademark_size_diff',
  TrademarkMaterialDiff = 'trademark_material_diff',
  TrademarkShapeDiff = 'trademark_shape_diff',
  TrademarkLogoNameConceal = 'trademark_logo_name_conceal',
  TrademarkModelNotExist = 'trademark_model_not_exist',
  TrademarkFreeRiding = 'trademark_free_riding',
  TrademarkOther = 'trademark_other',
  TrademarkMiscellaneous = 'trademark_miscellaneous',
  TrademarkNotFound = 'trademark_not_found',
  CopyrightWordObvious = 'copyright_word_obvious',
  CopyrightOriginDiff = 'copyright_origin_diff',
  CopyrightSizeDiff = 'copyright_size_diff',
  CopyrightMaterialDiff = 'copyright_material_diff',
  CopyrightShapeDiff = 'copyright_shape_diff',
  CopyrightModelNotExist = 'copyright_model_not_exist',
  CopyrightArtworkUse = 'copyright_artwork_use',
  CopyrightOther = 'copyright_other',
  CopyrightMiscellaneous = 'copyright_miscellaneous',
  CopyrightNotFound = 'copyright_not_found',
  DesignNoAuthorization = 'design_no_authorization',
  DesignMiscellaneous = 'design_miscellaneous',
  DesignNotFound = 'design_not_found',
  OfficialImage = 'official_image',
  OfficialVideo = 'official_video',
  NotFoundMiscellaneous = 'not_found_miscellaneous',
  NotFoundWordObvious = 'not_found_word_obvious',
  NotFoundOriginDiff = 'not_found_origin_diff',
  NotFoundSizeDiff = 'not_found_size_diff',
  NotFoundMaterialDiff = 'not_found_material_diff',
  NotFoundShapeDiff = 'not_found_shape_diff',
  NotFoundLogoNameConceal = 'not_found_logo_name_conceal',
  NotFoundModelNotExist = 'not_found_model_not_exist',
  NotFoundFreeRiding = 'not_found_free_riding',
  NotFoundArtworkUse = 'not_found_artwork_use',
  NotFoundOther = 'not_found_other',
  NotFoundNoAuthorization = 'not_found_no_authorization',
}

export enum MarqCommonEnumsSellerAnalyticsSellerTypeEnum {
  Cluster = 'cluster',
  Ecommerce = 'ecommerce',
  StandAlone = 'stand_alone',
}

export enum MarqCommonEnumsSellerListingStatusGraphTabType {
  AllDays = 'all_days',
  SevenDays = 'seven_days',
  ThirtyDays = 'thirty_days',
  NinetyDays = 'ninety_days',
  OneYears = 'one_years',
}

export enum MarqCommonEnumsSellerSearchSortEnum {
  RiskScore = 'risk_score',
  TotalDetected = 'total_detected',
  TotalRemoved = 'total_removed',
  ActiveListings = 'active_listings',
  SellerName = 'seller_name',
}

export enum MarqCommonEnumsSortedColumnArchivedPageEnum {
  ArchivedDate = 'archived_date',
}

export enum MarqCommonEnumsSortedColumnEnforcementPageEnum {
  UpdatedDate = 'updated_date',
  RequestedDate = 'requested_date',
}

export enum MarqCommonEnumsSortedOrderEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export enum MarqCommonEnumsSortedOrderSellerFilter {
  Asc = 'asc',
  Desc = 'desc',
}

export enum MarqCommonEnumsSortedSellerGroupEnum {
  ListingCount = 'listing_count',
  SellerName = 'seller_name',
  Featured = 'featured',
}

export enum MarqCommonEnumsAiClientAIResultEnum {
  LikelyCounterfeit = 'likely_counterfeit',
  AnalysisResult = 'analysis_result',
}

export enum MarqCommonEnumsAiClientTagEnum {
  Trademark = 'trademark',
  TextDetection = 'text_detection',
  ClientRequested = 'client_requested',
  InfringingImage = 'infringing_image',
  OfficialImage = 'official_image',
  RepeatOffender = 'repeat_offender',
  LowPrice = 'low_price',
  TopResult = 'top_result',
  Flagged = 'flagged',
}

export enum MarqCommonEnumsClientClientPlanType {
  Essential = 'Essential',
  Advanced = 'Advanced',
  Enterprise = 'Enterprise',
}

export enum MarqCommonEnumsClientClientRoleEnum {
  Owner = 'owner',
  Member = 'member',
  LimitedAccess = 'limited_access',
  Admin = 'admin',
}

export enum MarqCommonEnumsCrCRUrlStatusEnum {
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed',
}

export enum MarqCommonEnumsCrClientRequestChannelTypeEnum {
  Ecommerce = 'ecommerce',
  Website = 'website',
  None = 'none',
}

export enum MarqCommonEnumsCrClientRequestEnforcementTypeEnum {
  PrePublic = 'pre_public',
  Enforced = 'enforced',
}

export enum MarqCommonEnumsCrClientRequestTypeEnum {
  Url = 'url',
  Other = 'other',
}

export enum MarqCommonEnumsCrClientTaskFileTypeEnum {
  All = 'all',
  Document = 'document',
  Image = 'image',
  Sheet = 'sheet',
}

export enum MarqCommonEnumsCrClientTaskMessageTypeEnum {
  Open = 'open',
  Reply = 'reply',
  Reopen = 'reopen',
}

export enum MarqCommonEnumsCrClientTaskStatusEnum {
  Opened = 'opened',
  Reopened = 'reopened',
  Replied = 'replied',
  Completed = 'completed',
}

export enum MarqCommonEnumsCrCrUrlProgressStatusEnum {
  MarqVisionUnderReview = 'MarqVision under review',
  Detected = 'Detected',
  TestPurchaseOffered = 'Test Purchase Offered',
  UnderReview = 'Under review',
  Archived = 'Archived',
  EnforcementInProgress = 'Enforcement in Progress',
  Removed = 'Removed',
  CaseClosed = 'Case closed',
  RemovalDenied = 'Removal Denied',
  AuthorizedSellers = 'Authorized sellers',
  AuthorizedWebsites = 'Authorized websites',
  LikelyToBeAuthentic = 'Likely to be authentic',
  URLExpired = 'URL expired',
  NotEnforceable = 'Not enforceable',
  Value = '-',
  InvalidURL = 'Invalid URL',
}

export enum MarqCommonEnumsIpCopyrightType {
  Image = 'image',
  Content = 'content',
  Illust = 'illust',
  Celeb = 'celeb',
  Video = 'video',
  Object = 'object',
}

export enum MarqCommonEnumsIpCounterfeitType {
  WordObvious = 'word_obvious',
  OriginDiff = 'origin_diff',
  SizeDiff = 'size_diff',
  MaterialDiff = 'material_diff',
  ShapeDiff = 'shape_diff',
  LogoNameConceal = 'logo_name_conceal',
  ModelNotExist = 'model_not_exist',
  FreeRiding = 'free_riding',
  ArtworkUse = 'artwork_use',
  VideoUse = 'video_use',
  Other = 'other',
  Misc = 'misc',
  NoAuthorization = 'no_authorization',
  ImageTheft = 'image_theft',
  NotFound = 'not_found',
}

export enum MarqCommonEnumsPerfPerfOverviewFieldEnum {
  Detected = 'detected',
  EnforcementRequested = 'enforcement_requested',
  Removed = 'removed',
}

export enum MarqCommonEnumsSellerintelSellerClusterDataSortByEnum {
  SellerName = 'seller_name',
  BusRegNum = 'bus_reg_num',
  OnlineMarkNum = 'online_mark_num',
  PhoneNum = 'phone_num',
  Email = 'email',
  Address = 'address',
  CompName = 'comp_name',
  LegRep = 'leg_rep',
  ShopName = 'shop_name',
  Market = 'market',
  MarketCountryCode = 'market_country_code',
  ListingImages = 'listing_images',
  ListingTitle = 'listing_title',
  ListingDesc = 'listing_desc',
  Detected = 'detected',
  Removed = 'removed',
  Active = 'active',
}

export enum MarqCommonEnumsSellerintelSellerClusterStatusEnum {
  ActCalculating = 'act_calculating',
  ActActual = 'act_actual',
  Deactivated = 'deactivated',
}

export enum MarqCommonEnumsSellerintelSellerIntelClusterSortBy {
  SCount = 's_count',
  MCount = 'm_count',
  Detected = 'detected',
  Removed = 'removed',
  SVol = 's_vol',
  StCount = 'st_count',
}

export enum MarqCommonEnumsSellerintelSellerIntelDetailsCountriesSortBy {
  SellerCount = 'seller_count',
  MarketplaceCount = 'marketplace_count',
  DetectedCount = 'detected_count',
  EnforcedCount = 'enforced_count',
  SalesVolume = 'sales_volume',
  AvailableStock = 'available_stock',
}

export enum MarqCommonEnumsSellerintelSellerIntelDetailsDomainsSortBy {
  SellerCount = 'seller_count',
  DetectedCount = 'detected_count',
  EnforcedCount = 'enforced_count',
  SalesVolume = 'sales_volume',
  AvailableStock = 'available_stock',
}

export enum MarqCommonEnumsSellerintelSellerIntelDetailsSellersSortBy {
  DetectedCount = 'detected_count',
  EnforcedCount = 'enforced_count',
  SalesVolume = 'sales_volume',
  AvailableStock = 'available_stock',
}

export enum MarqCommonEnumsSellerintelSellerIntelMappingLevel {
  Level1 = 'level_1',
  Level2 = 'level_2',
  Level3 = 'level_3',
  Level4 = 'level_4',
}

export enum MarqCommonEnumsSrSRDetectionActionEnum {
  Enforcement = 'enforcement',
  Dismiss = 'dismiss',
  Later = 'later',
  Gray = 'gray',
  Flag = 'flag',
}

export enum MarqCommonEnumsSrSRDetectionLabelEnum {
  OfficialImage = 'official_image',
  Trademark = 'trademark',
  TextDetection = 'text_detection',
  InfringingImageUsed = 'infringing_image_used',
  RepeatOffender = 'repeat_offender',
  LowPrice = 'low_price',
  TopResult = 'top_result',
}

export enum MarqCommonEnumsStripeBillingHistoryStatusEnum {
  Success = 'success',
  Failed = 'failed',
  Refunded = 'refunded',
}

export enum MarqCommonEnumsStripeStripeCardBrandEnum {
  Amex = 'amex',
  Diners = 'diners',
  Discover = 'discover',
  EftposAu = 'eftpos_au',
  Jcb = 'jcb',
  Mastercard = 'mastercard',
  Unionpay = 'unionpay',
  Visa = 'visa',
  Unknown = 'unknown',
}

export enum MarqCommonEnumsSwActionPossibleEnum {
  Possible = 'possible',
  Impossible = 'impossible',
  ActionTaken = 'action_taken',
}

export enum MarqCommonEnumsSwSWActionResultStatusEnum {
  Removed = 'removed',
  Failed = 'failed',
}

export enum MarqCommonEnumsSwSWGoogleDelistingTabEnum {
  Reviewing = 'reviewing',
  Submitted = 'submitted',
  CaseClosed = 'case_closed',
}

export enum MarqCommonEnumsSwSWReportAbuseTabEnum {
  Reviewing = 'reviewing',
  Submitted = 'submitted',
  CaseClosed = 'case_closed',
}

export enum MarqCommonEnumsSwSWSearchTypeEnum {
  ListingId = 'listing_id',
  Include = 'include',
  DomainName = 'domain_name',
  ImageKeyword = 'image_keyword',
}

export enum MarqCommonEnumsSwSWSoftNoticeTabEnum {
  Reviewing = 'reviewing',
  Submitted = 'submitted',
  CaseClosed = 'case_closed',
}

export enum MarqCommonEnumsSwSWStatusEnum {
  ThirdFetched = 'third_fetched',
  ToBeApproved = 'to_be_approved',
  Approved = 'approved',
  Public = 'public',
  InvestigatePending = 'investigate_pending',
  InvInProgress = 'inv_in_progress',
  Investigated = 'investigated',
  ActionTaken = 'action_taken',
  NonEnforceable = 'non_enforceable',
  Irrelevant = 'irrelevant',
  Authorized = 'authorized',
  Trash = 'trash',
  Ignore = 'ignore',
  Closed = 'closed',
  InvCompleted = 'inv_completed',
  NotUse = 'not_use',
}

export enum MarqCommonEnumsSwSWUDRPStatusEnum {
  Requested = 'requested',
  Review = 'review',
  ReviewCheck = 'review_check',
  ReportReceive = 'report_receive',
  ReportCheck = 'report_check',
  ReportConstCheck = 'report_const_check',
  ReportShare = 'report_share',
  ReportDomain = 'report_domain',
  ModifiedInProgress = 'modified_in_progress',
  ModifiedCheck = 'modified_check',
  ModifiedShare = 'modified_share',
  DomainGracePeriod = 'domain_grace_period',
  SharedResult = 'shared_result',
  AfterDecision = 'after_decision',
  CaseClosed = 'case_closed',
  Removed = 'removed',
  Failed = 'failed',
}

export enum MarqCommonEnumsSwSWUDRPTabEnum {
  Reviewing = 'reviewing',
  Submitted = 'submitted',
  CaseClosed = 'case_closed',
}

export enum MarqCommonEnumsSwWebsiteSortedColumnEnum {
  DetectedDate = 'detected_date',
  RequestDate = 'request_date',
  PublicDate = 'public_date',
  UpdatedDate = 'updated_date',
  DomainName = 'domain_name',
  Featured = 'featured',
  ProgressDate = 'progress_date',
}

export enum MarqCommonEnumsTpTPAdditionalInfoTypeEnum {
  None = 'none',
  OrderConfirm = 'order_confirm',
  Delivering = 'delivering',
  TpUnavailable = 'tp_unavailable',
  DeliveringFailed = 'delivering_failed',
  Report = 'report',
}

export enum MarqCommonEnumsTpTPImageTypeEnum {
  Waybill = 'waybill',
  Package = 'package',
  Product = 'product',
  Labels = 'labels',
  Others = 'others',
  Report = 'report',
  Image = 'image',
}

export enum MarqCommonEnumsTpTPStatusEnum {
  TpPending = 'tp_pending',
  TpReview = 'tp_review',
  TpAvailable = 'tp_available',
  TpUnavailable = 'tp_unavailable',
  TpPayHold = 'tp_pay_hold',
  TpPaymentDenied = 'tp_payment_denied',
  TpPayConfirm = 'tp_pay_confirm',
  TpPrepare = 'tp_prepare',
  TpOrder = 'tp_order',
  TpOrderNoti = 'tp_order_noti',
  TpReceiptSuccess = 'tp_receipt_success',
  TpReceiptSuccessNoti = 'tp_receipt_success_noti',
  TpReceiptFailed = 'tp_receipt_failed',
  TpReceiptFailedNoti = 'tp_receipt_failed_noti',
  TpReportPub = 'tp_report_pub',
  TpReportPubNoti = 'tp_report_pub_noti',
  TpDelivering = 'tp_delivering',
  TpDeliveringNoti = 'tp_delivering_noti',
  TpDelivered = 'tp_delivered',
  TpCompleted = 'tp_completed',
  TpCompletedConfirm = 'tp_completed_confirm',
  TpInvoiceDelivery = 'tp_invoice_delivery',
  TpFinalPayHold = 'tp_final_pay_hold',
  TpFinalPayConfirm = 'tp_final_pay_confirm',
  TpFinalPayDenied = 'tp_final_pay_denied',
  TpPartnerPay = 'tp_partner_pay',
}

export enum MarqCommonEnumsTpTPSubStatusEnum {
  Reviewing = 'reviewing',
  Available = 'available',
  Canceled = 'canceled',
  Order = 'order',
  ItemReceived = 'item_received',
  ReportProvided = 'report_provided',
  Delivery = 'delivery',
  Delivered = 'delivered',
  Completed = 'completed',
  Unavailable = 'unavailable',
  ReceiptFailed = 'receipt_failed',
}

export enum MarqCommonEnumsTpTPTypeEnum {
  Standard = 'standard',
  Basic = 'basic',
}

export interface MarqCommonModelLocalDateRange {
  /**
   * @format date
   * @example "2023-03-01"
   */
  end?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start?: string | null;
}

export interface MarqCommonModelOffsetDateTimeRange {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  end: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  start: string;
}

export type MarqCommonProtocolEmptyRequest = object;

export type MarqCommonProtocolEmptyResponse = object;

export interface MarqCommonProtocolFileResponse {
  /** @format byte */
  bytes: string;
  content_type: IoKtorHttpContentType;
  filename: string;
}

export interface MonitoringDetectedBySellerProtocolsGetAllListingIdForSellerRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  /** @format int32 */
  seller_id?: number | null;
  seller_name_filter?: string[] | null;
  seller_sorted_column: MarqCommonEnumsSortedSellerGroupEnum;
  seller_sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedBySellerProtocolsGetAllListingIdForSellerResponse {
  listing_id_list: number[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  /** @format int32 */
  limit: number;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  /** @format int32 */
  offset: number;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  seller_sorted_column: MarqCommonEnumsSortedSellerGroupEnum;
  seller_sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerResponse {
  seller_count_list: MarqBrandModelResponseSellerCountResponse[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  /** @format int32 */
  seller_id?: number | null;
  seller_name_filter?: string[] | null;
  sorted_column?: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdResponse {
  /** @format int32 */
  domain_id?: number | null;
  domain_name?: string | null;
  listing_list: MarqBrandModelResponseDetectedListingResponse[];
  seller_page_url?: string | null;
  snapshot_path?: string | null;
  /** @format int32 */
  total_count: number;
}

export interface MonitoringDetectedBySellerProtocolsGetDetectedListingsByListingIdRequest {
  listing_id_list: number[];
}

export interface MonitoringDetectedBySellerProtocolsGetDetectedListingsByListingIdResponse {
  listing_list: MarqBrandModelResponseDetectedListingResponse[];
}

export interface MonitoringDetectedBySellerProtocolsGetSellerDetailsRequest {
  /** @format int32 */
  brand_id?: number | null;
  /** @format int32 */
  seller_id: number;
}

export interface MonitoringDetectedBySellerProtocolsGetSellerDetailsResponse {
  seller_detail: MarqBrandCoreModelResponseSellerDetailResponse;
}

export interface MonitoringDetectedBySellerProtocolsGetSellerExportListingsRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  listing_id_list?: number[] | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  /** @format int32 */
  seller_id?: number | null;
  seller_name_filter?: string[] | null;
  seller_sorted_column: MarqCommonEnumsSortedSellerGroupEnum;
  seller_sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedBySellerProtocolsSellerExcelExportRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  /** @format int32 */
  seller_id?: number | null;
  seller_name_filter?: string[] | null;
  seller_sorted_column: MarqCommonEnumsSortedSellerGroupEnum;
  seller_sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedBySellerProtocolsSellerExcelExportResponse {
  /** @format int32 */
  progress_id: number;
}

export interface MonitoringDetectedBySellerProtocolsSellerExcelExportByListingIdRequest {
  listing_id_list?: number[] | null;
}

export interface MonitoringDetectedBySellerProtocolsSellerExcelResultRequest {
  /** @format int32 */
  progress_id: number;
}

export interface MonitoringDetectedBySellerProtocolsSellerExcelResultResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringDetectedListingViewProtocolsExcelExportRequest {
  asset_id_filter: number[];
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter: number[];
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter: number[];
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter: number[];
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  search_date_filter?: string | null;
  seller_name_filter: string[];
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedListingViewProtocolsExcelExportResponse {
  /** @format int32 */
  progress_id: number;
}

export interface MonitoringDetectedListingViewProtocolsExcelExportByListingIdRequest {
  listing_id_list?: number[] | null;
}

export interface MonitoringDetectedListingViewProtocolsExcelResultRequest {
  /** @format int32 */
  progress_id: number;
}

export interface MonitoringDetectedListingViewProtocolsExcelResultResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringDetectedListingViewProtocolsGetAllListingIdRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  search_date_filter?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column?: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedListingViewProtocolsGetAllListingIdResponse {
  listing_id_list: number[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringDetectedListingViewProtocolsGetDetectedCountRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  refresh?: boolean | null;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedListingViewProtocolsGetDetectedCountResponse {
  /** @format int32 */
  total_count: number;
}

export interface MonitoringDetectedListingViewProtocolsGetDetectedListingsRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  /** @format int32 */
  limit: number;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  /** @format int32 */
  offset: number;
  refresh?: boolean | null;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  search_date_filter?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedListingViewProtocolsGetDetectedListingsResponse {
  asset_exists: boolean;
  listings: MarqBrandModelResponseDetectedListingResponse[];
}

export interface MonitoringDetectedListingViewProtocolsGetOldDetectedListingsRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  /** @format int32 */
  limit: number;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  /** @format int32 */
  offset: number;
  refresh?: boolean | null;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  search_date_filter?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedListingViewProtocolsGetOldDetectedListingsResponse {
  asset_exists: boolean;
  listings: MarqBrandModelResponseDetectedListingResponse[];
}

export interface MonitoringDetectedListingViewProtocolsGetOldExportListingsRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  infringing_images_filter?: boolean | null;
  listing_id_list?: number[] | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  repeat_offender_filter?: boolean | null;
  search?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  search_date_filter?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
}

export interface MonitoringDetectedProtocolsCloseDetectedTipResponse {
  detected_tip: boolean;
}

export interface MonitoringDetectedProtocolsDetectedRequestActiveRequest {
  domain_type: MarqCommonEnumsDomainTypeEnum;
}

export interface MonitoringDetectedProtocolsGetDetectedCountByDomainTypeRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface MonitoringDetectedProtocolsGetDetectedCountByDomainTypeResponse {
  ecommerce: MarqBrandModelResponseDomainCountResponse;
  social_media: MarqBrandModelResponseDomainCountResponse;
  stand_alone: MarqBrandModelResponseDomainCountResponse;
}

export interface MonitoringDetectedProtocolsGetReportingCapResponse {
  /** @format int32 */
  cap: number;
  /** @format int32 */
  remaining: number;
}

export interface MonitoringDetectedProtocolsReadNoteRequest {
  /** @format int32 */
  listing_id: number;
}

export interface MonitoringDetectedProtocolsReadNoteResponse {
  note?: MarqBrandCoreModelListingClientNoteData;
}

export interface MonitoringDetectedProtocolsRequestTestPurchaseRequest {
  listing_id_list: number[];
  tp_type: MarqCommonEnumsTpTPTypeEnum;
}

export interface MonitoringDetectedProtocolsUpdateToArchivedRequest {
  listing_ids: number[];
  status: MarqCommonEnumsListingStatusEnum;
}

export interface MonitoringEnforcementProtocolsGetCountByDomainTypeRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface MonitoringEnforcementProtocolsGetCountByDomainTypeResponse {
  ecommerce: MarqBrandModelResponseDomainCountResponse;
  social_media: MarqBrandModelResponseDomainCountResponse;
  stand_alone: MarqBrandModelResponseDomainCountResponse;
}

export interface MonitoringEnforcementProtocolsGetCountProgressRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface MonitoringEnforcementProtocolsGetCountProgressResponse {
  /** @format int32 */
  reporting: number;
  /** @format int32 */
  soft_notice: number;
}

export interface MonitoringEnforcementProtocolsGetCountStatusRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface MonitoringEnforcementProtocolsGetCountStatusResponse {
  /** @format int32 */
  in_progress: number;
  in_progress_badge: boolean;
  /** @format int32 */
  processing: number;
  processing_badge: boolean;
  /** @format int32 */
  removed: number;
  removed_badge: boolean;
  tp_badge: boolean;
}

export interface MonitoringEnforcementReportProtocolsExcelExportRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  enforcement_rule_id_filter?: number[] | null;
  model_id_filter?: number[] | null;
  reportable_filter?: MarqCommonEnumsReportableReasonEnum[] | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqCommonEnumsSortedColumnEnforcementPageEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  updated_date_range_filter?: MarqCommonModelLocalDateRange;
}

export interface MonitoringEnforcementReportProtocolsExcelExportResponse {
  /** @format int32 */
  progress_id: number;
}

export interface MonitoringEnforcementReportProtocolsExcelExportByListingIdRequest {
  listing_id_list?: number[] | null;
}

export interface MonitoringEnforcementReportProtocolsExcelResultRequest {
  /** @format int32 */
  progress_id: number;
}

export interface MonitoringEnforcementReportProtocolsExcelResultResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringEnforcementReportProtocolsGetAllListingIdRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  enforcement_rule_id_filter?: number[] | null;
  model_id_filter?: number[] | null;
  reportable_filter?: MarqCommonEnumsReportableReasonEnum[] | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  updated_date_range_filter?: MarqCommonModelLocalDateRange;
}

export interface MonitoringEnforcementReportProtocolsGetAllListingIdResponse {
  listing_id_list: number[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringEnforcementReportProtocolsGetAllSnapshotListRequest {
  listing_id_list: number[];
}

export interface MonitoringEnforcementReportProtocolsGetAllSnapshotListResponse {
  snapshot_list: MonitoringEnforcementReportProtocolsGetAllSnapshotListResponseSnapshotResponse[];
}

export interface MonitoringEnforcementReportProtocolsGetAllSnapshotListResponseSnapshotResponse {
  /** @format int32 */
  listing_id: number;
  snapshot_url: string;
}

export interface MonitoringEnforcementReportProtocolsGetEnforcementReportListingsRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  enforcement_rule_id_filter?: number[] | null;
  /** @format int32 */
  limit: number;
  model_id_filter?: number[] | null;
  /** @format int32 */
  offset: number;
  reportable_filter?: MarqCommonEnumsReportableReasonEnum[] | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  updated_date_range_filter?: MarqCommonModelLocalDateRange;
}

export interface MonitoringEnforcementReportProtocolsGetEnforcementReportListingsResponse {
  asset_exists: boolean;
  listings: MarqBrandModelResponseEnforcementReportListingResponse[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringEnforcementReportProtocolsGetExportListingsRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  enforcement_rule_id_filter?: number[] | null;
  listing_id_list?: number[] | null;
  model_id_filter?: number[] | null;
  reportable_filter?: MarqCommonEnumsReportableReasonEnum[] | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  updated_date_range_filter?: MarqCommonModelLocalDateRange;
}

export interface MonitoringEnforcementTPProtocolsExcelExportRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  model_id_filter?: number[] | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringEnforcementTPProtocolsExcelExportResponse {
  /** @format int32 */
  progress_id: number;
}

export interface MonitoringEnforcementTPProtocolsExcelExportByListingIdRequest {
  listing_id_list?: number[] | null;
}

export interface MonitoringEnforcementTPProtocolsExcelResultRequest {
  /** @format int32 */
  progress_id: number;
}

export interface MonitoringEnforcementTPProtocolsExcelResultResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringEnforcementTPProtocolsGetAllListingIdRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  model_id_filter?: number[] | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringEnforcementTPProtocolsGetAllListingIdResponse {
  listing_id_list: number[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringEnforcementTPProtocolsGetCountAndRecentlyListingResponse {
  exist_new_closed?: boolean | null;
  exist_new_completed?: boolean | null;
  exist_new_purchase?: boolean | null;
  exist_new_review?: boolean | null;
  /** @format int32 */
  purchase_count: number;
  /** @format int32 */
  review_count: number;
}

export interface MonitoringEnforcementTPProtocolsGetEnforcementTPListingsRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  /** @format int32 */
  limit: number;
  model_id_filter?: number[] | null;
  /** @format int32 */
  offset: number;
  search?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringEnforcementTPProtocolsGetEnforcementTPListingsResponse {
  asset_exists: boolean;
  listings: MarqBrandModelResponseEnforcementTPListingResponse[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringEnforcementTPProtocolsGetExportListingsRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  listing_id_list?: number[] | null;
  model_id_filter?: number[] | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringEnforcementTPProtocolsGetNoteDownloadUrlRequest {
  /** @format int32 */
  doc_id: number;
  /** @format int32 */
  listing_id: number;
}

export interface MonitoringEnforcementTPProtocolsGetNoteDownloadUrlResponse {
  url: string;
}

export interface MonitoringFilterProtocolPagingRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  enforcement_rule_id_filter?: number[] | null;
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  in_search_keyword_filter?: string | null;
  include_expired?: boolean | null;
  infringing_images_filter?: boolean | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  repeat_offender_filter?: boolean | null;
  reportable_filter?: MarqCommonEnumsReportableReasonEnum[] | null;
  search?: string | null;
  search_after: KotlinAny[];
  seller_name_filter?: string[] | null;
  /** @format int32 */
  size: number;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
  updated_date_range_filter?: MarqCommonModelLocalDateRange;
}

export interface MonitoringFilterProtocolRequest {
  asset_id_filter?: number[] | null;
  /** @format int32 */
  brand_id?: number | null;
  client_requested_filter?: boolean | null;
  client_status_filter: MarqBrandEnumsListingMonitoringStatusEnum;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_id_filter?: number[] | null;
  domain_type: MarqCommonEnumsDomainTypeEnum[];
  enforcement_rule_id_filter?: number[] | null;
  flag_id_filter?: number[] | null;
  gen_ai_counterfeit_filter?: boolean | null;
  include_expired?: boolean | null;
  infringing_images_filter?: boolean | null;
  low_price_filter?: boolean | null;
  /** @format double */
  max_price?: number | null;
  /** @format double */
  max_sales_volume?: number | null;
  /** @format double */
  min_price?: number | null;
  /** @format double */
  min_sales_volume?: number | null;
  model_id_filter?: number[] | null;
  official_image_id_filter?: number[] | null;
  repeat_offender_filter?: boolean | null;
  reportable_filter?: MarqCommonEnumsReportableReasonEnum[] | null;
  search?: string | null;
  seller_name_filter?: string[] | null;
  tm_infringement_filter?: boolean | null;
  tm_infringement_text_filter?: boolean | null;
  top_result_filter?: boolean | null;
  updated_date_range_filter?: MarqCommonModelLocalDateRange;
}

export interface MonitoringFilterProtocolResponse {
  /** @format int32 */
  count?: number | null;
  filter_display: boolean;
  list: MonitoringFilterProtocolResponseFilterItem[];
  search_after?: KotlinAny[] | null;
}

export interface MonitoringFilterProtocolResponseFilterItem {
  /** @format int32 */
  count?: number | null;
  id: KotlinAny;
  image_url?: string | null;
  list?: MonitoringFilterProtocolResponseFilterItem[] | null;
  text: string;
}

export interface MonitoringDetectionDetSmartRuleProtocolConditionRequest {
  asset_list?: number[] | null;
  atom_list?: number[] | null;
  label_list?: MarqCommonEnumsSrSRDetectionLabelEnum[] | null;
  marketplace_list?: number[] | null;
  price?: MarqBrandCoreModelResponsePriceResponse;
  seller_name_list?: string[] | null;
  title_contains?: string[] | null;
}

export interface MonitoringDetectionDetSmartRuleProtocolConditionResponse {
  asset_list?: MarqBrandCoreModelResponseAssetResponse[] | null;
  atom_list?: MarqBrandCoreModelProtocolAtomResponse[] | null;
  label_list?: MarqCommonEnumsSrSRDetectionLabelEnum[] | null;
  marketplace_list?: MarqBrandCoreModelResponseMarketplaceResponse[] | null;
  price?: MarqBrandCoreModelResponsePriceResponse;
  seller_name_list?: string[] | null;
  title_contains?: string[] | null;
}

export interface MonitoringDetectionDetSmartRuleProtocolCreateRuleRequest {
  action: MarqCommonEnumsSrSRDetectionActionEnum;
  /** @format int32 */
  brand_id: number;
  condition?: MonitoringDetectionDetSmartRuleProtocolConditionRequest;
  /** @format int32 */
  credit_limit: number;
  rule_title: string;
}

export interface MonitoringDetectionDetSmartRuleProtocolCreateRuleResponse {
  /** @format int32 */
  id: number;
}

export interface MonitoringDetectionDetSmartRuleProtocolDeleteRuleRequest {
  /** @format int32 */
  rule_id: number;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetAutoExhaustCreditResponse {
  auto_exhaust_credit: boolean;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAssetRequest {
  /** @format int32 */
  brand_id: number;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAssetResponse {
  asset_list: MarqBrandCoreModelResponseAssetResponse[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomRequest {
  /** @format int32 */
  brand_id: number;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomResponse {
  asset_list: MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomResponseAssetWithAtomResponse[];
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomResponseAssetWithAtomResponse {
  /** @format int32 */
  asset_id: number;
  asset_name: string;
  atom_list: MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomResponseAtom[];
  brand_name: string;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomResponseAtom {
  /** @format int32 */
  asset_id: number;
  /** @format int32 */
  atom_id: number;
  atom_image: string;
  atom_model_name: string;
  atom_model_number?: string | null;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoDomainRequest {
  /** @format int32 */
  client_id: number;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoDomainResponse {
  domain_list: MarqBrandCoreModelProtocolCountryDomains[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoSellerRequest {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  client_id: number;
  domain_id_list: number[];
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  search?: string | null;
  sorted_order: MarqCommonEnumsSortedOrderSellerFilter;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetFilterInfoSellerResponse {
  seller_name_list: string[];
  /** @format int32 */
  total_count: number;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetListRequest {
  action_list?: MarqCommonEnumsSrSRDetectionActionEnum[] | null;
  activated?: boolean | null;
  label_list?: MarqCommonEnumsSrSRDetectionLabelEnum[] | null;
}

export interface MonitoringDetectionDetSmartRuleProtocolGetListResponse {
  cap_exhausted: boolean;
  list: MonitoringDetectionDetSmartRuleProtocolGetListResponseRule[];
}

export interface MonitoringDetectionDetSmartRuleProtocolGetListResponseRule {
  action: MarqCommonEnumsSrSRDetectionActionEnum;
  activated: boolean;
  /** @format int32 */
  brand_id: number;
  brand_name: string;
  condition: MonitoringDetectionDetSmartRuleProtocolConditionResponse;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date: string;
  /** @format int32 */
  credit_limit: number;
  domain_type: MarqCommonEnumsDomainTypeEnum;
  /** @format int32 */
  id: number;
  /** @format int32 */
  order: number;
  rule_title: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date: string;
}

export interface MonitoringDetectionDetSmartRuleProtocolSetAutoExhaustCreditRequest {
  auto_exhaust_credit: boolean;
}

export interface MonitoringDetectionDetSmartRuleProtocolToggleRuleRequest {
  active: boolean;
  /** @format int32 */
  rule_id: number;
}

export interface MonitoringDetectionDetSmartRuleProtocolUpdateOrderRequest {
  rule_id_list: number[];
}

export interface MonitoringDetectionDetSmartRuleProtocolUpdateRuleRequest {
  action: MarqCommonEnumsSrSRDetectionActionEnum;
  /** @format int32 */
  brand_id: number;
  condition?: MonitoringDetectionDetSmartRuleProtocolConditionRequest;
  /** @format int32 */
  credit_limit: number;
  /** @format int32 */
  rule_id: number;
  rule_title: string;
}

export interface MonitoringPerfPerformanceProtocolsGetClientCountryListResponse {
  country_list: MarqBrandCoreModelResponseCodeLabelComboBoxResponse[];
}

export interface MonitoringPerfPerformanceProtocolsGetCohortAnalysisRequest {
  asset_id_list: number[];
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetCohortAnalysisResponse {
  data: MonitoringPerfPerformanceProtocolsGetCohortAnalysisResponseCohortMonthData[];
  /** @format double */
  removal_rate: number;
}

export interface MonitoringPerfPerformanceProtocolsGetCohortAnalysisResponseCohortMonthData {
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  /** @format int64 */
  detected_count: number;
  /** @format int64 */
  enforcement_req_count: number;
  /** @format int64 */
  removed_count: number;
}

export interface MonitoringPerfPerformanceProtocolsGetCohortAnalysisCSVNewRequest {
  asset_id_list: number[];
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetCohortFilterNewRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetCohortFilterNewResponse {
  asset_list: MarqBrandCoreModelResponseComboBoxIntValueLabelResponse[];
}

export interface MonitoringPerfPerformanceProtocolsGetComplianceRateRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetComplianceRateResponse {
  /** @format double */
  denied_percent: number;
  /** @format double */
  enforced_percent: number;
  /** @format double */
  in_review_percent: number;
}

export interface MonitoringPerfPerformanceProtocolsGetComplianceRateTopMarketsRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetComplianceRateTopMarketsResponse {
  rate_list: MonitoringPerfPerformanceProtocolsGetComplianceRateTopMarketsResponseRate[];
}

export interface MonitoringPerfPerformanceProtocolsGetComplianceRateTopMarketsResponseRate {
  domain_name: string;
  /** @format double */
  percent: number;
}

export interface MonitoringPerfPerformanceProtocolsGetEnforcedValueRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetEnforcedValueResponse {
  chart_value_list: MonitoringPerfPerformanceProtocolsGetEnforcedValueResponseChartValue[];
  /** @format int64 */
  enforced_value: number;
}

export interface MonitoringPerfPerformanceProtocolsGetEnforcedValueResponseChartValue {
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  /** @format int64 */
  value: number;
}

export interface MonitoringPerfPerformanceProtocolsGetExpertServicesRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetExpertServicesResponse {
  /** @format int64 */
  demand_letter_count: number;
  /** @format int64 */
  tp_count: number;
}

export interface MonitoringPerfPerformanceProtocolsGetImpactedSellerRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list?: MarqCommonEnumsCountryCodeEnum[] | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetImpactedSellerResponse {
  /** @format int64 */
  impacted_seller_count: number;
  /** @format int64 */
  repeat_offender_count: number;
  /** @format int32 */
  repeat_offender_percent: number;
}

export interface MonitoringPerfPerformanceProtocolsGetRemovedListingsOverTimeRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetRemovedListingsOverTimeResponse {
  chart_value_list: MarqBrandModelResponseListingsChartValue[];
  /** @format int64 */
  total_count: number;
}

export interface MonitoringPerfPerformanceProtocolsGetTopInfringingSellersRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetTopInfringingSellersResponse {
  top_sellers: MonitoringPerfPerformanceProtocolsGetTopInfringingSellersResponseTopSeller[];
}

export interface MonitoringPerfPerformanceProtocolsGetTopInfringingSellersResponseTopSeller {
  /** @format int32 */
  id: number;
  marketplace_name: string;
  name: string;
  /** @format int64 */
  removed_count: number;
  seller_string_id: string;
}

export interface MonitoringPerfPerformanceProtocolsGetWebsiteCountsRequest {
  /** @format int32 */
  brand_id?: number | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfPerformanceProtocolsGetWebsiteCountsResponse {
  /** @format int64 */
  detected_count: number;
  /** @format int64 */
  enforced_count: number;
  /** @format int64 */
  enforcement_req_count: number;
  /** @format int64 */
  udrp_count: number;
}

export interface MonitoringPerfPerformanceReportProtocolsGetListRequest {
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringPerfPerformanceReportProtocolsGetListResponse {
  list: MonitoringPerfPerformanceReportProtocolsGetListResponseReport[];
}

export interface MonitoringPerfPerformanceReportProtocolsGetListResponseReport {
  filename: string;
  /** @format int32 */
  id: number;
  s3url: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  uploaded_date: string;
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateResponse {
  /** @format double */
  denied_percent: number;
  /** @format double */
  enforced_percent: number;
  /** @format double */
  four_week_percent: number;
  /** @format double */
  in_review_percent: number;
  /** @format double */
  two_week_percent: number;
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  frequency_filter?: MarqCommonEnumsPerfTimeFrequencyEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeResponse {
  chart_value_list: MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeResponseCompRateChartValue[];
  frequency_filter_list: MarqBrandCoreModelResponseCodeLabelComboBoxResponse[];
  /** @format int64 */
  removed_count: number;
  /** @format int64 */
  reported_count: number;
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeResponseCompRateChartValue {
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  /** @format int64 */
  removed_count: number;
  /** @format int64 */
  reported_count: number;
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateOverTimeCsvRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  frequency_filter?: MarqCommonEnumsPerfTimeFrequencyEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopCsvRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopDataRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopDataResponse {
  top_data_list: MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopDataResponseTopData[];
}

export interface MonitoringPerfDetailsPerfDetailsCompRateProtocolsGetComplianceRateTopDataResponseTopData {
  domain_name: string;
  /** @format double */
  rate_percent: number;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingCountByAnalysisTypeRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingCountByAnalysisTypeResponse {
  /** @format int64 */
  detected_count: number;
  /** @format int64 */
  enforcement_requested_count: number;
  /** @format int64 */
  removed_count: number;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingsOverTimeRequest {
  analysis_type: MarqCommonEnumsPerfPerfOverviewFieldEnum;
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  frequency_filter?: MarqCommonEnumsPerfTimeFrequencyEnum;
  group_by_filter: MarqCommonEnumsPerfGroupByEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingsOverTimeResponse {
  chart_value_list: MarqBrandModelResponseListingsChartValue[];
  frequency_filter_list: MarqBrandCoreModelResponseCodeLabelComboBoxResponse[];
  /** @format int64 */
  total_count: number;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingsOverTimeCsvRequest {
  analysis_type: MarqCommonEnumsPerfPerfOverviewFieldEnum;
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  frequency_filter?: MarqCommonEnumsPerfTimeFrequencyEnum;
  group_by_filter: MarqCommonEnumsPerfGroupByEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetRemovedDataRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetRemovedDataResponse {
  /** @format int64 */
  enforced_value: number;
  /** @format int64 */
  removed_units: number;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopAssetCsvRequest {
  analysis_type: MarqCommonEnumsPerfPerfOverviewFieldEnum;
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  group_by_filter: MarqCommonEnumsPerfGroupByEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopAssetDataRequest {
  analysis_type: MarqCommonEnumsPerfPerfOverviewFieldEnum;
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  group_by_filter: MarqCommonEnumsPerfGroupByEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopAssetDataResponse {
  top_data_list: MarqBrandModelResponseTopData[];
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopCountryCsvRequest {
  analysis_type: MarqCommonEnumsPerfPerfOverviewFieldEnum;
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  group_by_filter: MarqCommonEnumsPerfGroupByEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopCountryDataRequest {
  analysis_type: MarqCommonEnumsPerfPerfOverviewFieldEnum;
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  group_by_filter: MarqCommonEnumsPerfGroupByEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopCountryDataResponse {
  top_data_list: MarqBrandModelResponseTopData[];
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopMarketplaceCsvRequest {
  analysis_type: MarqCommonEnumsPerfPerfOverviewFieldEnum;
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  group_by_filter: MarqCommonEnumsPerfGroupByEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopMarketplaceDataRequest {
  analysis_type: MarqCommonEnumsPerfPerfOverviewFieldEnum;
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  group_by_filter: MarqCommonEnumsPerfGroupByEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopMarketplaceDataResponse {
  top_data_list: MarqBrandModelResponseTopData[];
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetImpactedSellersRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetImpactedSellersResponse {
  impacted_sellers: MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetImpactedSellersResponseSellerData;
  /** @format double */
  repeat_offender_percent: number;
  repeat_offenders: MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetImpactedSellersResponseSellerData;
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetImpactedSellersResponseSellerData {
  /** @format int64 */
  total_detected_listings: number;
  /** @format int64 */
  total_removed_listings: number;
  /** @format int64 */
  total_sellers: number;
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetMarketplaceFilterListRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetMarketplaceFilterListResponse {
  marketplace_filter: MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetMarketplaceFilterListResponseCountryMarkets[];
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetMarketplaceFilterListResponseCountryMarkets {
  country: string;
  marketplace_list: MarqBrandCoreModelResponseComboBoxIntValueLabelResponse[];
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  marketplace_filter: number[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerResponse {
  seller_list: MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerResponseTopInfringingSeller[];
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerResponseTopInfringingSeller {
  /** @format int64 */
  active_count: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  first_detected?: string | null;
  /** @format int32 */
  id: number;
  marketplace_name: string;
  /** @format int64 */
  removed_count: number;
  seller_name: string;
  seller_string_id: string;
}

export interface MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerCsvRequest {
  /** @format int32 */
  brand_id?: number | null;
  country_list: MarqCommonEnumsCountryCodeEnum[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  marketplace_filter: number[];
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
}

export interface MonitoringWebsiteLogoDetectionResponse {
  /** @format double */
  bb_x1?: number | null;
  /** @format double */
  bb_x2?: number | null;
  /** @format double */
  bb_y1?: number | null;
  /** @format double */
  bb_y2?: number | null;
  /** @format int32 */
  image_height?: number | null;
  /** @format int32 */
  image_width?: number | null;
}

export interface MonitoringWebsiteSWDetectionProtocolsExcelResultRequest {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWDetectionProtocolsExcelResultResponse {
  complete: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetAllWebsiteIdListRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetAllWebsiteIdListResponse {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWDetectionProtocolsGetCountRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetCountResponse {
  /** @format int32 */
  count: number;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetDomainFilterRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  in_search_keyword_filter?: string | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type?: MarqCommonEnumsSwSWSearchTypeEnum;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetExportListingsRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  website_id_list?: number[] | null;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetExportListingsResponse {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetExportListingsByListingIdRequest {
  website_id_list?: number[] | null;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteGridListing[];
}

export interface MonitoringWebsiteSWDetectionProtocolsGetReportingCapResponse {
  /** @format int32 */
  cap: number;
  /** @format int32 */
  remaining: number;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteTableListing[];
}

export interface MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailResponse {
  website_detail: MarqBrandModelResponseWebsiteWebsiteDetailResponse;
}

export interface MonitoringWebsiteSWDetectionProtocolsRequestAuthorizedRequest {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWDetectionProtocolsRequestAuthorizedResponse {
  /** @format int32 */
  affected: number;
  domain_name: string;
}

export interface MonitoringWebsiteSWDetectionProtocolsRequestEnforceRequest {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWDetectionProtocolsRequestEnforceResponse {
  /** @format int32 */
  affected: number;
  listing_image_list?: string[] | null;
  /** @format int32 */
  remaining: number;
  /** @format int32 */
  request_id?: number | null;
  success: boolean;
  title: string;
}

export interface MonitoringWebsiteSWDetectionProtocolsRequestIgnoreRequest {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWDetectionProtocolsRequestIgnoreResponse {
  /** @format int32 */
  affected: number;
  image_url_list?: string[] | null;
  success: boolean;
  title: string;
}

export interface MonitoringWebsiteSWDetectionProtocolsUndoEnforcedRequest {
  /** @format int32 */
  request_id: number;
}

export interface MonitoringWebsiteSWDetectionProtocolsUndoEnforcedResponse {
  success: boolean;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsExcelResultRequest {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsExcelResultResponse {
  complete: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetAllWebsiteIdListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWGoogleDelistingTabEnum;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetAllWebsiteIdListResponse {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  tab: MarqCommonEnumsSwSWGoogleDelistingTabEnum;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountResponse {
  /** @format int32 */
  count: number;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountAndRecentlyStatusRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetCountAndRecentlyStatusResponse {
  exist_new_case_closed: boolean;
  exist_new_reviewing: boolean;
  exist_new_submitted: boolean;
  /** @format int32 */
  reviewing_count: number;
  /** @format int32 */
  submitted_count: number;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetExportListingsRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWGoogleDelistingTabEnum;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetExportListingsResponse {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetExportListingsByListingIdRequest {
  website_id_list?: number[] | null;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetGridWebsiteListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWGoogleDelistingTabEnum;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetGridWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteGridListing[];
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetTableWebsiteListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWGoogleDelistingTabEnum;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetTableWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteActionTableListing[];
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetWebsiteDetailRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsGetWebsiteDetailResponse {
  website_detail: MarqBrandModelResponseWebsiteWebsiteDetailResponse;
}

export interface MonitoringWebsiteSWEnforcedGoogleDelistingProtocolsRequestUDRPRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsExcelResultRequest {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsExcelResultResponse {
  complete: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetAllWebsiteIdListRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetAllWebsiteIdListResponse {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetCountRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetCountResponse {
  /** @format int32 */
  count: number;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetExportListingsRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetExportListingsResponse {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetExportListingsByListingIdRequest {
  website_id_list?: number[] | null;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetGridWebsiteListRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetGridWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteGridListing[];
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetTableWebsiteListRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetTableWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteTableListing[];
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetWebsiteDetailRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetWebsiteDetailResponse {
  website_detail: MarqBrandModelResponseWebsiteWebsiteDetailResponse;
}

export interface MonitoringWebsiteSWEnforcedProtocolsGetDomainFilterRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  in_search_keyword_filter?: string | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type?: MarqCommonEnumsSwSWSearchTypeEnum;
  sub_tab?: string | null;
  tab: MarqBrandEnumsSwBrandEnforceTabEnum;
}

export interface MonitoringWebsiteSWEnforcedProtocolsGetEnforcementTabsRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface MonitoringWebsiteSWEnforcedProtocolsGetEnforcementTabsResponse {
  google_delisting: MonitoringWebsiteSWEnforcedProtocolsGetEnforcementTabsTabResponse;
  report_abuse: MonitoringWebsiteSWEnforcedProtocolsGetEnforcementTabsTabResponse;
  soft_notice: MonitoringWebsiteSWEnforcedProtocolsGetEnforcementTabsTabResponse;
  udrp: MonitoringWebsiteSWEnforcedProtocolsGetEnforcementTabsTabResponse;
}

export interface MonitoringWebsiteSWEnforcedProtocolsGetEnforcementTabsTabResponse {
  /** @format int32 */
  in_progress: number;
  updated: boolean;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsExcelResultRequest {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsExcelResultResponse {
  complete: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetAllWebsiteIdListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWReportAbuseTabEnum;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetAllWebsiteIdListResponse {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  tab: MarqCommonEnumsSwSWReportAbuseTabEnum;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountResponse {
  /** @format int32 */
  count: number;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountAndRecentlyStatusRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountAndRecentlyStatusResponse {
  exist_new_case_closed: boolean;
  exist_new_reviewing: boolean;
  exist_new_submitted: boolean;
  /** @format int32 */
  reviewing_count: number;
  /** @format int32 */
  submitted_count: number;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetExportListingsRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWReportAbuseTabEnum;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetExportListingsResponse {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetExportListingsByListingIdRequest {
  website_id_list?: number[] | null;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetGridWebsiteListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWReportAbuseTabEnum;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetGridWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteGridListing[];
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetTableWebsiteListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWReportAbuseTabEnum;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetTableWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteActionTableListing[];
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetWebsiteDetailRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetWebsiteDetailResponse {
  website_detail: MarqBrandModelResponseWebsiteWebsiteDetailResponse;
}

export interface MonitoringWebsiteSWEnforcedReportAbuseProtocolsRequestUDRPRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsExcelResultRequest {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsExcelResultResponse {
  complete: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetAllWebsiteIdListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWSoftNoticeTabEnum;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetAllWebsiteIdListResponse {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  tab: MarqCommonEnumsSwSWSoftNoticeTabEnum;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountResponse {
  /** @format int32 */
  count: number;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountAndRecentlyStatusRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountAndRecentlyStatusResponse {
  exist_new_case_closed: boolean;
  exist_new_reviewing: boolean;
  exist_new_submitted: boolean;
  /** @format int32 */
  reviewing_count: number;
  /** @format int32 */
  submitted_count: number;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetExportListingsRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWSoftNoticeTabEnum;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetExportListingsResponse {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetExportListingsByListingIdRequest {
  website_id_list?: number[] | null;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetGridWebsiteListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWSoftNoticeTabEnum;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetGridWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteGridListing[];
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetTableWebsiteListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWSoftNoticeTabEnum;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetTableWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteActionTableListing[];
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetWebsiteDetailRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetWebsiteDetailResponse {
  website_detail: MarqBrandModelResponseWebsiteWebsiteDetailResponse;
}

export interface MonitoringWebsiteSWEnforcedSoftNoticeProtocolsRequestUDRPRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsExcelResultRequest {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsExcelResultResponse {
  complete: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetAllWebsiteIdListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWUDRPTabEnum;
  udrp_status_list?: MarqCommonEnumsSwSWUDRPStatusEnum[] | null;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetAllWebsiteIdListResponse {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  tab: MarqCommonEnumsSwSWUDRPTabEnum;
  udrp_status_list?: MarqCommonEnumsSwSWUDRPStatusEnum[] | null;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountResponse {
  /** @format int32 */
  count: number;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountAndRecentlyStatusRequest {
  /** @format int32 */
  brand_id?: number | null;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetCountAndRecentlyStatusResponse {
  exist_new_case_closed: boolean;
  exist_new_reviewing: boolean;
  exist_new_submitted: boolean;
  /** @format int32 */
  reviewing_count: number;
  /** @format int32 */
  submitted_count: number;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetExportListingsRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWUDRPTabEnum;
  udrp_status_list?: MarqCommonEnumsSwSWUDRPStatusEnum[] | null;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetExportListingsResponse {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetExportListingsByListingIdRequest {
  website_id_list?: number[] | null;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetGridWebsiteListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWUDRPTabEnum;
  udrp_status_list?: MarqCommonEnumsSwSWUDRPStatusEnum[] | null;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetGridWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteGridListing[];
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetStatusFilterByTabRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  tab: MarqCommonEnumsSwSWUDRPTabEnum;
  udrp_status_list?: MarqCommonEnumsSwSWUDRPStatusEnum[] | null;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetStatusFilterByTabResponse {
  status_list: MarqBrandCoreModelResponseCodeLabelComboBoxResponse[];
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetTableWebsiteListRequest {
  action_status?: MarqCommonEnumsSwSWActionResultStatusEnum;
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  tab: MarqCommonEnumsSwSWUDRPTabEnum;
  udrp_status_list?: MarqCommonEnumsSwSWUDRPStatusEnum[] | null;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetTableWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteActionTableListing[];
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetWebsiteDetailRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWEnforcedUDRPProtocolsGetWebsiteDetailResponse {
  website_detail: MarqBrandModelResponseWebsiteWebsiteDetailResponse;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsExcelResultRequest {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsExcelResultResponse {
  complete: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetAllWebsiteIdListRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetAllWebsiteIdListResponse {
  website_id_list: number[];
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetCountRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetCountResponse {
  /** @format int32 */
  count: number;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetExportListingsRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  logo_detection?: boolean | null;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  website_id_list?: number[] | null;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetExportListingsResponse {
  /** @format int32 */
  id: number;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetExportListingsByListingIdRequest {
  website_id_list?: number[] | null;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetGridWebsiteListRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetGridWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteGridListing[];
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetTableWebsiteListRequest {
  /** @format int32 */
  brand_id?: number | null;
  impersonation?: boolean | null;
  /** @format int32 */
  limit?: number | null;
  logo_detection?: boolean | null;
  /** @format int32 */
  offset: number;
  root_domain_id_list?: number[] | null;
  search?: string | null;
  search_type: MarqCommonEnumsSwSWSearchTypeEnum;
  sorted_column: MarqCommonEnumsSwWebsiteSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetTableWebsiteListResponse {
  website_list: MarqBrandModelResponseWebsiteWebsiteTableListing[];
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetWebsiteDetailRequest {
  /** @format int32 */
  website_id: number;
}

export interface MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetWebsiteDetailResponse {
  website_detail: MarqBrandModelResponseWebsiteWebsiteDetailResponse;
}

export interface PortfolioAssetProtocolsBulkUpdateAssetRequest {
  asset_ids: number[];
  currency?: MarqCommonEnumsCurrencyEnum;
  /** @format double */
  min_price?: number | null;
  related_ip_doc_ids?: number[] | null;
}

export interface PortfolioAssetProtocolsCreateAssetByPresignedRequest {
  asset_name: string;
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  collection_id?: number | null;
  currency: MarqCommonEnumsCurrencyEnum;
  image_file_url_list: string[];
  /** @format double */
  min_price: number;
  product_code?: string[] | null;
  product_url?: string | null;
  related_ip_doc_ids: number[];
}

export interface PortfolioAssetProtocolsCreateCollectionRequest {
  /** @format int32 */
  brand_id: number;
  collection_name: string;
}

export interface PortfolioAssetProtocolsCreateCollectionResponse {
  /** @format int32 */
  collection_id: number;
}

export interface PortfolioAssetProtocolsDeleteAssetRequest {
  /** @format int32 */
  asset_id: number;
}

export interface PortfolioAssetProtocolsDeleteCollectionRequest {
  /** @format int32 */
  collection_id: number;
}

export interface PortfolioAssetProtocolsGetAllCollectionsResponse {
  brand_collection: PortfolioAssetProtocolsGetAllCollectionsResponseIpPortfolioCollection[];
}

export interface PortfolioAssetProtocolsGetAllCollectionsResponseCollectionData {
  /** @format int32 */
  id: number;
  name: string;
}

export interface PortfolioAssetProtocolsGetAllCollectionsResponseIpPortfolioCollection {
  brand: PortfolioAssetProtocolsGetAllCollectionsResponseIpPortfolioData;
  collection_tree: PortfolioAssetProtocolsGetAllCollectionsResponseCollectionData[];
}

export interface PortfolioAssetProtocolsGetAllCollectionsResponseIpPortfolioData {
  /** @format int32 */
  id: number;
  name: string;
}

export interface PortfolioAssetProtocolsGetAssetRequest {
  /** @format int32 */
  id: number;
}

export interface PortfolioAssetProtocolsGetAssetResponse {
  /** @format int32 */
  asset_brand_id: number;
  /** @format int32 */
  asset_collection_id?: number | null;
  asset_currency: MarqCommonEnumsCurrencyEnum;
  asset_full_ip_doc_list: MarqBrandDataModelAssetDataFullIpDoc[];
  /** @format int32 */
  asset_id: number;
  asset_images: string[];
  /** @format double */
  asset_min_price?: number | null;
  asset_name: string;
  asset_product_code?: string[] | null;
  asset_product_url?: string | null;
  asset_related_ip_doc_ids: number[];
}

export interface PortfolioAssetProtocolsGetAssetListRequest {
  /** @format int32 */
  brand_id?: number | null;
  /** @format int32 */
  collection_id?: number | null;
}

export interface PortfolioAssetProtocolsGetAssetListResponse {
  asset_list: PortfolioAssetProtocolsGetAssetListResponseAsset[];
}

export interface PortfolioAssetProtocolsGetAssetListResponseAsset {
  /** @format int32 */
  brand_id: number;
  brand_name: string;
  /** @format int32 */
  collection_id?: number | null;
  currency: MarqCommonEnumsCurrencyEnum;
  /** @format int32 */
  id: number;
  images: string[];
  /** @format double */
  min_price?: number | null;
  name: string;
  product_code?: string[] | null;
  product_url?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date?: string | null;
}

export interface PortfolioAssetProtocolsGetAssetPresignedUrlRequest {
  file_name: string;
}

export interface PortfolioAssetProtocolsGetAssetPresignedUrlResponse {
  url: string;
}

export interface PortfolioAssetProtocolsGetIpDocumentsRequest {
  /** @format int32 */
  brand_id: number;
}

export interface PortfolioAssetProtocolsGetIpDocumentsResponse {
  ip_documents: MarqBrandDataModelIpDocByGroup[];
}

export interface PortfolioAssetProtocolsGetIpDocumentsUniqueNameRequest {
  /** @format int32 */
  brand_id: number;
}

export interface PortfolioAssetProtocolsGetIpDocumentsUniqueNameResponse {
  full_ip_doc_list: MarqBrandDataModelAssetDataFullIpDoc[];
}

export interface PortfolioAssetProtocolsUpdateAssetByPresignedRequest {
  added_image_string_list: string[];
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  collection_id?: number | null;
  currency: MarqCommonEnumsCurrencyEnum;
  deleted_image_string_list: string[];
  /** @format int32 */
  id: number;
  image_file_url_list?: string[] | null;
  /** @format double */
  min_price: number;
  name: string;
  product_code?: string[] | null;
  product_url?: string | null;
  related_ip_doc_ids: number[];
  urls?: string[] | null;
}

export interface PortfolioAssetProtocolsUpdateCollectionRequest {
  /** @format int32 */
  collection_id: number;
  collection_name: string;
}

export interface PortfolioCopyrightsProtocolsAddCopyrightRequest {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  doc_id: number;
  list: PortfolioCopyrightsProtocolsAddCopyrightRequestItem[];
}

export interface PortfolioCopyrightsProtocolsAddCopyrightRequestItem {
  name: string;
  thumbnail_url: string;
}

export interface PortfolioCopyrightsProtocolsAddDocumentRequest {
  /** @format int32 */
  brand_id: number;
  copyright_type?: MarqCommonEnumsIpCopyrightType;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  creation?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration?: string | null;
  file_name: string;
  file_url: string;
  /** @format int32 */
  licensee_id?: number | null;
  name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  publication?: string | null;
  registration_no?: string | null;
  /** @format int32 */
  rights_owner_id: number;
}

export interface PortfolioCopyrightsProtocolsAddDocumentResponse {
  /** @format int32 */
  id: number;
}

export interface PortfolioCopyrightsProtocolsDeleteCopyrightRequest {
  /** @format int32 */
  doc_id: number;
  /** @format int32 */
  id: number;
}

export interface PortfolioCopyrightsProtocolsDeleteDocumentRequest {
  /** @format int32 */
  id: number;
}

export interface PortfolioCopyrightsProtocolsGetCopyrightsRequest {
  /** @format int32 */
  doc_id: number;
  sorted_order_enum: MarqCommonEnumsSortedOrderEnum;
}

export interface PortfolioCopyrightsProtocolsGetCopyrightsResponse {
  doc_name: string;
  list: PortfolioCopyrightsProtocolsGetCopyrightsResponseCopyright[];
}

export interface PortfolioCopyrightsProtocolsGetCopyrightsResponseCopyright {
  /** @format int32 */
  id: number;
  name: string;
  thumbnail_url: string;
}

export interface PortfolioCopyrightsProtocolsGetDocumentDetailsRequest {
  /** @format int32 */
  id: number;
}

export interface PortfolioCopyrightsProtocolsGetDocumentDetailsResponse {
  copyright_type?: MarqCommonEnumsIpCopyrightType;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  creation?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration?: string | null;
  file_name?: string | null;
  file_url?: string | null;
  /** @format int32 */
  id: number;
  /** @format int32 */
  licensee_id?: number | null;
  name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  publication?: string | null;
  registration_no?: string | null;
  /** @format int32 */
  rights_owner_id: number;
}

export interface PortfolioCopyrightsProtocolsGetDocumentsRequest {
  /** @format int32 */
  brand_id: number;
}

export interface PortfolioCopyrightsProtocolsGetDocumentsResponse {
  list: PortfolioCopyrightsProtocolsGetDocumentsResponseDocument[];
}

export interface PortfolioCopyrightsProtocolsGetDocumentsResponseDocument {
  copyright_type?: MarqCommonEnumsIpCopyrightType;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  creation?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration?: string | null;
  file_name?: string | null;
  file_url?: string | null;
  /** @format int32 */
  id: number;
  licensee?: string | null;
  name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  publication?: string | null;
  registration_no?: string | null;
  rights_owner: string;
}

export interface PortfolioCopyrightsProtocolsUpdateCopyrightRequest {
  /** @format int32 */
  doc_id: number;
  /** @format int32 */
  id: number;
  name: string;
  thumbnail_url: string;
}

export interface PortfolioCopyrightsProtocolsUpdateDocumentRequest {
  copyright_type?: MarqCommonEnumsIpCopyrightType;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  creation?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration?: string | null;
  file_name: string;
  file_url: string;
  /** @format int32 */
  id: number;
  /** @format int32 */
  licensee_id?: number | null;
  name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  publication?: string | null;
  registration_no?: string | null;
  /** @format int32 */
  rights_owner_id: number;
}

export interface PortfolioDesignProtocolsAddDesignRequest {
  /** @format int32 */
  brand_id: number;
  countries: MarqCommonEnumsCountryCodeEnum[];
  document_name: string;
  document_url: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration_date: string;
  ip_class: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  licensee_expiration_date?: string | null;
  /** @format int32 */
  licensee_id?: number | null;
  name: string;
  /** @format int32 */
  owner_id: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  registered_date?: string | null;
  registration_no: string;
  thumbnail_url?: string | null;
}

export interface PortfolioDesignProtocolsAddDesignResponse {
  /** @format int32 */
  doc_id: number;
}

export interface PortfolioDesignProtocolsDeleteDesignRequest {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  doc_id: number;
}

export interface PortfolioDesignProtocolsGetDesignDetailRequest {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  doc_id: number;
}

export interface PortfolioDesignProtocolsGetDesignDetailResponse {
  countries: MarqCommonEnumsCountryCodeEnum[];
  document_name: string;
  document_url: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration_date?: string | null;
  ip_class?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  licensee_expiration_date?: string | null;
  /** @format int32 */
  licensee_id?: number | null;
  name: string;
  /** @format int32 */
  owner_id?: number | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  registered_date?: string | null;
  registration_no?: string | null;
  thumbnail_url?: string | null;
}

export interface PortfolioDesignProtocolsGetDesignListRequest {
  /** @format int32 */
  brand_id: number;
  country?: MarqCommonEnumsCountryCodeEnum[] | null;
  expiration_date?: MarqCommonModelOffsetDateTimeRange;
  ip_class?: string[] | null;
  is_expired_filter?: boolean | null;
  is_expiring_soon_filter?: boolean | null;
  keyword?: string | null;
  registered_date?: MarqCommonModelOffsetDateTimeRange;
}

export interface PortfolioDesignProtocolsGetDesignListResponse {
  design_list: PortfolioDesignProtocolsGetDesignListResponseDesign[];
}

export interface PortfolioDesignProtocolsGetDesignListResponseDesign {
  countries: MarqCommonEnumsCountryCodeEnum[];
  /** @format int32 */
  doc_id: number;
  document_name: string;
  document_url: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration_date?: string | null;
  ip_class?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  licensee_expiration_date?: string | null;
  name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  registered_date?: string | null;
  registration_no?: string | null;
  rights_licensee?: string | null;
  rights_owner?: string | null;
  thumbnail?: string | null;
}

export interface PortfolioDesignProtocolsUpdateDesignRequest {
  /** @format int32 */
  brand_id: number;
  countries: MarqCommonEnumsCountryCodeEnum[];
  /** @format int32 */
  doc_id: number;
  document_name: string;
  document_url: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration_date: string;
  ip_class: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  licensee_expiration_date?: string | null;
  /** @format int32 */
  licensee_id?: number | null;
  name: string;
  /** @format int32 */
  owner_id: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  registered_date?: string | null;
  registration_no: string;
  thumbnail_url?: string | null;
}

export interface PortfolioIpDocProtocolsAddBrandRequest {
  copyright_footer?: string | null;
  logo_url: string;
  name: string;
}

export interface PortfolioIpDocProtocolsAddBrandResponse {
  /** @format int32 */
  brand_id: number;
}

export interface PortfolioIpDocProtocolsDeleteBrandRequest {
  /** @format int32 */
  brand_id: number;
}

export interface PortfolioIpDocProtocolsGetBrandDetailRequest {
  /** @format int32 */
  brand_id: number;
}

export interface PortfolioIpDocProtocolsGetBrandDetailResponse {
  copyright_footer?: string | null;
  /** @format int32 */
  copyrights: number;
  /** @format int32 */
  designs: number;
  logo_url: string;
  name: string;
  /** @format int32 */
  trademarks: number;
}

export interface PortfolioIpDocProtocolsGetBrandListResponse {
  brand_list: PortfolioIpDocProtocolsGetBrandListResponseBrand[];
}

export interface PortfolioIpDocProtocolsGetBrandListResponseBrand {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  copyrights: number;
  /** @format int32 */
  designs: number;
  logo_url: string;
  name: string;
  /** @format int32 */
  trademarks: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  updated_date: string;
}

export interface PortfolioIpDocProtocolsGetDownloadUrlRequest {
  url: string;
}

export interface PortfolioIpDocProtocolsGetDownloadUrlResponse {
  url: string;
}

export interface PortfolioIpDocProtocolsGetLicenseeListResponse {
  list: PortfolioIpDocProtocolsGetLicenseeListResponseRightsLicensee[];
}

export interface PortfolioIpDocProtocolsGetLicenseeListResponseRightsLicensee {
  /** @format int32 */
  licensee_id: number;
  licensee_name: string;
}

export interface PortfolioIpDocProtocolsGetOwnerListResponse {
  list: PortfolioIpDocProtocolsGetOwnerListResponseRightsOwner[];
}

export interface PortfolioIpDocProtocolsGetOwnerListResponseRightsOwner {
  /** @format int32 */
  owner_id: number;
  owner_name: string;
}

export interface PortfolioIpDocProtocolsGetUploadUrlRequest {
  /** @format int32 */
  brand_id?: number | null;
  file_name: string;
}

export interface PortfolioIpDocProtocolsGetUploadUrlResponse {
  url: string;
}

export interface PortfolioIpDocProtocolsUpdateBrandRequest {
  /** @format int32 */
  brand_id: number;
  copyright_footer?: string | null;
  logo_url: string;
  name: string;
}

export interface PortfolioTrademarkProtocolsAddTrademarkRequest {
  /** @format int32 */
  brand_id: number;
  class_list: string[];
  countries: MarqCommonEnumsCountryCodeEnum[];
  document_name: string;
  document_url: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration_date: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  licensee_expiration_date?: string | null;
  /** @format int32 */
  licensee_id?: number | null;
  name: string;
  /** @format int32 */
  owner_id: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  registered_date?: string | null;
  registration_no: string;
  thumbnail_url?: string | null;
  trademark_text?: string[] | null;
}

export interface PortfolioTrademarkProtocolsAddTrademarkResponse {
  /** @format int32 */
  doc_id: number;
}

export interface PortfolioTrademarkProtocolsDeleteTrademarkRequest {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  doc_id: number;
}

export interface PortfolioTrademarkProtocolsGetTrademarkDetailRequest {
  /** @format int32 */
  brand_id: number;
  /** @format int32 */
  doc_id: number;
}

export interface PortfolioTrademarkProtocolsGetTrademarkDetailResponse {
  class_list: string[];
  countries: MarqCommonEnumsCountryCodeEnum[];
  document_name: string;
  document_url: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration_date?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  licensee_expiration_date?: string | null;
  /** @format int32 */
  licensee_id?: number | null;
  name: string;
  /** @format int32 */
  owner_id?: number | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  registered_date?: string | null;
  registration_no?: string | null;
  thumbnail_url?: string | null;
  trademark_text: string[];
}

export interface PortfolioTrademarkProtocolsGetTrademarkListRequest {
  /** @format int32 */
  brand_id: number;
  country?: MarqCommonEnumsCountryCodeEnum[] | null;
  expiration_date?: MarqCommonModelOffsetDateTimeRange;
  ip_class?: string[] | null;
  is_expired_filter?: boolean | null;
  is_expiring_soon_filter?: boolean | null;
  keyword?: string | null;
  registered_date?: MarqCommonModelOffsetDateTimeRange;
}

export interface PortfolioTrademarkProtocolsGetTrademarkListResponse {
  trademark_list: PortfolioTrademarkProtocolsGetTrademarkListResponseTrademark[];
}

export interface PortfolioTrademarkProtocolsGetTrademarkListResponseTrademark {
  class_list?: string[] | null;
  countries: MarqCommonEnumsCountryCodeEnum[];
  /** @format int32 */
  doc_id: number;
  document_name: string;
  document_url: string;
  exclusive_licensee?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration_date?: string | null;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  licensee_expiration_date?: string | null;
  name: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  registered_date?: string | null;
  registration_no?: string | null;
  rights_owner?: string | null;
  thumbnail?: string | null;
  trademark_text?: string[] | null;
}

export interface PortfolioTrademarkProtocolsUpdateTrademarkRequest {
  /** @format int32 */
  brand_id: number;
  class_list: string[];
  countries: MarqCommonEnumsCountryCodeEnum[];
  /** @format int32 */
  doc_id: number;
  document_name: string;
  document_url: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  expiration_date: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  licensee_expiration_date?: string | null;
  /** @format int32 */
  licensee_id?: number | null;
  name: string;
  /** @format int32 */
  owner_id: number;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  registered_date?: string | null;
  registration_no: string;
  thumbnail_url?: string | null;
  trademark_text?: string[] | null;
}

export interface SellerMarketplaceEnforcementActivityProtocolsExportExcelForEnforcementActivityRequest {
  seller_id: string;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  status_filter?: MarqCommonEnumsEnforcementStatusEnum[] | null;
}

export interface SellerMarketplaceEnforcementActivityProtocolsExportExcelForEnforcementActivityResponse {
  /** @format int32 */
  progress_id: number;
}

export interface SellerMarketplaceEnforcementActivityProtocolsGetEnforcementActivityCountRequest {
  seller_id: string;
  status_filter?: MarqCommonEnumsEnforcementStatusEnum[] | null;
}

export interface SellerMarketplaceEnforcementActivityProtocolsGetEnforcementActivityCountResponse {
  /** @format int32 */
  total_count: number;
}

export interface SellerMarketplaceEnforcementActivityProtocolsGetEnforcementActivityListRequest {
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  seller_id: string;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  status_filter?: MarqCommonEnumsEnforcementStatusEnum[] | null;
}

export interface SellerMarketplaceEnforcementActivityProtocolsGetEnforcementActivityListResponse {
  listing_list: SellerMarketplaceEnforcementActivityProtocolsGetEnforcementActivityListResponseListingResponse[];
  seller_name: string;
}

export interface SellerMarketplaceEnforcementActivityProtocolsGetEnforcementActivityListResponseListingResponse {
  /** @format int32 */
  listing_id: number;
  listing_title?: string | null;
  listing_url?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  removed_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  reported_date?: string | null;
  status?: string | null;
  tag: MarqBrandCoreModelProtocolInvestigationTags;
}

export interface SellerMarketplaceEnforcementActivityProtocolsGetFilterOptionsRequest {
  seller_id: string;
}

export interface SellerMarketplaceEnforcementActivityProtocolsProgressExcelForEnforcementActivityRequest {
  /** @format int32 */
  progress_id: number;
}

export interface SellerMarketplaceEnforcementActivityProtocolsProgressExcelForEnforcementActivityResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface SellerSellerAnalyticClusterProtocolsExportExcelRequest {
  active_seller_filter?: boolean | null;
  cluster_id_list?: number[] | null;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
  sorted_column: MarqCommonEnumsSellerSearchSortEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticClusterProtocolsExportExcelResponse {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticClusterProtocolsGetChannelDomainFilterRequest {
  active_seller_filter?: boolean | null;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
}

export interface SellerSellerAnalyticClusterProtocolsGetSellerClusterCountRequest {
  active_seller_filter?: boolean | null;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
}

export interface SellerSellerAnalyticClusterProtocolsGetSellerClusterCountResponse {
  /** @format int32 */
  total_count: number;
  /**
   * @format date
   * @example "2023-03-01"
   */
  updated_date?: string | null;
}

export interface SellerSellerAnalyticClusterProtocolsGetSellerClusterListRequest {
  active_seller_filter?: boolean | null;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
  sorted_column: MarqCommonEnumsSellerSearchSortEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticClusterProtocolsGetSellerClusterListResponse {
  seller_cluster_list: MarqBrandModelResponseSellerBrandSellerClusterResponse[];
}

export interface SellerSellerAnalyticClusterProtocolsProgressExcelRequest {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticClusterProtocolsProgressExcelResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsExportListingStatusListRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  listing_id_list?: number[] | null;
  reason_filter?: MarqCommonEnumsArchivedReasonEnum[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqCommonEnumsSortedColumnArchivedPageEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsExportListingStatusListResponse {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsExportProgressListingStatusListRequest {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsExportProgressListingStatusListResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsGetArchivedListingListRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  reason_filter?: MarqCommonEnumsArchivedReasonEnum[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqCommonEnumsSortedColumnArchivedPageEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsGetArchivedListingListResponse {
  listing_list: SellerSellerAnalyticDetailArchivedListingProtocolsGetArchivedListingListResponseListingResponse[];
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsGetArchivedListingListResponseListingResponse {
  /**
   * @format date
   * @example "2023-03-01"
   */
  archived_date: string;
  archived_reason: string;
  asset_name: string;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_name: string;
  /** @format int32 */
  listing_id: number;
  listing_title: string;
  listing_url: string;
  /** @format double */
  price?: number | null;
  seller_name: string;
  thumbnail_url: string;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsGetFilterInfoDomainRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  reason_filter?: MarqCommonEnumsArchivedReasonEnum[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsGetFilterInfoReasonRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  reason_filter?: MarqCommonEnumsArchivedReasonEnum[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsGetFilterInfoSellerRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  reason_filter?: MarqCommonEnumsArchivedReasonEnum[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsGetListingStatusCountRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  reason_filter?: MarqCommonEnumsArchivedReasonEnum[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
}

export interface SellerSellerAnalyticDetailArchivedListingProtocolsGetListingStatusCountResponse {
  /** @format int32 */
  total_count: number;
}

export interface SellerSellerAnalyticDetailDataDrivenProtocolsRequestDemandLetterRequest {
  /** @format int32 */
  cluster_id?: number | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  requested_date: string;
  seller_id?: string | null;
}

export interface SellerSellerAnalyticDetailDataDrivenProtocolsRequestUDRPRequest {
  /** @format int32 */
  cluster_id?: number | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  requested_date: string;
  seller_id?: string | null;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsExportListingStatusListRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  listing_id_list?: number[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsExportListingStatusListResponse {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListRequest {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqBrandCoreModelClientListingSortedColumnEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListResponse {
  listing_list: SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListResponseListingResponse[];
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListResponseListingResponse {
  asset_name: string;
  currency?: MarqCommonEnumsCurrencyEnum;
  /**
   * @format date
   * @example "2023-03-01"
   */
  detected_date: string;
  domain_name: string;
  /** @format int32 */
  listing_id: number;
  listing_title: string;
  listing_url: string;
  /** @format double */
  price?: number | null;
  seller_name: string;
  thumbnail_url: string;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsGetFilterInfoDomainRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsGetFilterInfoSellerRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
}

export interface SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountResponse {
  /** @format int32 */
  total_count: number;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsExportListingStatusListRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  listing_id_list?: number[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqCommonEnumsSortedColumnEnforcementPageEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  status_filter?: MarqCommonEnumsEnforcementStatusEnum[] | null;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsExportListingStatusListResponse {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsExportProgressListingStatusListRequest {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsExportProgressListingStatusListResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsGetEnforcedListingListRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  sorted_column: MarqCommonEnumsSortedColumnEnforcementPageEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
  status_filter?: MarqCommonEnumsEnforcementStatusEnum[] | null;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsGetEnforcedListingListResponse {
  listing_list: SellerSellerAnalyticDetailEnforcedListingProtocolsGetEnforcedListingListResponseListingResponse[];
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsGetEnforcedListingListResponseListingResponse {
  asset_name: string;
  currency?: MarqCommonEnumsCurrencyEnum;
  domain_name: string;
  /** @format int32 */
  listing_id: number;
  listing_title: string;
  listing_url: string;
  /** @format double */
  price?: number | null;
  seller_name: string;
  status: string;
  thumbnail_url: string;
  /**
   * @format date
   * @example "2023-03-01"
   */
  updated_date: string;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsGetFilterInfoDomainRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  status_filter?: MarqCommonEnumsEnforcementStatusEnum[] | null;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsGetFilterInfoSellerRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  status_filter?: MarqCommonEnumsEnforcementStatusEnum[] | null;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsGetFilterInfoStatusRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  status_filter?: MarqCommonEnumsEnforcementStatusEnum[] | null;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsGetListingStatusCountRequest {
  /** @format int32 */
  cluster_id?: number | null;
  domain_id_filter?: number[] | null;
  search?: string | null;
  seller_id?: string | null;
  seller_name_filter?: string[] | null;
  status_filter?: MarqCommonEnumsEnforcementStatusEnum[] | null;
}

export interface SellerSellerAnalyticDetailEnforcedListingProtocolsGetListingStatusCountResponse {
  /** @format int32 */
  total_count: number;
}

export interface SellerSellerAnalyticDetailProtocolsEditProfileRequest {
  address?: string | null;
  business_reg_num?: string | null;
  company_name?: string | null;
  country_code?: MarqCommonEnumsCountryCodeEnum;
  email?: string | null;
  legal_representative?: string | null;
  online_marketing_number?: string | null;
  phone?: string | null;
  seller_id: string;
  seller_name?: string | null;
  shop_name?: string | null;
}

export interface SellerSellerAnalyticDetailProtocolsGetClusterProfileRequest {
  /** @format int32 */
  cluster_id?: number | null;
  seller_id?: string | null;
}

export interface SellerSellerAnalyticDetailProtocolsGetClusterProfileResponse {
  profile: MarqBrandCoreModelResponseClusterProfileResponse;
}

export interface SellerSellerAnalyticDetailProtocolsGetClusterSellerListRequest {
  /** @format int32 */
  cluster_id: number;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  sort_by?: MarqCommonEnumsSellerintelSellerClusterDataSortByEnum;
}

export interface SellerSellerAnalyticDetailProtocolsGetClusterSellerListResponse {
  seller_list: MarqBrandDataModelSellerClusterAnalysisSellerDataSellerData[];
}

export interface SellerSellerAnalyticDetailProtocolsGetDataDrivenInsightRequest {
  /** @format int32 */
  cluster_id?: number | null;
  seller_id?: string | null;
}

export interface SellerSellerAnalyticDetailProtocolsGetDataDrivenInsightResponse {
  response: MarqBrandCoreModelResponseDataDrivenInsightResponse;
}

export interface SellerSellerAnalyticDetailProtocolsGetDetailRequest {
  /** @format int32 */
  cluster_id?: number | null;
  seller_id?: string | null;
}

export interface SellerSellerAnalyticDetailProtocolsGetFirstEnforcementHistoryRequest {
  seller_id: string;
}

export interface SellerSellerAnalyticDetailProtocolsGetFirstEnforcementHistoryResponse {
  first_enforcement_history: MarqBrandCoreModelResponseSellerDetailResponseFirstEnforcementHistory[];
  /** @format int64 */
  reported_listings_count: number;
}

export interface SellerSellerAnalyticDetailProtocolsGetListingStatusGraphRequest {
  /** @format int32 */
  cluster_id?: number | null;
  period_type: MarqCommonEnumsSellerListingStatusGraphTabType;
  seller_id?: string | null;
}

export interface SellerSellerAnalyticDetailProtocolsGetListingStatusGraphResponse {
  detected: SellerSellerAnalyticDetailProtocolsGetListingStatusGraphStatusData[];
  enforcement_req: SellerSellerAnalyticDetailProtocolsGetListingStatusGraphStatusData[];
  removed: SellerSellerAnalyticDetailProtocolsGetListingStatusGraphStatusData[];
}

export interface SellerSellerAnalyticDetailProtocolsGetListingStatusGraphStatusData {
  label: string;
  /** @format int64 */
  total_count: number;
}

export interface SellerSellerAnalyticDetailProtocolsGetSummaryRequest {
  /** @format int32 */
  cluster_id?: number | null;
  seller_id?: string | null;
}

export interface SellerSellerAnalyticDetailProtocolsGetSummaryResponse {
  summary: MarqBrandCoreModelResponseSellerDetailSummaryResponse;
}

export interface SellerSellerAnalyticSellerInClusterProtocolsExportExcelRequest {
  active_seller_filter?: boolean | null;
  /** @format int32 */
  cluster_id: number;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
  seller_id_list?: string[] | null;
  sorted_column: MarqCommonEnumsSellerSearchSortEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticSellerInClusterProtocolsExportExcelResponse {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticSellerInClusterProtocolsGetChannelDomainFilterRequest {
  active_seller_filter?: boolean | null;
  /** @format int32 */
  cluster_id: number;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
}

export interface SellerSellerAnalyticSellerInClusterProtocolsGetSellerCountRequest {
  active_seller_filter?: boolean | null;
  /** @format int32 */
  cluster_id: number;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
}

export interface SellerSellerAnalyticSellerInClusterProtocolsGetSellerCountResponse {
  /** @format int32 */
  total_count: number;
  /**
   * @format date
   * @example "2023-03-01"
   */
  updated_date: string;
}

export interface SellerSellerAnalyticSellerInClusterProtocolsGetSellerListRequest {
  active_seller_filter?: boolean | null;
  /** @format int32 */
  cluster_id: number;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
  sorted_column: MarqCommonEnumsSellerSearchSortEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticSellerInClusterProtocolsGetSellerListResponse {
  seller_list: MarqBrandModelResponseSellerSellerAnalyticsSellerInClusterResponse[];
}

export interface SellerSellerAnalyticSellerInClusterProtocolsProgressExcelRequest {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticSellerInClusterProtocolsProgressExcelResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface SellerSellerAnalyticSellerProtocolsExportExcelRequest {
  active_seller_filter?: boolean | null;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
  seller_id_list?: string[] | null;
  sorted_column: MarqCommonEnumsSellerSearchSortEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticSellerProtocolsExportExcelResponse {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticSellerProtocolsGetChannelDomainFilterRequest {
  active_seller_filter?: boolean | null;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
}

export interface SellerSellerAnalyticSellerProtocolsGetSellerCountRequest {
  active_seller_filter?: boolean | null;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
}

export interface SellerSellerAnalyticSellerProtocolsGetSellerCountResponse {
  /** @format int32 */
  total_count: number;
}

export interface SellerSellerAnalyticSellerProtocolsGetSellerListRequest {
  active_seller_filter?: boolean | null;
  domain_id_filter?: number[] | null;
  last_detected_date_filter?: MarqCommonModelLocalDateRange;
  /** @format int32 */
  limit: number;
  /** @format int32 */
  offset: number;
  risk_score_filter?: MarqBrandCoreModelRequestRiskScoreFilterRequestRiskScoreItem;
  search?: string | null;
  sorted_column: MarqCommonEnumsSellerSearchSortEnum;
  sorted_order: MarqCommonEnumsSortedOrderEnum;
}

export interface SellerSellerAnalyticSellerProtocolsGetSellerListResponse {
  seller_list: MarqBrandModelResponseSellerSellerAnalyticsResponse[];
}

export interface SellerSellerAnalyticSellerProtocolsProgressExcelRequest {
  /** @format int32 */
  progress_id: number;
}

export interface SellerSellerAnalyticSellerProtocolsProgressExcelResponse {
  completed: boolean;
  /** @format int32 */
  progress: number;
  url?: string | null;
}

export interface SettingsBillingProtocolsAddDefaultCardRequest {
  token: string;
}

export interface SettingsBillingProtocolsGetBillingHistoryRequest {
  over_stripe_pi_id?: string | null;
}

export interface SettingsBillingProtocolsGetBillingHistoryResponse {
  has_more: boolean;
  history_list: SettingsBillingProtocolsGetBillingHistoryResponseBillingHistoryRow[];
}

export interface SettingsBillingProtocolsGetBillingHistoryResponseBillingHistoryRow {
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  created_date: string;
  paid_success_data?: SettingsBillingProtocolsGetBillingHistoryResponseBillingHistoryRowPaidSuccessData;
  payment_status: MarqCommonEnumsStripeBillingHistoryStatusEnum;
  stripe_pi_id: string;
  type: string;
}

export interface SettingsBillingProtocolsGetBillingHistoryResponseBillingHistoryRowPaidSuccessData {
  /** @format double */
  amount_paid: number;
  invoice_pdf?: string | null;
}

export interface SettingsBillingProtocolsGetPaymentInfoResponse {
  payment_info?: SettingsBillingProtocolsGetPaymentInfoResponsePaymentInfo;
  plan_name: MarqCommonEnumsClientClientPlanType;
  subscription_info?: SettingsBillingProtocolsGetPaymentInfoResponseSubscriptionInfo;
}

export interface SettingsBillingProtocolsGetPaymentInfoResponsePaymentInfo {
  brand: MarqCommonEnumsStripeStripeCardBrandEnum;
  declined: boolean;
  /** @format int32 */
  exp_month: number;
  /** @format int32 */
  exp_year: number;
  last4: string;
  name: string;
}

export interface SettingsBillingProtocolsGetPaymentInfoResponseSubscriptionInfo {
  /** @format double */
  billing_amount: number;
  billing_interval: string;
  /**
   * @format date-time
   * @example "2023-03-01T00:00:00Z"
   */
  next_billing_date?: string | null;
}

export interface SettingsBillingProtocolsUpdateDefaultCardRequest {
  token: string;
}

export interface SettingsBillingProtocolsUpdateDefaultCardResponse {
  failed_payment_processed: boolean;
}

export enum SettingsCreditsProtocolsGetCreditHistoryCreditHistoryType {
  CREDITS = 'CREDITS',
  RETURNED_CREDITS = 'RETURNED_CREDITS',
}

export interface SettingsCreditsProtocolsGetCreditHistoryRequest {
  /**
   * @format date
   * @example "2023-03-01"
   */
  end_date?: string | null;
  /**
   * @format date
   * @example "2023-03-01"
   */
  start_date?: string | null;
  token?: string | null;
  type: SettingsCreditsProtocolsGetCreditHistoryCreditHistoryType;
}

export interface SettingsCreditsProtocolsGetCreditHistoryResponse {
  history: SettingsCreditsProtocolsGetCreditHistoryResponseHistory[];
  token: string;
}

export interface SettingsCreditsProtocolsGetCreditHistoryResponseHistory {
  /** @format int32 */
  accrued: number;
  /**
   * @format date
   * @example "2023-03-01"
   */
  date: string;
  type: string;
  /** @format int32 */
  used: number;
}

export interface SettingsCreditsProtocolsGetCreditsResponse {
  /** @format int32 */
  available_credits: number;
  /** @format int32 */
  available_returned_credits: number;
  /** @format int32 */
  total_credits: number;
}
