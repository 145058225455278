import { FolioCommonEnumsFolioCountryCodeEnum } from 'folio-admin/src/swagger/data-contracts';
import { MarqCommonEnumsCountryCodeEnum } from 'opscc-ui-web/src/swagger/data-contracts';
import { CountryCodeEnum } from './CountryCodeEnum';
import Countries from './Countries.json';
import RequestImageDim from './RequestImageDim';
import RequestImagePreview from './RequestImagePreview';
import { HttpStatusCodeEnum } from './HttpStatusCodeEnum';

export { RequestImageDim, RequestImagePreview, HttpStatusCodeEnum };

export enum RequestTypeEnum {
  SingleSelect = 's_selection',
  MultiSelect = 'm_selection',
  Free = 'free',
}

export interface IDateTimeRange {
  start: string | null;
  end: string | null;
}
type FileType = 'pdf' | 'doc' | 'file';
export interface IFilenameUrl {
  id?: number;
  type?: FileType;
  blob?: File;
  filename: string;
  url: string;
}

export interface IRequestQuestion {
  text: string;
  imageList: IFilenameUrl[];
  fileList: IFilenameUrl[];
  from: string;
  createdDate: string;
}

export interface IRequestReplySelectItem {
  id: number;
  text: string;
  selected: boolean;
}

export interface IRequestReplyFile {
  text: string;
  imageList: IFilenameUrl[];
  fileList: IFilenameUrl[];
  from: string;
  createdDate: string;
}

export interface IRequestReplySelect {
  list: IRequestReplySelectItem[];
  other: string | null;
  from: string;
  createdDate: string;
}

export interface IRequestSelectionOption {
  selectionList: IRequestReplySelectItem[];
  useOther: boolean;
}

export interface IRequest {
  qnaId: number;
  completed: boolean;
  type: RequestTypeEnum;
  deadline: string;
  countryList: CountryCodeEnum[];
  question: IRequestQuestion;
  selectionOption: IRequestSelectionOption | null;
  answer: IRequestReplySelect | IRequestReplyFile | null;
  maxFileNumber?: number | null;
  createdDate?: string;
  requestOwner?: string;
}

export const getFlagEmoji = (
  countryCode: CountryCodeEnum | FolioCommonEnumsFolioCountryCodeEnum | MarqCommonEnumsCountryCodeEnum
): string => {
  // 00 예외 처리 이유
  // Authorized Seller에서는 instagram, facebook같은 도메인은 Global로 처리를 해줘야함. 서버에서 Global로 내려주는 값은 00
  if (countryCode === '00') return String.fromCodePoint(parseInt('1F30E', 16));

  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
};

export const getCountry = (
  countryCode: CountryCodeEnum | FolioCommonEnumsFolioCountryCodeEnum | MarqCommonEnumsCountryCodeEnum
) => {
  const obj = Countries.find(({ code }) => code === countryCode);

  return obj;
};

export const CountryCode = Countries;
export * from './CountryCodeEnum';
export * from './HttpStatusCodeEnum';
export * from './RequestImageDim';
export * from './RequestImagePreview';
export * from './timeZone';
