import numeral from 'numeral';
import { ISnackbarProps } from '@marqvision/mds/core/Snackbar';
import { MDSToastSnackbar } from '@marqvision/mds/core';
import { EnforcementResponse } from '~/apis/detection';
import { MonitoringWebsiteSWDetectionProtocolsRequestIgnoreResponse } from '~/swagger/data-contracts';

export const getActionToastMessage = (count: number, name?: string | null, action = 'requested'): string => {
  if (!name) {
    return `${count} listing${count > 2 ? 's' : ''} have been ${action}`;
  }

  let result: string;
  if (name.length > 50) {
    result = `‘${name.substring(0, 50)}...’`;
  } else {
    result = `‘${name}...’`;
  }

  if (count > 1) {
    result = `${result} and ${count - 1} more listing${count > 2 ? 's' : ''} have been ${action}.`;
  }

  return result;
};
export const handleEnforceToast = (
  response: EnforcementResponse,
  reportingCapClient: boolean,
  callback?: (requestId: number) => void
): void => {
  const restImages = response.listing_image_list
    ? [...Array(response.affected - response.listing_image_list.length).keys()].map((i) => String(i))
    : [];
  const toastProps: ISnackbarProps = {
    type: 'succeed',
    images: [...(response.listing_image_list || []), ...restImages],
    title: '',
    message: '',
  };

  if (reportingCapClient) {
    toastProps.title = `${numeral(response.affected).format('0,0')} enforcement request(s) submitted`;
    toastProps.message = `${numeral(response.remaining).format('0,0')} credits remaining.`;
  } else {
    toastProps.title = 'Enforcement requested';
    toastProps.message = getActionToastMessage(response.affected, response.listing_title);
  }

  const requestId = response.request_id;
  if (callback && requestId) {
    toastProps.actionButton = {
      dismissBefore: true,
      text: 'Undo',
      event: () => callback(requestId),
    };
  }

  MDSToastSnackbar(toastProps);
};

export const handleIgnoreToast = (response: MonitoringWebsiteSWDetectionProtocolsRequestIgnoreResponse): void => {
  const MAX_TITLE_LENGTH = 60;
  let toastProps: ISnackbarProps;

  if (response.success) {
    const restImages = response.image_url_list
      ? [...Array(response.affected - response.image_url_list.length).keys()].map((i) => String(i))
      : [];
    const title = `‘${
      response.title.length > MAX_TITLE_LENGTH
        ? `${response.title.substring(0, MAX_TITLE_LENGTH - 1)}...`
        : response.title
    }’`;
    toastProps = {
      type: 'succeed',
      images: [...(response.image_url_list || []), ...restImages],
      title: '',
      message: '',
    };

    if (response.affected > 1) {
      toastProps.title = `${numeral(response.affected).format('0,0')} websites have been ignored.`;
      toastProps.message = `${title} and  ${numeral(response.affected).format('0,0')} more websites`;
    } else {
      toastProps.title = 'The website has been ignored.';
      toastProps.message = `${title}`;
    }
  } else {
    toastProps = { type: 'error', title: 'An error occurred, please try again.' };
  }

  MDSToastSnackbar(toastProps);
};
