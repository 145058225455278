import React, { JSX, useRef } from 'react';
import clsx from 'clsx';
import MultiDropdown from 'react-multilevel-dropdown';
import { makeStyles } from '@material-ui/core/styles';
import { IcoArrowRightOutline } from '../assets';
import { MDSCheckbox, MDSTheme } from './index';

const useStyles = makeStyles(() => ({
  menu: {
    width: 'max-content',
    minWidth: 'auto !important',
    boxShadow: '0 8px 20px 0 rgba(0, 19, 73, 0.12) !important',
    borderRadius: '7px !important',
    border: 'none',
    zIndex: 101,
    '& ul': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
    },
  },
  button: {
    padding: '0 !important',
    height: 'auto !important',
    boxShadow: 'none !important',
    letterSpacing: 'normal !important', // MultiDropdown 기본 스타일 덮어씀
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  light: {
    backgroundColor: `${MDSTheme.palette.white} !important`,
  },
  dark: {
    backgroundColor: `${MDSTheme.palette.bluegray[900]} !important`,
    '& path': {
      fill: MDSTheme.palette.white,
    },
  },
  menuItem: {
    ...MDSTheme.typography.regular,
    backgroundColor: MDSTheme.palette.white,
    color: `${MDSTheme.palette.bluegray[800]} !important`,
    height: '48px',
    lineHeight: '48px',
    padding: '0 16px',
    '&:first-child': {
      borderTopLeftRadius: '7px',
      borderTopRightRadius: '7px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '7px',
      borderBottomRightRadius: '7px',
    },
    '&:hover': {
      backgroundColor: MDSTheme.palette.blue[50],
    },
    '&:focus': {
      backgroundColor: '#e4e9f2',
    },
  },
  menuNameEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  disabledMenuItem: {
    opacity: 0.5,
    cursor: 'default',
    '&:hover, &:focus': {
      backgroundColor: MDSTheme.palette.white,
    },
  },
  redColor: {
    color: `${MDSTheme.palette.red[700]} !important`,
    '&:hover, &:focus': {
      color: `${MDSTheme.palette.red[700]} !important`,
    },
  },
  arrowRight: {
    marginLeft: '4px',
    width: '24px',
    height: '24px',
  },
  copyToSubmenu: {
    maxWidth: '300px',
    overflow: 'hidden',
    borderRadius: '8px',
  },
  selectIcon: {
    marginRight: '4px',
    marginTop: '1.3px',
  },
}));

export interface IMenuItem {
  label: string | React.ReactNode;
  key?: string | number; // label이 React.ReactNode 인 경우 key 를 사용한다.
  action?: () => void;
  subItems?: IMenuItem[];
  disabled?: boolean;
  redColor?: boolean;
  isChecked?: boolean;
}

export interface IDoubleDepthDropDownItem<T> {
  label: string;
  key?: string | number; // label이 React.ReactNode 인 경우 key 를 사용한다.
  value: T;
  isChecked: boolean;
  action: () => void;
  disabled?: boolean;
  redColor?: boolean;
  subItems: false | { domainId: number; label: string; isChecked: boolean; action: () => void }[];
}

interface IMenuProps<T> {
  title: JSX.Element;
  items: IMenuItem[] | IDoubleDepthDropDownItem<T>[];
  position?: 'left' | 'right' | 'top-right' | 'top-left';
  color?: 'light' | 'dark' | 'transparent';
  className?: Record<string, string>;
  shouldAllowContentClick?: boolean;
  shouldEnableOverlay?: boolean;
  disableOverlay?: () => void;
  shouldDisableFlex?: boolean;
  onChange?: (value: unknown, domainValue: unknown) => void;
}

const Dropdown = MultiDropdown as any;

const Menu = <T,>(props: IMenuProps<T>): JSX.Element => {
  const classes = useStyles();
  const ref = useRef<{
    toggle: () => void;
  }>(null);

  const handleClose = (): void => {
    if (ref.current) {
      ref.current.toggle?.();
    }
  };

  const handleClick = (event: React.MouseEvent, item: IMenuItem | IDoubleDepthDropDownItem<T>) => {
    event.stopPropagation();

    const clickedTarget = event.target as HTMLDivElement;
    const customLabelValue = clickedTarget.getAttribute('customLabel');

    if (item.disabled) return;

    if (item.isChecked === undefined && item.action) {
      item?.action();
      handleClose();

      return;
    }

    if ((item.action && clickedTarget.innerText === item.label) || (item.action && customLabelValue === item.label)) {
      item?.action();
    }
  };

  const handlePopUpClose = () => {
    if (props.onChange && props.disableOverlay) {
      props.disableOverlay();

      const channelsList = filterDataFetchingChannels(props.items as IDoubleDepthDropDownItem<T>[]);
      const domainsList = filterDataFetchingDomains(props.items as IDoubleDepthDropDownItem<T>[]);

      props.onChange(channelsList, domainsList);
    }
  };

  const filterDataFetchingChannels = (listItems: IDoubleDepthDropDownItem<T>[]) => {
    return listItems.filter((list) => list.isChecked).map((list) => list.value);
  };

  const filterDataFetchingDomains = (listItems: IDoubleDepthDropDownItem<T>[]) => {
    const domainIdList: number[] = [];

    listItems.forEach((list) => {
      if (typeof list.subItems === 'object') {
        list.subItems.map((item) => {
          if (item.isChecked) {
            domainIdList.push(item.domainId);
          }
        });
      }
    });

    return domainIdList;
  };

  const renderSubItem = (subItems: IMenuItem[]): JSX.Element => {
    return (
      <Dropdown.Submenu className={clsx(classes.copyToSubmenu, props.className?.subMenu)} position={props.position}>
        {subItems.map((item, index) => {
          return (
            <Dropdown.Item
              key={index}
              className={clsx(classes.menuItem, {
                [classes.disabledMenuItem]: item.disabled,
                [classes.redColor]: item.redColor,
              })}
              onClick={(event: React.MouseEvent) => handleClick(event, item)}
            >
              <span className={classes.menuNameEllipsis}>
                {item.isChecked !== undefined ? (
                  <div style={{ display: 'flex' }}>
                    <MDSCheckbox className="dropCheckbox" isPrimary onClick={item.action} checked={item.isChecked} />
                    <div style={{ marginLeft: '8px' }}>{item.label}</div>
                  </div>
                ) : (
                  <>{item.label}</>
                )}
              </span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Submenu>
    );
  };

  const renderMenuItem = (item: IMenuItem | IDoubleDepthDropDownItem<T>): JSX.Element => {
    return (
      <Dropdown.Item
        key={item.key || item.label}
        customLabel={item.label}
        className={clsx(classes.menuItem, {
          [classes.disabledMenuItem]: item.disabled,
          [classes.redColor]: item.redColor,
        })}
        onClick={(event: React.MouseEvent) => handleClick(event, item)}
      >
        <div
          className={props.className?.dropMenuWrapper}
          style={{ display: props.shouldDisableFlex ? 'block' : 'flex' }}
        >
          {item.isChecked !== undefined ? (
            <div style={{ display: 'flex' }}>
              <MDSCheckbox className="dropCheckbox" isPrimary onClick={item.action} checked={item.isChecked} />
              <div style={{ marginLeft: '8px' }}>{item.label}</div>
            </div>
          ) : (
            <>{item.label}</>
          )}
          {item.subItems && (
            <IcoArrowRightOutline className={clsx(classes.arrowRight, props.className?.arrowRightStyle)} />
          )}
        </div>
        {item.subItems && item.subItems.length > 0 && renderSubItem(item.subItems)}
      </Dropdown.Item>
    );
  };

  return (
    <>
      {props.shouldEnableOverlay && <div className={props.className?.overlay} onClick={handlePopUpClose} />}
      <Dropdown
        position={props.position}
        disabled={props.title?.props?.disabled}
        ref={ref}
        title={props.title}
        buttonVariant="tertiary"
        buttonClassName={clsx(classes.button, props.className?.buttonChip, {
          [classes.light]: !props.color || props.color === 'light',
          [classes.dark]: props.color === 'dark',
          [classes.transparent]: props.color === 'transparent',
        })}
        menuClassName={clsx(classes.menu, props.className?.menuItemAnimation, props.className?.mainMenu)}
      >
        {props.items.map((item: IMenuItem | IDoubleDepthDropDownItem<T>) => renderMenuItem(item))}
      </Dropdown>
    </>
  );
};

export default Menu;
