import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import { ChipProps } from '@material-ui/core/Chip/Chip';
import theme from './Theme';

const useStyles = makeStyles(() => ({
  chip: {
    cursor: 'inherit',
    borderRadius: '4px',
    minWidth: 'auto',
    transition: 'none',
    '&.MuiChip-clickable': {
      boxShadow: 'none',
      cursor: 'pointer',
    },
    '& .MuiChip-label': {
      padding: '0',
      textOverflow: 'unset',
      overflow: 'visible',
    },
    '& .MuiChip-deleteIcon': {
      display: 'none',
    },
    '& .MuiChip-icon, .end-icon > svg': {
      width: '16px',
      height: '16px',
    },
    '& .selected-label': {
      ...theme.typography.T12,
      marginLeft: '4px',
      borderRadius: '4px',
      height: '20px',
      lineHeight: '16px',
    },
    '&.x_small': {
      ...theme.typography.T12,
      ...theme.typography.medium,
      minWidth: '16px',
      height: '16px',
      padding: '3px',
      borderRadius: '100px',
    },
    '&.small': {
      ...theme.typography.T12,
      ...theme.typography.medium,
      height: '20px',
      padding: '0 4px',
      '& .MuiChip-icon': {
        marginLeft: '0',
        marginRight: '2px',
      },
      '& .end-icon > svg': {
        marginLeft: '2px',
        marginRight: '0',
        marginTop: '2px',
      },
      '& .start-icon > svg': {
        width: '12px',
        height: '12px',
        marginRight: '2px',
      },
    },
    '&.medium': {
      ...theme.typography.T14,
      ...theme.typography.medium,
      height: '24px',
      padding: '0 6px',
      '& .MuiChip-icon': {
        marginLeft: '0',
        marginRight: '4px',
      },
      '& .end-icon > svg': {
        marginLeft: '4px',
        marginRight: '-4px',
      },
      '& .start-icon > svg': {
        width: '16px',
        height: '16px',
        marginRight: '4px',
      },
    },
    ...getTagStyles(),
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .start-icon, & .end-icon': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
}));

const getColorFillBackground = (key: IOwnProps['color']): string => {
  return theme.palette[key][['bluegray', 'blue', 'yellow'].includes(key) ? '700' : '600'];
};

const getFillHoverBackgroundColor = (key: IOwnProps['color']): string => {
  if (key === 'white') {
    return theme.palette.bluegray[100];
  }
  return theme.palette[key][['bluegray', 'blue', 'yellow'].includes(key) ? '800' : '700'];
};

const getColorByIsBlueGray = (key: IOwnProps['color']): string => {
  if (key === 'bluegray') {
    return theme.palette.bluegray[900];
  }
  if (key === 'yellow') {
    return theme.palette.yellow[800];
  }
  return theme.palette[key][700];
};

const getTintBackgroundColor = (key: IOwnProps['color']): string => {
  return key === 'bluegray' ? theme.palette[key]['150'] : theme.palette[key]['50'];
};

const getTintHoverBackgroundColor = (key: IOwnProps['color']): string => {
  if (key === 'white') {
    return theme.palette.bluegray[700];
  }
  return theme.palette[key][key === 'bluegray' ? '300' : '200'];
};

const getColorByIsWhite = (key: IOwnProps['color']): string => {
  return key === 'white' ? theme.palette.bluegray['900'] : theme.palette.white;
};

const getTagStyles = () => {
  const result = {};

  (Object.keys(theme.palette) as IOwnProps['color'][]).forEach((key: IOwnProps['color']) => {
    Object.assign(result, {
      [`&.${key}.fill`]: {
        backgroundColor: getColorFillBackground(key),
        color: getColorByIsWhite(key),

        '& .selected-label': {
          backgroundColor: getTintBackgroundColor(key),
          color: getColorByIsBlueGray(key),
        },
        '& svg > path': {
          fill: theme.palette.white,
        },
        '&.MuiChip-clickable:hover': {
          backgroundColor: getFillHoverBackgroundColor(key),
        },
        '&:disabled': {
          backgroundColor: theme.palette[key]['300'],
        },
      },
      [`&.${key}.tint`]: {
        backgroundColor: getTintBackgroundColor(key),
        color: getColorByIsBlueGray(key),
        '& .selected-label': {
          backgroundColor: getColorFillBackground(key),
          color: theme.palette.white,
        },
        '& svg  > path': {
          fill: getColorByIsBlueGray(key),
        },
        '&.MuiChip-clickable:hover': {
          backgroundColor: getTintHoverBackgroundColor(key),
        },
        '&:disabled': {
          color: theme.palette[key]['300'],
          '& svg  > path': {
            fill: theme.palette[key]['300'],
          },
        },
      },
      [`&.${key}.outline`]: {
        border: `1px solid ${key === 'bluegray' ? theme.palette.bluegray[200] : getColorByIsBlueGray(key)}`,
        color: getColorByIsBlueGray(key),
        backgroundColor: theme.palette.white,
        '& .selected-label': {
          backgroundColor: getColorFillBackground(key),
          color: theme.palette.white,
        },
        '& svg  > path': {
          fill: getColorByIsBlueGray(key),
        },
        '&.MuiChip-clickable:hover': {
          backgroundColor: key === 'white' ? theme.palette.bluegray[700] : theme.palette.bluegray['100'],
        },
        '&:disabled': {
          border: `1px solid ${theme.palette.bluegray['150']}`,
          color: theme.palette[key]['300'],
          '& svg  > path': {
            fill: theme.palette[key]['300'],
          },
        },
      },
    });
  });

  return result;
};

interface IOwnProps {
  variant: 'fill' | 'tint' | 'outline';
  color: keyof typeof theme.palette;
  size?: 'x_small' | 'small' | 'medium';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

type IProps = IOwnProps & Omit<ChipProps, 'variant' | 'color' | 'size'>;

export const MDSTag = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { size = 'medium', color, variant, endIcon, label, className, startIcon, ...restProps } = props;

  const renderLabel = () => {
    return (
      <div className={classes.labelWrapper}>
        {startIcon && <span className="start-icon">{startIcon}</span>}
        {label}
        {endIcon && <span className="end-icon ">{endIcon}</span>}
      </div>
    );
  };

  return <Chip className={clsx(classes.chip, className, size, color, variant)} label={renderLabel()} {...restProps} />;
};

export default MDSTag;
