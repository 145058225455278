import numeral from 'numeral';
import { LanguageEnum } from './@types';

export const localizedLabel = {
  all: {
    [LanguageEnum.Korean]: (total: number) => `전체 ${numeral(total).format('0,0')}개`,
    [LanguageEnum.English]: (total: number) => `All (${numeral(total).format('0,0')})`,
  },
  allWithPaging: {
    [LanguageEnum.Korean]: `전체`,
    [LanguageEnum.English]: `All`,
  },
  searched: {
    [LanguageEnum.Korean]: (total: number) => `${numeral(total).format('0,0')}개 검색됨`,
    [LanguageEnum.English]: (total: number) => `Searched (${numeral(total).format('0,0')})`,
  },
  selected: {
    [LanguageEnum.Korean]: (total: number) => `${numeral(total).format('0,0')}개 선택됨`,
    [LanguageEnum.English]: (total: number) => `Selected (${numeral(total).format('0,0')})`,
  },
  featured: {
    [LanguageEnum.Korean]: '추천순',
    [LanguageEnum.English]: 'Featured',
  },
  asc: {
    [LanguageEnum.Korean]: 'ㄱ-ㅎ',
    [LanguageEnum.English]: 'A-Z',
  },
  desc: {
    [LanguageEnum.Korean]: 'ㅎ-ㄱ',
    [LanguageEnum.English]: 'Z-A',
  },
  search: {
    [LanguageEnum.Korean]: '검색',
    [LanguageEnum.English]: 'Search',
  },
};


