/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ArchivedProtocolsExcelExportByIdListRequest,
  ArchivedProtocolsExcelExportRequest,
  ArchivedProtocolsExcelExportResponse,
  ArchivedProtocolsExcelResultRequest,
  ArchivedProtocolsExcelResultResponse,
  ArchivedProtocolsGetListingsRequest,
  ArchivedProtocolsGetListingsResponse,
  ArchivedProtocolsRequestTestPurchaseRequest,
  ArchivedProtocolsUpdateStatusRequest,
  MarqCommonProtocolFileResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Archived<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Archived
   * @name ListingsCreate
   * @request POST:/archive/listings
   */
  listingsCreate = (data: ArchivedProtocolsGetListingsRequest, params: RequestParams = {}) =>
    this.http.request<ArchivedProtocolsGetListingsResponse, any>({
      path: `/archive/listings`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Archived
   * @name UpdateStatusCreate
   * @request POST:/archive/update-status
   */
  updateStatusCreate = (data: ArchivedProtocolsUpdateStatusRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/archive/update-status`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Archived
   * @name ExcelExportCreate
   * @request POST:/archive/excel/export
   */
  excelExportCreate = (data: ArchivedProtocolsExcelExportRequest, params: RequestParams = {}) =>
    this.http.request<ArchivedProtocolsExcelExportResponse, any>({
      path: `/archive/excel/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Archived
   * @name ExcelExportByIdCreate
   * @request POST:/archive/excel/export-by-id
   */
  excelExportByIdCreate = (data: ArchivedProtocolsExcelExportByIdListRequest, params: RequestParams = {}) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/archive/excel/export-by-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Archived
   * @name ExcelProgressCreate
   * @request POST:/archive/excel/progress
   */
  excelProgressCreate = (data: ArchivedProtocolsExcelResultRequest, params: RequestParams = {}) =>
    this.http.request<ArchivedProtocolsExcelResultResponse, any>({
      path: `/archive/excel/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Archived
   * @name RequestTpCreate
   * @request POST:/archive/request-tp
   */
  requestTpCreate = (data: ArchivedProtocolsRequestTestPurchaseRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/archive/request-tp`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
