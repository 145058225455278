import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { portfolioApi } from '../../apis';
import {
  PortfolioCopyrightsProtocolsAddCopyrightRequest,
  PortfolioCopyrightsProtocolsAddDocumentRequest,
  PortfolioCopyrightsProtocolsAddDocumentResponse,
  PortfolioCopyrightsProtocolsDeleteCopyrightRequest,
  PortfolioCopyrightsProtocolsDeleteDocumentRequest,
  PortfolioCopyrightsProtocolsGetCopyrightsRequest,
  PortfolioCopyrightsProtocolsGetCopyrightsResponse,
  PortfolioCopyrightsProtocolsGetDocumentDetailsRequest,
  PortfolioCopyrightsProtocolsGetDocumentDetailsResponse,
  PortfolioCopyrightsProtocolsGetDocumentsRequest,
  PortfolioCopyrightsProtocolsGetDocumentsResponse,
  PortfolioCopyrightsProtocolsUpdateCopyrightRequest,
  PortfolioCopyrightsProtocolsUpdateDocumentRequest,
  PortfolioDesignProtocolsAddDesignRequest,
  PortfolioDesignProtocolsAddDesignResponse,
  PortfolioDesignProtocolsDeleteDesignRequest,
  PortfolioDesignProtocolsGetDesignDetailRequest,
  PortfolioDesignProtocolsGetDesignDetailResponse,
  PortfolioDesignProtocolsGetDesignListRequest,
  PortfolioDesignProtocolsGetDesignListResponse,
  PortfolioDesignProtocolsUpdateDesignRequest,
  PortfolioIpDocProtocolsAddBrandRequest,
  PortfolioIpDocProtocolsAddBrandResponse,
  PortfolioIpDocProtocolsDeleteBrandRequest,
  PortfolioIpDocProtocolsGetBrandDetailRequest,
  PortfolioIpDocProtocolsGetBrandDetailResponse,
  PortfolioIpDocProtocolsGetBrandListResponse,
  PortfolioIpDocProtocolsGetDownloadUrlRequest,
  PortfolioIpDocProtocolsGetDownloadUrlResponse,
  PortfolioIpDocProtocolsGetLicenseeListResponse,
  PortfolioIpDocProtocolsGetOwnerListResponse,
  PortfolioIpDocProtocolsGetUploadUrlRequest,
  PortfolioIpDocProtocolsGetUploadUrlResponse,
  PortfolioIpDocProtocolsUpdateBrandRequest,
  PortfolioTrademarkProtocolsAddTrademarkRequest,
  PortfolioTrademarkProtocolsAddTrademarkResponse,
  PortfolioTrademarkProtocolsDeleteTrademarkRequest,
  PortfolioTrademarkProtocolsGetTrademarkDetailRequest,
  PortfolioTrademarkProtocolsGetTrademarkDetailResponse,
  PortfolioTrademarkProtocolsGetTrademarkListRequest,
  PortfolioTrademarkProtocolsGetTrademarkListResponse,
  PortfolioTrademarkProtocolsUpdateTrademarkRequest,
} from '../../swagger/data-contracts';

export const QueryKeyIpDocuments = 'ip_documents';

export const useIpBrandListQuery = <TData = PortfolioIpDocProtocolsGetBrandListResponse>(
  options?: UseQueryOptions<PortfolioIpDocProtocolsGetBrandListResponse, null, TData>
) => {
  return useQuery<PortfolioIpDocProtocolsGetBrandListResponse, null, TData>(
    [QueryKeyIpDocuments, 'list'],
    async () => {
      const { data } = await portfolioApi.brandList();
      return data;
    },
    {
      ...options,
      enabled: options?.enabled === undefined || options?.enabled,
      staleTime: 60000,
    }
  );
};

export const useBrandAddMutation = (): UseMutationResult<
  AxiosResponse<PortfolioIpDocProtocolsAddBrandResponse>,
  unknown,
  PortfolioIpDocProtocolsAddBrandRequest
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.brandAddCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'list']);
    },
  });
};

export const useBrandUpdateMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioIpDocProtocolsUpdateBrandRequest
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.brandUpdateCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'list']);
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'detail']);
    },
  });
};

export const useBrandDeleteMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioIpDocProtocolsDeleteBrandRequest
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.brandDeleteCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'list']);
    },
  });
};

export const useBrandDetailQuery = (
  request: PortfolioIpDocProtocolsGetBrandDetailRequest
): UseQueryResult<PortfolioIpDocProtocolsGetBrandDetailResponse> => {
  return useQuery([QueryKeyIpDocuments, 'detail', request.brand_id], async () => {
    const { data } = await portfolioApi.brandDetailCreate(request);
    return data;
  });
};

// Trademark
export const useIpDocumentsTrademarkQuery = (
  request: PortfolioTrademarkProtocolsGetTrademarkListRequest
): UseQueryResult<PortfolioTrademarkProtocolsGetTrademarkListResponse> => {
  return useQuery([QueryKeyIpDocuments, 'trademark', request], async () => {
    const { data } = await portfolioApi.trademarkCreate(request);
    return data;
  });
};

export const useIpDocumentsTrademarkAddMutation = (): UseMutationResult<
  AxiosResponse<PortfolioTrademarkProtocolsAddTrademarkResponse>,
  unknown,
  PortfolioTrademarkProtocolsAddTrademarkRequest
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.trademarkAddCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'trademark']);
    },
  });
};

export const useIpDocumentsTrademarkDetailQuery = (
  request: PortfolioTrademarkProtocolsGetTrademarkDetailRequest,
  enabled: boolean
): UseQueryResult<PortfolioTrademarkProtocolsGetTrademarkDetailResponse> => {
  return useQuery(
    [QueryKeyIpDocuments, 'trademark', 'detail', request.doc_id],
    async () => {
      const { data } = await portfolioApi.trademarkDetailCreate(request);
      return data;
    },
    { enabled }
  );
};

export const useIpDocumentsTrademarkUpdateMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioTrademarkProtocolsUpdateTrademarkRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.trademarkUpdateCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'trademark']);
    },
  });
};

export const useIpDocumentsTrademarkDeleteMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioTrademarkProtocolsDeleteTrademarkRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.trademarkDeleteCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'trademark']);
    },
  });
};

// Copyright
export const useCopyrightsDocumentListQuery = (
  request: PortfolioCopyrightsProtocolsGetDocumentsRequest
): UseQueryResult<PortfolioCopyrightsProtocolsGetDocumentsResponse> => {
  return useQuery([QueryKeyIpDocuments, 'copyrights', 'document', request.brand_id], async () => {
    const { data } = await portfolioApi.copyrightsDocListCreate(request);
    return data;
  });
};

export const useCopyrightsDocumentDetailQuery = (
  request: PortfolioCopyrightsProtocolsGetDocumentDetailsRequest,
  enabled: boolean
): UseQueryResult<PortfolioCopyrightsProtocolsGetDocumentDetailsResponse> => {
  return useQuery(
    [QueryKeyIpDocuments, 'copyrights', 'document', 'detail', request.id],
    async () => {
      const { data } = await portfolioApi.copyrightsDocDetailsCreate(request);
      return data;
    },
    { enabled }
  );
};

export const useCopyrightsDocumentAddMutation = (): UseMutationResult<
  AxiosResponse<PortfolioCopyrightsProtocolsAddDocumentResponse>,
  unknown,
  PortfolioCopyrightsProtocolsAddDocumentRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.copyrightsDocAddCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'copyrights', 'document']);
    },
  });
};

export const useCopyrightsDocumentUpdateMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioCopyrightsProtocolsUpdateDocumentRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.copyrightsDocUpdateCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'copyrights', 'document']);
    },
  });
};

export const useCopyrightsDocumentDeleteMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioCopyrightsProtocolsDeleteDocumentRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.copyrightsDocDeleteCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'copyrights', 'document']);
    },
  });
};

export const useCopyrightsCopyrightListQuery = (
  request: PortfolioCopyrightsProtocolsGetCopyrightsRequest,
  enabled: boolean
): UseQueryResult<PortfolioCopyrightsProtocolsGetCopyrightsResponse> => {
  return useQuery(
    [QueryKeyIpDocuments, 'copyrights', 'copyright', request.doc_id, request.sorted_order_enum],
    async () => {
      const { data } = await portfolioApi.copyrightsCopyrightListCreate(request);
      return data;
    },
    { enabled }
  );
};

export const useCopyrightsCopyrightAddMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioCopyrightsProtocolsAddCopyrightRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.copyrightsCopyrightAddCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'copyrights', 'copyright']);
    },
  });
};

export const useCopyrightsCopyrightUpdateMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioCopyrightsProtocolsUpdateCopyrightRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.copyrightsCopyrightUpdateCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'copyrights', 'copyright']);
    },
  });
};

export const useCopyrightsCopyrightDeleteMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioCopyrightsProtocolsDeleteCopyrightRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.copyrightsCopyrightDeleteCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'copyrights', 'copyright']);
    },
  });
};

// Design
export const useIpDocumentsDesignQuery = (
  request: PortfolioDesignProtocolsGetDesignListRequest
): UseQueryResult<PortfolioDesignProtocolsGetDesignListResponse> => {
  return useQuery([QueryKeyIpDocuments, 'design', request], async () => {
    const { data } = await portfolioApi.designCreate(request);
    return data;
  });
};

export const useIpDocumentsDesignAddMutation = (): UseMutationResult<
  AxiosResponse<PortfolioDesignProtocolsAddDesignResponse>,
  unknown,
  PortfolioDesignProtocolsAddDesignRequest
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.designAddCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'design']);
    },
  });
};

export const useIpDocumentsDesignUpdateMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioDesignProtocolsUpdateDesignRequest
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.designUpdateCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'design']);
    },
  });
};

export const useIpDocumentsDesignDeleteMutation = (): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  PortfolioDesignProtocolsDeleteDesignRequest,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(portfolioApi.designDeleteCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyIpDocuments, 'design']);
    },
  });
};

export const useIpDocumentsDesignDetailQuery = (
  request: PortfolioDesignProtocolsGetDesignDetailRequest,
  enabled: boolean
): UseQueryResult<PortfolioDesignProtocolsGetDesignDetailResponse> => {
  return useQuery(
    [QueryKeyIpDocuments, 'design', 'detail', request.doc_id],
    async () => {
      const { data } = await portfolioApi.designDetailCreate(request);
      return data;
    },
    { enabled }
  );
};

// Other
export const useIpDocumentsOwnerListQuery = (): UseQueryResult<PortfolioIpDocProtocolsGetOwnerListResponse> => {
  return useQuery([QueryKeyIpDocuments, 'owner_list'], async () => {
    const { data } = await portfolioApi.ownerList();
    return data;
  });
};

export const useIpDocumentsLicenseeListQuery = (): UseQueryResult<PortfolioIpDocProtocolsGetLicenseeListResponse> => {
  return useQuery([QueryKeyIpDocuments, 'licensee_list'], async () => {
    const { data } = await portfolioApi.licenseeList();
    return data;
  });
};

export const useIpDocumentFileUploadMutation = (): UseMutationResult<
  AxiosResponse<PortfolioIpDocProtocolsGetUploadUrlResponse>,
  unknown,
  PortfolioIpDocProtocolsGetUploadUrlRequest
> => {
  return useMutation(portfolioApi.presignedUploadCreate);
};

export const useIpDocumentFileDownloadMutation = (): UseMutationResult<
  AxiosResponse<PortfolioIpDocProtocolsGetDownloadUrlResponse>,
  unknown,
  PortfolioIpDocProtocolsGetDownloadUrlRequest,
  unknown
> => {
  return useMutation(portfolioApi.presignedDownloadCreate);
};
