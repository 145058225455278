export const stringSort = (a: string, b: string, orderBy: 'asc' | 'desc') => {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();
  if (nameA < nameB) return orderBy === 'asc' ? -1 : 1;
  if (nameA > nameB) return orderBy === 'asc' ? 1 : -1;

  return 0;
};

export const listSliceData = <T>(list: T[], target: T): T[] => {
  const index = list.findIndex((item) => item === target);
  if (index !== -1) {
    list.splice(index, 1);
  }
  return list;
};
