import { IDropDownItem } from '@marqvision/mds/core/DropDown';
import { FilterDropDown, FilterQueryParam } from '~/components/Filters/@types';

export const parseFilterQueryParamFromFeatures = (
  features: (string | number)[],
  list: IDropDownItem[]
): Partial<FilterQueryParam> => {
  if (features.length === 0) return {};

  const _features = features.filter((v) => Boolean(v));
  const oisFeatures = _features.filter(isFeatureOis);
  const nonOisFeatures = _features.filter(isNonFeatureOis);

  return nonOisFeatures.reduce(
    (acc, feature) => {
      switch (feature) {
        case 'tm_logo':
          return { ...acc, tm_logo: true };
        case 'tm_text':
          return { ...acc, tm_text: true };
        case 'infringing_images_filter':
          return { ...acc, infringing_images_filter: true };
        default:
          return acc;
      }
    },
    {
      ois: parseFeatureOisValue(oisFeatures, list),
    }
  );
};

export const parseFeaturesFromFilterQueryParam = (
  filter: Partial<FilterQueryParam>,
  list: IDropDownItem[]
): (string | number)[] => {
  if (Object.keys(filter).length === 0) return [];

  const features: (number | string)[] = [];

  if (filter.tm_logo) features.push('tm_logo');
  if (filter.tm_text) features.push('tm_text');
  if (filter.infringing_images_filter) features.push('infringing_images_filter');
  if (filter.ois) {
    const ois = typeof filter.ois === 'number' ? [filter.ois] : filter.ois;
    if (ois.includes(-1)) {
      return features.concat(
        list.find((item) => item.value === 'ois')?.children?.map((item) => item.value as number) || []
      );
    } else {
      features.push(...ois);
    }
  }
  return features;
};
const BASE_FEATURES_DATA: { value: string; label: string; count: null | number }[] = [
  {
    value: 'tm_logo',
    label: 'Trademarked logo used',
    count: null,
  },
  {
    value: 'tm_text',
    label: 'Trademarked text used',
    count: null,
  },
  {
    value: 'infringing_images_filter',
    label: 'Infringing image used',
    count: null,
  },
];
export const parseFeaturesFromFilterDropDown = (data: FilterDropDown): IDropDownItem[] => {
  const result = [...BASE_FEATURES_DATA];
  if (data.list.length > 0) {
    result.push({
      value: 'ois',
      label: 'Copyrighted image used',
      count: null,
      ...(data.list.length > 0 && { children: data.list }),
    });
  }
  return result;
};
const isFeatureOis = (feature: string | number): feature is number => {
  return typeof feature === 'number';
};
const isNonFeatureOis = (feature: string | number): feature is string => {
  return typeof feature === 'string';
};
const parseFeatureOisValue = (ois: number[], list: IDropDownItem[]): number[] => {
  const oisFeatures = list.find((item) => item.value === 'ois');
  if (!oisFeatures) return [];
  return oisFeatures.children && oisFeatures.children?.length > 0
    ? oisFeatures.children.length === ois.length
      ? [-1]
      : ois
    : [];
};
