import React, { SVGProps } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { IcoDocument, IcoExcelSheet, IcoImage, IcoPdf, IcoPptFill } from '@marqvision/mds/assets';
import { MDSTheme } from '@marqvision/mds/core';

type StyleProps = {
  color: string;
  width: string;
  height: string;
};

const useStyles = makeStyles({
  icon: {
    width: ({ width }: StyleProps) => width,
    height: ({ height }: StyleProps) => height,
    '& path': {
      fill: ({ color }: StyleProps) => color,
    },
  },
});

type IconData = { component: React.FunctionComponent<SVGProps<SVGSVGElement>>, color: string };

const iconList: Record<string, IconData> = {
  default: { component: IcoDocument, color: MDSTheme.palette.bluegray[900] },

  webp: { component: IcoImage, color: MDSTheme.palette.bluegray[900] },
  png: { component: IcoImage, color: MDSTheme.palette.bluegray[900] },
  jpg: { component: IcoImage, color: MDSTheme.palette.bluegray[900] },
  jpeg: { component: IcoImage, color: MDSTheme.palette.bluegray[900] },
  svg: { component: IcoImage, color: MDSTheme.palette.bluegray[900] },

  doc: { component: IcoDocument, color: MDSTheme.palette.blue[700] },
  docx: { component: IcoDocument, color: MDSTheme.palette.blue[700] },

  ppt: { component: IcoPptFill, color: MDSTheme.palette.red[700] },
  pptx: { component: IcoPptFill, color: MDSTheme.palette.red[700] },
  pdf: { component: IcoPdf, color: MDSTheme.palette.red[700] },

  csv: { component: IcoExcelSheet, color: MDSTheme.palette.green[700] },
  xls: { component: IcoExcelSheet, color: MDSTheme.palette.green[700] },
  xlsx: { component: IcoExcelSheet, color: MDSTheme.palette.green[700] },
};

type PropsWithFileName = {
  fileName: string;
  extension?: never;
};

type PropsWithExtension = {
  fileName?: never;
  extension: string;
};

type Props = {
  className?: string;
  color?: string;
  size?: string;  //width, height 동일 값 사용해 정사각형 출력
} & (PropsWithFileName | PropsWithExtension);

export const ExtensionIcon = (props: Props) => {
  const { fileName, className, size = '24px' } = props;
  
  const extension = props.extension?.toLowerCase() || fileName?.split('.').pop()?.toLowerCase();
  const key = (extension && extension in iconList) ? extension : 'default';
  
  const IconComponent = iconList[key].component;
  const color = props.color || iconList[key].color;
  
  const classes = useStyles({ color, width: size, height: size });

  return <IconComponent fill={color} className={clsx(classes.icon, className)} />;
};