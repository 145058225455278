import React, { HTMLAttributes, Ref, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { MDSTheme } from '../index';

const useStyles = makeStyles({
  tr: {
    '&.clickable': {
      cursor: 'pointer',
    },
    '& td': {
      backgroundColor: MDSTheme.palette.white,
    },
    'tbody &:hover td': {
      background: MDSTheme.palette.bluegray[50],
    },
    'tbody &.isViewingDetails td': {
      background: MDSTheme.palette.blue[50],
    },
    '&.isViewingDetails:hover td': {
      background: MDSTheme.palette.blue[200],
    },
  },
});

type Props = {
  isViewingDetails?: boolean;
};

const MDSTableRow = forwardRef(
  (props: Props & HTMLAttributes<HTMLTableRowElement>, ref: Ref<HTMLTableRowElement>): React.ReactNode => {
    const { className, isViewingDetails, children, ...restProps } = props;
    const classes = useStyles();

    return (
      <tr
        ref={ref}
        className={clsx(classes.tr, className, { isViewingDetails, clickable: !!props.onClick })}
        {...restProps}
      >
        {children}
      </tr>
    );
  }
);
MDSTableRow.displayName = 'MDSTableRow';

export default MDSTableRow;
