import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const getTypographyStyle = (fontSize: string) => ({
  fontSize,
  lineHeight: 1.5,
  '&.ellipsis': {
    display: '-webkit-box',
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    overflowWrap: 'anywhere',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
  },
});

const Breakpoint = {
  tablet: 768,
  mobile: 576,
} as const;

const MDSTheme = {
  typography: {
    H1: getTypographyStyle('24px'),
    T40: getTypographyStyle('40px'),
    T32: getTypographyStyle('32px'),
    T24: getTypographyStyle('24px'),
    T20: getTypographyStyle('20px'),
    T18: getTypographyStyle('18px'),
    T16: getTypographyStyle('16px'),
    T14: getTypographyStyle('14px'),
    T13: getTypographyStyle('13px'),
    T12: getTypographyStyle('12px'),
    bold: {
      fontFamily: `Visuelt-Bold, Pretendard Variable`,
      fontWeight: 700,
    },
    medium: {
      fontFamily: `Visuelt-Medium, Pretendard Variable`,
      fontWeight: 500,
    },
    regular: {
      fontFamily: `Visuelt-Regular, Pretendard Variable`,
      fontWeight: 400,
    },
    light: {
      fontFamily: `Visuelt-Light, Pretendard Variable`,
      fontWeight: 300,
    },
  },
  palette: {
    white: '#FFFFFF',
    bluegray: {
      50: '#F9FAFB',
      100: '#F1F4F6',
      150: '#EAEDF0',
      200: '#DFE3E8',
      300: '#C4CDD5',
      400: '#AAB5C0',
      500: '#919EAB',
      600: '#768695',
      700: '#637381',
      800: '#454F5B',
      850: '#343E49',
      900: '#212B36',
      1000: '#161C24',
    },
    blue: {
      50: '#E9EEFD',
      100: '#D7E1FB',
      200: '#C0CFF8',
      300: '#A3B9F5',
      400: '#7999F1',
      500: '#4F79EC',
      600: '#2D5FE9',
      700: '#1648D2',
      800: '#1F3F99',
      900: '#142A66',
    },
    red: {
      50: '#FCE9E6',
      100: '#FCD2CB',
      200: '#F6BEB4',
      300: '#F2A091',
      400: '#EC755F',
      500: '#E64A2D',
      600: '#E12705',
      700: '#CB1400',
      800: '#871703',
      900: '#5A0F02',
    },
    orange: {
      50: '#FFEFE5',
      100: '#FFE0CC',
      200: '#FFD0B2',
      300: '#FFBB8F',
      400: '#FF9D5C',
      500: '#FF7E29',
      600: '#FF6500',
      700: '#D35200',
      800: '#A13F00',
      900: '#662800',
    },
    yellow: {
      50: '#FFF3DC',
      100: '#FFE5B6',
      200: '#FFDA95',
      300: '#FFCB6C',
      400: '#FFBD42',
      500: '#FDAF1E',
      600: '#F1A416',
      700: '#CA8402',
      800: '#9B6500',
      900: '#4C3000',
    },
    green: {
      50: '#E5F7E5',
      100: '#CCEFCC',
      200: '#B2E7B2',
      300: '#8FDC8F',
      400: '#5CCC5C',
      500: '#29BC29',
      600: '#00AF00',
      700: '#008C00',
      800: '#1C5E20',
      900: '#004600',
    },
    teal: {
      50: '#E5F5F7',
      100: '#CDECF1',
      200: '#B3E1E9',
      300: '#91D5E0',
      400: '#5FC1D2',
      500: '#2CAEC4',
      600: '#049EB8',
      700: '#037E93',
      800: '#025E6E',
      900: '#013F49',
    },
    lightblue: {
      50: '#E5F6FE',
      100: '#CCEDFD',
      200: '#B2E4FC',
      300: '#8FD8FB',
      400: '#5CC7F9',
      500: '#29B5F8',
      600: '#00A7F6',
      700: '#007EBA',
      800: '#005C96',
      900: '#004262',
    },
    indigo: {
      50: '#F0ECFA',
      100: '#E3DAF6',
      200: '#D4C7F0',
      300: '#C1ADEB',
      400: '#A588E1',
      500: '#8963D8',
      600: '#7245D0',
      700: '#512AA3',
      800: '#3D1F7A',
      900: '#281551',
    },
    purple: {
      50: '#F4E5F7',
      100: '#EACCEF',
      200: '#DFB2E7',
      300: '#D18FDC',
      400: '#BD5CCD',
      500: '#A829BD',
      600: '#9700B0',
      700: '#78008C',
      800: '#5A0069',
      900: '#3C0046',
    },
    magenta: {
      50: '#FAE7F0',
      100: '#F7D0E2',
      200: '#F2B8D3',
      300: '#ED98BF',
      400: '#E469A2',
      500: '#DC3A85',
      600: '#D5146D',
      700: '#AA1057',
      800: '#7F0C41',
      900: '#55082B',
    },
    brown: {
      50: '#F0ECEA',
      100: '#E2D9D7',
      200: '#D2C6C2',
      300: '#BFACA6',
      400: '#A1877E',
      500: '#846156',
      600: '#6C4335',
      700: '#56352A',
      800: '#40281F',
      900: '#2B1A15',
    },
    gray: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#DDDDDD',
      400: '#CCCCCC',
      500: '#AAAAAA',
      600: '#777777',
      700: '#555555',
      800: '#333333',
      900: '#111111',
    },
    black: '#000000',
    black_alpha_50: 'rgba(0, 0, 0, 0.5)',
    black_alpha_30: 'rgba(0, 0, 0, 0.3)',
    black_alpha_10: 'rgba(0, 0, 0, 0.1)',
    black_alpha_5: 'rgba(0, 0, 0, 0.05)',
  },
  breakpoint: {
    ...Breakpoint,
  },
  breakpoints: createBreakpoints({}),
} as const;

export default MDSTheme;
