import {
  PortfolioIpDocProtocolsGetBrandListResponse,
  SettingsProtocolsGetClientActiveCountriesResponse,
} from '~/swagger/data-contracts';

export const parseActiveCountriesCount = (apiData: SettingsProtocolsGetClientActiveCountriesResponse) => {
  return apiData.countries.length + 1; // 1: Global
};
export const parseBrandList = (apiData: PortfolioIpDocProtocolsGetBrandListResponse) => {
  return apiData.brand_list.map((brand) => ({
    id: brand.brand_id,
    copyrights: brand.copyrights,

    designs: brand.designs,
    logoUrl: brand.logo_url,
    name: brand.name,

    trademarks: brand.trademarks,

    updatedDate: brand.updated_date,
  }));
};
