import { useMemo } from 'react';
import { useAtom, useSetAtom } from 'jotai/index';
import { WebsiteFilter } from '~/types/websiteImpersonation';
import { resetWebsiteFilterAtom, websiteFilterAtom } from '~/atoms/websiteImpersonation';

export const useWebsiteFilter = () => {
  const [filter, setFilter] = useAtom(websiteFilterAtom);
  const resetFilter = useSetAtom(resetWebsiteFilterAtom);

  const requestFilterParams = useMemo(() => {
    return {
      root_domain_id_list: filter.rootDomainIdList,
      search: filter.rootDomainSearchKeyword,
    };
  }, [filter]);

  const isResetFilterEnabled = useMemo(() => {
    return filter.rootDomainIdList && filter.rootDomainIdList.length > 0;
  }, [filter]);

  return {
    filter,
    setFilter: (newFilter: Partial<WebsiteFilter>) => {
      setFilter((ps) => ({ ...ps, ...newFilter }));
    },
    resetFilter,
    requestFilterParams,
    isResetFilterEnabled,
  };
};

export type UseWebsiteFilter = ReturnType<typeof useWebsiteFilter>;
