import JSZip from 'jszip';
import countries from 'i18n-iso-countries';
import countryLanguage from 'i18n-iso-countries/langs/en.json';
import { saveAs } from 'file-saver';
import { IDropDownItem, ISelectValue } from '@marqvision/mds/core/DropDown';
import { REGEX_EMAIL } from './RegEx';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: Blob, filename?: string) => boolean;
  }
}

//#region Manipulate array
export const toggleOnArray = <T extends string | number>(source: T[], element: T | T[]): T[] => {
  const set = new Set<T>(source);

  const arrayToToggle = element instanceof Array ? element : [element];

  const isSubset = isSubsetArray(source, arrayToToggle);

  arrayToToggle.forEach((element) => {
    if (isSubset && set.has(element)) {
      set.delete(element);
    } else {
      set.add(element);
    }
  });

  return [...set];
};

export const removeFromArray = <T>(source: T[], element: T | T[], comparator?: (item: T | T[]) => void): T[] => {
  const arr = [...source];
  const targets = Array.isArray(element) ? element : [element];

  targets.forEach((target) => {
    const removeIndex = arr.findIndex((item) => (comparator ? comparator(item) : item === target));

    if (removeIndex >= 0) {
      arr.splice(removeIndex, 1);
    }
  });

  return arr;
};

export const getIntersectionArray = (a: unknown[], b: unknown[]): unknown[] => {
  const set1 = new Set(a);
  const set2 = new Set(b);

  const intersection = [...set1].filter((element) => set2.has(element));

  return intersection;
};

export const isSubsetArray = <T extends string | number>(set: T[], subset: T[]): boolean =>
  subset.every((element) => set.includes(element));
//#endregion

//#region Download file
export const downloadFile = async (fileOrUrl: File | string, filename?: string): Promise<void> => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.rel = 'noopener noreferrer';

  let url;
  if (filename) {
    a.download = filename;
  }

  if (fileOrUrl instanceof File) {
    url = window.URL.createObjectURL(fileOrUrl);
    a.download = fileOrUrl.name;
  } else {
    const blob = await fetch(fileOrUrl).then((response) => response.blob());
    url = URL.createObjectURL(blob);
  }

  a.href = url;
  a.click();
  window.URL.revokeObjectURL(url);
};
export const downloadFileWithFilename = async (url: string, filename: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        if (filename && filename.length) a.download = filename;
        document.body.appendChild(a);
        a.click();
        resolve();
      })
      .catch((err) => reject(err));
  });
};

export const downloadBlob = (data: unknown, filename: string): void => {
  if (!data) return;

  const blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);

    return;
  }

  const lnk = document.createElement('a');
  const url = window.URL;
  let objectURL;

  lnk.download = filename || 'untitled';
  lnk.href = objectURL = url.createObjectURL(blob);
  lnk.dispatchEvent(new MouseEvent('click'));
  setTimeout(url.revokeObjectURL.bind(url, objectURL));
};

//#endregion

//#region Formatters
export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const trimTag = (text: string) => text.replace(/<[a-z]+\s*\/>/, ' ');
//#endregion

//#region Getters
export const getValidUrl = (url: string, scheme = 'https'): string =>
  /^(http|https):\/\//i.test(url) ? encodeURI(url) : `${scheme}://${encodeURI(url)}`;

export const getFileNameFromUrl = (url: string): string => url.split('/').pop()?.split('?')[0] || '';

export const getFileNameAndExtension = (fullFileName: string): [string, string] => {
  const [_, name = '', ext = ''] = Array.from(/(.*)(\.[a-zA-Z0-9]+$)/.exec(fullFileName) || []);

  return [name, ext];
};

// aliases
// - packages/mds/core/DropDown/@utils.ts:getRegExpByKeyword
export const getRegExpByKeyword = (keyword: string) => {
  return new RegExp(keyword.replace(/[.*+?^${}()\[\]\\]/g, '\\$&'), 'ig');
};

export const getUniqueURLs = (urlLike: string, urlList: string[]) => {
  const urls: string[] = [];

  urlLike.split('\n').forEach((url) => {
    // split by row (in google spreadsheet)
    if (!url.trim().length) return;
    if (urlList.includes(url)) return;

    urls.push(url);
  });

  return urls;
};

export const getCountryName = (countryCode: string): string => {
  countries.registerLocale(countryLanguage);

  if (!countryCode) {
    return '';
  }

  if (countryCode === '00') {
    return 'Global';
  } else {
    return countries.getName(countryCode, 'en', { select: 'alias' }) || '';
  }
};

//#endregion

//#region Parsers
export function parseJwt<T>(rawToken: string): T | undefined {
  try {
    // 토큰을 '.' 기준으로 분리
    const base64Url = rawToken.split('.')[1];
    // base64를 디코딩하여 JSON 문자열로 변환
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    // JSON 객체로 반환
    return JSON.parse(jsonPayload) as T;
  } catch (e) {
    // 오류 처리
    console.error('Invalid JWT Token', e);
  }
}

export const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};
//#endregion

//#region Feature Flag
const featureFlags = [
  'brand-client-8124-amplitude',
  'brand-client-8632-map-monitoring',
  'opscc-ui-web-10377-model-sample-sheet',
  'brand-client-10051-gen-ai',
] as const;
type featureFlagKey = (typeof featureFlags)[number];
export const FeatureFlags = featureFlags.reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: curr,
  }),
  {}
) as Record<featureFlagKey, string>;
//#endregion

//#region Validators
export const validatePassword = (value: string) => {
  const PasswordValidationRegex = /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[@#$%^&+=!?`~*()_\-\[\]{}|;:'",.<>\/\\]).*$/;

  return PasswordValidationRegex.test(value);
};

export const validateEmail = (email: string) => email.length > 0 && REGEX_EMAIL.test(email);
//#endregion

export const getSelected = (selected: ISelectValue | ISelectValue[], list?: IDropDownItem[]) => {
  if (Array.isArray(selected)) {
    if (selected.length > 0) {
      if ((list && list.length === selected.length) || (selected.length === 1 && selected[0].value === -1)) {
        return {
          name: 'All',
        };
      }
      if (list) {
        const loop = (item: IDropDownItem[]): number => {
          return item.reduce((tot, cur) => tot + (cur.children ? loop(cur.children) : 1), 0);
        };
        if (selected.length === loop(list)) {
          return {
            name: 'All',
          };
        }
      }
      return {
        name: selected[0].displayLabel || selected[0].label,
        count: selected.length > 1 ? `+${selected.length - 1}` : undefined,
      };
    }
    return undefined;
  }
  return {
    name: selected.displayLabel || selected.label,
  };
};

export const parseFixedPercent = (value?: number | string) => {
  if (typeof value === 'undefined') return '';
  const number = Number(value);

  return (Math.round(number * 10) / 10).toString();
};

/**
 * @param filename 최종적으로 만들어진 zip파일의 파일명
 * @param fileList 다운로드 받을 file 각각의 이름과 url을 담은 배열
 * @returns
 */
export const saveZip = async (filename: string, fileList: { url: string; filename?: string | null }[]) => {
  if (!fileList.length) return;

  const fetchFiles = await Promise.allSettled(
    fileList.map(({ url }) => {
      return fetch(url).then((r) => {
        if (r.status === 200) return r.blob();
        return Promise.reject(new Error(r.statusText));
      });
    })
  );

  const zip = new JSZip();
  fileList.forEach(({ url, filename }, index) => {
    const res = fetchFiles[index];
    if (res.status === 'fulfilled') {
      zip.file(filename || getFileNameFromUrl(url), res.value);
    } else {
      if (process.env.NODE_ENV !== 'production') {
        console.group('🚨 There are files that failed to download!!');
        console.error('file_url:', url);
        console.error('reason:', res.reason);
        console.groupEnd();
      }
    }
  });
  try {
    await zip.generateAsync({ type: 'blob' }).then((blob) => saveAs(blob, filename));
    return true;
  } catch {
    return false;
  }
};

export * from './RegEx';
export * from '../commerce/utils';
