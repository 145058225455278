import flattenDeep from 'lodash/fp/flattenDeep';

// TODO: MDSDropdown 타입 잡을 때 같이 타입 잡기 + MDS로 옮겨서 MDSDropdown의 유틸 함수로 사용될 수 있도록 하기
export const getFilterSelected = (selectedItems: unknown[], list?: any[]) => {
  if (!selectedItems || !list?.length) {
    return;
  }

  // selected value가 [-1]인 경우 All Selected
  if (JSON.stringify(selectedItems) === '[-1]') {
    return { name: 'All' };
  }

  const count = selectedItems.length;
  if (count > 0) {
    let selectedItem = list?.find((row) => {
      if (typeof selectedItems[0] === 'object') {
        // selected is array of {value: string, label: string}
        return selectedItems.find((item: any) => item.value === row.value);
      } else {
        // selected is array of primitive type
        return selectedItems.includes(row.value);
      }
    });

    if (!selectedItem) {
      list?.forEach((row) => {
        const selectedChild = row.children?.find((child: any) => {
          if (typeof selectedItems[0] === 'object') {
            // selected is array of {value: string, label: string}
            return selectedItems.find((item: any) => item.value === child.value);
          } else {
            // selected is array of primitive type
            return selectedItems.includes(child.value);
          }
        });
        if (selectedChild) {
          selectedItem = selectedChild;
        }
      });
    }

    const isGroupList = list?.some((item) => item.children);
    if (isGroupList) {
      const flattenList = flattenDeep(list?.map((item) => item.children));
      if (count === flattenList?.length) {
        return { name: 'All' };
      }
    } else {
      if (count === list?.length) {
        return { name: 'All' };
      }
    }

    return {
      name: selectedItem?.displayLabel || selectedItem?.label || 'Searching...',
      count: selectedItems.length > 1 ? `+${selectedItems.length - 1}` : undefined,
    };
  }
};
