/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MonitoringFilterProtocolResponse,
  SellerSellerAnalyticClusterProtocolsExportExcelRequest,
  SellerSellerAnalyticClusterProtocolsExportExcelResponse,
  SellerSellerAnalyticClusterProtocolsGetChannelDomainFilterRequest,
  SellerSellerAnalyticClusterProtocolsGetSellerClusterCountRequest,
  SellerSellerAnalyticClusterProtocolsGetSellerClusterCountResponse,
  SellerSellerAnalyticClusterProtocolsGetSellerClusterListRequest,
  SellerSellerAnalyticClusterProtocolsGetSellerClusterListResponse,
  SellerSellerAnalyticClusterProtocolsProgressExcelRequest,
  SellerSellerAnalyticClusterProtocolsProgressExcelResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SellerAnalyticsCluster<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SellerAnalyticsCluster
   * @name ClusterFilterChannelDomainsCreate
   * @request POST:/seller-analytic/cluster/filter/channel-domains
   */
  clusterFilterChannelDomainsCreate = (
    data: SellerSellerAnalyticClusterProtocolsGetChannelDomainFilterRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/cluster/filter/channel-domains`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsCluster
   * @name ClusterGetListCreate
   * @request POST:/seller-analytic/cluster/get_list
   */
  clusterGetListCreate = (
    data: SellerSellerAnalyticClusterProtocolsGetSellerClusterListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticClusterProtocolsGetSellerClusterListResponse, any>({
      path: `/seller-analytic/cluster/get_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsCluster
   * @name ClusterCountCreate
   * @request POST:/seller-analytic/cluster/count
   */
  clusterCountCreate = (
    data: SellerSellerAnalyticClusterProtocolsGetSellerClusterCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticClusterProtocolsGetSellerClusterCountResponse, any>({
      path: `/seller-analytic/cluster/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsCluster
   * @name ClusterExportCreate
   * @request POST:/seller-analytic/cluster/export
   */
  clusterExportCreate = (data: SellerSellerAnalyticClusterProtocolsExportExcelRequest, params: RequestParams = {}) =>
    this.http.request<SellerSellerAnalyticClusterProtocolsExportExcelResponse, any>({
      path: `/seller-analytic/cluster/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsCluster
   * @name ClusterExportProgressCreate
   * @request POST:/seller-analytic/cluster/export/progress
   */
  clusterExportProgressCreate = (
    data: SellerSellerAnalyticClusterProtocolsProgressExcelRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticClusterProtocolsProgressExcelResponse, any>({
      path: `/seller-analytic/cluster/export/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
