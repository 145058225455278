/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SettingsProtocolsGetClientActiveCountriesResponse,
  SettingsProtocolsGetEmployeeListResponse,
  SettingsProtocolsGetProfileResponse,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Settings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Settings
   * @name GetProfileList
   * @request GET:/settings/get-profile
   */
  getProfileList = (params: RequestParams = {}) =>
    this.http.request<SettingsProtocolsGetProfileResponse, any>({
      path: `/settings/get-profile`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Settings
   * @name GetEmployeeListList
   * @request GET:/settings/get-employee-list
   */
  getEmployeeListList = (params: RequestParams = {}) =>
    this.http.request<SettingsProtocolsGetEmployeeListResponse, any>({
      path: `/settings/get-employee-list`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Settings
   * @name ClientActiveCountriesList
   * @request GET:/settings/client-active-countries
   */
  clientActiveCountriesList = (params: RequestParams = {}) =>
    this.http.request<SettingsProtocolsGetClientActiveCountriesResponse, any>({
      path: `/settings/client-active-countries`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
