/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyRequest,
  MarqCommonProtocolEmptyResponse,
  MarqCommonProtocolFileResponse,
  SellerIntelligenceProtocolsGetClusterDetailsCountriesRequest,
  SellerIntelligenceProtocolsGetClusterDetailsCountriesResponse,
  SellerIntelligenceProtocolsGetClusterDetailsMarketplacesRequest,
  SellerIntelligenceProtocolsGetClusterDetailsMarketplacesResponse,
  SellerIntelligenceProtocolsGetClusterDetailsSellersDataRequest,
  SellerIntelligenceProtocolsGetClusterDetailsSellersDataResponse,
  SellerIntelligenceProtocolsGetClusterDetailsSellersRequest,
  SellerIntelligenceProtocolsGetClusterDetailsSellersResponse,
  SellerIntelligenceProtocolsGetClusterDetailsSummaryRequest,
  SellerIntelligenceProtocolsGetClusterDetailsSummaryResponse,
  SellerIntelligenceProtocolsGetClusterMasterStatusResponse,
  SellerIntelligenceProtocolsGetClustersSideViewTypeARequest,
  SellerIntelligenceProtocolsGetClustersSideViewTypeAResponse,
  SellerIntelligenceProtocolsGetClustersSideViewTypeBRequest,
  SellerIntelligenceProtocolsGetClustersSideViewTypeBResponse,
  SellerIntelligenceProtocolsGetExportDataRequest,
  SellerIntelligenceProtocolsGetTypeAClusterByIdRequest,
  SellerIntelligenceProtocolsGetTypeAClusterByIdResponse,
  SellerIntelligenceProtocolsGetTypeBClusterByIdRequest,
  SellerIntelligenceProtocolsGetTypeBClusterByIdResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SellerIntelligence<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name CmStatusCreate
   * @request POST:/seller-intel/cm-status
   */
  cmStatusCreate = (data: MarqCommonProtocolEmptyRequest, params: RequestParams = {}) =>
    this.http.request<SellerIntelligenceProtocolsGetClusterMasterStatusResponse, any>({
      path: `/seller-intel/cm-status`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClustersTypeACreate
   * @request POST:/seller-intel/clusters/type-a
   */
  clustersTypeACreate = (data: SellerIntelligenceProtocolsGetTypeAClusterByIdRequest, params: RequestParams = {}) =>
    this.http.request<SellerIntelligenceProtocolsGetTypeAClusterByIdResponse, any>({
      path: `/seller-intel/clusters/type-a`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClustersTypeBCreate
   * @request POST:/seller-intel/clusters/type-b
   */
  clustersTypeBCreate = (data: SellerIntelligenceProtocolsGetTypeBClusterByIdRequest, params: RequestParams = {}) =>
    this.http.request<SellerIntelligenceProtocolsGetTypeBClusterByIdResponse, any>({
      path: `/seller-intel/clusters/type-b`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClustersSideTypeACreate
   * @request POST:/seller-intel/clusters/side/type-a
   */
  clustersSideTypeACreate = (
    data: SellerIntelligenceProtocolsGetClustersSideViewTypeARequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerIntelligenceProtocolsGetClustersSideViewTypeAResponse, any>({
      path: `/seller-intel/clusters/side/type-a`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClustersSideTypeBCreate
   * @request POST:/seller-intel/clusters/side/type-b
   */
  clustersSideTypeBCreate = (
    data: SellerIntelligenceProtocolsGetClustersSideViewTypeBRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerIntelligenceProtocolsGetClustersSideViewTypeBResponse, any>({
      path: `/seller-intel/clusters/side/type-b`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClusterCountriesCreate
   * @request POST:/seller-intel/cluster/countries
   */
  clusterCountriesCreate = (
    data: SellerIntelligenceProtocolsGetClusterDetailsCountriesRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerIntelligenceProtocolsGetClusterDetailsCountriesResponse, any>({
      path: `/seller-intel/cluster/countries`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClusterSellersDataCreate
   * @request POST:/seller-intel/cluster/sellers-data
   */
  clusterSellersDataCreate = (
    data: SellerIntelligenceProtocolsGetClusterDetailsSellersDataRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerIntelligenceProtocolsGetClusterDetailsSellersDataResponse, any>({
      path: `/seller-intel/cluster/sellers-data`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClusterSellersCreate
   * @request POST:/seller-intel/cluster/sellers
   */
  clusterSellersCreate = (
    data: SellerIntelligenceProtocolsGetClusterDetailsSellersRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerIntelligenceProtocolsGetClusterDetailsSellersResponse, any>({
      path: `/seller-intel/cluster/sellers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClusterSummaryCreate
   * @request POST:/seller-intel/cluster/summary
   */
  clusterSummaryCreate = (
    data: SellerIntelligenceProtocolsGetClusterDetailsSummaryRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerIntelligenceProtocolsGetClusterDetailsSummaryResponse, any>({
      path: `/seller-intel/cluster/summary`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClusterMarketplacesCreate
   * @request POST:/seller-intel/cluster/marketplaces
   */
  clusterMarketplacesCreate = (
    data: SellerIntelligenceProtocolsGetClusterDetailsMarketplacesRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerIntelligenceProtocolsGetClusterDetailsMarketplacesResponse, any>({
      path: `/seller-intel/cluster/marketplaces`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name RequestActiveCreate
   * @request POST:/seller-intel/request-active
   */
  requestActiveCreate = (data: MarqCommonProtocolEmptyRequest, params: RequestParams = {}) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/seller-intel/request-active`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerIntelligence
   * @name ClusterSellersDataExportCreate
   * @request POST:/seller-intel/cluster/sellers-data/export
   */
  clusterSellersDataExportCreate = (
    data: SellerIntelligenceProtocolsGetExportDataRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/seller-intel/cluster/sellers-data/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
