import React, { useState } from 'react';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChipProps, Features, MDSChip, MDSIcon, MDSTypography } from '@marqvision/mds-v2';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '4px',
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    borderRadius: '8px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
});

type LabelProps = { label: string; color?: Features['color'] };

type Props = {
  from: string | LabelProps;
  to: string | LabelProps;
  onUpdate: () => void;
  startIcon?: React.ReactElement;
} & Partial<Pick<ChipProps, 'variant' | 'size' | 'color'>>;

export const UpdateListingStatusChipWithDialog = (props: Props) => {
  const { to, from, onUpdate, variant = 'border', size = 'medium', color = 'bluegray', startIcon } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = () => {
    onUpdate();
    handleClose();
  };

  return (
    <>
      <MDSChip variant={variant} size={size} color={color} startIcon={startIcon} onClick={handleOpen}>
        {typeof to === 'string' ? to : to.label}
      </MDSChip>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        PaperProps={{ className: classes.wrapper }}
      >
        <div className={classes.row}>
          <MDSTypography variant="T14" color={typeof from === 'string' ? undefined : from.color}>
            {typeof from === 'string' ? from : from.label}
          </MDSTypography>
          <MDSIcon.TailArrowDown transform="rotate(-90)" size={16} />
          <MDSTypography variant="T14" weight="bold" color={typeof to === 'string' ? undefined : to.color}>
            {typeof to === 'string' ? to : to.label}
          </MDSTypography>
        </div>
        <div className={classes.row}>
          <MDSChip variant="tint" color="bluegray" size="medium" onClick={handleClose}>
            <MDSIcon.CloseDelete variant="outline" size={16} />
          </MDSChip>
          <MDSChip variant="tint" color="blue" size="medium" onClick={handleUpdate}>
            <MDSIcon.Check variant="outline" size={16} color="color/content/primary/default/normal" />
          </MDSChip>
        </div>
      </Popover>
    </>
  );
};
