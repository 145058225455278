import * as Toastify from 'react-toastify';
import { makeStyles } from '@material-ui/core';
import { MDSTheme } from '@marqvision/mds/core';

const useStyles = makeStyles((theme) => ({
  toast: {
    position: 'absolute',
    width: '100%',
    padding: '0 16px',

    '& .Toastify__toast': {
      width: '100% !important',
      maxWidth: '800px',
      pointerEvents: 'auto',
      margin: '8px auto 0',
      minHeight: '44px',
      borderRadius: '8px',
      paddingLeft: '24px',
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
    '& .Toastify__toast--default': {
      width: '500px',
      backgroundColor: MDSTheme.palette.blue[500],
    },
    '& .Toastify__toast--success': {
      color: MDSTheme.palette.bluegray[800],
      backgroundColor: MDSTheme.palette.green[50],
    },
    '& .Toastify__toast--error': {
      backgroundColor: MDSTheme.palette.red[700],
      padding: '16px',
    },
    '& .Toastify__toast--info': {
      backgroundColor: MDSTheme.palette.blue[500],
      cursor: 'default',
      padding: 'inherit',
    },
    '& .Toastify__toast-body': {
      ...theme.typography.regular,
      fontSize: 13,
      color: MDSTheme.palette.white,
      textAlign: 'center',
      padding: '0',
      '& .Toastify__toast-icon': {
        display: 'none',
      },
    },
  },
}));

interface IProps {
  toast?: string;
}

export const ToastContainer = (props: IProps) => {
  const classes = useStyles();

  return (
    <Toastify.ToastContainer
      className={classes.toast}
      position="bottom-center"
      transition={Toastify.Slide}
      autoClose={4000}
      closeButton={false}
      hideProgressBar={true}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      limit={2} // note-@jamie: [PROD-8659]에 의해 일시적으로 조정합니다. - 논의 후 결정이 필요합 부분입니다.
      {...props}
    />
  );
};
