/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PortfolioAssetProtocolsBulkUpdateAssetRequest,
  PortfolioAssetProtocolsCreateAssetByPresignedRequest,
  PortfolioAssetProtocolsCreateCollectionRequest,
  PortfolioAssetProtocolsCreateCollectionResponse,
  PortfolioAssetProtocolsDeleteAssetRequest,
  PortfolioAssetProtocolsDeleteCollectionRequest,
  PortfolioAssetProtocolsGetAllCollectionsResponse,
  PortfolioAssetProtocolsGetAssetListRequest,
  PortfolioAssetProtocolsGetAssetListResponse,
  PortfolioAssetProtocolsGetAssetPresignedUrlRequest,
  PortfolioAssetProtocolsGetAssetPresignedUrlResponse,
  PortfolioAssetProtocolsGetAssetRequest,
  PortfolioAssetProtocolsGetAssetResponse,
  PortfolioAssetProtocolsGetIpDocumentsRequest,
  PortfolioAssetProtocolsGetIpDocumentsResponse,
  PortfolioAssetProtocolsGetIpDocumentsUniqueNameRequest,
  PortfolioAssetProtocolsGetIpDocumentsUniqueNameResponse,
  PortfolioAssetProtocolsUpdateAssetByPresignedRequest,
  PortfolioAssetProtocolsUpdateCollectionRequest,
  PortfolioCopyrightsProtocolsAddCopyrightRequest,
  PortfolioCopyrightsProtocolsAddDocumentRequest,
  PortfolioCopyrightsProtocolsAddDocumentResponse,
  PortfolioCopyrightsProtocolsDeleteCopyrightRequest,
  PortfolioCopyrightsProtocolsDeleteDocumentRequest,
  PortfolioCopyrightsProtocolsGetCopyrightsRequest,
  PortfolioCopyrightsProtocolsGetCopyrightsResponse,
  PortfolioCopyrightsProtocolsGetDocumentDetailsRequest,
  PortfolioCopyrightsProtocolsGetDocumentDetailsResponse,
  PortfolioCopyrightsProtocolsGetDocumentsRequest,
  PortfolioCopyrightsProtocolsGetDocumentsResponse,
  PortfolioCopyrightsProtocolsUpdateCopyrightRequest,
  PortfolioCopyrightsProtocolsUpdateDocumentRequest,
  PortfolioDesignProtocolsAddDesignRequest,
  PortfolioDesignProtocolsAddDesignResponse,
  PortfolioDesignProtocolsDeleteDesignRequest,
  PortfolioDesignProtocolsGetDesignDetailRequest,
  PortfolioDesignProtocolsGetDesignDetailResponse,
  PortfolioDesignProtocolsGetDesignListRequest,
  PortfolioDesignProtocolsGetDesignListResponse,
  PortfolioDesignProtocolsUpdateDesignRequest,
  PortfolioIpDocProtocolsAddBrandRequest,
  PortfolioIpDocProtocolsAddBrandResponse,
  PortfolioIpDocProtocolsDeleteBrandRequest,
  PortfolioIpDocProtocolsGetBrandDetailRequest,
  PortfolioIpDocProtocolsGetBrandDetailResponse,
  PortfolioIpDocProtocolsGetBrandListResponse,
  PortfolioIpDocProtocolsGetDownloadUrlRequest,
  PortfolioIpDocProtocolsGetDownloadUrlResponse,
  PortfolioIpDocProtocolsGetLicenseeListResponse,
  PortfolioIpDocProtocolsGetOwnerListResponse,
  PortfolioIpDocProtocolsGetUploadUrlRequest,
  PortfolioIpDocProtocolsGetUploadUrlResponse,
  PortfolioIpDocProtocolsUpdateBrandRequest,
  PortfolioTrademarkProtocolsAddTrademarkRequest,
  PortfolioTrademarkProtocolsAddTrademarkResponse,
  PortfolioTrademarkProtocolsDeleteTrademarkRequest,
  PortfolioTrademarkProtocolsGetTrademarkDetailRequest,
  PortfolioTrademarkProtocolsGetTrademarkDetailResponse,
  PortfolioTrademarkProtocolsGetTrademarkListRequest,
  PortfolioTrademarkProtocolsGetTrademarkListResponse,
  PortfolioTrademarkProtocolsUpdateTrademarkRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class IpPortfolio<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags IpPortfolio
   * @name OwnerList
   * @request GET:/ip_doc/owner
   */
  ownerList = (params: RequestParams = {}) =>
    this.http.request<PortfolioIpDocProtocolsGetOwnerListResponse, any>({
      path: `/ip_doc/owner`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name LicenseeList
   * @request GET:/ip_doc/licensee
   */
  licenseeList = (params: RequestParams = {}) =>
    this.http.request<PortfolioIpDocProtocolsGetLicenseeListResponse, any>({
      path: `/ip_doc/licensee`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name PresignedUploadCreate
   * @request POST:/ip_doc/presigned/upload
   */
  presignedUploadCreate = (data: PortfolioIpDocProtocolsGetUploadUrlRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioIpDocProtocolsGetUploadUrlResponse, any>({
      path: `/ip_doc/presigned/upload`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name PresignedDownloadCreate
   * @request POST:/ip_doc/presigned/download
   */
  presignedDownloadCreate = (data: PortfolioIpDocProtocolsGetDownloadUrlRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioIpDocProtocolsGetDownloadUrlResponse, any>({
      path: `/ip_doc/presigned/download`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name BrandList
   * @request GET:/ip_doc/brand
   */
  brandList = (params: RequestParams = {}) =>
    this.http.request<PortfolioIpDocProtocolsGetBrandListResponse, any>({
      path: `/ip_doc/brand`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name BrandDetailCreate
   * @request POST:/ip_doc/brand/detail
   */
  brandDetailCreate = (data: PortfolioIpDocProtocolsGetBrandDetailRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioIpDocProtocolsGetBrandDetailResponse, any>({
      path: `/ip_doc/brand/detail`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name BrandAddCreate
   * @request POST:/ip_doc/brand/add
   */
  brandAddCreate = (data: PortfolioIpDocProtocolsAddBrandRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioIpDocProtocolsAddBrandResponse, any>({
      path: `/ip_doc/brand/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name BrandUpdateCreate
   * @request POST:/ip_doc/brand/update
   */
  brandUpdateCreate = (data: PortfolioIpDocProtocolsUpdateBrandRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ip_doc/brand/update`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name BrandDeleteCreate
   * @request POST:/ip_doc/brand/delete
   */
  brandDeleteCreate = (data: PortfolioIpDocProtocolsDeleteBrandRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ip_doc/brand/delete`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CopyrightsDocListCreate
   * @request POST:/ip_doc/copyrights/doc/list
   */
  copyrightsDocListCreate = (data: PortfolioCopyrightsProtocolsGetDocumentsRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioCopyrightsProtocolsGetDocumentsResponse, any>({
      path: `/ip_doc/copyrights/doc/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CopyrightsDocAddCreate
   * @request POST:/ip_doc/copyrights/doc/add
   */
  copyrightsDocAddCreate = (data: PortfolioCopyrightsProtocolsAddDocumentRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioCopyrightsProtocolsAddDocumentResponse, any>({
      path: `/ip_doc/copyrights/doc/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CopyrightsDocDetailsCreate
   * @request POST:/ip_doc/copyrights/doc/details
   */
  copyrightsDocDetailsCreate = (
    data: PortfolioCopyrightsProtocolsGetDocumentDetailsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PortfolioCopyrightsProtocolsGetDocumentDetailsResponse, any>({
      path: `/ip_doc/copyrights/doc/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CopyrightsDocUpdateCreate
   * @request POST:/ip_doc/copyrights/doc/update
   */
  copyrightsDocUpdateCreate = (data: PortfolioCopyrightsProtocolsUpdateDocumentRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ip_doc/copyrights/doc/update`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CopyrightsDocDeleteCreate
   * @request POST:/ip_doc/copyrights/doc/delete
   */
  copyrightsDocDeleteCreate = (data: PortfolioCopyrightsProtocolsDeleteDocumentRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ip_doc/copyrights/doc/delete`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CopyrightsCopyrightListCreate
   * @request POST:/ip_doc/copyrights/copyright/list
   */
  copyrightsCopyrightListCreate = (
    data: PortfolioCopyrightsProtocolsGetCopyrightsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PortfolioCopyrightsProtocolsGetCopyrightsResponse, any>({
      path: `/ip_doc/copyrights/copyright/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CopyrightsCopyrightAddCreate
   * @request POST:/ip_doc/copyrights/copyright/add
   */
  copyrightsCopyrightAddCreate = (data: PortfolioCopyrightsProtocolsAddCopyrightRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ip_doc/copyrights/copyright/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CopyrightsCopyrightUpdateCreate
   * @request POST:/ip_doc/copyrights/copyright/update
   */
  copyrightsCopyrightUpdateCreate = (
    data: PortfolioCopyrightsProtocolsUpdateCopyrightRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/ip_doc/copyrights/copyright/update`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CopyrightsCopyrightDeleteCreate
   * @request POST:/ip_doc/copyrights/copyright/delete
   */
  copyrightsCopyrightDeleteCreate = (
    data: PortfolioCopyrightsProtocolsDeleteCopyrightRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/ip_doc/copyrights/copyright/delete`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name DesignCreate
   * @request POST:/ip_doc/design
   */
  designCreate = (data: PortfolioDesignProtocolsGetDesignListRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioDesignProtocolsGetDesignListResponse, any>({
      path: `/ip_doc/design`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name DesignDetailCreate
   * @request POST:/ip_doc/design/detail
   */
  designDetailCreate = (data: PortfolioDesignProtocolsGetDesignDetailRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioDesignProtocolsGetDesignDetailResponse, any>({
      path: `/ip_doc/design/detail`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name DesignAddCreate
   * @request POST:/ip_doc/design/add
   */
  designAddCreate = (data: PortfolioDesignProtocolsAddDesignRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioDesignProtocolsAddDesignResponse, any>({
      path: `/ip_doc/design/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name DesignUpdateCreate
   * @request POST:/ip_doc/design/update
   */
  designUpdateCreate = (data: PortfolioDesignProtocolsUpdateDesignRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ip_doc/design/update`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name DesignDeleteCreate
   * @request POST:/ip_doc/design/delete
   */
  designDeleteCreate = (data: PortfolioDesignProtocolsDeleteDesignRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ip_doc/design/delete`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name TrademarkCreate
   * @request POST:/ip_doc/trademark
   */
  trademarkCreate = (data: PortfolioTrademarkProtocolsGetTrademarkListRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioTrademarkProtocolsGetTrademarkListResponse, any>({
      path: `/ip_doc/trademark`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name TrademarkDetailCreate
   * @request POST:/ip_doc/trademark/detail
   */
  trademarkDetailCreate = (data: PortfolioTrademarkProtocolsGetTrademarkDetailRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioTrademarkProtocolsGetTrademarkDetailResponse, any>({
      path: `/ip_doc/trademark/detail`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name TrademarkAddCreate
   * @request POST:/ip_doc/trademark/add
   */
  trademarkAddCreate = (data: PortfolioTrademarkProtocolsAddTrademarkRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioTrademarkProtocolsAddTrademarkResponse, any>({
      path: `/ip_doc/trademark/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name TrademarkUpdateCreate
   * @request POST:/ip_doc/trademark/update
   */
  trademarkUpdateCreate = (data: PortfolioTrademarkProtocolsUpdateTrademarkRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ip_doc/trademark/update`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name TrademarkDeleteCreate
   * @request POST:/ip_doc/trademark/delete
   */
  trademarkDeleteCreate = (data: PortfolioTrademarkProtocolsDeleteTrademarkRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/ip_doc/trademark/delete`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name GetAssetCreate
   * @request POST:/asset/get_asset
   */
  getAssetCreate = (data: PortfolioAssetProtocolsGetAssetRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioAssetProtocolsGetAssetResponse, any>({
      path: `/asset/get_asset`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name GetAssetListCreate
   * @request POST:/asset/get_asset_list
   */
  getAssetListCreate = (data: PortfolioAssetProtocolsGetAssetListRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioAssetProtocolsGetAssetListResponse, any>({
      path: `/asset/get_asset_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name DeleteAssetCreate
   * @request POST:/asset/delete_asset
   */
  deleteAssetCreate = (data: PortfolioAssetProtocolsDeleteAssetRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/asset/delete_asset`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name GetAllCollectionsList
   * @request GET:/asset/get_all_collections
   */
  getAllCollectionsList = (params: RequestParams = {}) =>
    this.http.request<PortfolioAssetProtocolsGetAllCollectionsResponse, any>({
      path: `/asset/get_all_collections`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CreateCollectionCreate
   * @request POST:/asset/create_collection
   */
  createCollectionCreate = (data: PortfolioAssetProtocolsCreateCollectionRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioAssetProtocolsCreateCollectionResponse, any>({
      path: `/asset/create_collection`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name UpdateCollectionCreate
   * @request POST:/asset/update_collection
   */
  updateCollectionCreate = (data: PortfolioAssetProtocolsUpdateCollectionRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/asset/update_collection`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name BulkUpdateAssetCreate
   * @request POST:/asset/bulk-update-asset
   */
  bulkUpdateAssetCreate = (data: PortfolioAssetProtocolsBulkUpdateAssetRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/asset/bulk-update-asset`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name DeleteCollectionCreate
   * @request POST:/asset/delete_collection
   */
  deleteCollectionCreate = (data: PortfolioAssetProtocolsDeleteCollectionRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/asset/delete_collection`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name GetIpDocumentsUniqueCreate
   * @request POST:/asset/get_ip_documents_unique
   */
  getIpDocumentsUniqueCreate = (
    data: PortfolioAssetProtocolsGetIpDocumentsUniqueNameRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<PortfolioAssetProtocolsGetIpDocumentsUniqueNameResponse, any>({
      path: `/asset/get_ip_documents_unique`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name GetIpDocumentsCreate
   * @request POST:/asset/get-ip-documents
   */
  getIpDocumentsCreate = (data: PortfolioAssetProtocolsGetIpDocumentsRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioAssetProtocolsGetIpDocumentsResponse, any>({
      path: `/asset/get-ip-documents`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name CreateAssetPresignedCreate
   * @request POST:/asset/create_asset_presigned
   */
  createAssetPresignedCreate = (
    data: PortfolioAssetProtocolsCreateAssetByPresignedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/asset/create_asset_presigned`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name UpdateAssetPresignedCreate
   * @request POST:/asset/update_asset_presigned
   */
  updateAssetPresignedCreate = (
    data: PortfolioAssetProtocolsUpdateAssetByPresignedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/asset/update_asset_presigned`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IpPortfolio
   * @name PresignedCreate
   * @request POST:/asset/presigned
   */
  presignedCreate = (data: PortfolioAssetProtocolsGetAssetPresignedUrlRequest, params: RequestParams = {}) =>
    this.http.request<PortfolioAssetProtocolsGetAssetPresignedUrlResponse, any>({
      path: `/asset/presigned`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
