import { UseWebsiteFilter } from '~/hooks';
import { MarqBrandEnumsSwBrandEnforceTabEnum, MarqCommonEnumsSwSWSoftNoticeTabEnum } from '~/swagger/data-contracts';

export type WebsiteFilter = {
  rootDomainIdList?: number[] | null;
  rootDomainSearchKeyword: string;
};
export type WebsiteCommonPageProps = {
  websiteFilterProps: UseWebsiteFilter;
};
export type WebsitePathParams =
  | {
      page: 'detection';
      tab: string;
      stage: string;
      status: string;
    }
  | {
      page: 'enforcement';
      tab: string;
      stage: MarqBrandEnumsSwBrandEnforceTabEnum;
      status: MarqCommonEnumsSwSWSoftNoticeTabEnum;
    };

export enum TabEnum {
  EcommerceAndSocialMedia = 'ecommerce_and_social_media',
  Website = 'website',
}
