import React, { HTMLAttributes, Ref, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { MDSTheme } from '../index';

const useStyles = makeStyles(() => ({
  thead: {
    width: '100%',
    backgroundColor: MDSTheme.palette.white,
    '&.isStickyHeader': {
      position: 'sticky',
      top: '0',
      zIndex: 2,
    },
    '& td': {
      ...MDSTheme.typography.regular,
      ...MDSTheme.typography.T14,
      color: MDSTheme.palette.bluegray[700],
      borderBottom: `1px solid ${MDSTheme.palette.bluegray[200]} !important`,
    },
  },
}));

interface ITableHeadProps {
  isStickyHeader?: boolean;
}

const MDSTableHead = forwardRef(
  (props: ITableHeadProps & HTMLAttributes<HTMLTableSectionElement>, ref: Ref<HTMLTableSectionElement>): React.ReactNode => {
    const { isStickyHeader, className, children, ...restProps } = props;
    const classes = useStyles();

    return (
      <thead ref={ref} className={clsx(classes.thead, { isStickyHeader }, className)} {...restProps}>
        {children}
      </thead>
    );
  }
);
MDSTableHead.displayName = 'MDSTableHead';

export default MDSTableHead;
