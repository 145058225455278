/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyRequest,
  MarqCommonProtocolEmptyResponse,
  SettingsCreditsProtocolsGetCreditHistoryRequest,
  SettingsCreditsProtocolsGetCreditHistoryResponse,
  SettingsCreditsProtocolsGetCreditsResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Credits<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Credits
   * @name CreditsList
   * @request GET:/settings/credits
   */
  creditsList = (params: RequestParams = {}) =>
    this.http.request<SettingsCreditsProtocolsGetCreditsResponse, any>({
      path: `/settings/credits`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Credits
   * @name CreditsHistoryCreate
   * @request POST:/settings/credits/history
   */
  creditsHistoryCreate = (data: SettingsCreditsProtocolsGetCreditHistoryRequest, params: RequestParams = {}) =>
    this.http.request<SettingsCreditsProtocolsGetCreditHistoryResponse, any>({
      path: `/settings/credits/history`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Credits
   * @name CreditsContactUsCreate
   * @request POST:/settings/credits/contact-us
   */
  creditsContactUsCreate = (data: MarqCommonProtocolEmptyRequest, params: RequestParams = {}) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/settings/credits/contact-us`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
