import React, { useEffect, useState } from 'react';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import clsx from 'clsx';
import { IconButton, makeStyles } from '@material-ui/core';
import MDSTypography from '../Typography';
import MDSTheme from '../Theme';
import { IcoArrowDownOutline, IcoArrowUpOutline, IcoCheckOutline, IcoDividerCircle } from '../../assets';
import { leftNavBarStateAtom } from '.';

interface IProps extends INavItem {
  subNavItem?: INavItem[];
  dividerTop?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: '0 12px',
    '&.isFolded': {
      padding: '0 8px',
    },
    '& .MuiTypography-root': {
      color: MDSTheme.palette.bluegray[500],
    },
  },
  dividerTop: {
    margin: '8px 12px',
    borderTop: `1px solid ${MDSTheme.palette.bluegray[800]}`,
    '&.isFolded': {
      margin: '8px',
    },
  },
  subItemBox: {
    padding: '8px 12px 8px 24px',
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&.isFolded': {
      padding: '8px 0',
    },
  },
  linkWrap: {
    marginBottom: '4px',
    '&.isShowSub': {
      marginBottom: 0,
    },
  },
  link: {
    position: 'relative',
    padding: '9px 12px',
    height: '38px',
    display: 'flex',
    gap: '8px',
    color: MDSTheme.palette.bluegray[500],
    textDecoration: 'none',
    alignItems: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRadius: '19px',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&.sub': {
      height: '32px',
      borderRadius: '16px',
      justifyContent: 'space-between',
      transition: 'height 0s',
      '&:not(.isFolded)': {
        paddingLeft: '16px',
      },
    },
    '&.isShowSub': {
      marginBottom: 0,
      '& .MuiTypography-root': {
        color: MDSTheme.palette.white,
      },
      '& path': {
        fill: MDSTheme.palette.white,
      },
    },
    '& svg, img': {
      flex: '0 0 20px',
      width: '20px',
      height: '20px',
    },
    '&.sub svg, img': {
      position: 'absolute',
      left: '6px',
      top: '6px',
    },
    '& p': {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    '&.isFolded': {
      paddingLeft: '9px',
      paddingRight: '9px',
      '&.sub': {
        width: '24px',
        marginLeft: '7px',
        '&.isSelected': {
          height: '24px',
          margin: '6px',
          '& svg': {
            left: '2px',
            top: '2px',
          },
        },
      },
      '&:not(.sub) p': {
        opacity: 0,
      },
    },
    '& path': {
      fill: MDSTheme.palette.bluegray[500],
    },
    '&:hover': {
      textDecoration: 'none',
    },
    '&:not(.isSelected):hover': {
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
      '& path': {
        fill: MDSTheme.palette.white,
      },
      '& p': {
        color: MDSTheme.palette.white,
      },
    },
    '&.isSelected': {
      backgroundColor: MDSTheme.palette.white,
      '& .MuiTypography-root': {
        color: MDSTheme.palette.bluegray[900],
      },
      '& path': {
        fill: MDSTheme.palette.bluegray[900],
      },
    },
    '& .clampsIcon': {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      position: 'absolute',
      right: '12px',
      top: '8px',
    },
    '&.isFolded .clampsIcon': {
      opacity: 0,
    },
  },
  subWrap: {
    userSelect: 'none',
    borderRadius: '8px',
    transition: theme.transitions.create('outline', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    outline: '1px solid transparent',
    '&.isSubBoxSelected': {
      outlineColor: MDSTheme.palette.bluegray[800],
      // outline: `1px solid ${MDSTheme.palette.bluegray[800]}`,
    },
    marginBottom: '4px',
  },
  subCircleIcon: {
    width: '6px !important',
    height: '6px !important',
    margin: '7px 3px',
  },
  tag: {
    ...MDSTheme.typography.T12,
    ...MDSTheme.typography.medium,
    color: MDSTheme.palette.white,
    padding: '1px 4px',
    borderRadius: '4px',
  },
}));

interface INavItem {
  label: string | React.ReactNode;
  link?: string;
  isSelected?: boolean;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<unknown>;
  tag?: {
    label: string;
    color: string;
  };
}

const NavItem = ({ icon, label, link, subNavItem, dividerTop: dividerBottom, onClick, tag, ...respProps }: IProps) => {
  const classes = useStyles();
  const { isFolded } = useAtomValue(leftNavBarStateAtom);
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [isShowSub, setIsShowSub] = useState(false);

  const isSelected =
    respProps.isSelected !== undefined ? respProps.isSelected : link && matchPath(`${link}/*`, pathname);

  const toggleSubList = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.stopPropagation();
    subNavItem && setIsShowSub((ps) => !ps);
    onClick?.(e);
  };

  const handleGroupTitle = (e: React.MouseEvent<HTMLDivElement>) => {
    if (link) {
      navigate(link);
      return;
    }

    toggleSubList(e);
  };

  useEffect(() => {
    if (subNavItem && subNavItem.some((v) => v.isSelected || (v.link && matchPath(`${v.link}/*`, pathname)))) {
      setIsShowSub(true);
    }
  }, [subNavItem, pathname]);

  return (
    <div
      className={clsx(classes.root, {
        isFolded,
      })}
    >
      {dividerBottom && <div className={clsx(classes.dividerTop, { isFolded })} />}
      {link && !subNavItem && (
        <div className={classes.linkWrap}>
          <Link
            className={clsx(classes.link, {
              isSelected,
              isFolded,
            })}
            to={link}
            onClick={onClick}
          >
            {icon}
            <MDSTypography variant="T14" weight="medium" style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {label}
              {tag && (
                <div className={classes.tag} style={{ backgroundColor: tag.color }}>
                  {tag.label}
                </div>
              )}
            </MDSTypography>
          </Link>
        </div>
      )}
      <div
        className={clsx(classes.subWrap, {
          isSubBoxSelected: isShowSub,
        })}
      >
        {subNavItem && (
          <div
            className={clsx(classes.linkWrap, {
              isShowSub,
            })}
          >
            <div
              className={clsx(classes.link, {
                isFolded,
                isShowSub,
              })}
              onClick={handleGroupTitle}
            >
              {icon}
              <MDSTypography
                variant="T14"
                weight="medium"
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                {label}
                {tag && (
                  <div className={classes.tag} style={{ backgroundColor: tag.color }}>
                    {tag.label}
                  </div>
                )}
              </MDSTypography>
              <IconButton className="clampsIcon" onClick={toggleSubList}>
                {isShowSub ? <IcoArrowUpOutline /> : <IcoArrowDownOutline />}
              </IconButton>
            </div>
          </div>
        )}
        {isShowSub && (
          <div className={clsx(classes.subItemBox, { isFolded })}>
            {subNavItem?.map((subItem) => {
              const isSubItemSelected =
                subItem.isSelected !== undefined
                  ? subItem.isSelected
                  : subItem.link && matchPath(`${subItem.link}/*`, pathname);
              return (
                <React.Fragment key={subItem.link}>
                  {subItem.link && (
                    <div className={classes.linkWrap}>
                      <Link
                        className={clsx(classes.link, 'sub', {
                          isSelected: isSubItemSelected,
                          isFolded,
                        })}
                        to={subItem.link}
                        onClick={subItem.onClick}
                      >
                        {!isFolded && (
                          <MDSTypography
                            variant="T14"
                            weight="medium"
                            style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                          >
                            {subItem.label}
                            {subItem.tag && (
                              <div className={classes.tag} style={{ backgroundColor: subItem.tag.color }}>
                                {subItem.tag.label}
                              </div>
                            )}
                          </MDSTypography>
                        )}
                        {isFolded &&
                          (isSubItemSelected ? (
                            <IcoCheckOutline />
                          ) : (
                            subItem.icon || <IcoDividerCircle className={classes.subCircleIcon} />
                          ))}
                      </Link>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavItem;
