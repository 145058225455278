import numeral from 'numeral';

export const getBoundaryLabel = (min?: number | null, max?: number | null, suffix: string | null = '') => {
  const _suffix = suffix ? ` ${suffix}` : '';

  if (min || max) {
    if (min && max) {
      return {
        name: `${numeral(min).format('0,0')} ~ ${numeral(max).format('0,0')}${_suffix}`,
      };
    }
    if (min) {
      return {
        name: `${numeral(min).format('0,0')}${_suffix} or above`,
      };
    }
    if (max) {
      return {
        name: `${numeral(max).format('0,0')}${_suffix} or below`,
      };
    }
  }
  return;
};
