import { useCallback, useEffect } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import {
  dragStateAtom,
  listingSelectStateAtom,
  listingTotalCountAtom,
  resetListingItemsSelectStateAtom,
  selectMultipleItemsAtom,
  selectedListingItemCountAtom,
  selectedListingItemsAtom,
  updateAllListingItemsSelectStateAtom,
  updateListingItemSelectStateAtom,
} from './@atoms';

// prepare
export const usePrepareListSelecting = <T extends string | number = number>() => {
  const [listingSelectState, updateListingSelectState] = useAtom(listingSelectStateAtom);
  const setTotalCount = useSetAtom(listingTotalCountAtom);
  const resetListingSelectState = useSetAtom(resetListingItemsSelectStateAtom);

  const appendNewListingState = useCallback(
    (listingIds?: T[]) => {
      const items = new Map<T, boolean>();

      // listing select 로직에 대응하기 위해 atom에 업데이트.
      listingIds?.forEach((id) => {
        if (listingSelectState.get(id) === undefined) {
          items.set(id, false);
        }
      });
      updateListingSelectState((prev) => new Map([...prev, ...items]));
    },
    [listingSelectState, updateListingSelectState]
  );

  const resetListingState = useCallback(() => {
    resetListingSelectState();
  }, [resetListingSelectState]);

  const updateTotalCount = useCallback(
    (totalCount: number) => {
      setTotalCount(totalCount);
    },
    [setTotalCount]
  );

  const syncListingState = useCallback(
    ({ listingIds, reset }: { listingIds: T[]; reset?: boolean }) => {
      const items = new Map<T, boolean>();
      if (reset) {
        resetListingSelectState();
        listingIds?.forEach((id) => {
          items.set(id, false);
        });
        updateListingSelectState(items);
      } else {
        listingIds?.forEach((id) => {
          if (listingSelectState.get(id) === undefined) {
            items.set(id, false);
          }
        });
        updateListingSelectState((prev) => new Map([...prev, ...items]));
      }
    },
    [listingSelectState, resetListingSelectState, updateListingSelectState]
  );

  return {
    /**
     * @deprecated
     */
    appendNewListingState,
    /**
     * @deprecated
     */
    resetListingState,
    updateTotalCount,
    syncListingState,
  };
};

export const useListingSelectState = <T extends string | number = number>() => {
  const selectedCount = useAtomValue(selectedListingItemCountAtom);
  const totalCount = useAtomValue(listingTotalCountAtom);
  const allSelected = totalCount > 0 && selectedCount === totalCount;
  const indeterminate = !allSelected && selectedCount > 0;
  const selectedItems = useAtomValue(selectedListingItemsAtom) as T[];
  const isDragging = useAtomValue(dragStateAtom).isDragging;

  return {
    selectedCount,
    selectedItems,
    totalCount,
    allSelected,
    indeterminate,
    isDragging,
  };
};

// select all
type Params = { onSelectAll?: (nextAllSelectedState: boolean) => void };
export const useSelectAll = <T extends string | number = number>(params?: Params) => {
  const totalCount = useAtomValue(listingTotalCountAtom);
  const selectedCount = useAtomValue(selectedListingItemCountAtom);
  const allSelected = totalCount > 0 && selectedCount === totalCount;
  const indeterminate = !allSelected && selectedCount > 0;

  const setAllListingItems = useSetAtom(updateAllListingItemsSelectStateAtom);

  const { appendNewListingState } = usePrepareListSelecting<T>();

  const selectAll = useCallback(
    (listingIds?: T[]) => {
      if (totalCount === 0) {
        // totalCount === 0 이면 화면에서도 checkbox를 disabled 시키는 편이 좋을듯.
        return;
      } else {
        let nextAllSelectedState = false;
        // 몇개 만 선택되어있는 상태라면 모두 선택 해제. 아니라면 allSelected의 상태를 따라간다
        if (indeterminate) {
          setAllListingItems(nextAllSelectedState);
        } else if (allSelected) {
          setAllListingItems(nextAllSelectedState);
        } else {
          nextAllSelectedState = !allSelected;
          appendNewListingState(listingIds);
          setAllListingItems(nextAllSelectedState);
        }
        params?.onSelectAll?.(allSelected);
      }
    },
    [totalCount, indeterminate, allSelected, params, setAllListingItems, appendNewListingState]
  );

  return {
    totalCount,
    selectedCount,
    allSelected,
    indeterminate,
    selectAll,
  };
};

// select by drag
export const useSelect = <T extends string | number = number>() => {
  const [{ startIndex }] = useAtom(dragStateAtom);
  const selectedState = useAtomValue(listingSelectStateAtom);
  const updateListingItem = useSetAtom(updateListingItemSelectStateAtom);
  const updateSelectedItems = useSetAtom(selectMultipleItemsAtom);

  const getIsSelected = useCallback(
    (listingId: T) => {
      return !!selectedState.get(listingId);
    },
    [selectedState]
  );

  const selectOne = ({
    event,
    currentIndex,
    listingId,
  }: {
    event?: React.MouseEvent<HTMLElement, MouseEvent>;
    currentIndex?: number;
    listingId?: T;
  }) => {
    if (event?.shiftKey && currentIndex) {
      if (startIndex !== -1 && currentIndex !== startIndex) {
        updateSelectedItems({ index1: startIndex || 0, index2: currentIndex });
      }
    } else {
      if (listingId) {
        updateListingItem({ listingId });
      }
    }
  };

  return {
    getIsSelected,
    selectOne,
  };
};

export const useDragSelect = () => {
  const [{ isDragging, startIndex, lastIndex }, setDragState] = useAtom(dragStateAtom);
  const updateSelectedItems = useSetAtom(selectMultipleItemsAtom);

  const onDragStart = (index: number) => {
    setDragState({ isDragging: true, startIndex: index, lastIndex: index });
  };
  const onDragMove = debounce((e: React.MouseEvent) => {
    if (!isDragging || startIndex === null || lastIndex === null) return;

    // 현재 마우스 위치에 따른 아이템 ID 계산 로직 (예시 로직 필요)
    const currentIndex = calculateCurrentItemIndex(e);

    if (currentIndex !== null && lastIndex !== currentIndex) {
      setDragState((prev) => ({ ...prev, lastIndex: currentIndex }));
      updateSelectedItems({ index1: startIndex, index2: currentIndex });
    }
  }, 0);
  const onDragEnd = (index: number) => {
    setDragState((prev) => ({
      isDragging: false,
      startIndex: prev.startIndex,
      lastIndex: index,
    }));
  };
  //#endregion

  //#region - Mouse action by area
  useEffect(() => {
    const onMouseLeave = () => {
      setDragState((prev) => ({
        ...prev,
        isDragging: false,
      }));
    };
    if (isDragging) {
      document.body.addEventListener('mouseup', onMouseLeave);
      document.body.addEventListener('mouseleave', onMouseLeave);
    } else {
      document.body.removeEventListener('mouseup', onMouseLeave);
      document.body.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      document.body.removeEventListener('mouseup', onMouseLeave);
      document.body.addEventListener('mouseleave', onMouseLeave);
    };
  }, [isDragging, setDragState]);
  //#endregion

  return {
    onDragStart,
    onDragMove,
    onDragEnd,
  };
};

function calculateCurrentItemIndex(event: React.MouseEvent) {
  const targetElement = event.target as HTMLElement;

  // 바로 event.target에서 data-index를 확인
  let itemId = targetElement.getAttribute('data-index');
  if (itemId !== null) {
    return parseInt(itemId, 10); // 바로 숫자 ID로 변환
  }

  const { clientX, clientY } = event;
  const elements = document.elementsFromPoint(clientX, clientY);
  for (const el of elements) {
    itemId = el.getAttribute('data-index');
    if (itemId !== null) {
      return parseInt(itemId, 10); // 숫자 ID로 변환
    }
  }

  return null; // 조건에 맞는 요소가 없을 경우
}
