import React, { CSSProperties, useRef, useState } from 'react';
import clsx from 'clsx';
import { Box, TextField as MUITextField, TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { IcoSearch, IcoCloseDeleteRemoveBorder, IcoErrorWarningFill } from '../assets';
import MDSTheme from './Theme';
import Typography from './Typography';
import Button from './Button';

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
    margin: 0,
    backgroundColor: MDSTheme.palette.white,
    borderRadius: '4px',
    border: `1px solid ${MDSTheme.palette.bluegray['200']}`,
    '& > .MuiInputBase-root > .MuiInputBase-input': {
      ...MDSTheme.typography.regular,
      backgroundColor: MDSTheme.palette.white,
      padding: 0,
      height: 'auto',
      borderRadius: 0,
      color: MDSTheme.palette.bluegray[900],
      transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&::placeholder': {
        color: `${MDSTheme.palette.bluegray[300]} !important`,
        opacity: 1,
        ...MDSTheme.typography.regular,
        fontFamily: 'Visuelt-Regular, Pretendard Variable',
      },
    },
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& > .MuiInput-underline:before, & > .MuiInput-underline:hover:not(.Mui-disabled):before, & > .MuiInput-underline:after':
      {
        border: 'none',
      },
    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  small: {
    '& .MuiFormControl-root': {
      padding: '2.2px 11px',
    },
    '& .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input': {
      ...MDSTheme.typography.T13,
    },
    '& .clearButtonPosition': {
      left: '-23px',
      width: '16px',
      height: '16px',
    },
    '&.isSearch .MuiFormControl-root': {
      paddingLeft: '25px',
    },
    '& .searchIconPosition': {
      left: '7px',
      width: '16px',
      height: '16px',
    },
  },
  medium: {
    '& .MuiFormControl-root': {
      padding: '4.5px 11px',
    },
    '& .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input': {
      ...MDSTheme.typography.T14,
    },
    '& .clearButtonPosition': {
      left: '-23px',
      width: '16px',
      height: '16px',
    },
    '&.isSearch .MuiFormControl-root': {
      paddingLeft: '27px',
      paddingRight: '27px',
    },
    '& .searchIconPosition': {
      left: '7px',
      width: '16px',
      height: '16px',
    },
  },
  large: {
    '& .MuiFormControl-root': {
      padding: '6px 13px',
    },
    '& .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input': {
      ...MDSTheme.typography.T16,
    },
    '& .clearButtonPosition': {
      left: '-28px',
      width: '20px',
      height: '20px',
    },
    '&.isSearch .MuiFormControl-root': {
      paddingLeft: '32px',
    },
    '& .searchIconPosition': {
      left: '8px',
      width: '20px',
      height: '20px',
    },
  },
  extraLarge: {
    '& .MuiFormControl-root': {
      padding: '8px 16px',
    },
    '& .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input': {
      ...MDSTheme.typography.T20,
    },
    '&.isSearch .MuiFormControl-root': {
      paddingLeft: '35px',
    },
    '& .clearButtonPosition': {
      left: '-31px',
      width: '20px',
      height: '20px',
    },
    '& .searchIconPosition': {
      left: '11px',
      width: '20px',
      height: '20px',
    },
  },
  focused: {
    borderColor: MDSTheme.palette.blue['700'],
  },
  error: {
    borderColor: MDSTheme.palette.red['600'],
  },
  readOnly: {
    backgroundColor: MDSTheme.palette.bluegray['100'],
    '& > .MuiInputBase-root > .MuiInputBase-input[readonly]': {
      backgroundColor: `${MDSTheme.palette.bluegray['100']}!important`,
    },
  },
  disabled: {
    backgroundColor: MDSTheme.palette.bluegray['100'],
    '& > .MuiInputBase-root > .MuiInputBase-input.Mui-disabled': {
      backgroundColor: `${MDSTheme.palette.bluegray['100']}!important`,
      color: MDSTheme.palette.bluegray['300'],
    },
  },
  clearButtonShown: {
    '&>div': {
      width: 'calc(100% - 16px)',
    },
  },
  plusButtonMode: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  clearButton: {
    position: 'absolute',
    zIndex: 1,
    cursor: 'pointer',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  helperTextIcon: {
    marginLeft: '4.5px',
    marginRight: '5px',
    width: '12px',
    height: '12px',
    flexShrink: 0,
    '& > path': {
      fill: MDSTheme.palette.red['600'],
    },
    '&.medium, &.large': {
      width: '16px',
      height: '16px',
    },
    '&.extra_large': {
      width: '20px',
      height: '20px',
    },
  },
  inputTag: {
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  addButton: {
    borderRadius: '4px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    '&.small': {
      paddingLeft: '12px',
      paddingRight: '12px',
      ...MDSTheme.typography.T13,
    },
    '&.medium': {
      paddingLeft: '12px',
      paddingRight: '12px',
      ...MDSTheme.typography.T14,
    },
    '&.large': {
      paddingLeft: '14px',
      paddingRight: '14px',
      ...MDSTheme.typography.T16,
    },
    '&.extra_large': {
      paddingLeft: '14px',
      paddingRight: '14px',
      ...MDSTheme.typography.T16,
    },
  },
  searchIconPosition: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));
type TextFieldSize = 'small' | 'medium' | 'large' | 'extra_large';

interface IOwnProps {
  plusButton?: boolean | React.ReactNode;
  plusButtonDisabled?: boolean;
  size?: TextFieldSize;
  autoFocus?: boolean;
  readOnly?: boolean;
  helperText?: string;
  pattern?: string;
  isSearch?: boolean; // TODO: 삭제 필요
  maxLength?: number;
  textFieldStyle?: CSSProperties | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<SVGSVGElement>, value: unknown) => void;
  onFocus?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onPlus?: () => void;
  formatter?: (value: unknown) => string | unknown;
  onEnter?: (value: unknown) => void;
  disableClearButton?: boolean;
}

export type ITextFieldProps = IOwnProps &
  Omit<TextFieldProps, 'helperText' | 'size' | 'onChange' | 'onFocus' | 'onBlur'>;

const MDSTextField = React.forwardRef<HTMLInputElement, ITextFieldProps>(
  (props: ITextFieldProps, propsRef: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
    const classes = useStyles();
    const {
      size = 'small',
      isSearch,
      readOnly,
      disabled,
      plusButton,
      error,
      value,
      defaultValue,
      helperText,
      placeholder,
      style,
      InputProps,
      textFieldStyle,
      formatter = (v) => v,
      onPlus,
      onFocus,
      onBlur,
      onChange,
      onClick,
      className,
      plusButtonDisabled,
      onEnter,
      onKeyDown,
      disableClearButton,
      autoFocus,
      ...restProps
    } = props;
    const [focused, setFocused] = useState<boolean>(false);
    const valueRef = useRef<HTMLInputElement>();

    const handleInputFocus = (): void => {
      setFocused(true);
      onFocus?.();
    };

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      setFocused(false);
      onBlur?.(event);
    };

    const handleClearClick = (event: React.MouseEvent<SVGSVGElement>): void => {
      onChange?.(event, '');

      if (valueRef.current) {
        valueRef.current.value = '';

        if (isSearch) {
          valueRef.current.blur();
        }
      }
    };

    const handlePlusButtonClick = (): void => {
      onPlus?.();
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      onChange?.(event, event.target.value);
    };

    const handleInputClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      onClick?.(event);
    };

    return (
      <Box className={className} style={style}>
        <Box
          display="flex"
          alignItems="center"
          position="relative"
          className={clsx({
            [classes.small]: size === 'small',
            [classes.medium]: size === 'medium',
            [classes.large]: size === 'large',
            [classes.extraLarge]: size === 'extra_large',
            isSearch: isSearch,
          })}
        >
          <MUITextField
            {...restProps}
            autoFocus={autoFocus}
            ref={propsRef}
            inputRef={(ref) => {
              valueRef.current = ref;
            }}
            className={clsx(classes.input, {
              [classes.readOnly]: readOnly,
              [classes.disabled]: disabled,
              [classes.plusButtonMode]: !!plusButton,
              [classes.focused]: focused && !readOnly,
              [classes.error]: error,
              [classes.clearButtonShown]:
                (focused || error || value || defaultValue) && !disabled && !readOnly && !disableClearButton,
            })}
            defaultValue={defaultValue}
            value={formatter(value)}
            placeholder={placeholder}
            disabled={disabled || readOnly}
            error={error}
            // mui InputProps
            InputProps={
              {
                readOnly: readOnly,
                className: classes.inputTag,
                ...InputProps,
              } as Partial<StandardInputProps>
            }
            // html native attributes
            // - details: https://v4.mui.com/api/text-field/#textfield-api
            inputProps={{
              maxLength: props.maxLength,
            }}
            style={textFieldStyle}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onClick={handleInputClick}
            onKeyDown={(e) => {
              if (((plusButton && !plusButtonDisabled) || onEnter) && e.key === 'Enter' && !e.nativeEvent.isComposing) {
                onKeyDown?.(e);
                onPlus?.();
                onEnter?.(formatter(value));
              }
            }}
          />
          {isSearch && <IcoSearch className={clsx('searchIconPosition', classes.searchIconPosition)} />}
          <div style={{ position: 'relative' }}>
            {(focused || error || !!value || !!defaultValue) && !disabled && !readOnly && !disableClearButton && (
              <IcoCloseDeleteRemoveBorder
                className={clsx(classes.clearButton, 'clearButtonPosition')}
                onClick={handleClearClick}
                onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => e.preventDefault()}
              />
            )}
            {!!plusButton && (
              <Button
                variant="fill"
                type="button"
                color={error ? 'red' : 'blue'}
                size={size}
                className={classes.addButton}
                onClick={handlePlusButtonClick}
                disabled={disabled || plusButtonDisabled}
              >
                {typeof plusButton !== 'boolean' ? plusButton : 'Add'}
              </Button>
            )}
          </div>
        </Box>
        {error && helperText && (
          <Box width="100%" display="flex" alignItems="center" marginTop="4px">
            <IcoErrorWarningFill className={clsx(classes.helperTextIcon, size)} />
            <Typography variant="T12" weight="medium" style={{ color: MDSTheme.palette.red['700'] }}>
              {helperText}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
);

MDSTextField.displayName = 'MDSTextField';

export default MDSTextField;
