import React from 'react';
import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';
import { IcoArrowDownOutline, IcoSort } from '../assets';
import theme from './Theme';
import Typography, { TypographyVariant } from './Typography';

interface ISortByProps {
  label?: string;
  isArrowIcon?: boolean | true;
  isSortIcon?: boolean | true;
  className?: string;
  /** Default : T14 */
  textVariant?: TypographyVariant;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;

  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    height: '24px',
    cursor: 'pointer',
    padding: '0',
    '&:hover': {
      opacity: 1,
      '& .MuiTypography-root': {
        color: theme.palette.bluegray[1000],
      },
    },
    '& > span :first-child': {
      marginRight: '4px',
    },
  },
  disabled: {
    '& p': {
      color: theme.palette.bluegray[300],
    },
    '& svg > path': {
      fill: theme.palette.bluegray[300],
    },
  },
  unclickable: {
    cursor: 'initial !important',
  },
  buttonLabel: {
    alignItems: 'center',
    textTransform: 'none',
  },
  sortIcon: {
    width: '16px',
    height: '16px',
    '& path': {
      fill: theme.palette.bluegray[800],
    },
  },
}));

type IProps = ISortByProps;

export const MDSSortBy = (props: IProps) => {
  const { disabled, isArrowIcon = true, isSortIcon = true, label } = props;
  const classes = useStyles(props);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      classes={{
        root: clsx(classes.root, props.className, { [classes.unclickable]: !isArrowIcon }),
        label: clsx(classes.buttonLabel, { [classes.disabled]: disabled }),
      }}
    >
      {isSortIcon && <IcoSort className={classes.sortIcon} />}
      {label && (
        <Typography variant={props.textVariant || 'T14'} weight="medium">
          {label}
        </Typography>
      )}
      {isArrowIcon && <IcoArrowDownOutline />}
    </Button>
  );
};

export default MDSSortBy;
