import difference from 'lodash/fp/difference';

export const isEqualNumberArrays = (arr1: number[], arr2: number[]): boolean => {
  return arr1.reduce((p, c) => p + c, 0) === arr2.reduce((p, c) => p + c, 0);
};
export const isEqualStringArrays = (arr1: string[], arr2: string[]): boolean => {
  return arr1.sort().reduce((p, c) => p + c, '') === arr2.sort().reduce((p, c) => p + c, '');
};

export const isEqualArrays = (arr1: (string | number)[], arr2: (string | number)[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return difference(arr1, arr2).length === 0 && difference(arr2, arr1).length === 0;
};
