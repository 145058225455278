/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyRequest,
  MarqCommonProtocolEmptyResponse,
  MarqCommonProtocolFileResponse,
  MonitoringDetectedBySellerProtocolsGetAllListingIdForSellerRequest,
  MonitoringDetectedBySellerProtocolsGetAllListingIdForSellerResponse,
  MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerRequest,
  MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerResponse,
  MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdRequest,
  MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdResponse,
  MonitoringDetectedBySellerProtocolsGetDetectedListingsByListingIdRequest,
  MonitoringDetectedBySellerProtocolsGetDetectedListingsByListingIdResponse,
  MonitoringDetectedBySellerProtocolsGetSellerDetailsRequest,
  MonitoringDetectedBySellerProtocolsGetSellerDetailsResponse,
  MonitoringDetectedBySellerProtocolsGetSellerExportListingsRequest,
  MonitoringDetectedBySellerProtocolsSellerExcelExportByListingIdRequest,
  MonitoringDetectedBySellerProtocolsSellerExcelExportRequest,
  MonitoringDetectedBySellerProtocolsSellerExcelExportResponse,
  MonitoringDetectedBySellerProtocolsSellerExcelResultRequest,
  MonitoringDetectedBySellerProtocolsSellerExcelResultResponse,
  MonitoringDetectedListingViewProtocolsExcelExportByListingIdRequest,
  MonitoringDetectedListingViewProtocolsExcelExportRequest,
  MonitoringDetectedListingViewProtocolsExcelExportResponse,
  MonitoringDetectedListingViewProtocolsExcelResultRequest,
  MonitoringDetectedListingViewProtocolsExcelResultResponse,
  MonitoringDetectedListingViewProtocolsGetAllListingIdRequest,
  MonitoringDetectedListingViewProtocolsGetAllListingIdResponse,
  MonitoringDetectedListingViewProtocolsGetDetectedCountRequest,
  MonitoringDetectedListingViewProtocolsGetDetectedCountResponse,
  MonitoringDetectedListingViewProtocolsGetDetectedListingsRequest,
  MonitoringDetectedListingViewProtocolsGetDetectedListingsResponse,
  MonitoringDetectedListingViewProtocolsGetOldDetectedListingsRequest,
  MonitoringDetectedListingViewProtocolsGetOldDetectedListingsResponse,
  MonitoringDetectedListingViewProtocolsGetOldExportListingsRequest,
  MonitoringDetectedProtocolsCloseDetectedTipResponse,
  MonitoringDetectedProtocolsDetectedRequestActiveRequest,
  MonitoringDetectedProtocolsGetDetectedCountByDomainTypeRequest,
  MonitoringDetectedProtocolsGetDetectedCountByDomainTypeResponse,
  MonitoringDetectedProtocolsGetReportingCapResponse,
  MonitoringDetectedProtocolsReadNoteRequest,
  MonitoringDetectedProtocolsReadNoteResponse,
  MonitoringDetectedProtocolsRequestTestPurchaseRequest,
  MonitoringDetectedProtocolsUpdateToArchivedRequest,
  MonitoringDetectionDetSmartRuleProtocolCreateRuleRequest,
  MonitoringDetectionDetSmartRuleProtocolCreateRuleResponse,
  MonitoringDetectionDetSmartRuleProtocolDeleteRuleRequest,
  MonitoringDetectionDetSmartRuleProtocolGetAutoExhaustCreditResponse,
  MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAssetRequest,
  MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAssetResponse,
  MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomRequest,
  MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomResponse,
  MonitoringDetectionDetSmartRuleProtocolGetFilterInfoDomainRequest,
  MonitoringDetectionDetSmartRuleProtocolGetFilterInfoDomainResponse,
  MonitoringDetectionDetSmartRuleProtocolGetFilterInfoSellerRequest,
  MonitoringDetectionDetSmartRuleProtocolGetFilterInfoSellerResponse,
  MonitoringDetectionDetSmartRuleProtocolGetListRequest,
  MonitoringDetectionDetSmartRuleProtocolGetListResponse,
  MonitoringDetectionDetSmartRuleProtocolSetAutoExhaustCreditRequest,
  MonitoringDetectionDetSmartRuleProtocolToggleRuleRequest,
  MonitoringDetectionDetSmartRuleProtocolUpdateOrderRequest,
  MonitoringDetectionDetSmartRuleProtocolUpdateRuleRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringDetected<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedCountDomainTypeCreate
   * @request POST:/monitoring/detected/count/domain-type
   */
  detectedCountDomainTypeCreate = (
    data: MonitoringDetectedProtocolsGetDetectedCountByDomainTypeRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedProtocolsGetDetectedCountByDomainTypeResponse, any>({
      path: `/monitoring/detected/count/domain-type`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedArchiveCreate
   * @request POST:/monitoring/detected/archive
   */
  detectedArchiveCreate = (data: MonitoringDetectedProtocolsUpdateToArchivedRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/monitoring/detected/archive`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedTpCreate
   * @request POST:/monitoring/detected/tp
   */
  detectedTpCreate = (data: MonitoringDetectedProtocolsRequestTestPurchaseRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/monitoring/detected/tp`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedNoteGetNoteCreate
   * @request POST:/monitoring/detected/note/get_note
   */
  detectedNoteGetNoteCreate = (data: MonitoringDetectedProtocolsReadNoteRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringDetectedProtocolsReadNoteResponse, any>({
      path: `/monitoring/detected/note/get_note`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedReportingCapList
   * @request GET:/monitoring/detected/reporting-cap
   */
  detectedReportingCapList = (params: RequestParams = {}) =>
    this.http.request<MonitoringDetectedProtocolsGetReportingCapResponse, any>({
      path: `/monitoring/detected/reporting-cap`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedRequestActiveCreate
   * @request POST:/monitoring/detected/request-active
   */
  detectedRequestActiveCreate = (
    data: MonitoringDetectedProtocolsDetectedRequestActiveRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/monitoring/detected/request-active`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedCloseTipCreate
   * @request POST:/monitoring/detected/close_tip
   */
  detectedCloseTipCreate = (data: MarqCommonProtocolEmptyRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringDetectedProtocolsCloseDetectedTipResponse, any>({
      path: `/monitoring/detected/close_tip`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedCountCreate
   * @request POST:/monitoring/detected/count
   */
  detectedCountCreate = (
    data: MonitoringDetectedListingViewProtocolsGetDetectedCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedListingViewProtocolsGetDetectedCountResponse, any>({
      path: `/monitoring/detected/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedGetReviewListCreate
   * @request POST:/monitoring/detected/get_review_list
   */
  detectedGetReviewListCreate = (
    data: MonitoringDetectedListingViewProtocolsGetDetectedListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedListingViewProtocolsGetDetectedListingsResponse, any>({
      path: `/monitoring/detected/get_review_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedGetOldFeaturedReviewListCreate
   * @request POST:/monitoring/detected/get_old_featured_review_list
   */
  detectedGetOldFeaturedReviewListCreate = (
    data: MonitoringDetectedListingViewProtocolsGetOldDetectedListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedListingViewProtocolsGetOldDetectedListingsResponse, any>({
      path: `/monitoring/detected/get_old_featured_review_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedGetAllListingIdCreate
   * @request POST:/monitoring/detected/get_all_listing_id
   */
  detectedGetAllListingIdCreate = (
    data: MonitoringDetectedListingViewProtocolsGetAllListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedListingViewProtocolsGetAllListingIdResponse, any>({
      path: `/monitoring/detected/get_all_listing_id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedExcelExportCreate
   * @request POST:/monitoring/detected/excel/export
   */
  detectedExcelExportCreate = (
    data: MonitoringDetectedListingViewProtocolsExcelExportRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedListingViewProtocolsExcelExportResponse, any>({
      path: `/monitoring/detected/excel/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedExcelProgressCreate
   * @request POST:/monitoring/detected/excel/progress
   */
  detectedExcelProgressCreate = (
    data: MonitoringDetectedListingViewProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedListingViewProtocolsExcelResultResponse, any>({
      path: `/monitoring/detected/excel/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedGetExportListCreate
   * @request POST:/monitoring/detected/get_export_list
   */
  detectedGetExportListCreate = (
    data: MonitoringDetectedListingViewProtocolsGetOldExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/detected/get_export_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedExcelExportListingIdCreate
   * @request POST:/monitoring/detected/excel/export/listing-id
   */
  detectedExcelExportListingIdCreate = (
    data: MonitoringDetectedListingViewProtocolsExcelExportByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/detected/excel/export/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSellerCountCreate
   * @request POST:/monitoring/detected/seller/count
   */
  detectedSellerCountCreate = (
    data: MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerResponse, any>({
      path: `/monitoring/detected/seller/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSellerGetAllListingCreate
   * @request POST:/monitoring/detected/seller/get_all_listing
   */
  detectedSellerGetAllListingCreate = (
    data: MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdResponse, any>({
      path: `/monitoring/detected/seller/get_all_listing`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSellerGetAllListingIdCreate
   * @request POST:/monitoring/detected/seller/get_all_listing_id
   */
  detectedSellerGetAllListingIdCreate = (
    data: MonitoringDetectedBySellerProtocolsGetAllListingIdForSellerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedBySellerProtocolsGetAllListingIdForSellerResponse, any>({
      path: `/monitoring/detected/seller/get_all_listing_id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSellerGetListingByIdCreate
   * @request POST:/monitoring/detected/seller/get_listing_by_id
   */
  detectedSellerGetListingByIdCreate = (
    data: MonitoringDetectedBySellerProtocolsGetDetectedListingsByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedBySellerProtocolsGetDetectedListingsByListingIdResponse, any>({
      path: `/monitoring/detected/seller/get_listing_by_id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSellerSellerDetailCreate
   * @request POST:/monitoring/detected/seller/seller-detail
   */
  detectedSellerSellerDetailCreate = (
    data: MonitoringDetectedBySellerProtocolsGetSellerDetailsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedBySellerProtocolsGetSellerDetailsResponse, any>({
      path: `/monitoring/detected/seller/seller-detail`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSellerExcelExportCreate
   * @request POST:/monitoring/detected/seller/excel/export
   */
  detectedSellerExcelExportCreate = (
    data: MonitoringDetectedBySellerProtocolsSellerExcelExportRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedBySellerProtocolsSellerExcelExportResponse, any>({
      path: `/monitoring/detected/seller/excel/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSellerExcelProgressCreate
   * @request POST:/monitoring/detected/seller/excel/progress
   */
  detectedSellerExcelProgressCreate = (
    data: MonitoringDetectedBySellerProtocolsSellerExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectedBySellerProtocolsSellerExcelResultResponse, any>({
      path: `/monitoring/detected/seller/excel/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSellerGetExportListCreate
   * @request POST:/monitoring/detected/seller/get_export_list
   */
  detectedSellerGetExportListCreate = (
    data: MonitoringDetectedBySellerProtocolsGetSellerExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/detected/seller/get_export_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSellerExcelExportListingIdCreate
   * @request POST:/monitoring/detected/seller/excel/export/listing-id
   */
  detectedSellerExcelExportListingIdCreate = (
    data: MonitoringDetectedBySellerProtocolsSellerExcelExportByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/detected/seller/excel/export/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleListCreate
   * @request POST:/monitoring/detected/smart-rule/list
   */
  detectedSmartRuleListCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolGetListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectionDetSmartRuleProtocolGetListResponse, any>({
      path: `/monitoring/detected/smart-rule/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleCreateCreate
   * @request POST:/monitoring/detected/smart-rule/create
   */
  detectedSmartRuleCreateCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolCreateRuleRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectionDetSmartRuleProtocolCreateRuleResponse, any>({
      path: `/monitoring/detected/smart-rule/create`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleUpdateCreate
   * @request POST:/monitoring/detected/smart-rule/update
   */
  detectedSmartRuleUpdateCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolUpdateRuleRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/monitoring/detected/smart-rule/update`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleDeleteCreate
   * @request POST:/monitoring/detected/smart-rule/delete
   */
  detectedSmartRuleDeleteCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolDeleteRuleRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/monitoring/detected/smart-rule/delete`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleOrderCreate
   * @request POST:/monitoring/detected/smart-rule/order
   */
  detectedSmartRuleOrderCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolUpdateOrderRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/monitoring/detected/smart-rule/order`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleToggleCreate
   * @request POST:/monitoring/detected/smart-rule/toggle
   */
  detectedSmartRuleToggleCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolToggleRuleRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/monitoring/detected/smart-rule/toggle`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleFilterInfoAssetCreate
   * @request POST:/monitoring/detected/smart-rule/filter-info/asset
   */
  detectedSmartRuleFilterInfoAssetCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAssetRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAssetResponse, any>({
      path: `/monitoring/detected/smart-rule/filter-info/asset`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleFilterInfoAtomCreate
   * @request POST:/monitoring/detected/smart-rule/filter-info/atom
   */
  detectedSmartRuleFilterInfoAtomCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectionDetSmartRuleProtocolGetFilterInfoAtomResponse, any>({
      path: `/monitoring/detected/smart-rule/filter-info/atom`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleFilterInfoDomainCreate
   * @request POST:/monitoring/detected/smart-rule/filter-info/domain
   */
  detectedSmartRuleFilterInfoDomainCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolGetFilterInfoDomainRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectionDetSmartRuleProtocolGetFilterInfoDomainResponse, any>({
      path: `/monitoring/detected/smart-rule/filter-info/domain`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleFilterInfoSellerCreate
   * @request POST:/monitoring/detected/smart-rule/filter-info/seller
   */
  detectedSmartRuleFilterInfoSellerCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolGetFilterInfoSellerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringDetectionDetSmartRuleProtocolGetFilterInfoSellerResponse, any>({
      path: `/monitoring/detected/smart-rule/filter-info/seller`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleAutoExhaustCreditCreate
   * @request POST:/monitoring/detected/smart-rule/auto-exhaust-credit
   */
  detectedSmartRuleAutoExhaustCreditCreate = (
    data: MonitoringDetectionDetSmartRuleProtocolSetAutoExhaustCreditRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/monitoring/detected/smart-rule/auto-exhaust-credit`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringDetected
   * @name DetectedSmartRuleAutoExhaustCreditList
   * @request GET:/monitoring/detected/smart-rule/auto-exhaust-credit
   */
  detectedSmartRuleAutoExhaustCreditList = (params: RequestParams = {}) =>
    this.http.request<MonitoringDetectionDetSmartRuleProtocolGetAutoExhaustCreditResponse, any>({
      path: `/monitoring/detected/smart-rule/auto-exhaust-credit`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
