import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { leftNavBarStateAtom } from '@marqvision/mds/core/LeftNavBar';

// watchTarget이 true이면 navibar를 접는다.
export const useConditionalCloseLNB = ({
  watchTarget,
  documentWidthThreshold,
}: {
  watchTarget: boolean;
  documentWidthThreshold: number;
}) => {
  const documentWidth = window.document.body.offsetWidth;
  const [{ isLeftNavBarOpen }, setLeftNavBarAtom] = useAtom(leftNavBarStateAtom);

  useEffect(() => {
    if (!isLeftNavBarOpen) return;

    const isScreenWideEnough = documentWidth >= documentWidthThreshold;
    const isScreenAlreadySmall = documentWidth <= documentWidthThreshold;

    if (isScreenWideEnough || isScreenAlreadySmall) {
      setLeftNavBarAtom((prevState) => ({ ...prevState, isLeftNavBarOpen: !watchTarget }));
    }
  }, [watchTarget, documentWidth, setLeftNavBarAtom, documentWidthThreshold]);
};
