import { ReactNode, useRef, useState } from 'react';
import { MDSSelectContainer } from '@marqvision/mds-v2';
import { LimitedAccessRoleDesc } from './LimitedAccessRoleDesc';
import { MemberRoleDesc } from './MemberRoleDesc';
import { OwnerRoleDesc } from './OwnerRoleDesc';

export type AccessLevel = 'limited_access' | 'member' | 'owner';
const ACCESS_LEVEL_OPTIONS: Array<{
  value: AccessLevel;
  content: ReactNode;
  label: string;
}> = [
  {
    value: 'limited_access',
    content: <LimitedAccessRoleDesc />,
    label: 'Limited access',
  },
  {
    value: 'member',
    content: <MemberRoleDesc />,
    label: 'Member',
  },
  {
    value: 'owner',
    content: <OwnerRoleDesc />,
    label: 'Owner',
  },
];

type Props<T> = {
  defaultLevel?: T;
  disableOwner?: boolean;
  onChange?: (newLevel: T) => void;
};
export const UserAccessLevel = <T,>({ defaultLevel, disableOwner, onChange }: Props<T>) => {
  const [level, setLevel] = useState(defaultLevel || ACCESS_LEVEL_OPTIONS[0].value);
  const frozenChangeHandler = useRef(onChange);
  const handleChange = (newLevel: AccessLevel) => {
    setLevel(newLevel);
    frozenChangeHandler.current?.(newLevel as T);
  };
  const options = disableOwner ? ACCESS_LEVEL_OPTIONS.slice(0, 2) : ACCESS_LEVEL_OPTIONS;

  return (
    <MDSSelectContainer.Wrapper orientation="vertical" value={level as AccessLevel}>
      {options.map((data) => (
        <MDSSelectContainer.Item
          key={data.value}
          title={{ label: data.label }}
          onClick={() => handleChange(data.value)}
          {...data}
        />
      ))}
    </MDSSelectContainer.Wrapper>
  );
};
