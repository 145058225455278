import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import numeral from 'numeral';
import { IconButton, MenuItem, makeStyles } from '@material-ui/core';
import { IcoCheckOutline, IcoFold, IcoHelpBorder, IcoUnfold } from '../../assets';
import MDSCheckbox from '../Checkbox';
import Tooltip, { MDSTooltip } from '../Tooltip';
import MDSTypography from '../Typography';
import MDSTheme from '../Theme';
import { DropItemContext, IDropDownItem, IDropDownProps, IDropItemContext } from '.';
import { getRegExpByKeyword, getSelectedStatus } from './@utils';

const useStyles = makeStyles(() => ({
  tooltip: {
    display: 'inline',
    marginLeft: '4px',
  },
  count: {
    display: 'inline',
    marginLeft: '4px',
  },
}));

export interface IDropDownItemProps {
  initialValue: IDropDownProps['value'];
  item: IDropDownItem;
  depth: number;
  onClose: () => void;
}

const HighLight = ({ searchText, label }: { searchText: string; label: string }) => {
  const regex = getRegExpByKeyword(searchText);
  const getConvertedText = (text: string): string => {
    return searchText ? text?.replace(regex, '<span class="highlight">$&</span>') : text;
  };

  return ReactHtmlParser(getConvertedText(label));
};

const ItemLabel = ({ item, depth, initialValue, onClose }: IDropDownItemProps) => {
  const classes = useStyles();

  const [imgSrc, setImgSrc] = useState<string>();
  const { ref, inView } = useInView();

  const menuRef = useRef<HTMLLIElement>(null);

  const {
    list,
    isMultiSelect: _isMultiSelect,
    searchText,
    selectedValue,
    setSelectedValue,
    setFoldedList,
    getIsFolded,
    close,

    currentChildrenMultiSelectRoot,
    setCurrentChildrenMultiSelectRoot,
  } = useContext<IDropItemContext>(DropItemContext);

  const isMultiSelect = item.isChildrenMultiSelectable ?? _isMultiSelect ?? false;

  const isFolded = getIsFolded(item);
  const [allChildValue, selectedChildCount, isSelected, isIndeterminate, isAllSelected] = getSelectedStatus(
    item,
    selectedValue
  );

  const handleGroupFold = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setFoldedList((ps) => (isFolded ? ps.filter((v) => v !== item.value) : [...ps, item.value]));
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    let target = e.target as Element;
    let needClose = false;
    while (target && target !== e.currentTarget) {
      if (target.tagName === 'BUTTON') {
        needClose = true;
        break;
      } else {
        target = target.parentNode as Element;
      }
    }
    if (needClose) {
      onClose();
    }
  };

  const handleItemClick = () => {
    if (item.disabled) return;

    if (isMultiSelect) {
      if (isSelected || isIndeterminate || isAllSelected) {
        //-1 전체선택일 경우
        if (Array.isArray(selectedValue) && selectedValue[0] === -1) {
          const filteredList: number[] = [];
          list.forEach((currItem) => {
            if (currItem.value !== item.value) {
              filteredList.push(currItem.value as number);
            }
          });

          setSelectedValue(filteredList);
        } else {
          setSelectedValue((ps) => ps.filter((value) => !allChildValue.includes(value)));
        }
      } else {
        /**
          1. local multi select이 여러개 존재하는 경우, 현재 선택된 local dropdown 영역만 선택되어야 하기 때문에
             최신 선택된 local dropdown의 root key를 저장한다.
          2. isMultiSelect와 local multi selection은 동시에 사용할 수 없기 때문에 여기에서만 최신 선택된 local dropdown의 root key를 저장한다.
        */
        setCurrentChildrenMultiSelectRoot(item.value as string);
        if (item.isChildrenMultiSelectable) {
          if (item._isLocalMultiSelectRoot) {
            setSelectedValue((ps) => [...allChildValue.filter((value) => !ps.includes(value))]);
          } else {
            setSelectedValue((ps) => [...ps, ...allChildValue.filter((value) => !ps.includes(value))]);
          }
        } else {
          // 일반적인 경우
          setSelectedValue((ps) => [...ps, ...allChildValue.filter((value) => !ps.includes(value))]);
        }
      }
    } else {
      setSelectedValue([item.value]);
      close(item.value);
    }
  };

  useEffect(() => {
    /**
     * local multi select "내부에 포함된" dropdown item 을 선택했을 때
     * 해당 아이템이 포함된 local dropdown만 check가 적용되고, 나머지 영역은 모두 해제되어야 한다.
     * local dropdown 내부에서는 자기가 어떤 local dropdown 소속인지 알 수 없다.
     * 대신 local dropdown root 통해 local dropdown 내부의 각 아이템들과 그 아이템들의 선택 여부를 알 수 있다.
     */
    if (item.isChildrenMultiSelectable && item._isLocalMultiSelectRoot) {
      // 직전에 선택한 지역 dropdown item에만 적용
      if (!(currentChildrenMultiSelectRoot === item.value || allChildValue.includes(currentChildrenMultiSelectRoot)))
        return;

      const isContained = intersection(selectedValue, allChildValue).length > 0;
      if (isContained) {
        const resolvedArray = intersection(allChildValue, selectedValue);
        const isSameArray = isEqual((selectedValue || []).sort(), resolvedArray.sort());
        if (isSameArray) return;

        setSelectedValue([...intersection(allChildValue, selectedValue)]);
      }
    }
  }, [
    allChildValue,
    currentChildrenMultiSelectRoot,
    item._isLocalMultiSelectRoot,
    item.isChildrenMultiSelectable,
    item.value,
    selectedValue,
    setCurrentChildrenMultiSelectRoot,
    setSelectedValue,
  ]);

  useEffect(() => {
    if (inView && item.imageUrl && imgSrc === undefined) {
      if (item.onView) {
        setImgSrc('');
        const fn = async () => {
          const presignedUrl = await item.onView?.();
          if (presignedUrl) {
            setImgSrc(presignedUrl);
          }
        };
        fn();
      } else {
        setImgSrc(item.imageUrl);
      }
    }
  }, [inView, imgSrc, item]);

  useEffect(() => {
    if (!isMultiSelect && initialValue === item.value) {
      if (menuRef.current) {
        menuRef.current.scrollIntoView({ block: 'center' });
      }
    }
  }, [isMultiSelect, initialValue, item.value]);

  // multi select를 사용하는 dropdown에서, children을 가지고 있거나 value를 가지고 있는 아이템
  // 혹은 multi select를 사용하지 않는 dropdown에서, children을 가지지 않고 value만 가지고 있는 아이템
  if (
    (isMultiSelect && (item.children || item.value !== undefined)) ||
    (!isMultiSelect && !item.children && item.value !== undefined)
  ) {
    return (
      <MenuItem
        ref={menuRef}
        button
        onClick={handleItemClick}
        className={clsx('dropItem', {
          hasChild: item.children,
          hasImage: item.imageUrl,
          selectable: !item.disabled,
          disabled: item.disabled,
        })}
        style={{ paddingLeft: depth * 32 + 16 }}
        key={item.value as React.Key}
        tabIndex={isMultiSelect ? undefined : 0}
      >
        {isMultiSelect && (
          <MDSCheckbox
            indeterminate={isIndeterminate}
            className="dropCheckbox"
            isPrimary
            checked={isSelected || isAllSelected}
          />
        )}
        {item.imageUrl && (
          <div className="lazyImage">
            <img alt={imgSrc} src={imgSrc} ref={ref} />
          </div>
        )}
        {item.renderComponent ? (
          item.renderComponent(handleItemClick, onClose)
        ) : (
          <MDSTypography
            variant="T14"
            weight={!isMultiSelect && isSelected ? 'medium' : 'regular'}
            color={!isMultiSelect && isSelected ? MDSTheme.palette.blue[700] : MDSTheme.palette.bluegray[800]}
            lineClamp={2}
            style={{ flex: 'auto' }}
          >
            {!isMultiSelect && isSelected && <IcoCheckOutline className="selectIcon" />}
            {searchText && typeof item.label === 'string' ? (
              <HighLight label={item.label} searchText={searchText} />
            ) : (
              item.label
            )}
            {typeof item.count === 'number' && (
              <MDSTypography
                variant="T14"
                weight={!isMultiSelect && isSelected ? 'medium' : 'regular'}
                color={!isMultiSelect && isSelected ? MDSTheme.palette.blue[700] : MDSTheme.palette.bluegray[800]}
                className={classes.count}
              >
                ({numeral(item.count).format('0,0')})
              </MDSTypography>
            )}
            {item.help && (
              <MDSTooltip title={item.help} className={classes.tooltip}>
                <IcoHelpBorder width="16px" />
              </MDSTooltip>
            )}
          </MDSTypography>
        )}
        {item.children && (
          <div className="childrenIndicatorBox">
            {item.showSelectedChildCount && selectedChildCount > 0 && (
              <div className="selectedChildCountLabelBox">
                <MDSTypography variant="T14" color={MDSTheme.palette.blue[700]}>
                  Selected
                </MDSTypography>
                <MDSTypography variant="T14" color={MDSTheme.palette.blue[700]}>
                  {selectedChildCount}
                </MDSTypography>
              </div>
            )}
            <IconButton className="expendButton" onClick={handleGroupFold}>
              {!isFolded ? <IcoFold /> : <IcoUnfold />}
            </IconButton>
          </div>
        )}
      </MenuItem>
    );
  }

  return (
    <div onClick={handleClick}>
      {React.isValidElement(item.label) ? (
        item.label
      ) : (
        <div
          className={clsx('dropItem', { hasChild: item.children, hasImage: item.imageUrl, disabled: item.disabled })}
          style={{ paddingLeft: depth * 32 + 16 }}
        >
          {item.imageUrl && (
            <div className="lazyImage">
              <img alt={imgSrc} src={imgSrc} ref={ref} />
            </div>
          )}
          <MDSTypography variant="T14">
            {searchText && typeof item.label === 'string' ? (
              <HighLight searchText={searchText} label={item.label} />
            ) : (
              item.label
            )}
          </MDSTypography>
          {item.children && (
            <div className="childrenIndicatorBox">
              <IconButton className="expendButton" onClick={handleGroupFold}>
                {!isFolded ? <IcoFold /> : <IcoUnfold />}
              </IconButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const DropDownItem = (props: IDropDownItemProps): JSX.Element => {
  const { getIsFolded } = useContext<IDropItemContext>(DropItemContext);

  const { item, depth, ...restProps } = props;
  const isFolded = getIsFolded(item);

  const BgColorByDepth = [MDSTheme.palette.white, MDSTheme.palette.bluegray[50], MDSTheme.palette.bluegray[100]];

  return (
    <div
      style={{ backgroundColor: BgColorByDepth[depth] || MDSTheme.palette.bluegray[100] }}
      key={item.value as React.Key}
      onClick={(e) => e.stopPropagation()}
    >
      <ItemLabel {...props} />
      {!isFolded &&
        item.children?.map((child: IDropDownItem) => (
          <DropDownItemWrap key={child.value as string} item={child} depth={depth + 1} {...restProps} />
        ))}
    </div>
  );
};

const DropDownItemWrap = (props: IDropDownItemProps) => {
  const { textLengthToShowTooltip } = useContext<IDropItemContext>(DropItemContext);
  const { item } = props;

  return textLengthToShowTooltip && typeof item.label === 'string' && textLengthToShowTooltip <= item.label.length ? (
    <Tooltip title={item.label} key={item.label}>
      <DropDownItem {...props} />
    </Tooltip>
  ) : (
    <DropDownItem {...props} />
  );
};

export default DropDownItemWrap;
