import React, { useState, useRef, useEffect, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { TextField as MUITextField, TextFieldProps } from '@material-ui/core';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { makeStyles } from '@material-ui/core/styles';
import { IcoSearch, IcoCloseDeleteRemoveBorder } from '../assets';
import MDSTheme from './Theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  input: {
    width: '100%',
    margin: 0,
    backgroundColor: MDSTheme.palette.white,
    borderRadius: '4px',
    border: `1px solid ${MDSTheme.palette.bluegray['200']}`,
    transition: 'width 100ms ease-in-out',
    '& > .MuiInputBase-root > .MuiInputBase-input': {
      ...MDSTheme.typography.regular,
      backgroundColor: MDSTheme.palette.white,
      padding: 0,
      height: 'auto',
      borderRadius: 0,
      color: MDSTheme.palette.bluegray['800'],
      '&::placeholder': {
        color: `${MDSTheme.palette.bluegray[300]} !important`,
        opacity: 1,
        ...MDSTheme.typography.regular,
        fontFamily: 'Visuelt-Regular, Pretendard Variable',
      },
    },
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& > .MuiInput-underline:before, & > .MuiInput-underline:hover:not(.Mui-disabled):before, & > .MuiInput-underline:after':
      {
        border: 'none',
      },
    '& .MuiInputBase-multiline': {
      padding: 0,
    },
  },
  medium: {
    '& .MuiFormControl-root': {
      padding: '4.5px 27px',
    },
    '& .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input': {
      ...MDSTheme.typography.T14,
    },
    '& .clearButton': {
      left: '-23px',
      width: '16px',
      height: '16px',
    },
    '& .searchIconPosition': {
      left: '7px',
      width: '16px',
      height: '16px',
    },
  },
  large: {
    '& .MuiFormControl-root': {
      padding: '6px 32px',
    },
    '& .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input': {
      ...MDSTheme.typography.T16,
    },
    '& .clearButton': {
      left: '-28px',
      width: '20px',
      height: '20px',
    },
    '& .searchIconPosition': {
      left: '8px',
      width: '20px',
      height: '20px',
    },
  },
  extraLarge: {
    '& .MuiFormControl-root': {
      padding: '8px 35px',
    },
    '& .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input': {
      ...MDSTheme.typography.T20,
    },
    '& .clearButton': {
      left: '-31px',
      width: '20px',
      height: '20px',
    },
    '& .searchIconPosition': {
      left: '11px',
      width: '20px',
      height: '20px',
    },
  },
  focused: {
    borderColor: MDSTheme.palette.blue['700'],
  },
  error: {
    borderColor: MDSTheme.palette.red['600'],
  },
  clearButton: {
    position: 'absolute',
    zIndex: 1,
    cursor: 'pointer',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  inputButton: {
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

type SearchSize = 'medium' | 'large' | 'extra_large';

interface IOwnProps {
  size?: SearchSize;
  interactive?: boolean;
  realtime?: boolean;
  value?: string;
  onSearch: (value: string) => void;
  rootProps?: HTMLAttributes<HTMLDivElement> & {
    ref?: React.Ref<HTMLDivElement>;
  };
}

const width = {
  default: '230px',
  expand: '200px',
  shrink: '120px',
};

export type ITextFieldProps = IOwnProps & Omit<TextFieldProps, 'size'>;

const MDSSearch = (props: ITextFieldProps): JSX.Element => {
  const classes = useStyles();
  const {
    size = 'medium',
    interactive,
    realtime,
    value,
    style,
    InputProps,
    onSearch,
    onFocus,
    rootProps,
    ...restProps
  } = props;

  const [focused, setFocused] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>();

  const handleInputFocus = (): void => {
    setFocused(true);
  };

  const handleInputBlur = (): void => {
    setFocused(false);
    onSearch(inputRef.current?.value as string);
  };

  const handleClear = (): void => {
    setFocused(false);
    onSearch('');

    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.blur();
    }
  };

  const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.nativeEvent.isComposing) {
      inputRef.current?.blur();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const getInputWidth = () => {
    if (interactive) {
      if (focused || value) {
        return width.expand;
      }
      return width.shrink;
    }
    return width.default;
  };

  const getShrinkPaddingRight = interactive && !focused && !value && { paddingRight: 0 };

  const renderClearButton = () => {
    return (
      <div style={{ position: 'relative' }}>
        {(focused || value) && (
          <IcoCloseDeleteRemoveBorder
            className={clsx(classes.clearButton, 'clearButton')}
            onClick={handleClear}
            onMouseDown={(e: React.MouseEvent<SVGSVGElement>) => e.preventDefault()}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = !value ? '' : value;
    }
  }, [value]);

  const defaultProps = {
    onKeyDown: handleEnterKeyDown,
  };

  const realtimeProps = {
    onChange: handleInputChange,
  };

  return (
    <div
      {...rootProps}
      className={clsx(classes.root, rootProps?.className, {
        [classes.medium]: size === 'medium',
        [classes.large]: size === 'large',
        [classes.extraLarge]: size === 'extra_large',
      })}
    >
      <MUITextField
        {...restProps}
        inputRef={inputRef}
        placeholder={props.placeholder || 'Search'}
        className={clsx(classes.input, { [classes.focused]: focused })}
        InputProps={{ className: classes.inputButton, ...InputProps } as Partial<StandardInputProps>}
        style={{ width: props.fullWidth ? '100%' : getInputWidth(), ...getShrinkPaddingRight, ...style }}
        {...(realtime ? realtimeProps : defaultProps)}
        onFocus={(e) => {
          handleInputFocus();
          onFocus?.(e);
        }}
        onBlur={handleInputBlur}
      />
      <IcoSearch className={clsx(classes.searchIcon, 'searchIconPosition')} />
      {renderClearButton()}
    </div>
  );
};

MDSSearch.displayName = 'MDSSearch';

export default MDSSearch;
