/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MonitoringFilterProtocolPagingRequest,
  MonitoringFilterProtocolRequest,
  MonitoringFilterProtocolResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringFilter<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringFilter
   * @name FilterGetAllAssetsCreate
   * @request POST:/monitoring/filter/get-all-assets
   */
  filterGetAllAssetsCreate = (data: MonitoringFilterProtocolRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/monitoring/filter/get-all-assets`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringFilter
   * @name FilterGetAllDomainsCreate
   * @request POST:/monitoring/filter/get-all-domains
   */
  filterGetAllDomainsCreate = (data: MonitoringFilterProtocolRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/monitoring/filter/get-all-domains`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringFilter
   * @name FilterGetAllSellersCreate
   * @request POST:/monitoring/filter/get-all-sellers
   */
  filterGetAllSellersCreate = (data: MonitoringFilterProtocolPagingRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/monitoring/filter/get-all-sellers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringFilter
   * @name FilterDetectionGetAllRulesCreate
   * @request POST:/monitoring/filter/detection/get-all-rules
   */
  filterDetectionGetAllRulesCreate = (data: MonitoringFilterProtocolRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/monitoring/filter/detection/get-all-rules`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringFilter
   * @name FilterEnforcementGetAllRulesCreate
   * @request POST:/monitoring/filter/enforcement/get-all-rules
   */
  filterEnforcementGetAllRulesCreate = (data: MonitoringFilterProtocolRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/monitoring/filter/enforcement/get-all-rules`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringFilter
   * @name FilterGetAllOisCreate
   * @request POST:/monitoring/filter/get-all-ois
   */
  filterGetAllOisCreate = (data: MonitoringFilterProtocolPagingRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/monitoring/filter/get-all-ois`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringFilter
   * @name FilterGetAllReportableCreate
   * @request POST:/monitoring/filter/get-all-reportable
   */
  filterGetAllReportableCreate = (data: MonitoringFilterProtocolRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/monitoring/filter/get-all-reportable`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringFilter
   * @name FilterGetAllModelCreate
   * @request POST:/monitoring/filter/get-all-model
   */
  filterGetAllModelCreate = (data: MonitoringFilterProtocolRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/monitoring/filter/get-all-model`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
