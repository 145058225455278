import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { authorizedWebsiteAPI, queryClient } from '~/apis';
import {
  AuthorizedWebsiteProtocolsGetAuthWebsiteCountRequest,
  AuthorizedWebsiteProtocolsGetAuthWebsiteCountResponse,
  AuthorizedWebsiteProtocolsGetAuthWebsiteListRequest,
  AuthorizedWebsiteProtocolsGetAuthWebsiteListResponse,
} from '~/swagger/data-contracts';
import { QueryKeyDetected } from '~/apis/detection';
import { QueryKeySettings } from '~/apis/settings';
import { QueryKeyDetectedWebsite } from '~/apis/detection/websiteImpersonation';

const QueryKey = 'authorized-website';
const QueryOptions = { refetchOnWindowFocus: false };

export const useGetAuthorizedWebsiteCountQuery = (
  request: AuthorizedWebsiteProtocolsGetAuthWebsiteCountRequest,
  options?: UseQueryOptions<AuthorizedWebsiteProtocolsGetAuthWebsiteCountResponse>
) => {
  return useQuery<AuthorizedWebsiteProtocolsGetAuthWebsiteCountResponse>(
    [QueryKey, 'authorized-website-count', request],
    async () => {
      const { data } = await authorizedWebsiteAPI.countCreate(request);

      return data;
    },
    { ...QueryOptions, ...options }
  );
};

export const useGetAuthorizedWebsiteListQuery = (
  request: AuthorizedWebsiteProtocolsGetAuthWebsiteListRequest,
  options?: UseInfiniteQueryOptions<AuthorizedWebsiteProtocolsGetAuthWebsiteListResponse>
) => {
  return useInfiniteQuery<AuthorizedWebsiteProtocolsGetAuthWebsiteListResponse>(
    [QueryKey, 'authorized-website-list', request],
    async ({ pageParam }) => {
      const { data } = await authorizedWebsiteAPI.listCreate({
        ...request,
        offset: pageParam || request.offset,
      });

      return data;
    },
    {
      ...options,
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.list.length < 50) {
          return;
        }

        return request.limit ? request.limit * allPage.length : 0;
      },
    }
  );
};

export const useAddAuthorizedWebsiteMutation = () => {
  return useMutation(authorizedWebsiteAPI.postAuthorizedWebsite, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey, 'authorized-website-list']);
      queryClient.invalidateQueries([QueryKey, 'authorized-website-count']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-count']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-grid-view-list']);
      queryClient.invalidateQueries([QueryKeyDetectedWebsite, 'website-impersonation-table-view-list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'domain-type-count']);
      queryClient.invalidateQueries([QueryKeySettings, 'credits']);
    },
  });
};

export const useGetAllAuthorizedWebsiteIdMutation = () => {
  return useMutation(authorizedWebsiteAPI.getAllIdCreate);
};

export const useCreateWebsiteExcelMutation = () => {
  return useMutation(authorizedWebsiteAPI.exportCreate);
};
