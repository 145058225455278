import { useMutation, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import CryptoJS from 'crypto-js';
import { authApi, queryClient } from '~/apis';
import {
  AuthProtocolsGetUserInfoResponse,
  AuthProtocolsLoginResponse,
  MarqCommonEnumsClientClientRoleEnum,
} from '~/swagger/data-contracts';

const UserInfoLocalStorageIdentifier = '_marq_cc_ui';

export const QueryKeyAuth = 'auth';

type StorageData = AuthProtocolsLoginResponse & {
  selectedBrandId?: number;
};

type AuthData = StorageData & {
  isAdmin: boolean;
};

export const STORE_SECRET = process.env.REACT_APP_STORE_SECRET || '';

const isClientView: boolean = window.self !== window.top;

const parseAuthData = (data: string | null): AuthData | null => {
  if (data) {
    const temp = JSON.parse(CryptoJS.AES.decrypt(data, STORE_SECRET).toString(CryptoJS.enc.Utf8)) as AuthData;
    return {
      ...temp,
      isAdmin: temp.role === MarqCommonEnumsClientClientRoleEnum.Admin,
    };
  }
  return null;
};

const getAuth = (): (StorageData & { isAdmin: boolean }) | null => {
  try {
    const data = isClientView
      ? sessionStorage.getItem(UserInfoLocalStorageIdentifier)
      : localStorage.getItem(UserInfoLocalStorageIdentifier);
    return parseAuthData(data);
  } catch (e) {
    clearAuth();
    throw new Error('Failed to load user info');
  }
};

export const setAuth = (data: StorageData, refetch?: boolean) => {
  queryClient.setQueryData([QueryKeyAuth], {
    ...data,
    isAdmin: data.role === MarqCommonEnumsClientClientRoleEnum.Admin,
  });
  const encryptData = CryptoJS.AES.encrypt(JSON.stringify(data), STORE_SECRET).toString();
  if (isClientView) {
    sessionStorage.setItem(UserInfoLocalStorageIdentifier, encryptData);
  } else {
    localStorage.setItem(UserInfoLocalStorageIdentifier, encryptData);
  }
  if (refetch) {
    queryClient.invalidateQueries();
  }
};

export const clearAuth = () => {
  queryClient.setQueryData([QueryKeyAuth], null);
  queryClient.clear();
  if (isClientView) {
    sessionStorage.removeItem(UserInfoLocalStorageIdentifier);
  } else {
    localStorage.removeItem(UserInfoLocalStorageIdentifier);
  }
};

export const useAuth = (): UseQueryResult<AuthData | null> => useQuery([QueryKeyAuth], getAuth);
export const fetchAuth = async () => await queryClient.fetchQuery([QueryKeyAuth], getAuth);

export const refreshAuth = async (data: AuthProtocolsLoginResponse) => {
  const response = await authApi.refreshTokenCreate({
    refresh_token: data.refresh_token,
    email: data.user_email,
  });

  const { access_token } = response.data;

  setAuth(
    {
      ...data,
      access_token,
    },
    false
  );

  return access_token;
};

export const useLoginMutation = () => {
  return useMutation(authApi.loginCreate, {
    onSuccess: (resp) => {
      if (!resp) return;
      setAuth(resp.data, true);
    },
  });
};

export const useLogoutMutation = () => {
  return useMutation(authApi.logoutCreate, {
    onSuccess: () => {
      clearAuth();
    },
  });
};

export const useLoginWithToken = () =>
  useMutation(authApi.loginWithTokenCreate, {
    onSuccess: (resp) => {
      if (!resp) return;
      setAuth(resp.data, true);
    },
  });

export const useLoginRiotOkta = () =>
  useMutation(authApi.oktaCreate, {
    onSuccess: (resp) => {
      if (!resp) return;
      setAuth(resp.data, true);
    },
  });

export const useLoginGeneralOkta = () =>
  useMutation(authApi.loginCreate2, {
    onSuccess: (resp) => {
      if (!resp) return;
      setAuth(resp.data);
    },
  });

export const useSelectBrandId = () => {
  const { data: auth } = useAuth();

  return useMutation(
    (request: { brandId?: number }) =>
      new Promise(() => {
        setAuth({
          ...auth,
          selectedBrandId: request.brandId,
        } as StorageData);
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );
};

const USER_INFO_STALE_TIME = 1000 * 60 * 5; // 5 minutes
export const useUserInfoQuery = (
  options?: UseQueryOptions<AuthProtocolsGetUserInfoResponse>
): UseQueryResult<AuthProtocolsGetUserInfoResponse, unknown> =>
  useQuery({
    queryKey: [QueryKeyAuth, 'user-info'],
    queryFn: async () => {
      const { data } = await authApi.userInfoList();
      return data;
    },
    ...options,
    staleTime: USER_INFO_STALE_TIME,
  });
export const getUserInfoQuery = async () => {
  let data = queryClient.getQueryData<AuthProtocolsGetUserInfoResponse>([QueryKeyAuth, 'user-info']);
  if (!data) {
    try {
      data = await queryClient.fetchQuery({
        queryKey: [QueryKeyAuth, 'user-info'],
        queryFn: async () => {
          const { data } = await authApi.userInfoList();
          return data;
        },
        staleTime: USER_INFO_STALE_TIME,
      });
    } catch {
      // do nothing
    }
  }
  return data;
};
