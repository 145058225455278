import { makeStyles } from '@material-ui/core';
import { MDSTheme } from '@marqvision/mds/core';
import { CommonErrorPage } from './CommonError';
import { SentryFallbackRenderData } from './@types';

const useStyles = makeStyles(() => ({
  containerLayout: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: MDSTheme.palette.white,
  },
}));

export const ErrorBoundaryView = ({ errorData }: { errorData: SentryFallbackRenderData }) => {
  const classes = useStyles();
  return (
    <div className={classes.containerLayout}>
      <CommonErrorPage {...errorData} />
    </div>
  );
};
