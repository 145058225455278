export enum LanguageEnum {
  Korean = 'ko',
  English = 'en',
}

export interface ILocalizedBannerStrings {
  textContent: {
    id: string;
    mainText: string;
    subText: string;
    link: string;
  }[];
  buttonText: string;
}

export const LocalizedBannerStrings: Record<LanguageEnum, ILocalizedBannerStrings> = {
  [LanguageEnum.English]: {
    textContent: [
      {
        id: '10413_A_Eng',
        mainText: 'Get your free IP filing &',
        subText: 'management in China',
        link: 'https://folio.marqvision.com/?utm_source=commerce',
      },
      {
        id: '10413_B_Eng',
        mainText: 'Secure two IP filings in China',
        subText: 'for the cost of one',
        link: 'https://folio.marqvision.com/?utm_source=commerce',
      },
    ],
    buttonText: 'Consult Now',
  },
  [LanguageEnum.Korean]: {
    textContent: [
      {
        id: '10413_A_Kor',
        mainText: '중국 상표 출원+모니터링+',
        subText: '관리를 무료로 받아보세요',
        link: 'https://folio.marqvision.com/ko/?utm_source=commerce',
      },
      {
        id: '10413_B_Kor',
        mainText: '중국 상표 / 저작권 / 디자인권',
        subText: '출원 1+1 혜택을 받아보세요',
        link: 'https://folio.marqvision.com/ko/?utm_source=commerce',
      },
    ],
    buttonText: '상담 받기',
  },
};
