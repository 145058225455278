import React, { CSSProperties, ForwardedRef, forwardRef } from 'react';
import clsx from 'clsx';
import { IconButton, makeStyles, Drawer } from '@material-ui/core';
import { DrawerProps } from '@material-ui/core/Drawer';
import { IcoCloseDeleteRemoveOutline, IcoArrowLeftOutline } from '../assets';
import Typography from './Typography';
import theme from './Theme';

const useStyles = makeStyles(() => ({
  defaultPaper: {
    border: 'none',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 8px rgba(0, 0, 0, 0.12)',
    overflow: 'hidden',
  },
  headerButton: {
    width: '24px',
    height: '24px',
  },
  panelHeader: {
    padding: '16px 20px',
    borderBottom: `1px solid ${theme.palette.bluegray[200]}`,
    backgroundColor: theme.palette.white,
    width: '-webkit-fill-available',
    position: 'fixed',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  panelHeaderClose: {
    paddingRight: '16px',
  },
  panelHeaderBack: {
    paddingLeft: '16px',
  },
  titleWrapper: {
    display: 'flex',
    gap: '4px',
  },
  buttonWrapper: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  titleColumnWrapper: {
    display: 'grid',
    gap: '2px',
  },
  panelBody: {
    height: '100%',
    marginTop: '63px',
    overflow: 'overlay',
    overflowX: 'hidden',
  },
  panelActions: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.bluegray['100']}`,
    backgroundColor: theme.palette.white,
    display: 'flex',
    padding: '16px',
    gap: '8px',
    zIndex: 1,
    bottom: 0,
    position: 'absolute',
  },
}));

type OwnPanelHeaderProps = {
  title: string | JSX.Element;
  subText?: string;
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
  onBack?: React.ReactEventHandler;
  buttonWrapperStyle?: CSSProperties;
};

type PanelHeader = React.PropsWithChildren<OwnPanelHeaderProps & Omit<Partial<DrawerProps>, 'title'>>;

const PanelHeader = (props: Pick<PanelHeader, 'title' | 'subText' | 'leftButton'>) => {
  const classes = useStyles();
  const { title, subText, leftButton } = props;
  return (
    <div className={classes.buttonWrapper}>
      {React.isValidElement(title) ? (
        title
      ) : (
        <div className={clsx(classes.titleWrapper, { [classes.titleColumnWrapper]: leftButton })}>
          <Typography variant="T20" weight="bold" style={{ color: theme.palette.bluegray[900] }}>
            {title}
          </Typography>
          <Typography
            variant={leftButton ? 'T14' : 'T20'}
            weight="regular"
            style={{ color: theme.palette.bluegray[800] }}
          >
            {subText}
          </Typography>
        </div>
      )}
      {leftButton}
    </div>
  );
};
export const MDSPanelHeader = (props: PanelHeader) => {
  const classes = useStyles();
  const { title, subText, leftButton, rightButton, onBack, onClose, style, buttonWrapperStyle } = props;

  return (
    <div
      className={clsx('panel-header', classes.panelHeader, props.className, {
        [classes.panelHeaderBack]: onBack,
        [classes.panelHeaderClose]: onClose,
      })}
      style={style}
    >
      <div className={classes.buttonWrapper} style={buttonWrapperStyle}>
        {onBack && (
          <IconButton onClick={onBack} style={{ padding: 0 }}>
            <IcoArrowLeftOutline className={classes.headerButton} />
          </IconButton>
        )}
        <PanelHeader title={title} subText={subText} leftButton={leftButton} />
      </div>
      <div className={classes.buttonWrapper}>
        {rightButton}
        {onClose && (
          <IconButton onClick={onClose as React.ReactEventHandler} style={{ padding: 0 }}>
            <IcoCloseDeleteRemoveOutline className={classes.headerButton} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

type PanelBody = React.PropsWithChildren<Partial<DrawerProps>>;

export const MDSPanelBody = forwardRef((props: PanelBody, ref: ForwardedRef<HTMLDivElement>) => {
  const classes = useStyles();

  return (
    <div className={clsx('panel-body', classes.panelBody, props.className)} id={props.id} style={props.style} ref={ref}>
      {props.children}
    </div>
  );
});
MDSPanelBody.displayName = 'MDSPanelBody';

interface IOwnPanelActionProps {
  style?: React.CSSProperties;
  className?: string;
}

type PanelAction = React.PropsWithChildren<IOwnPanelActionProps>;

export const MDSPanelActions = (props: PanelAction) => {
  const classes = useStyles();

  return (
    <div className={clsx('panel-action', props.className, classes.panelActions)} style={props.style}>
      {props.children}
    </div>
  );
};

type OwnPanelProps = {
  title?: string;
  width?: string | number;
};

type IPanelProps = OwnPanelProps & Omit<DrawerProps, 'title'>;

export const MDSPanel = (props: IPanelProps) => {
  const classes = useStyles();
  const { width, children, ...restProps } = props;

  return (
    <Drawer
      classes={{
        paper: classes.defaultPaper,
      }}
      closeAfterTransition
      style={{ width }}
      {...restProps}
    >
      {children}
    </Drawer>
  );
};

export default MDSPanel;
