import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAtomValue } from 'jotai/index';
import { useAtom } from 'jotai';
import numeral from 'numeral';
import { isValidSearch, usePrepareListSelecting, useQueryParams } from '@marqvision/shared';
import { listingTotalCountAtom } from '@marqvision/shared/src/hooks';
import { MDSToastSnackbar, dismissToast } from '@marqvision/mds/core';
import {
  QueryKeyEnforcement,
  useGetAllAssets,
  useGetAllDomains,
  useGetAllModels,
  useGetAllReportable,
  useGetAllRules,
  useGetAllSellers,
} from '~/apis/enforcement/reporting';
import {
  MarqBrandEnumsListingMonitoringStatusEnum,
  MarqCommonEnumsCurrencyEnum,
  MarqCommonEnumsDomainTypeEnum,
  MarqCommonEnumsReportableReasonEnum,
  MarqCommonEnumsSortedOrderEnum,
  MonitoringFilterProtocolPagingRequest,
  MonitoringFilterProtocolRequest,
} from '~/swagger/data-contracts';
import { useEnforcementCountProgressQuery } from '~/apis/enforcement';
import {
  useEnforcementTpCountQuery,
  useGetAllTpAssets,
  useGetAllTpDomains,
  useGetAllTpSellers,
} from '~/apis/enforcement/testPurchase';
import { useLogger } from '~/hooks';
import { useClientBrandIds } from '~/pages/Enforcement/@hooks';
import {
  parseValueToValueArray,
  isEqualNumberArrays,
  isEqualStringArrays,
  mergePages,
  parseMarqBrandEnumsListingMonitoringStatusEnum,
} from '~/utils';
import { clientFilterChipAtom, userProfileAtom } from '~/atoms';
import { queryClient } from '~/apis';
import { getLogArchivedStatusTitle } from '~/components/Filters/@utils';
import {
  useEnforcementPathParams,
  useEnforcementPathParamsForTestPurchase,
} from '~/pages/Enforcement/EcommerceSocialMedia/@hooks';
import {
  EIPOption,
  EIPStatusFilterVocab,
  FilterChipOption,
  FilterQueryParam,
  TPStatusFilterVocab,
  UpdatedDateRange,
} from '../@types';

// hooks for each filters
const STALE_TIME = 5000; // 5sec
export type KeywordFilterParams = {
  onSearch?: (value: string) => void;
};
export const useKeyword = ({ onSearch }: KeywordFilterParams) => {
  const { params, setParams, removeParams } = useQueryParams<FilterQueryParam>();
  const keywordParam = params.search;
  const [value, setValue] = useState<string>(keywordParam);

  const search = (value: string) => {
    const { isValid, message } = isValidSearch(value);
    if (!isValid) {
      MDSToastSnackbar({
        type: 'error',
        title: message,
      });
      return false;
    } else {
      dismissToast();
    }
    if (value.trim() === '') {
      removeParams('search');
    } else {
      onSearch?.(value);
      setParams({ search: value }, { replace: true });
    }
  };

  useEffect(() => {
    // reset filter로 keyword query parameter를 날렸을 때 화면에 보이는 keyword도 같이 초기화한다.
    // -> query parameter가 react-router의 내부 state로 관리 되고 있기 때문에 query parameter가 변했을 때 effect를 이용하여 뒤이어 state 싱크를 맞춰야한다.
    if (!keywordParam) {
      setValue('');
    }
  }, [keywordParam]);

  return {
    value,
    handleValueChange: setValue,
    search,
  };
};

export const useGetFilterParams = () => {
  const { brandId } = useClientBrandIds();
  const clientFilterChipState = useAtomValue(clientFilterChipAtom);
  const userProfile = useAtomValue(userProfileAtom);
  const { isFilterApplied: isFilterActivated } = useResetFilterState();
  const { status } = usePathParams();
  const { params: queryParam, setParams, removeParams } = useQueryParams<FilterQueryParam>();
  const {
    domain_type,
    assets,
    marketplaces,
    seller_search_keyword,
    report_reason,
    ois,
    smart_rule_ids,
    tm_logo,
    tm_text,
    infringing_images_filter,
    likely_counterfeit,
    top_results,
    repeat_offender,
    flagged,
    requested,
    low_price,
    min_price,
    max_price,
    min_sales_volume,
    max_sales_volume,
    start_date,
    end_date,
    search,
    models,
  } = queryParam;
  const { includeExpired, domainType, sellerNameList: selectedSellerNames } = clientFilterChipState;
  const currency = userProfile?.client_currency || MarqCommonEnumsCurrencyEnum.KRW;

  const requestFilterParams = useMemo(() => {
    return parseMonitoringFilterProtocolRequest();
  }, [brandId, queryParam, clientFilterChipState]);
  const requestPagingFilterParams = useMemo(() => {
    return parseMonitoringFilterProtocolPagingRequest();
  }, [brandId, queryParam, clientFilterChipState]);
  const legacyRequestFilterParams = useMemo(() => {
    return parseLegacyRequest();
  }, [brandId, queryParam, clientFilterChipState]);

  function parseMonitoringFilterProtocolRequest(): MonitoringFilterProtocolRequest {
    const domainTypeList = (
      domainType.includes(',') ? domainType.split(',') : domainType
    ) as MarqCommonEnumsDomainTypeEnum[];

    return {
      brand_id: brandId,
      domain_type: parseValueToValueArray(domain_type || domainTypeList),
      reportable_filter: parseValueToValueArray(report_reason),
      enforcement_rule_id_filter: parseValueToValueArray(smart_rule_ids),
      client_status_filter: status,
      asset_id_filter: parseValueToValueArray(assets),
      domain_id_filter: parseValueToValueArray(marketplaces),
      seller_name_filter: parseValueToValueArray(selectedSellerNames),
      search,
      min_price,
      max_price,
      ...(min_sales_volume && { min_sales_volume: isNaN(min_sales_volume) ? undefined : min_sales_volume }),
      ...(max_sales_volume && { max_sales_volume: isNaN(max_sales_volume) ? undefined : max_sales_volume }),
      currency,

      include_expired: includeExpired,

      official_image_id_filter: parseValueToValueArray(ois),
      tm_infringement_filter: tm_logo,
      tm_infringement_text_filter: tm_text,
      infringing_images_filter,

      gen_ai_counterfeit_filter: likely_counterfeit,
      top_result_filter: top_results,
      repeat_offender_filter: repeat_offender,
      flag_id_filter: parseValueToValueArray(flagged),
      model_id_filter: parseValueToValueArray(models),
      client_requested_filter: requested,
      low_price_filter: low_price,
      updated_date_range_filter: {
        start: start_date,
        end: end_date,
      },
    };
  }
  function parseMonitoringFilterProtocolPagingRequest(): MonitoringFilterProtocolPagingRequest {
    return {
      ...requestFilterParams,
      // 프론트에서 필터 sorted_order 를 모두 제거됨
      sorted_order: MarqCommonEnumsSortedOrderEnum.Asc,
      in_search_keyword_filter: seller_search_keyword,
      size: 30,
      search_after: [],
    };
  }
  function parseLegacyRequest() {
    const { domain_type } = requestFilterParams;
    return {
      domain_type,
      asset_id_filter: parseValueToValueArray(assets) || [],
      domain_id_filter: parseValueToValueArray(marketplaces) || [],
      seller_name_filter: parseValueToValueArray(selectedSellerNames) || [],
      client_status_filter: status,
    };
  }

  return {
    params: queryParam,
    setParams,
    removeParams,
    isFilterActivated,
    requestFilterParams,
    requestPagingFilterParams,
    legacyRequestFilterParams,
  };
};
export const useAssetOptions = () => {
  const { brandId, clientId } = useClientBrandIds();
  const { status } = usePathParams();
  const { params, requestFilterParams, setParams, removeParams } = useGetFilterParams();
  const { amplitudeLogging } = useLogger();
  const { assets: selectedAssetIds } = params;
  const { page } = usePathParams();

  const assetQuery = useGetAllAssets(
    {
      ...requestFilterParams,
      asset_id_filter: [],
      brand_id: brandId,
      client_status_filter: status,
    },
    {
      enabled: !!status && status.length > 0,
      staleTime: STALE_TIME,
    }
  );

  const assetOptions = assetQuery.data?.list;

  const handleSelect = (value: number[]) => {
    if (!isEqualNumberArrays(value, parseValueToValueArray(selectedAssetIds))) {
      if (value.length > 0) {
        amplitudeLogging('applied filter', {
          Type: 'assets',
          On: page,
          ...(page === 'archived' && { Tab: `${getLogArchivedStatusTitle(status)}` }),
        });
        setParams({ assets: value }, { replace: true });
      } else {
        removeParams('assets');
      }
    }
  };

  return {
    list: assetOptions || [],
    totalCount: assetQuery.data?.count || 0,
    selected: parseValueToValueArray(selectedAssetIds), // to support MDSDropdown value type.
    select: handleSelect,
    disabled: assetOptions?.length === 0,
    isFetching: assetQuery.isFetching,
  };
};
export const useMarketplaceOptions = () => {
  const { status } = usePathParams();
  const { params, requestFilterParams, setParams, removeParams } = useGetFilterParams();
  const { marketplaces: selectedMarketplaceIds } = params;
  const { amplitudeLogging } = useLogger();
  const { page } = usePathParams();

  const marketplacesQuery = useGetAllDomains(
    {
      ...requestFilterParams,
      domain_id_list: [],
    },
    {
      enabled: !!status,
      staleTime: STALE_TIME,
    }
  );

  const marketplaceOptions = marketplacesQuery.data?.list;
  const handleSelect = (value: number[]) => {
    if (!isEqualNumberArrays(value, parseValueToValueArray(selectedMarketplaceIds))) {
      if (value.length > 0) {
        amplitudeLogging('applied filter', {
          Type: 'marketplaces',
          On: page,
          ...(page === 'archived' && { Tab: `${getLogArchivedStatusTitle(status)}` }),
        });
        setParams({ marketplaces: value }, { replace: true });
      } else {
        removeParams('marketplaces');
      }
    }
  };

  return {
    list: marketplaceOptions || [],
    totalCount: marketplacesQuery.data?.count || 0,
    selected: parseValueToValueArray(selectedMarketplaceIds),
    select: handleSelect,
    disabled: marketplaceOptions?.length === 0,
    isFetching: marketplacesQuery.isFetching,
  };
};

export const useModelOptions = () => {
  const { status } = usePathParams();
  const { params, requestFilterParams, setParams, removeParams } = useGetFilterParams();
  const { models: selectedModelIds } = params;

  const modelQuery = useGetAllModels(
    {
      ...requestFilterParams,
      model_id_filter: [],
    },
    {
      enabled: !!status,
      staleTime: STALE_TIME,
    }
  );

  const modelOptions = modelQuery.data?.list;
  const handleSelect = (value: number[]) => {
    if (!isEqualNumberArrays(value, parseValueToValueArray(selectedModelIds))) {
      if (value.length > 0) {
        setParams({ models: value }, { replace: true });
      } else {
        removeParams('models');
      }
    }
  };

  return {
    list: modelOptions || [],
    totalCount: modelQuery.data?.count || 0,
    selected: parseValueToValueArray(selectedModelIds),
    select: handleSelect,
    disabled: modelOptions?.length === 0,
    isFetching: modelQuery.isFetching,
    shouldDisplayFilter: modelQuery.data?.filter_display,
  };
};

enum PageWithFilterEnum {
  Detection = 'detection',
  Enforcement = 'enforcement',
  TestPurchase = 'testPurchase',
  Archived = 'archived',
}
export const usePathParams = () => {
  const { pathname } = useLocation();
  const pathnameArray = pathname.split('/');
  const { params } = useQueryParams<FilterQueryParam>();
  // todo-@elie: EnforcementStatus, TpEnforcementStatus 제거및 usePathParams 로 통합
  const { status: EnforcementStatus, tab, stage } = useEnforcementPathParams();
  const { status: TpEnforcementStatus } = useEnforcementPathParamsForTestPurchase();

  if (pathname.includes(PageWithFilterEnum.Detection)) {
    return {
      page: PageWithFilterEnum.Detection,
      status: MarqBrandEnumsListingMonitoringStatusEnum.Detection,
    };
  }
  if (pathname.includes(PageWithFilterEnum.Enforcement)) {
    return {
      page: PageWithFilterEnum.Enforcement,
      tab,
      stage,
      status: EnforcementStatus,
    };
  }
  if (pathname.includes(PageWithFilterEnum.TestPurchase)) {
    return {
      page: PageWithFilterEnum.TestPurchase,
      status: TpEnforcementStatus,
    };
  }
  if (pathname.includes(PageWithFilterEnum.Archived)) {
    /**
     * (pathnameArray[2] as MarqBrandEnumsListingMonitoringStatusEnum)
     * -> packages/brand-client/src/Router/ProtectedRouter.tsx 에서
     *    방어처리를 하기 때문에 따로 파싱함수를 만들지 않고 as 로 처리
     */
    return {
      page: PageWithFilterEnum.Archived,
      status:
        parseMarqBrandEnumsListingMonitoringStatusEnum(pathnameArray[2]) ||
        MarqBrandEnumsListingMonitoringStatusEnum.Dismissed,
    };
  }
  return {
    page: PageWithFilterEnum.Detection,
    status: MarqBrandEnumsListingMonitoringStatusEnum.Detection,
  };
};
export const useSellerOptions = () => {
  const { status } = usePathParams();
  const { params, requestPagingFilterParams, setParams, removeParams } = useGetFilterParams();
  const [{ sellerNameList }, setAtom] = useAtom(clientFilterChipAtom);
  const { amplitudeLogging } = useLogger();
  const { page } = usePathParams();

  const sellersQuery = useGetAllSellers(
    {
      ...requestPagingFilterParams,
      seller_name_filter: [],
    },
    { enabled: !!status, staleTime: STALE_TIME }
  );

  const sellerOptions = mergePages(sellersQuery.data?.pages);

  const handleSelect = (sellerNames: string[]) => {
    if (!isEqualStringArrays(sellerNames, sellerNameList)) {
      if (sellerNames.length > 0) {
        amplitudeLogging('applied filter', {
          Type: 'sellers',
          On: page,
          ...(page === 'archived' && { Tab: `${getLogArchivedStatusTitle(status)}` }),
        });
        setAtom((prev) => ({ ...prev, sellerNameList: sellerNames }));
      } else {
        setAtom((prev) => ({ ...prev, sellerNameList: [] }));
      }
    }
  };
  const handleSearch = (value: string) => {
    if (value !== params.seller_search_keyword) {
      if (value.trim() === '') {
        removeParams('seller_search_keyword');
      } else {
        const { isValid, message } = isValidSearch(value);
        if (!isValid) {
          MDSToastSnackbar({
            type: 'error',
            title: message,
          });
          return false;
        } else {
          dismissToast();
        }
        setParams({ seller_search_keyword: value }, { replace: true });
      }
    }
  };
  const handleFetchNextPage = () => {
    if (!sellersQuery.isFetching && !sellersQuery.isFetchingNextPage && sellersQuery.hasNextPage) {
      sellersQuery.fetchNextPage();
    }
  };

  useEffect(() => {
    return () => {
      queryClient.resetQueries([QueryKeyEnforcement, 'get-all-sellers']);
    };
  }, []);

  return {
    list: sellerOptions.list || [],
    selected: sellerNameList,
    totalCount: sellersQuery.data?.pages[0].count,
    disabled: sellerOptions.list.length === 0,
    isFetching: sellersQuery.isFetching,
    searchKeyword: params.seller_search_keyword,
    select: handleSelect,
    search: handleSearch,
    fetchNextPage: handleFetchNextPage,
  };
};
export const useSmartRuleOptions = () => {
  const { params, requestFilterParams, setParams, removeParams } = useGetFilterParams();
  const { smart_rule_ids: selectedSmartRuleIds } = params;
  const { amplitudeLogging } = useLogger();
  const { page } = usePathParams();

  const smartRuleQuery = useGetAllRules(requestFilterParams, {
    enabled: !!requestFilterParams.client_status_filter,
    staleTime: STALE_TIME,
  });

  const smartRuleOptions = smartRuleQuery.data?.list;

  const handleSelect = (value: number[]) => {
    if (!isEqualNumberArrays(value, parseValueToValueArray(selectedSmartRuleIds))) {
      if (value.length > 0) {
        amplitudeLogging('applied filter', {
          Type: 'smart rule',
          On: page,
        });
        setParams({ smart_rule_ids: value }, { replace: true });
      } else {
        removeParams('smart_rule_ids');
      }
    }
  };

  return {
    list: smartRuleOptions ?? [],
    selected: requestFilterParams.enforcement_rule_id_filter ?? [],
    select: handleSelect,
    disabled: smartRuleOptions?.length === 0,
    isFetching: smartRuleQuery.isFetching,
  };
};
export const useUpdatedDate = () => {
  const totalCount = useAtomValue(listingTotalCountAtom);
  const { amplitudeLogging } = useLogger();
  const { page } = usePathParams();
  const [params, setParams] = useSearchParams();
  const dateRange: UpdatedDateRange = {
    startDate: params.get('start_date'),
    endDate: params.get('end_date'),
  };
  const setDateRange = ({ startDate, endDate }: UpdatedDateRange) => {
    amplitudeLogging('applied filter', {
      Type: 'updated date',
      On: page,
    });
    if (startDate) params.set('start_date', startDate);
    if (endDate) params.set('end_date', endDate);
    setParams(params, { replace: true });
  };

  return {
    dateRange,
    setDateRange,
    disabled: totalCount === 0,
  };
};
// EIP: Enforcement in Progress
export const useEIPStatus = () => {
  const { brandId } = useClientBrandIds();
  const eipStatusQuery = useEnforcementCountProgressQuery(
    {
      brand_id: brandId || null,
    },
    { staleTime: STALE_TIME }
  );
  const { tab, stage, status } = useEnforcementPathParams();
  const sellers = useSellerOptions();

  const eipStatusOptions: FilterChipOption<EIPOption>[] = useMemo(() => {
    if (!eipStatusQuery.data) return [];
    return [
      {
        label: EIPStatusFilterVocab['reporting'],
        value: MarqBrandEnumsListingMonitoringStatusEnum.Reporting,
        count: numeral(eipStatusQuery.data.reporting).format('0,0'),
        selected: status === MarqBrandEnumsListingMonitoringStatusEnum.Reporting,
      },
      {
        label: EIPStatusFilterVocab['soft_notice'],
        value: MarqBrandEnumsListingMonitoringStatusEnum.SoftNotice,
        count: numeral(eipStatusQuery.data.soft_notice).format('0,0'),
        selected: status === MarqBrandEnumsListingMonitoringStatusEnum.SoftNotice,
      },
    ];
  }, [eipStatusQuery.data, status]);

  const navigate = useNavigate();
  const updateEIPStatus = (selected: FilterChipOption<EIPOption>) => {
    sellers.select([]);
    navigate(`/enforcement/${tab}/${stage}/${selected.value}/?sorted_column=updated_date&sorted_order=desc`, {
      replace: true,
    });
  };

  return {
    options: eipStatusOptions,
    select: updateEIPStatus,
    isFetching: eipStatusQuery.isFetching,
  };
};

export const useReportReason = () => {
  const { brandId, clientId } = useClientBrandIds();
  const { status } = useEnforcementPathParams();
  const { params, requestFilterParams, setParams, removeParams } = useGetFilterParams();
  const { assets, marketplaces, report_reason: reportReason } = params;
  const { amplitudeLogging } = useLogger();
  const { page } = usePathParams();

  const reportReasonQuery = useGetAllReportable(
    {
      ...requestFilterParams,
      reportable_filter: [],
      brand_id: brandId,
      client_status_filter: status,
    },
    {
      enabled: !!status,
      staleTime: STALE_TIME,
    }
  );

  const reportReasonOptions = reportReasonQuery.data?.list;

  const handleSelect = (value: MarqCommonEnumsReportableReasonEnum[]) => {
    if (value.length > 0) {
      amplitudeLogging('applied filter', {
        Type: 'report reason',
        On: page,
      });
      setParams({ report_reason: value }, { replace: true });
    } else {
      removeParams('report_reason');
    }
  };

  return {
    list: reportReasonOptions || [],
    selected: parseValueToValueArray(reportReason),
    select: handleSelect,
    isFetching: reportReasonQuery.isFetching,
    disabled: reportReasonOptions?.length === 0,
  };
};

//-----
export const useTpStatus = () => {
  const { tab, stage, status } = useEnforcementPathParamsForTestPurchase();

  const tpStatusQuery = useEnforcementTpCountQuery({}, { staleTime: STALE_TIME });
  const sellers = useSellerOptions();
  const tpStatusOptions: FilterChipOption<MarqBrandEnumsListingMonitoringStatusEnum>[] = useMemo(() => {
    if (!tpStatusQuery.data) return [];
    return [
      {
        label: TPStatusFilterVocab['reviewing'],
        value: MarqBrandEnumsListingMonitoringStatusEnum.Reviewing,
        count: tpStatusQuery.data.review_count,
        selected: status === MarqBrandEnumsListingMonitoringStatusEnum.Reviewing,
      },
      {
        label: TPStatusFilterVocab['purchase'],
        value: MarqBrandEnumsListingMonitoringStatusEnum.Purchase,
        count: tpStatusQuery.data.purchase_count,
        selected: status === MarqBrandEnumsListingMonitoringStatusEnum.Purchase,
      },
      {
        label: TPStatusFilterVocab['completed'],
        value: MarqBrandEnumsListingMonitoringStatusEnum.Completed,
        // count: tpStatusQuery.data.tp_completed, // NOTE: 값을 오고 있는데 화면에 보여주는지??
        selected: status === MarqBrandEnumsListingMonitoringStatusEnum.Completed,
      },
      {
        label: TPStatusFilterVocab['case_closed'],
        value: MarqBrandEnumsListingMonitoringStatusEnum.CaseClosed,
        // count: tpStatusQuery.data.tp_closed,
        selected: status === MarqBrandEnumsListingMonitoringStatusEnum.CaseClosed,
      },
    ];
  }, [status, tpStatusQuery.data]);

  const navigate = useNavigate();
  const updateTPStatus = (selected: FilterChipOption<MarqBrandEnumsListingMonitoringStatusEnum>) => {
    sellers.select([]);
    navigate(`/enforcement/${tab}/${stage}/${selected.value}?sorted_column=updated_date&sorted_order=desc`, {
      replace: true,
    });
  };

  return {
    options: tpStatusOptions,
    select: updateTPStatus,
    ifFetching: tpStatusQuery.isFetching,
  };
};

export const useTpAssetOptions = () => {
  const { brandId, clientId } = useClientBrandIds();
  const { tab, stage, status: tpStatus } = usePathParams();
  const { params, requestFilterParams, setParams, removeParams } = useGetFilterParams();
  const { assets: selectedTpAssetIds } = params;
  const { amplitudeLogging } = useLogger();

  const tpAssetQuery = useGetAllTpAssets(
    {
      ...requestFilterParams,
      asset_id_filter: [],
      brand_id: brandId,
      client_status_filter: tpStatus,
      tab,
      stage,
    },
    {
      enabled: !!tpStatus,
      staleTime: STALE_TIME,
    }
  );

  const tpAssetOptions = tpAssetQuery.data?.list ?? [];

  const { resetListingState } = usePrepareListSelecting();

  const handleSelect = (value: number[]) => {
    if (!isEqualNumberArrays(value, parseValueToValueArray(selectedTpAssetIds))) {
      if (value.length > 0) {
        amplitudeLogging('applied filter', {
          Type: 'assets',
          On: 'enforcement',
        });
        setParams({ assets: value }, { replace: true });
      } else {
        removeParams('assets');
      }
      resetListingState();
    }
  };

  return {
    list: tpAssetOptions || [],
    selected: parseValueToValueArray(selectedTpAssetIds),
    select: handleSelect,
    disabled: tpAssetOptions?.length === 0,
    isFetching: tpAssetQuery.isFetching,
  };
};
export const useTpMarketplaceOptions = () => {
  const { brandId, clientId } = useClientBrandIds();
  const { tab, stage, status: tpStatus } = usePathParams();
  const { params, requestFilterParams, setParams, removeParams } = useGetFilterParams();
  const { marketplaces: selectedTpMarketplaceIds } = params;
  const { amplitudeLogging } = useLogger();

  const tpMarketplacesQuery = useGetAllTpDomains(
    {
      ...requestFilterParams,
      domain_id_filter: [],
      brand_id: brandId,
      client_status_filter: tpStatus,
      tab,
      stage,
    },
    {
      enabled: !!tpStatus,
      staleTime: STALE_TIME,
    }
  );

  const tpMarketplaceOptions = tpMarketplacesQuery.data?.list ?? [];

  const handleSelect = (value: number[]) => {
    if (!isEqualNumberArrays(value, parseValueToValueArray(selectedTpMarketplaceIds))) {
      if (value.length > 0) {
        amplitudeLogging('applied filter', {
          Type: 'marketplaces',
          On: 'enforcement',
        });
        setParams({ marketplaces: value }, { replace: true });
      } else {
        removeParams('marketplaces');
      }
    }
  };

  return {
    list: tpMarketplaceOptions || [],
    selected: parseValueToValueArray(selectedTpMarketplaceIds),
    select: handleSelect,
    disabled: tpMarketplaceOptions?.length === 0,
    isFetching: tpMarketplacesQuery.isFetching,
  };
};
export const useTpSellerOptions = () => {
  const { brandId } = useClientBrandIds();
  const [{ sellerNameList }, setAtom] = useAtom(clientFilterChipAtom);
  const { tab, stage, status: tpStatus } = usePathParams();
  const { params, requestFilterParams, setParams, removeParams } = useGetFilterParams();
  const { amplitudeLogging } = useLogger();

  const tpSellersQuery = useGetAllTpSellers(
    {
      ...requestFilterParams,
      brand_id: brandId,
      client_status_filter: tpStatus,
      size: 30,
      search_after: [],
      sorted_order: MarqCommonEnumsSortedOrderEnum.Asc,
      tab,
      stage,
    },
    { enabled: !!tpStatus }
  );

  const tpSellerOptions = mergePages(tpSellersQuery.data?.pages).list ?? [];

  const handleSelect = (value: string[]) => {
    if (!isEqualStringArrays(value, sellerNameList)) {
      if (value.length > 0) {
        amplitudeLogging('applied filter', {
          Type: 'sellers',
          On: 'enforcement',
        });
        setAtom((prev) => ({ ...prev, sellerNameList: value }));
      } else {
        setAtom((prev) => ({ ...prev, sellerNameList: [] }));
      }
    }
  };
  const handleSearch = (value: string) => {
    if (value.trim() === '' && sellerNameList.length > 0) {
      removeParams('seller_search_keyword');
    } else {
      setParams({ seller_search_keyword: value }, { replace: true });
    }
  };
  const handleFetchNextPage = () => {
    if (!tpSellersQuery.isFetching && !tpSellersQuery.isFetchingNextPage) {
      tpSellersQuery.fetchNextPage();
    }
  };

  return {
    list: tpSellerOptions || [],
    selected: sellerNameList,
    select: handleSelect,
    disabled: tpSellerOptions?.length === 0,
    isFetching: tpSellersQuery.isFetching,
    searchKeyword: params.seller_search_keyword,
    totalCount: tpSellersQuery.data?.pages[0].count,
    search: handleSearch,
    fetchNextPage: handleFetchNextPage,
  };
};
export const useResetFilterState = () => {
  const { params, removeParams } = useQueryParams<FilterQueryParam>();
  const [clientFilterChipState, setClientFilterChipState] = useAtom(clientFilterChipAtom);

  const {
    keyword,
    search,
    assets,
    marketplaces,
    smart_rule_ids,
    report_reason,
    ois,
    high_risk,
    priority,
    use_of_rights,
    low_price,
    tm_logo,
    tm_text,
    infringing_images_filter,
    repeat_offender,
    top_results,
    requested,
    min_price,
    max_price,
    start_date,
    end_date,
    flagged,
    models,
    country,
    authorized_seller,
    max_sales_volume,
    min_sales_volume,
    likely_counterfeit,
  } = params;
  const getOrFalse = (value?: boolean | string | null) => (value ? value === 'true' || value : false);
  const isFilterApplied =
    keyword ||
    search ||
    !!assets ||
    !!marketplaces ||
    !!clientFilterChipState.sellerNameList.length ||
    !!smart_rule_ids ||
    !!report_reason ||
    !!ois ||
    flagged ||
    models ||
    country ||
    authorized_seller ||
    getOrFalse(high_risk) ||
    getOrFalse(priority) ||
    getOrFalse(use_of_rights) ||
    getOrFalse(low_price) ||
    getOrFalse(tm_logo) ||
    getOrFalse(tm_text) ||
    getOrFalse(infringing_images_filter) ||
    getOrFalse(repeat_offender) ||
    getOrFalse(top_results) ||
    getOrFalse(requested) ||
    getOrFalse(likely_counterfeit) ||
    !!min_price ||
    !!max_price ||
    !!start_date ||
    !!end_date ||
    !!min_sales_volume ||
    !!max_sales_volume;

  const resetAllFilters = () => {
    removeParams([
      'keyword',
      'search',
      'assets',
      'marketplaces',
      'smart_rule_ids',
      'report_reason',
      'high_risk',
      'priority',
      'use_of_rights',
      'low_price',
      'tm_logo',
      'tm_text',
      'infringing_images_filter',
      'repeat_offender',
      'top_results',
      'requested',
      'min_price',
      'max_price',
      'start_date',
      'end_date',
      'models',
      'country',
      'authorized_seller',
      'likely_counterfeit',
    ]);
    setClientFilterChipState((prev) => ({
      ...prev,
      sellerNameList: [],
    }));
  };

  return {
    isFilterApplied,
    resetAllFilters,
  };
};
