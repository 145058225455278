import { atom } from 'jotai';

/**
 *
 * [[[[중요]]]]]
 *
 * 이 atom 파일에 있는 atom들은 useListingSelector/index.ts에서 사용되는 atom들입니다.
 * 직접 호출하지 마세요!
 *
 *
 */

/**
 * listing select 상태의 source of truth.
 *
 *  - 여러 개의 list들을 종류에 따라 atomFamily로 관리한다.
 *    - (예: enforcement - under review 리스트, removed 리스트가 atomFamily에 의해 각각의 atom으로 생성된다.)
 *  - 순서를 유지하기 위해 Map으로 관리: Map<listingId, isSelected>
 *  - listing Item의 select 상태에 접근시 O(1)의 시간복잡도를 가지기 때문.
 *  - 개별 select시에 사용한다.
 */
export const listingSelectStateAtom = atom<Map<string | number, boolean>>(new Map());
listingSelectStateAtom.onMount = (setAtom) => {
  setAtom(new Map());
  return () => {
    setAtom(new Map());
  };
};
/**
 * listingSelectStateAtom의 파생상태.
 *
 * - multiple select시 id 기반으로만 관리했을 때, 유저의 실수로 인해 중간에 누락되는 listingId가 생길 수 있으므로, 보다나은 UX를 위해 array로 관리하는 것도 필요.
 * - multiple select시에 사용한다.
 */
export const listingSelectStateArrayAtom = atom((get) => {
  const items = get(listingSelectStateAtom);
  const itemArray = Array.from(items.entries());
  return itemArray.map(([listingId, selected]) => ({ listingId, selected }));
});

/**
 * listingSelectStateArrayAtom의 파생상태.
 *
 * - 현재 선택된 listing item의 listingId를 array로 반환.
 * - API 호출시 사용
 */
export const selectedListingItemsAtom = atom((get) => {
  const items = get(listingSelectStateArrayAtom);
  return items.filter(({ selected }) => selected).map(({ listingId }) => listingId);
});
/**
 * selectedListingItemsAtom의 파생상태.
 *
 * - 현재 선택된 listing item의 개수를 보여주는 데에 사용
 */
export const selectedListingItemCountAtom = atom((get) => get(selectedListingItemsAtom).length);

/**
 * 한개의 아이템을 선택/해제하는 atom.
 */
export const updateListingItemSelectStateAtom = atom(
  null,
  (get, set, item: { listingId: string | number; checked?: boolean }) => {
    const items = get(listingSelectStateAtom);
    const selected = items.get(item.listingId);
    set(listingSelectStateAtom, new Map([...items, [item.listingId, item.checked ?? !selected]]));
  }
);

/**
 * 모든 아이템을 선택/해제하는 atom.
 */
export const updateAllListingItemsSelectStateAtom = atom(null, (get, set, checked: boolean) => {
  const items = get(listingSelectStateAtom);
  const newItems = new Map<string | number, boolean>();
  for (const [listingId] of items.entries()) {
    newItems.set(listingId, checked);
  }
  set(listingSelectStateAtom, newItems);
});
/**
 * 모든 listingSelectStateAtom을 초기화하는 atom.
 */
export const resetListingItemsSelectStateAtom = atom(null, (get, set) => {
  set(listingSelectStateAtom, new Map());
});
//#endregion

export const listingTotalCountAtom = atom(0); // 실제 list는 pagination이 있기 때문에 totalCount값은 따로 관리 필요.

//#region - Listing Select States

export const dragStateAtom = atom<{
  isDragging: boolean;
  startIndex: number | null;
  lastIndex: number | null;
}>({
  isDragging: false,
  startIndex: null,
  lastIndex: null,
});

export const selectMultipleItemsAtom = atom(null, (get, set, indices: { index1: number; index2: number }) => {
  const startIndex = Math.min(indices.index1, indices.index2);
  const endIndex = Math.max(indices.index1, indices.index2);
  if (startIndex === -1 || endIndex === -1) return;

  const listArray = get(listingSelectStateArrayAtom);
  const newItems = new Map<string | number, boolean>();

  for (let i = startIndex; i <= endIndex; i++) {
    const item = listArray[i];
    if (!item) break; // atom에 너무 빨리 접근한 경우 이런 엣지케이스가 있더라...ㅠㅠ
    newItems.set(item.listingId, true);
  }
  const items = get(listingSelectStateAtom);
  set(listingSelectStateAtom, new Map([...items, ...newItems]));
});
