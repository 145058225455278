import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  MonitoringFilterProtocolResponse,
  SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListResponse,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListResponse,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetFilterInfoSellerRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountRequest,
  SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountResponse,
} from '~/swagger/data-contracts';
import { Listings } from '~/swagger/Listings';
import { http } from '~/apis/base';
import { QueryKeyDetected } from '~/apis/detection';
import { SellerAnalyticsDetailDetected } from '~/swagger/SellerAnalyticsDetailDetected';
import { QueryKeySettings } from '~/apis/settings';
import { queryClient } from '~/apis';
import { AdditionalQueryOptions } from '~/apis/@types';

const listingsApi = new Listings(http);
const sellerAnalyticsDetailDetectedApi = new SellerAnalyticsDetailDetected(http);

export const SellerDetailDetectedQueryKey = ['sellerDetail', 'listingStatus', 'detected'];

//#region List
export const useListingStatusDetectedListQuery = <TData>(
  request: SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListRequest,
  options: UseQueryOptions<
    SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListResponse,
    unknown,
    TData
  >
) =>
  useQuery<SellerSellerAnalyticDetailDetectedListingProtocolsGetDetectedListingListResponse, unknown, TData>(
    [...SellerDetailDetectedQueryKey, 'list', request],
    async () => {
      const { data } = await sellerAnalyticsDetailDetectedApi.detailListingStatusDetectedListCreate(request);
      return data;
    },
    options
  );

export const useListingStatusDetectedCountQuery = <TData>(
  request: SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountRequest,
  options: UseQueryOptions<
    SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountResponse,
    unknown,
    TData
  >
) =>
  useQuery<SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountResponse, unknown, TData>(
    [...SellerDetailDetectedQueryKey, 'count', request],
    async () => {
      const { data } = await sellerAnalyticsDetailDetectedApi.detailListingStatusDetectedCountCreate(request);
      return data;
    },
    {
      ...options,
      enabled: !!(request.cluster_id || request.seller_id),
    }
  );
//#endregion

// @deprecated
export const useArchiveMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(listingsApi.archiveCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyDetected, 'list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'count']);
      queryClient.invalidateQueries([QueryKeyDetected, 'old-list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'seller-count']);
      queryClient.invalidateQueries([QueryKeyDetected, 'domain-type-count']);
      queryClient.invalidateQueries([QueryKeySettings, 'credits']);

      queryClient.invalidateQueries([...SellerDetailDetectedQueryKey, 'list']); //TODO: 사용처에서 직접 전달하도록 해야할 듯 [morgan]
      queryClient.invalidateQueries([...SellerDetailDetectedQueryKey, 'count']);
    },
  });
};

export const useAddAuthorizedSellerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(listingsApi.authorizedCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyDetected, 'list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'count']);
      queryClient.invalidateQueries([QueryKeyDetected, 'old-list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'seller-count']);
      queryClient.invalidateQueries([QueryKeyDetected, 'domain-type-count']);
      queryClient.invalidateQueries([QueryKeySettings, 'credits']);

      queryClient.invalidateQueries([...SellerDetailDetectedQueryKey, 'list']);
      queryClient.invalidateQueries([...SellerDetailDetectedQueryKey, 'count']);
    },
  });
};

//#region Filters
export const useDetailListingStatusDetectedFilterDomainQuery = <TData>(
  request: SellerSellerAnalyticDetailDetectedListingProtocolsGetListingStatusCountRequest,
  options: UseQueryOptions<MonitoringFilterProtocolResponse, unknown, TData>
) =>
  useQuery<MonitoringFilterProtocolResponse, unknown, TData>(
    [...SellerDetailDetectedQueryKey, 'filter', 'domain', request],
    async () => {
      const { data } = await sellerAnalyticsDetailDetectedApi.detailListingStatusDetectedFilterDomainCreate(request);
      return data;
    },
    options
  );

export const useDetailListingStatusDetectedFilterSellerQuery = <TData>(
  request: SellerSellerAnalyticDetailDetectedListingProtocolsGetFilterInfoSellerRequest,
  options: UseQueryOptions<MonitoringFilterProtocolResponse, unknown, TData>
) =>
  useQuery<MonitoringFilterProtocolResponse, unknown, TData>(
    [...SellerDetailDetectedQueryKey, 'filter', 'seller', request],
    async () => {
      const { data } = await sellerAnalyticsDetailDetectedApi.detailListingStatusDetectedFilterSellerCreate(request);
      return data;
    },
    options
  );
//#endregion

//#region Actions
export const useDetailListingStatusDetectedExportMutation = () =>
  useMutation(sellerAnalyticsDetailDetectedApi.detailListingStatusDetectedExportCreate);
export const useDetailListingStatusDetectedExportProgressQuery = (
  params: SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListRequest,
  options: AdditionalQueryOptions<SellerSellerAnalyticDetailDetectedListingProtocolsExportProgressListingStatusListResponse>
) =>
  useQuery(
    [SellerDetailDetectedQueryKey, 'export', 'progress', params],
    async () => {
      const { data } = await sellerAnalyticsDetailDetectedApi.detailListingStatusDetectedExportProgressCreate(params);
      return data;
    },
    options
  );
//#endregion

//#region utils
export const invalidateDetectedQueries = () => {
  queryClient.invalidateQueries([...SellerDetailDetectedQueryKey, 'list']);
  queryClient.invalidateQueries([...SellerDetailDetectedQueryKey, 'count']);
};
//#endregion
