import { createTheme } from '@material-ui/core/styles';
import { MDSTheme } from '@marqvision/mds/core';
import { IcoArrowDownOutline } from '@marqvision/mds/assets';
import '@marqvision/mds/css/fonts.css';

export const CustomColor = {
  snow1: '#ffffff',
  snow2: '#f7f9fc',
  snow3: '#edf1f7',
  snow4: '#e4e9f2',
  snow5: '#bec5d3',
  snow6: '#8f9bb3',
  snow7: '#5b6985',
  snow8: '#2e3a59',
  snow9: '#02091b',
  blue1: '#e6edff',
  blue2: '#bfd0ff',
  blue3: '#84a5ff',
  blue4: '#356bff',
  blue5_primary: '#003de6',
  blue6: '#0033bf',
  blue7: '#002898',
  blue8: '#001e70',
  blue9: '#001349',
  alert: '#fa002e',
  yellow: '#f5B000',
} as const;

const Breakpoint = {
  tablet: 768,
  mobile: 576,
} as const;

type CustomColorsType = {
  [K in keyof typeof CustomColor]: (typeof CustomColor)[K];
};

declare module '@material-ui/core/styles/createPalette' {
  interface Palette extends CustomColorsType {}
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    medium: any;
    regular: any;
    label: any;
  }

  interface TypographyOptions {
    medium: any;
    regular: any;
    label: any;
  }
}

declare module '@material-ui/core/styles' {
  interface Theme {
    breakpoint: typeof Breakpoint;
  }

  interface ThemeOptions {
    breakpoint: typeof Breakpoint;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1f1f1f',
      contrastText: MDSTheme.palette.white,
    },
    secondary: {
      main: MDSTheme.palette.bluegray[900],
      contrastText: '#f9f9f9',
    },
    ...CustomColor,
  },
  typography: {
    ...MDSTheme.typography,
    h2: {
      fontSize: 24,
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontStretch: 'normal',
      letterSpacing: 0.25,
    },
    h6: {
      color: '#fff',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1,
    },
    label: {
      height: 13,
      fontSize: 13,
      lineHeight: 'normal',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
    },
    ...MDSTheme.typography.regular,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: 'Visuelt-Regular',
        fontSize: 14,
        color: MDSTheme.palette.bluegray[900],
      },
      h1: {
        color: MDSTheme.palette.bluegray[900],
      },
      h2: {
        color: MDSTheme.palette.bluegray[900],
      },
      h3: {
        color: MDSTheme.palette.bluegray[900],
      },
      h4: {
        color: MDSTheme.palette.bluegray[900],
      },
      h5: {
        color: MDSTheme.palette.bluegray[900],
      },
      h6: {
        color: MDSTheme.palette.bluegray[900],
      },
    },
    MuiTabs: {
      root: {
        minHeight: 'auto',
        marginLeft: 24,
        marginBottom: 10,
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        minWidth: 'auto !important',
        minHeight: 'auto !important',
        marginRight: 20,
        padding: 0,
        textTransform: 'unset',
        '&$selected': {
          color: `${MDSTheme.palette.blue[700]} !important`,
        },
      },
      textColorInherit: {
        color: MDSTheme.palette.bluegray[1000],
        opacity: 1,
      },
      wrapper: {
        fontFamily: 'Visuelt-Medium',
        fontSize: 14,
        fontWeight: 500,
      },
    },
    MuiTabPanel: {
      root: {
        padding: 0,
        height: '100%',
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Visuelt-Medium',
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 0.2,
        borderRadius: 2,
        boxShadow: 'none',
        outline: 'none',
        textTransform: 'none',
        color: MDSTheme.palette.bluegray[900],
        '&:hover': {
          color: MDSTheme.palette.bluegray[1000],
        },
      },
      text: {
        paddingTop: '5px',
        paddingBottom: '7px',
      },
    },
    MuiButtonGroup: {
      grouped: {
        minWidth: 'unset',
      },
      groupedOutlinedHorizontal: {
        '.MuiButtonGroup-root &': {
          borderRadius: 'inherit',
          margin: 'inherit',
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        color: '#808080',
      },
      indeterminate: {
        color: '#333333',
      },
    },
    MuiPaper: {
      root: {
        fontFamily: 'Visuelt-Regular',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 6,
        border: 'none',
        backgroundColor: MDSTheme.palette.white,
        boxShadow: '0 8px 20px 0 rgba(0, 19, 73, 0.12)',
      },
      paperWidthXs: {
        maxWidth: 370,
      },
      paperWidthSm: {
        maxWidth: 688,
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        '&:hover': {
          backgroundColor: undefined,
        },
      },
    },
    MuiBadge: {
      badge: {
        fontFamily: 'Visuelt-Medium',
        fontSize: 10,
        height: 16,
        minWidth: 16,
        borderRadius: 8,
        backgroundColor: '#2e3a59',
        color: '#ffffff',
        padding: '0 5px',
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
        fontSize: 14,
        borderRadius: 2,
        marginTop: 8,
        marginBottom: 5,
        '&$focused': {
          borderColor: MDSTheme.palette.blue[500],
        },
      },
      // @ts-ignore
      '& .MuiInputLabel-shrink': {
        display: 'none',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'Visuelt-Regular',
        fontSize: 14,
        lineHeight: 0.5,
      },
      shrink: {
        display: 'block',
        backgroundColor: MDSTheme.palette.white,
        padding: 3,
        '&$focused': {
          color: MDSTheme.palette.blue[600],
        },
        '&.Mui-error': {
          transition: 'none',
          color: MDSTheme.palette.red[700],
        },
      },
    },
    MuiSelect: {
      root: {
        fontFamily: 'Visuelt-Regular',
        fontWeight: 'normal',
        borderRadius: 2,
        height: '33px',
      },
      icon: {
        color: '#222b45',
        height: '100%',
        top: 0,
      },
      iconOutlined: {
        right: 12,
      },
      selectMenu: {
        lineHeight: '33px',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: `${MDSTheme.palette.bluegray[700]} !important`,
        flexDirection: 'unset',
      },
      icon: {
        display: 'none',
        color: `${MDSTheme.palette.bluegray[700]} !important`,
        opacity: '1 !important',
      },
      // @ts-ignore
      '& svg': {
        fill: `${MDSTheme.palette.bluegray[700]} !important`,
      },
    },
    MuiTableRow: {
      root: {
        '&.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: MDSTheme.palette.bluegray[50],
        },
      },
    },
    MuiTablePagination: {
      toolbar: {
        zIndex: 2,
      },
      input: {
        width: 'auto',
        height: 30,
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          color: MDSTheme.palette.red[700],
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        fontSize: 14,
        backgroundColor: MDSTheme.palette.bluegray[100],
        '&$focused': {
          backgroundColor: `${MDSTheme.palette.white} !important`,
          borderColor: MDSTheme.palette.blue[500],
        },
        '&.Mui-error': {
          borderColor: MDSTheme.palette.red[700],
        },
      },
      multiline: {
        width: '100%',
        fontSize: 14,
        backgroundColor: MDSTheme.palette.bluegray[100],
        '&:hover': {
          borderColor: MDSTheme.palette.bluegray[200],
          backgroundColor: `${MDSTheme.palette.white} !important`,
        },
        '&:focused': {
          backgroundColor: `${MDSTheme.palette.white} !important`,
          borderColor: MDSTheme.palette.blue[500],
        },
      },
      input: {
        color: MDSTheme.palette.bluegray[1000],
        borderRadius: 2,
        '&:hover, &:focus': {
          borderColor: MDSTheme.palette.bluegray[200],
          backgroundColor: `${MDSTheme.palette.white} !important`,
        },
        '&::placeholder': {
          color: `${MDSTheme.palette.bluegray[500]} !important`,
          opacity: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: MDSTheme.palette.bluegray[500],
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 2,
        border: `solid 1px ${MDSTheme.palette.bluegray[200]}`,
      },
      notchedOutline: {
        border: 0,
      },
      inputMarginDense: {
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 16,
        paddingRight: 16,
      },
      multiline: {
        alignItems: 'start',
        paddingTop: 10,
      },
      inputMultiline: {
        overflow: 'hidden',
        lineHeight: 1.5,
      },
      inputAdornedEnd: {
        minWidth: 'calc(100% - 2px)',
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 2,
      },
    },
    MuiMenuItem: {
      root: {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '12px',
        paddingBottom: '12px',
        ...MDSTheme.typography.T14,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: MDSTheme.palette.blue[50],
      },
    },
    MuiBreadcrumbs: {
      root: {
        position: 'absolute',
      },
      separator: {
        marginLeft: 2,
        marginRight: 2,
      },
      ol: {
        marginLeft: -6,
      },
      li: {
        padding: '4px 6px',
        maxWidth: 250,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&:hover': {
          borderRadius: 4,
          backgroundColor: 'rgba(0, 30, 112, 0.05)',
        },
        '& a': {
          color: `${MDSTheme.palette.bluegray[900]} !important`,
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiTooltip: {
      arrow: {
        color: MDSTheme.palette.bluegray[1000],
      },
      tooltip: {
        backgroundColor: `${MDSTheme.palette.bluegray[1000]}`,
        opacity: 0.9,
        borderRadius: 4,
        fontFamily: 'Visuelt-Medium',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        color: MDSTheme.palette.bluegray[200],
        padding: '4px 6px',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(2, 9, 27, 0.5)',
      },
    },
    MuiDialogTitle: {
      root: {
        '& svg': {
          width: 28,
          height: 28,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        minHeight: '0 !important',
        fontSize: 12,
        backgroundColor: MDSTheme.palette.red[700],
        borderRadius: 6,
      },
    },
    MuiDropzonePreviewList: {
      image: {
        minWidth: 'auto',
        height: 'auto',
        maxHeight: '100%',
        borderRadius: 6,
        boxShadow: 'none',
        margin: 'auto',
      },
      removeButton: {
        top: 0,
        right: -4,
        boxShadow: 'none',
        background: 'transparent !important',
        '& svg': {
          width: 24,
          height: 24,
        },
        '&:hover, &:focus': {
          opacity: 1,
        },
        '&:hover svg, &:focus svg': {
          opacity: 0.8,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        right: 10,
      },
    },
    MuiSwitch: {
      root: {
        width: 36,
        height: 22,
        padding: 0,
      },
      colorSecondary: {
        '&$checked': {
          color: MDSTheme.palette.white,
        },
      },
      switchBase: {
        padding: 2,
        color: MDSTheme.palette.white,
        '&$checked': {
          transform: 'translateX(14px)',
        },
      },
      thumb: {
        width: 18,
        height: 18,
        boxShadow: 'none',
      },
      track: {
        borderRadius: 16 / 1,
        opacity: 1,
        backgroundColor: MDSTheme.palette.bluegray[300],
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 1,
          backgroundColor: MDSTheme.palette.blue[500],
        },
      },
      checked: {},
    },
    MuiSkeleton: {
      root: {
        backgroundColor: 'rgba(81, 94, 107, 0.12)', // bluegray[750] 0.12%
      },
      pulse: {
        animation: 'skeleton-pulse 1.5s ease-in-out 0.5s infinite',
      },
    },
    MuiCssBaseline: {
      // common.css
      '@global': {
        html: {
          boxSizing: 'border-box',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
        body: {
          margin: 0,
          padding: 0,
          color: MDSTheme.palette.bluegray[900],
          ...MDSTheme.typography.T14,
          ...MDSTheme.typography.regular,
        },
        code: {
          fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
        },
        '.flexRow': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        '.spaceBetween': {
          justifyContent: 'space-between',
        },
        '.flexColumn': {
          display: 'flex',
          flexDirection: 'column',
        },
        '*::-webkit-scrollbar': {
          width: 8,
          height: 8,
          borderRadius: 4,
        },
        '*::-webkit-scrollbar-thumb': {
          visibility: 'hidden',
          opacity: 0,
          backgroundColor: MDSTheme.palette.bluegray[500],
          borderRadius: 4,
        },
        '*:hover::-webkit-scrollbar-thumb': {
          visibility: 'visible',
          opacity: 1,
        },
        'div[role="tooltip"]': {
          zIndex: 11111,
        },
        svg: {
          verticalAlign: 'middle',
          overflow: 'hidden',
        },
        '@keyframes skeleton-pulse': {
          '0%': {
            opacity: 1,
          },
          '50%': {
            opacity: 0.6,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiOutlinedInput: {
      labelWidth: 0,
      margin: 'dense',
    },
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application!
    },
    MuiSelect: {
      IconComponent: IcoArrowDownOutline,
    },
  },
  textInput: {
    border: '1px solid #e4e9f2',
    height: 36,
    width: '100%',
    fontSize: 14,
    borderRadius: 2,
    position: 'relative',
    minWidth: 184,
    maxWidth: 264,
    color: MDSTheme.palette.bluegray[1000],
    backgroundColor: MDSTheme.palette.bluegray[100],
    '&:hover': {
      borderColor: MDSTheme.palette.bluegray[200],
      backgroundColor: `${MDSTheme.palette.white} !important`,
    },
    '&:focus': {
      borderColor: MDSTheme.palette.blue[500],
      backgroundColor: `${MDSTheme.palette.white} !important`,
    },
    '&::placeholder': {
      color: `${MDSTheme.palette.bluegray[500]} !important`,
      opacity: 1,
    },
  },
  breakpoint: {
    ...Breakpoint,
  },
  drawerWidth: 150,
  direction: 'ltr',
});
