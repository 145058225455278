import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import MDSTableBody from './TableBody';
import MDSTableRow from './TableRow';
import MDSTableHead from './TableHead';
import MDSTableCell from './TableCell';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: '100%',
    position: 'relative',
    borderCollapse: 'unset',
    borderSpacing: '0',
    '&.isStickyLeft': {
      '& td:first-child': {
        position: 'sticky',
        left: '0',
        zIndex: 1,
        boxShadow: '5px 0px 5px -2px rgba(0, 0, 0, 0.06)',
        padding: '12px 16px',
      },
      '& td:nth-of-type(2)': {
        // sticky 컬럼의 바로 다음 컬럼
        paddingLeft: '16px',
      },
    },
    '&.isStickyRight': {
      '& td:last-child': {
        position: 'sticky',
        right: '0',
        zIndex: 1,
        boxShadow: '-5px 0px 5px -2px rgba(0, 0, 0, 0.06)',
        padding: '12px 16px',
      },
      '& td:nth-last-of-type(2)': {
        // sticky 컬럼의 바로 이전 컬럼
        paddingRight: '16px',
      },
    },
  },
}));

interface ITableProps {
  isStickyLeft?: boolean;
  isStickyRight?: boolean;
}

const MDSTable = (props: ITableProps & HTMLAttributes<HTMLTableElement>): React.ReactNode => {
  const { isStickyLeft, isStickyRight, className, children, ...restProps } = props;
  const classes = useStyles();

  return (
    <table className={clsx(classes.table, { isStickyLeft, isStickyRight }, className)} {...restProps}>
      {children}
    </table>
  );
};

export { MDSTable, MDSTableBody, MDSTableRow, MDSTableHead, MDSTableCell };
