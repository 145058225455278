import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { MDSTooltip } from '@marqvision/mds/core';
import { MDSThemeValue, MDSTypography, MDSTypographyProps } from '@marqvision/mds-v2';

const useStyles = makeStyles(() => ({
  tooltipBox: {
    width: ({ width }: { width?: string }) => width,
  },
  text: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '0',
      bottom: '0',
      width: '100%',
      height: '1px',
      backgroundImage: () => {
        const strokeColor = MDSThemeValue.color.border.neutral.strong.normal.replace('#', '%23');
        const svg = `<svg viewBox="0 0 500 1" xmlns="http://www.w3.org/2000/svg"><line x2="500" stroke="${strokeColor}" stroke-width="100" stroke-dasharray="2" /></svg>`;
        return `url('data:image/svg+xml;utf8,${svg}')`;
      },
      backgroundSize: 'cover',
    },
  },
}));

type Props = {
  width?: string;
  tooltipTitle: React.ReactElement | string;
} & MDSTypographyProps;

export const UnderlineTooltip = (props: React.PropsWithChildren<Props>) => {
  const { tooltipTitle, children, width, ...restProps } = props;
  const classes = useStyles({ width });

  return (
    <div>
      <MDSTooltip classes={{ tooltip: classes.tooltipBox }} title={tooltipTitle} placement="bottom">
        <MDSTypography className={classes.text} {...restProps}>
          {children}
        </MDSTypography>
      </MDSTooltip>
    </div>
  );
};
