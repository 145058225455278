import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';
import { MDSThemeValue } from '@marqvision/mds-v2';
import { useWrapperSize } from '../../../hooks';
import { ExtendedStackedBarChartDataItem, StackedBarChartDataItem, Tooltip } from './@types';
import { ChartTooltip } from './ChartTooltip';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    '& svg': {
      display: 'block',
    },
  },
  skeleton: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  },
  noData: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: MDSThemeValue.color.bg.surface.neutral.tertiary.normal,
  },
});

type Props = {
  data: StackedBarChartDataItem[];
  isLoading: boolean;
  tooltipTitle: string;
};

export const StackedBarChart = (props: Props) => {
  const { data, isLoading, tooltipTitle } = props;
  const classes = useStyles();

  const { wrapperRef, width, height } = useWrapperSize();

  const [tooltip, setTooltip] = useState<Tooltip | null>(null);

  const denominator = data.reduce<number>((prev, cur) => prev + cur.value, 0);
  const chartData: ExtendedStackedBarChartDataItem[] = data.reduce<ExtendedStackedBarChartDataItem[]>(
    (prev, cur, index) => {
      const prevItem = prev.at(index - 1);

      return [
        ...prev,
        {
          ...cur,
          width: (cur.value / denominator) * width,
          x: prevItem ? prevItem.x + prevItem.width : 0,
        },
      ];
    },
    []
  );

  const handleMouseOver = (data: ExtendedStackedBarChartDataItem) => (event: React.MouseEvent<SVGRectElement>) => {
    const wrapperDOMRect = wrapperRef.current?.getClientRects()?.[0];

    setTooltip({
      left: event.clientX,
      top: wrapperDOMRect ? wrapperDOMRect.y + wrapperDOMRect.height : 0,
      data,
    });
  };

  const handleMouseOut = () => {
    setTooltip(null);
  };

  const tooltipEvents = (data: ExtendedStackedBarChartDataItem) => ({
    onMouseOver: handleMouseOver(data),
    onMouseMove: handleMouseOver(data),
    onMouseOut: handleMouseOut,
  });

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      {isLoading ? (
        <Skeleton variant="rect" className={classes.skeleton} />
      ) : !chartData.length ? (
        <div className={classes.noData} />
      ) : (
        <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
          <defs>
            <mask id="stacked_bar_chart_mask">
              <g>
                <rect width={width} height={height} fill="black" />
                <rect width={width} height={height} fill="white" rx="8" />
              </g>
            </mask>
          </defs>
          <g mask="url(#stacked_bar_chart_mask)">
            {chartData.map((item) => (
              <rect
                key={item.label}
                x={item.x}
                width={item.width}
                height={height}
                fill={item.color}
                {...tooltipEvents(item)}
              />
            ))}
          </g>
        </svg>
      )}

      {tooltip && <ChartTooltip title={tooltipTitle} {...tooltip} />}
    </div>
  );
};
