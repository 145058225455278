/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MonitoringFilterProtocolResponse,
  SellerSellerAnalyticSellerInClusterProtocolsExportExcelRequest,
  SellerSellerAnalyticSellerInClusterProtocolsExportExcelResponse,
  SellerSellerAnalyticSellerInClusterProtocolsGetChannelDomainFilterRequest,
  SellerSellerAnalyticSellerInClusterProtocolsGetSellerCountRequest,
  SellerSellerAnalyticSellerInClusterProtocolsGetSellerCountResponse,
  SellerSellerAnalyticSellerInClusterProtocolsGetSellerListRequest,
  SellerSellerAnalyticSellerInClusterProtocolsGetSellerListResponse,
  SellerSellerAnalyticSellerInClusterProtocolsProgressExcelRequest,
  SellerSellerAnalyticSellerInClusterProtocolsProgressExcelResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SellerAnalyticsSellerInCluster<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SellerAnalyticsSellerInCluster
   * @name ClusterSellerFilterChannelDomainsCreate
   * @request POST:/seller-analytic/cluster/seller/filter/channel-domains
   */
  clusterSellerFilterChannelDomainsCreate = (
    data: SellerSellerAnalyticSellerInClusterProtocolsGetChannelDomainFilterRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/cluster/seller/filter/channel-domains`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsSellerInCluster
   * @name ClusterSellerGetListCreate
   * @request POST:/seller-analytic/cluster/seller/get_list
   */
  clusterSellerGetListCreate = (
    data: SellerSellerAnalyticSellerInClusterProtocolsGetSellerListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticSellerInClusterProtocolsGetSellerListResponse, any>({
      path: `/seller-analytic/cluster/seller/get_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsSellerInCluster
   * @name ClusterSellerCountCreate
   * @request POST:/seller-analytic/cluster/seller/count
   */
  clusterSellerCountCreate = (
    data: SellerSellerAnalyticSellerInClusterProtocolsGetSellerCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticSellerInClusterProtocolsGetSellerCountResponse, any>({
      path: `/seller-analytic/cluster/seller/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsSellerInCluster
   * @name ClusterSellerExportCreate
   * @request POST:/seller-analytic/cluster/seller/export
   */
  clusterSellerExportCreate = (
    data: SellerSellerAnalyticSellerInClusterProtocolsExportExcelRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticSellerInClusterProtocolsExportExcelResponse, any>({
      path: `/seller-analytic/cluster/seller/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsSellerInCluster
   * @name ClusterSellerExportProgressCreate
   * @request POST:/seller-analytic/cluster/seller/export/progress
   */
  clusterSellerExportProgressCreate = (
    data: SellerSellerAnalyticSellerInClusterProtocolsProgressExcelRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticSellerInClusterProtocolsProgressExcelResponse, any>({
      path: `/seller-analytic/cluster/seller/export/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
