import { MarqBrandEnumsListingMonitoringStatusEnum } from '~/swagger/data-contracts';

export const getLogArchivedStatusTitle = (tab?: MarqBrandEnumsListingMonitoringStatusEnum) => {
  let archivedStatusLogTitle = '';
  switch (tab) {
    case MarqBrandEnumsListingMonitoringStatusEnum.Dismissed:
      archivedStatusLogTitle = 'Dismissed';
      break;
    case MarqBrandEnumsListingMonitoringStatusEnum.Later:
      archivedStatusLogTitle = 'Review later';
      break;
    case MarqBrandEnumsListingMonitoringStatusEnum.Gray:
      archivedStatusLogTitle = 'Gray market listing';
      break;
    default:
      archivedStatusLogTitle = '';
  }
  return archivedStatusLogTitle;
};
