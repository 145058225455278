import axios from 'axios';
import { QueryClientConfig } from '@tanstack/query-core';

const isRetry = (failureCount: number, error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (!error.response) return false;
    // 401, 408, 504 에러인 경우 재시도
    return [401, 408, 504].includes(error.response?.status) && failureCount < 3;
  }
  return failureCount < 3;
};

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: isRetry,
    },
    mutations: {
      retry: isRetry,
    },
  },
};
