/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyResponse,
  MarqCommonProtocolFileResponse,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsExcelResultRequest,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsExcelResultResponse,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetAllWebsiteIdListRequest,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetAllWebsiteIdListResponse,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountAndRecentlyStatusRequest,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountAndRecentlyStatusResponse,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountRequest,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountResponse,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetExportListingsByListingIdRequest,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetExportListingsRequest,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetExportListingsResponse,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetGridWebsiteListRequest,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetGridWebsiteListResponse,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetTableWebsiteListRequest,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetTableWebsiteListResponse,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetWebsiteDetailRequest,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetWebsiteDetailResponse,
  MonitoringWebsiteSWEnforcedReportAbuseProtocolsRequestUDRPRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringWebsiteEnforcedReportAbuse<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseStatusCountCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/status-count
   */
  enforcementWebSiteReportAbuseStatusCountCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountAndRecentlyStatusRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountAndRecentlyStatusResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/status-count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseCountCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/count
   */
  enforcementWebSiteReportAbuseCountCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetCountResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseGridGetListCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/grid/get-list
   */
  enforcementWebSiteReportAbuseGridGetListCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetGridWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetGridWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/grid/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseTableGetListCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/table/get-list
   */
  enforcementWebSiteReportAbuseTableGetListCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetTableWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetTableWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/table/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseDetailsCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/details
   */
  enforcementWebSiteReportAbuseDetailsCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetWebsiteDetailRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetWebsiteDetailResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseGetIdListCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/get-id-list
   */
  enforcementWebSiteReportAbuseGetIdListCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetAllWebsiteIdListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetAllWebsiteIdListResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/get-id-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseRequestCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/request
   */
  enforcementWebSiteReportAbuseRequestCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsRequestUDRPRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/request`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseExcelCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/excel
   */
  enforcementWebSiteReportAbuseExcelCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetExportListingsResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/excel`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseExcelResultCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/excel/result
   */
  enforcementWebSiteReportAbuseExcelResultCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedReportAbuseProtocolsExcelResultResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/excel/result`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedReportAbuse
   * @name EnforcementWebSiteReportAbuseExcelListingIdCreate
   * @request POST:/monitoring/enforcement/web-site/report-abuse/excel/listing-id
   */
  enforcementWebSiteReportAbuseExcelListingIdCreate = (
    data: MonitoringWebsiteSWEnforcedReportAbuseProtocolsGetExportListingsByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/web-site/report-abuse/excel/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
