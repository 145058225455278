/**
 * 사용법과 예제코드: https://bitbucket.org/marqvision/marqvision-web/pull-requests/2353
 */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { type MDSThemeColorPath, resolveColor } from '@marqvision/mds-v2';

type ItemStyleProps = {
  // point 와 content 간의 gap
  columnGap: number;
  // item 별 bullet point 의 사이즈
  size: number;
  // item 별 bullet point 의 색상
  color: MDSThemeColorPath;
  // item 별 line 의 색상
  lineColor: MDSThemeColorPath;
};

const useItemStyles = makeStyles(() => ({
  item: {
    position: 'relative',
    display: 'flex',
    columnGap: ({ columnGap }: ItemStyleProps) => `${columnGap}px`,

    '& .pointWrapper': {
      display: 'grid',
      placeItems: 'center',
      height: '100%',
      zIndex: 1,

      '& .point': {
        display: 'grid',
        placeItems: 'center',
        width: ({ size }: ItemStyleProps) => `${size}px`,
        height: ({ size }: ItemStyleProps) => `${size}px`,
        borderRadius: '50%',
        backgroundColor: ({ color }: ItemStyleProps) => resolveColor(color),
      },
    },

    '&:after': {
      content: `''`,
      position: 'absolute',
      paddingBottom: ({ size }: ItemStyleProps) => `${size / 2}px`,
      transform: ({ size }: ItemStyleProps) => `translate(-50%, ${size / 2}px)`,
      backgroundColor: ({ lineColor }: ItemStyleProps) => resolveColor(lineColor),
      backgroundClip: 'content-box',
    },

    '&:last-child:after': {
      display: 'none',
    },
  },
}));

type ItemProps =
  | {
    variant: 'icon';
    // point 와 content 간의 gap
    columnGap?: number;
    // item 별 bullet point 의 아이콘
    icon: React.ReactElement;
    // item 별 line 의 색상
    lineColor?: MDSThemeColorPath;
  }
  | ({
    variant: 'point';
  } & Partial<ItemStyleProps>);

const Item = (props: React.PropsWithChildren<ItemProps>) => {
  const { variant, columnGap = 6, children } = props;
  const classes = useItemStyles({
    columnGap,
    size: (variant === 'point' ? props.size : props.icon.props.size) || 7,
    color: (variant === 'point' && props.color) || 'color/border/neutral/strong/normal',
    lineColor: props.lineColor || 'color/border/neutral/weak/normal',
  });

  return (
    <div className={classes.item}>
      <div className="pointWrapper">
        {variant === 'icon' && props.icon}
        {variant === 'point' && <i className="point"></i>}
      </div>
      {children}
    </div>
  );
};

type WrapperStyleProps = {
  rowGap: number;
  thickness: number;
  pointWrapperWidth: number;
  pointWrapperHeight: number;
  alignItems: 'start' | 'center';
};

const useWrapperStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: ({ rowGap }: WrapperStyleProps) => `${rowGap}px`,

    '& .pointWrapper': {
      width: ({ pointWrapperWidth }: WrapperStyleProps) => `${pointWrapperWidth}px`,
      height: ({ pointWrapperHeight }: WrapperStyleProps) => `${pointWrapperHeight}px`,
    },

    '&>div': {
      alignItems: ({ alignItems }: WrapperStyleProps) => alignItems,
      '&:after': {
        width: ({ thickness }: WrapperStyleProps) => `${thickness}px`,
        height: ({ rowGap }: WrapperStyleProps) => `calc(100% + ${rowGap}px)`,
        top: ({ alignItems, pointWrapperHeight }: WrapperStyleProps) =>
          alignItems === 'center' ? '50%' : `${pointWrapperHeight / 2}px`,
        left: ({ pointWrapperWidth }: WrapperStyleProps) => `${pointWrapperWidth / 2}px`,
      },
    },
  },
}));

type WrapperProps = {
  // item 간의 gap
  rowGap?: number;
  // line 굵기
  thickness?: number;
  // 아이템 별 bullet point 가 각기 다른 사이즈를 가질 수 있음 + 라인 위치 설정을 위해 wrapper 가 필요함
  // number 로 지정 시 정사각형을 그리며, width height 를 다르게 지정해 bullet point 의 위치를 조정할 수 있음
  pointWrapperSize: number | { width: number; height: number };
  // 아이템 별 bullet point 위치
  alignItems?: 'start' | 'center';
};

const Wrapper = (props: React.PropsWithChildren<WrapperProps>) => {
  const { rowGap = 16, thickness = 1, pointWrapperSize, alignItems = 'center', children } = props;
  const pointWrapperStyle = getPointWrapperStyle(pointWrapperSize);
  const classes = useWrapperStyles({ rowGap, thickness, alignItems, ...pointWrapperStyle });

  return <div className={classes.wrapper}>{children}</div>;
};

export const Stepper = { Wrapper, Item };

const getPointWrapperStyle = (pointWrapperSize: number | { width: number; height: number }) => {
  return typeof pointWrapperSize === 'number'
    ? { pointWrapperWidth: pointWrapperSize, pointWrapperHeight: pointWrapperSize }
    : { pointWrapperWidth: pointWrapperSize.width, pointWrapperHeight: pointWrapperSize.height };
};
