import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import {
  MarqBrandCoreModelClientListingSortedColumnEnum,
  MarqCommonEnumsCurrencyEnum,
  MarqCommonEnumsSortedOrderEnum,
  MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerRequest,
  MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerResponse,
  MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdRequest,
  MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdResponse,
  MonitoringDetectedBySellerProtocolsGetSellerDetailsRequest,
  MonitoringDetectedListingViewProtocolsGetDetectedListingsRequest,
  MonitoringDetectedListingViewProtocolsGetDetectedListingsResponse,
  MonitoringDetectedListingViewProtocolsGetOldDetectedListingsResponse,
  MonitoringDetectedProtocolsGetDetectedCountByDomainTypeRequest,
  MonitoringFilterProtocolRequest,
} from '~/swagger/data-contracts';
import { monitoringDetectedApi, monitoringFilterApi } from '~/apis';
import { SellerDetailDetectedQueryKey } from '~/apis/sellerDetail/listingStatus/detected';
import { setAuth, useAuth } from '~/apis/auth';
import { post } from '~/apis/base';
import { DomainTypeEnum } from '~/types/common/DomainTypeEnum';
import { IListing, ISellerCountList, TListingSortedColumn, TSellerSortedColumn } from '~/types/detected';
import { parseFilterDropDown } from '~/utils';
import { useGetFilterParams } from '~/components/Filters/@hooks';
import { useClientBrandIds } from '~/pages/Enforcement/@hooks';

export const QueryKeyDetected = 'detected';

export interface IRequestActiveRequest {
  domain_type: DomainTypeEnum;
}

const prefix = '/monitoring/detected';
/**
 * @deprecated
 */
export type SortOrder = 'asc' | 'desc';

export type EnforcementResponse = {
  affected: number;
  remaining: number;
  success: boolean;
  listing_image_list?: string[] | null;
  listing_title?: string | null;
  request_id?: number | null;
};

interface IAllListingIdResponse {
  listing_id_list: number[];
  total_count: number;
}

interface IGetListingRequest {
  asset_id_filter: number[];
  client_requested_filter?: boolean;
  currency?: string;
  domain_type: DomainTypeEnum[];
  flag_id_filter: number[];
  domain_id_filter: number[];
  max_price?: number;
  min_price?: number;
  official_image_id_filter: number[];
  repeat_offender_filter?: boolean;
  search?: string;
  seller_name_filter: string[];
  top_result_filter?: boolean;
  tm_infringement_filter?: boolean;
  tm_infringement_text_filter?: boolean;
  infringing_images_filter?: boolean;
  low_price_filter?: boolean;
  gen_ai_counterfeit_filter?: boolean;
  model_id_filter: number[];
}

export interface IGetDetectedListRequest extends IGetListingRequest {
  brand_id: number | null;
  limit: number;
  offset: number;
  sorted_column: TListingSortedColumn;
  sorted_order: SortOrder;
  search_date_filter: Dayjs;
}

export interface IGetSellerDetectedListRequest extends Omit<IGetDetectedListRequest, 'search_date_filter'> {
  seller_sorted_column: TSellerSortedColumn;
  seller_sorted_order: SortOrder;
}

export interface IGetDetectedListResponse {
  asset_exists: boolean;
  listings: IListing[];
}

export interface IGetDetectedTotalResponse {
  total_count: number;
}

export interface IGetSellerCountRequest extends IGetListingRequest {
  seller_sorted_column: TSellerSortedColumn;
  seller_sorted_order: SortOrder;
  limit: number;
  offset: number;
}

export interface IGetSellerListingRequest extends IGetListingRequest {
  seller_id: number | null;
  sorted_column: TListingSortedColumn;
  sorted_order: SortOrder;
}

export interface ISellerListing {
  domain_id: number;
  domain_name: string;
  listing_list: IListing[];
  seller_page_url: string;
  snapshot_path: string;
  total_count: number;
}

export interface IGetSellerCountResponse {
  seller_count_list: ISellerCountList[];
  total_count: number;
}

export interface ISellerDetail {
  seller_id: string;
  business_reg_number: string;
  clustered: boolean;
  company_address: string;
  company_name: string;
  denied_listing_count: number;
  domain_id: number;
  domain_name: string;
  email: string;
  online_market_number: string;
  other_info: string;
  phone_number: string;
  repeat_offender: boolean;
  representative_name: string;
  seller_name: string;
  denied_count: number;
  detected_count: number;
  enforced_count: number;
  investigated_count: number;
  not_reportable_count: number;
  on_standby_count: number;
  waiting_investigation_count: number;
  waiting_report_count: number;
}

export interface IGetSellerDetailRequest {
  seller_id: number;
}

export interface IGetSellerDetailResponse {
  seller_detail: ISellerDetail;
}

export const get_all_listing_id = (request: IGetDetectedListRequest): Promise<AxiosResponse<IAllListingIdResponse>> =>
  post(prefix + '/get_all_listing_id', request);

const sellerPrefix = prefix + '/seller';

export const get_seller_all_listing_id = (
  request: IGetSellerDetectedListRequest
): Promise<AxiosResponse<IAllListingIdResponse>> => post(sellerPrefix + '/get_all_listing_id', request);

export const request_active = (request: IRequestActiveRequest): Promise<AxiosResponse<void>> =>
  post('/monitoring/detected/request-active', request);

export const useDetectedCountDomainTypeQuery = (
  request: MonitoringDetectedProtocolsGetDetectedCountByDomainTypeRequest
) =>
  useQuery([QueryKeyDetected, 'domain-type-count', request], async () => {
    const { data } = await monitoringDetectedApi.detectedCountDomainTypeCreate(request);
    return data;
  });

export const useDetectedCountQuery = (
  request: IGetDetectedListRequest,
  { enabled = true } = {}
): UseQueryResult<IGetDetectedTotalResponse, unknown> => {
  const { brandId } = useClientBrandIds();
  const { legacyRequestFilterParams } = useGetFilterParams();
  return useQuery(
    [QueryKeyDetected, 'count', { ...request, ...legacyRequestFilterParams }],
    async () => {
      const { data } = await monitoringDetectedApi.detectedCountCreate({
        ...request,
        ...legacyRequestFilterParams,
        brand_id: brandId,
        currency: request.currency as MarqCommonEnumsCurrencyEnum,
      });
      return data;
    },
    { enabled }
  );
};

export const useReviewList = (request: IGetDetectedListRequest) => {
  const { brandId, clientId } = useClientBrandIds();
  const { params, legacyRequestFilterParams } = useGetFilterParams();

  return useReviewListQuery(
    {
      ...request,
      ...legacyRequestFilterParams,
      brand_id: brandId,
      search_date_filter: request.search_date_filter.toISOString(),
      sorted_column: request.sorted_column as MarqBrandCoreModelClientListingSortedColumnEnum,
      sorted_order: request.sorted_order as MarqCommonEnumsSortedOrderEnum,
      currency: request.currency as MarqCommonEnumsCurrencyEnum,
    },
    {
      enabled: !!params.domain_type,
    }
  );
};

export const useReviewListQuery = (
  request: MonitoringDetectedListingViewProtocolsGetDetectedListingsRequest,
  { enabled = true } = {}
): UseInfiniteQueryResult<MonitoringDetectedListingViewProtocolsGetDetectedListingsResponse, unknown> => {
  return useInfiniteQuery(
    [QueryKeyDetected, 'list', request],
    async ({ pageParam }) => {
      const { data } = await monitoringDetectedApi.detectedGetReviewListCreate({
        ...request,
        offset: pageParam || request.offset,
      });
      return data;
    },
    {
      enabled,
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.listings.length < 100) {
          return;
        }

        return request.limit * allPage.length;
      },
    }
  );
};

export const useOldFeaturedReviewListQuery = (
  request: IGetDetectedListRequest,
  enabled: boolean
): UseInfiniteQueryResult<MonitoringDetectedListingViewProtocolsGetOldDetectedListingsResponse, unknown> => {
  const { brandId, clientId } = useClientBrandIds();
  const { legacyRequestFilterParams } = useGetFilterParams();
  return useInfiniteQuery(
    [QueryKeyDetected, 'old-list', request],
    async ({ pageParam }) => {
      const { data } = await monitoringDetectedApi.detectedGetOldFeaturedReviewListCreate({
        ...request,
        ...legacyRequestFilterParams,
        brand_id: brandId,
        search_date_filter: request.search_date_filter.toISOString(),
        sorted_column: request.sorted_column as MarqBrandCoreModelClientListingSortedColumnEnum,
        sorted_order: request.sorted_order as MarqCommonEnumsSortedOrderEnum,
        currency: request.currency as MarqCommonEnumsCurrencyEnum,
        offset: pageParam || request.offset,
      });
      return data;
    },
    {
      enabled,
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.listings.length < 100) {
          return;
        }

        return request.limit * allPage.length;
      },
    }
  );
};

export const useSellerCountQuery = (
  request: MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerRequest
): UseInfiniteQueryResult<MonitoringDetectedBySellerProtocolsGetDetectedCountBySellerResponse, unknown> => {
  return useInfiniteQuery(
    [QueryKeyDetected, 'seller-count', request],
    async ({ pageParam }) => {
      const { data } = await monitoringDetectedApi.detectedSellerCountCreate({
        ...request,

        offset: pageParam || request.offset,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.seller_count_list.length < 100) {
          return;
        }

        return request.limit * allPage.length;
      },
    }
  );
};

export const useSellerListingQuery = (
  request: MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdRequest,
  enabled: boolean
): UseQueryResult<MonitoringDetectedBySellerProtocolsGetDetectedListBySellerIdResponse, unknown> => {
  return useQuery(
    [QueryKeyDetected, 'seller-listing', request.seller_id, request],
    async () => {
      const { data } = await monitoringDetectedApi.detectedSellerGetAllListingCreate(request);
      return data;
    },
    {
      enabled,
    }
  );
};

export const useGetAllRules = (request: MonitoringFilterProtocolRequest) => {
  return useQuery(
    [QueryKeyDetected, 'get-all-rules', request],
    async () => {
      const { data } = await monitoringFilterApi.filterDetectionGetAllRulesCreate(request);
      return data;
    },
    {
      select: parseFilterDropDown,
      enabled: !!request.domain_type && !!request.client_status_filter,
    }
  );
};

export const useSellerDetailQuery = (request: MonitoringDetectedBySellerProtocolsGetSellerDetailsRequest) => {
  return useQuery([QueryKeyDetected, 'seller-detail', request], async () => {
    const { data } = await monitoringDetectedApi.detectedSellerSellerDetailCreate(request);
    return data;
  });
};

export const useCloseDetectedTipMutation = () => {
  const { data: auth } = useAuth();
  return useMutation(monitoringDetectedApi.detectedCloseTipCreate, {
    onSuccess: () => {
      if (auth) {
        setAuth({
          ...auth,
          detected_tip: false,
        });
      }
    },
  });
};

export const useTestPurchaseMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(monitoringDetectedApi.detectedTpCreate, {
    onSettled: () => {
      queryClient.invalidateQueries([QueryKeyDetected, 'list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'count']);
      queryClient.invalidateQueries([QueryKeyDetected, 'old-list']);
      queryClient.invalidateQueries([QueryKeyDetected, 'seller-count']);
      queryClient.invalidateQueries([QueryKeyDetected, 'domain-type-count']);

      queryClient.invalidateQueries([...SellerDetailDetectedQueryKey, 'list']); //TODO: 사용처에서 직접 전달하도록 해야할 듯 [morgan]
      queryClient.invalidateQueries([...SellerDetailDetectedQueryKey, 'count']);
    },
  });
};

export const useRequestActiveMutation = () => useMutation(request_active);
export const useDetectionExcelExportMutation = () => useMutation(monitoringDetectedApi.detectedExcelExportCreate);
export const useDetectionExcelExportProgressMutation = () =>
  useMutation(monitoringDetectedApi.detectedExcelProgressCreate);
export const useDetectionListingIdExcelExportMutation = () =>
  useMutation(monitoringDetectedApi.detectedExcelExportListingIdCreate);
export const useDetectionSellerExcelExportMutation = () =>
  useMutation(monitoringDetectedApi.detectedSellerExcelExportCreate);
export const useDetectionSellerExcelExportProgressMutation = () =>
  useMutation(monitoringDetectedApi.detectedSellerExcelProgressCreate);
export const useDetectionSellerListingIdExcelExportMutation = () =>
  useMutation(monitoringDetectedApi.detectedSellerExcelExportListingIdCreate);
