type ValidFunction = (value: unknown) => {
  isValid: boolean;
  message: string;
};
/**
 * keyword, seller search validation
 * @param search
 */
export const isValidSearch: ValidFunction = (search: unknown) => {
  let _search = search;
  if (typeof search === 'number') {
    _search = search.toString();
  }

  if (typeof _search !== 'string') {
    return {
      isValid: false,
      message: 'Keyword must be a string.',
    };
  }
  if (_search.trim().length > 100) {
    return {
      isValid: false,
      message: 'Keyword must not exceed 100 characters.',
    };
  }
  return {
    isValid: true,
    message: '',
  };
};
