import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { MDSButton, MDSTheme, MDSTypography } from '@marqvision/mds/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    minHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    paddingBottom: '24px',
  },
  errorCode: {
    fontSize: '160px !important',
    fontWeight: '900 !important' as 'bold',
    lineHeight: '1.3 !important',
  },
}));

export const NotFoundBox = (): React.ReactNode => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className={classes.wrapper}>
      <MDSTypography variant="T40" weight="bold" color={MDSTheme.palette.blue[100]} className={classes.errorCode}>
        404
      </MDSTypography>
      <div className={classes.textWrapper}>
        <MDSTypography variant="T24" weight="bold" color={MDSTheme.palette.bluegray[900]}>
          Page not found
        </MDSTypography>
        <MDSTypography variant="T14" weight="regular" color={MDSTheme.palette.bluegray[900]}>
          The page you are looking for does not exist. It might have been moved or deleted.
        </MDSTypography>
      </div>
      <MDSButton variant="fill" color="blue" size="large" onClick={handleBackToHome}>
        Back to Home
      </MDSButton>
    </div>
  );
};