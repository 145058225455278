/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyRequest,
  MarqCommonProtocolFileResponse,
  MonitoringEnforcementTPProtocolsExcelExportByListingIdRequest,
  MonitoringEnforcementTPProtocolsExcelExportRequest,
  MonitoringEnforcementTPProtocolsExcelExportResponse,
  MonitoringEnforcementTPProtocolsExcelResultRequest,
  MonitoringEnforcementTPProtocolsExcelResultResponse,
  MonitoringEnforcementTPProtocolsGetAllListingIdRequest,
  MonitoringEnforcementTPProtocolsGetAllListingIdResponse,
  MonitoringEnforcementTPProtocolsGetCountAndRecentlyListingResponse,
  MonitoringEnforcementTPProtocolsGetEnforcementTPListingsRequest,
  MonitoringEnforcementTPProtocolsGetEnforcementTPListingsResponse,
  MonitoringEnforcementTPProtocolsGetExportListingsRequest,
  MonitoringEnforcementTPProtocolsGetNoteDownloadUrlRequest,
  MonitoringEnforcementTPProtocolsGetNoteDownloadUrlResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringEnforcementTp<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringEnforcementTP
   * @name EnforcementTpCountCreate
   * @request POST:/monitoring/enforcement/tp/count
   */
  enforcementTpCountCreate = (data: MarqCommonProtocolEmptyRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringEnforcementTPProtocolsGetCountAndRecentlyListingResponse, any>({
      path: `/monitoring/enforcement/tp/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementTP
   * @name EnforcementTpGetReviewListCreate
   * @request POST:/monitoring/enforcement/tp/get_review_list
   */
  enforcementTpGetReviewListCreate = (
    data: MonitoringEnforcementTPProtocolsGetEnforcementTPListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementTPProtocolsGetEnforcementTPListingsResponse, any>({
      path: `/monitoring/enforcement/tp/get_review_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementTP
   * @name EnforcementTpNoteDownloadUrlCreate
   * @request POST:/monitoring/enforcement/tp/note/download-url
   */
  enforcementTpNoteDownloadUrlCreate = (
    data: MonitoringEnforcementTPProtocolsGetNoteDownloadUrlRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementTPProtocolsGetNoteDownloadUrlResponse, any>({
      path: `/monitoring/enforcement/tp/note/download-url`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementTP
   * @name EnforcementTpGetAllListingIdCreate
   * @request POST:/monitoring/enforcement/tp/get_all_listing_id
   */
  enforcementTpGetAllListingIdCreate = (
    data: MonitoringEnforcementTPProtocolsGetAllListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementTPProtocolsGetAllListingIdResponse, any>({
      path: `/monitoring/enforcement/tp/get_all_listing_id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementTP
   * @name EnforcementTpExcelExportCreate
   * @request POST:/monitoring/enforcement/tp/excel/export
   */
  enforcementTpExcelExportCreate = (
    data: MonitoringEnforcementTPProtocolsExcelExportRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementTPProtocolsExcelExportResponse, any>({
      path: `/monitoring/enforcement/tp/excel/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementTP
   * @name EnforcementTpExcelProgressCreate
   * @request POST:/monitoring/enforcement/tp/excel/progress
   */
  enforcementTpExcelProgressCreate = (
    data: MonitoringEnforcementTPProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementTPProtocolsExcelResultResponse, any>({
      path: `/monitoring/enforcement/tp/excel/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementTP
   * @name EnforcementTpGetExportListCreate
   * @request POST:/monitoring/enforcement/tp/get_export_list
   */
  enforcementTpGetExportListCreate = (
    data: MonitoringEnforcementTPProtocolsGetExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/tp/get_export_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementTP
   * @name EnforcementTpExcelExportListingIdCreate
   * @request POST:/monitoring/enforcement/tp/excel/export/listing-id
   */
  enforcementTpExcelExportListingIdCreate = (
    data: MonitoringEnforcementTPProtocolsExcelExportByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/tp/excel/export/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
