import { useQuery } from '@tanstack/react-query';
import { monitoringEnforcementApi } from '~/apis';
import {
  MonitoringEnforcementProtocolsGetCountProgressRequest,
  MonitoringEnforcementProtocolsGetCountStatusRequest,
} from '~/swagger/data-contracts';

const QueryKeyEnforcement = 'monitoring/enforcement';

type AdditionalQueryOptions = {
  staleTime?: number;
  enabled?: boolean;
};
export const useEnforcementCountDomainTypeQuery = (request: { brand_id: number }, options?: AdditionalQueryOptions) => {
  return useQuery(
    [QueryKeyEnforcement, 'count/domain-type', request],
    async () => {
      const { data } = await monitoringEnforcementApi.enforcementCountDomainTypeCreate(request);
      return data;
    },
    options
  );
};

export const useEnforcementCountStatusQuery = (
  request: MonitoringEnforcementProtocolsGetCountStatusRequest,
  options?: AdditionalQueryOptions
) => {
  return useQuery(
    [QueryKeyEnforcement, 'count-status', request],
    async () => {
      const { data } = await monitoringEnforcementApi.enforcementCountStatusCreate(request);
      return data;
    },
    options
  );
};

export const useEnforcementCountProgressQuery = (
  request: MonitoringEnforcementProtocolsGetCountProgressRequest,
  options?: AdditionalQueryOptions
) =>
  useQuery(
    [QueryKeyEnforcement, 'count/progress', request],
    async () => {
      const { data } = await monitoringEnforcementApi.enforcementCountProgressCreate(request);
      return data;
    },
    options
  );
