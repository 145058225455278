/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MonitoringEnforcementProtocolsGetCountByDomainTypeRequest,
  MonitoringEnforcementProtocolsGetCountByDomainTypeResponse,
  MonitoringEnforcementProtocolsGetCountProgressRequest,
  MonitoringEnforcementProtocolsGetCountProgressResponse,
  MonitoringEnforcementProtocolsGetCountStatusRequest,
  MonitoringEnforcementProtocolsGetCountStatusResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringEnforcement<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringEnforcement
   * @name EnforcementCountDomainTypeCreate
   * @request POST:/monitoring/enforcement/count/domain-type
   */
  enforcementCountDomainTypeCreate = (
    data: MonitoringEnforcementProtocolsGetCountByDomainTypeRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementProtocolsGetCountByDomainTypeResponse, any>({
      path: `/monitoring/enforcement/count/domain-type`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcement
   * @name EnforcementCountStatusCreate
   * @request POST:/monitoring/enforcement/count/status
   */
  enforcementCountStatusCreate = (
    data: MonitoringEnforcementProtocolsGetCountStatusRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementProtocolsGetCountStatusResponse, any>({
      path: `/monitoring/enforcement/count/status`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcement
   * @name EnforcementCountProgressCreate
   * @request POST:/monitoring/enforcement/count/progress
   */
  enforcementCountProgressCreate = (
    data: MonitoringEnforcementProtocolsGetCountProgressRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementProtocolsGetCountProgressResponse, any>({
      path: `/monitoring/enforcement/count/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
