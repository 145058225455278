export enum LanguageEnum {
  English = 'en',
  Korean = 'ko',
}

export enum SortEnum {
  Asc = 'asc',
  Desc = 'desc',
  Featured = 'featured',
}


export type DropDownSortItem = {
  value: SortEnum;
  label: string;
};

export type ISelectValue = {
  value: string | number;
  label: string;
  displayLabel?: string;
}

export type IDropDownItem = {
  label: string | React.ReactNode;
  value: unknown;
  imageUrl?: string;
  disabled?: boolean;
  className?: string;
  isFolded?: boolean;
  children?: IDropDownItem[];
  showSelectedChildCount?: boolean; // 자식 아이템 중 선택된 아이템의 개수를 보여줄지 여부. children이 설정되어있어만 활성화됨.


  displayLabel?: string;
  help?: React.ReactNode;
  renderComponent?: (onClick: () => void, onClose: () => void) => React.ReactNode;
  onView?: () => Promise<string>;
  count?: number | null;
} & LocalMultiSelectionDropDownParams



/**
  LocalMultiSelectionDropDownParams
  
  local multi selection을 사용하는 경우, 전체 dropdown의 isMultiSelect prop의 설정 여부에 따라 동작이 달라집니다.

  만일 isMultiSelect prop이 true인 경우에는 local multi selection 기능을 사용할 수 없습니다.
  왜냐하면 이미 전체 dropdown에 multi select 기능이 활성화되어있기 때문입니다.

  isMultiSelect prop이 false인 경우에는 `isChildrenMultiSelectable: true` 로 설정된 children 배열에 관해서만 local multi selection 기능을 사용할 수 있습니다.
  이 기능을 구현하기 위해 local multi selection의 루트 키와, 개발 Dropdown item이 local multi selection의 루트인지 그 안에 속한 아이템인지 구분하는 작업이 필요합니다.
  _localMultiSelectRootKey와 _isLocalMultiSelectRootKey 가 이 값들을 구분하는데 사용됩니다.
 */
type LocalMultiSelectionDropDownParams = {
  isChildrenMultiSelectable?: boolean;
  _localMultiSelectRootKey?: string; // 
  _isLocalMultiSelectRoot?: boolean;
}