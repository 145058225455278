import React from 'react';

const ArrowDownOutlineIcon = ({ color = '#454F5B', scale = 1 }: { color?: string; scale?: number }): JSX.Element => (
  <svg width={24 * scale} height={24 * scale} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24 * scale} height={24 * scale} fill="white" fillOpacity="0.01" />
    <path
      d="M15.88 9.29L12 13.17L8.12001 9.29C7.73001 8.9 7.10001 8.9 6.71001 9.29C6.32001 9.68 6.32001 10.31 6.71001 10.7L11.3 15.29C11.69 15.68 12.32 15.68 12.71 15.29L17.3 10.7C17.69 10.31 17.69 9.68 17.3 9.29C16.91 8.91 16.27 8.9 15.88 9.29V9.29Z"
      fill={color}
    />
  </svg>
);

export default ArrowDownOutlineIcon;
