import { ChangeEvent } from 'react';
import clsx from 'clsx';
import { TextareaAutosize, TextareaAutosizeProps, makeStyles } from '@material-ui/core';
import MDSTheme from './Theme';

const useStyles = makeStyles(() => ({
  root: {
    ...MDSTheme.typography.regular,
    color: MDSTheme.palette.bluegray[800],
    border: `1px solid ${MDSTheme.palette.bluegray[200]}`,
    resize: 'none',
    borderRadius: '4px',
    '&::placeholder': {
      color: MDSTheme.palette.bluegray[300],
    },
  },
  small: {
    ...MDSTheme.typography.T13,
    padding: '2.2px 11px',
  },
  medium: {
    ...MDSTheme.typography.T14,
    padding: '4.5px 11px',
  },
  large: {
    ...MDSTheme.typography.T16,
    padding: '6px 13px',
  },
  extraLarge: {
    ...MDSTheme.typography.T20,
    padding: '8px 16px',
  },
  error: {
    borderColor: MDSTheme.palette.red[600],
  },
}));

type TextFieldSize = 'small' | 'medium' | 'large' | 'extra_large';

interface IProps {
  size?: TextFieldSize;
  error?: boolean;
  onChange: (value: string) => void;
}

export const TextArea = (props: IProps & Omit<TextareaAutosizeProps, 'onChange'>) => {
  const classes = useStyles();
  const { className, onChange, size = 'medium', minRows = 1, error, ...restProps } = props;

  return (
    <TextareaAutosize
      className={clsx(classes.root, {
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
        [classes.large]: size === 'large',
        [classes.extraLarge]: size === 'extra_large',
        [classes.error]: error,
      }, className)}
      minRows={minRows}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
      {...restProps}
    />
  );
};

export default TextArea;
