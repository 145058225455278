import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PopperJs from 'popper.js';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MDSThemeValue } from '@marqvision/mds-v2';
import { IcoCheckOutline, IcoCopyContent } from '@marqvision/mds/assets';
import { MDSChip, MDSTheme } from '@marqvision/mds/core';

const useStyles = makeStyles({
  tooltip: {
    padding: '0',
    margin: '0',
    backgroundColor: 'transparent',
  },
  link: {
    position: 'relative',
    padding: '1px 4px',
    display: 'inline',
    verticalAlign: 'bottom',
    cursor: 'pointer',
    wordBreak: 'break-all',
    ...MDSTheme.typography.medium,
    ...MDSTheme.typography.T12,
    borderRadius: '4px',

    '&.blue': {
      color: MDSThemeValue.color.content.primary.default.normal,
      backgroundColor: MDSThemeValue.color.bg.fill.primary.tint.normal,
      '&.isMouseOver': {
        color: MDSThemeValue.color.content.primary.default.hover,
        backgroundColor: MDSThemeValue.color.bg.fill.primary.tint.hover,
      },
    },

    '&.bluegray': {
      color: MDSThemeValue.color.content.neutral.default.normal,
      backgroundColor: MDSThemeValue.color.bg.fill.neutral.tint.normal,
      '&.isMouseOver': {
        color: MDSThemeValue.color.content.neutral.default.hover,
        backgroundColor: MDSThemeValue.color.bg.fill.neutral.tint.hover,
      },
    },
  },
  copyButton: {
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)',
  },
});

type LinkProps = {
  url: string;
  sliceLength?: number;
  color: 'blue' | 'bluegray';
};

// @morgan - 디자인 참고: https://www.figma.com/design/vr9YcnOv33UiEQq3QuGRXs/Client.-Customer-request?node-id=309-2890&m=dev
export const CopyLink = (props: LinkProps) => {
  const { url, sliceLength, color } = props;
  const classes = useStyles();

  const timerRef = useRef<NodeJS.Timeout>();
  const popperRef = useRef<PopperJs>(null);
  const positionRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const areaRef = useRef<HTMLDivElement>(null);

  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const label = sliceLength && url.length > sliceLength ? `${url.slice(0, sliceLength)}...` : url;

  const handleCopyButtonMove = (event: React.MouseEvent<HTMLDivElement>) => {
    positionRef.current = { x: event.clientX, y: event.clientY };
    popperRef.current?.update();
  };

  const handleUrlOpen = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleCopy = async () => {
    await window.navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  const handleCopyButtonShow = () => {
    setIsMouseOver(true);
  };

  const handleCopyButtonHide = () => {
    setIsMouseOver(false);
  };

  useEffect(() => {
    if (isMouseOver) return;
    timerRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 500);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [isMouseOver]);

  return (
    <Tooltip
      open={isMouseOver}
      interactive
      placement="top"
      classes={{ tooltip: classes.tooltip }}
      leaveDelay={isCopied ? 500 : 0}
      PopperProps={{
        popperRef,
        anchorEl: {
          clientHeight: 0,
          clientWidth: 0,
          getBoundingClientRect: () => {
            const x = positionRef.current.x;
            const y = areaRef.current?.getBoundingClientRect().y || 0;
            return new DOMRect(x, y, 0, 0);
          },
        },
      }}
      title={
        <MDSChip
          className={classes.copyButton}
          variant="outline"
          color="bluegray"
          size="small"
          icon={isCopied ? <IcoCheckOutline /> : <IcoCopyContent />}
          label={isCopied ? 'Copied' : 'Copy'}
          onClick={handleCopy}
          onMouseOver={handleCopyButtonShow}
          onMouseLeave={handleCopyButtonHide}
        />
      }
    >
      <span
        ref={areaRef}
        className={clsx(classes.link, color, { isMouseOver })}
        onClick={handleUrlOpen}
        onMouseOver={handleCopyButtonShow}
        onMouseMove={handleCopyButtonMove}
        onMouseLeave={handleCopyButtonHide}
      >
        {label}
      </span>
    </Tooltip>
  );
};

type AutoLinkProps = {
  text: string;
  sliceLength?: number;
  color?: 'blue' | 'bluegray';
};

// @morgan - 전달된 text 에서 url 을 모두 찾아 link 컴포넌트로 변환하여 출력합니다
export const AutoLink = (props: AutoLinkProps) => {
  const { text, sliceLength, color = 'blue' } = props;
  const urlRegExp = /(https?:\/\/[^\s/$.?#].[^\s]*[^\s.,;'"()])/gi;

  return text.split(urlRegExp).map((word, index) => {
    const match = word.match(urlRegExp);

    if (match) {
      const url = match[0].startsWith('http') ? match[0] : `https://${match[0]}`;
      return <CopyLink key={index} url={url} sliceLength={sliceLength} color={color} />;
    }
    return word;
  });
};
