import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  blurContainer: {
    transition: 'filter 300ms ease-out',
    filter: 'blur(8px)',
  },
  loaded: {
    filter: 'blur(0)',
  },
}));

interface LazyImageProps {
  imageUrl: string;
  className?: string;
}

// Image를 CSS Background Image: URL에 적용할 경우에 사용되는 LazyImage 공용 컴포넌트
// Image container 스타일은 prop으로 전달해주세요.

const LazyBackgroundImage = ({ imageUrl, className }: LazyImageProps) => {
  const classes = useStyles();
  const outerDivRef = useRef<HTMLDivElement | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const image = new Image();

          image.src = imageUrl;
          image.onload = () => {
            // Blur 효과를 주기 위해 setTimeout 적용
            // 제거하면 blur 효과없는 상태로 이미지 로딩됨
            setTimeout(() => {
              setLoaded(true);
            }, 300);

            if (outerDivRef.current) {
              outerDivRef.current.style.backgroundImage = `url(${imageUrl})`;
            }
          };

          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
      }
    );

    if (outerDivRef.current) {
      observer.observe(outerDivRef.current);
    }

    return () => {
      if (outerDivRef.current) {
        observer.unobserve(outerDivRef.current);
      }
    };
  }, [imageUrl]);

  return <div ref={outerDivRef} className={clsx(classes.blurContainer, className, { [classes.loaded]: loaded })} />;
};

export default LazyBackgroundImage;
