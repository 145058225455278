import { isEqualArrays } from '~/utils';

export const getObjectKeyDiff = (oldObj: any, newObj: any) => {
  const diff = Object.keys(newObj).reduce((result, key) => {
    if (!oldObj.hasOwnProperty(key) || oldObj[key] !== newObj[key]) {
      if (Array.isArray(newObj[key]) && isEqualArrays(oldObj[key], newObj[key])) return result;
      else result.push(key);
    }
    return result;
  }, [] as string[]);
  return diff;
};
