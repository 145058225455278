import React from 'react';

const CreditCardIcon = ({ color = '#454F5B', scale = 1 }: { color?: string; scale?: number }): JSX.Element => (
  <svg width={24 * scale} height={24 * scale} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24 * scale} height={24 * scale} fill="white" fillOpacity="0.01" />
    <path
      d="M22 5.93004V18.234C22 18.7487 21.811 19.195 21.4331 19.573C21.0551 19.9509 20.6007 20.1399 20.07 20.1399H3.90591C3.39123 20.1399 2.94491 19.9509 2.56695 19.573C2.18898 19.195 2 18.7487 2 18.234V5.93004C2 5.39928 2.18898 4.94491 2.56695 4.56695C2.94491 4.18898 3.39123 4 3.90591 4H20.07C20.6007 4 21.0551 4.18898 21.4331 4.56695C21.811 4.94491 22 5.39928 22 5.93004ZM3.90591 8.41496H20.07V5.93004H3.90591V8.41496ZM3.90591 11.5271V18.234H20.07V11.5271H3.90591ZM3.90591 18.234V5.93004V18.234Z"
      fill={color}
    />
  </svg>
);

export default CreditCardIcon;
