import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import * as Sentry from '@sentry/react';
import { MDSToastSnackbar } from '@marqvision/mds/core';
import { HttpClient } from '~/swagger/http-client';
import { clearAuth, QueryKeyAuth, refreshAuth } from '~/apis/auth';
import { AuthProtocolsLoginResponse } from '~/swagger/data-contracts';
import { queryClient } from './';

export const API_ROOT = process.env.REACT_APP_NEW_API_ROOT;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
export const OKTA_CALLBACK_URI = encodeURI(process.env.REACT_APP_OKTA_CALLBACK_URI || '');

const axiosConfig = {
  baseURL: API_ROOT,
  headers: {},
  timeout: 30000,
};

function reportToSentry(message: any, isMajorError = false, ignoreDefaultErrorToast = false) {
  captureException(message, isMajorError);

  if (!ignoreDefaultErrorToast) {
    MDSToastSnackbar({
      type: 'error',
      title: 'error',
      message: 'An error occurred, Please try again later.',
    });
  }
}

export const http: HttpClient = new HttpClient(axiosConfig);

export function captureException(message?: string, isMajorError = false) {
  if (isMajorError) {
    Sentry.captureException(message);
    return;
  }

  Sentry.configureScope(function (scope) {
    scope.setLevel('warning');
    Sentry.captureException(new Error(message));
  });
}

(function initializeToErrorHandling() {
  const requestWaitQueue: ((token: string) => void)[] = [];
  let isInProgressRefreshToken = false;

  async function updateToken() {
    isInProgressRefreshToken = true;

    const data = queryClient.getQueryData<AuthProtocolsLoginResponse>([QueryKeyAuth]);

    try {
      if (data?.refresh_token) {
        return await refreshAuth(data);
      } else {
        throw new Error('refresh token is not exist.');
      }
    } catch (e) {
      clearAuth();
    } finally {
      isInProgressRefreshToken = false;
    }
  }

  const responseInterceptor = async (error?: any) => {
    if (!error || !error.response) {
      reportToSentry(error.message, true);
      return;
    }

    if (error.config?.url.indexOf('/auth/refresh') >= 0) {
      clearAuth();
      return;
    }

    const status = error.response.status;
    const message = error.response.data ? error.response.data.error : 'An error occurred, Please try again later.';

    if (error.config && status === 401) {
      const { config: requestConfigToRetry } = error;

      if (!isInProgressRefreshToken) {
        updateToken().then((accessToken) => {
          while (accessToken && requestWaitQueue.length > 0) {
            const waitingRequest = requestWaitQueue.shift();
            waitingRequest?.(accessToken);
          }
        });
      }

      return new Promise((resolve) => {
        requestWaitQueue.push((newAccessToken: string) => {
          requestConfigToRetry['headers'] = {
            ...requestConfigToRetry.headers,
            Authorization: `Bearer ${newAccessToken}`,
          };

          resolve(axios.request(requestConfigToRetry));
        });
      });
    } else if (status >= 500 || status === 422) {
      // Major Error
      reportToSentry(message, true);
    } else {
      // >>>> PROD-8076 [jamie] 구현되면 아래 438 조건문 제거 - 바로 reportToSentry만 호출.
      if (
        status === 438 &&
        (error.config?.url.indexOf('/default_card/add') >= 0 || error.config?.url.indexOf('/default_card/update') >= 0)
      ) {
        reportToSentry(message, false, true);
        //<<<< PROD-8076 [jamie] 구현되면 아래 438 조건문 제거 - 바로 reportToSentry만 호출.
      } else {
        reportToSentry(message);
      }
    }

    // TODO: [PROD-8076] [jamie] raw axios object를 사용하는 곳을 점검한 뒤 Promise.resolve(error)로 넘기기
    return Promise.reject(error);
  };

  axios.interceptors.response.use(undefined, responseInterceptor);

  http.instance.interceptors.response.use(undefined, responseInterceptor);
})();

export function get<T>(url: string, params?: T, auth = true) {
  const config: AxiosRequestConfig = {
    baseURL: API_ROOT,
    params: params,
  };

  const authToken = queryClient.getQueryData<AuthProtocolsLoginResponse>([QueryKeyAuth])?.access_token;

  if (auth && authToken) {
    config.headers = { Authorization: 'Bearer ' + authToken };
  }

  return axios.get(url, config);
}

export function post<T>(url: string, data: T, auth = true, isResponseTypeBlob?: boolean) {
  const config: AxiosRequestConfig = {
    baseURL: API_ROOT,
  };

  const authToken = queryClient.getQueryData<AuthProtocolsLoginResponse>([QueryKeyAuth])?.access_token;

  if (auth && authToken) {
    config.headers = { Authorization: 'Bearer ' + authToken };
  }

  if (isResponseTypeBlob) {
    config.responseType = 'blob';
  }

  return axios.post(url, data, config);
}

http.instance.interceptors.request.use((config) => {
  const newConfig = {
    ...axiosConfig,
    ...config,
  };

  const authToken = queryClient.getQueryData<AuthProtocolsLoginResponse>([QueryKeyAuth])?.access_token;

  if (authToken) {
    if (newConfig.headers) {
      newConfig.headers.Authorization = `Bearer ${authToken}`;
    } else {
      newConfig.headers = { Authorization: `Bearer ${authToken}` } as AxiosRequestHeaders;
    }
  }

  return newConfig;
});
