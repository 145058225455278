/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuthorizedSellerProtocolsAddAuthorizedSellerRequest,
  AuthorizedSellerProtocolsExportAuthorizedSellerRequest,
  AuthorizedSellerProtocolsGetAllAuthorizedSellerIdListRequest,
  AuthorizedSellerProtocolsGetAllAuthorizedSellerIdListResponse,
  AuthorizedSellerProtocolsGetAuthorizedSellerCountRequest,
  AuthorizedSellerProtocolsGetAuthorizedSellerCountResponse,
  AuthorizedSellerProtocolsGetAuthorizedSellerDetailRequest,
  AuthorizedSellerProtocolsGetAuthorizedSellerDetailResponse,
  AuthorizedSellerProtocolsGetAuthorizedSellerListRequest,
  AuthorizedSellerProtocolsGetAuthorizedSellerListResponse,
  AuthorizedSellerProtocolsGetDomainFilterRequest,
  AuthorizedSellerProtocolsGetDomainFilterResponse,
  MarqCommonProtocolFileResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AuthorizedSeller<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags AuthorizedSeller
   * @name ListCreate
   * @request POST:/authorized-seller/list
   */
  listCreate = (data: AuthorizedSellerProtocolsGetAuthorizedSellerListRequest, params: RequestParams = {}) =>
    this.http.request<AuthorizedSellerProtocolsGetAuthorizedSellerListResponse, any>({
      path: `/authorized-seller/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedSeller
   * @name CountCreate
   * @request POST:/authorized-seller/count
   */
  countCreate = (data: AuthorizedSellerProtocolsGetAuthorizedSellerCountRequest, params: RequestParams = {}) =>
    this.http.request<AuthorizedSellerProtocolsGetAuthorizedSellerCountResponse, any>({
      path: `/authorized-seller/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedSeller
   * @name FilterDomainCreate
   * @request POST:/authorized-seller/filter/domain
   */
  filterDomainCreate = (data: AuthorizedSellerProtocolsGetDomainFilterRequest, params: RequestParams = {}) =>
    this.http.request<AuthorizedSellerProtocolsGetDomainFilterResponse, any>({
      path: `/authorized-seller/filter/domain`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedSeller
   * @name DetailsCreate
   * @request POST:/authorized-seller/details
   */
  detailsCreate = (data: AuthorizedSellerProtocolsGetAuthorizedSellerDetailRequest, params: RequestParams = {}) =>
    this.http.request<AuthorizedSellerProtocolsGetAuthorizedSellerDetailResponse, any>({
      path: `/authorized-seller/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedSeller
   * @name GetAllIdCreate
   * @request POST:/authorized-seller/get-all-id
   */
  getAllIdCreate = (data: AuthorizedSellerProtocolsGetAllAuthorizedSellerIdListRequest, params: RequestParams = {}) =>
    this.http.request<AuthorizedSellerProtocolsGetAllAuthorizedSellerIdListResponse, any>({
      path: `/authorized-seller/get-all-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedSeller
   * @name ExportCreate
   * @request POST:/authorized-seller/export
   */
  exportCreate = (data: AuthorizedSellerProtocolsExportAuthorizedSellerRequest, params: RequestParams = {}) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/authorized-seller/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedSeller
   * @name PostAuthorizedSeller
   * @request POST:/authorized-seller/add
   */
  postAuthorizedSeller = (data: AuthorizedSellerProtocolsAddAuthorizedSellerRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/authorized-seller/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
