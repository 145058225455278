import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MDSTypography } from '@marqvision/mds-v2';
import { MDSChip, MDSTheme } from '../index';
import { IcoAttachfile, IcoCloseDeleteRemoveBorder, IcoDocument, IcoExcelSheet, IcoImage, IcoPdf } from '../../assets';
import { IFile, Progress } from './@types';

const useStyles = makeStyles(() => ({
  chipList: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '6px',
    rowGap: '8px',
  },
  loading: {
    width: '20px !important',
    height: '20px !important',
    padding: '2px',
    '& svg': {
      width: '16px !important',
      height: '16px !important',
      flex: '1 !important',
    },
    '& circle': {
      color: MDSTheme.palette.bluegray[600],
    },
  },
}));

const renderChipIcon = (filename?: string) => {
  const extension = filename?.split('.').pop()?.toLowerCase() || '';
  if (['jpg', 'jpeg', 'png', 'svg'].includes(extension)) {
    return <IcoImage />;
  }
  if (['pdf'].includes(extension)) {
    return <IcoPdf />;
  }
  if (['doc', 'docx'].includes(extension)) {
    return <IcoDocument />;
  }
  if (['csv', 'xlsx', 'xls'].includes(extension)) {
    return <IcoExcelSheet />;
  }
  return <IcoAttachfile />;
};

interface IProps<T> {
  files: T[];
  onDelete?: (index: number) => () => void;
  uploadProgress: Progress;
}

export const ChipList = <T extends IFile>(props: IProps<T>): React.ReactNode => {
  const { files, onDelete, uploadProgress } = props;
  const classes = useStyles();

  return (
    <div className={classes.chipList}>
      {files.map(({ url, name }, index) => (
        <MDSChip
          className="chipItem"
          key={url}
          variant="tint"
          color="bluegray"
          label={
            <MDSTypography variant="T14" weight="medium" lineClamp={1} whiteSpace="pre-wrap" wordBreak="break-all">
              {name}
            </MDSTypography>
          }
          icon={
            uploadProgress[url]?.isLoading ? <CircularProgress className={classes.loading} /> : renderChipIcon(name)
          }
          endIcon={onDelete && <IcoCloseDeleteRemoveBorder />}
          endIconClick={onDelete?.(index)}
        />
      ))}
    </div>
  );
};
