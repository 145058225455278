import { useRef, useState } from 'react';
import clsx from 'clsx';
import { MenuItem, Popper, makeStyles } from '@material-ui/core';
import MDSCheckbox from '../../Checkbox';
import MDSTypography from '../../Typography';
import { localizedLabel } from '../@constants';
import MDSSortBy from '../../SortBy';
import { DropDownSortItem, LanguageEnum, SortEnum } from '../@types';
import MDSTheme from '../../Theme';
import { IcoCheckOutline } from '../../../assets';
import { IDropDownProps } from '../index';

const useStyles = makeStyles({
  dropSortBy: {
    marginLeft: 'auto',
  },
  dropSortWrap: {
    boxShadow: '0px 8px 16px 0px #00000033',
    backgroundColor: MDSTheme.palette.white,
    borderRadius: '8px',
    marginTop: '4px',
    width: '160px',
  },
  dropSortItem: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    paddingRight: '16px',
    '&:hover': {
      backgroundColor: MDSTheme.palette.blue[50],
    },
    '& .selectIcon': {
      width: '16px',
      height: '16px',
      marginRight: '4px',
      verticalAlign: 'text-bottom',
      '& > path': {
        fill: MDSTheme.palette.blue['700'],
      },
    },
  },
  sortMenuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '&:hover': {
      background: MDSTheme.palette.white,
    },
  },
  readonlyMenuItem: {
    cursor: 'initial',
    '&:hover': {
      background: MDSTheme.palette.white,
    },
  },
});

const sortingMenuList = (locale: LanguageEnum): DropDownSortItem[] => [
  { value: SortEnum.Asc, label: localizedLabel.asc[locale] },
  { value: SortEnum.Desc, label: localizedLabel.desc[locale] },
];

type Props = {
  sort: SortEnum;
  isMultiSelect?: boolean;
  isGroupList: boolean;
  selectedValue: unknown[];
  displayedCount: number;
  locale: LanguageEnum;
  isSearching: boolean;
  listingLength: number;
  onChange: (sort: SortEnum) => void;
  onCheckAll: () => void;
} & Pick<
  IDropDownProps,
  | 'hideSort'
  | 'hideFeaturedSort'
  | 'hideSelectAll'
  | 'sortTitleLabel'
  | 'sortFeatured'
  | 'totalCount'
  | 'onScrollToBottom'
>;

const SortListItem = ({ sort, item, onClick }: { sort: SortEnum; item: DropDownSortItem; onClick: () => void }) => {
  const classes = useStyles();

  const isChecked = sort === item.value;

  return (
    <MenuItem button onClick={onClick} className={classes.dropSortItem} key={item.value}>
      <MDSTypography
        variant="T14"
        weight={isChecked ? 'medium' : 'regular'}
        style={{
          color: isChecked ? MDSTheme.palette.blue['700'] : MDSTheme.palette.bluegray['800'],
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isChecked && <IcoCheckOutline className="selectIcon" />}
        {item.label}
      </MDSTypography>
    </MenuItem>
  );
};

export const TotalAndSort = (props: Props) => {
  const classes = useStyles();

  const { sort, selectedValue, hideFeaturedSort, locale, displayedCount, isSearching, onChange, onCheckAll } = props;

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const sortRef = useRef<HTMLElement>();

  const sortList = sortingMenuList(locale).filter(
    (item) => props.isGroupList || props.onScrollToBottom || !hideFeaturedSort
  );

  const handleOpenSort = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    sortRef.current = event.currentTarget;
    setIsOpenMenu((ps) => !ps);
  };

  const handleChange = (value: SortEnum) => {
    onChange(value);
    setIsOpenMenu(false);
  };

  const displayCheckbox =
    props.isMultiSelect &&
    !(selectedValue.length === 0 && props.hideSelectAll) && // Search 여부 무관 > Select All 막음
    !(isSearching && selectedValue.length === 0); // Search 했을때 > Select All 막음

  return (
    <>
      {props.isMultiSelect && (
        <div className="dropItem dropSortBox">
          <MenuItem
            onClick={() => displayCheckbox && onCheckAll()}
            className={clsx(classes.sortMenuItem, { [classes.readonlyMenuItem]: !displayCheckbox })}
          >
            {displayCheckbox && (
              <MDSCheckbox
                className="dropCheckbox"
                isPrimary
                checked={selectedValue.length > 0}
                indeterminate={selectedValue.length > 0}
              />
            )}
            <MDSTypography weight="medium" variant="T14">
              {selectedValue.length === 0 &&
                (isSearching
                  ? localizedLabel.searched[locale](displayedCount)
                  : props.onScrollToBottom
                  ? localizedLabel.allWithPaging[locale]
                  : localizedLabel.all[locale](props.totalCount ? props.totalCount : displayedCount))}
              {selectedValue.length > 0 &&
                localizedLabel.selected[locale](
                  props.onScrollToBottom && props.totalCount && props.listingLength === selectedValue.length
                    ? props.totalCount
                    : selectedValue[0] === -1
                    ? props.totalCount || 0
                    : selectedValue.length
                )}
            </MDSTypography>
          </MenuItem>
          {!props.hideSort &&
            (props.sortFeatured ? (
              <MDSSortBy label="Featured" isArrowIcon={false} className={classes.dropSortBy} />
            ) : (
              <MDSSortBy
                onClick={handleOpenSort}
                label={
                  (props.sortTitleLabel ? `${props.sortTitleLabel} ` : '') +
                  sortList.find((item: DropDownSortItem) => item.value === sort)?.label
                }
                className={classes.dropSortBy}
              />
            ))}
        </div>
      )}
      {!props.isMultiSelect && (
        <div className="dropItem dropSortBox">
          <MDSTypography weight="medium" variant="T14">
            {isSearching ? localizedLabel.searched[locale](displayedCount) : localizedLabel.all[locale](displayedCount)}
          </MDSTypography>
          <MDSSortBy
            onClick={handleOpenSort}
            label={
              (props.sortTitleLabel ? `${props.sortTitleLabel} ` : '') +
              sortList.find((item: DropDownSortItem) => item.value === sort)?.label
            }
            className={classes.dropSortBy}
          />
        </div>
      )}
      <Popper open={isOpenMenu} anchorEl={sortRef.current} placement="bottom-start" style={{ zIndex: 1300 }}>
        <div className={classes.dropSortWrap}>
          {sortList.map((item: DropDownSortItem) => (
            <SortListItem key={item.value} sort={sort} item={item} onClick={() => handleChange(item.value)} />
          ))}
        </div>
      </Popper>
    </>
  );
};
