import { useEffect, useRef, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Panel, { MDSPanelBody, MDSPanelHeader } from '@marqvision/mds/core/Panel';
import { MDSButton, MDSMessageBox } from '@marqvision/mds/core';
import { IcoDownload, IcoRefresh } from '@marqvision/mds/assets';
import { MDSTypography } from '@marqvision/mds-v2';
import { MessageTypeEnum } from '@marqvision/mds/core/MessageBox';
import { downloadFileWithFilename } from '../utils';

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      bottom: 0,
      height: 'auto',
    },
  },
  panelHeader: {
    borderBottom: 'none !important',
  },
  panelBody: {
    width: '800px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentErrorBox: {
    padding: '0 16px 16px',
  },
  panelContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
    '& iframe': {
      width: '100%',
      height: '100%',
      border: 'none',
    },
  },
});

type Props = {
  report: {
    title: string;
    url: string;
  };

  onClose: () => void;
  onDone?: () => void;
};

// 20초 안에 iframe 내부 파일이 다 로딩되지 않으면 유저에게 현재 상황을 알려주고 reload를 유도한다. - google viewer의 문제!!
const MAX_WAIT_TIME_IN_MS = 20 * 1000;
export const DocumentPreviewPanel = (props: Props) => {
  const classes = useStyles();
  const { report, onClose, onDone } = props;
  const [previewLoadingState, setPreviewLoadingState] = useState({
    url: report.url,
    isLoading: true,
    isError: false,
  });
  const previewUrl = `https://drive.google.com/viewer?url=${report.url}&embedded=true`;

  const timer = useRef<number | null>(null);
  const handleLoaded = () => {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
    setPreviewLoadingState((prev) => ({
      ...prev,
      isLoading: false,
      isError: false,
    }));
  };

  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const handleDownloadClick = async () => {
    setIsDownloadInProgress(true);
    await downloadFileWithFilename(report.url, report.title);
    onDone?.();
    setIsDownloadInProgress(false);
  };
  const handleRetryClick = () => {
    setPreviewLoadingState({
      url: previewUrl + '&refresh=' + Date.now(),
      isLoading: true,
      isError: false,
    });
  };

  useEffect(() => {
    setPreviewLoadingState({
      url: previewUrl + '&refresh=' + Date.now(), // 미리보기 전환시 iframe을 강제로 unmount -> mount 시킨다
      isLoading: true,
      isError: false,
    });

    timer.current = window.setTimeout(() => {
      setPreviewLoadingState((prev) => ({
        url: prev.url,
        isLoading: true,
        isError: true,
      }));
    }, MAX_WAIT_TIME_IN_MS);

    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, [previewUrl]);

  return (
    <Panel open variant="persistent" onClose={onClose} anchor="right" width="800px" className={classes.root}>
      <MDSPanelHeader
        onClose={onClose}
        className={classes.panelHeader}
        title={
          <MDSTypography variant="T20" weight="bold" lineClamp={1} wordBreak="break-all">
            {report.title}
          </MDSTypography>
        }
        leftButton={
          <MDSButton
            variant="outline"
            color="bluegray"
            size="medium"
            startIcon={<IcoDownload />}
            onClick={handleDownloadClick}
            disabled={isDownloadInProgress}
          >
            Download
          </MDSButton>
        }
      />
      <MDSPanelBody className={classes.panelBody} key={previewLoadingState.url}>
        {previewLoadingState.isError && (
          <div className={classes.contentErrorBox}>
            <MDSMessageBox
              title="Error loading"
              type={MessageTypeEnum.Error}
              message="The internet connection is not stable. Please try reloading preview or downloading the file."
              messageCTAButton={
                <MDSButton
                  variant="text"
                  color="blue"
                  size="medium"
                  startIcon={<IcoRefresh />}
                  onClick={handleRetryClick}
                >
                  Reload
                </MDSButton>
              }
              hideRightButton
            />
          </div>
        )}
        <div className={classes.panelContent}>
          {previewLoadingState.isLoading && <CircularProgress />}
          <iframe src={previewUrl} onLoad={handleLoaded} />
        </div>
      </MDSPanelBody>
    </Panel>
  );
};
