import { makeStyles } from '@material-ui/styles';
import { MDSThemeValue, MDSTypography } from '@marqvision/mds-v2';
import { LegendItem } from '../LegendItem';
import { Tooltip } from './@types';

const useStyles = makeStyles({
  tooltip: {
    padding: '6px 8px',
    position: 'fixed',
    pointerEvents: 'none',
    transition: '0.3s',
    transform: 'translate(-50%, calc(-100% - 12px))',
    borderRadius: '8px',
    border: `1px solid ${MDSThemeValue.color.border.neutral.default.normal}`,
    backgroundColor: MDSThemeValue.color.bg.surface.neutral.default.normal,
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.04), 0px 1px 4px 0px rgba(0, 0, 0, 0.08)',
    display: 'grid',
    gap: '4px',
    zIndex: 10,
  },
});

export const ChartTooltip = (props: Tooltip) => {
  const { top, left, data } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.tooltip}
      style={{
        display: data ? 'block' : 'none',
        top,
        left,
      }}
    >
      <MDSTypography variant="T13" weight="regular">
        {data.label}
      </MDSTypography>
      <LegendItem pointColor={data.color} pointSize="8px" gap="4px">
        <MDSTypography variant="T13" weight="medium">
          {data.value.toLocaleString()}
        </MDSTypography>
      </LegendItem>
    </div>
  );
};
