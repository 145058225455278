import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MDSTheme } from './index';

const useStyles = makeStyles(() => ({
  defaultHighlight: {
    ...MDSTheme.typography.medium,
    background: 'linear-gradient(90deg, #3EFFC6 32%, #306CFF 54.88%)',
    backgroundSize: '200% auto',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    animation: '$shine .8s cubic-bezier(0, 0.4, 0.4, 0) infinite',
  },
  '@keyframes shine': {
    to: {
      backgroundPosition: '200% center',
    },
  },
}));

interface IProps {
  text?: string | null;
  startIndex?: number | null;
  endIndex?: number | null;
  highlightClassName?: string;
}

const HighlightedText = (props: IProps): React.ReactNode => {
  const classes = useStyles();
  const { text, startIndex, endIndex, highlightClassName } = props;

  const isTextDetection = typeof startIndex === 'number' && typeof endIndex === 'number';

  if (!text) return;

  return isTextDetection ? (
    <>
      {[...text].slice(0, startIndex).join('')}
      <span className={highlightClassName || classes.defaultHighlight}>
        {[...text].slice(startIndex, endIndex).join('')}
      </span>
      {[...text].slice(endIndex).join('')}
    </>
  ) : (
    text
  );
};

export default HighlightedText;
