import { FormEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import { MDSTheme, MDSTypography, MDSButton, MDSPopup } from '@marqvision/mds/core';
import { IcoTask, IcoArrowRightOutline } from '@marqvision/mds/assets';

const useStyles = makeStyles(() => ({
  guideWrapper: {
    padding: '24px',
  },
  guideTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },
  buttonWrapper: {
    width: '100%',
    textAlign: 'right',
    padding: '16px 59px',
  },
}));

const ClusterUpdatedDialog = () => {
  const classes = useStyles();

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    window.location.href = '/seller';
  };

  return (
    <MDSPopup width="300px" shouldApplyBackdropFilter>
      <form onSubmit={handleFormSubmit}>
        <div className={classes.guideWrapper}>
          <div className={classes.guideTextWrapper}>
            <IcoTask />
            <MDSTypography variant="T16" weight="medium" color={MDSTheme.palette.bluegray[900]}>
              Cluster has been updated!
            </MDSTypography>
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <MDSButton
            variant="fill"
            color="blue"
            size="medium"
            type="submit"
            endIcon={<IcoArrowRightOutline />}
            disabled={false}
            style={{ padding: '4.5px 11px' }}
          >
            View updated clusters
          </MDSButton>
        </div>
      </form>
    </MDSPopup>
  );
};

export default ClusterUpdatedDialog;
