import { useEffect } from 'react';
import { Box, makeStyles, IconButton } from '@material-ui/core';
import { CloseDeleteRemoveOutlineIcon, Download } from '@marqvision/mds/icons';
import { MDSDimmed } from '@marqvision/mds-v2';

export interface IFilenameUrl {
  filename: string;
  url: string;
}

interface IProps {
  image: IFilenameUrl;
  onDownload?: () => Promise<void> | void;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  iconButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '4px',
    '& + &': {
      marginLeft: '16px',
    },
  },
}));

const RequestImagePreview = ({ image, onDownload, onClose }: IProps): JSX.Element => {
  const classes = useStyles();

  useEffect(() => {
    const scrollWrap = window.document.getElementById('scrollLayout');
    if (scrollWrap) {
      scrollWrap.style.overflow = 'hidden';
    }
    return () => {
      if (scrollWrap) {
        scrollWrap.style.overflow = 'auto';
      }
    };
  }, []);

  return (
    <MDSDimmed isOpen={true}>
      <img
        alt="previewImage"
        src={image.url}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          backgroundColor: '#fff',
          backgroundImage:
            'linear-gradient(45deg, #eaeaea 25%, transparent 25%, transparent 75%, #eaeaea 75%, #eaeaea), linear-gradient(45deg, #eaeaea 25%, transparent 25%, transparent 75%, #eaeaea 75%, #eaeaea)',
          backgroundPosition: '0 0, 5px 5px',
          backgroundSize: '10px 10px',
        }}
      />
      <Box position="absolute" top="16px" right="16px">
        {onDownload && (
          <IconButton onClick={onDownload} className={classes.iconButton}>
            <Download color="white" />
          </IconButton>
        )}
        <IconButton onClick={onClose} className={classes.iconButton}>
          <CloseDeleteRemoveOutlineIcon color="white" />
        </IconButton>
      </Box>
    </MDSDimmed>
  );
};

export default RequestImagePreview;
