import React from 'react';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/styles';
import { IcoDocument, IcoMinusFill } from '../../assets';
import { MDSButton, MDSTheme, MDSTypography } from '../index';
import { IMDSFileUploaderProps } from './index';
import { acceptedFilesByType, DefaultMaxFileSize, LocalizedLabel } from './@constants';
import { FileUploaderTypeEnum, IFile, LanguageEnum } from './@types';

const useStyles = makeStyles(() => ({
  dropzoneWrapper: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
  dropzoneIcon: {
    '& path': {
      fill: MDSTheme.palette.blue[700],
    },
  },
  dropzoneText: {
    display: 'grid',
    justifyItems: 'center',
    gap: '4px',
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: '4px',
  },
  readonlyIcon: {
    width: '24px',
    height: '24px',
    '& path': {
      fill: MDSTheme.palette.bluegray[500],
    },
  },
}));

interface IProps<T> extends IMDSFileUploaderProps<T> {
  onAddButtonClick?: () => void;
}

export const Dropzone = <T extends IFile>(props: IProps<T>): React.ReactNode => {
  const {
    onAddButtonClick,
    renderDropzoneText,
    type = FileUploaderTypeEnum.All,
    isError,
    locale = LanguageEnum.English,
  } = props;
  const classes = useStyles();

  const maxFileSize = Math.min(props.maxFileSize || DefaultMaxFileSize, Number.MAX_SAFE_INTEGER);

  const getPlaceholder = () => {
    return (
      props.customAccepts?.placeholder ||
      acceptedFilesByType[type]
        .map((fileType) => fileType.split('/')[1]?.toUpperCase() || fileType.substring(1).toUpperCase())
        .join(', ')
    );
  };

  return (
    <div className={classes.dropzoneWrapper}>
      {onAddButtonClick ? (
        <>
          <IcoDocument className={classes.dropzoneIcon} />
          <div className={classes.dropzoneText}>
            {renderDropzoneText ? (
              renderDropzoneText(onAddButtonClick)
            ) : (
              <div className={classes.textWrapper}>
                <MDSTypography variant="T14" weight="regular" color={MDSTheme.palette.bluegray[800]}>
                  {LocalizedLabel[locale].DragAndDrop}
                </MDSTypography>
                <MDSButton variant="text" size="medium" color="blue" onClick={onAddButtonClick}>
                  {LocalizedLabel[locale].Browse}
                </MDSButton>
              </div>
            )}
            <MDSTypography variant="T12" weight="regular" color={MDSTheme.palette.bluegray[500]}>
              {getPlaceholder()} {maxFileSize !== Infinity && `(Max. ${numeral(maxFileSize).format('0b', Math.floor)})`}
            </MDSTypography>
          </div>
          {isError && (
            <MDSTypography variant="T13" weight="medium" color={MDSTheme.palette.red[700]}>
              {LocalizedLabel[locale].AnErrorOccurred}
            </MDSTypography>
          )}
        </>
      ) : (
        <>
          <IcoMinusFill className={classes.readonlyIcon} />
          <div className={classes.dropzoneText}>
            <MDSTypography variant="T14" weight="regular" color={MDSTheme.palette.bluegray[500]}>
              {LocalizedLabel[locale].Disabled}
            </MDSTypography>
            <MDSTypography variant="T12" weight="regular" color={MDSTheme.palette.bluegray[500]}>
              {getPlaceholder()} {maxFileSize !== Infinity  && `(Max. ${numeral(maxFileSize).format('0b', Math.floor)})`}
            </MDSTypography>
          </div>
        </>
      )}
    </div>
  );
};
