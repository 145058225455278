/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  HomeProtocolsGetBrandAssetsResponse,
  HomeProtocolsGetClosedListRequest,
  HomeProtocolsGetClosedListResponse,
  HomeProtocolsGetHomeResponse,
  HomeProtocolsGetOpenedListRequest,
  HomeProtocolsGetOpenedListResponse,
  HomeProtocolsGetOtherDetailRequest,
  HomeProtocolsGetOtherDetailResponse,
  HomeProtocolsGetOtherPresignedDownloadUrlRequest,
  HomeProtocolsGetOtherPresignedDownloadUrlResponse,
  HomeProtocolsGetOtherPresignedUploadUrlRequest,
  HomeProtocolsGetOtherPresignedUploadUrlResponse,
  HomeProtocolsGetTaskDetailRequest,
  HomeProtocolsGetTaskDetailResponse,
  HomeProtocolsPostOtherAddRequest,
  HomeProtocolsPostOtherAddResponse,
  HomeProtocolsPostUrlAddRequest,
  HomeProtocolsPostUrlAddResponse,
  HomeProtocolsPostUrlGetEcommerceDetailRequest,
  HomeProtocolsPostUrlGetEcommerceDetailResponse,
  HomeProtocolsPostUrlGetWebsiteDetailRequest,
  HomeProtocolsPostUrlGetWebsiteDetailResponse,
  HomeProtocolsTaskDismissRequest,
  HomeProtocolsTaskGetDownloadUrlRequest,
  HomeProtocolsTaskGetDownloadUrlResponse,
  HomeProtocolsTaskGetUploadUrlRequest,
  HomeProtocolsTaskGetUploadUrlResponse,
  HomeProtocolsTaskSubmitRequest,
  MarqCommonProtocolEmptyRequest,
  MarqCommonProtocolEmptyResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Home<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Home
   * @name HomeList
   * @request GET:/home
   */
  homeList = (params: RequestParams = {}) =>
    this.http.request<HomeProtocolsGetHomeResponse, any>({
      path: `/home`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name TaskDetailCreate
   * @request POST:/home/task/detail
   */
  taskDetailCreate = (data: HomeProtocolsGetTaskDetailRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsGetTaskDetailResponse, any>({
      path: `/home/task/detail`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name TaskSubmitCreate
   * @request POST:/home/task/submit
   */
  taskSubmitCreate = (data: HomeProtocolsTaskSubmitRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/home/task/submit`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name TaskDismissCreate
   * @request POST:/home/task/dismiss
   */
  taskDismissCreate = (data: HomeProtocolsTaskDismissRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/home/task/dismiss`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name TaskUploadUrlCreate
   * @request POST:/home/task/upload-url
   */
  taskUploadUrlCreate = (data: HomeProtocolsTaskGetUploadUrlRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsTaskGetUploadUrlResponse, any>({
      path: `/home/task/upload-url`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name TaskDownloadUrlCreate
   * @request POST:/home/task/download-url
   */
  taskDownloadUrlCreate = (data: HomeProtocolsTaskGetDownloadUrlRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsTaskGetDownloadUrlResponse, any>({
      path: `/home/task/download-url`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name OpenedCreate
   * @request POST:/home/opened
   */
  openedCreate = (data: HomeProtocolsGetOpenedListRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsGetOpenedListResponse, any>({
      path: `/home/opened`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name ClosedCreate
   * @request POST:/home/closed
   */
  closedCreate = (data: HomeProtocolsGetClosedListRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsGetClosedListResponse, any>({
      path: `/home/closed`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name BrandAssetsList
   * @request GET:/home/brand-assets
   */
  brandAssetsList = (params: RequestParams = {}) =>
    this.http.request<HomeProtocolsGetBrandAssetsResponse, any>({
      path: `/home/brand-assets`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name UrlAddCreate
   * @request POST:/home/url/add
   */
  urlAddCreate = (data: HomeProtocolsPostUrlAddRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsPostUrlAddResponse, any>({
      path: `/home/url/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name UrlDetailEcommerceCreate
   * @request POST:/home/url/detail/ecommerce
   */
  urlDetailEcommerceCreate = (data: HomeProtocolsPostUrlGetEcommerceDetailRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsPostUrlGetEcommerceDetailResponse, any>({
      path: `/home/url/detail/ecommerce`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name UrlDetailWebsiteCreate
   * @request POST:/home/url/detail/website
   */
  urlDetailWebsiteCreate = (data: HomeProtocolsPostUrlGetWebsiteDetailRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsPostUrlGetWebsiteDetailResponse, any>({
      path: `/home/url/detail/website`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name OtherAddCreate
   * @request POST:/home/other/add
   */
  otherAddCreate = (data: HomeProtocolsPostOtherAddRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsPostOtherAddResponse, any>({
      path: `/home/other/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name OtherPresignedUploadUrlCreate
   * @request POST:/home/other/presigned/upload-url
   */
  otherPresignedUploadUrlCreate = (data: HomeProtocolsGetOtherPresignedUploadUrlRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsGetOtherPresignedUploadUrlResponse, any>({
      path: `/home/other/presigned/upload-url`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name OtherPresignedDownloadUrlCreate
   * @request POST:/home/other/presigned/download-url
   */
  otherPresignedDownloadUrlCreate = (
    data: HomeProtocolsGetOtherPresignedDownloadUrlRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<HomeProtocolsGetOtherPresignedDownloadUrlResponse, any>({
      path: `/home/other/presigned/download-url`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name OtherDetailsCreate
   * @request POST:/home/other/details
   */
  otherDetailsCreate = (data: HomeProtocolsGetOtherDetailRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsGetOtherDetailResponse, any>({
      path: `/home/other/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name UrlContactSalesCreate
   * @request POST:/home/url/contact-sales
   */
  urlContactSalesCreate = (data: MarqCommonProtocolEmptyRequest, params: RequestParams = {}) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/home/url/contact-sales`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Home
   * @name UrlStatusCreate
   * @request POST:/home/url/status
   */
  urlStatusCreate = (data: HomeProtocolsPostUrlGetEcommerceDetailRequest, params: RequestParams = {}) =>
    this.http.request<HomeProtocolsPostUrlGetEcommerceDetailResponse, any>({
      path: `/home/url/status`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
