import React, { useState, useEffect, useRef, UIEvent, useMemo } from 'react';
import clsx from 'clsx';
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MDSTheme from '../Theme';
import DropDownItem from './DropDownItem';
import { DropDownSortItem, IDropDownItem, ISelectValue, LanguageEnum, SortEnum } from './@types';
import { Search } from './@components/Search';
import { TotalAndSort } from './@components/TotalAndSort';
import { getFilteredList, getResolvedList, getSortedList } from './@utils';
import { useDropdown } from './@hooks';

const useStyles = makeStyles(() => ({
  dropdownPopover: {
    marginTop: '4px',
    borderRadius: '8px',
    maxHeight: '480px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      margin: '6px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: MDSTheme.palette.bluegray['300'],
      border: '2px solid transparent',
      backgroundClip: 'padding-box',
      borderRadius: '8px',
    },
  },
  dropDownWrap: {
    minWidth: '180px',
    color: MDSTheme.palette.bluegray[800],
    '& .dropItem': {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 16px',
      gap: '8px',
      '&.selectable:hover': {
        backgroundColor: MDSTheme.palette.blue[50],
      },
      '&.hasImage': {
        height: '72px',
      },
      '& .highlight': {
        ...MDSTheme.typography.medium,
        color: MDSTheme.palette.yellow['600'],
      },
      '&.dropSortBox': {
        paddingRight: '8px',
      },
      '& .lazyImage': {
        display: 'flex',
        alignItems: 'center',
        marginRight: '16px',
        marginLeft: '8px',
        backgroundColor: MDSTheme.palette.bluegray[200],
        '& img': {
          display: 'block',
          width: '48px',
          height: '48px',
          objectFit: 'cover',
          borderRadius: '2px',
        },
      },
      '&.disabled': {
        opacity: '0.4',
        cursor: 'initial',
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
    '& .dropCheckbox': {
      padding: 0,
      marginRight: '8px',
    },

    '& .childrenIndicatorBox': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      '& .selectedChildCountLabelBox': {
        display: 'flex',
        gap: '4px',
      },
      '& .expendButton': {
        padding: '0',
        marginLeft: 'auto',
      },
    },

    '& .dropSearchBox': {
      padding: '8px 8px 0',
    },
    '& .selectIcon': {
      width: '16px',
      height: '16px',
      marginRight: '4px',
      verticalAlign: 'text-bottom',
      '& > path': {
        fill: MDSTheme.palette.blue['700'],
      },
    },
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 9,
    backgroundColor: MDSTheme.palette.white,
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.04)',
  },
}));

interface IDropDownMultiProps extends IDropDownProps {
  isMultiSelect: true;
  value: unknown[];
}

interface IDropDownSingleProps extends IDropDownProps {
  isMultiSelect?: false;
  value: unknown;
}

export interface IDropDownProps {
  isSearch?: boolean;
  totalAndSort?: boolean;
  sortFeatured?: boolean;

  /**
   * Select All 체크박스를 항상 숨깁니다.
   * 아이템 선택 시 Deselect All만 가능합니다.
   */
  hideSelectAll?: boolean;
  /**
   * Search 중인 경우 Select All 체크박스를 숨깁니다.
   * 아이템 선택 시 Deselect All만 가능합니다.
   */
  isReturnSelectedItem?: boolean;
  /**
   * Select All 인 경우에도 -1을 반환하지 않고 선택된 아이템 리스트를 반환합니다.
   */
  value: unknown | unknown[];
  list: IDropDownItem[];
  disabled?: boolean;
  readOnly?: boolean;
  renderAnchorComponent: (
    value: ISelectValue | ISelectValue[],
    handleOpen: (event: React.SyntheticEvent) => void
  ) => React.ReactNode;
  onChange?: (value: IDropDownItem['value'] | IDropDownItem['value'][]) => void;
  width?: number | string;
  onScrollToBottom?: () => void;
  totalCount?: number;
  hideFeaturedSort?: boolean;
  sort?: SortEnum;
  onSort?: (sort: SortEnum) => void;
  search?: string;
  onSearch?: (search: string) => void;
  textLengthToShowTooltip?: number;
  locale?: LanguageEnum;
  sortList?: DropDownSortItem[];
  sortTitleLabel?: string;
  isInfiniteScroll?: boolean;
  hideSort?: boolean;
  className?: string;
  isAnchorWidthMatched?: boolean;
}

export interface IDropItemContext {
  getIsFolded: (item: IDropDownItem) => boolean;
  setFoldedList: React.Dispatch<React.SetStateAction<unknown[]>>;
  setSelectedValue: React.Dispatch<React.SetStateAction<unknown[]>>;
  isMultiSelect?: boolean;
  textLengthToShowTooltip?: number;
  searchText: string;
  close: (value?: unknown) => void;
  selectedValue: unknown[];
  list: IDropDownItem[];
  currentChildrenMultiSelectRoot: unknown;
  setCurrentChildrenMultiSelectRoot: React.Dispatch<React.SetStateAction<unknown>>;
}

interface IDropDownPopup {
  anchorEl?: Element;
  isOpen: boolean;
  onClose: () => void;
  maxWidth?: number | string;
}

const DropDownPopup = (
  props: (IDropDownMultiProps | IDropDownSingleProps) &
    IDropDownPopup & {
      onSaveLastSearch: (value: unknown[]) => void;
    }
): React.ReactNode => {
  const { isOpen, onClose, locale = LanguageEnum.English, hideFeaturedSort, onSaveLastSearch } = props;
  const classes = useStyles();

  const [searchText, setSearchText] = useState<string>('');
  const isGroupList = props.list.some((item) => item.children);
  const [sort, setSort] = useState<SortEnum>(props.sort || SortEnum.Asc);
  const [displayList, setDisplayList] = useState<IDropDownItem[]>(props.list);
  const [selectedValue, setSelectedValue] = useState<unknown[]>(
    Array.isArray(props.value) ? props.value : [props.value]
  );
  const [currentChildrenMultiSelectRoot, setCurrentChildrenMultiSelectRoot] = useState<unknown>();

  const [foldedList, setFoldedList] = useState<unknown[]>([]);

  const contentRef = useRef<HTMLDivElement>(null);

  const displayedAllChild = (() => {
    const arr: unknown[] = [];
    const loop = (items: IDropDownItem[]) => {
      items.forEach((item) => {
        if (item.children) {
          loop(item.children);
        } else if (item.value) {
          arr.push(item.value);
        }
      });
    };
    loop(displayList);
    return arr;
  })();

  const getIsFolded = (item: IDropDownItem): boolean => foldedList.includes(item.value);

  const handleClose = (value?: unknown) => {
    if (props.onChange) {
      if (props.isMultiSelect) {
        // selected value가 [-1]인 경우 All Selected
        props.onChange(
          props.onScrollToBottom &&
            selectedValue.length > 0 &&
            selectedValue.length === props.list.length &&
            !props.isReturnSelectedItem &&
            !searchText
            ? [-1]
            : selectedValue
        );
      } else if (resolvedList.some((item) => item.isChildrenMultiSelectable)) {
        // isMultiSelect와 local multi selection은 동시에 사용할 수 없다. - isMultiSelect
        // isMultiSelect가 true이면 이미 전체 dropdown에 multi select 기능이 활성화되어있기 때문에 local multi selection 기능을 쓸 필요가 없기 때문에.
        props.onChange(value ? [value] : selectedValue);
      } else if (value !== undefined) {
        props.onChange(value);
      }
    }

    onClose();
    props.onSort?.(
      !props.hideFeaturedSort && (isGroupList || props.onScrollToBottom) ? SortEnum.Featured : SortEnum.Asc
    );
    props.onSearch?.('');
  };

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (!props.onScrollToBottom || (props.totalCount && props.totalCount === props.list.length)) {
      return;
    }
    if (
      contentRef.current?.offsetHeight &&
      Math.ceil(event.currentTarget.clientHeight + event.currentTarget.scrollTop) >= contentRef.current.offsetHeight
    ) {
      props.onScrollToBottom();
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    props.onSearch?.(value);
  };

  const handleCheckAll = () => {
    if (selectedValue.length > 0) {
      setSelectedValue([]);
    } else if (!props.isReturnSelectedItem && props.isInfiniteScroll) {
      setSelectedValue([-1]);
    } else {
      setSelectedValue(displayedAllChild);
    }
  };

  const handleSort = (value: SortEnum) => {
    setSort(value);
    props.onSort?.(value);
  };
  const resolvedList = useMemo(() => getResolvedList(props.list), [props.list]);
  useEffect(() => {
    setDisplayList(getFilteredList(resolvedList, searchText));
  }, [searchText, resolvedList]);

  useEffect(() => {
    setDisplayList(props.hideSort ? resolvedList : getSortedList(resolvedList, sort, props.totalAndSort));
  }, [sort, resolvedList, props.hideSort, props.totalAndSort]);

  useEffect(() => {
    onSaveLastSearch(selectedValue);
  }, [selectedValue]);

  return (
    <Popover
      open={isOpen}
      anchorEl={props.anchorEl as Element}
      classes={{ paper: classes.dropdownPopover }}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        onScroll: handleScroll,
        style: { width: props.width, maxWidth: props.maxWidth },
      }}
    >
      <DropItemContext.Provider
        value={{
          list: resolvedList,
          getIsFolded,
          setFoldedList,
          selectedValue,
          setSelectedValue,
          isMultiSelect: props.isMultiSelect,
          textLengthToShowTooltip: props.textLengthToShowTooltip,
          searchText,
          close: handleClose,
          currentChildrenMultiSelectRoot,
          setCurrentChildrenMultiSelectRoot,
        }}
      >
        <div ref={contentRef} className={clsx(classes.dropDownWrap, props.className)}>
          {(props.isSearch || props.isMultiSelect) && (
            <div className={classes.stickyHeader}>
              {props.isSearch && <Search locale={locale} onSearch={handleSearch} />}
              {props.totalAndSort && (
                <TotalAndSort
                  sort={sort}
                  locale={locale}
                  hideFeaturedSort={hideFeaturedSort}
                  isGroupList={isGroupList}
                  selectedValue={selectedValue}
                  displayedCount={displayedAllChild.length}
                  isSearching={!!searchText}
                  listingLength={resolvedList.length}
                  onCheckAll={handleCheckAll}
                  {...props}
                  onChange={handleSort}
                />
              )}
            </div>
          )}
          {displayList.map((item: IDropDownItem) => (
            <DropDownItem
              key={item.value as string}
              item={item}
              depth={0}
              onClose={handleClose}
              initialValue={props.value}
            />
          ))}
        </div>
      </DropItemContext.Provider>
    </Popover>
  );
};

export const DropItemContext = React.createContext<IDropItemContext>({} as IDropItemContext);

export const MDSDropDown = (props: IDropDownMultiProps | IDropDownSingleProps): React.ReactNode => {
  const { value, list, width, disabled, readOnly, isMultiSelect, isAnchorWidthMatched } = props;
  const { selectedItems, popupWidth, popupAnchorEl, isOpen, handlePopupOpen, closePopup, handleChange } = useDropdown({
    value,
    list,
    width,
    disabled,
    readOnly,
    isMultiSelect,
    isAnchorWidthMatched,
  });

  return (
    <>
      {props.renderAnchorComponent(selectedItems, handlePopupOpen)}
      {isOpen && (
        <DropDownPopup
          {...props}
          onSaveLastSearch={handleChange}
          anchorEl={popupAnchorEl.current}
          width={popupWidth}
          maxWidth={popupWidth}
          isOpen={isOpen}
          onClose={closePopup}
        />
      )}
    </>
  );
};

export { SortEnum } from './@types';
export type { IDropDownItem, ISelectValue };
export default MDSDropDown;
