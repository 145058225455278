import React, { ClipboardEvent, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { MDSIcon, MDSTypography } from '@marqvision/mds-v2';
import { IcoCloseDeleteRemoveFill } from '../assets';
import MDSChip from './Chip';
import MDSTextField, { ITextFieldProps } from './TextField';
import { listSliceData } from './utils';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: 'auto',
    display: 'grid',
    gap: '8px',
  },
  textFieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  chipWrapper: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    rowGap: '8px',
    columnGap: '4px',
    flexWrap: 'wrap',
    overflow: 'hidden',
    flexDirection: ({ orientation }: { orientation: 'horizontal' | 'vertical' }) =>
      orientation === 'horizontal' ? 'row' : 'column',
  },
});

// string array 로 반환 (여러 줄 복사 대응)
export const parseClipboardTextToArray = (e: ClipboardEvent): string[] => {
  e.preventDefault();
  const clipboardText = e.clipboardData?.getData('text/plain');

  if (!clipboardText.trim()) return [];

  return clipboardText
    .replaceAll('\t', '')
    .split(/[\r\n]+/g)
    .map((text) => text.trim())
    .filter((text) => text);
};

export type ChipChangeEvent = (list: string[], action: 'add' | 'remove', value: string[]) => void;

type OwnProps = {
  chips: string[];
  placeholder?: string;
  onChange: ChipChangeEvent;
  orientation?: 'horizontal' | 'vertical';
  renderChipComponents?: (props: { isDisabled: boolean; onDelete: (value: string) => void }) => React.ReactNode;
};

type Props = OwnProps & Omit<ITextFieldProps, 'onChange' | 'ref'>;

const TextFieldWithChip = (props: Props) => {
  const { chips, placeholder, onChange, orientation = 'horizontal', renderChipComponents, ...restProps } = props;
  const classes = useStyles({ orientation });

  const [inputValue, setInputValue] = useState<string>('');

  const addChip = (chip: string) => {
    const newChip = chip.trim();
    if (!newChip || chips.indexOf(newChip) !== -1) return;

    const newChips = [...chips, newChip];
    onChange(newChips, 'add', [newChip]);
    setInputValue('');
  };

  const handleTextFieldChange = (_: unknown, value: unknown) => {
    setInputValue(value as string);
  };

  const handleChipAddKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.key !== 'Enter') return;

    addChip(inputValue);
  };

  const handlePaste = (e: ClipboardEvent) => {
    const textArray = parseClipboardTextToArray(e);

    const newChips = [...new Set([...chips, ...textArray])];
    onChange(newChips, 'add', textArray);
    setInputValue('');
  };

  const handlePlusClick = () => {
    addChip(inputValue);
  };

  const handleChipRemove = (value: string) => {
    if (value !== '') {
      onChange(listSliceData([...chips], value), 'remove', [value]);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.textFieldWrapper}>
        <MDSTextField
          size="large"
          value={inputValue}
          onChange={handleTextFieldChange}
          placeholder={placeholder}
          plusButton
          onPlus={handlePlusClick}
          onKeyDown={handleChipAddKeydown}
          onPaste={handlePaste}
          {...restProps}
        />

        {!restProps.error && restProps.helperText && (
          <div className={classes.helperText}>
            <MDSIcon.ErrorWarning variant="fill" size={16} color="color/content/critical/default/normal" />
            <MDSTypography variant="T12" weight="medium" color="color/content/critical/default/normal">
              {restProps.helperText}
            </MDSTypography>
          </div>
        )}
      </div>
      {renderChipComponents?.({
        onDelete: handleChipRemove,
        isDisabled: !!restProps.disabled,
      }) ||
        (chips.length > 0 && (
          <div className={clsx(classes.chipWrapper, { vertical: orientation === 'vertical' })}>
            {chips.map((item: string) => (
              <MDSChip
                key={item}
                variant="tint"
                size="small"
                color="bluegray"
                label={item}
                endIcon={!restProps.disabled && <IcoCloseDeleteRemoveFill />}
                endIconClick={!restProps.disabled ? () => handleChipRemove(item) : undefined}
                multiple
              />
            ))}
          </div>
        ))}
    </div>
  );
};

export default TextFieldWithChip;
