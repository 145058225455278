import React from 'react';
import { FormControlLabel, makeStyles, Radio, RadioProps } from '@material-ui/core';
import { IcoRadioSelected, IcoRadioSelectedDisabled, IcoRadioUnselected, IcoRadioUnselectedDisabled } from '../assets';

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'flex-start',
    margin: '0',
  },
  radio: {
    padding: '0',
  },
}));

interface IOwnProps {
  label?: React.ReactNode;
}

type IProps = IOwnProps & RadioProps;

const MDSRadio = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { value, label, disabled, ...restProps } = props;

  return (
    <FormControlLabel
      value={value}
      label={label}
      className={classes.root}
      control={
        <Radio
          icon={disabled ? <IcoRadioUnselectedDisabled /> : <IcoRadioUnselected />}
          checkedIcon={disabled ? <IcoRadioSelectedDisabled /> : <IcoRadioSelected />}
          disabled={disabled}
          className={classes.radio}
          {...restProps}
        />
      }
    />
  );
};

export default MDSRadio;
