import { FileUploaderTypeEnum, LanguageEnum } from './@types';

export const DefaultMaxFileSize = 20971520;

export const acceptedFilesByType = {
  [FileUploaderTypeEnum.Image]: ['image/jpg', 'image/jpeg', 'image/png'],
  [FileUploaderTypeEnum.Document]: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
  [FileUploaderTypeEnum.Pdf]: ['application/pdf'],
  [FileUploaderTypeEnum.Excel]: ['.xlsx', '.xls', '.xlsm'],
  [FileUploaderTypeEnum.Trademark]: [
    '.pptx',
    '.doc',
    'application/zip',
    'application/pdf',
    'image/jpg',
    'image/jpeg',
    'image/png',
  ],
  [FileUploaderTypeEnum.Report]: ['application/pdf', '.ppt', '.pptx', '.doc', '.docx', '.xls', '.xlsx'],
  [FileUploaderTypeEnum.All]: [],
};

export const LocalizedLabel = {
  [LanguageEnum.English]: {
    DragAndDrop: 'Drag and drop or',
    Browse: 'Browse',
    AnErrorOccurred: 'An error occurred. Please try again.',
    Limit: (limit: number) => `A maximum of ${limit} files can be attached.`,
    DataType: 'This file(s) cannot be attached here.',
    FileFormat: 'The file is not a supported format',
    FileSize: (MaxFileSize: string) => `The file exceeds ${MaxFileSize}`,
    Disabled: 'Drag and drop unavailble',
    UploadingFiles: 'Uploading file...',
  },
  [LanguageEnum.Korean]: {
    DragAndDrop: '파일을 끌어서 놓거나',
    Browse: '선택해주세요',
    AnErrorOccurred: '오류가 발생했습니다. 다시 시도해주세요.',
    Limit: (limit: number) => `최대 ${limit}개의 파일까지 첨부할 수 있습니다.`,
    DataType: '이 파일은 여기에 첨부할 수 없습니다.',
    FileFormat: '지원되지 않는 포맷입니다.',
    FileSize: (MaxFileSize: string) => `파일크기는 ${MaxFileSize}를 넘지 않아야 합니다.`,
    Disabled: '비활성화됨: 이 필드는 수정할 수 없습니다',
    UploadingFiles: '파일 업로드 중...',
  },
} as const;

export const imageHeaderTypeList: Record<string, string> = {
  '89504e47': 'image/png',
  ffd8ffe0: 'image/jpeg',
  ffd8ffe1: 'image/jpeg',
  ffd8ffe2: 'image/jpeg',
  ffd8ffe3: 'image/jpeg',
  ffd8ffe8: 'image/jpeg',
  ffd8ffdb: 'image/jpeg',
};
