import React, { JSX } from 'react';
import clsx from 'clsx';
import { Button as MUIButton, ButtonProps, makeStyles } from '@material-ui/core';
import theme from './Theme';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: 'auto',
    transition: 'none',
    borderRadius: '4px',
    textTransform: 'none',
    letterSpacing: 0,
    '& > .MuiButton-label': {
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    '&:hover': {
      opacity: 1,
    },
    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
      display: 'block',
      margin: 0,
      '& > svg': {
        display: 'block',
      },
    },
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '& .MuiButton-endIcon': {
      marginLeft: '4px',
    },
    ...theme.typography.medium,
    '&.small': {
      padding: '0 8px',
      '&.endIcon': {
        paddingRight: '6px',
      },
      '&.startIcon': {
        paddingLeft: '6px',
      },
      '&.outline': {
        padding: '0 7px',
        '&.endIcon': {
          paddingRight: '5px',
        },
        '&.startIcon': {
          paddingLeft: '5px',
        },
      },
      '&:not(.text)': {
        height: '26px',
      },
      '& .MuiButton-startIcon': {
        marginRight: '2px',
      },
      '& .MuiButton-endIcon': {
        marginLeft: '2px',
      },
      ...theme.typography.T13,
      '& .MuiButton-startIcon > svg, & .MuiButton-endIcon svg': {
        width: '16px',
        height: '16px',
      },
    },
    '&.medium': {
      padding: '0 12px',
      '&.outline': {
        padding: '0 11px',
        '&.endIcon': {
          paddingRight: '7px',
        },
        '&.startIcon': {
          paddingLeft: '7px',
        },
      },
      '&.endIcon': {
        paddingRight: '8px',
      },
      '&.startIcon': {
        paddingLeft: '8px',
      },
      '&:not(.text)': {
        height: '32px',
      },
      ...theme.typography.T14,
      '& .MuiButton-startIcon > svg, & .MuiButton-endIcon svg': {
        width: '16px',
        height: '16px',
      },
    },
    '&.large': {
      padding: '0 14px',
      '&.outline': {
        padding: '0 13px',
        '&.endIcon': {
          paddingRight: '9px',
        },
        '&.startIcon': {
          paddingLeft: '9px',
        },
      },
      '&.endIcon': {
        paddingRight: '10px',
      },
      '&.startIcon': {
        paddingLeft: '10px',
      },
      '&:not(.text)': {
        height: '38px',
      },
      ...theme.typography.T16,
      '& .MuiButton-startIcon > svg, & .MuiButton-endIcon svg': {
        width: '20px',
        height: '20px',
      },
    },
    '&.extra_large': {
      padding: '0 16px',
      '&.outline': {
        padding: '0 15px',
        '&.endIcon': {
          paddingRight: '11px',
        },
        '&.startIcon': {
          paddingLeft: '11px',
        },
      },
      '&.endIcon': {
        paddingRight: '12px',
      },
      '&.startIcon': {
        paddingLeft: '12px',
      },
      '&:not(.text)': {
        height: '44px',
      },
      ...theme.typography.T20,
      '& .MuiButton-startIcon > svg, & .MuiButton-endIcon svg': {
        width: '24px',
        height: '24px',
      },
    },
    ...getButtonStyles(),
  },
  prefixIcon: {
    marginRight: '2px',
    display: 'block',
  },
  suffixIcon: {
    marginLeft: '2px',
    display: 'block',
  },
}));

const getColorFillBackground = (key: IOwnProps['color']): string => {
  if (key === 'white') {
    return theme.palette.white;
  }
  return theme.palette[key][['bluegray', 'blue', 'yellow'].includes(key) ? '700' : '600'];
};

const getColorFillText = (key: IOwnProps['color']): string => {
  if (key === 'white') {
    return theme.palette.bluegray[900];
  }
  return theme.palette.white;
};

const getFillTextHoverBackgroundColor = (key: IOwnProps['color']): string => {
  if (key === 'white') {
    return theme.palette.bluegray[100];
  }
  return theme.palette[key][['bluegray', 'blue', 'yellow'].includes(key) ? '800' : '700'];
};

const getColorByIsBlueGray = (key: IOwnProps['color']): string => {
  if (key === 'white') {
    return theme.palette[key];
  }
  if (key === 'yellow') {
    return theme.palette[key][800];
  }
  if (key === 'bluegray') {
    return theme.palette[key][900];
  }
  return theme.palette[key][700];
};

const getTintBackgroundColor = (key: IOwnProps['color']): string => {
  if (key === 'white') {
    return theme.palette.bluegray[800];
  }
  return key === 'bluegray' ? theme.palette[key]['150'] : theme.palette[key]['50'];
};

const getTintHoverBackgroundColor = (key: IOwnProps['color']): string => {
  if (key === 'white') {
    return theme.palette.bluegray[700];
  }
  return theme.palette[key][key === 'bluegray' ? '300' : '200'];
};

const getButtonStyles = () => {
  const result = {};

  (Object.keys(theme.palette) as IOwnProps['color'][]).forEach((key: IOwnProps['color']) => {
    Object.assign(result, {
      [`&.${key}.fill`]: {
        backgroundColor: getColorFillBackground(key),
        color: getColorFillText(key),
        '& svg': {
          color: getColorFillText(key),
          '& path': {
            fill: getColorFillText(key),
          },
        },
        '&:hover': {
          backgroundColor: getFillTextHoverBackgroundColor(key),
        },
        '&:disabled': {
          backgroundColor: theme.palette[key]['300'],
        },
        '&.completed': {
          color: theme.palette.bluegray['600'],
          backgroundColor: theme.palette.bluegray['200'],
          cursor: 'default',
          '& svg  > path': {
            fill: theme.palette.bluegray['600'],
          },
          '&:hover': {
            '& svg  > path': {
              fill: theme.palette.bluegray['600'],
            },
          },
        },
      },
      [`&.${key}.tint`]: {
        backgroundColor: getTintBackgroundColor(key),
        color: getColorByIsBlueGray(key),
        '& svg': {
          color: getColorByIsBlueGray(key),
          '& path': {
            fill: getColorByIsBlueGray(key),
          },
        },
        '&:hover': {
          backgroundColor: getTintHoverBackgroundColor(key),
        },
        '&:disabled': {
          color: theme.palette[key]['300'],
          '& svg  > path': {
            fill: theme.palette[key]['300'],
          },
        },
        '&.completed': {
          color: theme.palette.bluegray['600'],
          backgroundColor: theme.palette.bluegray['200'],
          cursor: 'default',
          '& svg  > path': {
            fill: theme.palette.bluegray['600'],
          },
        },
      },
      [`&.${key}.outline`]: {
        border: `1px solid ${key === 'white' ? theme.palette.white : theme.palette.bluegray['200']}`,
        color: getColorByIsBlueGray(key),
        backgroundColor: key === 'white' ? 'transparent' : theme.palette.white,
        '& svg': {
          color: getColorByIsBlueGray(key),
          '& path': {
            fill: getColorByIsBlueGray(key),
          },
        },
        '&:hover': {
          backgroundColor: key === 'white' ? theme.palette.bluegray[700] : theme.palette.bluegray['100'],
        },
        '&:disabled': {
          border: `1px solid ${theme.palette.bluegray['150']}`,
          color: theme.palette[key]['300'],
          '& svg  > path': {
            fill: theme.palette[key]['300'],
          },
        },
        '&.completed': {
          color: theme.palette.bluegray['600'],
          backgroundColor: theme.palette.bluegray['50'],
          cursor: 'default',
          '& svg  > path': {
            fill: theme.palette.bluegray['600'],
          },
        },
      },
      [`&.${key}.text`]: {
        padding: '0!important',
        color: getColorByIsBlueGray(key),
        '& svg': {
          color: getColorByIsBlueGray(key),
          '& path': {
            fill: getColorByIsBlueGray(key),
          },
        },
        '&:hover': {
          color: getFillTextHoverBackgroundColor(key),
          background: 'none',
        },
        '&:disabled': {
          color: theme.palette[key]['300'],
          '& svg  > path': {
            fill: theme.palette[key]['300'],
          },
          opacity: key === 'white' ? 0.4 : 1,
        },
        '&.small': {
          ...theme.typography.T13,
        },
        '&.medium': {
          ...theme.typography.T14,
        },
        '&.large': {
          ...theme.typography.T16,
        },
        '&.extra_large': {
          ...theme.typography.T20,
        },
        '&.completed': {
          color: theme.palette.bluegray['600'],
          cursor: 'default',
          '& svg  > path': {
            fill: theme.palette.bluegray['600'],
          },
        },
      },
    });
  });

  return result;
};

interface IOwnProps {
  variant: 'fill' | 'tint' | 'outline' | 'text';
  color: keyof typeof theme.palette;
  size: 'small' | 'medium' | 'large' | 'extra_large';
  completed?: boolean;
  style?: React.CSSProperties;
}

type IProps = IOwnProps & Omit<ButtonProps, 'variant' | 'color' | 'size' | 'style'>;

export const MDSButton = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { size, color, variant, children, completed, style, endIcon, startIcon, className, ...restProps } = props;

  return (
    <MUIButton
      className={clsx(classes.button, className, size, color, variant, {
        completed: completed,
        endIcon: !!endIcon,
        startIcon: !!startIcon,
      })}
      style={{
        ...style,
      }}
      {...restProps}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {children}
    </MUIButton>
  );
};

export default MDSButton;
