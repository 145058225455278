import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { MDSTheme } from '../index';
import { IFile, IMDSFileUploaderProps } from './index';
import { ChipList } from './ChipList';
import { FileUploaderTypeEnum, Progress, LanguageEnum } from './@types';
import { Dropzone } from './Dropzone';
import { LoadingProgress } from './LoadingProgress';
import { ImagePreviewItem } from './ImagePreviewItem';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'grid',
    gap: '12px',
    '&.withTitle:not(:has(> div:only-child))': {
      gridTemplateRows: 'auto 1fr',
    },
    '&.isPreviewScrollable': {
      height: '100%',
    },
  },
  hidden: {
    display: 'none',
  },
  dropzone: {
    display: 'grid',
    overflow: 'hidden',
    borderRadius: '4px',
    border: `1px solid ${MDSTheme.palette.bluegray[200]}`,
    '&>div': {
      minHeight: '100%',
    },
    '&.isPreviewScrollable': {
      display: 'block',
      overflowY: 'auto',
    },
  },
  highlight: {
    border: `3px solid ${MDSTheme.palette.blue[700]}`,
  },
  readonlyWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: MDSTheme.palette.bluegray[100],
  },
  previewWrapper: {
    position: 'relative',
    padding: '8px',
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    overflow: 'hidden',
    gap: '4px',
    '&.isPreviewScrollable': {
      overflow: 'unset',
    },
  },
  overlayDropzone: {
    position: 'sticky',
    bottom: '8px',
    zIndex: 10,
    gridColumn: '1 / -1',
    backgroundColor: MDSTheme.palette.white,
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '50%',
      bottom: '0',
      transform: 'translateY(-200%)',
      background: `linear-gradient(transparent, ${MDSTheme.palette.white})`,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '8px',
      top: '100%',
      backgroundColor: MDSTheme.palette.white,
    },
  },
  skeleton: {
    maxWidth: 'unset',
    width: '100%',
    height: '100%',
  },
}));

interface IProps<T> extends Omit<IMDSFileUploaderProps<T>, 'onDelete'> {
  uploadProgress: Progress;
  highlight: boolean;
  onHighlightOn: () => void;
  onHighlightOff: () => void;
  onDragOn: React.DragEventHandler;
  onDrop: React.DragEventHandler;
  onPaste: React.ClipboardEventHandler;
  onDelete: (index: number) => () => void;
  onAddButtonClick: () => void;
}

export const FileUploader = <T extends IFile>(props: IProps<T>): React.ReactNode => {
  const {
    highlight,
    uploadProgress,
    onHighlightOn,
    onHighlightOff,
    onDragOn,
    onDrop,
    onPaste,
    onDelete,
    onAddButtonClick,
    onClickItem,
    ...fileUploaderProps
  } = props;
  const {
    variant = 'preview',
    renderTitleComponent,
    type = FileUploaderTypeEnum.All,
    files,
    previewColumns = files.length,
    disabled = false,
    disabledComponent,
    readOnly = false,
    renderActionButtons,
    renderPreviewComponent,
    progressSizes,
    className,
    style,
    locale = LanguageEnum.English,
    isOverlayDropzoneShow,
    isPreviewScrollable,
  } = fileUploaderProps;
  const classes = useStyles();

  const loadingFilesLength = Object.values(uploadProgress).filter(({ isLoading }) => isLoading).length;

  const isActive = !disabled && !readOnly;

  return (
    <div
      className={clsx(classes.wrapper, { withTitle: !!renderTitleComponent, isPreviewScrollable })}
      style={{ ...style }}
    >
      {renderTitleComponent?.({
        onAdd: onAddButtonClick,
        isLoading: !!loadingFilesLength,
        type,
        disabled: !isActive,
      })}

      <div
        className={clsx(
          classes.dropzone,
          { [classes.highlight]: isActive && highlight, isPreviewScrollable },
          className
        )}
        onDragEnter={!isActive ? undefined : onDragOn}
        onDragOver={!isActive ? undefined : onDragOn}
        onDragLeave={!isActive ? undefined : onHighlightOff}
        onDrop={!isActive ? undefined : onDrop}
        onPaste={!isActive ? undefined : onPaste}
        onFocus={!isActive ? undefined : onHighlightOn}
        onBlur={!isActive ? undefined : onHighlightOff}
        tabIndex={0}
        style={style}
      >
        {loadingFilesLength > 0 || progressSizes?.totalSize ? (
          // loading progress
          <div className={classes.previewWrapper}>
            <LoadingProgress locale={locale} uploadProgress={uploadProgress} progressProp={progressSizes} />
          </div>
        ) : (variant === 'chipList' || files.length < 1) && !isActive ? (
          // disabled
          disabledComponent ?? <Dropzone {...fileUploaderProps} />
        ) : files.length > 0 && variant === 'preview' ? (
          // image preview
          <div
            className={clsx(classes.previewWrapper, 'preview', { isPreviewScrollable })}
            style={{
              gridTemplateColumns: `repeat(${Math.min(previewColumns, 3)}, 1fr)`,
              alignContent: previewColumns === 1 ? 'start' : 'center',
            }}
          >
            {files.map(
              (item, index) =>
                !uploadProgress[item.url]?.isLoading && (
                  <ImagePreviewItem
                    key={item.url}
                    onClick={onClickItem}
                    file={item}
                    index={index}
                    disabled={disabled}
                    readOnly={readOnly}
                    onDelete={onDelete}
                    renderActionButtons={renderActionButtons}
                  />
                )
            )}
            {isOverlayDropzoneShow && (
              <div className={classes.overlayDropzone}>
                <Dropzone onAddButtonClick={!isActive ? undefined : onAddButtonClick} {...fileUploaderProps} />
              </div>
            )}
          </div>
        ) : (
          // dropzone
          <Dropzone onAddButtonClick={onAddButtonClick} {...fileUploaderProps} />
        )}
      </div>
      {variant === 'chipList' && files.length > 0 && (
        <ChipList files={files} onDelete={readOnly ? undefined : onDelete} uploadProgress={uploadProgress} />
      )}
      {renderPreviewComponent?.({
        files,
        disabled,
        isLoading: !!loadingFilesLength,
        type,
        onAdd: onAddButtonClick,
        onDelete,
        progress: uploadProgress,
      })}
    </div>
  );
};
