/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyResponse,
  SellerSellerAnalyticDetailDataDrivenProtocolsRequestDemandLetterRequest,
  SellerSellerAnalyticDetailDataDrivenProtocolsRequestUDRPRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SellerAnalyticsDataDriven<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SellerAnalyticsDataDriven
   * @name DetailDataDrivenUdrpCreate
   * @request POST:/seller-analytic/detail/data-driven/udrp
   */
  detailDataDrivenUdrpCreate = (
    data: SellerSellerAnalyticDetailDataDrivenProtocolsRequestUDRPRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/seller-analytic/detail/data-driven/udrp`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsDataDriven
   * @name DetailDataDrivenDemandLetterCreate
   * @request POST:/seller-analytic/detail/data-driven/demand-letter
   */
  detailDataDrivenDemandLetterCreate = (
    data: SellerSellerAnalyticDetailDataDrivenProtocolsRequestDemandLetterRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/seller-analytic/detail/data-driven/demand-letter`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
