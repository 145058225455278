export const RAW_COLORS = {
  white: '#ffffff',
  whiteAlpha50: 'rgba(255, 255, 255, 0.5)',
  whiteAlpha40: 'rgba(255, 255, 255, 0.4)',
  whiteAlpha30: 'rgba(255, 255, 255, 0.3)',
  whiteAlpha20: 'rgba(255, 255, 255, 0.2)',
  whiteAlpha10: 'rgba(255, 255, 255, 0.1)',
  clear: 'rgba(255, 255, 255, 0)',
  blue50: '#e9eefd',
  blue100: '#d7e1fb',
  blue200: '#c0cff8',
  blue300: '#a3b9f5',
  blue400: '#7999f1',
  blue500: '#4f79ec',
  blue600: '#2d5fe9',
  blue700: '#1648d2',
  blue800: '#1f3f99',
  blue900: '#142a66',
  bluegray50: '#f9fafb',
  bluegray100: '#f1f4f6',
  bluegray150: '#eaedf0',
  bluegray200: '#dfe3e8',
  bluegray300: '#c4cdd5',
  bluegray400: '#aab5c0',
  bluegray500: '#919eab',
  bluegray600: '#768695',
  bluegray700: '#637381',
  bluegray750: '#515e6b',
  bluegray800: '#454f5b',
  bluegray850: '#343e49',
  bluegray900: '#212b36',
  bluegray1000: '#161c24',
  red50: '#fce9e6',
  red100: '#fcd2cb',
  red200: '#f6beb4',
  red300: '#f2a091',
  red400: '#ec755f',
  red500: '#e64a2d',
  red600: '#e12705',
  red700: '#cb1400',
  red800: '#871703',
  red900: '#5a0f02',
  orange50: '#ffefe5',
  orange100: '#ffe0cc',
  orange200: '#ffd0b2',
  orange300: '#ffbb8f',
  orange400: '#ff9d5c',
  orange500: '#ff7e29',
  orange600: '#ff6500',
  orange700: '#d35200',
  orange800: '#a13f00',
  orange900: '#662800',
  yellow50: '#fff3dc',
  yellow100: '#ffe5b6',
  yellow200: '#ffda95',
  yellow300: '#ffcb6c',
  yellow400: '#ffbd42',
  yellow500: '#fdaf1e',
  yellow600: '#f1a416',
  yellow700: '#ca8402',
  yellow800: '#9b6500',
  yellow900: '#4c3000',
  green50: '#e5f7e5',
  green100: '#ccefcc',
  green200: '#b2e7b2',
  green300: '#8fdc8f',
  green400: '#5ccc5c',
  green500: '#29bc29',
  green600: '#00ad00',
  green700: '#008000',
  green800: '#1c5e20',
  green900: '#004600',
  teal50: '#e5f5f7',
  teal100: '#cdecf1',
  teal200: '#b3e1e9',
  teal300: '#91d5e0',
  teal400: '#5fc1d2',
  teal500: '#2caec4',
  teal600: '#049eb8',
  teal700: '#037e93',
  teal800: '#025e6e',
  teal900: '#013f49',
  lightblue50: '#e5f6fe',
  lightblue100: '#ccedfd',
  lightblue200: '#b2e4fc',
  lightblue300: '#8fd8fb',
  lightblue400: '#5cc7f9',
  lightblue500: '#29b5f8',
  lightblue600: '#00a7f6',
  lightblue700: '#007eba',
  lightblue800: '#005c96',
  lightblue900: '#004262',
  indigo50: '#f0ecfa',
  indigo100: '#e3daf6',
  indigo200: '#d4c7f0',
  indigo300: '#c1adeb',
  indigo400: '#a588e1',
  indigo500: '#8963d8',
  indigo600: '#7245d0',
  indigo700: '#512aa3',
  indigo800: '#3d1f7a',
  indigo900: '#281551',
  purple50: '#f4e5f7',
  purple100: '#eaccef',
  purple200: '#dfb2e7',
  purple300: '#d18fdc',
  purple400: '#bd5ccd',
  purple500: '#a829bd',
  purple600: '#9700b0',
  purple700: '#78008c',
  purple800: '#5a0069',
  purple900: '#3c0046',
  magenta50: '#fae7f0',
  magenta100: '#f7d0e2',
  magenta200: '#f2b8d3',
  magenta300: '#ed98bf',
  magenta400: '#e469a2',
  magenta500: '#dc3a85',
  magenta600: '#d5146d',
  magenta700: '#aa1057',
  magenta800: '#7f0c41',
  magenta900: '#55082b',
  brown50: '#f0ecea',
  brown100: '#e2d9d7',
  brown200: '#d2c6c2',
  brown300: '#bfaca6',
  brown400: '#a1877e',
  brown500: '#846156',
  brown600: '#6c4335',
  brown700: '#56352a',
  brown800: '#40281f',
  brown900: '#2b1a15',
  grey50: '#fafafa',
  grey100: '#f5f5f5',
  grey200: '#eeeeee',
  grey300: '#dddddd',
  grey400: '#cccccc',
  grey500: '#aaaaaa',
  grey600: '#777777',
  grey700: '#555555',
  grey800: '#333333',
  grey900: '#111111',
  black: '#000000',
  blackAlpha90: 'rgba(0, 0, 0, 0.9)',
  blackAlpha80: 'rgba(0, 0, 0, 0.8)',
  blackAlpha70: 'rgba(0, 0, 0, 0.7)',
  blackAlpha60: 'rgba(0, 0, 0, 0.6)',
  blackAlpha50: 'rgba(0, 0, 0, 0.5)',
  blackAlpha40: 'rgba(0, 0, 0, 0.4)',
  blackAlpha30: 'rgba(0, 0, 0, 0.3)',
  blackAlpha20: 'rgba(0, 0, 0, 0.2)',
  blackAlpha10: 'rgba(0, 0, 0, 0.1)',
  blackAlpha5: 'rgba(0, 0, 0, 0.05)',
  blueTeal: 'linear-gradient(90deg,rgba(95, 193, 210, 1) 0%,rgba(79, 121, 236, 1) 100%)',
};

export const COLOR_TOKENS = {
  bg: {
    fill: {
      neutral: {
        default: {
          normal: RAW_COLORS.white,
          hover: RAW_COLORS.bluegray100,
          completed: RAW_COLORS.bluegray50,
          disabled: RAW_COLORS.white,
        },
        tint: {
          normal: RAW_COLORS.bluegray100,
          hover: RAW_COLORS.bluegray300,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.bluegray150,
        },
        strong: {
          normal: RAW_COLORS.bluegray700,
          hover: RAW_COLORS.bluegray800,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.bluegray300,
        },
        weak: {
          normal: RAW_COLORS.bluegray300,
          disabled: RAW_COLORS.bluegray150,
        },
      },
      primary: {
        default: {
          normal: RAW_COLORS.blue700,
          hover: RAW_COLORS.blue800,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.blue300,
        },
        tint: {
          normal: RAW_COLORS.blue50,
          hover: RAW_COLORS.blue200,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.blue50,
        },
      },
      critical: {
        default: {
          normal: RAW_COLORS.red600,
          hover: RAW_COLORS.red700,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.red300,
        },
        tint: {
          normal: RAW_COLORS.red50,
          hover: RAW_COLORS.red200,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.red50,
        },
      },
      success: {
        default: {
          normal: RAW_COLORS.green600,
          hover: RAW_COLORS.green700,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.green300,
        },
        tint: {
          normal: RAW_COLORS.green50,
          hover: RAW_COLORS.green200,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.green50,
        },
      },
      warning: {
        default: {
          normal: RAW_COLORS.yellow700,
          hover: RAW_COLORS.yellow800,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.yellow300,
        },
        tint: {
          normal: RAW_COLORS.yellow50,
          hover: RAW_COLORS.yellow200,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.yellow50,
        },
      },
      teal: {
        default: {
          normal: RAW_COLORS.teal600,
          hover: RAW_COLORS.teal700,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.teal300,
        },
        tint: {
          normal: RAW_COLORS.teal50,
          hover: RAW_COLORS.teal200,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.teal50,
        },
      },
      purple: {
        default: {
          normal: RAW_COLORS.purple600,
          hover: RAW_COLORS.purple700,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.purple300,
        },
        tint: {
          normal: RAW_COLORS.purple50,
          hover: RAW_COLORS.purple200,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.purple50,
        },
      },
      inverse: {
        primary: '#7aacff',
        default: {
          normal: RAW_COLORS.white,
          hover: RAW_COLORS.bluegray200,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.whiteAlpha40,
        },
        tint: {
          normal: RAW_COLORS.whiteAlpha20,
          hover: RAW_COLORS.whiteAlpha30,
          completed: RAW_COLORS.bluegray200,
          disabled: RAW_COLORS.whiteAlpha20,
        },
        borderbutton: {
          hover: RAW_COLORS.whiteAlpha20,
        },
      },
    },
    surface: {
      neutral: {
        default: {
          normal: RAW_COLORS.white,
          hover: RAW_COLORS.bluegray100,
          disabled: RAW_COLORS.white,
        },
        secondary: {
          normal: RAW_COLORS.bluegray50,
          hover: RAW_COLORS.bluegray150,
          disabled: RAW_COLORS.bluegray50,
        },
        tertiary: {
          normal: RAW_COLORS.bluegray100,
          hover: RAW_COLORS.bluegray200,
        },
      },
      primary: {
        default: {
          normal: RAW_COLORS.blue50,
          hover: RAW_COLORS.blue200,
          disabled: RAW_COLORS.blue50,
        },
      },
      critical: {
        default: {
          normal: RAW_COLORS.red50,
          hover: RAW_COLORS.red200,
          disabled: RAW_COLORS.red50,
        },
      },
      success: {
        default: {
          normal: RAW_COLORS.green50,
          hover: RAW_COLORS.green200,
          disabled: RAW_COLORS.green50,
        },
      },
      warning: {
        default: {
          normal: RAW_COLORS.yellow50,
          hover: RAW_COLORS.yellow200,
          disabled: RAW_COLORS.yellow50,
        },
      },
      teal: {
        default: { normal: RAW_COLORS.teal50, hover: RAW_COLORS.teal200, disabled: RAW_COLORS.teal50 },
      },
      purple: {
        default: {
          normal: RAW_COLORS.purple50,
          hover: RAW_COLORS.purple200,
          disabled: RAW_COLORS.purple50,
        },
      },
      inverse: {
        light: RAW_COLORS.bluegray900,
        dark: RAW_COLORS.bluegray1000,
      },
      selected: {
        default: {
          normal: RAW_COLORS.blue50, // surface/primary/default 와 동일
          hover: RAW_COLORS.blue200, // surface/primary/defaultHover 와 동일
        },
      },
    },
  },
  content: {
    on_default_color: RAW_COLORS.white,
    placeholder: {
      normal: RAW_COLORS.bluegray300,
    },
    neutral: {
      default: {
        normal: RAW_COLORS.bluegray900,
        hover: RAW_COLORS.bluegray1000,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.bluegray300,
      },
      secondary: {
        normal: RAW_COLORS.bluegray700,
        hover: RAW_COLORS.bluegray800,
        disabled: RAW_COLORS.bluegray300,
      },
      tertiary: {
        normal: RAW_COLORS.bluegray400,
        hover: RAW_COLORS.bluegray500,
        disabled: RAW_COLORS.bluegray300,
      },
    },
    primary: {
      default: {
        normal: RAW_COLORS.blue700,
        hover: RAW_COLORS.blue800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.blue300,
      },
    },
    critical: {
      default: {
        normal: RAW_COLORS.red700,
        hover: RAW_COLORS.red800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.red300,
      },
    },
    success: {
      default: {
        normal: RAW_COLORS.green700,
        hover: RAW_COLORS.green800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.green300,
      },
    },
    warning: {
      default: {
        normal: RAW_COLORS.yellow700,
        hover: RAW_COLORS.yellow800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.yellow300,
      },
    },
    teal: {
      default: {
        normal: RAW_COLORS.teal700,
        hover: RAW_COLORS.teal800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.teal300,
      },
    },
    purple: {
      default: {
        normal: RAW_COLORS.purple700,
        hover: RAW_COLORS.purple800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.purple300,
      },
    },
    inverse: {
      default: {
        normal: RAW_COLORS.white,
        hover: RAW_COLORS.bluegray100,
        disabled: RAW_COLORS.whiteAlpha40,
        completed: RAW_COLORS.bluegray400,
      },
      primary: '#7aacff',
    },
    selected: {
      default: {
        normal: RAW_COLORS.blue700, // content/primary/default 와 동일
        hover: RAW_COLORS.blue800, // content/primary/defaultHover 와 동일
      },
    },
  },
  border: {
    neutral: {
      default: {
        normal: RAW_COLORS.bluegray200,
        hover: RAW_COLORS.bluegray200,
        completed: RAW_COLORS.bluegray200,
        disabled: RAW_COLORS.bluegray150,
      },
      strong: {
        normal: RAW_COLORS.bluegray300,
      },
      weak: {
        normal: RAW_COLORS.bluegray150,
      },
    },
    primary: {
      weak: {
        normal: RAW_COLORS.bluegray200,
        hover: RAW_COLORS.bluegray200,
        completed: RAW_COLORS.bluegray200,
        disabled: RAW_COLORS.bluegray150,
      },
      default: {
        normal: RAW_COLORS.blue700,
        hover: RAW_COLORS.blue800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.blue300,
      },
    },
    critical: {
      weak: {
        normal: RAW_COLORS.bluegray200,
        hover: RAW_COLORS.bluegray200,
        completed: RAW_COLORS.bluegray200,
        disabled: RAW_COLORS.bluegray150,
      },
      default: {
        normal: RAW_COLORS.red700,
        hover: RAW_COLORS.red800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.red300,
      },
    },
    success: {
      weak: {
        normal: RAW_COLORS.bluegray200,
        hover: RAW_COLORS.bluegray200,
        completed: RAW_COLORS.bluegray200,
        disabled: RAW_COLORS.bluegray150,
      },
      default: {
        normal: RAW_COLORS.green700,
        hover: RAW_COLORS.green800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.green300,
      },
    },
    warning: {
      weak: {
        normal: RAW_COLORS.bluegray200,
        hover: RAW_COLORS.bluegray200,
        completed: RAW_COLORS.bluegray200,
        disabled: RAW_COLORS.bluegray150,
      },
      default: {
        normal: RAW_COLORS.yellow700,
        hover: RAW_COLORS.yellow800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.yellow300,
      },
    },
    teal: {
      weak: {
        normal: RAW_COLORS.bluegray200,
        hover: RAW_COLORS.bluegray200,
        completed: RAW_COLORS.bluegray200,
        disabled: RAW_COLORS.bluegray150,
      },
      default: {
        normal: RAW_COLORS.teal700,
        hover: RAW_COLORS.teal800,
        completed: RAW_COLORS.bluegray200,
        disabled: RAW_COLORS.teal300,
      },
    },
    purple: {
      weak: {
        normal: RAW_COLORS.bluegray200,
        hover: RAW_COLORS.bluegray200,
        completed: RAW_COLORS.bluegray200,
        disabled: RAW_COLORS.bluegray150,
      },
      default: {
        normal: RAW_COLORS.purple700,
        hover: RAW_COLORS.purple800,
        completed: RAW_COLORS.bluegray600,
        disabled: RAW_COLORS.purple300,
      },
    },
    inverse: {
      default: {
        normal: RAW_COLORS.white,
        hover: RAW_COLORS.white,
        disabled: RAW_COLORS.bluegray400,
      },
      primary: {
        normal: '#7aacff',
      },
    },
    selected: {
      default: {
        normal: RAW_COLORS.blue700, // border/primary/default 와 동일
        hover: RAW_COLORS.blue800, // border/primary/defaultHover 와 동일
      },
    },
    target: {
      default: RAW_COLORS.blackAlpha10,
      ai: RAW_COLORS.indigo200,
    },
  },

  /**
   * @deprecated
   * - todo-@jamie: comp color들은 해당 컴포넌트들 내부로 옮기고 여기서는 제거하기.
   * - https://bitbucket.org/marqvision/marqvision-web/pull-requests/2574 PR 참고.
   */
  comp: {
    divider: {
      color: {
        default: RAW_COLORS.bluegray150,
        weak: RAW_COLORS.bluegray100,
        strong: RAW_COLORS.bluegray200,
        circle: RAW_COLORS.bluegray400,
      },
    },
    input: {
      color: {
        bg: {
          normal: RAW_COLORS.white,
          disabled: RAW_COLORS.bluegray100,
        },
        border: {
          normal: RAW_COLORS.bluegray200,
          active: RAW_COLORS.blue700,
          error: RAW_COLORS.red600,
          hover: RAW_COLORS.bluegray300,
          disabled: RAW_COLORS.bluegray200,
        },
      },
    },
    table: {
      color: {
        bg: {
          default: {
            normal: RAW_COLORS.white,
            hover: RAW_COLORS.bluegray50,
          },
          secondary: {
            normal: RAW_COLORS.bluegray50,
            hover: RAW_COLORS.bluegray100,
          },
          viewing: {
            normal: RAW_COLORS.blue50,
            hover: RAW_COLORS.blue100,
          },
        },
        border: {
          horizontal: {
            header: RAW_COLORS.bluegray200,
            body: RAW_COLORS.bluegray100,
          },
          vertical: {
            default: RAW_COLORS.bluegray150,
          },
        },
      },
    },
    dimmed: {
      color: {
        default: RAW_COLORS.blackAlpha50,
      },
    },
  },
};
