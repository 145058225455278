import React, { useEffect, useState } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider as MDSThemeProvider } from '@emotion/react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { theme } from '@marqvision/shared/src/library';
import { MDSThemeValue, MDSResetCSS } from '@marqvision/mds-v2';
import { LanguageEnum } from './LocalizedStrings';
import { LanguageLocalStorageIdentifier, useSentryUserInfoUpdate } from './hooks';
import './App.css';
import { ErrorBoundaryView } from './pages/ErrorPage/ErrorBoundary';

export interface ILanguageContext {
  language: LanguageEnum;
  setLanguage: React.Dispatch<React.SetStateAction<LanguageEnum>>;
}

export const LanguageContext = React.createContext<ILanguageContext>({
  language: LanguageEnum.English,
  setLanguage: () => {},
});

const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK,
  clientKey: process.env.REACT_APP_GROWTHBOOK_KEY,
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('### Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

const App = (): JSX.Element => {
  const [language, setLanguage] = useState<LanguageEnum>(
    (() => {
      /**
       * 초기 언어 우선순위
       * 1. Session Storage 에 저장 되어있는 언어
       * 2. 브라우저 내장 객체의 언어
       * 3. 영어
       */
      const initialLanguage =
        (sessionStorage.getItem(LanguageLocalStorageIdentifier) as LanguageEnum) ||
        (typeof navigator.language === 'string' && (navigator.language.substring(0, 2) as LanguageEnum)) ||
        LanguageEnum.English;

      return initialLanguage;
    })()
  );

  useEffect(() => {
    sessionStorage.setItem(LanguageLocalStorageIdentifier, language);
  }, [language]);

  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
    // crash logging
    if (sessionStorage.getItem('good_exit') && sessionStorage.getItem('good_exit') !== 'true') {
      Sentry.getCurrentScope().setExtras({
        time_before_crash: sessionStorage.getItem('time_before_crash'),
        js_heap_size_limit: sessionStorage.getItem('js_heap_size_limit'),
        total_js_heap_size: sessionStorage.getItem('total_js_heap_size'),
        used_js_heap_size: sessionStorage.getItem('used_js_heap_size'),
      });
      Sentry.captureMessage('crash detected');
    }
  }, []);

  useSentryUserInfoUpdate();

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <Sentry.ErrorBoundary fallback={(errorData) => <ErrorBoundaryView errorData={errorData} />}>
        <MuiThemeProvider theme={{ ...theme, ...MDSThemeValue }}>
          <CssBaseline />
          <LanguageContext.Provider value={{ language, setLanguage }}>
            <MDSThemeProvider theme={MDSThemeValue}>
              <MDSResetCSS />
              <Outlet />
            </MDSThemeProvider>
          </LanguageContext.Provider>
        </MuiThemeProvider>
        <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
        {/* ReactQueryDevtool is shown only when NODE_ENV === 'development' */}
      </Sentry.ErrorBoundary>
    </GrowthBookProvider>
  );
};

export default App;
