/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolFileResponse,
  MonitoringEnforcementReportProtocolsExcelExportByListingIdRequest,
  MonitoringEnforcementReportProtocolsExcelExportRequest,
  MonitoringEnforcementReportProtocolsExcelExportResponse,
  MonitoringEnforcementReportProtocolsExcelResultRequest,
  MonitoringEnforcementReportProtocolsExcelResultResponse,
  MonitoringEnforcementReportProtocolsGetAllListingIdRequest,
  MonitoringEnforcementReportProtocolsGetAllListingIdResponse,
  MonitoringEnforcementReportProtocolsGetAllSnapshotListRequest,
  MonitoringEnforcementReportProtocolsGetAllSnapshotListResponse,
  MonitoringEnforcementReportProtocolsGetEnforcementReportListingsRequest,
  MonitoringEnforcementReportProtocolsGetEnforcementReportListingsResponse,
  MonitoringEnforcementReportProtocolsGetExportListingsRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringEnforcementReport<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringEnforcementReport
   * @name EnforcementReportedGetReviewListCreate
   * @request POST:/monitoring/enforcement/reported/get_review_list
   */
  enforcementReportedGetReviewListCreate = (
    data: MonitoringEnforcementReportProtocolsGetEnforcementReportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementReportProtocolsGetEnforcementReportListingsResponse, any>({
      path: `/monitoring/enforcement/reported/get_review_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementReport
   * @name EnforcementReportedGetAllListingIdCreate
   * @request POST:/monitoring/enforcement/reported/get_all_listing_id
   */
  enforcementReportedGetAllListingIdCreate = (
    data: MonitoringEnforcementReportProtocolsGetAllListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementReportProtocolsGetAllListingIdResponse, any>({
      path: `/monitoring/enforcement/reported/get_all_listing_id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementReport
   * @name EnforcementReportedGetSnapshotsCreate
   * @request POST:/monitoring/enforcement/reported/get_snapshots
   */
  enforcementReportedGetSnapshotsCreate = (
    data: MonitoringEnforcementReportProtocolsGetAllSnapshotListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementReportProtocolsGetAllSnapshotListResponse, any>({
      path: `/monitoring/enforcement/reported/get_snapshots`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementReport
   * @name EnforcementReportedExcelExportCreate
   * @request POST:/monitoring/enforcement/reported/excel/export
   */
  enforcementReportedExcelExportCreate = (
    data: MonitoringEnforcementReportProtocolsExcelExportRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementReportProtocolsExcelExportResponse, any>({
      path: `/monitoring/enforcement/reported/excel/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementReport
   * @name EnforcementReportedExcelProgressCreate
   * @request POST:/monitoring/enforcement/reported/excel/progress
   */
  enforcementReportedExcelProgressCreate = (
    data: MonitoringEnforcementReportProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringEnforcementReportProtocolsExcelResultResponse, any>({
      path: `/monitoring/enforcement/reported/excel/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementReport
   * @name EnforcementReportedGetExportListCreate
   * @request POST:/monitoring/enforcement/reported/get_export_list
   */
  enforcementReportedGetExportListCreate = (
    data: MonitoringEnforcementReportProtocolsGetExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/reported/get_export_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringEnforcementReport
   * @name EnforcementReportedExcelExportListingIdCreate
   * @request POST:/monitoring/enforcement/reported/excel/export/listing-id
   */
  enforcementReportedExcelExportListingIdCreate = (
    data: MonitoringEnforcementReportProtocolsExcelExportByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/reported/excel/export/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
