/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PasswordProtocolsChangePasswordRequest,
  PasswordProtocolsFindPasswordRequest,
  PasswordProtocolsResetPasswordRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Password<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Password
   * @name FindCreate
   * @request POST:/password/find
   */
  findCreate = (data: PasswordProtocolsFindPasswordRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/password/find`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Password
   * @name FindResetCreate
   * @request POST:/password/find-reset
   */
  findResetCreate = (data: PasswordProtocolsResetPasswordRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/password/find-reset`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Password
   * @name ChangeCreate
   * @request POST:/password/change
   */
  changeCreate = (data: PasswordProtocolsChangePasswordRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/password/change`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
