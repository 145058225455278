import { useNavigate } from 'react-router-dom';

const isInternalLink = (url: string): boolean => {
  const currentHost = window.location.hostname;
  const urlHost = new URL(url, window.location.href).hostname;
  return !urlHost || currentHost === urlHost;
};

/**
 * brand-client에서 url을 열 때는 새 창으로 열지만, opscc-ui-web 의 client view에서는 내부 링크로 이동하게 한다.
 * - opscc에서 brand를 새창으로 열면 brand의 token 정보를 몰라서 제대로 작동하지 않는다.
 */
export const useConditionalNavigate = () => {
  const navigate = useNavigate();
  const isClientView: boolean = window.self !== window.top;

  return (url: string) => {
    if (isInternalLink(url) && isClientView) {
      navigate(url);
    } else {
      window.open(url, '_blank', 'noopener noreferrer');
    }
  };
};
