import {
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { authorizedSellerAPI } from '~/apis';
import {
  AuthorizedSellerProtocolsGetAuthorizedSellerCountRequest,
  AuthorizedSellerProtocolsGetAuthorizedSellerCountResponse,
  AuthorizedSellerProtocolsGetAuthorizedSellerDetailRequest,
  AuthorizedSellerProtocolsGetAuthorizedSellerDetailResponse,
  AuthorizedSellerProtocolsGetAuthorizedSellerListRequest,
  AuthorizedSellerProtocolsGetAuthorizedSellerListResponse,
  AuthorizedSellerProtocolsGetDomainFilterRequest,
  AuthorizedSellerProtocolsGetDomainFilterResponse,
} from '~/swagger/data-contracts';

const QueryKey = 'authorized-seller';
const QueryOptions = { refetchOnWindowFocus: false };

export const useGetAuthorizedSellerCountQuery = (
  request: AuthorizedSellerProtocolsGetAuthorizedSellerCountRequest,
  options?: UseQueryOptions<AuthorizedSellerProtocolsGetAuthorizedSellerCountResponse>
) => {
  return useQuery<AuthorizedSellerProtocolsGetAuthorizedSellerCountResponse>(
    [QueryKey, 'authorized-seller-count', request],
    async () => {
      const { data } = await authorizedSellerAPI.countCreate(request);

      return data;
    },
    { ...QueryOptions, ...options }
  );
};

export const useGetAuthorizedSellerDomainListQuery = (
  request: AuthorizedSellerProtocolsGetDomainFilterRequest
): UseQueryResult<AuthorizedSellerProtocolsGetDomainFilterResponse, unknown> => {
  return useQuery(
    [QueryKey, 'authorized-seller-domain-list', request],
    async () => {
      const { data } = await authorizedSellerAPI.filterDomainCreate(request);

      return data;
    },
    QueryOptions
  );
};

export const useGetAuthorizedSellerListQuery = (
  request: AuthorizedSellerProtocolsGetAuthorizedSellerListRequest,
  options?: UseInfiniteQueryOptions<AuthorizedSellerProtocolsGetAuthorizedSellerListResponse>
) => {
  return useInfiniteQuery<AuthorizedSellerProtocolsGetAuthorizedSellerListResponse>(
    [QueryKey, 'authorized-seller-list', request],
    async ({ pageParam }) => {
      const { data } = await authorizedSellerAPI.listCreate({
        ...request,
        offset: pageParam || request.offset,
      });

      return data;
    },
    {
      ...options,
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.seller_list.length < 50) {
          return;
        }

        return request.limit ? request.limit * allPage.length : 0;
      },
    }
  );
};

export const useGetAuthorizedSellerDetailQuery = (
  request: AuthorizedSellerProtocolsGetAuthorizedSellerDetailRequest
): UseQueryResult<AuthorizedSellerProtocolsGetAuthorizedSellerDetailResponse, unknown> => {
  return useQuery(
    [QueryKey, 'authorized-seller-detail', request],
    async () => {
      const { data } = await authorizedSellerAPI.detailsCreate(request);

      return data;
    },
    QueryOptions
  );
};

export const useAddAuthorizedSellerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(authorizedSellerAPI.postAuthorizedSeller, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey, 'authorized-seller-list']);
      queryClient.invalidateQueries([QueryKey, 'authorized-seller-count']);
    },
  });
};

export const useGetAllAuthorizedSellerIdMutation = () => {
  return useMutation(authorizedSellerAPI.getAllIdCreate);
};

export const useCreateSellerExcelMutation = () => {
  return useMutation(authorizedSellerAPI.exportCreate);
};
