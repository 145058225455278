import { makeStyles } from '@material-ui/core';
import { MDSIcon, MDSTypography } from '@marqvision/mds-v2';

const useStyles = makeStyles({
  guideText: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
});

export const LimitedAccessRoleDesc = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.guideText}>
        <MDSIcon.CloseDelete variant="outline" size={16} color="color/content/critical/default/normal" />
        <MDSTypography variant="T13" color="color/content/critical/default/normal">
          Cannot add, edit, or delete users.
        </MDSTypography>
      </div>
      <div className={classes.guideText}>
        <MDSIcon.CloseDelete variant="outline" size={16} color="color/content/critical/default/normal" />
        <MDSTypography variant="T13" color="color/content/critical/default/normal">
          Cannot access Home and Performance pages.
        </MDSTypography>
      </div>
      <div className={classes.guideText}>
        <MDSIcon.Check variant="outline" size={16} color="color/content/success/default/normal" />
        <MDSTypography variant="T13" color="color/content/success/default/normal">
          Access all other pages.
        </MDSTypography>
      </div>
    </div>
  );
};
