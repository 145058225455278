/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuthProtocolsCheckAccessTokenResponse,
  AuthProtocolsCheckPasswordRequest,
  AuthProtocolsCheckPasswordResponse,
  AuthProtocolsCheckRefreshTokenResponse,
  AuthProtocolsGetUserInfoResponse,
  AuthProtocolsLoginRequest,
  AuthProtocolsLoginResponse,
  AuthProtocolsLoginWithTokenRequest,
  AuthProtocolsLoginWithTokenResponse,
  AuthProtocolsLogoutRequest,
  AuthProtocolsLogoutResponse,
  AuthProtocolsOktaLoginInfoRequest,
  AuthProtocolsOktaLoginInfoResponse,
  AuthProtocolsOktaLoginRequest,
  AuthProtocolsOktaLoginResponse,
  AuthProtocolsOktaRiotLoginRequest,
  AuthProtocolsOktaRiotLoginResponse,
  AuthProtocolsRefreshTokenRequest,
  AuthProtocolsRefreshTokenResponse,
  MarqCommonProtocolEmptyResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Auth<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Auth
   * @name LoginCreate
   * @request POST:/login
   */
  loginCreate = (data: AuthProtocolsLoginRequest, params: RequestParams = {}) =>
    this.http.request<AuthProtocolsLoginResponse, any>({
      path: `/login`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name HealthCheckList
   * @request GET:/health_check
   */
  healthCheckList = (params: RequestParams = {}) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/health_check`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name LoginWithTokenCreate
   * @request POST:/login_with_token
   */
  loginWithTokenCreate = (data: AuthProtocolsLoginWithTokenRequest, params: RequestParams = {}) =>
    this.http.request<AuthProtocolsLoginWithTokenResponse, any>({
      path: `/login_with_token`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name CheckPasswordCreate
   * @request POST:/check_password
   */
  checkPasswordCreate = (data: AuthProtocolsCheckPasswordRequest, params: RequestParams = {}) =>
    this.http.request<AuthProtocolsCheckPasswordResponse, any>({
      path: `/check_password`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name LogoutCreate
   * @request POST:/logout
   */
  logoutCreate = (data: AuthProtocolsLogoutRequest, params: RequestParams = {}) =>
    this.http.request<AuthProtocolsLogoutResponse, any>({
      path: `/logout`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name CheckAccessTokenList
   * @request GET:/check_access_token
   */
  checkAccessTokenList = (params: RequestParams = {}) =>
    this.http.request<AuthProtocolsCheckAccessTokenResponse, any>({
      path: `/check_access_token`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name CheckRefreshTokenList
   * @request GET:/check_refresh_token
   */
  checkRefreshTokenList = (params: RequestParams = {}) =>
    this.http.request<AuthProtocolsCheckRefreshTokenResponse, any>({
      path: `/check_refresh_token`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name RefreshTokenCreate
   * @request POST:/refresh_token
   */
  refreshTokenCreate = (data: AuthProtocolsRefreshTokenRequest, params: RequestParams = {}) =>
    this.http.request<AuthProtocolsRefreshTokenResponse, any>({
      path: `/refresh_token`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name OktaCreate
   * @request POST:/login/okta
   */
  oktaCreate = (data: AuthProtocolsOktaRiotLoginRequest, params: RequestParams = {}) =>
    this.http.request<AuthProtocolsOktaRiotLoginResponse, any>({
      path: `/login/okta`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name InfoCreate
   * @request POST:/okta/info
   */
  infoCreate = (data: AuthProtocolsOktaLoginInfoRequest, params: RequestParams = {}) =>
    this.http.request<AuthProtocolsOktaLoginInfoResponse, any>({
      path: `/okta/info`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name LoginCreate2
   * @request POST:/okta/login
   * @originalName loginCreate
   * @duplicate
   */
  loginCreate2 = (data: AuthProtocolsOktaLoginRequest, params: RequestParams = {}) =>
    this.http.request<AuthProtocolsOktaLoginResponse, any>({
      path: `/okta/login`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name UserInfoList
   * @request GET:/user-info
   */
  userInfoList = (params: RequestParams = {}) =>
    this.http.request<AuthProtocolsGetUserInfoResponse, any>({
      path: `/user-info`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
