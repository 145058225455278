import { Features } from '../@types';

export const ArrowLeft = ({ variant }: Features<'ArrowLeft'>) =>
  variant === 'outline' ? (
    <path
      d="M14.71 6.71C14.32 6.32 13.69 6.32 13.3 6.71L8.71 11.3C8.32 11.69 8.32 12.32 8.71 12.71L13.3 17.3C13.69 17.69 14.32 17.69 14.71 17.3C15.1 16.91 15.1 16.28 14.71 15.89L10.83 12L14.71 8.12C15.1 7.73 15.09 7.09 14.71 6.71Z"
      fill="currentColor"
    />
  ) : variant === 'border' ? (
    <>
      <path
        d="M9.21 11.29L12.29 8.21C12.68 7.82 13.32 7.82 13.71 8.21C14.1 8.6 14.1 9.23 13.71 9.62L11.33 12L13.71 14.38C14.1 14.77 14.1 15.4 13.71 15.79C13.32 16.18 12.68 16.18 12.29 15.79L9.21 12.71C8.82 12.31 8.82 11.68 9.21 11.29Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12Z"
        fill="currentColor"
      />
    </>
  ) : (
    <path
      d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM12.29 8.21L9.21 11.29C8.82 11.68 8.82 12.31 9.21 12.71L12.29 15.79C12.68 16.18 13.32 16.18 13.71 15.79C14.1 15.4 14.1 14.77 13.71 14.38L11.33 12L13.71 9.62C14.1 9.23 14.1 8.6 13.71 8.21C13.32 7.82 12.68 7.82 12.29 8.21Z"
      fill="currentColor"
    />
  );

export const ArrowRight = ({ variant }: Features<'ArrowRight'>) =>
  variant === 'outline' ? (
    <path
      d="M9.29 6.71001C8.9 7.10001 8.9 7.73001 9.29 8.12001L13.17 12L9.29 15.88C8.9 16.27 8.9 16.9 9.29 17.29C9.68 17.68 10.31 17.68 10.7 17.29L15.29 12.7C15.68 12.31 15.68 11.68 15.29 11.29L10.7 6.70001C10.32 6.32001 9.68 6.32001 9.29 6.71001Z"
      fill="currentColor"
    />
  ) : variant === 'border' ? (
    <>
      <path
        d="M14.79 11.29L11.71 8.21C11.32 7.82 10.68 7.82 10.29 8.21C9.9 8.6 9.9 9.23 10.29 9.62L12.67 12L10.29 14.38C9.9 14.77 9.9 15.4 10.29 15.79C10.68 16.18 11.32 16.18 11.71 15.79L14.79 12.71C15.18 12.31 15.18 11.68 14.79 11.29Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12ZM4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12Z"
        fill="currentColor"
      />
    </>
  ) : (
    <path
      d="M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM11.71 8.21L14.79 11.29C15.18 11.68 15.18 12.31 14.79 12.71L11.71 15.79C11.32 16.18 10.68 16.18 10.29 15.79C9.9 15.4 9.9 14.77 10.29 14.38L12.67 12L10.29 9.62C9.9 9.23 9.9 8.6 10.29 8.21C10.68 7.82 11.32 7.82 11.71 8.21Z"
      fill="currentColor"
    />
  );

export const ArrowUp = ({ variant }: Features<'ArrowUp'>) =>
  variant === 'outline' ? (
    <path
      d="M11.29 8.71L6.7 13.3C6.31 13.69 6.31 14.32 6.7 14.71C7.09 15.1 7.72 15.1 8.11 14.71L12 10.83L15.88 14.71C16.27 15.1 16.9 15.1 17.29 14.71C17.68 14.32 17.68 13.69 17.29 13.3L12.7 8.71C12.32 8.32 11.68 8.32 11.29 8.71Z"
      fill="currentColor"
    />
  ) : variant === 'border' ? (
    <>
      <path
        d="M11.29 9.21L8.21 12.29C7.82 12.68 7.82 13.32 8.21 13.71C8.6 14.1 9.23 14.1 9.62 13.71L12 11.33L14.38 13.71C14.77 14.1 15.4 14.1 15.79 13.71C16.18 13.32 16.18 12.68 15.79 12.29L12.71 9.21C12.31 8.82 11.68 8.82 11.29 9.21Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="currentColor"
      />
    </>
  ) : (
    <path
      d="M12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22ZM15.79 12.29L12.71 9.21C12.32 8.82 11.69 8.82 11.29 9.21L8.21 12.29C7.82 12.68 7.82 13.32 8.21 13.71C8.6 14.1 9.23 14.1 9.62 13.71L12 11.33L14.38 13.71C14.77 14.1 15.4 14.1 15.79 13.71C16.18 13.32 16.18 12.68 15.79 12.29Z"
      fill="currentColor"
    />
  );

export const ArrowDown = ({ variant }: Features<'ArrowDown'>) =>
  variant === 'outline' ? (
    <path
      d="M15.88 9.29L12 13.17L8.12001 9.29C7.73001 8.9 7.10001 8.9 6.71001 9.29C6.32001 9.68 6.32001 10.31 6.71001 10.7L11.3 15.29C11.69 15.68 12.32 15.68 12.71 15.29L17.3 10.7C17.69 10.31 17.69 9.68 17.3 9.29C16.91 8.91 16.27 8.9 15.88 9.29Z"
      fill="currentColor"
    />
  ) : variant === 'border' ? (
    <>
      <path
        d="M11.29 14.79L8.21 11.71C7.82 11.32 7.82 10.68 8.21 10.29C8.6 9.9 9.23 9.9 9.62 10.29L12 12.67L14.38 10.29C14.77 9.9 15.4 9.9 15.79 10.29C16.18 10.68 16.18 11.32 15.79 11.71L12.71 14.79C12.31 15.18 11.68 15.18 11.29 14.79Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22ZM12 4C7.59 4 4 7.59 4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4Z"
        fill="currentColor"
      />
    </>
  ) : (
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM15.79 11.71L12.71 14.79C12.32 15.18 11.69 15.18 11.29 14.79L8.21 11.71C7.82 11.32 7.82 10.68 8.21 10.29C8.6 9.9 9.23 9.9 9.62 10.29L12 12.67L14.38 10.29C14.77 9.9 15.4 9.9 15.79 10.29C16.18 10.68 16.18 11.32 15.79 11.71Z"
      fill="currentColor"
    />
  );
