import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

const StringifyOptions = {
  arrayFormat: 'comma' as const,
  skipNull: true,
  skipEmptyString: true,
};

const ParseOptions = {
  parseNumbers: true,
  parseBooleans: true,
  ...StringifyOptions,
};

export type QueryParamStateValue = string | number | boolean | null | (string | number | boolean | null)[];

function useQueryParamState<S extends QueryParamStateValue>(
  name: string,
  initialState: S,
  validValues: S[]
): [S, Dispatch<SetStateAction<S>>];

function useQueryParamState<S extends QueryParamStateValue>(
  name: string,
  initialState?: S
): [S, Dispatch<SetStateAction<S>>];

function useQueryParamState<S extends QueryParamStateValue>(
  name: string
): [S | undefined, Dispatch<SetStateAction<S | undefined>>];

function useQueryParamState<S extends QueryParamStateValue>(
  name: string,
  initialState?: S,
  validValues?: S[]
): [S | undefined, React.Dispatch<React.SetStateAction<S | undefined>>] {
  const navigate = useNavigate();
  const { search } = useLocation();

  const queryParamsMap = qs.parse(window.location.search, ParseOptions);

  const getValue = (value: S): S | undefined => {
    if (validValues && !validValues.includes(value)) {
      return validValues[0];
    }

    return value || initialState;
  };

  const [state, setState] = useState<S | undefined>(getValue(queryParamsMap[name] as S));

  useEffect(() => {
    // state 변경 시 navigate 실행
    const currentQueryParamsMap = qs.parse(window.location.search, ParseOptions) as Record<
      string | number | symbol,
      S | undefined
    >;

    if (currentQueryParamsMap[name] !== state) {
      currentQueryParamsMap[name] = state;

      navigate(`?${qs.stringify(currentQueryParamsMap, StringifyOptions)}`, { replace: true });
    }
  }, [name, state, navigate, search]);

  return [state, setState];
}

/**
 * @deprecated
 */
export default useQueryParamState;
