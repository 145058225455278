import React, { HTMLAttributes, useRef, useState } from 'react';
import axios, { AxiosProgressEvent } from 'axios';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/core/styles';
import { MDSToastSnackbar } from '../index';
import { acceptedFilesByType, DefaultMaxFileSize, LocalizedLabel } from './@constants';
import {
  AddEvent,
  ErrorResp,
  FileUploaderTypeEnum,
  IFile,
  LanguageEnum,
  PreviewEvent,
  Progress,
  ProgressSizes,
} from './@types';
import { isAcceptedDropData, isAcceptedFileFormat } from './@util';
import { FileUploader } from './FileUploader';

export { FileUploaderTypeEnum } from './@types';
export type { IFile, ErrorResp } from './@types';

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
}));

export interface IMDSFileUploaderProps<T> extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'onError'> {
  files: T[];
  variant?: 'chipList' | 'preview' | 'normal'; // for folio case | custom chip list case
  type?: FileUploaderTypeEnum;
  limit?: number;
  maxFileSize?: number;
  customAccepts?: {
    accepts: string[];
    placeholder: string;
  };
  acceptDropData?: string; // drop data types 확인용. undefined: 모든 dropData 허용
  previewColumns?: number;
  disabled?: boolean;
  disabledComponent?: React.ReactNode;
  readOnly?: boolean;
  progressSizes?: ProgressSizes;
  renderTitleComponent?: (event: AddEvent) => React.ReactNode;
  renderDropzoneText?: (onAdd: () => void) => React.ReactNode;
  renderActionButtons?: (index: number) => React.ReactNode;
  renderPreviewComponent?: (event: PreviewEvent) => React.ReactNode;
  onChange?: (files: T[]) => void;
  onAdd?: (file: T) => void;
  onDelete?: (index: number) => void;
  onUpload: (fileName: string) => Promise<string>;
  isError?: boolean;
  onError?: (resp: ErrorResp) => void;
  locale?: LanguageEnum;
  onClickItem?: (file: T, index: number) => void;
  onLoadedAll?: () => void;
  isOverlayDropzoneShow?: boolean; // true: preview box 최하단에 dropzone 출력
  isPreviewScrollable?: boolean; // true: image preview box 내부에 스크롤바 추가, else: height 꽉 맞게 grid 배치
}

export const MDSFileUploader = <T extends IFile>(props: IMDSFileUploaderProps<T>): React.ReactNode => {
  const {
    type = FileUploaderTypeEnum.All,
    onChange,
    onAdd,
    onDelete,
    onUpload,
    files,
    customAccepts,
    acceptDropData,
    onError,
    locale = LanguageEnum.English,
    disabled,
    readOnly,
    onLoadedAll,
  } = props;
  const classes = useStyles();

  const limit = Math.min(props.limit || 3, Number.MAX_SAFE_INTEGER);
  const maxFileSize = Math.min(props.maxFileSize || DefaultMaxFileSize, Number.MAX_SAFE_INTEGER);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [highlight, setHighlight] = useState(false);
  const [progress, setProgress] = useState<Progress>({});

  const loadingFilesLength = Object.values(progress).filter(({ isLoading }) => isLoading).length;

  const handleHighlightOn = () => {
    setHighlight(true);
  };

  const handleHighlightOff = () => {
    setHighlight(false);
  };

  const handleToastErrorMessage = (message: string, fileName?: string) => {
    if (onError) return onError({ message, fileName });
    MDSToastSnackbar({ type: 'error', title: message });
  };

  const isAcceptedFile = async (file: File) => {
    if (file.size > maxFileSize) {
      handleToastErrorMessage(
        LocalizedLabel[locale].FileSize(numeral(maxFileSize).format('0b', Math.floor)),
        file.name
      );
      return false;
    }

    if (!(await isAcceptedFileFormat(file, customAccepts?.accepts || acceptedFilesByType[type]))) {
      handleToastErrorMessage(LocalizedLabel[locale].FileFormat, file.name);
      return false;
    }
    return file;
  };

  const handleDragOn = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (isAcceptedDropData(e, acceptDropData)) {
      handleHighlightOn();
    } else {
      handleHighlightOff();
    }
  };

  const dropData = (e: React.DragEvent<HTMLDivElement>) => {
    const text = e.dataTransfer.getData('text/plain');
    const droppedElement: T = text && JSON.parse(text);

    if (droppedElement) {
      // Drop Element
      handleAddUrl(droppedElement);
    } else {
      // Drop files
      const files = e.dataTransfer.files;
      handleFiles(files);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!highlight) return handleToastErrorMessage(LocalizedLabel[locale].DataType);
    if (files.length >= limit) return handleToastErrorMessage(LocalizedLabel[locale].Limit(limit));

    handleHighlightOff();
    dropData(e);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!highlight) return;

    const files = e.clipboardData.files;
    handleFiles(files);
  };

  const handleFiles = async (fileList: FileList | null) => {
    clearProgress();

    if (!fileList) return;
    if (fileList.length < 1 || fileList.length > limit - files.length) {
      handleToastErrorMessage(LocalizedLabel[locale].Limit(limit));
    } else {
      const newFiles = [...files];
      const typeCheckedList = await Promise.all([...fileList].map(isAcceptedFile));
      const acceptedList = typeCheckedList.filter((file) => file) as File[];
      await Promise.all(acceptedList.map(uploadFile(newFiles)));
      clearProgress();
    }

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const uploadFile = (newFiles: T[]) => async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const url = await onUpload(file.name);
      const fileUrl = url.split('?')[0];
      handleAddFile(newFiles, fileUrl, file.name);
      setProgress((prev) => ({
        ...prev,
        [fileUrl]: { isLoading: true, loaded: 0, total: 0 },
      }));

      await axios.put(url, file, {
        onUploadProgress: handleUploadProgress(fileUrl),
      });
      onLoadedAll?.();
      return { fileUrl, fileName: file.name };
    } catch (error) {
      handleToastErrorMessage(LocalizedLabel[locale].AnErrorOccurred);
      clearProgress();
    }
  };

  const handleAddFile = (files: T[], url: string, name: string) => {
    const newFile = { id: null, url, name } as T;
    files.push(newFile);
    onChange?.(files);
    onAdd?.(newFile);

    return { files };
  };

  const handleAddUrl = (data: T) => {
    const newFile = { ...data, base_image_id: data.id, id: null };
    onChange?.([...files, newFile]);
    onAdd?.(newFile);
  };

  const handleDelete = (index: number) => () => {
    const newFiles = files.filter((_, i) => i !== index);
    onChange?.(newFiles);
    onDelete?.(index);
  };

  const handleAddButtonClick = () => {
    inputRef.current?.click();
  };

  const handleUploadProgress = (targetUrl: string) => (progressEvent: AxiosProgressEvent) => {
    setProgress((prev) => {
      const newProgress = { ...prev };
      newProgress[targetUrl] = {
        isLoading: (progressEvent.total || 0) >= progressEvent.loaded,
        totalSize: progressEvent.total,
        uploadedSize: progressEvent.loaded,
      };
      return newProgress;
    });
  };

  const clearProgress = () => {
    setProgress({});
  };

  return (
    <>
      <FileUploader
        {...props}
        highlight={highlight}
        uploadProgress={progress}
        onHighlightOn={handleHighlightOn}
        onHighlightOff={handleHighlightOff}
        onDragOn={handleDragOn}
        onDrop={handleDrop}
        onPaste={handlePaste}
        onDelete={handleDelete}
        onAddButtonClick={handleAddButtonClick}
      />
      {(!disabled || !readOnly) && (
        <input
          multiple
          type="file"
          ref={inputRef}
          accept={(customAccepts?.accepts || acceptedFilesByType[type]).join(', ')}
          onChange={(e) => handleFiles(e.target.files)}
          className={classes.hidden}
          disabled={!!loadingFilesLength}
        />
      )}
    </>
  );
};
