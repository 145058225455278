import {
  MarqBrandEnumsListingMonitoringStatusEnum,
  MarqCommonEnumsReportableReasonEnum,
  MarqCommonEnumsSortedOrderEnum,
  MonitoringFilterProtocolResponse,
  MonitoringFilterProtocolResponseFilterItem,
} from '~/swagger/data-contracts';
import { FilterDropDown, IDropDownItem, SortValueEnum } from '~/components/Filters/@types';

export const parseMarqCommonEnumsSortedOrderEnum = (order?: SortValueEnum): MarqCommonEnumsSortedOrderEnum => {
  switch (order) {
    case SortValueEnum.Asc:
      return MarqCommonEnumsSortedOrderEnum.Asc;
    case SortValueEnum.Desc:
      return MarqCommonEnumsSortedOrderEnum.Desc;
    default:
      return MarqCommonEnumsSortedOrderEnum.Asc;
  }
};

// todo-@elie: useInfiniteQuery 내에서 처리하지 못해 mergePages 를 뷰에서 사용하고 있음, 방법을 찾으면 제거필요.
export const mergePages = (filterList?: FilterDropDown[]): FilterDropDown => {
  if (!filterList) return { count: 0, list: [] };

  return filterList.reduce(
    (acc, page, currentIndex) => {
      return {
        count: currentIndex === 0 ? page.count : acc.count,
        list: [...acc.list, ...page.list],
      };
    },
    { count: 0, list: [] }
  );
};
const parseFilterDropDownItem = (data?: MonitoringFilterProtocolResponseFilterItem): IDropDownItem => {
  if (!data) return { value: '', label: '' };

  const { id, text, count, list, ...rest } = data;
  return {
    ...rest,
    imageUrl: rest.image_url,
    value: id,
    label: text,
    count,
    children: list
      ? list.map((item) => {
          return parseFilterDropDownItem(item);
        })
      : undefined,
  };
};
export const parseFilterDropDown = (data?: MonitoringFilterProtocolResponse): FilterDropDown => {
  if (!data) return { count: 0, list: [] };

  const { count, search_after, list, filter_display } = data;
  return {
    count: count || 0,
    search_after,
    filter_display,
    list: list.map((item) => parseFilterDropDownItem(item)),
  };
};
export const parseMarqBrandEnumsListingMonitoringStatusEnum = (
  status: string
): MarqBrandEnumsListingMonitoringStatusEnum => {
  switch (status) {
    case 'review_later':
      return MarqBrandEnumsListingMonitoringStatusEnum.Later;
    case 'gray_market_listing':
      return MarqBrandEnumsListingMonitoringStatusEnum.Gray;
    case 'dismissed':
      return MarqBrandEnumsListingMonitoringStatusEnum.Dismissed;
    default:
      return status as MarqBrandEnumsListingMonitoringStatusEnum;
  }
};
export const parseMarqCommonEnumsReportableReasonEnumList = (
  reasons?: string[]
): MarqCommonEnumsReportableReasonEnum[] => {
  if (!reasons) return [];

  return reasons.reduce<MarqCommonEnumsReportableReasonEnum[]>((acc, cur) => {
    if (Object.values(MarqCommonEnumsReportableReasonEnum).includes(cur as MarqCommonEnumsReportableReasonEnum)) {
      return acc.concat([cur as MarqCommonEnumsReportableReasonEnum]);
    } else {
      return acc;
    }
  }, []);
};
