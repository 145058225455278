export type BoundingBox = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

export type IFile = {
  id?: number | null;
  url: string;
  name?: string;
  boundingBox?: BoundingBox[];
};

export enum FileUploaderTypeEnum {
  Image = 'image',
  Document = 'document',
  Excel = 'excel',
  Pdf = 'pdf',
  Trademark = 'trademark',
  All = 'all',
  Report = 'report',
}

export type ErrorResp = {
  message: string;
  fileName?: string;
};

export enum LanguageEnum {
  English = 'en',
  Korean = 'ko',
}

export type AddEvent = {
  onAdd: () => void;
  disabled: boolean;
  isLoading: boolean;
  type: FileUploaderTypeEnum;
};

export type PreviewEvent = {
  onAdd: () => void;
  disabled: boolean;
  isLoading: boolean;
  type: FileUploaderTypeEnum;
  files: IFile[];
  onDelete: (index: number) => () => void;
  progress: Progress;
};

export type Progress = Record<string, { totalSize?: number; uploadedSize?: number; isLoading?: boolean }>;

export type ProgressSizes = {
  loadedCurrentSize: number;
  loadedTotalSize: number;
  totalSize: number;
};
