import { QueryClient } from '@tanstack/react-query';
import { queryClientConfig } from '@marqvision/shared/config/react-query';
import { MonitoringEnforcementReport } from '~/swagger/MonitoringEnforcementReport';
import { http } from '~/apis/base';
import { Credits } from '~/swagger/Credits';
import { MonitoringEnforcementTp } from '~/swagger/MonitoringEnforcementTp';
import { MonitoringDetected } from '~/swagger/MonitoringDetected';
import { Home } from '~/swagger/Home';
import { Performance } from '~/swagger/Performance';
import { IpPortfolio } from '~/swagger/IpPortfolio';
import { SellerAnalyticsDetail } from '~/swagger/SellerAnalyticsDetail';
import { SellerAnalyticsDetailDetected } from '~/swagger/SellerAnalyticsDetailDetected';
import { SellerAnalyticsDetailEnforced } from '~/swagger/SellerAnalyticsDetailEnforced';
import { SellerAnalyticsDetailArchived } from '~/swagger/SellerAnalyticsDetailArchived';
import { SellerAnalyticsSellerInCluster } from '~/swagger/SellerAnalyticsSellerInCluster';
import { LSettings } from '~/swagger/LSettings';
import { SellerAnalyticsDataDriven } from '~/swagger/SellerAnalyticsDataDriven';
import { Password } from '~/swagger/Password';
import { Email } from '~/swagger/Email';
import { Auth } from '~/swagger/Auth';
import { Archived } from '~/swagger/Archived';
import { SellerAnalyticsCluster } from '~/swagger/SellerAnalyticsCluster';
import { SellerAnalyticsSeller } from '~/swagger/SellerAnalyticsSeller';
import { EmployeeSignup } from '~/swagger/EmployeeSignup';
import { Menu } from '~/swagger/Menu';
import { MonitoringEnforcement } from '~/swagger/MonitoringEnforcement';
import { MonitoringWebsiteDetected } from '~/swagger/MonitoringWebsiteDetected';
import { MonitoringWebsiteEnforced } from '~/swagger/MonitoringWebsiteEnforced';
import { MonitoringWebsiteEnforcedSoftNotice } from '~/swagger/MonitoringWebsiteEnforcedSoftNotice';
import { MonitoringWebsiteEnforcedGoogleDelisting } from '~/swagger/MonitoringWebsiteEnforcedGoogleDelisting';
import { MonitoringWebsiteEnforcedUdrp } from '~/swagger/MonitoringWebsiteEnforcedUdrp';
import { MonitoringWebsiteEnforcedReportAbuse } from '~/swagger/MonitoringWebsiteEnforcedReportAbuse';
import { MonitoringWebsiteEnforcedNonEnforceable } from '~/swagger/MonitoringWebsiteEnforcedNonEnforceable';
import { MonitoringWebsiteEnforcedUnderReview } from '~/swagger/MonitoringWebsiteEnforcedUnderReview';
import { InfringingImage } from '~/swagger/InfringingImage';
import { AuthorizedSeller } from '~/swagger/AuthorizedSeller';
import { SellerIntelligence } from '~/swagger/SellerIntelligence';
import { Common } from '~/swagger/Common';
import { PerformanceDetailsEnfAnalysis } from '~/swagger/PerformanceDetailsEnfAnalysis';
import { PerformanceDetailsImpactedSellers } from '~/swagger/PerformanceDetailsImpactedSellers';
import { PerformanceDetailsCompRate } from '~/swagger/PerformanceDetailsCompRate';
import { Billing } from '~/swagger/Billing';
import { Map } from '~/swagger/Map';
import { MonitoringFilter } from '~/swagger/MonitoringFilter';
import { Settings } from '~/swagger/Settings';
import { AuthorizedWebsite } from '~/swagger/AuthorizedWebsite';

// Common
export const commonApi = new Common(http);

// Home
export const homeApi = new Home(http);

// Performance
export const performanceApi = new Performance(http);
export const performanceEnforcementApi = new PerformanceDetailsEnfAnalysis(http);
export const performanceSellerApi = new PerformanceDetailsImpactedSellers(http);
export const performanceComplianceRateApi = new PerformanceDetailsCompRate(http);

// Portfolio
export const portfolioApi = new IpPortfolio(http);

export const authorizedSellerAPI = new AuthorizedSeller(http);
export const authorizedWebsiteAPI = new AuthorizedWebsite(http);

// Detection Standalone Websites
export const detectionWebsiteDetectedApi = new MonitoringWebsiteDetected(http);

// Archive Page
export const archiveApi = new Archived(http);

// Enforcements
export const monitoringEnforcementApi = new MonitoringEnforcement(http);

export const enforcementWIMonitoringApi = new MonitoringWebsiteEnforced(http);

export const enforcementUnderReviewApi = new MonitoringWebsiteEnforcedUnderReview(http);
export const enforcementSoftNoticeApi = new MonitoringWebsiteEnforcedSoftNotice(http);
export const enforcementGoogleDelistingApi = new MonitoringWebsiteEnforcedGoogleDelisting(http);
export const enforcementReportAbuseApi = new MonitoringWebsiteEnforcedReportAbuse(http);
export const enforcementUdrpApi = new MonitoringWebsiteEnforcedUdrp(http);

export const enforcementNonEnforceable = new MonitoringWebsiteEnforcedNonEnforceable(http);

export const monitoringEnforcementReportApi = new MonitoringEnforcementReport(http);
export const monitoringEnforcementTpApi = new MonitoringEnforcementTp(http);
export const monitoringFilterApi = new MonitoringFilter(http);

// Monitoring Details

export const monitoringDetectedApi = new MonitoringDetected(http);

// Seller
export const sellerAnalyticsClusterApi = new SellerAnalyticsCluster(http);
export const sellerAnalyticsDetailApi = new SellerAnalyticsDetail(http);
export const sellerAnalyticsSellerApi = new SellerAnalyticsSeller(http);
export const sellerIntelligenceApi = new SellerIntelligence(http);

export const sellerAnalyticsDetailDetectedApi = new SellerAnalyticsDetailDetected(http);
export const sellerAnalyticsDetailEnforcedApi = new SellerAnalyticsDetailEnforced(http);
export const sellerAnalyticsDetailArchivedApi = new SellerAnalyticsDetailArchived(http);
export const sellerAnalyticsDetailDataDriven = new SellerAnalyticsDataDriven(http);
export const sellerAnalyticsSellerInClusterApi = new SellerAnalyticsSellerInCluster(http);

// Map Monitoring

export const mapMonitoringApi = new Map(http);

// Settings
export const legacySettingsApi = new LSettings(http);
export const settingsApi = new Settings(http);
export const creditsApi = new Credits(http);
export const billingApi = new Billing(http);
export const passwordApi = new Password(http);
export const emailApi = new Email(http);
export const authApi = new Auth(http);
export const employeeSignupApi = new EmployeeSignup(http);
export const menuApi = new Menu(http);
export const infringingImageApi = new InfringingImage(http);

export const queryClient = new QueryClient(queryClientConfig);
