import { useEffect, useRef, useState } from 'react';

export const useWrapperSize = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries && entries[0]) {
        const el = entries[0].target as HTMLElement;
        setWidth(el.clientWidth || 0);
        setHeight(el.clientHeight || 0);
      }
    });

    const wrapperEl = wrapperRef.current;
    if (wrapperEl) {
      observer.observe(wrapperEl);
    }
    return () => {
      if (wrapperEl) {
        observer.unobserve(wrapperEl);
      }
    };
  }, []);

  return { wrapperRef, width, height };
};
