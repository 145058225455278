import { atom } from 'jotai/index';
import { WebsiteFilter, WebsitePathParams } from '~/types/websiteImpersonation';

type WebsiteFilterAtom = WebsiteFilter;
export const websiteFilterAtom = atom<WebsiteFilterAtom>({
  rootDomainIdList: [],
  rootDomainSearchKeyword: '',
});
export const resetWebsiteFilterAtom = atom(null, (_get, set) => {
  set(websiteFilterAtom, { rootDomainIdList: [], rootDomainSearchKeyword: '' });
});

type WebsitePathParamsAtom = WebsitePathParams;
const defaultWebsitePathParams: WebsitePathParamsAtom = {
  page: 'detection',
  tab: 'website',
  stage: '',
  status: '',
};
export const websitePathParamsAtom = atom<WebsitePathParamsAtom>(defaultWebsitePathParams);

export const resetWebsitePathParamsAtom = atom(null, (_get, set) => {
  set(websitePathParamsAtom, defaultWebsitePathParams);
});
