import React, { JSX, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { atom, useAtom, Provider, createStore } from 'jotai';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';
import MDSTheme from '../Theme';
import { IcoMenu, IcoMenuClose } from '../../assets';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  root: {
    transition: theme.transitions.create('flex', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fixed: {
    overflow: 'hidden',
    backgroundColor: MDSTheme.palette.bluegray[1000],
    borderRadius: '0px 16px 0px 0px',
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&.shadow': {
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
  },
  logoWrap: {
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '56px',
  },
  logo: {
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: 0,
    '& > *': {
      marginLeft: '12px',
      height: '12px',
      width: 'auto',
      display: 'block',
    },
    '& svg': {},
  },
  closeButton: {
    border: '1px solid',
    borderColor: MDSTheme.palette.bluegray[800],
    borderRadius: '16px',
    padding: 0,
    width: '32px',
    height: '32px',
    '& svg': {
      width: '18px',
      height: '18px',
    },
    '& path': {
      fill: 'white',
    },
  },
  menuButton: {
    border: 0,
    borderRadius: 0,
    padding: 0,
    width: '30px',
    height: '32px',
    '& svg': {
      width: '24px',
      height: '24px',
    },
    '& path': {
      fill: 'white',
    },
  },
}));

export const leftNavWidth = 240;
export const foldedLeftNavWidth = 54;
export const expandNavWindowWidth = 1440;

interface IProps {
  logo: React.ReactElement;
  HomeLink?: string;
  onClickHome?: () => void; // todo: need to move to sub-layer of logo?
  style?: React.CSSProperties;
  onOpenNav?: (value: boolean) => void;
}

interface ILeftNavBarAtom {
  isLeftNavBarOpen: boolean;
  isFolded: boolean;
}

const myStore = createStore();
export const leftNavBarStateAtom = atom<ILeftNavBarAtom>({ isLeftNavBarOpen: true, isFolded: false });

const LeftNavBar = (props: PropsWithChildren<IProps>): JSX.Element => {
  const classes = useStyles();
  const [{ isLeftNavBarOpen }, setIsLeftNavBarOpen] = useAtom(leftNavBarStateAtom);
  const { logo, children, HomeLink = '/', onOpenNav, onClickHome } = props;

  const resizeObserver = useRef<ResizeObserver>();
  const delayAction = useRef(false);
  const [isHover, setIsHover] = useState(false);
  const hoverRef = useRef(false);
  const lastWidth = useRef(0);
  const isExpanded = isLeftNavBarOpen || isHover;

  const handleFold = () => {
    setIsLeftNavBarOpen((prevState) => ({ ...prevState, isLeftNavBarOpen: false }));
    props.onOpenNav?.(false);
    setIsHover(false);
    hoverRef.current = false;
    delayAction.current = true;

    setTimeout(() => {
      delayAction.current = false;
    }, 225);
  };

  const handleHover = (value: boolean) => {
    if (delayAction.current) {
      return;
    }
    setIsHover(value);
    hoverRef.current = value;
  };

  useEffect(() => {
    if (!resizeObserver.current) {
      resizeObserver.current = new ResizeObserver((entries) => {
        const { width } = entries[0].contentRect;

        if (!hoverRef.current && lastWidth.current !== width) {
          const value = width > expandNavWindowWidth;
          setIsLeftNavBarOpen((prevState) => ({ ...prevState, isLeftNavBarOpen: value }));

          onOpenNav?.(value);
          lastWidth.current = width;
        }
      });
      resizeObserver.current.observe(document.body);
    }
    return () => {
      resizeObserver.current?.unobserve(document.body);
    };
  }, [setIsLeftNavBarOpen, onOpenNav]);

  useEffect(() => {
    myStore.set(leftNavBarStateAtom, {
      isLeftNavBarOpen,
      isFolded: !isExpanded,
    });
    onOpenNav?.(isLeftNavBarOpen);
  }, [isExpanded]);

  useEffect(() => {
    onOpenNav?.(window.innerWidth > expandNavWindowWidth);
  }, [onOpenNav]);

  return (
    <Provider store={myStore}>
      <div
        className={classes.root}
        onMouseLeave={() => setIsHover(false)}
        style={{
          width: isLeftNavBarOpen ? leftNavWidth : foldedLeftNavWidth,
          flex: `0 0 ${isLeftNavBarOpen ? leftNavWidth : foldedLeftNavWidth}px`,
        }}
      >
        <div
          className={clsx(classes.fixed, { shadow: !isLeftNavBarOpen && isExpanded })}
          style={{ width: isExpanded ? leftNavWidth : foldedLeftNavWidth, ...props.style }}
        >
          <div className={classes.logoWrap}>
            <Link
              to={HomeLink}
              className={classes.logo}
              onClick={() => {
                onClickHome?.();
              }}
            >
              {logo}
            </Link>
            {(isLeftNavBarOpen || !isExpanded) && (
              <>
                {isLeftNavBarOpen && (
                  <IconButton className={classes.closeButton} onClick={handleFold}>
                    <IcoMenuClose />
                  </IconButton>
                )}
                {!isLeftNavBarOpen && (
                  <IconButton
                    className={classes.menuButton}
                    onClick={() => {
                      setIsLeftNavBarOpen((prevState) => ({ ...prevState, isLeftNavBarOpen: true }));
                      props.onOpenNav?.(true);
                    }}
                  >
                    <IcoMenu />
                  </IconButton>
                )}
              </>
            )}
          </div>
          <div onMouseEnter={() => handleHover(true)}>{children}</div>
        </div>
      </div>
    </Provider>
  );
};

export const MDSNavItem = NavItem;

export default LeftNavBar;
