import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: ({ gap }: { gap: string }) => gap,
  },
  point: {
    flexShrink: 0,
    borderRadius: '50%',
  },
});

type CustomLegendIcon = {
  customLegendIcon: React.ReactElement;
  pointColor?: never;
  pointSize?: never;
};

type CircleIcon = {
  customLegendIcon?: never;
  pointColor: string;
  // bullet point diameter - default 10px
  pointSize?: string;
};

type Props = {
  // space between bullets and labels - default 8px
  gap?: string;
} & (CustomLegendIcon | CircleIcon);

export const LegendItem = (props: React.PropsWithChildren<Props>) => {
  const { pointSize = '10px', pointColor, gap = '8px', children, customLegendIcon } = props;
  const classes = useStyles({ gap });

  return (
    <div className={classes.wrapper}>
      {customLegendIcon || (
        <div className={classes.point} style={{ width: pointSize, height: pointSize, backgroundColor: pointColor }} />
      )}
      {children}
    </div>
  );
};
