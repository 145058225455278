import React from 'react';
import clsx from 'clsx';
import { IconButton, makeStyles } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import {
  IcoArrowRightOutline,
  IcoCheckOutline,
  IcoCloseDeleteRemoveBorder,
  IcoErrorWarningBorder,
  IcoInfoBorder,
  IcoPriorityBorder,
} from '../assets';
import theme from './Theme';
import Typography from './Typography';
import Button from './Button';

const useStyles = makeStyles(() => ({
  root: {
    padding: '12px 16px',
    borderRadius: '8px',
    backgroundColor: (props: IProps) => getBackgroundColor(props.type, props.color),
    border: (props: IProps) => getBorder(props.color),
  },
  leftIcon: {
    '&.medium': {
      alignSelf: 'center',
      '& > svg': {
        width: '20px',
        height: '20px',
        marginRight: '8px',
      },
    },
    '&.small': {
      alignSelf: 'center',
      '& > svg': {
        width: '18px',
        height: '18px',
        marginRight: '8px',
      },
    },
    '&.large > svg': {
      width: '32px',
      height: '32px',
      marginTop: '6px',
      marginRight: '16px',
    },
    '&.images > svg': {
      width: '20px',
      height: '20px',
      marginRight: '4px',
    },
    '& > svg > path': {
      fill: (props: IProps) => getContentColor(props.type, props.color),
    },
  },
  image: {
    objectFit: 'cover',
    width: '66px',
    height: '66px',
    borderRadius: '4px',
    '&.small': {
      width: '32px',
      height: '32px',
    },
  },
  overlay: {
    position: 'absolute',
    bottom: '12px',
    borderRadius: '4px',
    width: '32px',
    height: '32px',
    paddingTop: '7px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(Aux-Oiseaux_MOOD_Ay)',
  },
  closeButton: {
    width: '20px',
    height: '20px',
    '& > path': {
      fill: theme.palette.bluegray[800],
    },
  },
  messageBox: {
    display: 'flex',
    '& button': {
      marginLeft: '8px',
      verticalAlign: 'baseline',
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export enum MessageTypeEnum {
  Normal = 'normal',
  Caution = 'caution',
  Succeed = 'succeed',
  Error = 'error',
  Promotion = 'promotion',
  Info = 'info',
}

interface IOwnProps {
  type: MessageTypeEnum;
  color?: keyof typeof theme.palette;
  title: React.ReactNode;
  titleSize?: 'medium' | 'large';
  images?: string[];
  message?: React.ReactNode;
  iconSize?: 'small' | 'medium' | 'large';
  customLeftIcon?: React.ReactNode;
  customRightButton?: React.ReactNode;
  hideRightButton?: boolean;
  messageOnClick?: () => void;
  messageCTAButton?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const TypeColor = {
  [MessageTypeEnum.Normal]: 'bluegray',
  [MessageTypeEnum.Caution]: 'yellow',
  [MessageTypeEnum.Succeed]: 'green',
  [MessageTypeEnum.Error]: 'red',
  [MessageTypeEnum.Promotion]: 'blue',
  [MessageTypeEnum.Info]: 'bluegray',
} as const;

const getContentColor = (type: MessageTypeEnum, color?: IOwnProps['color']) => {
  if (color) {
    return theme.palette[color][['bluegray', 'white'].includes(color) ? '900' : '700'];
  }
  if (type === MessageTypeEnum.Normal) {
    return theme.palette.bluegray['900']; // update to MDS spec
  }

  return theme.palette[TypeColor[type]]['700'];
};

const getBackgroundColor = (type: MessageTypeEnum, color?: IOwnProps['color']) => {
  if (color) {
    return theme.palette[color][['bluegray', 'white'].includes(color) ? '100' : '50'];
  }

  return theme.palette[TypeColor[type]][50];
};

const getBorder = (color?: IOwnProps['color']) => {
  switch (color) {
    case 'white':
      return `1px solid ${theme.palette.bluegray[200]}`;
    default:
      return 'none';
  }
};

type IProps = IOwnProps & Omit<BoxProps, 'color' | 'title'>;

const MessageBox = (props: IProps): React.ReactNode => {
  const classes = useStyles(props);

  const {
    type,
    color,
    images,
    title,
    titleSize,
    message,
    className,
    iconSize = 'medium',
    customLeftIcon,
    customRightButton,
    hideRightButton,
    messageOnClick,
    messageCTAButton,
    onClose,
    ...restProps
  } = props;

  const renderImages = (): React.ReactNode => {
    if (images) {
      const imageCount = images.length || 0;
      return (
        <Box marginRight="16px" display="flex">
          <img alt="Listing" src={images[0]} className={classes.image} />
          {imageCount > 1 && (
            <Box display="inline-grid" marginLeft="2px" style={{ gap: '2px' }}>
              <img alt="Listing" src={images[1]} className={clsx(classes.image, 'small')} />
              {imageCount > 2 && (
                <>
                  <img alt="Listing" src={images[2]} className={clsx(classes.image, 'small')} />
                  {imageCount > 3 && (
                    <div className={classes.overlay}>
                      <Typography variant="T16" weight="medium" style={{ color: theme.palette.white }}>
                        +{imageCount - 3}
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      );
    }
    return <></>;
  };

  const renderLeftIcon = (): React.ReactNode => {
    const setIcon = (): React.ReactNode => {
      switch (type) {
        case MessageTypeEnum.Succeed:
          return <IcoCheckOutline className={clsx(type)} />;
        case MessageTypeEnum.Caution:
          return <IcoErrorWarningBorder className={clsx(type)} />;
        case MessageTypeEnum.Error:
          return <IcoPriorityBorder className={clsx(type)} />;
        case MessageTypeEnum.Info:
          return <IcoInfoBorder className={clsx(type)} />;
        default:
          return <IcoCheckOutline className={clsx(type)} />;
      }
    };

    return <div className={clsx(classes.leftIcon, iconSize, images && 'images')}>{customLeftIcon || setIcon()}</div>;
  };

  const renderBody = (): React.ReactNode => {
    return (
      <Box marginRight="16px" alignSelf="center" display="flex" flexDirection="row">
        {iconSize === 'large' && renderLeftIcon()}
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row">
            {(iconSize === 'medium' || iconSize === 'small') && renderLeftIcon()}
            <Typography
              variant={titleSize === 'medium' ? 'T14' : 'T16'}
              weight="medium"
              style={{ color: getContentColor(type, color) }}
            >
              {title}
            </Typography>
          </Box>
          {message && renderMessage()}
        </Box>
      </Box>
    );
  };

  const renderMessage = (): React.ReactNode => {
    return (
      <Box marginTop="8px" className={classes.messageBox}>
        <Typography variant="T14" weight="regular">
          {message}
        </Typography>
        {messageOnClick && (
          <Button variant="text" color="blue" size="medium" endIcon={<IcoArrowRightOutline />} onClick={messageOnClick}>
            See detail
          </Button>
        )}
        {messageCTAButton}
      </Box>
    );
  };

  const renderRightButton = (): React.ReactNode => {
    if (!hideRightButton) {
      if (customRightButton) {
        return customRightButton;
      }

      return (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <IcoCloseDeleteRemoveBorder />
        </IconButton>
      );
    }
  };

  return (
    <div className={clsx(classes.root, className, type, color)} {...restProps}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex">
          {renderImages()}
          {renderBody()}
        </Box>
        {renderRightButton()}
      </Box>
    </div>
  );
};

export default MessageBox;
