import React, { createContext, ReactNode, Reducer, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useSetAtom } from 'jotai';
import clsx from 'clsx';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { useInView } from 'react-intersection-observer';
import { Outlet, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Box, makeStyles } from '@material-ui/core';
import { MDSLeftNavBar, MDSNavItem, MDSTheme } from '@marqvision/mds/core';
import { useAuth } from '~/apis/auth';
import { scrollContainerRefAtom, userProfileAtom } from '~/atoms';
import { LogoWhite } from '~/components/Dropdown/assets';
import Header, { headerHeight } from '~/components/Layouts/Header';
import { ToastContainer } from '~/components/Toasts/ToastContainer';
import { useGetProfileQuery } from '~/apis/settings';
import pkg from '../../../package.json';
import { getAllowedRoutes, LNBConfig, MARQ_COMMERCE_LNB } from './@constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundColor: MDSTheme.palette.bluegray[850],
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    overflow: 'overlay',
    overflowX: 'hidden',
    marginTop: headerHeight,
    marginLeft: '16px',
    '& #sticky-header': {
      backgroundColor: MDSTheme.palette.white,
    },
  },
  rootScrolling: {
    '& #anchor-wrapper': {
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 8px rgba(0, 0, 0, 0.12)',
      border: '0',
    },
    '& #sticky-header': {
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 8px rgba(0, 0, 0, 0.12)',
      transition: 'box-shadow 700ms',
    },
  },
  contentWrapper: {
    display: 'flex',
    gap: '4px',
    width: '100%',
  },
  content: {
    position: 'relative',
    overflow: 'hidden',
    flexGrow: 1,
    backgroundColor: 'white',
    borderRadius: '24px 24px 0 0',
  },
  scrollbar: {
    overflow: 'overlay',
    overflowX: 'auto',
    height: `calc(100vh - ${headerHeight})`,
  },
  fixedPanelWrapper: {
    marginLeft: '4px',
    '& .MuiDrawer-paper': {
      top: headerHeight,
      borderRadius: '24px 24px 0 0',
      '& .panel-header': {
        borderRadius: '24px 24px 0 0',
      },
    },
  },
  nonStickyWrapper: {
    position: 'relative',
    backgroundColor: '#fff',
    zIndex: 1100,
  },
}));

interface ILayoutContext {
  title: ReactNode;
  brandUnselectable?: boolean;
  isBrandChipDisabled?: boolean;
  fixedPanel?: ReactNode;
  actionButton?: ReactNode;
  menuButton?: ReactNode;
  nonStickySection?: ReactNode;
  stickySection?: ReactNode;
}

const defaultValue = {
  title: '',
  brandUnselectable: undefined,
  isBrandChipDisabled: undefined,
  fixedPanel: undefined,
  actionButton: undefined,
  menuButton: undefined,
  nonStickySection: undefined,
  stickySection: undefined,
};

export const LayoutWrapperContext = createContext<[ILayoutContext, React.Dispatch<Partial<ILayoutContext>>]>([
  defaultValue,
  () => null,
]);

const LayoutWrapper = (): JSX.Element => {
  const classes = useStyles();

  const [state, setState] = useReducer<Reducer<ILayoutContext, Partial<ILayoutContext>>>(
    (_, partialState) => ({ ...defaultValue, ...partialState }),
    defaultValue
  );

  const handleChangeLayoutState: React.Dispatch<Partial<ILayoutContext>> = useCallback((newState) => {
    setState({
      ...defaultValue,
      ...newState,
    });
  }, []);

  const {
    title,
    brandUnselectable,
    isBrandChipDisabled,
    fixedPanel,
    actionButton,
    menuButton,
    nonStickySection,
    stickySection,
  } = state;

  const growthBook = useGrowthBook();
  const { pathname } = useLocation();

  const setScrollContainerRefAtom = useSetAtom(scrollContainerRefAtom);
  const { data: auth } = useAuth();
  const clientId = auth?.client_id;
  const { data } = useGetProfileQuery({
    enabled: !!auth?.access_token,
  });
  const setAtom = useSetAtom(userProfileAtom);

  useEffect(() => {
    setAtom(data);
  }, [data, setAtom]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { ref, inView } = useInView();

  // const { children } = props;

  const [openNav, setOpenNav] = useState(true);

  useEffect(() => {
    growthBook?.setAttributes({
      client_id: clientId,
    });
  }, [clientId, growthBook]);

  const [allowedRoutes, setAllowedRoute] = useState<LNBConfig[]>([]);

  useEffect(() => {
    getAllowedRoutes(MARQ_COMMERCE_LNB).then((res) => {
      setAllowedRoute(res);
    });
  }, []);

  useEffect(() => {
    setScrollContainerRefAtom(containerRef.current);
  }, [containerRef.current]);

  return (
    <LayoutWrapperContext.Provider value={[state, handleChangeLayoutState]}>
      <div className={classes.root}>
        <div className={clsx(classes.wrapper, { [classes.rootScrolling]: !inView })}>
          <Header
            openNav={openNav}
            title={title}
            brandUnselectable={brandUnselectable}
            isBrandChipDisabled={isBrandChipDisabled}
            actionButton={actionButton}
            menuButton={menuButton}
          />
          <Box display="flex" width="100%">
            <MDSLeftNavBar
              logo={<LogoWhite />}
              onClickHome={() => {
                window.location.replace(`/?${pkg.version}`);
              }}
              HomeLink={'/'}
              style={{ zIndex: 1110 }}
              onOpenNav={setOpenNav}
            >
              {allowedRoutes.map((menu) =>
                menu.type === 'LNB_ITEM' ? (
                  <MDSNavItem
                    key={menu.display?.text}
                    label={menu.display?.text}
                    link={menu.path}
                    icon={menu.display?.icon ? menu.display.icon : undefined}
                    dividerTop={menu.display?.dividerTop}
                    isSelected={
                      allowedRoutes.some((v) => v.type === 'LNB_ITEM' && v.path !== menu.path && pathname === v.path)
                        ? false
                        : undefined
                    }
                  />
                ) : (
                  <MDSNavItem
                    key={menu.display?.text}
                    label={menu.display?.text}
                    link={menu.path}
                    icon={menu.display?.icon ? menu.display.icon : undefined}
                    subNavItem={menu.items?.map((sub) => ({
                      label: sub.display?.text,
                      link: sub.path,
                    }))}
                    dividerTop={menu.display?.dividerTop}
                  />
                )
              )}
            </MDSLeftNavBar>
            <main className={clsx(classes.content)}>
              <div id="scroll-wrapper" ref={containerRef} className={classes.scrollbar}>
                {nonStickySection ? <div className={classes.nonStickyWrapper}>{nonStickySection}</div> : undefined}
                <div
                  ref={ref}
                  style={{ position: 'sticky', left: 0, top: 0, zIndex: 10, backgroundColor: MDSTheme.palette.white }}
                >
                  {stickySection}
                </div>
                <Outlet />
              </div>
              <ToastContainer />
            </main>
            {fixedPanel && (
              <div id="fixed-panel" className={classes.fixedPanelWrapper}>
                {fixedPanel}
              </div>
            )}
          </Box>
        </div>
      </div>
    </LayoutWrapperContext.Provider>
  );
};

export default LayoutWrapper;
