/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolFileResponse,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingCountByAnalysisTypeRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingCountByAnalysisTypeResponse,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingsOverTimeCsvRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingsOverTimeRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingsOverTimeResponse,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetRemovedDataRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetRemovedDataResponse,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopAssetCsvRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopAssetDataRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopAssetDataResponse,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopCountryCsvRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopCountryDataRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopCountryDataResponse,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopMarketplaceCsvRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopMarketplaceDataRequest,
  MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopMarketplaceDataResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PerformanceDetailsEnfAnalysis<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisCountCreate
   * @request POST:/performance/details/enf-analysis/count
   */
  detailsEnfAnalysisCountCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingCountByAnalysisTypeRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingCountByAnalysisTypeResponse, any>({
      path: `/performance/details/enf-analysis/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisCreate
   * @request POST:/performance/details/enf-analysis
   */
  detailsEnfAnalysisCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingsOverTimeRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingsOverTimeResponse, any>({
      path: `/performance/details/enf-analysis`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisRemovedDataCreate
   * @request POST:/performance/details/enf-analysis/removed-data
   */
  detailsEnfAnalysisRemovedDataCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetRemovedDataRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetRemovedDataResponse, any>({
      path: `/performance/details/enf-analysis/removed-data`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisTopAssetCreate
   * @request POST:/performance/details/enf-analysis/top-asset
   */
  detailsEnfAnalysisTopAssetCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopAssetDataRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopAssetDataResponse, any>({
      path: `/performance/details/enf-analysis/top-asset`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisTopMarketplaceCreate
   * @request POST:/performance/details/enf-analysis/top-marketplace
   */
  detailsEnfAnalysisTopMarketplaceCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopMarketplaceDataRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopMarketplaceDataResponse, any>({
      path: `/performance/details/enf-analysis/top-marketplace`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisTopCountryCreate
   * @request POST:/performance/details/enf-analysis/top-country
   */
  detailsEnfAnalysisTopCountryCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopCountryDataRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopCountryDataResponse, any>({
      path: `/performance/details/enf-analysis/top-country`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisCsvCreate
   * @request POST:/performance/details/enf-analysis/csv
   */
  detailsEnfAnalysisCsvCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetListingsOverTimeCsvRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/performance/details/enf-analysis/csv`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisTopCountryCsvCreate
   * @request POST:/performance/details/enf-analysis/top-country/csv
   */
  detailsEnfAnalysisTopCountryCsvCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopCountryCsvRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/performance/details/enf-analysis/top-country/csv`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisTopAssetCsvCreate
   * @request POST:/performance/details/enf-analysis/top-asset/csv
   */
  detailsEnfAnalysisTopAssetCsvCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopAssetCsvRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/performance/details/enf-analysis/top-asset/csv`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsEnfAnalysis
   * @name DetailsEnfAnalysisTopMarketplaceCsvCreate
   * @request POST:/performance/details/enf-analysis/top-marketplace/csv
   */
  detailsEnfAnalysisTopMarketplaceCsvCreate = (
    data: MonitoringPerfDetailsPerfDetailsEnfAnalysisProtocolsGetTopMarketplaceCsvRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/performance/details/enf-analysis/top-marketplace/csv`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
