import { MarqCommonEnumsClientClientRoleEnum } from '~/swagger/data-contracts';
import { getUserInfoQuery } from '~/apis/auth';

const hasHomePageAccess = async () => {
  const authData = await getUserInfoQuery();
  const isAllowed = authData?.role !== MarqCommonEnumsClientClientRoleEnum.LimitedAccess;
  return isAllowed;
};
const hasPerformancePageAccess = async () => {
  const authData = await getUserInfoQuery();
  const isAllowed = authData?.role !== MarqCommonEnumsClientClientRoleEnum.LimitedAccess;
  return isAllowed;
};
const hasPerformanceReportPageAccess = async () => {
  const authData = await getUserInfoQuery();
  const isAllowed = authData?.role !== MarqCommonEnumsClientClientRoleEnum.LimitedAccess;
  return isAllowed;
};
const hasPortfolioAssetsPageAccess = async () => {
  const authData = await getUserInfoQuery();
  const isAllowed = !(!authData?.use_ecommerce && !authData?.use_social_media && authData?.use_stand_alone);
  return isAllowed;
};
const hasPortfolioInfringingImagesPageAccess = async () => {
  const authData = await getUserInfoQuery();
  const isAllowed = !(!authData?.use_ecommerce && !authData?.use_social_media && authData?.use_stand_alone);
  return isAllowed;
};
const hasPortfolioAuthorizedSellersPageAccess = async () => {
  const authData = await getUserInfoQuery();
  const isAllowed = authData?.use_ecommerce || authData?.use_social_media || authData?.use_stand_alone;
  return isAllowed;
};
const hasArchivedPageAccess = async () => {
  // note-@jamie: [PROD-9620] 댓글에 의해 일시적으로 주석처리함.

  // const authData = await fetchAuth();
  // const isAllowed = !(!authData?.use_ecommerce && !authData?.use_social_media && authData?.use_stand_alone);
  // return isAllowed;
  return true;
};

const hasSellerPagesAccess = async () => {
  const authData = await getUserInfoQuery();

  // role == limited access 인 경우에는 모든 브랜드+모든 국가에 권한이 있을 때에만 이 화면에 접근 가능.
  const isAllowedRole = !(
    authData?.role === MarqCommonEnumsClientClientRoleEnum.LimitedAccess &&
    (!authData?.limited_access_policy?.has_all_brand_access || !authData?.limited_access_policy?.has_all_country_access)
  );

  const isAllowedActiveDomainType = !(
    !authData?.use_ecommerce &&
    !authData?.use_social_media &&
    authData?.use_stand_alone
  );

  return isAllowedRole && isAllowedActiveDomainType;
};

const hasSettingTabAccess = async () => {
  const authData = await getUserInfoQuery();
  const isAllowedBillingTabRole = authData?.role !== MarqCommonEnumsClientClientRoleEnum.LimitedAccess;
  const isAllowedPeopleTabRole = authData?.role
    ? [MarqCommonEnumsClientClientRoleEnum.Admin, MarqCommonEnumsClientClientRoleEnum.Owner].includes(authData.role)
    : false;

  return {
    billing: isAllowedBillingTabRole,
    people: isAllowedPeopleTabRole,
  };
};

const hasPriceMonitoringPageAccess = async () => {
  const authData = await getUserInfoQuery();
  return authData?.use_map_monitoring;
};

export const PermissionMap = {
  Home: hasHomePageAccess,
  Performance: hasPerformancePageAccess,
  PerformanceReport: hasPerformanceReportPageAccess,
  PortfolioAssets: hasPortfolioAssetsPageAccess,
  PortfolioInfringingImages: hasPortfolioInfringingImagesPageAccess,
  PortfolioAuthorizedSellers: hasPortfolioAuthorizedSellersPageAccess,
  Archived: hasArchivedPageAccess,
  Seller: hasSellerPagesAccess,
  PriceMonitoring: hasPriceMonitoringPageAccess,
  SettingTab: hasSettingTabAccess,
};
