import React, { useState } from 'react';
import ko from 'date-fns/locale/ko';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { Calendar } from 'react-date-range';
import { Box, makeStyles, Popover } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  calendar: {
    '& .rdrDateDisplayWrapper': {
      display: 'none',
    },
  },
}));

type LocaleType = 'ko' | 'en';

interface IProps {
  renderAnchorComponent: (
    value: string | null | undefined,
    handleOpen: (event: React.SyntheticEvent) => void
  ) => JSX.Element;
  value?: string | null;
  onChange: (value: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  locale?: LocaleType;
}

export const CalendarPicker = ({ renderAnchorComponent, value, onChange, locale = 'en', ...restProps }: IProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleOpen = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      {renderAnchorComponent(value, handleOpen)}
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            marginTop: '4px',
            borderRadius: '8px',
          },
        }}
      >
        <Calendar
          locale={locale === 'ko' ? ko : undefined}
          date={value ? new Date(value) : undefined}
          className={classes.calendar}
          onChange={(date: Date) => {
            onChange(date);
            handleClose();
          }}
          {...restProps}
        />
      </Popover>
    </Box>
  );
};

export default CalendarPicker;
