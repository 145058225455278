/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuthorizedWebsiteProtocolsAddAuthWebsiteRequest,
  AuthorizedWebsiteProtocolsExportAuthWebsiteRequest,
  AuthorizedWebsiteProtocolsGetAllAuthWebsiteIdListRequest,
  AuthorizedWebsiteProtocolsGetAllAuthWebsiteIdListResponse,
  AuthorizedWebsiteProtocolsGetAuthWebsiteCountRequest,
  AuthorizedWebsiteProtocolsGetAuthWebsiteCountResponse,
  AuthorizedWebsiteProtocolsGetAuthWebsiteListRequest,
  AuthorizedWebsiteProtocolsGetAuthWebsiteListResponse,
  MarqCommonProtocolFileResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AuthorizedWebsite<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags AuthorizedWebsite
   * @name CountCreate
   * @request POST:/authorized-website/count
   */
  countCreate = (data: AuthorizedWebsiteProtocolsGetAuthWebsiteCountRequest, params: RequestParams = {}) =>
    this.http.request<AuthorizedWebsiteProtocolsGetAuthWebsiteCountResponse, any>({
      path: `/authorized-website/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedWebsite
   * @name ListCreate
   * @request POST:/authorized-website/list
   */
  listCreate = (data: AuthorizedWebsiteProtocolsGetAuthWebsiteListRequest, params: RequestParams = {}) =>
    this.http.request<AuthorizedWebsiteProtocolsGetAuthWebsiteListResponse, any>({
      path: `/authorized-website/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedWebsite
   * @name GetAllIdCreate
   * @request POST:/authorized-website/get_all_id
   */
  getAllIdCreate = (data: AuthorizedWebsiteProtocolsGetAllAuthWebsiteIdListRequest, params: RequestParams = {}) =>
    this.http.request<AuthorizedWebsiteProtocolsGetAllAuthWebsiteIdListResponse, any>({
      path: `/authorized-website/get_all_id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedWebsite
   * @name ExportCreate
   * @request POST:/authorized-website/export
   */
  exportCreate = (data: AuthorizedWebsiteProtocolsExportAuthWebsiteRequest, params: RequestParams = {}) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/authorized-website/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizedWebsite
   * @name PostAuthorizedWebsite
   * @request POST:/authorized-website/add
   */
  postAuthorizedWebsite = (data: AuthorizedWebsiteProtocolsAddAuthWebsiteRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/authorized-website/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
