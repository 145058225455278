import { JSX } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { IcoCheckOutline, IcoCloseDeleteRemoveBorder, IcoErrorWarningBorder, IcoNoFavicon, IcoPriorityBorder } from '../assets';
import { MDSButton } from './index';
import Typography from './Typography';
import theme from './Theme';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  toastIcon: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
    '& path': {
      fill: (props: ISnackbarProps) => getContentColor(props.type),
    },
  },
  successIcon: {
    '& path': {
      fill: theme.palette.green[700],
    },
  },
  errorIcon: {
    '& path': {
      fill: theme.palette.red[700],
    },
  },
  cautionIcon: {
    '& path': {
      fill: theme.palette.yellow[700],
    },
  },
  toastImage: {
    objectFit: 'cover',
    width: '66px',
    height: '66px',
    borderRadius: '4px',
  },
  imageSmall: {
    width: '32px',
    height: '32px',
  },
  overlay: {
    position: 'absolute',
    bottom: '12px',
    borderRadius: '4px',
    width: '32px',
    height: '32px',
    paddingTop: '7px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(Aux-Oiseaux_MOOD_Ay)',
  },
}));

export interface ISnackbarProps {
  type: 'completed' | 'caution' | 'succeed' | 'error';
  title: string;
  images?: string[];
  message?: string;
  hideCloseButton?: boolean;
  actionButton?: {
    dismissBefore?: boolean;
    text: string;
    event: () => void;
  };
}

const TypeColor = {
  completed: 'bluegray',
  caution: 'yellow',
  succeed: 'green',
  error: 'red',
} as const;

const getBackgroundColor = (type: keyof typeof TypeColor) => {
  if (type === 'completed') {
    return theme.palette[TypeColor[type]][1000];
  } else {
    return theme.palette[TypeColor[type]][50];
  }
};

const getContentColor = (type: keyof typeof TypeColor) => {
  if (type === 'completed') {
    return theme.palette.white;
  } else if (type === 'caution') {
    return theme.palette[TypeColor[type]][800];
  }

  return theme.palette[TypeColor[type]][700];
};

const Image = (props: { src: string; className: string; size: { width: number; height: number } }) => {
  const {
    src,
    className,
    size: { width, height },
  } = props;
  const isValidUrl = /^(http|https):\/\/[^ "]+$/.test(src);
  return (
    <>
      {isValidUrl ? (
        <img alt="Listing" src={src} className={className} />
      ) : (
        <IcoNoFavicon width={width} height={height} />
      )}
    </>
  );
};

export const MDSSnackbar = (props: ISnackbarProps): JSX.Element => {
  const classes = useStyles(props);

  const { type, images, title, message, actionButton } = props;

  const renderImages = (): JSX.Element | void => {
    if (images) {
      const imageCount = images.length || 0;
      return (
        <Box marginRight="16px" display="flex">
          <Image src={images[0]} className={classes.toastImage} size={{ width: 66, height: 66 }} />
          {imageCount > 1 && (
            <Box display="inline-grid" marginLeft="2px" gridGap="2px">
              <Image
                src={images[1]}
                className={clsx(classes.toastImage, classes.imageSmall)}
                size={{ width: 32, height: 32 }}
              />
              {imageCount > 2 && (
                <>
                  <Image
                    src={images[2]}
                    className={clsx(classes.toastImage, classes.imageSmall)}
                    size={{ width: 32, height: 32 }}
                  />
                  {imageCount > 3 && (
                    <div className={classes.overlay}>
                      <Typography variant="T16" weight="medium" style={{ color: theme.palette.white }}>
                        +{imageCount - 3}
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      );
    }
  };

  return (
    <Box justifyContent="space-between" className={classes.root}>
      <>{renderImages()}</>
      <Box flex="1" alignSelf="center">
        <Box display="flex" alignItems="center">
          {type === 'completed' && <IcoCheckOutline className={clsx(classes.toastIcon, classes.successIcon)} />}
          {type === 'succeed' && <IcoCheckOutline className={clsx(classes.toastIcon, classes.successIcon)} />}
          {type === 'error' && <IcoPriorityBorder className={clsx(classes.toastIcon, classes.errorIcon)} />}
          {type === 'caution' && <IcoErrorWarningBorder className={clsx(classes.toastIcon, classes.cautionIcon)} />}
          <Typography
            lineClamp={1}
            variant="T16"
            weight="medium"
            style={{ color: getContentColor(type), textAlign: 'left' }}
          >
            {title}
          </Typography>
        </Box>
        {message && (
          <Box marginTop="8px" textAlign="left">
            <Typography
              lineClamp={2}
              variant="T14"
              weight="regular"
              style={{ color: type === 'completed' ? theme.palette.white : theme.palette.bluegray[800] }}
            >
              {message}
            </Typography>
          </Box>
        )}
      </Box>
      {actionButton && (
        <MDSButton
          color="blue"
          size="large"
          variant="text"
          style={{ marginRight: '16px' }}
          onClick={actionButton.event}
        >
          {actionButton.text}
        </MDSButton>
      )}
    </Box>
  );
};

const SnackbarOptions = {
  pauseOnHover: true,
  autoClose: 10000,
  closeButton: (
    <IconButton style={{ alignSelf: 'center', width: '20px', height: '20px' }}>
      <IcoCloseDeleteRemoveBorder />
    </IconButton>
  ),
  style: {
    width: '800px',
    minHeight: '45px',
    borderRadius: '8px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    padding: '12px 16px',
  },
};

const MDSToastSnackbar = (props: ISnackbarProps): void => {
  if (props.actionButton?.dismissBefore) {
    toast.dismiss();
  }

  toast(<MDSSnackbar {...props} />, {
    ...SnackbarOptions,
    closeButton: props.hideCloseButton ? false : SnackbarOptions.closeButton,
    style: {
      ...SnackbarOptions.style,
      backgroundColor: getBackgroundColor(props.type),
    },
  });
};

export default MDSToastSnackbar;

export const dismissToast = (): void => {
  toast.dismiss();
};
