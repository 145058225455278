import { UseQueryResult, useQuery, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { enforcementWIMonitoringApi } from '~/apis';
import {
  MarqCommonProtocolEmptyRequest,
  MonitoringFilterProtocolResponse,
  MonitoringWebsiteSWEnforcedProtocolsGetDomainFilterRequest,
  MonitoringWebsiteSWEnforcedProtocolsGetEnforcementTabsResponse,
} from '~/swagger/data-contracts';
import { useClientBrandIds } from '~/pages/Enforcement/@hooks';

export const WebsiteImpersonationQueryKey = 'enforcement/websiteImpersonation';

export const useGetEnforcementWIStatus = (
  request: MarqCommonProtocolEmptyRequest
): UseQueryResult<MonitoringWebsiteSWEnforcedProtocolsGetEnforcementTabsResponse, unknown> => {
  const { brandId } = useClientBrandIds();

  return useQuery(
    [WebsiteImpersonationQueryKey, 'enforcement-website-impersonation-status', { ...request, brandId }],
    async () => {
      const { data } = await enforcementWIMonitoringApi.enforcementWebSiteTabsCreate({ ...request, brand_id: brandId });

      return data;
    }
  );
};

export const useWebsiteEnforcementFilterDomainQuery = <TData = MonitoringFilterProtocolResponse>(
  request: MonitoringWebsiteSWEnforcedProtocolsGetDomainFilterRequest,
  options?: UseInfiniteQueryOptions<MonitoringFilterProtocolResponse, unknown, TData>
) => {
  const LIMIT = request.limit || 30;
  return useInfiniteQuery<MonitoringFilterProtocolResponse, unknown, TData>(
    [WebsiteImpersonationQueryKey, 'get-all-domains', request],
    async ({ pageParam }) => {
      const { data } = await enforcementWIMonitoringApi.enforcementWebSiteFilterDomainCreate({
        ...request,
        limit: LIMIT,
        offset: pageParam || request.offset,
      });
      return data;
    },
    {
      enabled: !!request.tab,
      ...options,
      getNextPageParam: (lastPage, allPage) => {
        if (lastPage.list.length < LIMIT) {
          return;
        }

        return LIMIT * allPage.length;
      },
    }
  );
};
