import { MarqCommonEnumsDomainTypeEnum } from '~/swagger/data-contracts';
import { TabEnum } from '~/types/websiteImpersonation';

export const DEFAULT_TAB: TabEnum = TabEnum.EcommerceAndSocialMedia;
export const LIMIT = 50;
export const domainTypeList: Record<TabEnum, MarqCommonEnumsDomainTypeEnum[]> = {
  [TabEnum.EcommerceAndSocialMedia]: [
    MarqCommonEnumsDomainTypeEnum.Ecommerce,
    MarqCommonEnumsDomainTypeEnum.SocialMedia,
  ],
  [TabEnum.Website]: [MarqCommonEnumsDomainTypeEnum.StandAlone],
};
export const tabLabel: Record<TabEnum, string> = {
  [TabEnum.EcommerceAndSocialMedia]: 'Ecommerce & Social media',
  [TabEnum.Website]: 'Website',
};
export const logTabLabel: Record<TabEnum, string> = {
  [TabEnum.EcommerceAndSocialMedia]: 'ecommerce & social media',
  [TabEnum.Website]: 'website',
};
export const addButtonLabel: Record<TabEnum, string> = {
  [TabEnum.EcommerceAndSocialMedia]: 'Authorized sellers',
  [TabEnum.Website]: 'Authorized websites',
};
export const addPanelTitle: Record<TabEnum, string> = {
  [TabEnum.EcommerceAndSocialMedia]: 'Add authorized sellers',
  [TabEnum.Website]: 'Add authorized websites',
};
export const logAddSuccessEventName: Record<TabEnum, string> = {
  [TabEnum.EcommerceAndSocialMedia]: 'added authorized sellers',
  [TabEnum.Website]: 'added authorized website',
};
export const getAddSuccessMessage = (tab: TabEnum, list: string[] | number[]) => {
  return tab === TabEnum.EcommerceAndSocialMedia
    ? `The requested seller will be added within the next few hours.`
    : list.length === 1
    ? `1 website has been successfully added to the authorized list.`
    : `${list.length} websites have been successfully added to the authorized list.`;
};
export const suffixLabel: Record<TabEnum, string> = {
  [TabEnum.EcommerceAndSocialMedia]: 'Sellers',
  [TabEnum.Website]: 'Websites',
};
export const exportFileSuffixLabel: Record<TabEnum, string> = {
  [TabEnum.EcommerceAndSocialMedia]: '_Authorized_Seller',
  [TabEnum.Website]: '_Authorized_Website',
};
