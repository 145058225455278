import { MarqCommonEnumsArchivedReasonEnum, MarqCommonEnumsListingStatusEnum } from '~/swagger/data-contracts';

const CUSTOMIZED_TARGET_CLIENT_ID_728 = 728;
const CUSTOMIZED_TARGET_CLIENT_ID_1000 = 1000;

const archiveReasonLabel = {
  [MarqCommonEnumsArchivedReasonEnum.Dismissed]: 'Dismissed',
  [MarqCommonEnumsArchivedReasonEnum.Later]: 'Review later',
  [MarqCommonEnumsArchivedReasonEnum.Gray]: 'Gray market listing',
} as const;

const archiveReasonLabelBy728 = {
  [MarqCommonEnumsArchivedReasonEnum.Dismissed]: 'Dismissed',
  [MarqCommonEnumsArchivedReasonEnum.Later]: 'Review later',
  [MarqCommonEnumsArchivedReasonEnum.Gray]: 'Highly Likely Infringing',
} as const;

const archiveReasonLabelBy1000 = {
  [MarqCommonEnumsArchivedReasonEnum.Dismissed]: 'Dismissed',
  [MarqCommonEnumsArchivedReasonEnum.Later]: 'Review later',
  [MarqCommonEnumsArchivedReasonEnum.Gray]: 'Test Purchase listing',
} as const;

const archiveListingStatusLabel = {
  [MarqCommonEnumsListingStatusEnum.Dismissed]: 'Dismissed',
  [MarqCommonEnumsListingStatusEnum.Later]: 'Review later',
  [MarqCommonEnumsListingStatusEnum.Gray]: 'Gray market listing',
} as const;

const archiveListingStatusLabelBy728 = {
  [MarqCommonEnumsListingStatusEnum.Dismissed]: 'Dismissed',
  [MarqCommonEnumsListingStatusEnum.Later]: 'Review later',
  [MarqCommonEnumsListingStatusEnum.Gray]: 'Highly Likely Infringing',
} as const;

const archiveListingStatusLabelBy1000 = {
  [MarqCommonEnumsListingStatusEnum.Dismissed]: 'Dismissed',
  [MarqCommonEnumsListingStatusEnum.Later]: 'Review later',
  [MarqCommonEnumsListingStatusEnum.Gray]: 'Test Purchase listing',
} as const;

/**
 * [IMPORTANT] 해당 constant 는 수정시 PM 와 미리 싱크해야 합니다.
 */
const logArchivedTypeLabel = {
  [MarqCommonEnumsArchivedReasonEnum.Dismissed]: 'Dismissed',
  [MarqCommonEnumsArchivedReasonEnum.Later]: 'Review later',
  [MarqCommonEnumsArchivedReasonEnum.Gray]: 'Gray market listing',
} as const;

export const getArchivedReasonLabelByClientId = (
  type: MarqCommonEnumsArchivedReasonEnum,
  clientId?: number
):
  | (typeof archiveReasonLabel)[MarqCommonEnumsArchivedReasonEnum]
  | (typeof archiveReasonLabelBy728)[MarqCommonEnumsArchivedReasonEnum]
  | (typeof archiveReasonLabelBy1000)[MarqCommonEnumsArchivedReasonEnum] => {
  // https://marqvision.atlassian.net/browse/PROD-8470
  if (clientId === CUSTOMIZED_TARGET_CLIENT_ID_728) {
    return archiveReasonLabelBy728[type];
  }
  if (clientId === CUSTOMIZED_TARGET_CLIENT_ID_1000) {
    return archiveReasonLabelBy1000[type];
  }
  return archiveReasonLabel[type];
};
type GetArchivedListingStatusLabelByClientIdType =
  | MarqCommonEnumsListingStatusEnum.Dismissed
  | MarqCommonEnumsListingStatusEnum.Later
  | MarqCommonEnumsListingStatusEnum.Gray;
export const getArchivedListingStatusLabelByClientId = (
  type: GetArchivedListingStatusLabelByClientIdType,
  clientId?: number
):
  | (typeof archiveListingStatusLabel)[GetArchivedListingStatusLabelByClientIdType]
  | (typeof archiveListingStatusLabelBy728)[GetArchivedListingStatusLabelByClientIdType]
  | (typeof archiveListingStatusLabelBy1000)[MarqCommonEnumsArchivedReasonEnum] => {
  // https://marqvision.atlassian.net/browse/PROD-8470
  if (clientId === CUSTOMIZED_TARGET_CLIENT_ID_728) {
    return archiveListingStatusLabelBy728[type];
  }
  if (clientId === CUSTOMIZED_TARGET_CLIENT_ID_1000) {
    return archiveListingStatusLabelBy1000[type];
  }
  return archiveListingStatusLabel[type];
};

export const getLogArchivedReasonLabel = (
  type: MarqCommonEnumsArchivedReasonEnum
): (typeof logArchivedTypeLabel)[MarqCommonEnumsArchivedReasonEnum] => {
  return logArchivedTypeLabel[type];
};
