import { RefObject, useCallback, useEffect } from 'react';
import { useAuth } from '~/apis/auth';
import { IUseMonitorListingKeyboardNavigationProps } from '../Detection/@types';

export const useClientBrandIds = () => {
  const { data: auth } = useAuth();
  return {
    brandId: auth?.selectedBrandId,
    clientId: auth?.client_id,
  } as {
    brandId: number;
    clientId: number;
  };
};

//TODO: 2024/01/12 배포 이후 공통 custom hook shared로 별도로 추출, admin, client 로직 99% 비슷 (eddie)
export const useMonitorListingKeyboardNavigation = ({
  listingIds,
  openListingId,
  onNavigate,
  containerRef,
}: IUseMonitorListingKeyboardNavigationProps) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (openListingId !== null && onNavigate) {
        const currentIndex = listingIds.indexOf(openListingId);
        if (event.key === 'ArrowLeft' && currentIndex > 0) {
          const newIndex = currentIndex - 1;
          onNavigate(listingIds[newIndex]);
          scrollToCenter(newIndex);
        } else if (event.key === 'ArrowRight' && currentIndex < listingIds.length - 1) {
          const newIndex = currentIndex + 1;
          onNavigate(listingIds[newIndex]);
          scrollToCenter(newIndex);
        }
      }
    };

    const scrollToCenter = (index: number) => {
      const element = document.getElementById(`listing-${listingIds[index]}`);

      if (element) {
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const elementHeight = element.offsetHeight;
        const offsetTop = element.offsetTop;
        const scrollPosition = Math.max(offsetTop - (windowHeight - elementHeight) / 2, 0);

        containerRef?.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [listingIds, openListingId, onNavigate]);
};

export const useScrollToSelectedItem = (listingRef: RefObject<HTMLElement>) => {
  const scrollTo = useCallback(
    (scrollTargetQuerySelector: string) => {
      setTimeout(() => {
        if (listingRef.current) {
          const selected = listingRef.current.querySelector(scrollTargetQuerySelector);
          selected?.scrollIntoView({ behavior: 'instant', block: 'center' });
        }
      }, 300);
    },
    [listingRef]
  );

  return {
    scrollTo,
  };
};
