import { useAtomValue } from 'jotai/index';
import { MarqBrandModelResponseWebsiteWebsiteDetailInfoResponse } from '~/swagger/data-contracts';
import { useLogger } from '~/hooks';
import { websitePathParamsAtom } from '~/atoms/websiteImpersonation';

/**
 * 참고: MarqBrandModelResponseWebsiteWebsiteDetailResponse
 */
export const useWebsiteDetailInfo = () => {
  const { amplitudeLogging } = useLogger();
  const { page } = useAtomValue(websitePathParamsAtom);
  const handleDownloadProofPdf = (websiteInfo?: MarqBrandModelResponseWebsiteWebsiteDetailInfoResponse) => {
    if (!websiteInfo) return;

    amplitudeLogging('exported snapshot', { On: page, Type: 'website' });
    window.open(websiteInfo?.proof_url as string, '_blank');
  };
  return {
    handleDownloadProofPdf,
  };
};

export type UseWebsiteDetailInfo = ReturnType<typeof useWebsiteDetailInfo>;
