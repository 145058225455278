import { useCallback, useEffect, useMemo } from 'react';
import qs from 'query-string';
import { NavigateOptions, useLocation, useSearchParams } from 'react-router-dom';

//#region initial query parameter 설정 동시성 이슈 해결
// 서로 다른 컴포넌트에서 거의 동시에 초기 query parameter를 설정할 때 동시성 이슈 발생으로 인해 일부 query parameter가 누락되는 문제가 있음
// 참고: https://github.com/remix-run/react-router/issues/9757#issuecomment-1359693916
const queryParamQueue: unknown[] = [];
let hasOnGoingQuery = false;
function useInitConcurrentQueryParam<Query = Record<string, string>>(
  setParam: (value: Partial<Query>, options?: NavigateOptions) => void,
  initialValue?: Partial<Query>,
  initializeOptions?: NavigateOptions
) {
  const location = useLocation();

  useEffect(() => {
    if (initialValue && !location.search) {
      queryParamQueue.push({ value: initialValue, options: initializeOptions });

      setTimeout(() => {
        while (true) {
          if (queryParamQueue.length === 0) break;
          if (hasOnGoingQuery) return;

          hasOnGoingQuery = true;

          const item = queryParamQueue.pop() as { value: Partial<Query>; options?: NavigateOptions };
          if (item) {
            setParam(item.value, item.options);
            hasOnGoingQuery = false;
          }
        }
      }, 0);
    }
  }, [JSON.stringify(initialValue), location.pathname, location.search]);
}
//#endregion

export function useQueryParams<Query = Record<string, string>>(
  initialValue?: Partial<Query>,
  initializeOptions?: NavigateOptions
) {
  const location = useLocation();
  const [rawParams, setRawParams] = useSearchParams();

  const setParams = useCallback(
    (value: Partial<Query>, options?: NavigateOptions) => {
      setRawParams((prevParams) => {
        Object.entries(value).forEach(([key, value]) => {
          prevParams.set(key, value as string);
        });
        return prevParams;
      }, options);
    },
    [setRawParams]
  );

  const removeParams = useCallback(
    (key: keyof Query | (keyof Query)[], options?: NavigateOptions) => {
      if (key instanceof Array) {
        let flag = false;
        key.forEach((k) => {
          if (rawParams.has(k as string)) {
            rawParams.delete(k as string);
            flag = true;
          }
        });
        if (flag) {
          setRawParams(rawParams, options);
        }
      } else {
        if (rawParams.has(key as string)) {
          rawParams.delete(key as string);
          setRawParams(rawParams, options);
        }
      }
    },
    [rawParams]
  );

  const removeAll = useCallback(
    (options?: NavigateOptions & { exclude?: (keyof Query)[] }) => {
      const keys = [...rawParams.keys()];
      for (const key of keys) {
        if (options?.exclude && options.exclude.includes(key as keyof Query)) {
          continue;
        }
        rawParams.delete(key);
      }
      setRawParams(rawParams, options);
    },
    [rawParams]
  );

  const params = useMemo(() => {
    // TODO: 조건부 타입을 이용해 parseNumbers 옵션 사용 여부를 결정하도록 수정
    const rawQuery = qs.parse(location.search, { arrayFormat: 'comma', parseNumbers: true, parseBooleans: true });

    // null string은 무조건 null로 변환
    Object.entries(rawQuery).forEach(([key, value]) => {
      if (value === 'null') rawQuery[key] = null;
    });

    return rawQuery as Query;
  }, [location]);

  const stringParams = useMemo(() => {
    //@morgan parseNumbers 옵션 제거를 위해 추가
    const query = qs.parse(location.search, { arrayFormat: 'comma' }) as Query;
    return query;
  }, [location]);

  useInitConcurrentQueryParam(setParams, initialValue, initializeOptions);

  return { params, stringParams, setParams, removeParams, removeAll };
}
