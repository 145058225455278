import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { Chip as MUIChip, makeStyles } from '@material-ui/core';
import { ChipProps } from '@material-ui/core/Chip/Chip';
import { resolveColor } from '@marqvision/mds-v2';
import theme from './Theme';
import Typography from './Typography';
import Tooltip from './Tooltip';
import MDSTag from './Tag';

const useStyles = makeStyles(() => ({
  chip: {
    minWidth: 'auto',
    transition: 'none',
    gap: '4px',
    '& svg': {
      flexShrink: 0,
      // CSSBaseLine? 추가 한 이후로 정렬이 안맞아서
      display: 'block',

      width: '16px',
      height: '16px',
    },
    '&.MuiChip-clickable:active': {
      boxShadow: 'none',
    },
    '& .MuiChip-icon': {
      margin: '0',
    },
    '& .MuiChip-label': {
      padding: '0',
      textOverflow: 'unset',
      width: '100%',
    },
    '& .MuiChip-deleteIcon': {
      // display: 'none' // end icon 이 존재하고 delete icon 역시 별도로 존재하고 싶지 않을까?
      margin: 0,
    },
    '& .label-wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      gap: '4px',
    },
    '&.extra_small': {
      padding: '0 4px',
    },
    '&.small': {
      minHeight: '26px',
      padding: '3.2px 8px',
      borderRadius: '13px',
      gap: '2px',
      '& .label-wrapper': {
        gap: '2px',
      },
    },
    '&.medium': {
      minHeight: '32px',
      padding: '5.5px 12px',
      borderRadius: '16px',
    },
    '&.large': {
      minHeight: '38px',
      padding: '7px 14px',
      borderRadius: '19px',
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
    '&.extra_large': {
      minHeight: '48px',
      padding: '9px 20px',
      borderRadius: '24px',
      gap: '8px',
      '& .label-wrapper': {
        gap: '8px',
      },
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
    ...getChipStyles(),
  },
  chipText: {
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const getColorFillBackground = (key: keyof typeof theme.palette): string => {
  return theme.palette[key][['bluegray', 'blue', 'yellow'].includes(key) ? '700' : '600'];
};

const getColorByIsBlueGray = (key: keyof typeof theme.palette): string => {
  if (key === 'bluegray') return theme.palette.bluegray[900];
  else if (key === 'yellow') return theme.palette.yellow[800];
  else return theme.palette[key][700];
};

const getTintBackgroundColor = (key: keyof typeof theme.palette): string => {
  return key === 'bluegray' ? theme.palette[key]['150'] : theme.palette[key]['50'];
};

const getChipStyles = () => {
  const result = {};

  ([...Object.keys(theme.palette)] as IChipProps['color'][]).forEach((key: IChipProps['color']) => {
    Object.assign(result, {
      [`&.${key}.fill`]:
        key === 'white'
          ? {
              backgroundColor: theme.palette.white,
              color: theme.palette.bluegray[900],
              '&:hover': {
                backgroundColor: theme.palette.bluegray[100],
              },
              '& svg > path': {
                fill: theme.palette.bluegray[900],
              },
              '&[aria-disabled=true]': {
                color: theme.palette.bluegray[900],
                backgroundColor: theme.palette.bluegray[600],
                opacity: 1,
                '& svg > path': {
                  fill: theme.palette.bluegray[900],
                },
              },
            }
          : {
              backgroundColor: getColorFillBackground(key),
              color: theme.palette.white,
              '&:hover': {
                backgroundColor: ['bluegray', 'blue', 'yellow'].includes(key)
                  ? theme.palette[key][800]
                  : theme.palette[key][700],
              },
              '& svg > path': {
                fill: theme.palette.white,
              },
              '&[aria-disabled=true]': {
                color: theme.palette.white,
                backgroundColor: theme.palette[key][300],
                opacity: 1,
                '& svg:not(.icon-colored) > path': {
                  fill: theme.palette.white,
                },
              },
            },
      [`&.${key}.tint`]:
        key === 'white'
          ? {
              color: theme.palette.white,
              backgroundColor: theme.palette.bluegray[800],
              '&:hover': {
                backgroundColor: theme.palette.bluegray[700],
              },
              '& svg > path': {
                fill: theme.palette.white,
              },
              '&[aria-disabled=true]': {
                color: theme.palette.bluegray[400],
                backgroundColor: theme.palette.bluegray[800],
                opacity: 1,
                '& svg > path': {
                  fill: theme.palette.bluegray[400],
                },
              },
            }
          : {
              backgroundColor: getTintBackgroundColor(key),
              color: getColorByIsBlueGray(key),
              '&:hover': {
                backgroundColor: key === 'bluegray' ? theme.palette[key][200] : theme.palette[key][100],
              },
              '& svg:not(.icon-colored) > path': {
                fill: getColorByIsBlueGray(key),
              },
              '&[aria-disabled=true]': {
                color: theme.palette[key][300],
                backgroundColor: getTintBackgroundColor(key),
                opacity: 1,
                '& svg:not(.icon-colored) > path': {
                  fill: theme.palette[key][300],
                },
              },
            },
      [`&.${key}.outline`]: {
        ...(key === 'white'
          ? {
              border: `1px solid ${theme.palette.white}`,
              color: theme.palette.white,
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: theme.palette.bluegray[800],
              },
              '& svg:not(.icon-colored) > path': {
                fill: theme.palette.white,
              },
              '&[aria-disabled=true]': {
                border: `1px solid ${theme.palette.bluegray[400]}`,
                color: theme.palette.bluegray[400],
                backgroundColor: 'transparent',
                opacity: 1,
                '& svg:not(.icon-colored) > path': {
                  fill: theme.palette.bluegray[400],
                },
              },
            }
          : {
              border: `1px solid ${theme.palette.bluegray['200']}`,
              color: getColorByIsBlueGray(key),
              backgroundColor: theme.palette.white,
              '&:hover': {
                backgroundColor: theme.palette.bluegray[100],
              },
              '& svg:not(.icon-colored) > path': {
                fill: getColorByIsBlueGray(key),
              },
              '&[aria-disabled=true]': {
                border: `1px solid ${theme.palette.bluegray[150]}`,
                color: theme.palette[key][300],
                opacity: 1,
                '& svg:not(.icon-colored) > path': {
                  fill: theme.palette[key][300],
                },
              },
            }),
        '&.small': {
          padding: '2.2px 8px',
        },
        '&.medium': {
          padding: '4.5px 12px',
        },
        '&.large': {
          padding: '6px 14px',
        },
        '&.extra_large': {
          padding: '8px 20px',
        },
      },
    });
  });

  return result;
};

export interface IChipProps {
  width?: string;
  variant: 'fill' | 'tint' | 'outline';
  color: keyof typeof theme.palette;
  size?: 'small' | 'extra_small' | 'medium' | 'large' | 'extra_large';
  endIcon?: React.ReactNode;
  endIconClick?: (event: MouseEvent<HTMLSpanElement>) => void;
  selected?: {
    name?: string;
    count?: string | number;
    color?: keyof typeof theme.palette;
    startIcon?: React.ReactNode;
    ellipsis?: boolean;
  } | null;
  /**
   * label의 길이가 입력받은 숫자보다 길면
   * 마우스 hover시 툴팁을 보여줌
   */
  textLengthToShowTooltip?: number;
  label?: React.ReactNode;
  labelStyle?: React.CSSProperties;
  multiple?: boolean;
}

enum ChipTypoVariantEnum {
  ExtraSmall = 'T12',
  Small = 'T13',
  Medium = 'T14',
  Large = 'T16',
  ExtraLarge = 'T20',
}

type IProps = IChipProps & Omit<ChipProps, 'variant' | 'color' | 'size' | 'label' | 'children'>;

const useMDSv2Color = makeStyles(() => ({
  mdsV2Color: {
    '& svg > path': {
      fill: ({ iconColor }: { iconColor?: string }) => `${iconColor} !important` ?? 'currentColor',
    },
  },
}));

export const MDSChip = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const {
    size = 'medium',
    color,
    variant,
    endIcon,
    endIconClick,
    selected,
    label,
    className,
    width,
    textLengthToShowTooltip,
    style,
    labelStyle,
    multiple,
    ...restProps
  } = props;

  const handleEndIconClick = (event: MouseEvent<HTMLSpanElement>) => {
    if (endIconClick) {
      endIconClick(event);
    }
  };

  const renderSelectedName = (): string | undefined => {
    if (selected) {
      const { name, ellipsis = true } = selected;
      const _name =
        typeof name === 'string' ? name?.replace(/(?<name>\S+)\s?\((?<number>\d+)\)/g, '$<name>') || name : name;
      if (ellipsis && _name && _name.length > 20) {
        return _name.substring(0, 20) + '...';
      }
      return _name;
    }
  };

  const renderLabel = () => {
    const variant: ChipTypoVariantEnum = {
      small: ChipTypoVariantEnum.Small,
      extra_small: ChipTypoVariantEnum.ExtraSmall,
      medium: ChipTypoVariantEnum.Medium,
      large: ChipTypoVariantEnum.Large,
      extra_large: ChipTypoVariantEnum.ExtraLarge,
    }[size];

    const text = (
      <Typography
        className={classes.chipText}
        variant={variant}
        weight="medium"
        style={{ color: 'inherit', lineClamp: multiple ? undefined : 1, whiteSpace: multiple ? 'pre-wrap' : 'nowrap' }}
      >
        {label}
      </Typography>
    );

    const content = label && (React.isValidElement(label) ? label : text);

    return (
      <div className="label-wrapper" style={{ ...labelStyle }}>
        {selected ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {content}
            {!!selected.name && (
              <MDSTag
                size="small"
                variant={props.variant === 'fill' ? 'tint' : 'fill'}
                color={selected.color || props.color}
                label={renderSelectedName()}
              />
            )}
            {!!selected.count && (
              <MDSTag
                size="small"
                startIcon={selected.startIcon}
                variant={props.variant === 'fill' ? 'tint' : 'fill'}
                color={selected.color || props.color}
                label={selected.count}
              />
            )}
          </div>
        ) : (
          content
        )}
        {endIcon && (
          <span
            className="end-icon"
            style={{ cursor: endIconClick ? 'pointer' : 'inherit' }}
            onClick={(event: MouseEvent<HTMLSpanElement>) => handleEndIconClick(event)}
          >
            {endIcon}
          </span>
        )}
      </div>
    );
  };

  const mdsV2ColorClasses = useMDSv2Color({
    iconColor: props.icon?.props.color ? resolveColor(props.icon?.props.color) : undefined, // MDSv2 Icon 컴포넌트의 color prop을 사용하기 위해 임시 추가
  });

  const renderChip = (
    <MUIChip
      style={{ width, height: multiple ? 'auto' : 'unset', ...style }}
      className={clsx(
        classes.chip,
        className,
        size,
        color,
        variant,
        props.icon?.props.color && mdsV2ColorClasses.mdsV2Color
      )}
      label={renderLabel()}
      {...restProps}
    />
  );

  return React.isValidElement(label) ? (
    renderChip
  ) : textLengthToShowTooltip && textLengthToShowTooltip <= (label as string).length ? (
    <Tooltip title={label as string}>{renderChip}</Tooltip>
  ) : (
    renderChip
  );
};

export default MDSChip;
