import React from 'react';
import { Slide, ToastContainer, ToastContainerProps } from 'react-toastify';
import { makeStyles } from '@material-ui/core';
import MDSTheme from '@marqvision/mds/core/Theme';

const useStyles = makeStyles((theme) => ({
  toast: {
    '&.Toastify__toast-container.Toastify__toast-container--bottom-center': {
      width: '100%',
      maxWidth: '768px',
      [theme.breakpoints.down(1280)]: {
        padding: '0 24px 0',
      },
      [theme.breakpoints.down(768)]: {
        padding: '0 16px 16px',
      },
    },
    '& .Toastify__toast': {
      minHeight: '44px',
      borderRadius: '8px',
      paddingLeft: '24px',
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
    '& .Toastify__toast--default': {
      width: '500px',
      backgroundColor: MDSTheme.palette.bluegray[50],
    },
    '& .Toastify__toast--success': {
      color: MDSTheme.palette.bluegray[800],
      backgroundColor: MDSTheme.palette.green[50],
    },
    '& .Toastify__toast--error': {
      backgroundColor: MDSTheme.palette.red[50],
    },
    '& .Toastify__toast--info': {
      backgroundColor: MDSTheme.palette.bluegray[50],
      cursor: 'default',
      padding: 'inherit',
    },
    '& .Toastify__toast-body': {
      ...MDSTheme.typography.T13,
      ...MDSTheme.typography.regular,
      color: MDSTheme.palette.white,
      textAlign: 'center',
      overflow: 'hidden',
      padding: '0',
    },
  },
}));

export const Toast = (props: ToastContainerProps & React.RefAttributes<HTMLDivElement>): React.ReactNode => {
  const classes = useStyles();

  return (
    <ToastContainer
      className={classes.toast}
      position="bottom-center"
      transition={Slide}
      autoClose={4000}
      closeButton={false}
      hideProgressBar={true}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      limit={1}
      {...props}
    />
  );
};
