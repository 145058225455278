/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyRequest,
  MarqCommonProtocolFileResponse,
  MonitoringFilterProtocolResponse,
  MonitoringWebsiteSWDetectionProtocolsExcelResultRequest,
  MonitoringWebsiteSWDetectionProtocolsExcelResultResponse,
  MonitoringWebsiteSWDetectionProtocolsGetAllWebsiteIdListRequest,
  MonitoringWebsiteSWDetectionProtocolsGetAllWebsiteIdListResponse,
  MonitoringWebsiteSWDetectionProtocolsGetCountRequest,
  MonitoringWebsiteSWDetectionProtocolsGetCountResponse,
  MonitoringWebsiteSWDetectionProtocolsGetDomainFilterRequest,
  MonitoringWebsiteSWDetectionProtocolsGetExportListingsByListingIdRequest,
  MonitoringWebsiteSWDetectionProtocolsGetExportListingsRequest,
  MonitoringWebsiteSWDetectionProtocolsGetExportListingsResponse,
  MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListRequest,
  MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListResponse,
  MonitoringWebsiteSWDetectionProtocolsGetReportingCapResponse,
  MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListRequest,
  MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListResponse,
  MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailRequest,
  MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailResponse,
  MonitoringWebsiteSWDetectionProtocolsRequestAuthorizedRequest,
  MonitoringWebsiteSWDetectionProtocolsRequestAuthorizedResponse,
  MonitoringWebsiteSWDetectionProtocolsRequestEnforceRequest,
  MonitoringWebsiteSWDetectionProtocolsRequestEnforceResponse,
  MonitoringWebsiteSWDetectionProtocolsRequestIgnoreRequest,
  MonitoringWebsiteSWDetectionProtocolsRequestIgnoreResponse,
  MonitoringWebsiteSWDetectionProtocolsUndoEnforcedRequest,
  MonitoringWebsiteSWDetectionProtocolsUndoEnforcedResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringWebsiteDetected<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteReportingCapCreate
   * @request POST:/monitoring/detected/web-site/reporting-cap
   */
  detectedWebSiteReportingCapCreate = (data: MarqCommonProtocolEmptyRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsGetReportingCapResponse, any>({
      path: `/monitoring/detected/web-site/reporting-cap`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteFilterDomainCreate
   * @request POST:/monitoring/detected/web-site/filter/domain
   */
  detectedWebSiteFilterDomainCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsGetDomainFilterRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/monitoring/detected/web-site/filter/domain`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteCountCreate
   * @request POST:/monitoring/detected/web-site/count
   */
  detectedWebSiteCountCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsGetCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsGetCountResponse, any>({
      path: `/monitoring/detected/web-site/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteGridGetListCreate
   * @request POST:/monitoring/detected/web-site/grid/get-list
   */
  detectedWebSiteGridGetListCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsGetGridWebsiteListResponse, any>({
      path: `/monitoring/detected/web-site/grid/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteTableGetListCreate
   * @request POST:/monitoring/detected/web-site/table/get-list
   */
  detectedWebSiteTableGetListCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsGetTableWebsiteListResponse, any>({
      path: `/monitoring/detected/web-site/table/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteDetailsCreate
   * @request POST:/monitoring/detected/web-site/details
   */
  detectedWebSiteDetailsCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsGetWebsiteDetailResponse, any>({
      path: `/monitoring/detected/web-site/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteGetIdListCreate
   * @request POST:/monitoring/detected/web-site/get-id-list
   */
  detectedWebSiteGetIdListCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsGetAllWebsiteIdListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsGetAllWebsiteIdListResponse, any>({
      path: `/monitoring/detected/web-site/get-id-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteEnforceCreate
   * @request POST:/monitoring/detected/web-site/enforce
   */
  detectedWebSiteEnforceCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsRequestEnforceRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsRequestEnforceResponse, any>({
      path: `/monitoring/detected/web-site/enforce`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteEnforceUndoCreate
   * @request POST:/monitoring/detected/web-site/enforce/undo
   */
  detectedWebSiteEnforceUndoCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsUndoEnforcedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsUndoEnforcedResponse, any>({
      path: `/monitoring/detected/web-site/enforce/undo`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteIgnoreCreate
   * @request POST:/monitoring/detected/web-site/ignore
   */
  detectedWebSiteIgnoreCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsRequestIgnoreRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsRequestIgnoreResponse, any>({
      path: `/monitoring/detected/web-site/ignore`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteAuthorizedCreate
   * @request POST:/monitoring/detected/web-site/authorized
   */
  detectedWebSiteAuthorizedCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsRequestAuthorizedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsRequestAuthorizedResponse, any>({
      path: `/monitoring/detected/web-site/authorized`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteExcelCreate
   * @request POST:/monitoring/detected/web-site/excel
   */
  detectedWebSiteExcelCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsGetExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsGetExportListingsResponse, any>({
      path: `/monitoring/detected/web-site/excel`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteExcelResultCreate
   * @request POST:/monitoring/detected/web-site/excel/result
   */
  detectedWebSiteExcelResultCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWDetectionProtocolsExcelResultResponse, any>({
      path: `/monitoring/detected/web-site/excel/result`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteDetected
   * @name DetectedWebSiteExcelListingIdCreate
   * @request POST:/monitoring/detected/web-site/excel/listing-id
   */
  detectedWebSiteExcelListingIdCreate = (
    data: MonitoringWebsiteSWDetectionProtocolsGetExportListingsByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/detected/web-site/excel/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
