import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { MDSButton, MDSTheme, MDSTypography } from '../index';
import { IcoCloseDeleteRemoveFill, IcoDocument, IcoPdf } from '../../assets';
import { IFile } from './@types';

const useStyles = makeStyles(() => ({
  previewItem: {
    position: 'relative',
    width: '100%',
    maxHeight: '100%',
    aspectRatio: '1 / 1',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    '&:hover $previewAction': {
      display: 'flex',
    },
    '& img': {
      width: '100%',
      height: '100%',
      display: 'block',
      objectFit: 'contain',
    },
    '& .otherPreview': {
      flexDirection: 'column',
      gap: '8px',
      width: '100%',
      height: '100%',
      border: `1px solid ${MDSTheme.palette.bluegray[200]}`,
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      aspectRatio: '1 / 1',
    },
  },
  previewAction: {
    padding: '8px',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'none',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '8px',
    backgroundColor: MDSTheme.palette.black_alpha_50,
  },
  boundingBoxWrap: {
    position: 'relative',
    maxHeight: '100%',
    '& .boundingBox': {
      position: 'absolute',
      border: `4px solid ${MDSTheme.palette.yellow[500]}`,
    },
  },
}));

type ImageProps = {
  url: string;
  name?: string;
  onChangeScale?: (scale: number) => void;
};

const Image = ({ url, name, onChangeScale }: ImageProps) => {
  const arrImageExtension = ['png', 'jpg', 'jpeg', 'gif', 'webp'];

  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();

  const imgRef = useRef<HTMLImageElement>(null);

  const extension = url.split('.')[url.split('.').length - 1].split('?')[0].toLowerCase();

  const handleLoadImage = (event: React.SyntheticEvent) => {
    if (!onChangeScale) {
      return;
    }
    const target = event.target as HTMLImageElement;
    setHeight(target.parentElement?.parentElement?.offsetHeight || undefined);
    setWidth(target.parentElement?.parentElement?.offsetWidth || undefined);
    onChangeScale(target.height / target.naturalHeight);
  };

  useEffect(() => {
    const img = imgRef.current;
    const observer = new ResizeObserver((entries) => {
      if (entries && entries[0] && onChangeScale) {
        const target = entries[0].target as HTMLImageElement;
        onChangeScale(target.height / target.naturalHeight);
      }
    });
    if (img) {
      observer.observe(img);
    }
    return () => {
      if (img) {
        observer.unobserve(img);
      }
    };
  }, [onChangeScale]);

  if (arrImageExtension.includes(extension)) {
    return (
      <img
        ref={imgRef}
        src={url}
        alt={url}
        onLoad={handleLoadImage}
        style={{
          height: height ? 'auto' : undefined,
          maxHeight: height,
          width: width ? 'auto' : undefined,
          maxWidth: width,
        }}
      />
    );
  } else {
    const fileName = url.split('/')[url.split('/').length - 1].split('?')[0];
    return (
      <div className="otherPreview">
        {extension === 'pdf' ? <IcoPdf /> : <IcoDocument />}
        <MDSTypography>{name || fileName}</MDSTypography>
      </div>
    );
  }
};

const stopFocusEvent = (e: React.FocusEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

interface IProps<T> {
  file: T;
  index: number;
  disabled: boolean;
  readOnly: boolean;
  onDelete: (index: number) => () => void;
  renderActionButtons?: (index: number) => React.ReactNode;
  onClick?: (file: T, index: number) => void;
}

export const ImagePreviewItem = <T extends IFile>(props: IProps<T>): React.ReactNode => {
  const { file, index, readOnly, renderActionButtons, onDelete, onClick } = props;
  const classes = useStyles();

  const [scale, setScale] = useState(1);

  const handleChangeScale = useCallback((value: number) => {
    setScale(value);
  }, []);

  return (
    <div className={classes.previewItem} key={file.url} onClick={() => onClick?.(file, index)}>
      {file.boundingBox && (
        <div className={classes.boundingBoxWrap}>
          <Image url={file.url} name={file.name} onChangeScale={handleChangeScale} />
          {file.boundingBox?.map((box, index) => (
            <div
              key={index}
              className="boundingBox"
              style={{
                top: box.y1 * scale,
                left: box.x1 * scale,
                width: (box.x2 - box.x1) * scale,
                height: (box.y2 - box.y1) * scale,
              }}
            />
          ))}
        </div>
      )}
      {!file.boundingBox && <Image url={file.url} name={file.name} />}
      {!readOnly && (
        <div className={classes.previewAction} onFocus={stopFocusEvent}>
          {renderActionButtons?.(index)}
          <MDSButton
            variant="text"
            color="white"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(index)();
            }}
          >
            <IcoCloseDeleteRemoveFill />
          </MDSButton>
        </div>
      )}
    </div>
  );
};
