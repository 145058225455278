/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyResponse,
  SettingsBillingProtocolsAddDefaultCardRequest,
  SettingsBillingProtocolsGetBillingHistoryRequest,
  SettingsBillingProtocolsGetBillingHistoryResponse,
  SettingsBillingProtocolsGetPaymentInfoResponse,
  SettingsBillingProtocolsUpdateDefaultCardRequest,
  SettingsBillingProtocolsUpdateDefaultCardResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Billing<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Billing
   * @name BillingGetPaymentInfoList
   * @request GET:/settings/billing/get-payment-info
   */
  billingGetPaymentInfoList = (params: RequestParams = {}) =>
    this.http.request<SettingsBillingProtocolsGetPaymentInfoResponse, any>({
      path: `/settings/billing/get-payment-info`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Billing
   * @name BillingGetBillingHistoryCreate
   * @request POST:/settings/billing/get-billing-history
   */
  billingGetBillingHistoryCreate = (
    data: SettingsBillingProtocolsGetBillingHistoryRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SettingsBillingProtocolsGetBillingHistoryResponse, any>({
      path: `/settings/billing/get-billing-history`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Billing
   * @name BillingDefaultCardAddCreate
   * @request POST:/settings/billing/default-card/add
   */
  billingDefaultCardAddCreate = (data: SettingsBillingProtocolsAddDefaultCardRequest, params: RequestParams = {}) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/settings/billing/default-card/add`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Billing
   * @name BillingDefaultCardUpdateCreate
   * @request POST:/settings/billing/default-card/update
   */
  billingDefaultCardUpdateCreate = (
    data: SettingsBillingProtocolsUpdateDefaultCardRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SettingsBillingProtocolsUpdateDefaultCardResponse, any>({
      path: `/settings/billing/default-card/update`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
