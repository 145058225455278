import React from 'react';
import clsx from 'clsx';
import { CircularProgress, makeStyles, Slider as MUISlider, SliderProps as MUISliderProps, withStyles } from '@material-ui/core';
import theme from './Theme';
import Typography from './Typography';

interface SliderProps {
  /**
   * thumb 에 자동으로 value를 보여줄 지 설정하는 값 Default: true
   */
  isAutoValue?: boolean;
  max?: number;
  min?: number;
  thumbViewValue?: string | number;
  defaultValue?: number | number[];
  isLoading?: boolean;
  onTriggeredMouseUp?: (event: React.ChangeEvent<unknown>, value: number | number[]) => void;
}
const useStyles = makeStyles(() => ({
  autoValue: {
    padding: '0px 14px',
  },
  circleProgress: {
    width: '28px',
    height: '28px',
    '& .MuiCircularProgress-svg': {
      color: theme.palette.white,
    },
  },
}));

const MDSSlider = withStyles({
  root: {
    color: theme.palette.blue[700],
    padding: '13px 0',
    borderRadius: '2px',
  },
  thumb: {
    width: '28px',
    height: '28px',
    background: theme.palette.blue[700],
    border: '0px',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    '&:focus, &:hover, &$active': {
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.2)',
      '@media (hover: none)': {
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.2)',
      },
    },
    '&.Mui-disabled': {
      width: '28px',
      height: '28px',
      background: theme.palette.bluegray[700],
      border: '0px',
      marginTop: -12,
      marginLeft: -13,
    },
    '&.Mui-focusVisible': {
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
  },
  track: {
    height: '4px',
    borderRadius: '100px',
  },
  rail: {
    color: theme.palette.blue[200],
    opacity: 1,
    height: '4px',
    borderRadius: '100px',
  },
  disabled: {
    '& .MuiSlider-rail': {
      color: theme.palette.bluegray[300],
    },
    '& .MuiSlider-track': {
      color: theme.palette.bluegray[700],
    },
  },
  active: {
    boxShadow: 'none',
  },
})(MUISlider);

type Props = SliderProps & Omit<MUISliderProps, 'onChangeCommitted' | 'defaultValue'>;

export const Slider = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { className, isAutoValue, onTriggeredMouseUp, thumbViewValue, isLoading, ...restProps } = props;

  const thumbComponent = (props: any): JSX.Element => {
    if (!(isAutoValue === false)) {
      return (
        <span {...props}>
          {isLoading ? (
            <CircularProgress
              className={classes.circleProgress}
              style={{ width: '14px', height: '14px' }}
              thickness={5}
            />
          ) : (
            <Typography variant="T16" weight="medium" style={{ color: theme.palette.white }}>
              {thumbViewValue ? thumbViewValue : props['aria-valuenow']}
            </Typography>
          )}
        </span>
      );
    }
    return <span {...props} />;
  };

  return (
    <div className={clsx({ [classes.autoValue]: !(isAutoValue === false) })}>
      <MDSSlider
        className={clsx(className)}
        ThumbComponent={thumbComponent}
        onChangeCommitted={onTriggeredMouseUp}
        {...restProps}
      />
    </div>
  );
};

export default Slider;
