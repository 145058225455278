import React, { CSSProperties } from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from './Typography';
import MDSTheme from './Theme';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'grid',
    gridTemplateRows: '1fr auto 2fr',
  },
  emptyViewContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyViewTextContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '20px',
  },
  emptyViewMessage: {
    textAlign: 'center',
  },
}));

interface IProps {
  title?: string;
  message?: string | React.ReactNode;
  heightDeductionAmount?: number;
  element?: React.ReactNode;
  style?: CSSProperties;
}

const MDSEmptyView = (props: IProps) => {
  const classes = useStyles();
  const { title, message, element, heightDeductionAmount = 0, style } = props;

  return (
    <Paper
      elevation={0}
      className={classes.wrapper}
      style={{ height: `calc(100% - ${heightDeductionAmount}px)`, ...style }}
    >
      <div />
      <Grid item container className={classes.emptyViewContentWrapper}>
        <div className={classes.emptyViewTextContent}>
          <Typography variant="T18" weight="medium" color={MDSTheme.palette.bluegray[900]}>
            {title}
          </Typography>
          <Typography className={classes.emptyViewMessage} variant="T14" color={MDSTheme.palette.bluegray[700]}>
            {message}
          </Typography>
        </div>
        {element}
      </Grid>
      <div />
    </Paper>
  );
};

export default MDSEmptyView;
