import { ReactNode, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import { makeStyles } from '@material-ui/core';
import { MDSButton, MDSChip, MDSMarqCommerceBlackLogo, MDSTheme, MDSTypography } from '@marqvision/mds/core';
import pkg from '../../../package.json';
import { SentryFallbackRenderData } from './@types';

// TODO: same origin / cross origin에 따라 헤더 레이아웃이 꼭 필요한지 다시한번 확인 필요
const useIsSameOrigin = () => {
  const [isSameOrigin, setIsSameOrigin] = useState(false);

  useEffect(() => {
    const currentOrigin = window.location.origin;
    const prevOrigin = document.referrer;
    setIsSameOrigin(prevOrigin.includes(currentOrigin));
  }, []);

  return isSameOrigin;
};

export function CommonErrorPage(props: SentryFallbackRenderData) {
  const isSameOrigin = useIsSameOrigin();

  return isSameOrigin ? (
    <CommonErrorBox {...props} />
  ) : (
    <MarqVisionWrapper>
      <CommonErrorBox {...props} />
    </MarqVisionWrapper>
  );
}

const useStyles = makeStyles(() => ({
  containerLayout: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: MDSTheme.palette.white,
  },
  titleLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Visuelt-Medium', // MDS v1 font가 Visuelt-Regular 로 하드코딩되어있음
    marginBottom: '32px',
    '& h1': {
      margin: 0,
      fontSize: '96px',
      fontWeight: 700,
      marginBlock: 0,
      lineHeight: '123px',
    },
    '& h2': {
      margin: 0,
      fontSize: '40px',
      color: MDSTheme.palette.bluegray[400],
      fontWeight: 700,
      marginBlock: 0,
      lineHeight: '51px',
    },
  },
  actionLayout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '16px',
  },
  descriptionLayout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
  },
}));

type Props = {
  onRetry?: () => void;
} & Partial<SentryFallbackRenderData>; // Sentry Error boundary로 들어오지 않는 경우에도 이 컴포넌트를 사용하기 위함 (react-query request 결과 error일 때)
export const CommonErrorBox = ({ onRetry, ...errorData }: Props) => {
  const isAvailableToReportSentry = errorData.error && errorData.eventId;

  const classes = useStyles();
  const handleContactUsClick = () => {
    if (isAvailableToReportSentry) {
      Sentry.showReportDialog(errorData as Required<SentryFallbackRenderData>);
    }
  };
  const handleRetryClick = () => {
    if (onRetry) onRetry();
    else {
      window.location.replace(`/?${pkg.version}`);
    }
  };

  return (
    <div className={classes.containerLayout}>
      <div>
        <div className={classes.titleLayout}>
          <MDSTypography variant="T24" weight="bold">
            Something went wrong
          </MDSTypography>
        </div>
        <div className={classes.actionLayout}>
          <MDSChip variant="fill" color="blue" label="Try again" size="large" onClick={handleRetryClick} />
        </div>
        {isAvailableToReportSentry && (
          <div className={classes.descriptionLayout}>
            <MDSButton variant="text" color="blue" size="medium" onClick={handleContactUsClick}>
              Contact us
            </MDSButton>
            <MDSTypography variant="T14">if this issue recurs.</MDSTypography>
          </div>
        )}
      </div>
    </div>
  );
};

const useWrapperStyles = makeStyles(() => ({
  containerLayout: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    marginTop: '48px',
  },
  footer: {
    marginBottom: '48px',
  },
}));

const MarqVisionWrapper = ({ children }: { children: ReactNode }) => {
  const classes = useWrapperStyles();
  const currentYear = dayjs().year();
  return (
    <div className={classes.containerLayout}>
      <div className={classes.header}>
        <MDSMarqCommerceBlackLogo width={180} height={16} />
      </div>
      {children}
      <div className={classes.footer}>
        <MDSTypography variant="T14">© {currentYear} Marq Vision Inc. All Rights Reserved.</MDSTypography>
      </div>
    </div>
  );
};
