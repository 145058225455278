import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { IcoCloseDeleteRemoveOutline } from '../assets';
import { MDSTheme, MDSTypography } from './index';

const popupTitleUseStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  dialogTitle: {
    padding: '16px 20px',
    borderBottom: `1px solid ${MDSTheme.palette.bluegray[200]}`,
    '& svg': {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
    },
  },
}));

interface IPopupTitle {
  rootProps?: React.HTMLAttributes<HTMLDivElement>;
  startIcon?: React.ReactNode;
  onClose?: (e?: React.MouseEvent) => void;
}

type IPopupTitleProps = IPopupTitle & React.HTMLAttributes<HTMLDivElement>;

export const MDSPopupTitle = (props: React.PropsWithChildren<IPopupTitleProps>): React.ReactNode => {
  const classes = popupTitleUseStyles();
  const { children, startIcon, onClose, rootProps, ...restProps } = props;

  return (
    <DialogTitle className={classes.dialogTitle} {...rootProps}>
      <Box display="flex" alignItems="center" gridGap="8px" {...restProps}>
        {!!startIcon && <Box>{startIcon}</Box>}
        {restProps.className ? (
          children
        ) : (
          <MDSTypography variant="T20" weight="medium" color={MDSTheme.palette.bluegray[900]}>
            {children}
          </MDSTypography>
        )}
        {onClose && <IcoCloseDeleteRemoveOutline style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={onClose} />}
      </Box>
    </DialogTitle>
  );
};

const popupContentUseStyles = makeStyles({
  dialogContent: {
    padding: '16px 20px !important',
  },
});

export const MDSPopupContent = (
  props: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
): React.ReactNode => {
  const classes = popupContentUseStyles();
  const { children, ...restProps } = props;
  return (
    <DialogContent className={classes.dialogContent} {...restProps}>
      {children}
    </DialogContent>
  );
};

const popupActionsUseStyles = makeStyles({
  dialogActions: {
    padding: '16px 20px',
    display: 'flex',
    alignItems: 'center',
  },
});

export const MDSPopupActions = (
  props: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
): React.ReactNode => {
  const classes = popupActionsUseStyles();
  const { children, ...restProps } = props;
  return (
    <DialogActions className={classes.dialogActions} {...restProps}>
      {children}
    </DialogActions>
  );
};

interface IPopup {
  isOpen?: boolean;
  width?: string;
  shouldApplyBackdropFilter?: boolean;
}

type IProps = React.PropsWithChildren<IPopup> & Omit<DialogProps, 'open'>;

export const MDSPopup = (props: IProps): React.ReactNode => {
  const classes = popupTitleUseStyles();
  const { width, shouldApplyBackdropFilter, isOpen = true, children, ...dialogProps } = props;

  return (
    <Dialog
      open={isOpen}
      {...dialogProps}
      BackdropProps={{
        classes: {
          root: shouldApplyBackdropFilter ? classes.backDrop : '',
        },
      }}
    >
      <Box width={width}>{children}</Box>
    </Dialog>
  );
};

export default MDSPopup;
