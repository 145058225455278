import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { IconButton } from '@material-ui/core';
import { foldedLeftNavWidth, leftNavWidth } from '@marqvision/mds/core/LeftNavBar';
import { IcoAccountFill, IcoSettingsFill } from '@marqvision/mds/assets';
import { MDSButton, MDSDropDown, MDSTheme, MDSTooltip } from '@marqvision/mds/core';
import { MDSIcon, MDSTypography } from '@marqvision/mds-v2';
import { CountryCodeEnum, getFlagEmoji } from '@marqvision/shared';
import LoadingCircularProgress from '~/components/LoadingCircularProgress';
import Dropdown from '../../Popover/Dropdown';
import { useHeaderViewModel } from './@hooks';

export const headerHeight = '56px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: MDSTheme.palette.bluegray[850],
      boxShadow: 'none',
      height: headerHeight,
      width: `calc(100% - ${foldedLeftNavWidth}px)`,
      marginLeft: foldedLeftNavWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${leftNavWidth}px)`,
      marginLeft: leftNavWidth,
    },
    toolbar: {
      height: headerHeight,
      minHeight: headerHeight,
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '32px',
    },
    toolbarText: {
      display: 'flex',
      alignItems: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    toolbarButtons: {
      display: 'flex',
      gap: '16px',
    },
    countryBox: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    countryCountLabel: {
      borderBottom: `1px dashed ${theme._raw_color.bluegray400}`,
      cursor: 'pointer',
    },
    actionButtons: {
      marginLeft: '16px',
      alignItems: 'center',
      display: 'flex',
      gap: '8px',
    },
    iconButton: {
      '& svg': {
        width: '16px',
        marginRight: '4px',
        '& path': {
          fill: 'white',
        },
      },
      [theme.breakpoints.down(1440)]: {
        '& svg': {
          width: '20px',
          marginRight: '4px',
          '& path': {
            fill: 'white',
          },
        },
        '& p': {
          display: 'none',
        },
      },
    },
  })
);

interface IProps {
  openNav: boolean;
  title: ReactNode;
  brandUnselectable?: boolean;
  isBrandChipDisabled?: boolean;
  actionButton?: ReactNode;
  menuButton?: ReactNode;
}

const Header = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { openNav, title, isBrandChipDisabled, actionButton, menuButton } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const { brand, country, user, isLoading } = useHeaderViewModel({ brandUnselectable: props.brandUnselectable });

  const handleUserPopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserPopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLink = (link: string) => {
    navigate({ pathname: link });
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: openNav,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <LoadingCircularProgress open={isLoading} />
        <div className={classes.toolbarText}>
          <MDSTypography variant="T20" weight="medium" color="color/content/inverse/default/normal">
            {title}
          </MDSTypography>
          <div className={classes.actionButtons}>{actionButton}</div>
        </div>
        <div className={classes.toolbarButtons}>
          {country.isAvailableAll
            ? null // 선택할 수 있는 모든 국가가 선택되어있는 경우에는 따로 표시하지 않는다.
            : country.options &&
              (country.options.length === 1 ? (
                <div className={classes.countryBox}>
                  <div>{getFlagEmoji(country.options[0].value as CountryCodeEnum)}</div>
                  <MDSTypography variant="T14" weight="medium" color="color/content/inverse/default/normal">
                    {country.options[0].label}
                  </MDSTypography>
                </div>
              ) : country.options.length > 1 ? (
                <div className={classes.countryBox}>
                  <MDSTypography variant="T14" weight="medium" color="color/content/inverse/default/normal" as="div">
                    Access to
                  </MDSTypography>
                  <MDSTooltip title={country.options.map((c) => c.label).join(', ')}>
                    <MDSTypography
                      variant="T14"
                      weight="medium"
                      color="color/content/inverse/default/normal"
                      as="div"
                      className={classes.countryCountLabel}
                    >
                      {country.options.length} countries
                    </MDSTypography>
                  </MDSTooltip>
                </div>
              ) : null)}
          {brand.options &&
            (brand.options.length === 1 ? (
              ''
            ) : isBrandChipDisabled ? (
              <MDSButton
                color="white"
                size="medium"
                variant="text"
                endIcon={<MDSIcon.ArrowDown variant="outline" />}
                disabled
              >
                {(brand.selectedId && brand.list.find(({ id }) => id === brand.selectedId)?.name) || 'All brands'}
              </MDSButton>
            ) : brand.unselectable ? (
              <MDSTooltip title="This page can't be viewed by brand">
                <MDSButton
                  color="white"
                  size="medium"
                  variant="text"
                  endIcon={<MDSIcon.ArrowDown variant="outline" />}
                  disabled
                >
                  All brands
                </MDSButton>
              </MDSTooltip>
            ) : (
              <MDSDropDown
                renderAnchorComponent={(items, open) => (
                  <MDSButton
                    size="medium"
                    variant="text"
                    color="white"
                    endIcon={<MDSIcon.ArrowDown variant="outline" />}
                    onClick={open}
                  >
                    {brand.options.find((row) => row.value === brand.selectedId)?.label}
                  </MDSButton>
                )}
                value={brand.selectedId}
                list={brand.options}
                onChange={(value) => brand.onChange(value as number)}
              />
            ))}
          {menuButton}
          <IconButton className={classes.iconButton} onClick={() => handleLink('/settings')}>
            <IcoSettingsFill />
            <MDSTypography variant="T14" weight="medium" color="color/content/inverse/default/normal">
              Settings
            </MDSTypography>
          </IconButton>
          <IconButton className={classes.iconButton} onClick={handleUserPopoverOpen}>
            <IcoAccountFill />
            <MDSTypography variant="T14" weight="medium" color="color/content/inverse/default/normal">
              {user.firstName}
            </MDSTypography>
          </IconButton>
        </div>

        <Dropdown
          anchorEl={anchorEl}
          onClose={handleUserPopoverClose}
          items={[{ name: 'Sign out', onClick: user.onLogout }]}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
