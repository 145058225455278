/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolFileResponse,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsExcelResultRequest,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsExcelResultResponse,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetAllWebsiteIdListRequest,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetAllWebsiteIdListResponse,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetCountRequest,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetCountResponse,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetExportListingsByListingIdRequest,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetExportListingsRequest,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetExportListingsResponse,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetGridWebsiteListRequest,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetGridWebsiteListResponse,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetTableWebsiteListRequest,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetTableWebsiteListResponse,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetWebsiteDetailRequest,
  MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetWebsiteDetailResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringWebsiteEnforcedUnderReview<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUnderReview
   * @name EnforcementWebSiteUnderReviewCountCreate
   * @request POST:/monitoring/enforcement/web-site/under-review/count
   */
  enforcementWebSiteUnderReviewCountCreate = (
    data: MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetCountResponse, any>({
      path: `/monitoring/enforcement/web-site/under-review/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUnderReview
   * @name EnforcementWebSiteUnderReviewGridGetListCreate
   * @request POST:/monitoring/enforcement/web-site/under-review/grid/get-list
   */
  enforcementWebSiteUnderReviewGridGetListCreate = (
    data: MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetGridWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetGridWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/under-review/grid/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUnderReview
   * @name EnforcementWebSiteUnderReviewTableGetListCreate
   * @request POST:/monitoring/enforcement/web-site/under-review/table/get-list
   */
  enforcementWebSiteUnderReviewTableGetListCreate = (
    data: MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetTableWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetTableWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/under-review/table/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUnderReview
   * @name EnforcementWebSiteUnderReviewDetailsCreate
   * @request POST:/monitoring/enforcement/web-site/under-review/details
   */
  enforcementWebSiteUnderReviewDetailsCreate = (
    data: MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetWebsiteDetailRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetWebsiteDetailResponse, any>({
      path: `/monitoring/enforcement/web-site/under-review/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUnderReview
   * @name EnforcementWebSiteUnderReviewGetIdListCreate
   * @request POST:/monitoring/enforcement/web-site/under-review/get-id-list
   */
  enforcementWebSiteUnderReviewGetIdListCreate = (
    data: MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetAllWebsiteIdListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetAllWebsiteIdListResponse, any>({
      path: `/monitoring/enforcement/web-site/under-review/get-id-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUnderReview
   * @name EnforcementWebSiteUnderReviewExcelCreate
   * @request POST:/monitoring/enforcement/web-site/under-review/excel
   */
  enforcementWebSiteUnderReviewExcelCreate = (
    data: MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetExportListingsResponse, any>({
      path: `/monitoring/enforcement/web-site/under-review/excel`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUnderReview
   * @name EnforcementWebSiteUnderReviewExcelResultCreate
   * @request POST:/monitoring/enforcement/web-site/under-review/excel/result
   */
  enforcementWebSiteUnderReviewExcelResultCreate = (
    data: MonitoringWebsiteSWEnforcedUnderReviewProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedUnderReviewProtocolsExcelResultResponse, any>({
      path: `/monitoring/enforcement/web-site/under-review/excel/result`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedUnderReview
   * @name EnforcementWebSiteUnderReviewExcelListingIdCreate
   * @request POST:/monitoring/enforcement/web-site/under-review/excel/listing-id
   */
  enforcementWebSiteUnderReviewExcelListingIdCreate = (
    data: MonitoringWebsiteSWEnforcedUnderReviewProtocolsGetExportListingsByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/web-site/under-review/excel/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
