/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MonitoringFilterProtocolResponse,
  SellerSellerAnalyticSellerProtocolsExportExcelRequest,
  SellerSellerAnalyticSellerProtocolsExportExcelResponse,
  SellerSellerAnalyticSellerProtocolsGetChannelDomainFilterRequest,
  SellerSellerAnalyticSellerProtocolsGetSellerCountRequest,
  SellerSellerAnalyticSellerProtocolsGetSellerCountResponse,
  SellerSellerAnalyticSellerProtocolsGetSellerListRequest,
  SellerSellerAnalyticSellerProtocolsGetSellerListResponse,
  SellerSellerAnalyticSellerProtocolsProgressExcelRequest,
  SellerSellerAnalyticSellerProtocolsProgressExcelResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SellerAnalyticsSeller<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SellerAnalyticsSeller
   * @name SellerFilterChannelDomainsCreate
   * @request POST:/seller-analytic/seller/filter/channel-domains
   */
  sellerFilterChannelDomainsCreate = (
    data: SellerSellerAnalyticSellerProtocolsGetChannelDomainFilterRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringFilterProtocolResponse, any>({
      path: `/seller-analytic/seller/filter/channel-domains`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsSeller
   * @name SellerGetListCreate
   * @request POST:/seller-analytic/seller/get_list
   */
  sellerGetListCreate = (data: SellerSellerAnalyticSellerProtocolsGetSellerListRequest, params: RequestParams = {}) =>
    this.http.request<SellerSellerAnalyticSellerProtocolsGetSellerListResponse, any>({
      path: `/seller-analytic/seller/get_list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsSeller
   * @name SellerCountCreate
   * @request POST:/seller-analytic/seller/count
   */
  sellerCountCreate = (data: SellerSellerAnalyticSellerProtocolsGetSellerCountRequest, params: RequestParams = {}) =>
    this.http.request<SellerSellerAnalyticSellerProtocolsGetSellerCountResponse, any>({
      path: `/seller-analytic/seller/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsSeller
   * @name SellerExportCreate
   * @request POST:/seller-analytic/seller/export
   */
  sellerExportCreate = (data: SellerSellerAnalyticSellerProtocolsExportExcelRequest, params: RequestParams = {}) =>
    this.http.request<SellerSellerAnalyticSellerProtocolsExportExcelResponse, any>({
      path: `/seller-analytic/seller/export`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SellerAnalyticsSeller
   * @name SellerExportProgressCreate
   * @request POST:/seller-analytic/seller/export/progress
   */
  sellerExportProgressCreate = (
    data: SellerSellerAnalyticSellerProtocolsProgressExcelRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<SellerSellerAnalyticSellerProtocolsProgressExcelResponse, any>({
      path: `/seller-analytic/seller/export/progress`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
