/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ListingsListingsProtocolsAddAuthorizedRequest,
  ListingsListingsProtocolsDoEnforcementBySellerRequest,
  ListingsListingsProtocolsDoEnforcementBySellerResponse,
  ListingsListingsProtocolsDoEnforcementRequest,
  ListingsListingsProtocolsDoEnforcementResponse,
  ListingsListingsProtocolsGetCounterfeitReasonsRequest,
  ListingsListingsProtocolsGetCounterfeitReasonsResponse,
  ListingsListingsProtocolsGetDetailsRequest,
  ListingsListingsProtocolsGetDetailsResponse,
  ListingsListingsProtocolsGetNoteUploadUrlRequest,
  ListingsListingsProtocolsGetNoteUploadUrlResponse,
  ListingsListingsProtocolsRequestTestPurchaseRequest,
  ListingsListingsProtocolsUndoEnforcementRequest,
  ListingsListingsProtocolsUndoEnforcementResponse,
  ListingsListingsProtocolsUpdateCounterfeitReasonThumbRequest,
  ListingsListingsProtocolsUpdateToArchivedRequest,
  ListingsListingsProtocolsUpdateToArchivedResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Listings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Listings
   * @name DetailsCreate
   * @request POST:/listings/details
   */
  detailsCreate = (data: ListingsListingsProtocolsGetDetailsRequest, params: RequestParams = {}) =>
    this.http.request<ListingsListingsProtocolsGetDetailsResponse, any>({
      path: `/listings/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Listings
   * @name EnforcementCreate
   * @request POST:/listings/enforcement
   */
  enforcementCreate = (data: ListingsListingsProtocolsDoEnforcementRequest, params: RequestParams = {}) =>
    this.http.request<ListingsListingsProtocolsDoEnforcementResponse, any>({
      path: `/listings/enforcement`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Listings
   * @name EnforcementBySellerCreate
   * @request POST:/listings/enforcement-by-seller
   */
  enforcementBySellerCreate = (
    data: ListingsListingsProtocolsDoEnforcementBySellerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ListingsListingsProtocolsDoEnforcementBySellerResponse, any>({
      path: `/listings/enforcement-by-seller`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Listings
   * @name UndoEnforcementCreate
   * @request POST:/listings/undo-enforcement
   */
  undoEnforcementCreate = (data: ListingsListingsProtocolsUndoEnforcementRequest, params: RequestParams = {}) =>
    this.http.request<ListingsListingsProtocolsUndoEnforcementResponse, any>({
      path: `/listings/undo-enforcement`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Listings
   * @name EnforcementNoteUploadUrlCreate
   * @request POST:/listings/enforcement/note-upload-url
   */
  enforcementNoteUploadUrlCreate = (
    data: ListingsListingsProtocolsGetNoteUploadUrlRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ListingsListingsProtocolsGetNoteUploadUrlResponse, any>({
      path: `/listings/enforcement/note-upload-url`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Listings
   * @name ArchiveCreate
   * @request POST:/listings/archive
   */
  archiveCreate = (data: ListingsListingsProtocolsUpdateToArchivedRequest, params: RequestParams = {}) =>
    this.http.request<ListingsListingsProtocolsUpdateToArchivedResponse, any>({
      path: `/listings/archive`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Listings
   * @name PostListings
   * @request POST:/listings/tp
   */
  postListings = (data: ListingsListingsProtocolsRequestTestPurchaseRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/listings/tp`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Listings
   * @name AuthorizedCreate
   * @request POST:/listings/authorized
   */
  authorizedCreate = (data: ListingsListingsProtocolsAddAuthorizedRequest, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/listings/authorized`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Listings
   * @name CfReasonsCreate
   * @request POST:/listings/cf-reasons
   */
  cfReasonsCreate = (data: ListingsListingsProtocolsGetCounterfeitReasonsRequest, params: RequestParams = {}) =>
    this.http.request<ListingsListingsProtocolsGetCounterfeitReasonsResponse, any>({
      path: `/listings/cf-reasons`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Listings
   * @name CfReasonsThumbCreate
   * @request POST:/listings/cf-reasons/thumb
   */
  cfReasonsThumbCreate = (
    data: ListingsListingsProtocolsUpdateCounterfeitReasonThumbRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<void, any>({
      path: `/listings/cf-reasons/thumb`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
