import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth } from '~/apis/auth';

/**
 * 주의: react-query, jotai intialize된 이후에 사용가능!!
 */
export const useSentryUserInfoUpdate = () => {
  const { data } = useAuth();
  useEffect(() => {
    try {
      const scope = Sentry.getCurrentScope();
      scope.setUser({
        id: data?.user_id,
        email: data?.user_email,
      });
      scope.setTag('client_id', data?.client_id);
      scope.setTag('client_name', data?.client_name);
    } catch {
      // do nothing
    }
  }, [data]);
};
