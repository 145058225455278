import React from 'react';
import clsx from 'clsx';
import { ButtonGroup, ButtonGroupProps, makeStyles } from '@material-ui/core';
import theme from './Theme';

const useStyles = makeStyles(() => ({
  root: {
    height: 'fit-content',
    ...getChipStyles(),
    '&.small': {
      borderRadius: '13px',
    },
    '&.medium': {
      borderRadius: '16px',
    },
    '&.large': {
      borderRadius: '19px',
    },
    '&.extra_large': {
      borderRadius: '24px',
    },
  },
  rootDivided: {
    '& > .MuiChip-root:not(:last-child)': {
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      paddingRight: '8px',
      '&.outline': {
        borderRight: '0px',
        marginRight: '-2px',
      },
    },
    '& > .MuiChip-root:last-child': {
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      paddingLeft: '8px',
      '&.outline': {
        borderLeft: '0px !important',
        marginLeft: '-2px',
      },
    },
    // For Chips rendered in Dropdown
    '& > div:not(:last-child)': {
      '& > .MuiChip-root': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        paddingRight: '8px',
        '&.outline': {
          borderRight: '0px',
          marginRight: '-2px',
        },
      },
    },
    '& div:last-child': {
      '& > .MuiChip-root': {
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
        paddingLeft: '8px',
        '&.outline': {
          borderLeft: '0px !important',
          marginLeft: '-2px',
        },
      },
    },
  },
  divider: {
    minWidth: '1px',
    width: '1px',
    height: '20px',
    background: 'rgba(0, 0, 0, 0.1)',
    alignSelf: 'center',
    zIndex: 1,
    '&.fill, &.tint.white': {
      background: 'rgba(255, 255, 255, 0.4)',
    },
  },
}));

const getColorFillBackground = (key: keyof typeof theme.palette): string => {
  return theme.palette[key][['bluegray', 'blue'].includes(key) ? '700' : '600'];
};

const getTintBackgroundColor = (key: keyof typeof theme.palette): string => {
  if (key === 'white') {
    return theme.palette.bluegray[800];
  }
  return key === 'bluegray' ? theme.palette[key]['150'] : theme.palette[key]['50'];
};

const getChipStyles = (): any => {
  const result = {};

  ([...Object.keys(theme.palette)] as IOwnProps['color'][]).forEach((key: IOwnProps['color']) => {
    Object.assign(result, {
      [`&.${key}.fill`]: {
        backgroundColor: getColorFillBackground(key),
      },
      [`&.${key}.tint`]: {
        backgroundColor: getTintBackgroundColor(key),
      },
      [`&.${key}.outline`]: {
        backgroundColor: theme.palette.white,
      },
    });
  });

  return result;
};

interface IOwnProps {
  size?: 'small' | 'extra_small' | 'medium' | 'large' | 'extra_large';
  variant: 'fill' | 'tint' | 'outline';
  color: keyof typeof theme.palette;
  divider?: boolean;
}

type IProps = React.PropsWithChildren<IOwnProps & Omit<ButtonGroupProps, 'variant' | 'color'>>;

export const ChipGroup = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { size = 'medium', variant, color, divider, children, ...restProps } = props;

  const childrenArray = React.Children.toArray(children);
  const childrenElementList = childrenArray.reduce<React.ReactNode[]>(
    (prev, cur, index) => [
      ...prev,
      ...((divider &&
        index !== 0 && [<div key={`divider-${index}`} className={clsx(classes.divider, variant, color)} />]) ||
        []),
      cur,
    ],
    []
  );

  return (
    <ButtonGroup
      variant="outlined"
      className={clsx(classes.root, variant, color, size, { [classes.rootDivided]: divider })}
      {...restProps}
    >
      {childrenElementList.map((item) => item)}
    </ButtonGroup>
  );
};

export default ChipGroup;
