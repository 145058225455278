import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  tbody: {
    marginTop: '-1px',
  },
});

const MDSTableBody = (props: HTMLAttributes<HTMLTableSectionElement>): React.ReactNode => {
  const { className, children, ...restProps } = props;
  const classes = useStyles();

  return (
    <tbody className={clsx(classes.tbody, className)} {...restProps}>
      {children}
    </tbody>
  );
};

export default MDSTableBody;
