// pages
const RiotAuthOkta = lazy(() => import('../components/User/RiotAuthOkta'));
const RiotAuthOktaCallback = lazy(() => import('../components/User/RiotAuthOktaCallback'));
const OktaAuth = lazy(() => import('../components/User/OktaAuth'));
const OktaAuthCallback = lazy(() => import('../components/User/OktaAuthCallback'));
const LoginPage = lazy(() => import('../components/User/LoginPage'));
const ForgotPasswordPage = lazy(() => import('../components/User/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('../components/User/ResetPasswordPage'));
const VerifyEmailPage = lazy(() => import('../components/User/VerifyEmailPage'));
const SignUpPage = lazy(() => import('../components/User/SignUpPage'));
const LoginWithToken = lazy(() => import('../components/User/LoginWithToken'));

const PDFViewer = lazy(() => import('~/components/PDFViewer'));
const HomePage = lazy(() => import('~/pages/Home'));
const SettingsPage = lazy(() => import('~/pages/Settings'));
const LibraryPage = lazy(() => import('~/pages/Portfolio/InfringingImages'));
const DetectionPage = lazy(() => import('~/pages/Detection'));
const EnforcementPage = lazy(() => import('~/pages/Enforcement'));
const ArchivedPage = lazy(() => import('~/pages/Archived'));
const AssetsPage = lazy(() => import('~/pages/Portfolio/Assets'));
const IpPortfolioPage = lazy(() => import('~/pages/Portfolio/IPDocuments'));
const OfficialImagesPages = lazy(() => import('~/pages/Portfolio/OfficialImages'));
const AuthorizedSeller = lazy(() => import('~/pages/Portfolio/AuthorizedSeller'));
const PerformanceOverviewPage = lazy(() => import('~/pages/Performance/Overview'));
const PerformanceEnforcementAnalysisPage = lazy(() => import('~/pages/Performance/EnforcementAnalysis'));
const PerformanceSellerAnalysisPage = lazy(() => import('~/pages/Performance/SellerAnalysis'));
const PerformanceMarketplaceComplianceRatePage = lazy(() => import('~/pages/Performance/MarketplaceComplianceRate'));
const PerformanceReportsPage = lazy(() => import('~/pages/Performance/Reports'));
const SellerIntelligenceLabPage = lazy(() => import('~/pages/SellerIntelligence/Lab'));
const SellerPage = lazy(() => import('~/pages/SellerAnalytics'));
const MAPMonitoring = lazy(() => import('~/pages/MAPMonitoring'));

const NotFoundPage = lazy(() => import('~/pages/ErrorPage/NotFound'));

import { Suspense, lazy } from 'react';
import { RouteObject, createBrowserRouter, redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import LoadingCircularProgress from '~/components/LoadingCircularProgress';
import App from '~/App';
import LayoutWrapper from '~/components/Layouts/LayoutWrapper';
import AxiosInterceptorWrapper from '~/components/Dashboard/AxiosInterceptorWrapper';
import { ErrorBoundaryView } from '~/pages/ErrorPage/ErrorBoundary';
import { fetchAuth, getUserInfoQuery } from '~/apis/auth';
import { PermissionMap } from './permissionMap';

const MarqCommerceRoutes: RouteObject[] = [
  {
    path: '',
    loader: async () => {
      const authData = await fetchAuth();
      if (authData) {
        return redirect('/home');
      } else {
        return redirect('/login');
      }
    },
    errorElement: <Sentry.ErrorBoundary fallback={(errorData) => <ErrorBoundaryView errorData={errorData} />} />,
  },
  {
    path: '/',
    element: <App />,
    errorElement: <Sentry.ErrorBoundary fallback={(errorData) => <ErrorBoundaryView errorData={errorData} />} />,
    children: [
      //#region >>>>> Sign up / oAuth Login
      {
        path: 'auth',
        element: <LayoutWrapper />,
        children: [
          {
            path: 'okta',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <RiotAuthOkta />
              </Suspense>
            ),
          },
          {
            path: 'okta-callback',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <RiotAuthOktaCallback />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'okta',
        element: <LayoutWrapper />,
        children: [
          {
            path: 'auth',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <OktaAuth />
              </Suspense>
            ),
          },
          {
            path: 'callback',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <OktaAuthCallback />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'dashboard/:access_token/:refresh_token/:opscc_user',
        element: (
          <Suspense fallback={<LoadingCircularProgress open />}>
            <LoginWithToken />
          </Suspense>
        ),
      },
      {
        path: 'resetpw',
        children: [
          {
            path: ':token',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <ResetPasswordPage />
              </Suspense>
            ),
          },
          {
            path: '',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <ForgotPasswordPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'verifyEmail/:token',
        element: (
          <Suspense fallback={<LoadingCircularProgress open />}>
            <VerifyEmailPage />
          </Suspense>
        ),
      },
      {
        path: 'signup/:token',
        element: (
          <Suspense fallback={<LoadingCircularProgress open />}>
            <SignUpPage />
          </Suspense>
        ),
      },
      //#endregion <<<<<< Sign up / oAuth Login

      {
        path: 'login',
        element: (
          <Suspense fallback={<LoadingCircularProgress open />}>
            <LoginPage />
          </Suspense>
        ),
      },
      {
        path: '',
        element: <LayoutWrapper />,
        loader: async () => {
          const authData = await fetchAuth();
          if (!authData) {
            return redirect('/login');
          }
          return null;
        },
        children: [
          {
            path: 'home',
            index: true,
            loader: async () => {
              const hasAccess = await PermissionMap.Home();
              if (hasAccess) {
                return null; // bypass
              } else {
                return redirect('/ip-portfolio');
              }
            },
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <HomePage />
              </Suspense>
            ),
          },
          {
            path: 'settings/:settingsTab?',
            loader: async ({ params }) => {
              const availableTabs = ['account', 'billing', 'people', 'credits']; // SettingsTabEnum과 일원화 되면 좋겠다 :)
              if (!availableTabs.includes(params.settingsTab || '')) {
                return redirect('/settings/account');
              } else {
                const hasAccess = await PermissionMap.SettingTab();
                if (
                  (params.settingsTab === 'billing' && !hasAccess.billing) ||
                  (params.settingsTab === 'people' && !hasAccess.people)
                ) {
                  return redirect('/settings/account');
                } else {
                  return new Response(JSON.stringify(hasAccess), {
                    status: 200,
                  });
                }
              }
            },
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <SettingsPage />
              </Suspense>
            ),
          },

          {
            path: 'performance',
            loader: async () => {
              const hasAccess = await PermissionMap.Performance();
              if (hasAccess) {
                return null; // bypass
              } else {
                return redirect('/ip-portfolio');
              }
            },
            children: [
              {
                path: '',
                index: true,
                element: (
                  <Suspense fallback={<LoadingCircularProgress open />}>
                    <PerformanceOverviewPage />
                  </Suspense>
                ),
              },
              {
                path: 'enforcement',
                loader: async ({ params }) => {
                  if (!params.tab) {
                    return redirect('detected');
                  } else {
                    return null;
                  }
                },
                children: [
                  {
                    path: ':tab',
                    element: (
                      <Suspense fallback={<LoadingCircularProgress open />}>
                        <PerformanceEnforcementAnalysisPage />
                      </Suspense>
                    ),
                  },
                ],
                element: (
                  <Suspense fallback={<LoadingCircularProgress open />}>
                    <PerformanceEnforcementAnalysisPage />
                  </Suspense>
                ),
              },
              {
                path: 'seller',
                element: (
                  <Suspense fallback={<LoadingCircularProgress open />}>
                    <PerformanceSellerAnalysisPage />
                  </Suspense>
                ),
              },
              {
                path: 'compliance',
                element: (
                  <Suspense fallback={<LoadingCircularProgress open />}>
                    <PerformanceMarketplaceComplianceRatePage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'performance-reports',
            loader: async () => {
              const hasAccess = await PermissionMap.PerformanceReport();
              if (hasAccess) {
                return null; // bypass
              } else {
                return redirect('/ip-portfolio');
              }
            },
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <PerformanceReportsPage />
              </Suspense>
            ),
          },

          //#region >>>>> Portfolio
          {
            path: 'ip-portfolio/:id?',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <IpPortfolioPage />
              </Suspense>
            ),
          },
          {
            path: 'library',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <LibraryPage />
              </Suspense>
            ),
          },
          {
            path: 'assets',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <AssetsPage />
              </Suspense>
            ),
          },
          {
            path: 'official-image/:brand_id?/:collection_id?',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <OfficialImagesPages />
              </Suspense>
            ),
          },
          {
            path: 'authorized-sellers',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <AuthorizedSeller />
              </Suspense>
            ),
          },
          //#endregion

          {
            path: 'pdf-viewer/:file',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <PDFViewer />
              </Suspense>
            ),
          },

          {
            path: 'detection',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <DetectionPage />
              </Suspense>
            ),
          },

          {
            path: 'enforcement',

            loader: async ({ params }) => {
              if (!params.tab) {
                const userData = await getUserInfoQuery();

                if (userData?.use_ecommerce && userData?.use_social_media) {
                  return redirect('ecommerce_and_social_media/under_review/processing');
                } else if (!userData?.use_social_media) {
                  return redirect('ecommerce/under_review/processing');
                } else if (!userData?.use_ecommerce) {
                  return redirect('social_media/under_review/processing');
                } else if (userData?.use_stand_alone) {
                  return redirect('website/under_review/processing');
                } else {
                  // 모든 탭이 locked 인 상태는 없겠지만, 있다면 ecommerce 탭으로 이동
                  return redirect('ecommerce/under_review/processing');
                }
              }

              return null;
            },
            children: [
              {
                path: ':tab/:stage/:status',
                element: (
                  <Suspense fallback={<LoadingCircularProgress open />}>
                    <EnforcementPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'archived',
            loader: async (ctx) => {
              const hasAccess = PermissionMap.Archived();
              if (!hasAccess) {
                return redirect('/ip-portfolio');
              }
              if (!ctx.params.tab) {
                return redirect('dismissed');
              }
              return null;
            },
            children: [
              {
                path: ':tab',
                element: (
                  <Suspense fallback={<LoadingCircularProgress open />}>
                    <ArchivedPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: '',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <AxiosInterceptorWrapper />
              </Suspense>
            ),
            children: [
              {
                path: 'seller',
                loader: async () => {
                  const hasAccess = await PermissionMap.Seller();
                  if (hasAccess) {
                    return null; // bypass
                  } else {
                    return redirect('/ip-portfolio');
                  }
                },
                children: [
                  {
                    path: 'lab/:clusterId?',
                    element: (
                      <Suspense fallback={<LoadingCircularProgress open />}>
                        <SellerIntelligenceLabPage />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':detailPageType?/:id?/:subPageType?',
                    element: (
                      <Suspense fallback={<LoadingCircularProgress open />}>
                        <SellerPage />
                      </Suspense>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'price-monitoring',
            loader: async () => {
              const hasAccess = await PermissionMap.PriceMonitoring();
              if (hasAccess) {
                return null; // bypass
              } else {
                return redirect('/home');
              }
            },
            children: [
              {
                path: ':model_id?/:country?',
                element: (
                  <Suspense fallback={<LoadingCircularProgress open />}>
                    <MAPMonitoring />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: '*',
            element: (
              <Suspense fallback={<LoadingCircularProgress open />}>
                <NotFoundPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];

export const marqCommerceClientRouter = createBrowserRouter(MarqCommerceRoutes);
