import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox as MUICheckbox } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import theme from './Theme';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  checkbox: {
    '&.primary > .MuiIconButton-label > input + .MuiSvgIcon-root': {
      color: theme.palette.bluegray['300'],
    },
    '&.primary.Mui-checked > .MuiIconButton-label > .MuiSvgIcon-root': {
      color: theme.palette.blue['700'],
    },
    '&.primary.Mui-checked.Mui-disabled > .MuiIconButton-label > .MuiSvgIcon-root': {
      color: theme.palette.blue['200'],
    },
    '&.primary.Mui-disabled > .MuiIconButton-label > .MuiSvgIcon-root': {
      color: theme.palette.bluegray['150'],
    },
    '&.primary.MuiCheckbox-indeterminate > .MuiIconButton-label > .MuiSvgIcon-root ': {
      color: theme.palette.blue['700'],
    },
    '&.basic > .MuiIconButton-label > input + .MuiSvgIcon-root': {
      color: theme.palette.white,
    },
    '&.basic.Mui-checked > .MuiIconButton-label > .MuiSvgIcon-root': {
      color: theme.palette.white,
    },
    '&.basic.checked.Mui-disabled > .MuiIconButton-label > .MuiSvgIcon-root': {
      color: theme.palette.white,
    },
    '&.basic.Mui-disabled > .MuiIconButton-label > .MuiSvgIcon-root': {
      color: theme.palette.white,
      opacity: '0.5',
    },
  },
  disabledArea: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '14px',
    height: '14px',
    '&.primary': {
      backgroundColor: theme.palette.bluegray['50'],
    },
    '&.basic': {
      opacity: '0.3',
      backgroundColor: theme.palette.white,
    },
  },
}));

interface ICheckboxProps {
  isPrimary?: boolean;
  className?: string;
}

type IProps = CheckboxProps & ICheckboxProps;

export const MDSCheckbox = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { isPrimary, ...restProps } = props;

  return (
    <div className={classes.root}>
      <MUICheckbox
        {...restProps}
        className={clsx(classes.checkbox, { primary: isPrimary, basic: !isPrimary }, props.className)}
      />
      {props.disabled && !props.checked && !props.indeterminate && (
        <div className={clsx(classes.disabledArea, { primary: isPrimary, basic: !isPrimary })} />
      )}
    </div>
  );
};

export default MDSCheckbox;
