import React from 'react';

const ErrorWarningFillIcon = ({ color = '#E12705', scale = 1 }: { color?: string; scale?: number }): JSX.Element => (
  <svg width={20 * scale} height={20 * scale} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0003 11.3334C10.2364 11.3334 10.4345 11.2534 10.5945 11.0934C10.7539 10.934 10.8337 10.7362 10.8337 10.5001V7.14592C10.8337 6.9098 10.7539 6.71536 10.5945 6.56258C10.4345 6.4098 10.2364 6.33342 10.0003 6.33342C9.76421 6.33342 9.56644 6.41314 9.40699 6.57258C9.24699 6.73258 9.16699 6.93064 9.16699 7.16675V10.5209C9.16699 10.757 9.24699 10.9515 9.40699 11.1042C9.56644 11.257 9.76421 11.3334 10.0003 11.3334ZM10.0003 14.6667C10.2364 14.6667 10.4345 14.5867 10.5945 14.4267C10.7539 14.2673 10.8337 14.0695 10.8337 13.8334C10.8337 13.5973 10.7539 13.3992 10.5945 13.2392C10.4345 13.0798 10.2364 13.0001 10.0003 13.0001C9.76421 13.0001 9.56644 13.0798 9.40699 13.2392C9.24699 13.3992 9.16699 13.5973 9.16699 13.8334C9.16699 14.0695 9.24699 14.2673 9.40699 14.4267C9.56644 14.5867 9.76421 14.6667 10.0003 14.6667ZM10.0003 18.8334C8.84755 18.8334 7.76421 18.6145 6.75033 18.1767C5.73644 17.7395 4.85449 17.1459 4.10449 16.3959C3.35449 15.6459 2.76088 14.764 2.32366 13.7501C1.88588 12.7362 1.66699 11.6529 1.66699 10.5001C1.66699 9.3473 1.88588 8.26397 2.32366 7.25008C2.76088 6.23619 3.35449 5.35425 4.10449 4.60425C4.85449 3.85425 5.73644 3.26036 6.75033 2.82258C7.76421 2.38536 8.84755 2.16675 10.0003 2.16675C11.1531 2.16675 12.2364 2.38536 13.2503 2.82258C14.2642 3.26036 15.1462 3.85425 15.8962 4.60425C16.6462 5.35425 17.2398 6.23619 17.677 7.25008C18.1148 8.26397 18.3337 9.3473 18.3337 10.5001C18.3337 11.6529 18.1148 12.7362 17.677 13.7501C17.2398 14.764 16.6462 15.6459 15.8962 16.3959C15.1462 17.1459 14.2642 17.7395 13.2503 18.1767C12.2364 18.6145 11.1531 18.8334 10.0003 18.8334Z"
      fill={color}
    />
  </svg>
);

export default ErrorWarningFillIcon;
