import { stringSort } from '../utils';
import { IDropDownItem, ISelectValue, SortEnum } from './@types';

// origin: packages/shared/src/utils/index.ts:getRegExpByKeyword
export const getRegExpByKeyword = (keyword: string) => {
  return new RegExp(keyword.replace(/[.*+?^${}()\[\]\\]/g, '\\$&'), 'ig');
};

// #region Dropdown container utils
export const getResolvedList = (items: IDropDownItem[]) => {
  const loop = (currentItems: IDropDownItem[]): IDropDownItem[] => {
    return currentItems.map((item) => {
      if (item.children) {
        const updatedChildren = loop(item.children);

        if (item.isChildrenMultiSelectable) {
          return {
            ...item,
            _isLocalMultiSelectRoot: item.isChildrenMultiSelectable,
            children: updatedChildren.map((child) => ({
              ...child,
              _localMultiSelectRootKey: item.value as string,
              isChildrenMultiSelectable: true,
            })),
          };
        } else {
          return {
            ...item,
            children: updatedChildren,
          };
        }
      }
      return item;
    });
  };
  const result = loop(items);
  return result;
};

/**
 *
 * @param values[]: 선택된 값
 * @param initialList: props로 전달받은 리스트
 * @param lastList: sorting, filtering에 따라 변경된 리스트
 * @param isMultiSelect: 멀티셀렉트 여부
 * @returns 위의 내용을 바탕으로 dropdown item을 반환
 */
export const getSelectedDropdownItems = (params: {
  values: unknown[];
  initialList: IDropDownItem[];
  lastList: IDropDownItem[];
  isMultiSelect?: boolean;
}): ISelectValue | ISelectValue[] => {
  const { values, initialList, lastList, isMultiSelect } = params;

  if (values.length === 1 && values[0] === -1) {
    return [
      {
        label: 'All',
        value: -1,
      },
    ];
  }

  const arr: ISelectValue[] = [];
  const temp = [...values];
  const loop = (items: IDropDownItem[]) => {
    items.forEach((item) => {
      if (temp.length === 0) {
        return;
      }
      if (temp.includes(item.value)) {
        arr.push({
          label: item.label as string,
          value: item.value as string | number,
          displayLabel: item.displayLabel,
        });
        temp.splice(
          temp.findIndex((v) => v === item.value),
          1
        );
      }
      if (item.children) {
        loop(item.children);
      }
    });
  };
  loop([...lastList, ...initialList]);

  const resolvedList = getResolvedList(initialList);
  const hasIsChildrenMultiSelectable = resolvedList.some((item) => item.isChildrenMultiSelectable);

  return isMultiSelect || hasIsChildrenMultiSelectable ? arr : arr[0];
};

// #endregion Dropdown container utils

// #region utils
export const getAllChildValue = (item: IDropDownItem) => {
  const arr: unknown[] = [];

  const loop = (currentItem: IDropDownItem) => {
    if (!currentItem.children) {
      if (currentItem.value !== undefined) {
        arr.push(currentItem.value);
      }
    } else {
      currentItem.children.forEach((loopItem) => loop(loopItem));
    }
  };
  loop(item);

  return arr;
};

export const getAllChildLabel = (item: IDropDownItem) => {
  const arr: string[] = [];

  const loop = (currentItem: IDropDownItem) => {
    if (typeof currentItem.label === 'string') {
      arr.push(currentItem.label);
    }
    if (currentItem.children) {
      currentItem.children.forEach((loopItem) => loop(loopItem));
    }
  };
  loop(item);

  return arr;
};

export const getFilteredList = (items: IDropDownItem[], searchText: string) => {
  const loop = (currentItem: IDropDownItem): IDropDownItem[] => {
    const regex = getRegExpByKeyword(searchText);
    if (typeof currentItem.label === 'string' && currentItem.label.match(regex)) {
      return [currentItem];
    } else if (getAllChildLabel(currentItem).some((value) => value.match(regex))) {
      return [
        {
          ...currentItem,
          children: currentItem.children?.reduce(
            (totalArr: IDropDownItem[], value: IDropDownItem) => [...totalArr, ...loop(value)],
            []
          ),
        },
      ];
    } else {
      return [];
    }
  };
  if (searchText) {
    return items.reduce((totalArr: IDropDownItem[], value: IDropDownItem) => [...totalArr, ...loop(value)], []);
  }
  return items;
};

export const getSortedList = (items: IDropDownItem[], sort: SortEnum, totalAndSort?: boolean) => {
  const loop = (currentItems: IDropDownItem[]): IDropDownItem[] => {
    const newArr = [...currentItems];
    return newArr
      .sort((a: IDropDownItem, b: IDropDownItem) =>
        typeof a.label === 'string' && typeof b.label === 'string'
          ? stringSort(a.label, b.label, sort as 'asc' | 'desc')
          : 0
      )
      .map((v) => ({
        ...v,
        children: v.children ? loop(v.children) : undefined,
      }));
  };
  if (totalAndSort && sort !== SortEnum.Featured) {
    return loop(items);
  }
  return items;
};

export const getSelectedStatus = (
  item: IDropDownItem,
  selectedValue: unknown[]
): [unknown[], number, boolean, boolean, boolean] => {
  const allChildValue = getAllChildValue(item);
  const selectedChildCount = (allChildValue.filter((v) => selectedValue.includes(v)) || []).length;
  const isSelected = !allChildValue.some((v) => !selectedValue.includes(v));
  const isIndeterminate = !isSelected && selectedChildCount > 0;
  const isAllSelected = !isSelected && selectedValue && selectedValue.length > 0 && selectedValue[0] === -1;

  return [allChildValue, selectedChildCount, isSelected, isIndeterminate, isAllSelected];
};

// #endregion utils
