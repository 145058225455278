import React from 'react';

const ArrowRightOutlineIcon = ({ color = '#454F5B', scale = 1 }: { color?: string; scale?: number }): JSX.Element => (
  <svg width={24 * scale} height={24 * scale} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24 * scale} height={24 * scale} fill="white" fillOpacity="0.01" />
    <path
      d="M9.29 6.71001C8.9 7.10001 8.9 7.73001 9.29 8.12001L13.17 12L9.29 15.88C8.9 16.27 8.9 16.9 9.29 17.29C9.68 17.68 10.31 17.68 10.7 17.29L15.29 12.7C15.68 12.31 15.68 11.68 15.29 11.29L10.7 6.70001C10.32 6.32001 9.68 6.32001 9.29 6.71001Z"
      fill={color}
    />
  </svg>
);

export default ArrowRightOutlineIcon;
