import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  chart: {
    animation: ({ disableAnimation }: { disableAnimation?: boolean }) =>
      disableAnimation ? undefined : '$chart 0.3s forwards',
    transformOrigin: '50% 100%',
  },
  '@keyframes chart': {
    from: {
      transform: 'scale(0)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
});

type CircleProps = {
  disableAnimation?: boolean;
  radius: number;
  color: string;
  tooltipEvents: {
    onMouseOut: () => void;
    onMouseOver: (event: React.MouseEvent<SVGCircleElement>) => void;
    onMouseMove: (event: React.MouseEvent<SVGCircleElement>) => void;
  };
  width: number;
  height: number;
};

export const Circle = (props: CircleProps) => {
  const { width, height, disableAnimation, radius, tooltipEvents, color } = props;
  const classes = useStyles({ disableAnimation });

  const minSize = Math.min(width, height) / 100;

  return (
    <circle
      cx={width / 2}
      cy={(100 - radius) * minSize}
      r={radius * minSize}
      fill={color}
      {...tooltipEvents}
      className={classes.chart}
    />
  );
};
