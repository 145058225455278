import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useAuth, useLogoutMutation, useSelectBrandId, useUserInfoQuery } from '~/apis/auth';
import { useIpBrandListQuery } from '~/apis/portfolio/ipDocuments';
import { userProfileAtom } from '~/atoms';
import { useLogger } from '~/hooks';
import { MarqCommonEnumsClientClientRoleEnum } from '~/swagger/data-contracts';
import { parseBrandList } from './parsers';

export const useHeaderViewModel = (params: { brandUnselectable?: boolean }) => {
  const { data: auth } = useAuth(); // 여기에 있는 role은 새로고침해도 변하지 않는다
  const { data: freshUserInfo } = useUserInfoQuery();
  const currentUserRole = freshUserInfo?.role;
  const selectedBrandId = auth?.selectedBrandId || null;
  const { data: brandList } = useIpBrandListQuery({ enabled: !!auth, select: parseBrandList });
  const { mutateAsync, isLoading } = useLogoutMutation();
  const { mutate: selectBrand } = useSelectBrandId();

  // PROD-9645 에 의해 brandUnselectable 조건에서 "&& !!selectedBrandId;" 조건 제외
  const brandUnselectable = !!params.brandUnselectable; // && !!selectedBrandId;

  const brandOption = [
    { label: 'All brands', value: null },
    ...(brandList?.map((brand) => ({ label: brand.name, value: brand.id })) || []),
  ];

  const userProfile = useAtomValue(userProfileAtom);
  const userCountries = userProfile?.limited_access_policy?.countries?.map((country) => ({
    label: country.name,
    value: country.code,
  }));
  const isUserAvailableAllBrand =
    currentUserRole === MarqCommonEnumsClientClientRoleEnum.LimitedAccess
      ? userProfile?.limited_access_policy?.has_all_brand_access
      : true;
  const isUserAvailableAllCountry =
    currentUserRole === MarqCommonEnumsClientClientRoleEnum.LimitedAccess
      ? userProfile?.limited_access_policy?.has_all_country_access
      : true;

  const handleChangeBrand = (value: number) => {
    selectBrand({
      brandId: value,
    });
  };

  const { amplitudeLogging } = useLogger();
  const navigate = useNavigate();
  const handleLogout = async () => {
    amplitudeLogging('sign out');
    await mutateAsync({
      access_token: auth?.access_token,
    });
    navigate('/login');
  };

  useEffect(() => {
    if (brandList) {
      if (selectedBrandId && !brandList.some((v) => v.id === selectedBrandId)) {
        selectBrand({
          brandId: undefined,
        });
      }
      if (brandList.length === 1) {
        selectBrand({
          brandId: brandList[0].id,
        });
      }
    }
  }, [selectBrand, brandList, selectedBrandId]);

  return {
    brand: {
      selectedId: selectedBrandId,
      onChange: handleChangeBrand,
      list: brandList || [],
      options: brandOption,
      unselectable: brandUnselectable,
      isAvailableAll: isUserAvailableAllBrand,
    },
    country: {
      options: userCountries,
      isAvailableAll: isUserAvailableAllCountry,
    },
    user: {
      firstName: userProfile?.first_name,
      onLogout: handleLogout,
    },
    isLoading,
  };
};
