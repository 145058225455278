import { ReactNode, useEffect, useState } from 'react';
import clsx from 'classnames';
import { IconButton, makeStyles } from '@material-ui/core';
import { TooltipProps } from '@material-ui/core/Tooltip';
import { MDSTheme, MDSTooltip } from '@marqvision/mds/core';
import { MDSIcon } from '@marqvision/mds-v2';

type Props = {
  url: string;
  icon?:
    | {
        size?: number;
      }
    | ReactNode;
  disabled?: boolean;
  onOpenLink?: () => void;
  tooltip?: {
    title: string;
    placement?: TooltipProps['placement'];
  };
};

const useStyles = makeStyles(() => ({
  tableButton: {
    cursor: 'pointer',
    borderRadius: '4px',
    padding: '8px',
    '&:hover': {
      backgroundColor: MDSTheme.palette.white,
    },
    '&.disabled': {
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
}));

// !!리뷰포인트
// 컴포넌트명에 대해 고민이 큽니다ㅠ
export const OpenUrl = (props: Props) => {
  const classes = useStyles();
  // !!리뷰포인트
  // props.url 등을 사용하면 명시적이긴 하나 object destructuring 의 장점을 살리지 못함.. 고민 고민
  const [disabled, setDisabled] = useState<boolean>(false);
  const tooltip = Object.assign({ title: 'Open in new tab', placement: 'top' }, props.tooltip);
  const handleOpenLink = (url: string) => {
    if (props.onOpenLink) {
      props.onOpenLink();
    } else {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  useEffect(() => {
    setDisabled(props.disabled !== undefined ? props.disabled : !props.url);
  }, [props.url, props.disabled]);

  return (
    <>
      {!disabled ? (
        <MDSTooltip title={tooltip.title} placement={tooltip.placement}>
          <IconButton className={classes.tableButton} onClick={() => handleOpenLink(props.url)}>
            {props.icon ? <>{props.icon}</> : <MDSIcon.OpenNew color={'color/content/neutral/default/normal'} />}
          </IconButton>
        </MDSTooltip>
      ) : (
        <IconButton className={clsx(classes.tableButton, 'disabled')}>
          <MDSIcon.OpenNew color={'color/content/neutral/default/disabled'} />
        </IconButton>
      )}
    </>
  );
};
