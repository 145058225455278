/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolEmptyResponse,
  MarqCommonProtocolFileResponse,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsExcelResultRequest,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsExcelResultResponse,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetAllWebsiteIdListRequest,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetAllWebsiteIdListResponse,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountAndRecentlyStatusRequest,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountAndRecentlyStatusResponse,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountRequest,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountResponse,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetExportListingsByListingIdRequest,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetExportListingsRequest,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetExportListingsResponse,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetGridWebsiteListRequest,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetGridWebsiteListResponse,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetTableWebsiteListRequest,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetTableWebsiteListResponse,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetWebsiteDetailRequest,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetWebsiteDetailResponse,
  MonitoringWebsiteSWEnforcedSoftNoticeProtocolsRequestUDRPRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringWebsiteEnforcedSoftNotice<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeStatusCountCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/status-count
   */
  enforcementWebSiteSoftNoticeStatusCountCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountAndRecentlyStatusRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountAndRecentlyStatusResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/status-count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeCountCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/count
   */
  enforcementWebSiteSoftNoticeCountCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetCountResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeGridGetListCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/grid/get-list
   */
  enforcementWebSiteSoftNoticeGridGetListCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetGridWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetGridWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/grid/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeTableGetListCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/table/get-list
   */
  enforcementWebSiteSoftNoticeTableGetListCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetTableWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetTableWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/table/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeDetailsCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/details
   */
  enforcementWebSiteSoftNoticeDetailsCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetWebsiteDetailRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetWebsiteDetailResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeGetIdListCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/get-id-list
   */
  enforcementWebSiteSoftNoticeGetIdListCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetAllWebsiteIdListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetAllWebsiteIdListResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/get-id-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeRequestCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/request
   */
  enforcementWebSiteSoftNoticeRequestCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsRequestUDRPRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolEmptyResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/request`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeExcelCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/excel
   */
  enforcementWebSiteSoftNoticeExcelCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetExportListingsResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/excel`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeExcelResultCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/excel/result
   */
  enforcementWebSiteSoftNoticeExcelResultCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedSoftNoticeProtocolsExcelResultResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/excel/result`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedSoftNotice
   * @name EnforcementWebSiteSoftNoticeExcelListingIdCreate
   * @request POST:/monitoring/enforcement/web-site/soft-notice/excel/listing-id
   */
  enforcementWebSiteSoftNoticeExcelListingIdCreate = (
    data: MonitoringWebsiteSWEnforcedSoftNoticeProtocolsGetExportListingsByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/web-site/soft-notice/excel/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
