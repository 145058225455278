import { useEffect } from 'react';
import { useSetAtom } from 'jotai/index';
import { clientFilterChipAtom } from '~/atoms';
import { useEnforcementPathParams } from '~/pages/Enforcement/EcommerceSocialMedia/@hooks';

export const useClearClientFilterChipAtom = () => {
  const { tab, stage } = useEnforcementPathParams();
  const setClientFilterChipAtom = useSetAtom(clientFilterChipAtom);
  useEffect(() => {
    setClientFilterChipAtom((ps) => ({
      ...ps,
      sellerNameList: [],
      includeExpired: false, // todo-@elie: domain_type 도 추후 처리 필요.
    }));
  }, [setClientFilterChipAtom, tab, stage]);
};
