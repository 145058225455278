/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolFileResponse,
  MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetImpactedSellersRequest,
  MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetImpactedSellersResponse,
  MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetMarketplaceFilterListRequest,
  MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetMarketplaceFilterListResponse,
  MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerCsvRequest,
  MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerRequest,
  MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PerformanceDetailsImpactedSellers<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags PerformanceDetailsImpactedSellers
   * @name DetailsImpactedSellersMarketplacesCreate
   * @request POST:/performance/details/impacted-sellers/marketplaces
   */
  detailsImpactedSellersMarketplacesCreate = (
    data: MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetMarketplaceFilterListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetMarketplaceFilterListResponse, any>({
      path: `/performance/details/impacted-sellers/marketplaces`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsImpactedSellers
   * @name DetailsImpactedSellersCreate
   * @request POST:/performance/details/impacted-sellers
   */
  detailsImpactedSellersCreate = (
    data: MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetImpactedSellersRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetImpactedSellersResponse, any>({
      path: `/performance/details/impacted-sellers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsImpactedSellers
   * @name DetailsImpactedSellersTopInfringingSellerCreate
   * @request POST:/performance/details/impacted-sellers/top-infringing-seller
   */
  detailsImpactedSellersTopInfringingSellerCreate = (
    data: MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerResponse, any>({
      path: `/performance/details/impacted-sellers/top-infringing-seller`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PerformanceDetailsImpactedSellers
   * @name DetailsImpactedSellersTopInfringingSellerCsvCreate
   * @request POST:/performance/details/impacted-sellers/top-infringing-seller/csv
   */
  detailsImpactedSellersTopInfringingSellerCsvCreate = (
    data: MonitoringPerfDetailsPerfDetailsImpactedSellersProtocolsGetTopInfringingSellerCsvRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/performance/details/impacted-sellers/top-infringing-seller/csv`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
