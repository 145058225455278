import React, { ReactNode } from 'react';
import { FormControlLabel, makeStyles, Switch, SwitchProps } from '@material-ui/core';
import { IcoCheckFill } from '../assets';
import theme from './Theme';

interface IOwnProps {
  label?: ReactNode;
}

type IProps = SwitchProps & IOwnProps;

const useStyles = makeStyles(() => ({
  controlLabel: {
    gap: ({label}: IProps) => label ? '6px' : '0',
    margin: '0px',
    padding: '2px'
  },
  root: {
    width: ({ size }: IProps) => (size === 'small' ? '24px' : '32px'),
    height: ({ size }: IProps) => (size === 'small' ? '16px' : '20px'),
    padding: 0,
  },
  track: {
    borderRadius: '10px',
    backgroundColor: theme.palette.bluegray['300'],
    opacity: 1,
  },
  trackDisabled: {
    borderRadius: '10px',
    backgroundColor: `${theme.palette.blue['300']} !important`,
  },
  switchBase: {
    padding: ({ size }: IProps) => `${size === 'small' ? '1.33px' : '2px'} !important`,
    '&.Mui-checked': {
      padding: `0 !important`,
      transform: ({ size }: IProps) => `${size === 'small' ? 'translateX(8.01px)' : 'translateX(11.5px)'} !important`,
      '& + $track': {
        backgroundColor: theme.palette.blue['700'],
        opacity: 1,
      },
      '& > span > svg': {
        fill: theme.palette.white,
        width: ({ size }: IProps) => (size === 'small' ? '16px' : undefined),
        height: ({ size }: IProps) => (size === 'small' ? '16px' : undefined),
        '& > path': {
          fill: theme.palette.white,
        },
      },
      '&:hover': {
        background: 'none',
      },
    },
  },
  thumb: {
    width: ({ size }: IProps) => `${size === 'small' ? '13.33px' : '16px'} !important`,
    height: ({ size }: IProps) => `${size === 'small' ? '13.33px' : '16px'} !important`,
    boxShadow: 'none',
    color: theme.palette.white,
  },
  disabled: {
    '& + .MuiSwitch-track': {
      opacity: '1 !important',
      backgroundColor: `${theme.palette.bluegray[150]} !important`,
    },
    '& .MuiIconButton-label .MuiSwitch-thumb': {
      color: `${theme.palette.bluegray['50']}`,
    },
    '&.Mui-checked': {
      '& + $track': {
        backgroundColor: `${theme.palette.blue['300']} !important`,
      },
    },
  },
  label: {
    fontSize: ({ size }: IProps) => (size === 'small' ? '13px' : '14px'),
  },
}));

export const Toggle = (props: IProps): JSX.Element => {
  const { checked, disabled, onChange, label, ...restProps } = props;
  const classes = useStyles(props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChange) {
      onChange(event, checked);
    }
  };

  return (
    <FormControlLabel
      className={classes.controlLabel}
      label={label}
      classes={{
        label: classes.label,
      }}
      control={
        <Switch
          {...restProps}
          checked={checked}
          disabled={disabled}
          checkedIcon={<IcoCheckFill width="20px" height="20px" />}
          classes={{
            root: classes.root,
            track: classes.track,
            thumb: classes.thumb,
            switchBase: classes.switchBase,
            disabled: classes.disabled,
          }}
          onChange={handleChange}
        />
      }
    />
  );
};

export default Toggle;
