/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolFileResponse,
  MonitoringPerfPerformanceProtocolsGetClientCountryListResponse,
  MonitoringPerfPerformanceProtocolsGetCohortAnalysisCSVNewRequest,
  MonitoringPerfPerformanceProtocolsGetCohortAnalysisRequest,
  MonitoringPerfPerformanceProtocolsGetCohortAnalysisResponse,
  MonitoringPerfPerformanceProtocolsGetCohortFilterNewRequest,
  MonitoringPerfPerformanceProtocolsGetCohortFilterNewResponse,
  MonitoringPerfPerformanceProtocolsGetComplianceRateRequest,
  MonitoringPerfPerformanceProtocolsGetComplianceRateResponse,
  MonitoringPerfPerformanceProtocolsGetComplianceRateTopMarketsRequest,
  MonitoringPerfPerformanceProtocolsGetComplianceRateTopMarketsResponse,
  MonitoringPerfPerformanceProtocolsGetEnforcedValueRequest,
  MonitoringPerfPerformanceProtocolsGetEnforcedValueResponse,
  MonitoringPerfPerformanceProtocolsGetExpertServicesRequest,
  MonitoringPerfPerformanceProtocolsGetExpertServicesResponse,
  MonitoringPerfPerformanceProtocolsGetImpactedSellerRequest,
  MonitoringPerfPerformanceProtocolsGetImpactedSellerResponse,
  MonitoringPerfPerformanceProtocolsGetRemovedListingsOverTimeRequest,
  MonitoringPerfPerformanceProtocolsGetRemovedListingsOverTimeResponse,
  MonitoringPerfPerformanceProtocolsGetTopInfringingSellersRequest,
  MonitoringPerfPerformanceProtocolsGetTopInfringingSellersResponse,
  MonitoringPerfPerformanceProtocolsGetWebsiteCountsRequest,
  MonitoringPerfPerformanceProtocolsGetWebsiteCountsResponse,
  MonitoringPerfPerformanceReportProtocolsGetListRequest,
  MonitoringPerfPerformanceReportProtocolsGetListResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Performance<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Performance
   * @name CountryListList
   * @request GET:/performance/country-list
   */
  countryListList = (params: RequestParams = {}) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetClientCountryListResponse, any>({
      path: `/performance/country-list`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name WebsiteCountsCreate
   * @request POST:/performance/website-counts
   */
  websiteCountsCreate = (data: MonitoringPerfPerformanceProtocolsGetWebsiteCountsRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetWebsiteCountsResponse, any>({
      path: `/performance/website-counts`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name EnforcedValueCreate
   * @request POST:/performance/enforced-value
   */
  enforcedValueCreate = (data: MonitoringPerfPerformanceProtocolsGetEnforcedValueRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetEnforcedValueResponse, any>({
      path: `/performance/enforced-value`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name ImpactedSellerCreate
   * @request POST:/performance/impacted-seller
   */
  impactedSellerCreate = (
    data: MonitoringPerfPerformanceProtocolsGetImpactedSellerRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetImpactedSellerResponse, any>({
      path: `/performance/impacted-seller`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name ComplianceRateCreate
   * @request POST:/performance/compliance-rate
   */
  complianceRateCreate = (
    data: MonitoringPerfPerformanceProtocolsGetComplianceRateRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetComplianceRateResponse, any>({
      path: `/performance/compliance-rate`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name ExpertServicesCreate
   * @request POST:/performance/expert-services
   */
  expertServicesCreate = (
    data: MonitoringPerfPerformanceProtocolsGetExpertServicesRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetExpertServicesResponse, any>({
      path: `/performance/expert-services`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name CohortAnalysisCsvCreate
   * @request POST:/performance/cohort-analysis-csv
   */
  cohortAnalysisCsvCreate = (
    data: MonitoringPerfPerformanceProtocolsGetCohortAnalysisCSVNewRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/performance/cohort-analysis-csv`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name CohortAnalysisFilterCreate
   * @request POST:/performance/cohort-analysis/filter
   */
  cohortAnalysisFilterCreate = (
    data: MonitoringPerfPerformanceProtocolsGetCohortFilterNewRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetCohortFilterNewResponse, any>({
      path: `/performance/cohort-analysis/filter`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name TopInfringingSellersCreate
   * @request POST:/performance/top-infringing-sellers
   */
  topInfringingSellersCreate = (
    data: MonitoringPerfPerformanceProtocolsGetTopInfringingSellersRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetTopInfringingSellersResponse, any>({
      path: `/performance/top-infringing-sellers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name ListingsTimeCreate
   * @request POST:/performance/listings-time
   */
  listingsTimeCreate = (
    data: MonitoringPerfPerformanceProtocolsGetRemovedListingsOverTimeRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetRemovedListingsOverTimeResponse, any>({
      path: `/performance/listings-time`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name ComplianceRateTopCreate
   * @request POST:/performance/compliance-rate-top
   */
  complianceRateTopCreate = (
    data: MonitoringPerfPerformanceProtocolsGetComplianceRateTopMarketsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetComplianceRateTopMarketsResponse, any>({
      path: `/performance/compliance-rate-top`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name CohortAnalysisCreate
   * @request POST:/performance/cohort-analysis
   */
  cohortAnalysisCreate = (
    data: MonitoringPerfPerformanceProtocolsGetCohortAnalysisRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringPerfPerformanceProtocolsGetCohortAnalysisResponse, any>({
      path: `/performance/cohort-analysis`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Performance
   * @name ReportListCreate
   * @request POST:/performance/report/list
   */
  reportListCreate = (data: MonitoringPerfPerformanceReportProtocolsGetListRequest, params: RequestParams = {}) =>
    this.http.request<MonitoringPerfPerformanceReportProtocolsGetListResponse, any>({
      path: `/performance/report/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
