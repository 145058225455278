import React from 'react';
import { TablePagination, IconButton, makeStyles } from '@material-ui/core';
import { TablePaginationProps } from '@material-ui/core/TablePagination/TablePagination';
import { IcoArrowLeftOutline, IcoArrowRightOutline } from '../assets';
import Theme from './Theme';
import numeral from 'numeral';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 6px',
    borderBottom: '0',
    background: 'white',
  },
  toolbar: {
    minHeight: '32px',
    padding: '0',
  },
  actions: {
    marginLeft: '0',
  },
  caption: {
    ...Theme.typography.regular,
    ...Theme.typography.T14,
    color: Theme.palette.bluegray['800'],
    '& span': {
      ...Theme.typography.medium,
      marginRight: '4px',
    },
  },
  buttons: {
    flexShrink: 0,
    '& button': {
      marginRight: '0 !important',
      '& svg': {
        color: Theme.palette.bluegray['800'],
      },
    },
    '& button.Mui-disabled': {
      '& svg': {
        opacity: 0.5,
      },
    },
  },
}));

interface IOwnProps {
  label?: string;
}

type IProps = IOwnProps & TablePaginationProps;

const TablePaginationActions = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <div className={classes.buttons}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <IcoArrowLeftOutline />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        <IcoArrowRightOutline />
      </IconButton>
    </div>
  );
};

export const Pagination = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { label, ...restProps } = props;

  const formattedNumber = (value: number) => {
    return numeral(value).format('0,0');
  };

  return (
    <TablePagination
      {...restProps}
      classes={{
        root: classes.root,
        toolbar: classes.toolbar,
        actions: classes.actions,
        caption: classes.caption,
      }}
      rowsPerPageOptions={[]}
      labelDisplayedRows={({ from, to, count }) => (
        <div>
          <span>
            {label ? `${label} ` : ''}
            {formattedNumber(from)}-{formattedNumber(to)}
          </span>
          of {formattedNumber(count)}
        </div>
      )}
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default Pagination;
