/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MarqCommonProtocolFileResponse,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsExcelResultRequest,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsExcelResultResponse,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetAllWebsiteIdListRequest,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetAllWebsiteIdListResponse,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetCountRequest,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetCountResponse,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetExportListingsByListingIdRequest,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetExportListingsRequest,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetExportListingsResponse,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetGridWebsiteListRequest,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetGridWebsiteListResponse,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetTableWebsiteListRequest,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetTableWebsiteListResponse,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetWebsiteDetailRequest,
  MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetWebsiteDetailResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MonitoringWebsiteEnforcedNonEnforceable<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedNonEnforceable
   * @name EnforcementWebSiteNonEnforceableCountCreate
   * @request POST:/monitoring/enforcement/web-site/non-enforceable/count
   */
  enforcementWebSiteNonEnforceableCountCreate = (
    data: MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetCountRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetCountResponse, any>({
      path: `/monitoring/enforcement/web-site/non-enforceable/count`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedNonEnforceable
   * @name EnforcementWebSiteNonEnforceableGridGetListCreate
   * @request POST:/monitoring/enforcement/web-site/non-enforceable/grid/get-list
   */
  enforcementWebSiteNonEnforceableGridGetListCreate = (
    data: MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetGridWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetGridWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/non-enforceable/grid/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedNonEnforceable
   * @name EnforcementWebSiteNonEnforceableTableGetListCreate
   * @request POST:/monitoring/enforcement/web-site/non-enforceable/table/get-list
   */
  enforcementWebSiteNonEnforceableTableGetListCreate = (
    data: MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetTableWebsiteListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetTableWebsiteListResponse, any>({
      path: `/monitoring/enforcement/web-site/non-enforceable/table/get-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedNonEnforceable
   * @name EnforcementWebSiteNonEnforceableDetailsCreate
   * @request POST:/monitoring/enforcement/web-site/non-enforceable/details
   */
  enforcementWebSiteNonEnforceableDetailsCreate = (
    data: MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetWebsiteDetailRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetWebsiteDetailResponse, any>({
      path: `/monitoring/enforcement/web-site/non-enforceable/details`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedNonEnforceable
   * @name EnforcementWebSiteNonEnforceableGetIdListCreate
   * @request POST:/monitoring/enforcement/web-site/non-enforceable/get-id-list
   */
  enforcementWebSiteNonEnforceableGetIdListCreate = (
    data: MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetAllWebsiteIdListRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetAllWebsiteIdListResponse, any>({
      path: `/monitoring/enforcement/web-site/non-enforceable/get-id-list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedNonEnforceable
   * @name EnforcementWebSiteNonEnforceableExcelCreate
   * @request POST:/monitoring/enforcement/web-site/non-enforceable/excel
   */
  enforcementWebSiteNonEnforceableExcelCreate = (
    data: MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetExportListingsRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetExportListingsResponse, any>({
      path: `/monitoring/enforcement/web-site/non-enforceable/excel`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedNonEnforceable
   * @name EnforcementWebSiteNonEnforceableExcelResultCreate
   * @request POST:/monitoring/enforcement/web-site/non-enforceable/excel/result
   */
  enforcementWebSiteNonEnforceableExcelResultCreate = (
    data: MonitoringWebsiteSWEnforcedNonEnforceableProtocolsExcelResultRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MonitoringWebsiteSWEnforcedNonEnforceableProtocolsExcelResultResponse, any>({
      path: `/monitoring/enforcement/web-site/non-enforceable/excel/result`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MonitoringWebsiteEnforcedNonEnforceable
   * @name EnforcementWebSiteNonEnforceableExcelListingIdCreate
   * @request POST:/monitoring/enforcement/web-site/non-enforceable/excel/listing-id
   */
  enforcementWebSiteNonEnforceableExcelListingIdCreate = (
    data: MonitoringWebsiteSWEnforcedNonEnforceableProtocolsGetExportListingsByListingIdRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<MarqCommonProtocolFileResponse, any>({
      path: `/monitoring/enforcement/web-site/non-enforceable/excel/listing-id`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
