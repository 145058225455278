import { useCallback, useEffect, useRef, useState } from 'react';
import { useBlocker } from 'react-router-dom';

const usePromisifiedModal = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const modalPromiseRef = useRef<{ resolve: () => void; reject: () => void } | null>(null);

  const onPromisifiedModalOpen = () => {
    return new Promise<void>((resolve, reject) => {
      modalPromiseRef.current = { resolve, reject };
      setShowPrompt(true);
    });
  };

  const onLeave = () => {
    modalPromiseRef.current?.resolve();
    setShowPrompt(false);
  };

  const onStay = () => {
    modalPromiseRef.current?.reject();
    setShowPrompt(false);
  };

  return { showPrompt, onStay, onLeave, onPromisifiedModalOpen };
};

export const usePrompt = (isDirty: boolean) => {
  const blocker = useBlocker(isDirty);
  const { showPrompt, onStay, onLeave, onPromisifiedModalOpen } = usePromisifiedModal();
  const modalPromiseRef = useRef<{ resolve: () => void; reject: () => void } | null>(null);

  const confirm = useCallback(() => {
    if (!isDirty) return Promise.resolve(true);

    return new Promise<boolean>((resolve) => {
      onPromisifiedModalOpen().then(
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });
  }, [isDirty, modalPromiseRef]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm().then((result) => {
        if (result) blocker.proceed();
        else blocker.reset();
      });
    }
  }, [blocker, confirm]);

  useEffect(() => {
    if (isDirty) window.onbeforeunload = () => 'Changes you made may not be saved.';

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  return { showPrompt, onStay, onLeave };
};
