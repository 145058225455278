import MDSSearch from '../../Search';
import { localizedLabel } from '../@constants';
import { LanguageEnum } from '../@types';

type Props = {
  locale: LanguageEnum;
  onSearch: (value: string) => void;
};

export const Search = (props: Props) => {
  const { locale, onSearch } = props;

  return (
    <div className="dropSearchBox">
      <MDSSearch
        autoFocus
        placeholder={localizedLabel.search[locale]}
        realtime
        onSearch={onSearch}
        style={{ width: '100%' }}
      />
    </div>
  );
};
