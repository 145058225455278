import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { MDSIcon, MDSTypography, MDSTypographyProps } from '@marqvision/mds-v2';
import { MDSButton } from '@marqvision/mds/core';

type CollapsedTextProps = MDSTypographyProps & {
  showMoreLabel?: string;
  showLessLabel?: string;
};
/**
 * @props showMoreLabel, showLessLabel prop 이외의 나머지 prop은 MDSTypography와 동일한 prop을 사용합니다.
 */
export const CollapsibleText = ({
  variant = 'T16',
  lineClamp = 4,
  showMoreLabel = 'Show more',
  showLessLabel = 'Show less',
  ...props
}: CollapsedTextProps) => {
  const [descriptionAreaState, setDescriptionAreaState] = useState<'hidden' | 'collapsed' | 'expanded'>('hidden');
  const showMoreButtonProps = useMemo(
    () => ({
      endIcon:
        descriptionAreaState === 'collapsed' ? (
          <MDSIcon.ArrowDown variant="outline" />
        ) : (
          <MDSIcon.ArrowUp variant="outline" />
        ),
      children: descriptionAreaState === 'collapsed' ? showMoreLabel : showLessLabel,
      onClick: () => {
        setDescriptionAreaState((prev) => (prev === 'collapsed' ? 'expanded' : 'collapsed'));
      },
    }),
    [descriptionAreaState, showLessLabel, showMoreLabel]
  );

  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const fontHeight = Number(variant.replace('T', '')) * 1.5; // 1.5 is the line-height
    const textHeightLimit = fontHeight * (lineClamp || Number.MAX_SAFE_INTEGER); //pixels
    const showExpandToggle = textHeightLimit < (textRef.current?.clientHeight ?? 0);
    setDescriptionAreaState(showExpandToggle ? 'collapsed' : 'hidden');
  }, [lineClamp, variant, props.children]);

  return (
    <div>
      <MDSTypography
        variant={variant}
        lineClamp={descriptionAreaState === 'collapsed' ? lineClamp : 0}
        {...props}
        as="div"
      >
        <div ref={textRef}>{props.children}</div>
      </MDSTypography>
      {descriptionAreaState !== 'hidden' && (
        <MDSButton {...showMoreButtonProps} variant="text" color="bluegray" size="medium" />
      )}
    </div>
  );
};
