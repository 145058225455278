import { UseQueryOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  SettingsCreditsProtocolsGetCreditHistoryRequest,
  SettingsProtocolsGetClientActiveCountriesResponse,
  SettingsProtocolsGetEmployeeListResponse,
  SettingsProtocolsGetProfileResponse,
} from '~/swagger/data-contracts';
import { creditsApi, queryClient, legacySettingsApi, settingsApi } from '~/apis';

export const QueryKeySettings = 'settings';

export const useGetProfileQuery = (
  options: UseQueryOptions<SettingsProtocolsGetProfileResponse, null, SettingsProtocolsGetProfileResponse>
) => {
  return useQuery<SettingsProtocolsGetProfileResponse, null, SettingsProtocolsGetProfileResponse>(
    [QueryKeySettings, 'profile'],
    async () => {
      const { data } = await settingsApi.getProfileList();
      return data;
    },
    options
  );
};

export const useUpdateCompanyProfileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(legacySettingsApi.settingsUpdateCompanyProfileCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeySettings, 'company_list']);
    },
  });
};

export const useGetEmployeeListQuery = <TData>(
  options?: UseQueryOptions<SettingsProtocolsGetEmployeeListResponse, unknown, TData>
) => {
  return useQuery<SettingsProtocolsGetEmployeeListResponse, unknown, TData>(
    [QueryKeySettings, 'employee', 'list'],
    async () => {
      const { data } = await settingsApi.getEmployeeListList();
      return data;
    },
    options
  );
};

export const useGetClientActiveCountriesQuery = <TData>(
  options?: UseQueryOptions<SettingsProtocolsGetClientActiveCountriesResponse, unknown, TData>
) => {
  return useQuery<SettingsProtocolsGetClientActiveCountriesResponse, unknown, TData>(
    [QueryKeySettings, 'employee', 'countries'],
    async () => {
      const { data } = await settingsApi.clientActiveCountriesList();
      return data;
    },
    options
  );
};

export const useSendInvitationMutation = () => {
  return useMutation(legacySettingsApi.settingsSendInvitationCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeySettings, 'employee']);
    },
  });
};

export const useResendInvitationMutation = () => {
  return useMutation(legacySettingsApi.settingsRequestInvitationEmailCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeySettings, 'employee']);
    },
  });
};

export const useUpdateEmployeeRoleMutation = () => {
  return useMutation(legacySettingsApi.settingsUpdateEmployeeRoleCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeySettings, 'employee']);
    },
  });
};

export const useDeleteEmployeeMutation = () => {
  return useMutation(legacySettingsApi.settingsDeleteEmployeeCreate, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeySettings, 'employee']);
    },
  });
};

// credits
export const useGetCreditsQuery = () => {
  return useQuery([QueryKeySettings, 'credits'], async () => {
    const { data } = await creditsApi.creditsList();
    return data;
  });
};

export const useGetCreditsHistoryQuery = (request: SettingsCreditsProtocolsGetCreditHistoryRequest) => {
  return useInfiniteQuery(
    [QueryKeySettings, 'credits_history', request],
    async ({ pageParam }) => {
      const { data } = await creditsApi.creditsHistoryCreate({
        ...request,
        token: pageParam,
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.history.length < 1) return;

        return lastPage.token;
      },
    }
  );
};

export const useContactUsMutation = () => useMutation(creditsApi.creditsContactUsCreate);
